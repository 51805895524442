<form [formGroup]="importForm" class="search-form text-right" [ngClass]="{ edit: isEditMode }">
  <div fxLayoutAlign="stretch" class="m-b--8">
    <app-dropdown fxFlex="65px" class="platform-dropdown" [isSmallIcon]="true" (onClosed)="(onDropdownClose$)">
      <div selected fxFlex="grow" fxLayout="column" fxLayoutGap="12px" class="platform-dropdown--selected">
        <span fxFlex="52px" fxFlex.lt-sm="46px" fxLayoutAlign="center center">
          <mat-icon
            [svgIcon]="
              selectedMediaPlatform === SOCIAL_MEDIA_ACCOUNT_TYPE.Mixcloud
                ? selectedMediaPlatform.toLowerCase() + '-frame'
                : selectedMediaPlatform.toLowerCase()
            "
            [ngClass]="selectedMediaPlatform | lowercase"></mat-icon>
        </span>
      </div>
      <div options fxFlex="grow" fxLayout="column" class="platform-dropdown--options">
        <ng-container *ngFor="let media of socialMediaAccountTypes; index as i">
          <div
            fxFlex="42px"
            fxLayoutAlign="center center"
            *ngIf="SOCIAL_MEDIA_ACCOUNT_TYPE[media] !== selectedMediaPlatform"
            (click)="onMediaPlatformSelect(SOCIAL_MEDIA_ACCOUNT_TYPE[media])"
            class="option">
            <mat-icon
              [svgIcon]="
                SOCIAL_MEDIA_ACCOUNT_TYPE[media] === SOCIAL_MEDIA_ACCOUNT_TYPE.Mixcloud
                  ? media.toLowerCase() + '-frame'
                  : media.toLowerCase()
              "
              [ngClass]="media.toLowerCase()"></mat-icon>
          </div>
        </ng-container>
      </div>
    </app-dropdown>

    <app-search-input
      fxFlex="grow"
      formControlName="searchText"
      placeholder="Enter artist name"
      isImportStyles="true"
      (onSearchClick$)="onSearchClick()"
      i18n-placeholder>
    </app-search-input>
  </div>
  <a *ngIf="!isEditMode" [routerLink]="['/artists', 'create']" class="skip-import" i18n>
    Skip import and create new profile
  </a>
  <a *ngIf="isEditMode" fxHide fxShow.lt-sm (click)="nextStepClick$.emit()" class="skip-import mobile" i18n>
    Skip import and go to edit mode
  </a>
</form>

<ng-container *ngIf="!loading; else loadingTmplt">
  <section
    fxLayout="column"
    fxLayoutAlign="start center"
    *ngIf="importablePageData$ | async as importablePageData"
    class="search-results">
    <div fxLayout="column" fxLayoutGap="14px" *ngIf="importablePageData.length <= 0" class="m-b--40">
      <p class="f-size--16 f-weight--600 text-center">
        <span i18n>We didn't find any matches for </span>
        <span>"{{ importForm.value.searchText }}".</span>
      </p>
      <p class="f-size--14 text-center" i18n>Make sure the spelling and/or user name is correct.</p>
    </div>

    <div
      fxLayout.lt-sm="row wrap"
      fxLayoutGap="16px"
      fxLayoutGap.lt-sm="10px"
      class="search-results--list m-b--64"
      [ngClass]="{ edit: isEditMode }">
      <ng-container *ngFor="let importableData of importablePageData">
        <app-importable-page-card
          fxFlex="180px"
          fxFlex.lt-sm="calc(50% - 10px)"
          [isSelected]="isImportablePageSelected(importableData)"
          [socialMediaAccountData]="importableData"
          (onImportablePageCardClick$)="onImportablePageCardClick($event)">
        </app-importable-page-card>
      </ng-container>
    </div>
  </section>

  <section class="selected-profiles-section">
    <h3 class="subtitle-1 f-weight--600 m-b--32" [ngClass]="{ 'text-center': !isEditMode }"
      ><span i18n>Selected profiles</span> ({{ selectedProfilesLength }})</h3
    >

    <div
      fxLayout="row"
      fxLayoutAlign.lt-sm="center start"
      fxLayoutGap="32px"
      fxLayoutGap.lt-sm="-16px"
      class="selected-profiles m-b--56"
      [ngClass]="{ edit: isEditMode }">
      <div
        *ngFor="let mediaType of socialMediaAccountTypes; let i = index"
        class="selected-profile"
        [ngClass]="{
          filled: !!selectedImportableData[SOCIAL_MEDIA_ACCOUNT_TYPE[mediaType]],
          empty: !selectedImportableData[SOCIAL_MEDIA_ACCOUNT_TYPE[mediaType]],
          central: i === 1
        }">
        <mat-icon svgIcon="close-circle-gray" (click)="onRemoveMediaClick(mediaType)" class="close-icon"></mat-icon>
        <ng-container *ngIf="!!selectedImportableData[SOCIAL_MEDIA_ACCOUNT_TYPE[mediaType]]">
          <img
            [src]="selectedImportableData[SOCIAL_MEDIA_ACCOUNT_TYPE[mediaType]].thumbnailImageUrl"
            class="selected-profile--img" />
        </ng-container>
        <mat-icon
          [svgIcon]="
            SOCIAL_MEDIA_ACCOUNT_TYPE[mediaType] === SOCIAL_MEDIA_ACCOUNT_TYPE.Mixcloud
              ? mediaType.toLowerCase() + '-frame'
              : mediaType.toLowerCase()
          "
          class="selected-profile--media-icon"
          [ngClass]="mediaType.toLowerCase()">
        </mat-icon>
      </div>
    </div>

    <button
      fxLayoutAlign="start center"
      fxLayoutGap="4px"
      [disabled]="
        !selectedImportableData.MIXCLOUD &&
        !selectedImportableData.YOUTUBE &&
        !selectedImportableData.SPOTIFY &&
        !isEditMode
      "
      (click)="onSaveDataClick()"
      class="gig-button primary m-l-auto"
      [ngClass]="{ 'small m--0-auto': !isEditMode, 'wide m-left--auto': isEditMode }">
      <mat-icon *ngIf="!isEditMode" svgIcon="import-white"></mat-icon>
      <span *ngIf="!isEditMode" class="white" i18n>Import</span>
      <span *ngIf="isEditMode" class="next" i18n>Next</span>
    </button>

    <div fxHide fxShow.lt-sm class="step-number">
      <span fxFlex class="text-center">{{ PROGRESS_STEP_NUMBER.IMPORT_CONTENT }} <span i18n>of</span> 5</span>
    </div>
  </section>
</ng-container>

<ng-template #loadingTmplt>
  <div fxLayout fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="48px">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template>
