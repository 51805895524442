import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetCurrentUserGQL, User } from '../../generated/graphql';
import { MetaService } from '../services/meta.service';

@Component({
  selector: 'app-gigs',
  templateUrl: './gigs.component.html',
  styleUrls: ['./gigs.component.scss'],
})
export class GigsComponent implements OnInit {
  currentUser: Observable<User>;
  constructor(private getMe: GetCurrentUserGQL, private meta: MetaService) {}

  ngOnInit() {
    this.meta.setLocalizedTitle({
      en: `Post a gig and get applications from artists, live bands and DJ's | Gigital`,
      'sv-se': 'Skapa ett gig - få anbud av artister, band & DJs | Gigital',
    });

    this.meta.setLocalizedDescription({
      en: `200 verified artists, DJ's and bands. Find and book your favorite. Free registration. Safe bookings.`,
      'sv-se':
        'Hitta och boka över 200 professionella artister, band och DJ:s till ditt event med hjälp av vår tjänst Gigs. Gratis registrering - trygg och säker bokning.',
    });

    this.currentUser = this.getMe.watch().valueChanges.pipe(map(({ data }) => data.me));
  }
}
