import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Booking,
  BookingStatusEnum,
  ChannelFieldsFragment,
  ChannelFieldsFragmentDoc,
  ChannelMessage,
  PostChannelMessageGQL,
  User,
} from '../../../generated/graphql';
import { doesTextContainContactInfo } from '../../../utils/contactInfo';
import { amIBooker, atLeastOneDateAccepted, getStatus } from '../../bookings/bookingUtils';
import { ExtendedChannel } from '../../bookings/channelUtils';
import { BookingDetailsModalComponent } from '../../bookings/components/booking-details-modal/booking-details-modal.component';
import { apiToDate } from '../../helpers';
import { AnalyticsService } from '../../services/analytics.service';
import { IconsRegistryService, IconSubsets } from '../../services/icons-registry.service';
import { TranslationsService } from '../../services/translations.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { FilesListComponent } from '../files-list/files-list.component';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
})
export class ChannelComponent implements AfterViewChecked, OnChanges, OnInit {
  @Output() showBooking: EventEmitter<any> = new EventEmitter();

  @Input() channel: ExtendedChannel;
  @Input() booking: Booking;
  @Input() currentUser: User;
  @Input() loading: boolean;
  @Input() hasStatus: boolean;
  @Input() showInput = true;

  @ViewChild('messagesContainer') messagesContainerEl;
  doScroll = false;

  get bookingStatus() {
    return getStatus(this.booking, this.currentUser.id);
  }

  get amIBooker(): any {
    return amIBooker(this.booking, this.currentUser.id);
  }

  get datesStatus(): any {
    return atLeastOneDateAccepted(this.booking.dates) ? $localize`Confirmed` : $localize`Unconfirmed`;
  }

  get termsLink(): string {
    const locale = this.currentLocale === 'sv-se' ? 'sv' : 'en';
    return `http://help.gigital.se/${locale}/articles/3315741-terms-of-use`;
  }

  get policyLink(): string {
    if (this.currentLocale === 'sv-se') {
      return 'http://help.gigital.se/sv/articles/3304388-avbokningspolicy';
    } else {
      return 'http://help.gigital.se/en/articles/3304388-cancellation-policy';
    }
  }

  messagesWithFiles: ChannelMessage[] = [];
  messageBody = new FormControl();
  messageLoading = false;
  warnContactInfo = false;
  isBookingStale = false;

  public currentLocale: string;

  constructor(
    private postMessage: PostChannelMessageGQL,
    public translationsService: TranslationsService,
    private iconsService: IconsRegistryService,
    private analytics: AnalyticsService,
    private snackbar: MatSnackBar,
    private modal: MatDialog,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS, IconSubsets.FORMS]);
  }

  public ngOnInit(): void {
    this.currentLocale = this.translationsService.getCurrentLocale();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.channel && changes.channel.currentValue) {
      this.messagesWithFiles = this.channel.messages.filter((message: ChannelMessage) => (message.file ? true : false));
    }

    if (changes.booking && changes.booking.currentValue) {
      const sorted = this.booking.dates.sort((a, b) => apiToDate(b.date).getTime() - apiToDate(a.date).getTime());

      const latestDate = apiToDate(sorted[0].date);
      latestDate.setDate(latestDate.getDate() + 1);

      const laterDate = new Date();
      laterDate.setDate(laterDate.getDate() - 1);

      this.isBookingStale = Date.now() > latestDate.getTime() || this.booking.status === BookingStatusEnum.Cancelled;

      if (this.isBookingStale) {
        this.messageBody.disable();
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.doScroll && this.messagesContainerEl) {
      this.messagesContainerEl.nativeElement.scrollTop = this.messagesContainerEl.nativeElement.scrollHeight;
      this.doScroll = false;
    }
  }

  acknowledgeContactInfo() {
    this.warnContactInfo = false;
    this.analytics.emitEvent('channel_message_contact_info_acknowledged', { channel_id: this.channel.id.toString() });
  }

  submitMessage(file?: File, existingFileId?: number): void {
    if (this.messageBody.value || file) {
      const containsContactInfo = doesTextContainContactInfo(this.messageBody.value);
      if (containsContactInfo && this.channel.booking.status != BookingStatusEnum.Signed) {
        // Do some shit with it
        // console.log('msg contains contact info');
        this.warnContactInfo = true;
        this.analytics.emitEvent('channel_message_contact_info_blocked', { channel_id: this.channel.id.toString() });
        return;
      }

      let splitFileName = [];
      if (file) splitFileName = file.name.split('.');
      this.postMessage
        .mutate(
          {
            body: file || existingFileId ? '' : this.messageBody.value,
            channelId: this.channel.id,
            file: file ? file : undefined,
            existingFileId: existingFileId ? existingFileId : undefined,
            fileExtension: file ? splitFileName[splitFileName.length - 1] : undefined,
          },
          {
            update: (store, { data: { postMessage } }) => {
              if (postMessage) {
                const cache: ChannelFieldsFragment = store.readFragment({
                  fragment: ChannelFieldsFragmentDoc,
                  id: `Channel:${this.channel.id.toString()}`,
                  fragmentName: 'channelFields',
                });

                cache.messages.push(postMessage);

                store.writeFragment({
                  fragment: ChannelFieldsFragmentDoc,
                  id: `Channel:${this.channel.id.toString()}`,
                  data: cache,
                  fragmentName: 'channelFields',
                });

                this.messageBody.setValue('');
              }
              this.messageLoading = false;
            },
            context: { useMultipart: !!file },
          },
        )
        .subscribe(
          () => {
            this.messageBody.enable();
            if (file) {
              this.analytics.emitEvent('channel_message_file_append', { channel_id: this.channel.id.toString() });
            } else {
              this.analytics.emitEvent('channel_message_send_complete', { channel_id: this.channel.id.toString() });
            }
            setTimeout(() => (this.doScroll = true), 500);
          },
          (error) => {
            this.messageLoading = false;
            const snackbarRef = this.snackbar.open(
              $localize`Could not send the message, please try again.`,
              $localize`Send`,
              { duration: 3000 },
            );
            snackbarRef.onAction().subscribe(() => {
              this.submitMessage();
            });
            this.analytics.emitEvent('channel_message_send_fail', { channel_id: this.channel.id.toString() });
          },
        );
      this.messageBody.disable();
    }
  }

  uploadFile() {
    const dialogRef = this.modal.open(FileUploadComponent, { width: '640px' });
    dialogRef.afterClosed().subscribe((res) => {
      if (!!res && !!res.file) {
        this.submitMessage(res.file);
      } else if (!!res && !!res.existingFileId) {
        this.submitMessage(undefined, res.existingFileId);
      }
    });
  }

  public openAllAtachedFiles(): void {
    this.modal.open(FilesListComponent, { width: '640px', data: this.messagesWithFiles });
  }

  public getPartner(): User {
    return this.channel.users.find((user) => user.id !== this.currentUser.id);
  }

  public openDetails(): void {
    this.modal.open(BookingDetailsModalComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      data: {
        booking: this.booking,
        currentUser: this.currentUser,
      },
    });
  }
}
