import { Booking, BookingStatusEnum } from '../../generated/graphql';

export const ApplicationStatus = {
  BOOKED: $localize`Booked`,
  PENDING: $localize`Pending`,
  DECLINED: $localize`Declined`,
  FINALIZING: $localize`Finalizing`,
};

export const getApplicationStatus = (booking: Booking): string => {
  if (booking.status === BookingStatusEnum.Signed) return ApplicationStatus.BOOKED;

  if (
    booking.status === BookingStatusEnum.Declined ||
    booking.status === BookingStatusEnum.Cancelled ||
    booking.status === BookingStatusEnum.ApplicationCancelled
  ) {
    return ApplicationStatus.DECLINED;
  }

  if (booking.status === BookingStatusEnum.Pending || booking.status === BookingStatusEnum.WaitingForOtherSign)
    return ApplicationStatus.FINALIZING;

  return ApplicationStatus.PENDING;
};
