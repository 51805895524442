const namesMap = new Map();
namesMap.set('Stockholms län', 'Stockholm');
namesMap.set('Västra Götalands län', 'Göteborg');
namesMap.set('Skånes län', 'Malmö');

export const getPlaceName = (name: string): string => {
  if (namesMap.has(name)) {
    return namesMap.get(name);
  }
  return name;
};

// yuck, no typings. Hound me to actually add the typings via graphql
export const getTownName = (placeData: any) => {
  // Step down granularly. Roughly translates to town -> county -> state/region -> country
  let foundTown = placeData.components.find(
    (c) => (c.types as string[]).indexOf('postal_town') > -1 || (c.types as string[]).indexOf('locality') > -1,
  );

  if (!foundTown) {
    foundTown = placeData.components.find((c) => (c.types as string[]).indexOf('administrative_area_level_2') > -1);
  }

  if (!foundTown) {
    foundTown = placeData.components.find((c) => (c.types as string[]).indexOf('administrative_area_level_1') > -1);
  }

  if (!foundTown) {
    foundTown = placeData.components.find((c) => (c.types as string[]).indexOf('country') > -1);
  }

  return foundTown?.long_name || 'The world';
};
