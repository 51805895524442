<header fxLayout="row" fxLayoutAlign="space-between">
  <ng-content select="[title]"></ng-content>
  <h1 class="h5 gray-1" mat-dialog-title *ngIf="title">{{ title }} <sup *ngIf="isBeta">BETA</sup></h1>
  <button matDialogClose class="m-left--auto cursor"><mat-icon svgIcon="close"></mat-icon></button>
</header>
<mat-dialog-content [class.prevent-scroll]="preventScroll">
  <ng-content></ng-content>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!(hideSave && hideClose)">
  <button mat-flat-button matDialogClose i18n="@@close" *ngIf="!hideClose">Close</button>

  <button
    *ngIf="!hideSave && !saveButtonName"
    (click)="onSave()"
    [disabled]="disableSave"
    class="gig-button black wide m-left--auto"
    i18n="@@save"
    >Save</button
  >
  <button
    *ngIf="!hideSave && saveButtonName"
    (click)="onSave()"
    [disabled]="disableSave"
    class="gig-button black wide m-left--auto"
    >{{ saveButtonName }}</button
  >
</mat-dialog-actions>
