import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  apiError: string;
  private orgRef: string;
  private returnTo: string;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private meta: MetaService,
    private activeRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.meta.setLocalizedTitle({
      en: 'Create an account | Entertainment for you event | Gigital',
      'sv-se': 'Skapa ett konto | Underhållning till ditt event | Gigital',
    });

    this.meta.setLocalizedDescription({
      en: `Create your free Gigital account. 200 verified artists, DJ's and bands. Find and book your favorite. Free registration. Safe bookings.`,
      'sv-se':
        'Registrera dig gratis på Gigital. Hitta och boka över 200 artister, band och DJs till ditt event. Gratis registrering - trygg och säker bokning.',
    });

    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });

    this.orgRef = this.activeRoute.snapshot.queryParams.orgRef;
    this.returnTo = this.activeRoute.snapshot.queryParams.returnTo;
  }

  get username(): AbstractControl {
    return this.loginForm.get('username');
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  get firstName(): AbstractControl {
    return this.loginForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.loginForm.get('lastName');
  }

  onSubmit(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.apiError = undefined;

    const callback = (error) => {
      this.loading = false;
      console.log(error);
      if (error.description && error.description.rules && error.description.rules[0]) {
        this.apiError = `${error.description.rules[0].message}`.replace('%d', error.description.rules[0].format[0]);
      } else if (error.name) {
        this.apiError = error.name;
      } else {
        this.apiError = error.code;
      }
    };

    this.auth.register(
      this.username.value,
      this.password.value,
      this.firstName.value,
      this.lastName.value,
      callback,
      this.orgRef,
      this.returnTo,
    );
  }

  onSocialSignup(platform?: string): void {
    this.auth.socialLogin(platform);
  }
}
