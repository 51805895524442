import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingsDetailSignComponent } from './pages/bookings-detail-sign/bookings-detail-sign.component';
import { BookingsDetailComponent } from './pages/bookings-detail/bookings-detail.component';
import { BookingsListComponent } from './pages/bookings-list/bookings-list.component';

const routes: Routes = [
  {
    path: '',
    component: BookingsListComponent,
  },
  {
    path: ':bookingId',
    component: BookingsDetailComponent,
    children: [
      {
        path: 'sign',
        component: BookingsDetailSignComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BookingsRoutingModule {}
