import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'media',
})
export class MediaPipe implements PipeTransform {
  constructor(private sanitizer?: DomSanitizer) {}

  transform(media: any, args?: any): any {
    if (media && media.socialMediaId === 6) {
      let videoId = '';
      if (media.url.includes('youtu.be')) {
        videoId = media.url.split('be/')[1];
      }

      if (media.url.includes('youtube')) {
        if (!media.url.includes('v=')) {
          return;
        }
        videoId = media.url.split('v=')[1].split('&')[0];
      }

      if (media.socialMediaTypeId === 3) {
        videoId = media.url;
      }

      return this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/embed/${videoId}?enablejsapi=1&origin="https://www.gigital.com/"`,
      );
    }

    if (media && media.socialMediaId === 7) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://w.soundcloud.com/player/?url=${
          media.url
        }&color=%23f53b57&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&buying=false&download=false&sharing=false${
          media.needVisual ? '&visual=true' : null
        }`,
      );
    }

    if (media && media.socialMediaId === 8) {
      let videoId = media.url;
      if (media.socialMediaTypeId === 3) {
        videoId = `https://open.spotify.com/track/${videoId}`;
      }
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://embed.spotify.com/?theme=0&uri=${videoId}`);
    }

    if (media && media.socialMediaId === 9) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://player.vimeo.com/video/${media.url.split('.com/')[1]}`,
      );
    }

    if (media && media.socialMediaId === 10) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.mixcloud.com/widget/iframe/?hide_cover=1&&light=1&feed=/${media.url.split('.com/')[1]}`,
      );
    }

    return '';
  }
}
