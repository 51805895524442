import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BackArrowTitleComponent } from '../components/back-arrow-title/back-arrow-title.component';
import { InputModule } from '../input/input.module';
import { SharedModule } from '../shared/shared.module';
import { AddGigModalComponent } from './components/add-gig-modal/add-gig-modal.component';
import { AddGigComponent } from './components/add-gig/add-gig.component';
import { ApplicationCardComponent } from './components/application-card/application-card.component';
import { PlacesCheckListComponent } from './components/filter/places-check-list/places-check-list.component';
import { SortDropdownComponent } from './components/filter/sort-dropdown/sort-dropdown.component';
import { TagButtonsComponent } from './components/filter/tag-buttons/tag-buttons.component';
import { TypeButtonsComponent } from './components/filter/type-buttons/type-buttons.component';
import { GigApplicationSummaryComponent } from './components/gig-application-summary/gig-application-summary.component';
import { GigApplicationComponent } from './components/gig-application/gig-application.component';
import { GigApplyComponent } from './components/gig-apply/gig-apply.component';
import { GigBookingComponent } from './components/gig-booking/gig-booking.component';
import { GigCardRowComponent } from './components/gig-card-row/gig-card-row.component';
import { GigCardComponent } from './components/gig-card/gig-card.component';
import { GigStatusComponent } from './components/gig-status/gig-status.component';
import { GigsBookingBarComponent } from './components/gigs-booking-bar/gigs-booking-bar.component';
import { GigsHeaderComponent } from './components/gigs-header/gigs-header.component';
import { GigsNavbarComponent } from './components/gigs-navbar/gigs-navbar.component';
import { PreviousGigItemComponent } from './components/previous-gig-item/previous-gig-item.component';
import { PreviousGigModalComponent } from './components/previous-gig-modal/previous-gig-modal.component';
import { SelectApplicantsComponent } from './components/select-applicants/select-applicants.component';
import { UserGigsListComponent } from './components/user-gigs-list/user-gigs-list.component';
import { GigsRoutingModule } from './gigs-routing.module';
import { GigsComponent } from './gigs.component';
import { ApplicationsComponent } from './pages/applications/applications.component';
import { ApplyComponent } from './pages/apply/apply.component';
import { CreateGigFormDescriptionComponent } from './pages/create-gig-form/create-gig-form-description/create-gig-form-description.component';
import { CreateGigFormDetailsComponent } from './pages/create-gig-form/create-gig-form-details/create-gig-form-details.component';
import { CreateGigFormComponent } from './pages/create-gig-form/create-gig-form.component';
import { EditGigComponent } from './pages/edit-gig/edit-gig.component';
import { GigDetailsHeaderComponent } from './pages/gig-details/components/gig-details-header/gig-details-header.component';
import { GigDetailsOverviewComponent } from './pages/gig-details/components/gig-details-overview/gig-details-overview.component';
import { GigDetailsSlotDetailsComponent } from './pages/gig-details/components/gig-details-slot-details/gig-details-slot-details.component';
import { GigDetailsSlotsComponent } from './pages/gig-details/components/gig-details-slots/gig-details-slots.component';
import { GigEditBaseComponent } from './pages/gig-details/components/gig-edit-base/gig-edit-base.component';
import { GigEditSlotComponent } from './pages/gig-details/components/gig-edit-slot/gig-edit-slot.component';
import { GigDetailsComponent } from './pages/gig-details/gig-details.component';
import { GigsListComponent } from './pages/gigs-list/gigs-list.component';
import { ListHeaderComponent } from './pages/gigs-list/list-header/list-header.component';
import { GigsLoggedOutComponent } from './pages/gigs-logged-out/gigs-logged-out.component';
import { MyGigsListComponent } from './pages/my-gigs-list/my-gigs-list.component';
import { UserGigsComponent } from './pages/user-gigs/user-gigs.component';
import { CreateGigFormSummaryComponent } from './pages/create-gig-form/create-gig-form-summary/create-gig-form-summary.component';
@NgModule({
  declarations: [
    GigsListComponent,
    GigsComponent,
    GigDetailsComponent,
    GigsLoggedOutComponent,
    ApplicationsComponent,
    ApplicationCardComponent,
    GigsHeaderComponent,
    GigsNavbarComponent,
    GigDetailsHeaderComponent,
    GigDetailsOverviewComponent,
    GigDetailsSlotsComponent,
    GigDetailsSlotDetailsComponent,
    AddGigComponent,
    AddGigModalComponent,
    GigCardComponent,
    GigEditSlotComponent,
    GigEditBaseComponent,
    GigApplyComponent,
    GigApplicationComponent,
    SelectApplicantsComponent,
    UserGigsComponent,
    UserGigsListComponent,
    GigApplicationSummaryComponent,
    GigsBookingBarComponent,
    GigBookingComponent,
    CreateGigFormComponent,
    ApplyComponent,
    EditGigComponent,
    GigCardRowComponent,
    GigStatusComponent,
    MyGigsListComponent,
    ListHeaderComponent,
    PlacesCheckListComponent,
    TagButtonsComponent,
    PreviousGigModalComponent,
    PreviousGigItemComponent,
    TypeButtonsComponent,
    SortDropdownComponent,
    CreateGigFormDescriptionComponent,
    CreateGigFormDetailsComponent,
    CreateGigFormSummaryComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    FlexLayoutModule,
    GigsRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatToolbarModule,
    MatDialogModule,
    CloudinaryModule,
    MatDatepickerModule,
    MatStepperModule,
    MatIconModule,
    MatGoogleMapsAutocompleteModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatOptionModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatRadioModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatMenuModule,
    MatTabsModule,
    NgxSkeletonLoaderModule,
    IvyCarouselModule,
    MatSidenavModule,
    MatButtonToggleModule,
    InputModule,
  ],
  entryComponents: [
    AddGigModalComponent,
    GigEditSlotComponent,
    GigEditBaseComponent,
    GigApplyComponent,
    SelectApplicantsComponent,
    GigApplicationSummaryComponent,
    GigBookingComponent,
    BackArrowTitleComponent,
  ],
  exports: [TagButtonsComponent, GigStatusComponent, GigCardComponent],
})
export class GigsModule {}
