<app-info-bar
  *ngIf="pageProgressStep.denied"
  [infoType]="INFO_BAR_TYPE.Warning"
  [text]="pageProgressStep.denyReason"></app-info-bar>
<div *ngIf="!isLoading; else loadingTmplt" class="d--block container container-right">
  <h1 class="h6 f-weight--600 m-b--30" i18n>Media</h1>
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="tab-label" fxLayoutAlign="start center" fxLayoutGap="8px">
          <span i18n>Video</span>&nbsp;({{ video?.length || 0 }})
        </span>
      </ng-template>

      <div class="tab-container">
        <button class="gig-button stroked m-b--30" (click)="openMediaModal()" i18n>Add video</button>
        <app-artist-edit-media-video [videoArray]="video" (onMediaChanged$)="updatePageMedia()">
        </app-artist-edit-media-video>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="tab-label" fxLayoutAlign="start center" fxLayoutGap="8px">
          <span i18n>Audio</span>&nbsp;({{ audio?.length || 0 }})
        </span>
      </ng-template>

      <div class="tab-container">
        <button class="gig-button stroked m-b--30" (click)="openMediaModal()" i18n>Add audio</button>
        <app-artist-edit-media-audio [audioArray]="audio" (onMediaChanged$)="updatePageMedia()">
        </app-artist-edit-media-audio>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="tab-label" fxLayoutAlign="start center" fxLayoutGap="8px">
          <span i18n>Gallery</span>&nbsp;({{ gallery?.length || 0 }})
        </span>
      </ng-template>

      <div class="tab-container">
        <app-file-input
          title="Add photo"
          [requiredFileType]="'image/*'"
          (fileChange$)="addNewImagesToGallery($event)"
          class="d--block m-b--30"
          i18n-title></app-file-input>
        <app-artist-edit-media-gallery
          [gallery]="gallery"
          (onMediaChanged$)="updatePageGallery()"></app-artist-edit-media-gallery>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div fxLayoutAlign="space-between center" class="m-t--32">
    <button fxLayoutAlign="start center" class="gig-button transparent wide" (click)="goToStep$.emit(-1)">
      <mat-icon fxHide.lt-sm svgIcon="back-arrow"></mat-icon>
      <span i18n>Back</span>
    </button>
    <span fxFlex fxHide fxShow.lt-sm class="text-center">{{ PROGRESS_STEP_NUMBER.MEDIA }} <span i18n>of</span> 5</span>
    <button class="gig-button primary wide" (click)="goToStep$.emit(1)" i18n>Next</button>
  </div>
</div>

<ng-template #loadingTmplt>
  <div fxLayout fxLayoutAlign="center center" class="height--100per">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="48px">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template>
