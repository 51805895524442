import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  Booking,
  FindOnePageGQL,
  Gig,
  GigSlot,
  Page,
  PageLink,
  PageOwnership,
  PageReviews,
  Review,
  Tag,
} from '../../../../generated/graphql';
import { LONG_DATE } from '../../../helpers';
import { socialMediaId } from '../../../models/SocialMediaType';
import { GET_SELECTED_APPLICATIONS, SELECT_APPLICATION } from '../../../resolvers';

@Component({
  selector: 'app-gig-application-summary',
  templateUrl: './gig-application-summary.component.html',
  styleUrls: ['./gig-application-summary.component.scss'],
})
export class GigApplicationSummaryComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { gig: Gig; application: Booking; slot: GigSlot },
    @Inject(PLATFORM_ID) private platformId: object,
    private apollo: Apollo,
    private dialogRef: MatDialogRef<GigApplicationSummaryComponent>,
    private findOne: FindOnePageGQL,
  ) {}

  private reviewsEl: ElementRef<HTMLElement>;
  get observeReviewsElement() {
    return this.reviewsEl;
  }
  @ViewChild('rav', { static: false }) set observeReviewsElement(ref: ElementRef<HTMLElement>) {
    this.reviewsEl = ref;
    if (isPlatformBrowser(this.platformId) && this.reviewsCount > 0) {
      if (ref?.nativeElement && !this.observeReviews) {
        this.observeReviews = new IntersectionObserver(([entry]) => {
          this.isReviewsHighlit = entry.isIntersecting;
        });
        this.observeReviews.observe(ref.nativeElement);
      }
    }
  }
  private observeReviews: IntersectionObserver;
  isReviewsHighlit = false;

  pageTags: { type?: Tag; genres?: Tag[]; context?: Tag[]; mood?: Tag[]; location?: Tag } = {
    type: undefined,
    genres: [],
    context: [],
    mood: [],
    location: undefined,
  };

  dateFormat = LONG_DATE;

  video: PageLink = null;
  songs: PageLink[] = [];
  page: Observable<Page>;
  reviews: Observable<PageReviews>;
  reviewsCount = 0;
  unSelectedSaveButtonName = $localize`Select application`;
  selectedSaveButtonName = $localize`Deselect application`;
  showSaveButtonName = this.unSelectedSaveButtonName;
  PageOwnership = PageOwnership;

  reviewerNames: { [id: number]: string } = {};
  ngOnInit() {
    this.apollo
      .watchQuery({
        query: GET_SELECTED_APPLICATIONS,
      })
      .valueChanges.pipe(map(({ data }) => (data as any).selectedApplications))
      .subscribe((selectedApplications: [{ applicationId: number; slotId: number }]) => {
        // Select translation to show on the save button
        if (
          selectedApplications
            .filter((s) => s.slotId === this.data.slot.id)
            .map((s) => s.applicationId)
            .includes(this.data.application.id)
        ) {
          this.showSaveButtonName = this.selectedSaveButtonName;
        } else {
          this.showSaveButtonName = this.unSelectedSaveButtonName;
        }
      });

    const findQuery = this.findOne.watch(
      { pageId: this.data.application.page.id, paging: { skip: 0, limit: 3 } },
      { fetchPolicy: 'no-cache', nextFetchPolicy: 'no-cache' },
    );

    this.page = findQuery.valueChanges.pipe(
      map((d) => {
        this.setPageTags(d.data.findOnePage);

        if (d.data.findOnePage.links) {
          this.video = this.selectVideo(d.data.findOnePage);
          this.songs = d.data.findOnePage.links.filter(
            (l) =>
              l.socialMediaId === socialMediaId.SOUND_CLOUD ||
              l.socialMediaId === socialMediaId.SPOTIFY ||
              l.socialMediaId === socialMediaId.MIX_CLOUD,
          );
        }
        return d.data.findOnePage;
      }),
      tap((p) => {
        this.reviewsCount = p.positiveReviews;
      }),
    );

    this.reviews = findQuery.valueChanges.pipe(
      map((d) => d.data.getPageReviews),
      tap((d) => {
        for (const review of d.reviews as Review[]) {
          this.reviewerNames[review.id] = this.getReviewerInitials(review);
        }
      }),
    );
  }

  private selectVideo(page: Page) {
    const videos = page.links.filter(
      (l) => l.socialMediaId === socialMediaId.YOUTUBE || l.socialMediaId === socialMediaId.VIMEO,
    );
    let selected = videos.find((l) => l.isHighlight);
    if (!selected) {
      selected = videos[0];
    }

    return selected;
  }

  onSave() {
    const application = {
      applicationId: this.data.application.id,
      slotId: this.data.slot.id,
      dateId: this.data.application.dates.find((d) => d.gigSlot.id === this.data.slot.id).id,
    };
    const sub = this.apollo
      .mutate({
        mutation: SELECT_APPLICATION,
        variables: { application },
      })
      .subscribe();

    sub.unsubscribe();

    this.dialogRef.close();
  }

  private setPageTags(page: Page): void {
    // Wtf why does a page that has applied not have tags?
    if (!page.tags) {
      return;
    }

    page.tags.forEach((t) => {
      if (t.group === 4) {
        this.pageTags.type = t;
      }
      if (t.group === 1) {
        this.pageTags.genres.push(t);
      }
      if (t.group === 2) {
        this.pageTags.mood.push(t);
      }
      if (t.group === 3) {
        this.pageTags.context.push(t);
      }
      if (t.group === 6) {
        this.pageTags.location = t;
      }
    });
  }

  getReviewerInitials(review: Review) {
    if (review.user) {
      return `${review.user.firstName.charAt(0).toUpperCase()} ${review.user.lastName.charAt(0).toUpperCase()}`;
    }

    if (review.reviewer) {
      const split = review.reviewer.name.split(' ');
      if (split.length > 1) {
        return `${split[0].charAt(0).toUpperCase()} ${split[split.length - 1].charAt(0).toUpperCase()}`;
      } else {
        return review.reviewer.name.charAt(0).toUpperCase();
      }
    }

    return '';
  }

  scrollToElement(toReviews: boolean, el?: HTMLDivElement) {
    // Why the fuck can we not call this method the same way for reviews as for the application in the template???
    // Makes no god damn sense and I have to write these kinds of bullshit methods to handle it
    let _el: HTMLElement;
    if (toReviews) {
      _el = this.observeReviewsElement.nativeElement;
    } else {
      _el = el;
    }
    _el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
}
