<div class="container">
  <main *ngIf="page$ | async as page; else loading">
    <ng-container *ngIf="reviewsListFullInfo$ | async as reviewsInfo">
      <div fxLayoutGap="116px" fxLayout.lt-md="column" fxLayoutGap.lt-md="32px" class="artist-details-header">
        <div class="artist-profile" fxFlex="312px" fxFlex.lt-md="grow" fxLayout="column" fxLayoutAlign="start center">
          <app-avatar
            [profilePicture]="page.profilePicture"
            [specSize]="213"
            class="artist-profile--avatar m-b--16"></app-avatar>
          <span *ngIf="isFastResponder" fxLayoutAlign="center center" class="fast-responder-badge">
            <mat-icon svgIcon="light-yellow"></mat-icon>
            <span i18n>Fast responder</span>
          </span>
          <h1 class="artist-profile--name">{{ page.name }}</h1>
          <p class="artist-profile--address">{{ page.placeData?.formattedAddress }}</p>

          <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="8px" class="artist-profile--main-tags">
            <span *ngIf="page.category" class="tag m-b--8">{{ PAGE_CATEGORY_TITLE[page.category] }}</span>
            <span *ngFor="let tag of getFilteredTags(page.tags, PAGE_TAG_GROUP.PERFORMER_TYPE)" class="tag m-b--8">
              {{ tag.translatedName }}
            </span>
            <span *ngFor="let tag of getFilteredTags(page.tags, PAGE_TAG_GROUP.MUSIC_TYPE)" class="tag m-b--8">
              {{ tag.translatedName }}
            </span>
            <span *ngIf="getFilteredTags(page.tags, PAGE_TAG_GROUP.NEW_GENRE).length > 0" class="tag m-b--8">
              {{ getFilteredTags(page.tags, PAGE_TAG_GROUP.NEW_GENRE)[0].translatedName }}
            </span>
          </div>
          <button class="gig-button primary f-size--16" (click)="onSendRequestClick()" i18n>
            Send booking request
          </button>
        </div>
        <app-artist-media fxFlex="grow" [mediaLinks]="page.links" [gallery]="page.gallery"></app-artist-media>
      </div>

      <div class="artist-details">
        <nav class="artist-details--navigation" fxHide.lt-md>
          <a i18n>Media</a>
          <a (click)="scrollToOverviewSection()" i18n>Overview</a>
          <a
            *ngIf="reviewsInfo?.totalReviews > 0 || (reviewsInfo?.totalReviews <= 0 && isPreviewMode)"
            (click)="scrollToReviewsSection()">
            <span i18n>Reviews</span> <span> ({{ reviewsInfo.totalReviews }})</span>
          </a>
          <ng-container *ngIf="page?.faq?.length > 0 || (page?.faq?.length <= 0 && isPreviewMode)">
            <a (click)="scrollToFaqSection()" i18n>FAQ</a>
          </ng-container>
        </nav>

        <section
          #overviewSection
          class="artist-details--section"
          [ngClass]="{ last: !page?.faq?.length && reviewsInfo?.totalReviews <= 0 && !isPreviewMode }">
          <div
            class="m-b--40"
            fxLayoutAlign="space-between start"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start stretch">
            <h2 class="h5 f-weight--600" i18n>Overview</h2>
            <div fxFlex="760px" fxFlex.lt-md="grow" class="details-item--info">
              <app-artist-overview [page]="page"></app-artist-overview>
            </div>
          </div>

          <div class="details-item" fxLayoutAlign="end start">
            <div fxFlex="760px" fxFlex.lt-md="grow" class="details-item--info">
              <p class="presentation m-b--32">{{ page.presentation }}</p>
              <p fxLayout.lt-md="column" fxLayoutGap.lt-md="16px">
                <span>
                  <span *ngIf="page.category" class="tag m-b--8">{{ PAGE_CATEGORY_TITLE[page.category] }}</span>
                  <span *ngFor="let tag of page.tags" class="tag m-b--8"> {{ tag.translatedName }}</span>
                </span>
              </p>
            </div>
          </div>

          <div
            *ngIf="checkIfDisplayBadges(page)"
            class="details-item m-t--64"
            fxLayoutAlign="space-between start"
            fxLayout.lt-md="column">
            <h2 fxHide.lt-md class="details-item--title" i18n>Badges</h2>
            <h2 fxHide fxShow.lt-md class="details-item--title m-b--30" i18n>Badges on Gigital</h2>
            <div fxFlex="760px" fxFlex.lt-md="grow" class="details-item--info">
              <div fxLayout="row wrap" fxLayoutGap="24px" class="badges-list">
                <ng-container *ngFor="let badge of page.badges">
                  <app-artist-badge *ngIf="badge !== PAGE_BADGES.FastResponder" [badge]="badge"></app-artist-badge>
                </ng-container>
              </div>
            </div>
          </div>
        </section>

        <section
          *ngIf="reviewsInfo.totalReviews > 0 || (reviewsInfo.totalReviews <= 0 && isPreviewMode)"
          [ngClass]="{ last: page?.faq?.length <= 0 && !isPreviewMode }"
          class="artist-details--section"
          #reviewsSection>
          <div class="reviews" fxLayout.lt-md="column" fxLayoutAlign="space-between start">
            <h2 fxHide.lt-md class="details-item--title">
              <span i18n>Reviews</span>&nbsp;
              <span *ngIf="reviewsInfo.totalReviews > 0">({{ reviewsInfo.totalReviews }})</span>
            </h2>
            <h2 fxHide fxShow.lt-md class="details-item--title"
              >{{ reviewsInfo.totalReviews }} <span i18n>reviews</span>
            </h2>
            <div fxFlex="760px" fxFlex.lt-md="grow" class="details-item--info reviews-lists">
              <app-reviews-list
                *ngIf="reviewsInfo.totalReviews > 0; else noReviews"
                [totalReviews]="reviewsInfo.totalReviews"
                [hasNextPage]="reviewsInfo.hasNextPage"
                [take]="reviewsInfo.reviewsTake"
                [skip]="reviewsInfo.reviewsSkip"
                [reviews$]="reviewsList$"
                [page]="page">
              </app-reviews-list>

              <ng-template #noReviews>
                <span class="f-size--16 missing-data-color" i18n>You have not collected any reviews yet</span>
              </ng-template>
            </div>
          </div>
        </section>

        <section
          #faqSection
          *ngIf="page?.faq?.length > 0 || (page?.faq?.length <= 0 && isPreviewMode)"
          class="artist-details--section last">
          <div class="details-item reviews" fxLayoutAlign="space-between start" fxLayout.lt-md="column">
            <h2 class="details-item--title faq"> <span i18n>FAQ</span></h2>
            <div fxFlex="760px" fxFlex.lt-md="grow" fxLayout="column" fxLayoutGap="16px" class="details-item--info">
              <ng-container *ngIf="page.faq?.length > 0; else noFaqs">
                <app-artist-faq-question *ngFor="let pageFaqAnswer of page.faq" [pageFaqAnswer]="pageFaqAnswer">
                </app-artist-faq-question>
              </ng-container>
              <ng-template #noFaqs>
                <span class="f-size--16 missing-data-color" i18n>You have not created a FAQ yet</span>
              </ng-template>
            </div>
          </div>
        </section>
      </div>
    </ng-container>
  </main>
</div>

<ng-template #loading>
  <div class="container">
    <ngx-skeleton-loader
      [theme]="{
        width: '100%',
        height: '430px',
        'border-radius': '4px',
        'background-color': '#fafafa',
        'margin-bottom': 0
      }">
    </ngx-skeleton-loader>
    <div fxLayout="row" fxLayoutGap="50px" fxLayoutAlign="start end">
      <ngx-skeleton-loader
        [theme]="{
          width: '340px',
          height: '340px',
          position: 'relative',
          top: '-180px',
          left: '100px',
          'border-radius': '4px',
          'background-color': '#fafafa',
          'margin-bottom': '-120px'
        }">
      </ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '400px',
          height: '50px',
          position: 'relative',
          top: '-235px',
          left: '100px',
          'border-radius': '4px',
          'background-color': '#fafafa',
          'margin-bottom': '-120px'
        }">
      </ngx-skeleton-loader>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="50px grid" class="loading">
      <div fxFlex.gt-sm="30%">
        <ngx-skeleton-loader
          [theme]="{
            width: '100%',
            height: '430px',
            'border-radius': '4px',
            'background-color': '#fafafa',
            'margin-bottom': '50px'
          }">
        </ngx-skeleton-loader>
      </div>
      <div fxFlex.gt-sm="70%">
        <ngx-skeleton-loader
          [theme]="{
            width: '100%',
            height: '430px',
            'border-radius': '4px',
            'background-color': '#fafafa',
            'margin-bottom': '50px'
          }">
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>
