import { Booking, BookingDates, BookingStatusEnum, CalculatedPricing } from '../../generated/graphql';
import { apiToDate } from './dates';

export const isBookingRequest = (booking: Booking): boolean => {
  return (
    booking.status === BookingStatusEnum.Request ||
    booking.status === BookingStatusEnum.Pending ||
    booking.status === BookingStatusEnum.WaitingForOtherSign ||
    booking.status === BookingStatusEnum.Cancelled ||
    booking.status === BookingStatusEnum.Declined
  );
};

export const getBookingPrice = (dates: BookingDates[]): number => {
  return dates.reduce((previousValue, currentValue) => previousValue + currentValue.price, 0);
};

export const getFeeAmount = (dates: BookingDates[], fee: number): number => {
  return Math.round(getBookingPrice(dates) * (fee || 1.15) - getBookingPrice(dates));
};

export const displayableFee = (amount: number) => {
  return Math.abs(amount * 100 - 100).toPrecision(3);
};

export const getPriceAfterFees = (price: number, fee: number): number => {
  return Math.ceil(price * (fee || 1.15));
};

export const isBookingDateExpired = (bookingDate: BookingDates): boolean => {
  const today = new Date();
  const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  return apiToDate(bookingDate.date) < yesterday;
};

export const getBookerDatesSum = (booking: Booking): number => {
  return booking?.dates.reduce((a, b) => a + Math.round(b.price * (booking.calculatedPricing?.booker?.fee || 1.15)), 0);
};

export const getArtistDatesSum = (booking: Booking): number => {
  return booking?.dates.reduce((a, b) => a + Math.round(b.price * (booking.calculatedPricing?.artist?.fee || 0.85)), 0);
};

export const getEquipmentFee = (calculatedPricing: CalculatedPricing): number => {
  return Math.round(
    calculatedPricing.supplies * (calculatedPricing?.booker?.fee || 1.15) - calculatedPricing?.supplies,
  );
};

export const getDatesPriceSum = (dates: BookingDates[]): number => {
  return dates.reduce((a, b) => a + b.price, 0);
};
