import { isPlatformBrowser } from '@angular/common';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router, RouterModule, Routes } from '@angular/router';
import { asyncScheduler, fromEvent } from 'rxjs';
import { debounceTime, filter, observeOn, scan, tap } from 'rxjs/operators';
import { AuthorizedComponent } from './components/authorized.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { LoggedOutGuard } from './guards/logged-out.guard';
import { NotificationsComponent } from './notifications/pages/notifications/notifications.component';
import { CancellationPolicyComponent } from './pages/cancellation-policy/cancellation-policy.component';
import { CompanyComponent } from './pages/company/company.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { ForArtistsComponent } from './pages/for-artists/for-artists.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeComponent } from './pages/home/home.component';
import { HowItWorksWorkComponent } from './pages/how-it-works/how-it-works.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HarrysComponent } from './pages/partners/harrys/harrys.component';
import { NordicChoiceComponent } from './pages/partners/nordic-choice/nordic-choice.component';
import { PhilsBurgerComponent } from './pages/partners/phils-burger/phils-burger.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SignupComponent } from './pages/signup/signup.component';
import { TosComponent } from './pages/tos/tos.component';
import { AuthService } from './services/auth.service';

export const routes: Routes = [
  {
    path: 'bookings',
    loadChildren: () => import('./bookings/bookings.module').then((m) => m.BookingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'artists',
    loadChildren: () => import('./artists/artists.module').then((m) => m.ArtistsModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reviews',
    loadChildren: () => import('./reviews/reviews.module').then((m) => m.ReviewsModule),
    canActivate: [AuthGuard],
  },
  { path: 'gigs', loadChildren: () => import('./gigs/gigs.module').then((m) => m.GigsModule) },
  { path: 'invite', loadChildren: () => import('./invites/invites.module').then((m) => m.InvitesModule) },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard],
  },
  { path: 'contracts', loadChildren: () => import('./contracts/contracts.module').then((m) => m.ContractsModule) },
  {
    path: 'notifications',
    component: NotificationsComponent,
  },
  { path: 'authorized', component: AuthorizedComponent },
  { path: 'login', component: LoginComponent, canActivate: [LoggedOutGuard] },
  { path: 'signup', component: SignupComponent, canActivate: [LoggedOutGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [LoggedOutGuard] },
  { path: 'legal/cancellation', component: CancellationPolicyComponent },
  { path: 'legal/privacy', component: PrivacyComponent },
  { path: 'legal/tos', component: TosComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'customers', component: CustomersComponent },
  { path: 'for-artists', component: ForArtistsComponent },
  { path: 'strawberry', component: NordicChoiceComponent }, // nordic choice is now called strawberry
  { path: 'phils-burger', component: PhilsBurgerComponent },
  { path: 'harrys', component: HarrysComponent },
  { path: 'how-it-works', component: HowItWorksWorkComponent },
  { path: 'company', component: CompanyComponent },
  { path: '', component: HomeComponent },
  { path: '**', component: NotFoundComponent },
];

interface ScrollPositionRestore {
  event: Event;
  positions: { [K: number]: number };
  trigger: 'imperative' | 'popstate' | 'hashchange';
  idToRestore: number;
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  isBrowser: boolean;
  constructor(private router: Router, private auth: AuthService, @Inject(PLATFORM_ID) private platformId: object) {
    this.isBrowser = isPlatformBrowser(this.platformId);

    if (this.isBrowser) {
      // Shamelessly stolen from https://github.com/angular/angular/issues/24547#issuecomment-469351520
      let lastScrollTop = 0;
      fromEvent(window, 'scroll')
        .pipe(
          debounceTime(50),
          tap(() => {
            lastScrollTop = document.querySelector('html').scrollTop;
          }),
        )
        .subscribe();

      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd),
          scan<Event, ScrollPositionRestore>((acc, event) => {
            if (event instanceof NavigationEnd) {
              this.auth.urlPool.push(event.url);
            }
            return {
              event,
              positions: {
                ...acc.positions,
                ...(event instanceof NavigationStart
                  ? {
                      [event.id]: lastScrollTop,
                    }
                  : {}),
              },
              trigger: event instanceof NavigationStart ? event.navigationTrigger : acc.trigger,
              idToRestore:
                (event instanceof NavigationStart && event.restoredState && event.restoredState.navigationId + 1) ||
                acc.idToRestore,
            };
          }),
          filter(({ event, trigger }) => event instanceof NavigationEnd && !!trigger),
          observeOn(asyncScheduler),
        )
        .subscribe(({ event, trigger, positions, idToRestore }) => {
          if ((event as any).url.indexOf('/artists/search?') > -1) {
            return;
          }
          if (trigger === 'imperative') {
            document.querySelector('html').scrollTop = 0;
          }

          if (trigger === 'popstate') {
            document.querySelector('html').scrollTop = positions[idToRestore];
          }
        });
    }
  }
}
