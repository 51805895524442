import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Booking, BookingDates, Gig, GigSlot, PageOwnership } from '../../../../generated/graphql';
import { GET_SELECTED_APPLICATIONS, SELECT_APPLICATION } from '../../../resolvers';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';
import { PopupService } from '../../../services/popup.service';
import { GigApplicationSummaryComponent } from '../gig-application-summary/gig-application-summary.component';

@Component({
  selector: 'app-gig-application',
  templateUrl: './gig-application.component.html',
  styleUrls: ['./gig-application.component.scss'],
})
export class GigApplicationComponent implements OnInit {
  @Input()
  gig: Gig;

  @Input()
  application: Booking;

  @Input()
  slot: GigSlot;

  @Input()
  bookingId: number;

  date: BookingDates;
  selected = false;
  PageOwnership = PageOwnership;

  constructor(
    private iconsService: IconsRegistryService,
    private popups: PopupService,
    private apollo: Apollo,
    private router: Router,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  ngOnInit(): void {
    this.date = this.application.dates.find((d) => d.gigSlot && d.gigSlot.id === this.slot.id);
    this.apollo
      .watchQuery({
        query: GET_SELECTED_APPLICATIONS,
      })
      .valueChanges.pipe(map(({ data }) => (data as any).selectedApplications))
      .subscribe((selectedApplications: [{ applicationId: number; slotId: number; dateId: number }]) => {
        const slot: boolean = !!selectedApplications.find((a) => a.slotId === this.slot.id);
        const application: boolean = !!selectedApplications.find((a) => a.applicationId === this.application.id);
        const isThisDate: boolean = this.date && !!selectedApplications.find((a) => a.dateId === this.date.id);
        this.selected = slot && application && isThisDate;
      });
  }

  previewRef: MatDialogRef<GigApplicationSummaryComponent, any>;
  openApplication(): void {
    if (!this.bookingId) {
      this.previewRef = this.popups.open(GigApplicationSummaryComponent, '800px', {
        panelClass: 'gigital-modal',
        data: { gig: this.gig, application: this.application, slot: this.slot },
      });
    } else {
      this.router.navigate(['/bookings', this.bookingId]);
    }
  }

  onSaveModal() {
    this.selectApplication();
    this.previewRef.close();
  }

  selectApplication(): void {
    if (!this.bookingId) {
      const application = {
        applicationId: this.application.id,
        slotId: this.slot.id,
        dateId: this.application.dates.find((d) => d.gigSlot.id === this.slot.id).id,
      };
      const sub = this.apollo
        .mutate({
          mutation: SELECT_APPLICATION,
          variables: { application },
        })
        .subscribe();

      sub.unsubscribe();
    } else {
      this.router.navigate(['/bookings', this.bookingId]);
    }
  }

  acceptApplication() {
    // this.accept
    //   .mutate({ applicationId: this.application.id, message: this.application.message })
    //   .subscribe(console.log);
  }

  denyApplication() {
    // this.deny.mutate({ applicationId: this.application.id, slotId: this.slot.id }).subscribe(console.log);
  }
}
