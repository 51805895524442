<!-- Overview info, name/place/type -->
<div fxLayout="column" class="padded">
  <div class="gigs-container gig-label">
    <label class="label {{ meta.color }}">{{ translations.gigType(gig.type).translation }}</label>
  </div>
  <div class="gigs-container">
    <h1 class="gig-name" *ngIf="gig.name">{{ gig.name }}</h1>
    <h1 class="gig-name" *ngIf="gig.limitedData" i18n>Gig in {{ locationName }}</h1>
  </div>
  <div
    fxLayout="row"
    class="gigs-container location"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    *ngIf="!gig.limitedData">
    <mat-icon class="icon" svgIcon="compass"></mat-icon>
    <a [href]="googleMapsUrl" target="_blank">{{ gig.placeData?.formattedAddress || gig.placeData?.name }}</a>
  </div>
  <div class="limited-image-spacer" *ngIf="gig.limitedData"></div>

  <!-- Mobile carousel -->
  <div fxHide fxShow.lt-md *ngIf="gig.images.length > 0">
    <carousel *ngIf="gig.images.length > 1 && isBrowser" [arrows]="false" [dots]="true" [cellWidth]="'100%'">
      <div class="carousel-cell" *ngFor="let image of displayImagesUrl">
        <div class="cell-image" [style.background-image]="image"></div>
      </div>
    </carousel>
    <img
      *ngIf="gig.images.length === 1 || (gig.images?.length > 0 && !isBrowser)"
      class="fallback-image"
      [src]="fallbackImageUrl" />
  </div>

  <div class="gigs-container" fxLayout="row">
    <!-- no images description -->
    <div
      fxLayout="column"
      fxLayoutGap="24px"
      fxLayoutAlign="space-between"
      fxFlex.gt-md="64%"
      *ngIf="gig.images.length === 0">
      <div class="description no-margin-top">
        <h2 i18n>About gig</h2>
        <span class="body" *ngIf="gig.details">{{ gig.details }}</span>
        <span class="body" *ngIf="gig.limitedData" i18n
          >Only verified artist profiles have full access to this gig. Log in to apply to the gig or manage your artist
          profile.</span
        >
      </div>

      <div
        *ngIf="!gig.limitedData"
        fxLayout="row wrap"
        fxLayoutGap="100px"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="0"
        class="eq-section">
        <div fxLayout="column" fxLayoutGap="26px" class="m-b--40">
          <span i18n class="equipment-title">Equipment on site</span>
          <app-equipment-icons [equipments]="gig.supplies"></app-equipment-icons>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-between start" class="eq-section--request m-b--40">
          <span fxLayoutAlign="start center" fxLayoutGap="4px" class="equipment-title">
            <span i18n>Equipment request</span>
            <mat-icon
              svgIcon="info-fill"
              matTooltip="The artist is expected to bring equipment for an additional fee"
              class="info-icon"
              i18n-matTooltip>
            </mat-icon>
          </span>
          <span *ngIf="gig.isRequestingSupplies" class="text-uppercase" i18n>Yes</span>
          <span *ngIf="!gig.isRequestingSupplies" class="text-uppercase" i18n>No</span>
        </div>
      </div>
    </div>

    <!-- desktop carousel -->
    <div
      fxHide.lt-md
      *ngIf="gig.images.length > 1 && isBrowser"
      class="carousel-container"
      fxFlex="66%"
      fxLayout="column">
      <carousel [dots]="true" [cellWidth]="'100%'">
        <div class="carousel-cell" *ngFor="let image of displayImagesUrl">
          <div class="cell-image" [style.background-image]="image"></div>
        </div>
      </carousel>
    </div>
    <div fxHide.lt-md fxFlex="66%" *ngIf="gig.images.length === 1 || (gig.images?.length > 0 && !isBrowser)">
      <img class="fallback-image" [src]="fallbackImageUrl" />
    </div>
    <div fxHide.lt-md class="carousel-spacer"> </div>
    <div fxHide.lt-md fxFlex="33%" class="info-card-container">
      <ng-container *ngTemplateOutlet="infoCard"></ng-container>
    </div>
  </div>

  <!-- yes images description -->
  <div class="gigs-container" *ngIf="gig.images.length > 0">
    <div fxLayout="column" fxLayoutGap="24px" fxFlex.gt-md="64%">
      <div class="description">
        <h2 i18n>About gig</h2>
        <span class="body" *ngIf="gig.details">{{ gig.details }}</span>
        <span class="body" *ngIf="gig.limitedData" i18n
          >Only verified artist profiles have full access to this gig. Log in to apply to the gig or manage your artist
          profile.</span
        >
      </div>
      <div
        fxLayoutAlign="start space-between"
        fxLayoutGap="100px"
        fxLayout.lt-md="column"
        fxLayoutGap.lt-md="40px"
        *ngIf="!gig.limitedData && gig.supplies?.length > 0">
        <div fxLayout="column" fxLayoutGap="26px">
          <span i18n class="equipment-title">Equipment on site</span>
          <app-equipment-icons [equipments]="gig.supplies"></app-equipment-icons>
        </div>

        <div fxFlex="190px" fxFlex.lt-md="auto" fxLayout="column" fxLayoutAlign="space-between start">
          <span fxLayoutAlign="start center" fxLayoutGap="4px">
            <span class="equipment-title" i18n>Equipment request</span>
            <mat-icon
              svgIcon="info-fill"
              matTooltip="The artist is expected to bring equipment for an additional fee"
              class="info-icon"
              i18n-matTooltip>
            </mat-icon>
          </span>
          <span *ngIf="gig.isRequestingSupplies" class="text-uppercase" i18n>Yes</span>
          <span *ngIf="!gig.isRequestingSupplies" class="text-uppercase" i18n>No</span>
        </div>
      </div>
    </div>
  </div>

  <div fxHide fxShow.lt-md class="gigs-container info-card-container mobile">
    <ng-container *ngTemplateOutlet="infoCard"></ng-container>
  </div>
</div>

<ng-template #infoCard>
  <div class="info-card" fxLayout="column" fxLayoutGap="30px">
    <div fxLayout="row" fxLayoutGap="20px" *ngIf="!gig.limitedData">
      <mat-icon class="info-section icon calendar" svgIcon="calendar-fill-gray"></mat-icon>
      <div fxLayout="column" fxLayoutGap="4px">
        <span class="info-section header" i18n>Event date</span>
        <span *ngIf="dateState === 'single'" class="info-section content">{{ firstDate | date: 'd MMM y' }}</span>
        <span *ngIf="dateState === 'multi'" class="info-section content"
          >{{ firstDate | date: 'd' }} - {{ lastDate | date: 'd MMM y' }}</span
        >
        <span *ngIf="dateState === 'multiMonth'" class="info-section content"
          >{{ firstDate | date: 'd MMM' }} - {{ lastDate | date: 'd MMM y' }}</span
        >
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-icon class="info-section icon" svgIcon="user-fill-gray"></mat-icon>
      <div fxLayout="column" fxLayoutGap="4px">
        <span class="info-section header" i18n>Looking for</span>
        <span class="info-section content">{{ lookingFor }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-icon class="info-section icon" svgIcon="microphone-fill-gray"></mat-icon>
      <div fxLayout="column" fxLayoutGap="4px">
        <span class="info-section header" i18n>Acts available</span>
        <span class="info-section content">{{ openSlots }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-icon class="info-section icon" svgIcon="budget-fill-gray"></mat-icon>
      <div fxLayout="column" fxLayoutGap="4px">
        <span class="info-section header" i18n>Total Budget</span>
        <span class="info-section content" *ngIf="!gig.limitedData && !gig.slots[0]?.priceHidden"
          >{{ totalBudget }} SEK</span
        >
        <span
          class="info-section content"
          *ngIf="gig.slots[0]?.priceHidden || gig.limitedData"
          i18n
          fxLayout="row"
          fxLayoutAlign="start center"
          >Non-public&nbsp;<img
            matTooltip="Only visible for verified artists"
            i18n-matTooltip
            src="assets/icons/actions/question-fill.svg"
            class="non-public-hover"
        /></span>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px" *ngIf="gig.isOwner">
      <a class="gig-button primary" i18n [routerLink]="['edit']">Edit details</a>
      <button
        class="gig-button stroked"
        i18n
        *ngIf="gig.isOwner && !gig.deleted"
        (click)="update(true)"
        [disabled]="loading"
        >Close gig</button
      >
      <button
        class="gig-button stroked"
        i18n
        *ngIf="gig.isOwner && gig.deleted"
        (click)="update(false)"
        [disabled]="loading"
        fxLayout="row"
        fxLayoutAlign="center center"
        >Open gig
        <mat-spinner *ngIf="loading" [diameter]="16"></mat-spinner>
      </button>
      <a (click)="scrollToSlots()" class="gig-button primary" i18n *ngIf="!gig.isOwner && canApply | async"
        >Apply to gig</a
      >
    </div>
  </div>
</ng-template>
