import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-radio-buttons-input',
  templateUrl: './radio-buttons-input.component.html',
  styleUrls: ['./radio-buttons-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonsInputComponent),
      multi: true,
    },
  ],
})
export class RadioButtonsInputComponent implements ControlValueAccessor, OnInit {
  @Input() options: { name: string; value: any };

  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;

  @Input() label: string = '';
  @Input() isLightLabel: false;
  @Input() labelTooltip: string = '';

  public _value: string;

  public get value() {
    return this._value;
  }

  public set value(value) {
    this._value = value;

    this.onTouch(value);
    this.onChange(value);
  }

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.INPUTS]);
  }

  public ngOnInit(): void {}

  public writeValue(value: any): void {
    this.value = value;
  }

  private onChange: any = () => {};
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  private onTouch: any = () => {};
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public inputChanges(event) {
    let value: string = event.target.value;
    this.value = value;
  }
}
