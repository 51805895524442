import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  apiError: string;

  constructor(private fb: FormBuilder, private auth: AuthService, private meta: MetaService) {}

  ngOnInit(): void {
    this.meta.setLocalizedTitle({
      en: 'Log in | Entertainment for you event | Gigital',
      'sv-se': 'Logga in | Underhållning till ditt event | Gigital',
    });

    this.meta.setLocalizedDescription({
      en: `Log in to your Gigital account. 200 verified artists, DJ's and bands. Find and book your favorite. Free registration. Safe bookings.`,
      'sv-se':
        'Logga in på ditt Gigital-konto. Hitta och boka över 200 artister, band och DJs till ditt event. Gratis registrering - trygg och säker bokning.',
    });

    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  get username(): AbstractControl {
    return this.loginForm.get('username');
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  onSubmit(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.apiError = undefined;

    const callback = error => {
      this.loading = false;
      this.apiError = error.description;
    };

    this.auth.login(this.username.value, this.password.value, callback);
  }

  onSocialLogin(platform?: string): void {
    this.auth.socialLogin(platform);
  }
}
