import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-artist-list-filter-menu-item',
  templateUrl: './artist-list-filter-menu-item.component.html',
  styleUrls: ['./artist-list-filter-menu-item.component.scss'],
})
export class ArtistListFilterMenuItemComponent implements OnInit {
  @Output() closeFilter$: EventEmitter<any> = new EventEmitter();
  @Output() doSearch$: EventEmitter<any> = new EventEmitter();

  @Input() filterablePlaces: any;
  @Input() filterTitle: string;
  @Input() filterOptions: any;

  public get filterSections(): any[] {
    return this.filterOptions.sections;
  }

  constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  public ngOnInit(): void {
    this.filterOptions.sections.forEach((section) => {
      section.numberToShow = 6;
      section.isOpen = false;
    });
  }

  public getOptionLabel(option): string {
    let label = option?.translatedName || option?.name || option?.longName;
    if (!!label) {
      label = label;
    }
    return label;
  }

  public openFilterSection(section: any): void {
    section.numberToShow = section.options.length;
    section.isOpen = !section.isOpen;
  }

  public applyFilters(): void {
    this.doSearch$.emit();
    this.closeFilter();
  }

  public clearAll(): void {
    this.filterOptions.sections.forEach((section) => section.options.map((option) => (option.active = false)));
  }

  public closeFilter(): void {
    this.closeFilter$.emit(null);
  }
}
