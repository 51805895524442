import { Page, ReviewStatusEnum } from '../generated/graphql';

export const isPageSearchable = (page: Page) => {
  return page.reviewStatus === ReviewStatusEnum.Approved;
};

export const getSimpleReviewText = (page: Page) => {
  // emojikungen
  const total = page.positiveReviews + page.negativeReviews;
  const pct = (page.positiveReviews / total) * 100;
  return `(${page.positiveReviews}/${total})${!isNaN(pct) ? pct.toFixed(1) : 0}% 👍🏼`;
};

export const getIdOrSlug = (test: string) => {
  if (/^-?\d+$/.test(test)) {
    return { pageId: parseInt(test, 10) };
  }
  return { slug: test };
};
