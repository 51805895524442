import { Component, Input, OnInit } from '@angular/core';
import { Page, ReviewStatusEnum } from '../../../../generated/graphql';
import { ArtistProgress } from '../../../models/ArtistProgress';
import { ArtistProgressService } from '../../../services/artist-progress.service';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-artist-profile-card',
  templateUrl: './artist-profile-card.component.html',
  styleUrls: ['./artist-profile-card.component.scss'],
})
export class ArtistProfileCardComponent implements OnInit {
  @Input() page: Page;

  get artistProgress(): ArtistProgress {
    return this.artistService.getArtistProgress(this.page);
  }

  get deniedSteps(): string {
    return this.artistProgress.steps
      .filter((step) => step.denied)
      .map((step) => step.stepNumber)
      .join(',');
  }

  ReviewStatusEnum = ReviewStatusEnum;

  constructor(private artistService: ArtistProgressService, private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  ngOnInit(): void {}
}
