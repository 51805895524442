<div class="artist-list-item-video">
  <header fxLayout fxLayoutAlign="space-between center">
    <h1 class="mat-display-1 white" i18n
      >Viewing video by <a [routerLink]="['/artists', data.page.slug]">{{ data.page.name }}</a></h1
    >
    <button class="artist-list-item-video-close" mat-icon-button (click)="dialogRef.close()"
      ><mat-icon>close</mat-icon>t</button
    >
  </header>
  <main class="video-16-9">
    <div class="video-16-9-loading" fxLayout fxLayoutAlign="center center">
      <mat-spinner [diameter]="64"></mat-spinner>
    </div>
    <iframe
      [src]="data.pageVideo | media"
      frameborder="0"
      allowfullscreen
      allow="encrypted-media"
      allowtransparency="true"
    ></iframe>
  </main>
</div>
