<section fxLayout="column" fxLayoutGap="16px" [formGroup]="filterGroup">
  <button [class.cursor-pointer]="filterGroup.get('allTags').enabled" (click)="toggleFormControl('allTags', $event)">
    <mat-checkbox color="primary" formControlName="allTags" i18n="filter all tag types">All</mat-checkbox>
  </button>
  <button [class.cursor-pointer]="filterGroup.get('djTags').enabled" (click)="toggleFormControl('djTags', $event)">
    <mat-checkbox color="primary" formControlName="djTags" i18n="filter by dj">Dj</mat-checkbox>
  </button>
  <button
    [class.cursor-pointer]="filterGroup.get('artistBandTags').enabled"
    (click)="toggleFormControl('artistBandTags', $event)">
    <mat-checkbox color="primary" formControlName="artistBandTags" i18n="filter by artist/band"
      >Artist, Band</mat-checkbox
    >
  </button>
</section>
