import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseQueryParams',
})
export class ParseQueryParamsPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): any {
    try {
      const parsedParams = JSON.parse(value);
      return parsedParams;
    } catch (e) {
      return {};
    }
  }
}
