<a
  [routerLink]="['/artists', page.slug]"
  class="artist-card"
  [class.artist-list-item--small]="small"
  [class.dark-mode]="darkMode">
  <div class="artist-card-header m-b--12">
    <div class="artist-card-header--image">
      <app-cloud-image
        class="profile-image"
        *ngIf="page.profilePicture"
        [profilePicture]="page.profilePicture"
        [altText]="page.name + 's profile picture'"
        i18n-altText></app-cloud-image>
      <div *ngIf="!page.profilePicture" class="profile-image-fallback"></div>
    </div>
    <a *ngIf="pageVideo" class="artist-card-header--video-link" (click)="showVideo($event)">
      <mat-icon svgIcon="play-video"></mat-icon>
    </a>
  </div>

  <h2 class="subtitle-1 f-weight--600 m-b--4">{{ page.name }}</h2>
  <p *ngIf="pageType" class="artist-card--type">{{ page?.category }}</p>
  <p class="artist-card--price">
    <span i18n>From</span>&nbsp;
    <span class="f-weight--600">{{ page.price }} SEK</span>
  </p>
</a>
