import { Component, Input, OnInit } from '@angular/core';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-home-landing-header',
  templateUrl: './home-landing-header.component.html',
  styleUrls: ['./home-landing-header.component.scss'],
})
export class HomeLandingHeaderComponent implements OnInit {
  @Input() homePageMetaData: any;
  @Input() userFlowSteps: any[];

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public ngOnInit(): void {}
}
