import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconSubsets, IconsRegistryService } from '../../services/icons-registry.service';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  saveButtonName: string;

  @Output()
  save = new EventEmitter();

  @Input()
  disableSave = false;

  @Input()
  hideSave = false;

  @Input()
  hideClose = false;

  @Input()
  isBeta = false;

  @Input()
  preventScroll = false;

  constructor(public popupservice: PopupService, private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  close() {
    this.popupservice.close();
  }

  public onSave(): void {
    this.save.emit();
  }

  ngOnInit() {
    window.scrollTo(0, 1);
  }
}
