import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Page, User } from '../../../../generated/graphql';
import { ImageSelectedEvent } from '../../../artists/components/image-cropper/image-cropper.component';
import { UploadImageModalComponent } from '../../../artists/pages/artist-detail-edit/components/upload-image-modal/upload-image-modal.component';
import { PopupService } from '../../../services/popup.service';

@Component({
  selector: 'app-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss'],
})
export class AvatarUploaderComponent implements OnInit {
  @Output() onPictureUploaded$: EventEmitter<ImageSelectedEvent> = new EventEmitter<ImageSelectedEvent>();

  @Input() profilePicture: Page;
  @Input() size: number;
  @Input() user: User;

  public localProfilePicture: ImageSelectedEvent;
  public localProfilePicturePreview: SafeResourceUrl;

  constructor(public popupservice: PopupService, private sanitizer: DomSanitizer) {}

  public ngOnInit(): void {}

  public changeProfilePicture(event: Event): void {
    const ref = this.popupservice.open(UploadImageModalComponent, '800px', {
      data: { aspect: 1, type: 0, currentImage: this.profilePicture },
      panelClass: 'gigital-modal',
    });
    ref.afterClosed().subscribe((d: { image: ImageSelectedEvent }) => {
      if (d) {
        this.localProfilePicture = d.image;
        this.localProfilePicturePreview = this.sanitizer.bypassSecurityTrustResourceUrl(d.image.preview);
        this.onPictureUploaded$.emit(d.image);
      }
    });
  }
}
