<ng-container *ngIf="!!pic">
  <ng-container *ngIf="pic.cropData.x > -1">
    <cl-image [public-id]="pic.fileName" responsive style="width: 100%; height: 100%" appLazyLoad (onError)="error()"
      (onLoad)="loaded()" [attr.alt]="altText" [class.cover-img]="cover">
      <cl-transformation [attr.width]="pic.cropData.w" [attr.height]="pic.cropData.h" [attr.x]="pic.cropData.x"
        [attr.y]="pic.cropData.y" crop="crop"></cl-transformation>
      <cl-transformation crop="limit" [attr.width]="maxWidth">
      </cl-transformation>
      <cl-transformation quality="auto" fetch-format="auto" flags="progressive" responsive>
      </cl-transformation>
    </cl-image>
  </ng-container>

  <ng-container *ngIf="pic.cropData.x === -1">
    <cl-image [public-id]="pic.fileName" responsive crop="scale" appLazyLoad dpr="auto" (onError)="error()"
      (onLoad)="loaded()" [class.cover-img]="cover" [attr.alt]="altText">
      <cl-transformation crop="limit" [attr.width]="maxWidth"></cl-transformation>
      <cl-transformation quality="auto" fetch-format="auto" flags="progressive" responsive></cl-transformation>
    </cl-image>
  </ng-container>
</ng-container>
