import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { User } from '@sentry/types';
import { Observable } from 'rxjs';
import { IconsRegistryService, IconSubsets } from '../../services/icons-registry.service';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FooterComponent implements OnInit {
  @ViewChild('expandedContent') expandedContent: ElementRef;
  @ViewChild('footerHeader') footerHeader: ElementRef;

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (
      !!this.expanded &&
      !this.footerHeader.nativeElement.contains(event.target) &&
      !this.expandedContent.nativeElement.contains(event.target)
    ) {
      this.togglePanel();
    }
  }

  @Input() user: Observable<User>;

  public expanded: boolean = false;
  public urlLocale: string = 'en';

  public ngOnInit(): void {
    if (this.translationsService.getCurrentLocale() === 'sv-se') {
      this.urlLocale = 'sv';
    }
  }

  public togglePanel() {
    this.expanded = !this.expanded;
  }

  public constructor(private iconsService: IconsRegistryService, private translationsService: TranslationsService) {
    this.iconsService.registerIcons([IconSubsets.SOCIAL_MEDIA, IconSubsets.NAVIGATION]);
  }
}
