<section fxLayout="column" fxLayoutGap="16px" [formGroup]="placesGroup">
  <button class="cursor-pointer" (click)="toggleFormControl('all', $event)">
    <mat-checkbox color="primary" i18n formControlName="all">All locations</mat-checkbox>
  </button>
  <button
    class="cursor-pointer"
    (click)="toggleFormControl(entry.place.longName, $event)"
    *ngFor="let entry of existingPlaces | async">
    <mat-checkbox color="primary" [formControlName]="entry.place.longName"
      >{{ entry.place.longName }} <span class="tag-match">{{ entry.amount }}</span></mat-checkbox
    >
  </button>
</section>
