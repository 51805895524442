<p>Review loading</p>
<ng-container *ngIf="review | async as review">
  <p
    >Review {{ review.page.name }} for the booking on {{ review.bookingDate.date | apiDate | date }} with status
    {{ review.bookingDate.booking.status }}</p
  >
  <p>{{ review.recommended }}</p>

  <form [formGroup]="reviewForm" (submit)="updateReview()">
    <mat-checkbox formControlName="recommended">Recommended</mat-checkbox>
    <mat-form-field>
      <textarea matInput formControlName="message"></textarea>
    </mat-form-field>
    <button mat-button type="submit">Submit review</button>
  </form>
</ng-container>
