<div fxLayout="column" fxLayoutGap="8px" class="price-input">
  <p
    *ngIf="!!label"
    fxLayoutAlign="start center"
    fxLayoutGap="6px"
    class="price-input--label"
    [ngClass]="{ light: isLightLabel }">
    <span>{{ label }}</span>
    <mat-icon *ngIf="!!labelTooltip" [matTooltip]="labelTooltip" svgIcon="info-fill"></mat-icon>
  </p>
  <div class="price-input--value" fxLayout="row" fxLayoutGap="5px" [ngClass]="{ invalid: invalid }">
    <input
      #priceInput
      [fxFlex]="priceInputWidth"
      [disabled]="isDisabled"
      type="number"
      [placeholder]="placeholder"
      (input)="inputChanges($event)" />
    <span fxFlex="grow">{{ currencyShorthand }}</span>
  </div>
</div>
