<div fxLayout="column" class="container">
  <app-back-arrow-title [titleText]="'My gigs'" i18n-titleText></app-back-arrow-title>

  <div *ngIf="gigs | async as gigs; else loading" fxLayout="column" fxLayout.gt-sm="row wrap" fxFlex="100%">
    <div
      class="empty-gigs-list"
      *ngIf="gigs.length === 0"
      fxFlex="0 0 100%"
      fxLayout="column"
      fxLayoutAlign="center center">
      <img src="assets/images/empty-gigs-list.svg" class="illustration fade-in" />
      <h2 class="mat-h1" i18n>You don't have any gigs yet</h2>
      <p class="mat-body-1 gray-2" i18n>
        Its a quick process to get your first gig up and running.<br />Get started by clicking the button below!
      </p>
      <a mat-flat-button color="primary" [routerLink]="['/gigs/add']" i18n>Post gig</a>
    </div>

    <ul
      *ngIf="gigs.length > 0"
      class="titles-list"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxFlex="100%"
      fxLayoutGap.lt-md="10px">
      <li
        *ngFor="let label of LABELS_LIST"
        [fxHide.lt-sm]="true"
        [ngClass]="{ 'text-right': label.title === 'Price Before' }"
        [class]="label.title | lowercase"
        [fxFlex]="label.width"
        [fxFlex.lt-md]="label.mdWidth || label.width">
        {{ label.trans }}
        <a *ngIf="label.title === 'Price Before'" [href]="policyLink" i18n>FEE</a>
      </li>
    </ul>
    <ng-container *ngFor="let gig of gigs">
      <app-gig-card-row class="" fxFlex="100%" [gig]="gig"></app-gig-card-row>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="32px grid" class="loading">
    <div *ngFor="let item of [].constructor(15)" fxFlex.gt-sm="33.3333%" fxFlex.gt-lg="25%">
      <ngx-skeleton-loader
        [theme]="{
          width: '100%',
          height: '430px',
          'border-radius': '4px',
          'background-color': '#fafafa',
          'margin-bottom': 0
        }">
      </ngx-skeleton-loader>
    </div>
  </div>
</ng-template>
