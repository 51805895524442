<div fxHide.lt-sm="true" fxLayout="row wrap" class="media-items-list">
  <div
    *ngFor="let video of videoArray"
    igxDrag
    igxDrop
    #dragRef
    fxFlex="262px"
    fxFlex.lt-sm="366px"
    id="{{ video.id }}"
    (dropped)="onVideoDropped($event)"
    (dragStart)="dragStartHandler(video.id)"
    (ghostCreate)="ghostCreateHandler(dragRef)"
    (dragEnd)="dragEndHandler(dragRef)"
    (enter)="onEnterHandler($event)"
    [ghostClass]="'drag_ghost_class'"
    class="media-item-container">
    <div fxLayout="column" class="media-item">
      <div fxLayoutAlign="space-between center" class="media-item--top">
        <span fxLayoutGap="5px" fxLayoutAlign="start center">
          <mat-icon class="media-item--drag-icon">reorder</mat-icon>
          <span i18n>Drag to reorder</span>
        </span>
        <span *ngIf="video?.order === 0" i18n>Primary</span>
      </div>

      <div class="video-16-9">
        <div class="video-16-9-loading" fxLayout fxLayoutAlign="center center">
          <mat-spinner [diameter]="64"></mat-spinner>
        </div>
        <app-media-iframe [srcLink]="video"></app-media-iframe>
      </div>
      <div class="media-item--bottom" fxLayoutAlign="flex-end">
        <button igxDragIgnore (click)="deleteMedia(videoArray, video.id)" class="gig-button transparent" i18n
          >Delete</button
        >
      </div>
    </div>
  </div>
</div>

<div fxHide fxShow.lt-sm>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="24px"
    cdkDropList
    (cdkDropListDropped)="dropVideoOnMobile($event)">
    <div fxFlex="235px" *ngFor="let video of videoArray" cdkDrag>
      <div fxLayout="column" class="media-item">
        <div fxLayoutAlign="space-between center" class="media-item--top">
          <span fxLayoutGap="5px" fxLayoutAlign="start center">
            <mat-icon class="media-item--drag-icon">reorder</mat-icon>
            <span i18n>Drag to reorder</span>
          </span>
          <span *ngIf="video?.order === 0" i18n>Primary</span>
        </div>

        <div class="video-16-9">
          <div class="video-16-9-loading" fxLayout fxLayoutAlign="center center">
            <mat-spinner [diameter]="64"></mat-spinner>
          </div>
          <app-media-iframe [srcLink]="video"></app-media-iframe>
        </div>
        <div class="media-item--bottom" fxLayoutAlign="flex-end">
          <button igxDragIgnore (click)="deleteMedia(videoArray, video.id)" class="gig-button transparent" i18n
            >Delete</button
          >
        </div>
      </div>
    </div>
  </div>
</div>
