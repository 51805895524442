import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { Intercom } from 'ng-intercom';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ExistingPlace,
  FilterFeed,
  GetFilterablePagePlacesGQL,
  GetFilteredPagesGQL,
  GetFilteredPagesQuery,
  GetFilteredPagesQueryVariables,
  GetOneListGQL,
  GetTagsGQL,
  GigType,
  Page,
  PageList,
  Tag,
  TagMatches,
} from '../../../../generated/graphql';
import { ArtistSortingOption, ArtistSortingValues } from '../../../models/ArtistSortingOptions';
import { GET_PAGE_FILTER } from '../../../resolvers';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';
import { MetaService } from '../../../services/meta.service';

export const ARTIST_SORTING_OPTIONS: ArtistSortingOption[] = [
  {
    name: $localize`Most relevant`,
    value: ArtistSortingValues.RelevanceDesc,
  },
  {
    name: $localize`Price - high to low`,
    value: ArtistSortingValues.PriceDesc,
  },
  {
    name: $localize`Price - low to high`,
    value: ArtistSortingValues.PriceAsc,
  },
];
@Component({
  selector: 'app-artist-list',
  templateUrl: './artist-list.component.html',
  styleUrls: ['./artist-list.component.scss'],
})
export class ArtistListComponent implements OnInit, OnDestroy {
  public sortingOptions: ArtistSortingOption[] = ARTIST_SORTING_OPTIONS;
  public activeSortingIndex: number = 0;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private getFilteredPages: GetFilteredPagesGQL,
    private iconsService: IconsRegistryService,
    private findList: GetOneListGQL,
    private getTags: GetTagsGQL,
    private apollo: Apollo,
    private meta: MetaService,
    private route: ActivatedRoute,
    private getFilterable: GetFilterablePagePlacesGQL,
    private intercom: Intercom,
  ) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  initialized = false;
  paging = false;
  filtering = false;

  pageFeedQuery: QueryRef<GetFilteredPagesQuery, GetFilteredPagesQueryVariables>;
  pageFeed: Observable<FilterFeed>;
  pageFeedVariables;

  subscriptions: Subscription[] = [];
  overlayHidden;

  tags: Observable<Tag[]>;
  matches: Observable<TagMatches[]>;
  hits: Observable<TagMatches[]>;

  list: Observable<PageList>;

  filterablePlaces: Observable<ExistingPlace[]>;

  gigTypes = GigType;

  isHandset: boolean;

  displayPages: Observable<{
    matching: Page[];
    fill: Page[];
    totalPageCount: number;
  }>;

  ngOnInit(): void {
    this.meta.setLocalizedTitle({
      en: `Discover Gigital's artists | Artists, Live bands, DJs | Gigital`,
      'sv-se': 'Utforska Gigitals utbud | Artister, band & DJs | Gigital',
    });

    this.meta.setLocalizedDescription({
      en: `Are you looking for live music for your event? 200 verified artists, DJ's and bands. Find and book your favorite. Free registration. Safe bookings.`,
      'sv-se':
        'Söker du efter underhållning till ditt event? Hitta och boka över 200 artister, band och DJ:s till ditt event. Gratis registrering - trygg och säker bokning.',
    });

    if (this.route.snapshot.params.listId) {
      this.list = this.findList
        .watch({ idOrSlug: this.route.snapshot.params.listId })
        .valueChanges.pipe(map((l) => l.data.findOneList));
    }

    const initSub = this.apollo
      .watchQuery({ query: GET_PAGE_FILTER })
      .valueChanges.pipe(map(({ data }) => (data as any).pageFilter))
      .subscribe((res) => {
        this.pageFeedVariables = res;
        if (!this.initialized) {
          this.fetch();
          this.initialized = true;
        } else {
          this.fetchMore();
        }
      });

    this.subscriptions.push(initSub);

    this.filterablePlaces = this.getFilterable.fetch().pipe(map((res) => res.data.getFilterablePagePlaces)) as any;

    const handsetSub = this.breakpointObserver
      .observe(Breakpoints.Medium)
      .pipe(map((result) => result.matches))
      .subscribe((matches) => {
        this.isHandset = !matches;
      });

    this.subscriptions.push(handsetSub);

    this.tags = this.getTags.watch().valueChanges.pipe(map(({ data }) => data.tags));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
  }
  fetch(): void {
    // skip = 0 on initial fetch
    const pageFeedVariables = Object.assign({}, this.pageFeedVariables);
    pageFeedVariables.skip = 0;
    this.pageFeedQuery = this.getFilteredPages.watch(pageFeedVariables);
    this.pageFeed = this.pageFeedQuery.valueChanges.pipe(map(({ data }) => data.filterPages));
    this.tags = this.pageFeedQuery.valueChanges.pipe(map(({ data }) => data.filterPages.existingTags));

    this.matches = this.pageFeedQuery.valueChanges.pipe(map(({ data }) => data.filterPages.tagMatches));
    this.hits = this.pageFeedQuery.valueChanges.pipe(map(({ data }) => data.filterPages.tagHits));

    this.hits.subscribe(() => {});
    this.matches.subscribe(() => {});

    this.displayPages = this.pageFeedQuery.valueChanges.pipe(
      map(({ data }) => {
        const pages = data.filterPages.edges;
        const matching = [];
        for (const page of pages) {
          if (
            page.tags
              .filter((t) => t.group !== 4)
              .map((t) => t.id)
              .some((t) => this.pageFeedVariables.tags.includes(t))
          ) {
            matching.push(page);
          } else {
            break;
          }
        }
        return { matching, fill: pages.slice(matching.length), totalPageCount: data.filterPages.count };
      }),
    );

    this.displayPages.subscribe(() => {});
  }

  onFetchMore(): void {
    const pageFilter = Object.assign({}, this.pageFeedVariables);
    pageFilter.skip += pageFilter.limit;

    // Set paging so we know if we should reset results or not in fetchMore (l: 105)
    this.paging = true;

    const SET_PAGE_FILTER = gql`
      mutation SetPageFilter($pageFilter: SetPageFilterInput!) {
        setPageFilter(pageFilter: $pageFilter) @client {
          skip
        }
      }
    `;
    const sub = this.apollo
      .mutate({
        mutation: SET_PAGE_FILTER,
        variables: { pageFilter },
      })
      .subscribe();

    this.subscriptions.push(sub);
  }

  fetchMore(): void {
    try {
      this.filtering = true;
      this.pageFeedQuery.fetchMore({
        variables: this.pageFeedVariables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const edges = this.paging
            ? [...prev.filterPages.edges, ...fetchMoreResult.filterPages.edges]
            : [...fetchMoreResult.filterPages.edges];

          this.paging = false;
          this.filtering = false;

          return Object.assign(
            {},
            {
              filterPages: {
                edges,
                tagMatches: [...fetchMoreResult.filterPages.tagMatches],
                skip: fetchMoreResult.filterPages.skip,
                hasNextPage: fetchMoreResult.filterPages.hasNextPage,
                existingTags: fetchMoreResult.filterPages.existingTags,
                tagHits: fetchMoreResult.filterPages.tagHits,
                count: fetchMoreResult.filterPages.count,
                __typename: 'FilterFeed',
              },
            },
          );
        },
      });
    } catch (error) {
      console.error('artist-list.component.ts - fetchMore()', error);
    }
  }

  openIntercom() {
    if ((window as any).Intercom.booted) {
      this.intercom.showNewMessage();
    }
  }
}
