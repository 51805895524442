import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Image, PageLink } from '../../../../generated/graphql';
import { socialMediaId } from '../../../models/SocialMediaType';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

export interface ExtendedPageLink extends PageLink {
  isArtist?: boolean;
}

export enum MediaTabs {
  VIDEO = 'video',
  AUDIO = 'audio',
  PHOTO = 'photo',
}

@Component({
  selector: 'app-artist-media',
  templateUrl: './artist-media.component.html',
  styleUrls: ['./artist-media.component.scss'],
})
export class ArtistMediaComponent implements OnInit {
  @Input() mediaLinks: PageLink[];
  @Input() gallery: Image[];

  public selectedTabLabel: MediaTabs;
  public selectedSlideIndex: number = 0;

  public audio: ExtendedPageLink[];
  public videos: PageLink[];

  public MEDIA_TABS = MediaTabs;

  constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.MEDIA, IconSubsets.NAVIGATION]);
  }

  public ngOnInit(): void {
    this.videos = this.mediaLinks.filter(
      (l) => l.socialMediaId === socialMediaId.YOUTUBE || l.socialMediaId === socialMediaId.VIMEO,
    );

    this.audio = this.mediaLinks.filter(
      (l) =>
        l.socialMediaId === socialMediaId.SOUND_CLOUD ||
        l.socialMediaId === socialMediaId.SPOTIFY ||
        l.socialMediaId === socialMediaId.MIX_CLOUD,
    );

    this.audio.map((s) => {
      if (s.url.includes('artist')) {
        s.isArtist = true;
      }
      return s;
    });

    if (this.gallery?.length > 0) {
      this.selectedTabLabel = MediaTabs.PHOTO;
    }
    if (this.audio.length > 0) {
      this.selectedTabLabel = MediaTabs.AUDIO;
    }
    if (this.videos.length > 0) {
      this.selectedTabLabel = MediaTabs.VIDEO;
    }
  }

  public onTabSelected(event: MatTabChangeEvent) {
    this.selectedTabLabel = event.tab.ariaLabel as MediaTabs;
    this.selectedSlideIndex = 0;
  }

  public onNextSlideClick(slidesNumber: number): void {
    this.selectedSlideIndex =
      this.selectedSlideIndex < slidesNumber - 1 ? this.selectedSlideIndex + 1 : this.selectedSlideIndex;
  }

  public onPreviousSlideClick(): void {
    this.selectedSlideIndex = this.selectedSlideIndex > 0 ? this.selectedSlideIndex - 1 : this.selectedSlideIndex;
  }
}
