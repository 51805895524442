<ng-container *ngIf="dashboardBookings$ | async as dashboardBookings">
  <ng-container *ngIf="possibleReviews$ | async as reviews">
    <section *ngIf="reviews.length > 0" class="m-b--45">
      <h2 class="m-b--24">
        <span i18n>Artist reviews</span>
        <span *ngIf="reviewsCount > 0"> ({{ reviewsCount }}) </span>
      </h2>

      <app-quick-review-slider [possibleReviews$]="possibleReviews$"></app-quick-review-slider>
    </section>
  </ng-container>

  <section fxHide fxShow.lt-sm="true" class="statistics m-b--60">
    <div fxLayout="column" fxLayoutGap="13px" class="statistics-item">
      <span class="count">{{ dashboardBookings.upcomingCount }}</span>
      <span class="title" i18n>Upcoming gigs</span>
    </div>

    <div fxLayout="column" fxLayoutGap="15px" class="statistics-item">
      <span class="count">{{ dashboardBookings.pendingCount }}</span>
      <span class="title" i18n>Pending gigs</span>
    </div>

    <div *ngIf="pendingInvoices$" fxLayout="column" fxLayoutGap="15px" class="statistics-item invoice">
      <span class="count">{{ pendingInvoices }} SEK</span>
      <span class="title" i18n>Pending invoices</span>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="dashboardBookings$ | async as dashboardBookings">
  <div *ngIf="dashboardBookings.pendingCount > 0" class="m-b--60">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="m-b--15">
      <h2 class="title">
        <span i18n="@@dashboarPendingBookings">Pending</span>
        <span *ngIf="dashboardBookings.pendingCount > 0"> ({{ dashboardBookings.pendingCount }}) </span>
      </h2>
      <a *ngIf="dashboardBookings.pendingCount > 0" class="see-all" [routerLink]="['/bookings']" i18n>See all</a>
    </div>

    <mat-divider *ngIf="dashboardBookings.pendingCount <= 0" class="m-b--15"></mat-divider>

    <app-dashboard-pending-bookings
      [pendingBookings]="dashboardBookings.pending"
      [pendingCount]="dashboardBookings.pendingCount"
      class="m-b--50">
    </app-dashboard-pending-bookings>
  </div>

  <div class="m-b--60">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="m-b--15">
      <h2 class="title">
        <span i18n>Bookings</span>
        <span *ngIf="dashboardBookings.upcomingCount > 0"> ({{ dashboardBookings.upcomingCount }}) </span>
      </h2>
      <a *ngIf="dashboardBookings.upcomingCount > 0" class="see-all" [routerLink]="['/bookings']" i18n>See all</a>
    </div>

    <mat-divider *ngIf="dashboardBookings.upcomingCount <= 0" class="m-b--15"></mat-divider>

    <app-dashboard-booking-dates
      [upcomingDates]="dashboardBookings.upcomingDates"
      [upcomingCount]="dashboardBookings.upcomingCount"
      class="m-b--105">
    </app-dashboard-booking-dates>
  </div>
</ng-container>

<section fxLayout="column" class="pages-section m-b--64" *ngIf="userPages$ | async as userPages">
  <h2 class="m-b--24">
    <span i18n>Artist profile</span>&nbsp;<span *ngIf="userPages.length > 0">({{ userPages.length }})</span>
  </h2>
  <div fxLayoutGap="47px" fxLayoutGap.lt-md="0px" fxLayoutGap.lt-sm="0" class="profile-wrap">
    <app-artist-profile-dashboard fxFlex.lt-md="100%" [userPages]="userPages"></app-artist-profile-dashboard>

    <app-image-card-dashboard
      fxHide.lt-md="true"
      *ngIf="userPages.length > 0"
      fxFlex="grow"
      imageName="web/Dashboard/1J8A7106_4_xleygh.jpg"
      [imageData]="{
        crop: {
          x: 0,
          y: 0
        },
        size: 'cover'
      }"
      [flipH]="false"
      title="Had gig outside Gigital?"
      i18n-title
      subTitle="Request a review to make your profile more attractive!"
      i18n-subTitle
      buttonName="Request a review"
      i18n-buttonName
      buttonUrl="/invite/review"></app-image-card-dashboard>
  </div>
</section>

<section class="m-b--64">
  <div fxLayoutAlign="space-between baseline">
    <h2 class="title" i18n>Explore gigs</h2>
    <a class="see-all" [routerLink]="['/gigs']">Show all</a>
  </div>
  <div *ngIf="gigs$ | async as gigs" fxLayoutGap="32px" fxLayoutGap.lt-sm="13px" class="gigs-list">
    <app-gig-card
      fxFlex="calc(25% - 32px)"
      fxFlex.lt-sm="calc(82% - 13px)"
      fxLayout="column"
      *ngFor="let gig of gigs"
      [gig]="gig">
    </app-gig-card>
  </div>
</section>

<section fxHide fxShow.lt-md="true" *ngIf="userPages$ | async as userPages" class="m-b--55">
  <app-image-card-dashboard
    *ngIf="userPages.length > 0"
    fxFlex="grow"
    class="mobile"
    imageName="web/Dashboard/1J8A7106_4_xleygh.jpg"
    [imageData]="{
      crop: {
        x: 50,
        y: 0
      },
      size: 'cover'
    }"
    [flipH]="false"
    title="Had gig outside Gigital?"
    i18n-title
    subTitle="Request a review to make your profile more attractive!"
    i18n-subTitle
    buttonName="Request a review"
    i18n-buttonName
    buttonUrl="/invite/review"></app-image-card-dashboard>
</section>
