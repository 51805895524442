<section *ngIf="pendingBookings && pendingBookings.length > 0">
  <div *ngIf="pendingCount > 0; else noBookings">
    <div fxLayout="column">
      <div class="data-header" fxLayout="row" fxLayoutAlign="space-between center" fxShow fxHide.lt-md>
        <span *ngIf="userType === USER_TYPE.Booker" fxFlex="25%" i18n>Artist</span>
        <span *ngIf="userType !== USER_TYPE.Booker" fxFlex="25%" i18n>Gig</span>

        <span *ngIf="userType === USER_TYPE.Booker" fxFlex="25%" i18n>Date & Time</span>
        <span *ngIf="userType !== USER_TYPE.Booker" fxFlex="25%" i18n>Date</span>

        <span *ngIf="userType === USER_TYPE.Booker" fxFlex="25%" i18n>Gig</span>
        <span *ngIf="userType !== USER_TYPE.Booker" fxFlex="25%" i18n>Payout</span>

        <span fxFlex="25%" i18n>Action</span>
      </div>
    </div>

    <div fxLayoutGap="0px" fxLayoutGap.lt-md="8px" fxLayout="column" class="items-container">
      <!-- TODO: cache list of bookings in ts -->
      <app-dashboard-booking-item
        *ngFor="let booking of pendingBookings.slice(0, 5)"
        [userType]="userType"
        [booking]="booking"></app-dashboard-booking-item>
    </div>
  </div>
</section>

<ng-template #noBookings>
  <span *ngIf="userType === USER_TYPE.Booker" i18n>You don’t have any bookings yet.</span>

  <ng-container *ngIf="userPages$ | async as userPages">
    <span *ngIf="userType === USER_TYPE.Artist && userPages.length <= 0" i18n>
      You need a verified artist profile to apply for gigs.
    </span>
    <span *ngIf="userType === USER_TYPE.Artist && userPages.length > 0">
      <span i18n>You don’t have any bookings yet. You can find gigs to apply for</span>&nbsp;
      <a [routerLink]="['../', 'gigs']" class="text-underline" i18n>here</a>
    </span>
  </ng-container>
</ng-template>
