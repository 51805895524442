<app-info-bar *ngIf="pageProgressStep.denied" [infoType]="INFO_BAR_TYPE.Warning" [text]="pageProgressStep.denyReason">
</app-info-bar>
<div class="d--block container container-right">
  <div class="header">
    <h1 class="h6 f-weight--600" i18n>Import content</h1>
    <p class="gray-2" i18n
      >Update your artist profile by importing content from multiple channels as Youtube, Spotify and Mixcloud.
    </p>
  </div>

  <app-artist-import-form
    *ngIf="pageId || isCreateMode"
    [isEditMode]="true"
    [pageId]="pageId"
    (nextStepClick$)="goToStep$.next(1)"></app-artist-import-form>
</div>
