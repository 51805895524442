import { Gig, GigSlot, GigType, SlotStatus } from '../../generated/graphql';
import { isSlotBooked, isSlotOpen } from '../../utils/gigSlots';
import { apiToDate } from './dates';

export interface GigMeta {
  color: string;
  icon: string;
  plectrumId: number;
}

export const GigStatus = {
  OPEN: $localize`Open`,
  CLOSED: $localize`Closed`,
  BOOKED: $localize`Booked`,
};

export const getGigMetaData = (gig: Gig): GigMeta => {
  return getMetaFromGigType(gig.type);
};

export const getMetaFromGigType = (gigType: GigType) => {
  const out: GigMeta = {
    color: '',
    icon: '',
    plectrumId: null,
  };

  if (!gigType) {
    return out;
  }

  switch (gigType) {
    case GigType.Business:
      out.icon = 'business_center';
      out.color = 'green';
      out.plectrumId = 4;
      break;
    case GigType.Club:
      out.icon = 'local_bar';
      out.color = 'pink';
      out.plectrumId = 2;
      break;
    case GigType.Festival:
      out.icon = 'music_note';
      out.color = 'purple';
      out.plectrumId = 5;
      break;
    case GigType.Gala:
      out.icon = 'star';
      out.color = 'orange';
      out.plectrumId = 6;
      break;
    case GigType.Others:
      out.icon = 'favorite';
      out.color = 'light-turq';
      out.plectrumId = 7;
      break;
    case GigType.Private:
      out.icon = 'lock';
      out.color = 'pink';
      out.plectrumId = 1;
      break;
    case GigType.Shopping:
      out.icon = 'shopping_basket';
      out.color = 'blue';
      out.plectrumId = 2;
      break;
    case GigType.Venue:
      out.icon = 'shopping_basket';
      out.color = 'pink';
      out.plectrumId = 2;
      break;
  }

  return out;
};

export const getTagsList = (gig: Gig): string => {
  const useTags = gig.slots?.length > 0 ? gig.slots.map((s) => s.tags) : gig.availableTags;
  return [].concat
    .apply([], useTags)
    .sort((a, b) => {
      if (a.name === 'Artist') {
        return -1;
      } else if (a.name === 'Band') {
        return 0;
      } else if (a.name === 'DJ') {
        return 1;
      }
      return a.id - b.id;
    })
    .map((t) => t.name)
    .filter((x, i, a) => a.indexOf(x) === i)
    .join(', ');
};

const today = new Date();
export const filterActiveGigSlots = (s: GigSlot) => {
  return apiToDate(s.deadline).getTime() > today.getTime() && s.status !== SlotStatus.Assigned;
};

export const getGigStatus = (gig: Gig): string => {
  if (gig.deleted) return GigStatus.CLOSED;
  if (gig.slots.filter(isSlotOpen).length > 0) return GigStatus.OPEN;
  if (gig.slots.filter(isSlotBooked).length === gig.slots.length) return GigStatus.BOOKED;
  return GigStatus.CLOSED;
};

export const filterSlotsDates = (slots: GigSlot[]) => {
  let sortedDateSlots = slots
    .filter((s) => isSlotOpen(s))
    .sort((a, b) => {
      return parseInt(a.date) - parseInt(b.date);
    });

  if (sortedDateSlots.length === 0) {
    sortedDateSlots = slots.sort((a, b) => {
      return parseInt(a.date) - parseInt(b.date);
    });
  }

  return sortedDateSlots;
};
