import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-slider-controls',
  templateUrl: './slider-controls.component.html',
  styleUrls: ['./slider-controls.component.scss'],
})
export class SliderControlsComponent implements OnInit {
  @Output('onPreviousClick') onPreviousClick$: EventEmitter<any> = new EventEmitter();
  @Output('onNextClick') onNextClick$: EventEmitter<any> = new EventEmitter();

  @Input() disabledRight: boolean;
  @Input() disabledLeft: boolean;

  constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  ngOnInit(): void {}

  public previousClick(): void {
    this.onPreviousClick$.emit();
  }

  public nextClick(): void {
    this.onNextClick$.emit();
  }
}
