<div fxLayout="column" fxLayoutAlign="center center" class="new-profile" [ngClass]="{ 'full-screen': isFirst }">
  <mat-icon class="artist-icon" svgIcon="artist"></mat-icon>

  <p *ngIf="isFirst" i18n>Create your first artist profile.</p>
  <p *ngIf="!isFirst" i18n>Create new artist profile.</p>
  <p i18n class="text">Get started by clicking the button below</p>

  <button class="gig-button primary" (click)="openCreateArtist()">
    <span *ngIf="isFirst" i18n>Create artist profile</span>
    <span *ngIf="!isFirst" i18n>Create profile</span>
  </button>
</div>
