import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-authorized',
  template: `
    <div class="authorizing bg-gray-5" fxLayout fxLayoutAlign="center center">
      <div>
        <mat-spinner></mat-spinner>
        <p i18n class="mat-body gray-3">Logging in...</p>
      </div>
    </div>
  `,
  styles: [
    `
      .authorizing {
        height: calc(100vh - 76px);

        p {
          margin-top: 32px;
        }
      }
    `,
  ],
})
export class AuthorizedComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.auth.handleAuth();

    this.auth.loginStatusChanges.pipe(first()).subscribe(s => {
      if (s) {
        this.redirectToReturn();
      }
    });
  }

  private async redirectToReturn() {
    let returnUrl: string;
    let forceReturn: string;
    setTimeout(async () => {
      if (isPlatformBrowser(this.platformId)) {
        forceReturn = localStorage.getItem('forceReturn');
        returnUrl = forceReturn || localStorage.getItem('returnUrl');

        await this.router.navigateByUrl(returnUrl ? returnUrl : '/').then(s => {
          localStorage.removeItem('forceReturn');
          localStorage.removeItem('returnUrl');
        });
      }
    }, 0);
  }
}
