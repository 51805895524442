import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { Component, Input, OnInit } from '@angular/core';
import { Gig } from '../../../../generated/graphql';
import { isSlotBooked } from '../../../../utils/gigSlots';
import { areDatesSameDay, defineFirstDateFormat, getFirstAndLastSlotDates, MEDIUM_DATE } from '../../../helpers/dates';
import { getGigStatus } from '../../../helpers/gigs';
registerLocaleData(localeFr);

@Component({
  selector: 'app-gig-card-row',
  templateUrl: './gig-card-row.component.html',
  styleUrls: ['./gig-card-row.component.scss'],
})
export class GigCardRowComponent implements OnInit {
  @Input() gig: Gig;

  dateFormat = MEDIUM_DATE;
  firstDateFormat = MEDIUM_DATE;

  firstDate: Date;
  lastDate: Date;
  doDatesDiffer = false;

  public get totalPrice(): number {
    return this.gig.slots.reduce((partialSum, slot) => partialSum + slot.price, 0);
  }

  public get slotsBooked(): number {
    return this.gig.slots.filter(isSlotBooked).length;
  }

  public get gigStatus(): string {
    return getGigStatus(this.gig);
  }

  constructor() {}

  ngOnInit(): void {
    this.getFirstDateFormat();

    if (this.gig.slots.length > 0) {
      const { firstDate, lastDate } = getFirstAndLastSlotDates(this.gig.slots);
      this.doDatesDiffer = !areDatesSameDay(firstDate, lastDate);

      this.firstDate = firstDate;
      this.lastDate = lastDate;
    }
  }

  public getFirstDateFormat(): void {
    this.firstDateFormat = defineFirstDateFormat(this.gig.slots);
  }
}
