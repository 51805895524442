import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-artist-detail-preview-modal',
  templateUrl: './artist-detail-preview-modal.component.html',
  styleUrls: ['./artist-detail-preview-modal.component.scss'],
})
export class ArtistDetailPreviewModalComponent {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: { pageId: string },

    private dialogRef: MatDialogRef<ArtistDetailPreviewModalComponent>,
    private iconsService: IconsRegistryService,
  ) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  public onModalClose(): void {
    this.dialogRef.close();
  }
}
