<div class="filtering">
  <div fxLayoutAlign="space-between center" class="filtering--header container">
    <button class="gig-button transparent clear" (click)="clearAll()" i18n>Clear all</button>
    <h1 class="subtitle-1 f-weight--600" i18n>Filters</h1>
    <button class="gig-button icon" (click)="activeModal.close()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <div class="filtering--content container">
    <div fxLayoutAlign="space-between center" class="m-b--44">
      <span class="f-weight--600" i18n>Artist with equipment</span>
      <app-checkbox-toggle-slider [(ngModel)]="includesEquipment"></app-checkbox-toggle-slider>
    </div>

    <div fxLayout="column" fxLayoutGap="24px" class="artist-type-filter">
      <app-checkbox-input
        label="Live"
        [isThinStyle]="true"
        [(ngModel)]="isLiveSelected"
        i18n-label></app-checkbox-input>
      <app-checkbox-input label="DJ" [isThinStyle]="true" [(ngModel)]="isDjSelected" i18n-label></app-checkbox-input>
    </div>

    <mat-accordion>
      <ng-container *ngFor="let filter of filterSections">
        <mat-expansion-panel *ngIf="filter.options.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="f-weight--600">{{ filter.title }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div>
            <div fxLayout="row wrap" fxLayoutGap="24px 24px grid" class="filter-section">
              <app-checkbox-input
                *ngFor="let option of filter.options | slice: 0:filter.numberToShow"
                fxFlex="50%"
                [(ngModel)]="option.active"
                [isThinStyle]="true"
                [label]="getLabel(option)"></app-checkbox-input>
            </div>
            <button
              *ngIf="filter.options.length > 8 && !filter.isOpen"
              (click)="openFilterSection(filter)"
              class="gig-button transparent show-all"
              i18n
              >+ Show all</button
            >
          </div>
        </mat-expansion-panel>
      </ng-container>

      <mat-expansion-panel *ngIf="data.filters.location.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="f-weight--600" i18n>Region</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="m-b--32" *ngFor="let country of data.filters.location">
          <h3 class="subtitle-1 f-weight--600 m-b--28">{{ country.name }}</h3>
          <div fxLayout="row wrap" fxLayoutGap="24px 24px grid" class="filter-section">
            <app-checkbox-input
              *ngFor="let place of country.places"
              fxFlex="50%"
              [(ngModel)]="place.active"
              [isThinStyle]="true"
              [label]="place?.longName"></app-checkbox-input>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="filtering--footer container">
    <button fxFlex class="gig-button primary" (click)="doSearch()" i18n>Show results</button>
  </div>
</div>
