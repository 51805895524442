<div
  fxLayoutAlign="stretch start"
  fxLayoutGap="120px"
  fxLayout.lt-md="column"
  fxLayoutGap.lt-md="42px"
  class="request-step container">
  <div fxFlex="316px" fxFlex.lt-md="auto" class="request-step--left">
    <h1 class="request-step--title m-b--20" i18n>Date, budget & equipment</h1>
    <p class="intro-text" i18n>Share event details, keep in mind that they can affect the final price.</p>
  </div>

  <div class="w--100per" *ngIf="page$ | async as page">
    <div>
      <form fxFlex="406px" fxFlex.lt-md="auto" fxLayout="column" fxLayoutGap="40px" [formGroup]="createRequestForm">
        <div>
          <app-date-calendar-input
            [minDate]="todayDate"
            [selectedDates]="selectedDates"
            [invalid]="
              (createRequestForm.controls.slotDates.touched || createRequestForm.controls.slotDates.dirty) &&
              createRequestForm.controls.slotDates.invalid
            "
            formControlName="slotDates"
            label="Date for gig"
            i18n-label></app-date-calendar-input>
          <div
            *ngIf="
              (createRequestForm.controls.slotDates.touched || createRequestForm.controls.slotDates.dirty) &&
              createRequestForm.controls.slotDates.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="createRequestForm.controls.slotDates.errors?.required"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>Date is required.</span>
            </p>
          </div>
        </div>

        <app-select-input
          label="Select start time"
          [selectOptions]="gigStartTimeOptions"
          formControlName="startTime"
          i18n-label></app-select-input>

        <app-select-input
          label="Select length of the gig"
          [selectOptions]="timeLengthOptions"
          formControlName="setLength"
          i18n-label></app-select-input>

        <div
          fxLayout="column"
          fxLayoutAlign="center start"
          fxLayoutGap="12px"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="full-w">
          <p class="f-weight--600" i18n>Technical euiqpment on site</p>
          <div fxLayout="row" fxLayoutGap="12px" class="full-w" fxLayoutAlign.gt-md="space-around">
            <button
              (click)="selectSupplyType(0)"
              class="image-button cursor-pointer"
              [ngClass]="{ selected: this.createRequestForm.get('suppliesMixer').value }"
              fxLayoutAlign="start center"
              fxFlex="50%">
              <mat-icon class="svg-icon" svgIcon="mixer"></mat-icon>
              <span i18n>Mixertable</span>
            </button>
            <button
              (click)="selectSupplyType(2)"
              class="image-button cursor-pointer"
              [ngClass]="{ selected: this.createRequestForm.get('suppliesSpeakers').value }"
              fxLayoutAlign="start center"
              fxFlex="50%">
              <mat-icon class="svg-icon" svgIcon="speakers"></mat-icon>
              <span i18n>Speakers</span>
            </button>
          </div>
          <div fxLayout="row" fxLayoutGap="12px" class="full-w m-b--24" fxLayoutAlign.gt-md="space-around">
            <button
              (click)="selectSupplyType(1)"
              class="image-button cursor-pointer"
              [ngClass]="{ selected: this.createRequestForm.get('suppliesPa').value }"
              fxLayoutAlign="start center"
              fxFlex="50%">
              <mat-icon class="svg-icon pa-system" svgIcon="pa-system"></mat-icon>
              <span i18n>PA system</span>
            </button>
            <button
              (click)="selectSupplyType(-1)"
              class="image-button cursor-pointer"
              [ngClass]="{ selected: areAllSupliesDeselected() }"
              fxLayoutAlign="start center"
              fxFlex="50%">
              <mat-icon class="svg-icon none" svgIcon="none"></mat-icon>
              <span i18n>None</span>
            </button>
          </div>

          <label fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-checkbox-input formControlName="supplyStatus"></app-checkbox-input>
            <span class="f-size--14" i18n>Request artist to bring missing equipment for a fee</span>
          </label>
        </div>

        <div>
          <app-input
            formControlName="budgetAll"
            placeholder="0"
            [type]="'number'"
            [label]="budgetLabel"
            [mask]="'SEK'"
            [invalid]="
              (createRequestForm.controls.budgetAll.touched || createRequestForm.controls.budgetAll.dirty) &&
              createRequestForm.controls.budgetAll.invalid
            "
            class="d--block m-b--8"
            i18n-placeholder></app-input>
          <a href="" class="tip text-underline d--block m-b--12"
            ><span i18n>Price incl fees</span> {{ totalPriceWithFee }} SEK (excl VAT)</a
          >

          <div
            *ngIf="
              (createRequestForm.controls.budgetAll.touched || createRequestForm.controls.budgetAll.dirty) &&
              createRequestForm.controls.budgetAll.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="createRequestForm.controls.budgetAll.errors?.required"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>Price is required.</span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
