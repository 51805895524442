import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GetDashboardGigsGQL, Gig } from '../../../../generated/graphql';

@Component({
  selector: 'app-customer-gigs-dashboard',
  templateUrl: './customer-gigs-dashboard.component.html',
  styleUrls: ['./customer-gigs-dashboard.component.scss'],
})
export class CustomerGigsDashboardComponent implements OnInit {
  public gigs$: Observable<Gig[]>;
  public gigsCount: number;

  constructor(private gigsGql: GetDashboardGigsGQL) {}

  ngOnInit(): void {
    this.gigs$ = this.gigsGql.watch().valueChanges.pipe(
      tap((d) => (this.gigsCount = d.data.dashboardGigs.count)),
      map((d) => d.data.dashboardGigs.gigs),
    );
  }
}
