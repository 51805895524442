<!-- TODO: Add icons and color -->
<div [formGroup]="formGroup">
  <mat-form-field style="width: 100%">
    <mat-select placeholder="Gig type" name="item" formControlName="type">
      <mat-option *ngFor="let type of gigTypes" [value]="type.type">
        {{ type.translation }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="type.hasError('required')" i18n> Gig type is required </mat-error>
  </mat-form-field>
</div>
