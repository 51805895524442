<div class="question">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ pageFaqAnswer.question }}</mat-panel-title>
      </mat-expansion-panel-header>
      <p class="question--text">{{ pageFaqAnswer.answer }}</p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
