import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ApplicationsByUserGQL, ApplicationType, Booking, Pagination } from '../../../../generated/graphql';
import { getApplicationStatus } from '../../../helpers/applications';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

export const APPLICATIONS_DATA_LABELS = [
  { trans: $localize`Aplied to`, title: 'Aplied to', width: '180px', mdWidth: '135px' },
  { trans: $localize`Date`, title: 'Date', width: '90px' },
  { trans: $localize`Price Before`, title: 'Price Before', width: '112px', mdWidth: '80px' },
  { trans: $localize`Profile`, title: 'Profile', width: '140px', mdWidth: '100px' },
  { trans: $localize`Status`, title: 'Status', width: '100px', mdWidth: '140px' },
  { trans: $localize`Action`, title: 'Action', width: '120px', mdHide: true },
];

export const APPLICATION_TYPE_NAMES = [
  { type: ApplicationType.All, trans: $localize`All` },
  { type: ApplicationType.Booked, trans: $localize`Booked` },
  { type: ApplicationType.Declined, trans: $localize`Declined` },
  { type: ApplicationType.Finalizing, trans: $localize`Finalizing` },
  { type: ApplicationType.Pending, trans: $localize`Pending` },
];

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ApplicationsComponent implements OnInit {
  public typeFilter$: BehaviorSubject<ApplicationType>;
  public getNewPageData$: BehaviorSubject<Pagination>;
  public bookingDates$: Observable<Booking[]>;

  public existingTypes: string[] = ['All'];
  public selectedCheckbox: number = 0;
  public pageSize: number = 10;
  public totalAmount: number;

  public POLICY_LINK = 'http://help.gigital.se/en/articles/3350100-payment-policy';
  public APPLICATION_TYPE_NAMES = APPLICATION_TYPE_NAMES;
  public LABELS_LIST = APPLICATIONS_DATA_LABELS;
  public APPLICATION_TYPE = ApplicationType;

  constructor(private getApplications: ApplicationsByUserGQL, private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  ngOnInit() {
    this.initVariables();
    this.initApplicationsQuery();
  }

  public initVariables(): void {
    this.getNewPageData$ = new BehaviorSubject<Pagination>({ limit: this.pageSize, skip: 0 });
    this.typeFilter$ = new BehaviorSubject<ApplicationType>(ApplicationType.All);
  }

  public initApplicationsQuery(): void {
    this.bookingDates$ = combineLatest([this.typeFilter$, this.getNewPageData$]).pipe(
      switchMap(([type, pageChangedInfo]: [ApplicationType, Pagination]) =>
        this.getApplications
          .watch({ type: type, paging: { limit: pageChangedInfo.limit, skip: pageChangedInfo.skip } })
          .valueChanges.pipe(
            map(({ data }) => {
              this.totalAmount = data.applicationsByUser.total;
              return data.applicationsByUser.edges;
            }),
            tap((bookingDates) => {
              bookingDates.forEach((date) => {
                const type = getApplicationStatus(date.booking);
                this.existingTypes.push(ApplicationType.All);
                this.existingTypes.indexOf(type) === -1 ? this.existingTypes.push(ApplicationType[type]) : null;
              });
            }),
          ),
      ),
    );
  }

  public filterType(type: ApplicationType, selectedCheckboxIndex: number): void {
    this.selectedCheckbox = selectedCheckboxIndex;
    this.typeFilter$.next(type);
  }

  public checkIfExists(type: string): boolean {
    return this.existingTypes.indexOf(type) === -1 ? true : false;
  }

  public changePage(newPageOptions: Pagination): void {
    this.getNewPageData$.next({ limit: newPageOptions.limit, skip: newPageOptions.skip });
  }
}
