<div fxLayout="column" fxLayoutGap="8px" class="toggle-slider">
  <p class="toggle-slider--label" *ngIf="!!label" [ngClass]="{ light: isLightLabel }">{{ label }}</p>

  <label class="toggle-slider--switch" [ngClass]="{ large: !!switchIcon }">
    <input type="checkbox" [(ngModel)]="inputValue" (input)="inputChanges($event)" [disabled]="disabled" />
    <div class="slider">
      <div fxLayoutAlign="center center" class="round">
        <mat-icon *ngIf="!!switchIcon" class="round--icon" [svgIcon]="switchIcon"></mat-icon>
      </div>
    </div>
  </label>
</div>
