import { Component, Input, OnInit } from '@angular/core';
import { Gig, GigTemplate, GigType } from '../../../../generated/graphql';
import { TranslatedEnum, TranslationsService } from '../../../services/translations.service';

@Component({
  selector: 'app-fallback-image',
  templateUrl: './fallback-image.component.html',
  styleUrls: ['./fallback-image.component.scss'],
})
export class FallbackImageComponent implements OnInit {
  @Input() gig: Gig;
  @Input() borderRadius: string = '0px';
  @Input() textSize: string = '12px';
  @Input() template: GigTemplate;

  @Input()
  hideText = false;

  type: TranslatedEnum<GigType>;

  constructor(private translations: TranslationsService) {}

  ngOnInit(): void {
    if (this.gig) {
      this.type = this.translations.gigType(this.gig.type);
    } else if (this.template) {
      this.type = this.translations.gigType(this.template.type);
    }
  }
}
