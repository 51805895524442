import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import * as LogRocket from 'logrocket';
import { environment } from './environments/environment';
import { GIGITAL_VERSION } from './globals';

export const initLogging = () => {
  if (environment.appEnvironment === 'staging' || environment.appEnvironment === 'production') {
    Sentry.init({
      dsn: 'https://59493f648265477d8a771ced7625b8ed@sentry.io/1539595',
      environment: environment.appEnvironment,
      release: GIGITAL_VERSION,
    });
    LogRocket.init('ylgyp9/prod', {
      release: GIGITAL_VERSION,
    });
  }
};

@Injectable()
export class GigitalErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error): void {
    Sentry.captureException(error.originalError || error);
    LogRocket.captureException(error.originalError || error);
    console.error(error);
    // throw error;
  }
}
