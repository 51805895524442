import { Component, Input, OnInit } from '@angular/core';
import { Billing } from '../../../generated/graphql';

@Component({
  selector: 'app-contract-billing-detail',
  templateUrl: './contract-billing-detail.component.html',
  styleUrls: ['./contract-billing-detail.component.scss'],
})
export class ContractBillingDetailComponent implements OnInit {
  @Input()
  billing: Billing;

  // @Output()
  // selectBillingInfo = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  // selectBilling() {
  //   this.selectBillingInfo.next(this.billing.id);
  // }
}
