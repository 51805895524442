import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(value: number, isBooker: boolean, userFee: number): string {
    if (isBooker) {
      return Math.round(+value * (userFee || 1.15)).toLocaleString('sv-SE');
    }
    return Math.round(+value * 0.85).toLocaleString('sv-SE');
  }
}
