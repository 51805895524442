import { Component, Input, OnInit } from '@angular/core';
import { Page } from '../../../generated/graphql';

@Component({
  selector: 'app-artists-list-item-contentful',
  templateUrl: './artists-list-item-contentful.component.html',
  styleUrls: ['./artists-list-item-contentful.component.scss'],
})
export class ArtistsListItemContentfulComponent implements OnInit {
  @Input()
  artist: Page;
  constructor() {}

  ngOnInit() {}
}
