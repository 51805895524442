import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, Injector, LOCALE_ID, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { Apollo, gql } from 'apollo-angular';
import * as LogRocket from 'logrocket';
import { Intercom } from 'ng-intercom';
import { NgcCookieConsentConfig, NgcCookieConsentService } from 'ngx-cookieconsent';
import { EventReplayer } from 'preboot';
import { Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { GetCurrentUserGQL, User } from '../generated/graphql';
import { MissingUserInfoComponent } from './components/missing-user-info/missing-user-info.component';
import { AnalyticsService } from './services/analytics.service';
import { AuthService } from './services/auth.service';
import { MetaService } from './services/meta.service';
import { NavbarService } from './services/navbar.service';
import { NotificationsService } from './services/notifications.service';

declare let NG_CONF;

const GET_LOGIN_STATUS = gql`
  query getLoggedIn {
    isLoggedIn @client
  }
`;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    public auth: AuthService,
    private apollo: Apollo,
    private getCurrentUser: GetCurrentUserGQL,
    private notifications: NotificationsService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private replayer: EventReplayer,
    private meta: MetaService,
    private intercom: Intercom,
    private modal: MatDialog,
    private injector: Injector,
    private analytics: AnalyticsService,
    public navbarStatus: NavbarService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // import('aos').then(aos => {
      //   aos.init();
      // });

      window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
  }

  @ViewChild('sidenav')
  sideNav: MatSidenav;

  overlayHidden;
  openIntercom = false;

  missingInfoRef: MatDialogRef<MissingUserInfoComponent>;

  user: Observable<User>;
  loginStatusChecked = false;
  showFooter = true;
  isIdentified = false;
  isGaIdentified = false;
  isHandset: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  async ngOnInit() {
    this.document.documentElement.lang = this.localeId;

    if (isPlatformBrowser(this.platformId)) {
      // import('aos').then(aos => {
      //   aos.init();
      // });
    }

    this.clearOldStorage();

    this.router.events
      .pipe(
        withLatestFrom(this.isHandset),
        filter(([a, b]) => b && a instanceof NavigationEnd),
      )
      .subscribe(() => this.sideNav.close());

    let lastUrl;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap((event: RouterEvent) => {
          if (event.url && event.url.startsWith('/bookings/')) {
            this.showFooter = false;
          } else if (event.url && event.url.startsWith('/artists/') && event.url.includes('/book')) {
            this.showFooter = false;
          } else if (event.url && event.url.startsWith('/gigs/add')) {
            this.showFooter = false;
          } else {
            this.showFooter = true;
          }
        }),
      )
      .subscribe((e: RouterEvent) => {
        this.meta.setHreflangRoute(e.url);
        if (lastUrl && e.url.split('?')[0].indexOf(lastUrl.split('?')[0])) {
          this.meta.setTitleWithDefaults(this.meta.fallbackTitle, false, e.url);
        }
        lastUrl = e.url;
      });
    this.meta.setTitleWithDefaults(this.meta.fallbackTitle, false);

    this.activeRoute.queryParams.subscribe((q) => {
      if (!!q['mail-chat']) {
        this.openIntercom = true;
      }
      if (!!q.mail_unsub) {
        if (!this.auth.isLoggedIn) {
          localStorage.setItem('forceReturn', '/user/settings');
        } else {
          this.router.navigate(['/user/settings']);
        }
      }
    });

    if (this.auth.isLoggedIn && isPlatformBrowser(this.platformId)) {
      console.log('check logged in APPCOMP');
      await this.auth.checkLogin();
    }

    this.apollo
      .watchQuery<any>({
        query: GET_LOGIN_STATUS,
      })
      .valueChanges.subscribe(({ data }) => {
        if (data && data.isLoggedIn) {
          this.setCurrentUser();
        } else {
          this.intercom.boot({
            app_id: environment.INTERCOM_APP_ID,
            user_id: null,
          });
        }
      });
  }

  clearOldStorage() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('profile') && !!localStorage.getItem('isLoggedIn')) {
        localStorage.clear();
      }
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.replayer.replayAll();
      const cc = this.injector.get(NgcCookieConsentService);

      let domain = 'localhost';
      if (typeof NG_CONF !== 'undefined') {
        domain = NG_CONF.DOMAIN;
      } else {
        domain = environment.DOMAIN;
      }
      const ccConf: NgcCookieConsentConfig = { ...cc.getConfig(), autoAttach: true, cookie: { domain } };
      cc.init(ccConf);
    }
  }

  setCurrentUser() {
    this.user = this.getCurrentUser.watch({}, { errorPolicy: 'all' }).valueChanges.pipe(
      map(({ data }) => {
        if (data.me && data.me.id) {
          this.notifications.subscribeToNotifications();

          if (!this.isIdentified) {
            Sentry.configureScope((scope) => {
              scope.setUser({ email: data.me.email, id: data.me.id.toString() });
            });

            LogRocket.identify(data.me.id.toString(), {
              name: `${data.me.firstName} ${data.me.lastName}`,
              email: data.me.email,
            });

            this.intercom.boot({
              app_id: environment.INTERCOM_APP_ID,
              user_id: data.me.id.toString(),
              name: `${data.me.firstName} ${data.me.lastName}`,
              email: data.me.email,
            });

            // HOTFIX: have to set user id on browser.
            // Don't know if we need to set it on the server as well
            if (isPlatformBrowser(this.platformId)) {
              this.analytics.setUserId(data.me.id);
            }

            this.isIdentified = true;
          }
        }

        if (data.me && data.me.missingData.length > 0 && !this.missingInfoRef) {
          if (isPlatformBrowser(this.platformId)) {
            this.missingInfoRef = this.modal.open(MissingUserInfoComponent, {
              data: { user: data.me },
              disableClose: true,
              width: '500px',
            });
            this.missingInfoRef.afterClosed().subscribe((d) => {
              // HACK for #118
              if (!d) {
                this.missingInfoRef = this.modal.open(MissingUserInfoComponent, {
                  data: { user: data.me },
                  disableClose: true,
                });
              } else {
                this.missingInfoRef = undefined;
              }
            });
          }
        }

        if (this.openIntercom) {
          // TODO: Display a message depending on the email arriving from?
          this.intercom.showNewMessage();
        }
        this.loginStatusChecked = true;

        return data.me;
      }),
    );
  }
}
