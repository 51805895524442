import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Booking,
  GetBookingByIdGQL,
  GetBookingByIdQuery,
  GetBookingByIdQueryVariables,
  GetCurrentUserGQL,
  SubscribeToBookingUpdatesGQL,
  SubscribeToChannelGQL,
  User,
} from '../../../../generated/graphql';
import { MetaService } from '../../../services/meta.service';
import { getStatus } from '../../bookingUtils';
import { extendBooking, ExtendedBooking, groupMessages } from '../../channelUtils';

@Component({
  selector: 'app-bookings-detail',
  templateUrl: './bookings-detail.component.html',
  styleUrls: ['./bookings-detail.component.scss'],
})
export class BookingsDetailComponent implements OnInit {
  constructor(
    private getBooking: GetBookingByIdGQL,
    private getCurrentUser: GetCurrentUserGQL,
    private lastMessageSubscription: SubscribeToChannelGQL,
    private bookingChangesSubscription: SubscribeToBookingUpdatesGQL,
    private route: ActivatedRoute,
    private meta: MetaService,
  ) {}

  channelId: number;
  booking: Observable<Booking>;
  bookingLoading = true;
  bookingStatus: string;
  bookingQuery: QueryRef<GetBookingByIdQuery, GetBookingByIdQueryVariables>;
  currentUser: Observable<User>;
  currentUserId: number;

  messagesSubscribed = false;
  bookingSubscribed = false;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.setBooking(parseInt(params.bookingId, 10));
    });

    this.currentUser = this.getCurrentUser.watch().valueChanges.pipe(
      map(({ data }) => {
        this.currentUserId = data.me.id;
        return data.me;
      }),
    );
  }

  private setBooking(bookingId: number): void {
    this.bookingQuery = this.getBooking.watch(
      { bookingId },
      { fetchPolicy: 'network-only', nextFetchPolicy: 'network-only' },
    );
    this.booking = this.bookingQuery.valueChanges.pipe(
      map(({ data, loading }) => {
        this.bookingLoading = loading;

        if (!this.messagesSubscribed && data) {
          this.subscribeToNewMessages(data.bookingById.channel.id);
        }

        if (!this.bookingSubscribed && data) {
          this.subscribeToBookingUpdates(data.bookingById.id);
        }

        this.bookingStatus = getStatus(data.bookingById, this.currentUserId);

        const modifiedBooking: ExtendedBooking = extendBooking(data.bookingById);
        if (modifiedBooking.channel.messages && modifiedBooking.channel.messages.length > 0) {
          modifiedBooking.channel.groupedMessages = groupMessages(modifiedBooking.channel.messages);
        }

        this.meta.setTitleWithDefaults(`Booking request for ${data.bookingById.page.name}`);

        if (!modifiedBooking.channel.booking) {
          modifiedBooking.channel.booking = modifiedBooking;
        }

        return modifiedBooking;
      }),
    );
  }

  private subscribeToBookingUpdates(bookingId: number): void {
    this.bookingQuery.subscribeToMore({
      document: this.bookingChangesSubscription.document,
      variables: {
        bookingId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.bookingChanges) {
          return prev;
        }

        // TODO: fix this in backend?
        const data = {
          bookingById: {
            ...subscriptionData.data.bookingChanges,
            isBooker:
              !!subscriptionData.data.bookingChanges &&
              subscriptionData.data.bookingChanges.user.id === this.currentUserId,
          },
        };

        return data;
      },
    });

    this.bookingSubscribed = true;
  }

  private subscribeToNewMessages(channelId: number): void {
    this.bookingQuery.subscribeToMore({
      document: this.lastMessageSubscription.document,
      variables: {
        channelId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        // Do we need to update here? We don't seem to be rendering the bot messages either way
        // prev.bookingById.channel.messages = [
        //   ...prev.bookingById.channel.messages,
        //   subscriptionData.data.channelMessages,
        // ];

        return prev;
      },
    });

    this.messagesSubscribed = true;
  }
}
