<header fxLayoutAlign="center center" class="container bg-gray-16">
  <p i18n>Preview mode</p>
  <button fxHide.lt-sm fxFlexAlign="end" (click)="onModalClose()" class="gig-button light" i18n>Go to edit mode</button>
  <button
    fxHide
    fxShow.lt-sm
    fxFlexAlign="end"
    fxLayoutAlign.lt-sm="center center"
    (click)="onModalClose()"
    class="gig-button icon"
    i18n>
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</header>

<main>
  <app-artist-detail [isPreviewMode]="true" [pageId]="data.pageId"></app-artist-detail>
</main>
