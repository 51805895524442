import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetPagesByUserGQL, Page, SendReviewInviteGQL } from '../../../../generated/graphql';
import { TranslationsService } from '../../../services/translations.service';

@Component({
  selector: 'app-invite-external-review',
  templateUrl: './invite-external-review.component.html',
  styleUrls: ['./invite-external-review.component.scss'],
})
export class InviteExternalReviewComponent implements OnInit {
  public pages$: Observable<Page[]>;

  public requestFormGroup: FormGroup;
  public isLoading: boolean = false;
  public selectedPage: Page;

  get eventTypes() {
    return this.translations.getGigTypesList();
  }

  constructor(
    public translations: TranslationsService,
    private sendInvite: SendReviewInviteGQL,
    private userPages: GetPagesByUserGQL,
    private snackbar: MatSnackBar,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getPages();

    this.requestFormGroup = new FormGroup({
      pageId: new FormControl(this.selectedPage?.id, [Validators.required]),
      email: new FormControl('', [Validators.required]),
      message: new FormControl(''),
    });
  }

  private getPages(): void {
    this.pages$ = this.userPages.watch().valueChanges.pipe(
      map((d) => {
        if (d.data.pagesByUser.length > 0) {
          this.selectedPage = d.data.pagesByUser[0];
          this.requestFormGroup.get('pageId').setValue(this.selectedPage.id);
        }
        return d.data.pagesByUser;
      }),
    );
  }

  public submitReviewInvite(): void {
    if (this.requestFormGroup.valid) {
      this.isLoading = true;

      this.sendInvite.mutate({ reviewInvite: this.requestFormGroup.value }).subscribe(
        () => {
          this.isLoading = false;
          this.snackbar.open($localize`Your invitation was sent successfully!`, undefined, { duration: 3000 });
          this.router.navigate(['']);
        },
        () => this.snackbar.open($localize`Could not save changes. Try again.`, undefined, { duration: 3000 }),
      );
    }
  }

  public onPageSelected(selectedPage: Page): void {
    this.selectedPage = selectedPage;
    this.requestFormGroup.get('pageId').setValue(this.selectedPage.id);
  }
}
