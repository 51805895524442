<section *ngIf="booking | async as booking; else loading" class="booking-sign" mat-dialog-content>
  <div *ngIf="booking.signatures?.length === 1" class="alert-header">
    <div *ngIf="haveISigned(booking, currentUserId)" i18n>Waiting for other party to sign</div>
    <div *ngIf="!haveISigned(booking, currentUserId)" i18n>Waiting for you to sign</div>
  </div>
  <h1 class="h5 gray-1 m-b--25 f-weight--600" i18n>Signing contract</h1>

  <div class="booking-sign-reminder">
    <p i18n class="mat-body gray-2 m-b--50" i18n
      >Make sure you have included everything that has been negotiated before signing the contract.
    </p>
  </div>
  <mat-divider></mat-divider>
  <div class="booking-sign-part">
    <h2 class="gray-1 f-size--16 m--0 m-b--25" i18n>Parties</h2>
    <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="32px">
      <div class="booking-sign-party" fxFlex="50%">
        <div fxLayout fxLayoutAlign="start center">
          <app-avatar class="m-r--7" [small]="true" [user]="booking.user"></app-avatar>
          <p class="mat-body gray-2"> {{ booking.user?.firstName }} {{ booking.user?.lastName.charAt(0) + '.' }} </p>
        </div>
      </div>
      <div class="booking-sign-party" fxFlex="50%">
        <div fxLayout fxLayoutAlign="start center">
          <app-avatar class="m-r--7" [small]="true" [profilePicture]="booking.page.profilePicture"></app-avatar>
          <p class="mat-body gray-2">
            {{ booking.page.user?.firstName }} {{ booking.page.user?.lastName.charAt(0) + '.' }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="booking-sign-part">
    <h2 class="gray-1 f-size--16 m--0 m-b--15" i18n>Description</h2>
    <p class="m-b--15"><span i18n>Gig type</span>: {{ booking.type }}</p>
    <p class="m--0" *ngIf="booking.message; else noMessage">{{ booking.message }} </p>
    <ng-template #noMessage><span i18n>No description</span></ng-template>
  </div>

  <mat-divider></mat-divider>

  <div class="booking-sign-part">
    <h2 class="gray-1 f-size--16 m--0 m-b--10" i18n>Location</h2>
    <p class="mat-body gray-2 m--0">{{ booking.place.formattedAddress }}</p>
  </div>

  <mat-divider></mat-divider>

  <div class="booking-sign-part">
    <h2 class="gray-1 f-size--16 m--0 m-b--10" i18n>Travel allowance</h2>
    <p *ngIf="booking.travelPrice" class="mat-body gray-2 m--0"
      >{{ booking.travelPrice }} {{ booking.dates[0].priceCurrency || 'SEK' }}</p
    >
    <p *ngIf="!booking.travelPrice" class="mat-body gray-2 m--0" i18n>Not set</p>
  </div>

  <mat-divider></mat-divider>

  <div class="booking-sign-part">
    <p class="m-b--15 f-weight--600">Equipment provided by the booker: </p>
    <app-equipment-icons class="d--block m-b--36" [equipments]="booking.supplies"></app-equipment-icons>

    <p class="m-b--10 f-weight--600">Equipment provided by the artist: </p>
    <p *ngIf="booking.supplyStatus === EQUIPMENT_STATUS.Supplied" i18n>
      {{ booking.calculatedPricing.supplies | priceSpaceFormat }} {{ booking.calculatedPricing.currency }}
    </p>

    <p *ngIf="booking.supplyStatus !== EQUIPMENT_STATUS.Supplied" fxLayoutAlign="start center">
      <span fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="svg-icon" svgIcon="none"></mat-icon>
        <span i18n>None</span>
      </span>
    </p>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="column" class="booking-sign-part">
    <h2 class="gray-1 f-size--16 m--0 m-b--25" i18n>Date</h2>

    <div class="m-b--7" fxFlex="100%">
      <span fxFlex="50%" class="text-underline f-size--10 gray-2" i18n>Dates</span>
      <span fxFlex="50%" class="text-underline f-size--10 gray-2" i18n>Set-time</span>
    </div>

    <div *ngFor="let date of acceptedDates">
      <p class="m-t--0 m-b--10" fxFlex="100%">
        <span fxFlex="50%" class="f-size--15">{{ date.date | apiDate | date }}</span>
        <span fxFlex="50%">
          <span *ngIf="date.startTime">{{ timestampToTimeString(date.startTime) }}</span> -
          <span *ngIf="date.endTime">{{ timestampToTimeString(date.endTime) }}</span>
        </span>
      </p>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="booking-sign-part">
    <h2 class="gray-1 f-size--16 m--0 m-b--20">
      <span class="m-r--10" i18n>Pricing</span> <span class="f-size--12 f-weight--400" i18n>Invoiced after gig</span>
    </h2>

    <p class="m-b--15" fxLayoutAlign="space-between center">
      <span class="f-size--16">{{ acceptedDates.length }} <span i18n>dates</span></span>
      <span class="f-size--16"
        >{{ booking.calculatedPricing.baseDates | priceSpaceFormat }} {{ booking.calculatedPricing.currency }}</span
      >
    </p>

    <p *ngIf="booking.supplyStatus === EQUIPMENT_STATUS.Supplied" fxLayoutAlign="space-between center" class="m-b--16">
      <span i18n>Equipment</span>
      <span
        >{{ booking.calculatedPricing.supplies | priceSpaceFormat }}
        {{ booking.calculatedPricing.currency || 'SEK' }}</span
      >
    </p>

    <p class="m-b--36" fxLayoutAlign="space-between center">
      <span class="f-size--16">
        <a [href]="POLICY_LINK" class="gray-1 text-underline"
          ><span i18n>Booking fee</span> ({{ displayableFee(feePerc) }}%)</a
        >
      </span>
      <span class="f-size--16">
        <span *ngIf="amIBooker">
          {{ booking.calculatedPricing.booker.feePrice | priceSpaceFormat }} {{ booking.calculatedPricing.currency }}
        </span>
        <span *ngIf="!amIBooker">
          {{ booking.calculatedPricing.artist.feePrice | priceSpaceFormat }} {{ booking.calculatedPricing.currency }}
        </span>
      </span>
    </p>

    <p *ngIf="booking.travelPrice" fxLayoutAlign="space-between center" class="m-b--16">
      <span i18n>Travel expense</span>
      <span>{{ booking.travelPrice | priceSpaceFormat }} {{ booking.calculatedPricing.currency || 'SEK' }}</span>
    </p>

    <div fxLayout="column" fxLayoutGap="8px">
      <p class="m-b--10" fxLayoutAlign="space-between center">
        <span class="f-size--16 f-weight--600"
          ><span i18n>Total price</span> ({{ booking.calculatedPricing.currency }})<span class="bright-red">*</span>
        </span>
        <span class="f-size--16 f-weight--600">
          <strong *ngIf="amIBooker">
            {{ booking.calculatedPricing.booker.final | priceSpaceFormat }}
            {{ booking.calculatedPricing.currency || 'SEK' }}
          </strong>
          <strong *ngIf="!amIBooker">
            {{ booking.calculatedPricing.artist.final | priceSpaceFormat }}
            {{ booking.calculatedPricing.currency || 'SEK' }}
          </strong>
        </span>
      </p>
      <span i18n class="f-size--12 gray-3 vat">*Excluding VAT 25%</span>
    </div>
  </div>

  <mat-divider *ngIf="booking?.page?.user?.id !== (currentUser | async)?.id"></mat-divider>

  <div fxLayout="column">
    <button
      fxFlex="100%"
      class="m-b--10 gig-button primary"
      [disabled]="loadingSign"
      *ngIf="!artistSignature && booking?.page?.user?.id === (currentUser | async)?.id"
      (click)="sign(booking.id, 'artist')">
      <span i18n>Sign</span>
    </button>

    <button
      fxFlex="100%"
      class="m-b--10 gig-button primary"
      *ngIf="!bookerSignature && booking?.user?.id === (currentUser | async)?.id"
      (click)="sign(booking.id, 'booker')"
      [disabled]="loadingSign"
      i18n>
      Sign
    </button>

    <button fxFlex="100%" matDialogClose class="m-b--10 gig-button stroked">
      <span i18n>Close</span>
    </button>
  </div>
</section>

<ng-template #loading>
  <div class="booking-sign gray-1 mat-body" i18n="@@loading"> Loading... </div>
</ng-template>
