import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../shared/shared.module';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';
import { NotificationsDropdownComponent } from './pages/notifications-dropdown/notifications-dropdown.component';
import { NotificationsListComponent } from './pages/notifications-list/notifications-list.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';

@NgModule({
  declarations: [
    NotificationCardComponent,
    NotificationsComponent,
    NotificationsDropdownComponent,
    NotificationsListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot([{ path: 'notifications', component: NotificationsComponent }], { relativeLinkResolution: 'legacy' }),
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    SharedModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [NotificationCardComponent, NotificationsDropdownComponent],
})
export class NotificationsModule {}
