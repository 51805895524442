<div class="question-container" (click)="isPanelOpened = !isPanelOpened">
  <div class="expansion-panel" [ngClass]="{ opened: isPanelOpened }">
    <div fxLayoutAlign="space-between center" class="expansion-panel--header">
      <p>{{ title }} </p>
      <mat-icon svgIcon="expand-down-medium"></mat-icon>
    </div>

    <div *ngIf="isPanelOpened" fxLayout="column" fxLayoutGap="34px" class="expansion-panel--content" [@expansion]>
      <p *ngIf="!isHTML">{{ content }}</p>
      <div *ngIf="isHTML" [innerHTML]="content"></div>
    </div>
  </div>
</div>
