<div
  class="bot-message mat-caption gray-2"
  [ngSwitch]="parsedMessage.type"
  [ngClass]="parsedMessage.type.toLowerCase()">
  <span *ngSwitchCase="BOT_MESSAGE_TYPE.ComponentModified" i18n>
    <mat-icon inline class="blue">edit</mat-icon> <strong>{{ displayName }}</strong> has modified the
    <ng-container *ngTemplateOutlet="componentType"></ng-container>.
  </span>

  <span *ngSwitchCase="BOT_MESSAGE_TYPE.ComponentSigned" i18n>
    <mat-icon inline class="green">done</mat-icon> <strong>{{ displayName }}</strong> has signed the
    <ng-container *ngTemplateOutlet="componentType"></ng-container>.
  </span>

  <span *ngSwitchCase="BOT_MESSAGE_TYPE.ContractSigned" i18n>
    <mat-icon inline class="green">done</mat-icon> <strong>{{ displayName }}</strong> has signed the contract.
  </span>

  <span *ngSwitchCase="BOT_MESSAGE_TYPE.ContractDeclinedArtist" i18n="@@contractDeclined">
    <mat-icon inline class="red">clear</mat-icon> <strong>{{ displayName }}</strong> has declined the contract.
  </span>

  <span *ngSwitchCase="BOT_MESSAGE_TYPE.ContractDeclinedBooker" i18n="@@contractDeclined">
    <mat-icon inline class="red">clear</mat-icon> <strong>{{ displayName }}</strong> has declined the contract.
  </span>

  <span *ngSwitchCase="BOT_MESSAGE_TYPE.FileAttached">
    <mat-icon inline class="blue">cloud_upload</mat-icon> <strong>{{ displayName }}</strong>
    <span i18n="[user] has attached [file name]">has attached</span>
    <a target="blank" download="{{ downloadName }}" [href]="downloadUrl">{{ message.file.fileName }}</a>
  </span>

  <span *ngSwitchCase="BOT_MESSAGE_TYPE.TravelPriceChanged" i18n="@@travelPriceChanged">
    <mat-icon inline class="green">done</mat-icon> <strong>{{ displayName }}</strong> has added travel allowance.
  </span>

  <span *ngSwitchCase="BOT_MESSAGE_TYPE.SuppliesRequest">
    <span *ngIf="parsedMessage.isPage" i18n="@@suppliesDenied">
      <mat-icon inline class="red">clear</mat-icon> <strong>{{ displayName }}</strong> has denied the price for
      <ng-container *ngTemplateOutlet="componentType"></ng-container>.
    </span>

    <span *ngIf="!parsedMessage.isPage" i18n="@@suppliesRequested">
      <mat-icon inline class="blue">edit</mat-icon> <strong>{{ displayName }}</strong> has requested
      <ng-container *ngTemplateOutlet="componentType"></ng-container>.
    </span>
  </span>

  <span *ngSwitchCase="BOT_MESSAGE_TYPE.SuppliesPriceChange" i18n="@@suppliesPriceChanged">
    <mat-icon inline class="blue">edit</mat-icon> <strong>{{ displayName }}</strong> has proposed a price for
    <ng-container *ngTemplateOutlet="componentType"></ng-container>.
  </span>

  <span *ngSwitchCase="BOT_MESSAGE_TYPE.SuppliesSigned" i18n="@@suppliesSigned">
    <mat-icon inline class="blue">edit</mat-icon> <strong>{{ displayName }}</strong> has accepted the price for
    <ng-container *ngTemplateOutlet="componentType"></ng-container>.
  </span>
</div>
<ng-template #componentType>
  <span [ngSwitch]="parsedMessage.componentType">
    <ng-container *ngSwitchCase="BOOKING_COMPONENT_TYPE.Date" i18n>date</ng-container>
    <ng-container *ngSwitchCase="BOOKING_COMPONENT_TYPE.Others" i18n>other</ng-container>
    <ng-container *ngSwitchCase="BOOKING_COMPONENT_TYPE.Place" i18n>place</ng-container>
    <ng-container *ngSwitchCase="BOOKING_COMPONENT_TYPE.Price" i18n>price</ng-container>
    <ng-container *ngSwitchCase="BOOKING_COMPONENT_TYPE.Supplies" i18n>equipment</ng-container>
  </span>
</ng-template>
