import { Component, Input, OnInit } from '@angular/core';
import { BookingComponentItemType, BotMessage, BotMessageType } from '../../../../generated/graphql';
import { ExtendedChannelMessage } from '../../../bookings/channelUtils';

@Component({
  selector: 'app-bot-message',
  templateUrl: './bot-message.component.html',
  styleUrls: ['./bot-message.component.scss'],
})
export class BotMessageComponent implements OnInit {
  @Input() message: ExtendedChannelMessage;

  public BOOKING_COMPONENT_TYPE = BookingComponentItemType;
  public BOT_MESSAGE_TYPE = BotMessageType;

  public parsedMessage: BotMessage;
  public downloadName: string = '';
  public displayName: string = '';
  public downloadUrl: string = '';

  constructor() {}

  ngOnInit() {
    this.parsedMessage = JSON.parse(this.message.body);

    this.displayName = this.parsedMessage.isPage
      ? this.parsedMessage.page.pageName
      : this.parsedMessage.sender.fullName;

    if (this.message.file) {
      this.downloadUrl = `https://res.cloudinary.com/hthruuqgk/raw/upload/v1566908756/${this.message.file.fileName}`;
      this.downloadName = `${this.message.file.fileName}.${this.message.file.fileExtension}`;
    }
  }
}
