import { Injectable } from '@angular/core';
import { Gig, GigSlotInput, GigType, UpdateGigGQL } from '../../generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class GigsService {
  constructor(private editGig: UpdateGigGQL) {}

  urlFilterExists = false;
  urlFilter: { tags?: number[]; sorting?: string; locations?: any; types?: GigType[] } = {};
  filterHasUpdated: { tags: boolean; sorting: boolean; locations: boolean; types: boolean } = {
    locations: false,
    sorting: false,
    tags: false,
    types: false,
  };

  isFilteredHandled() {
    if (!this.urlFilterExists) {
      return true;
    }
    return (
      this.urlFilterExists &&
      this.filterHasUpdated.locations &&
      this.filterHasUpdated.tags &&
      this.filterHasUpdated.types &&
      this.filterHasUpdated.sorting
    );
  }

  updateSlot(gig: Gig, slot: GigSlotInput) {
    return this.editGig.mutate({
      gig: {
        id: gig.id,
        name: gig.name,
        type: gig.type,
        placeId: gig.placeId,
        slots: [
          {
            id: slot.id,
            date: slot.date,
            price: slot.price,
            nullPrice: slot.nullPrice,
            tags: slot.tags,
            startTime: slot.startTime,
            endTime: slot.endTime,
          },
        ],
      },
    });
  }
}
