import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { GetUserAdminGQL } from '../../generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private getMe: GetUserAdminGQL, private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const canEnter = await new Promise((res, rej) => {
      const subscription = this.getMe
        .fetch()
        .pipe(
          first(),
          map(({ data }) => data.me),
        )
        .subscribe(u => {
          if (!u || !u.isAdmin) {
            this.router.navigate(['']);
            subscription.unsubscribe();
            res(false);
            return false;
          }

          subscription.unsubscribe();
          res(true);
          return true;
        });
    });
    return canEnter as boolean;
  }
}
