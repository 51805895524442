<div
  class="modal-header"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutAlign.gt-md="space-between"
  mat-dialog-title
  fxLayoutGap.lt-md="12px">
  <div matDialogClose class="icon-container" fxLayout="column" fxHide fxShow.lt-md>
    <mat-icon svgIcon="expand-left"></mat-icon>
  </div>
  <h2 i18n *ngIf="!hasTemplates">Choose a previous gig</h2>
  <h2 i18n *ngIf="hasTemplates">Ready concepts for {{ data.orgName }}</h2>
  <div>
    <button class="small-round" matDialogClose fxHide fxShow.gt-md>x</button>
  </div>
</div>
<div class="space"></div>
<div class="modal-container" mat-dialog-content>
  <div fxLayout="column" class="items-container">
    <app-previous-gig-item
      (click)="selectGig(gig)"
      *ngFor="let gig of gigs | async"
      [gig]="gig"></app-previous-gig-item>
    <app-previous-gig-item
      (click)="selectTemplate(template)"
      *ngFor="let template of data?.templates"
      [template]="template"></app-previous-gig-item>
  </div>
</div>
