import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { GetCurrentUserGQL, User } from '../../../../../generated/graphql';
import { getTimeLengthOptionsForSelect, getTimeOptionsForSelectDropdown } from '../../../../helpers/dates';
import { IconSubsets, IconsRegistryService } from '../../../../services/icons-registry.service';
import { SelectInputOptions } from '../../../../shared/components/inputs/select-input/select-input.component';

@Component({
  selector: 'app-create-gig-form-details',
  templateUrl: './create-gig-form-details.component.html',
  styleUrls: ['./create-gig-form-details.component.scss'],
})
export class CreateGigFormDetailsComponent implements OnInit {
  @Input() createGigForm: FormGroup;

  public gigStartTimeOptions: SelectInputOptions[] = [];
  public timeLengthOptions: SelectInputOptions[] = [];
  public todayDate: Date;
  public user: User;

  public get budgetAllLabel(): string {
    return $localize`Set your budget per date or act`;
  }

  public get budgetLiveLabel(): string {
    return $localize`Price per Artist/Band`;
  }

  public get budgetDJLabel(): string {
    return $localize`Price per DJ`;
  }

  public get selectedDates(): Date[] {
    return this.createGigForm.value.tempSlotDate?.map((d) => new Date(d));
  }

  public get totalPriceWithFee(): number {
    return Math.round(
      this.createGigForm.value.budgetAll *
        (this.createGigForm.value.tempSlotDate?.length || 1) *
        (this.user?.fee || 1.15),
    );
  }

  public get livePriceWithFee(): number {
    return Math.round(
      this.createGigForm.value.budgetLive *
        (this.createGigForm.value.tempSlotDate?.length || 1) *
        (this.user?.fee || 1.15),
    );
  }

  public get djPriceWithFee(): number {
    return Math.round(
      this.createGigForm.value.budgetDJ *
        (this.createGigForm.value.tempSlotDate?.length || 1) *
        (this.user?.fee || 1.15),
    );
  }

  public constructor(private getCurrentUserGQL: GetCurrentUserGQL, private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION, IconSubsets.EQUIPMENT]);
  }

  public ngOnInit(): void {
    this.todayDate = new Date();

    this.gigStartTimeOptions = getTimeOptionsForSelectDropdown();
    this.timeLengthOptions = getTimeLengthOptionsForSelect();

    this.getCurrentUserGQL
      .watch()
      .valueChanges.pipe(map((d) => (this.user = d.data.me)))
      .subscribe();
  }

  public areAllSupliesDeselected(): boolean {
    return (
      !this.createGigForm.get('suppliesMixer').value &&
      !this.createGigForm.get('suppliesSpeakers').value &&
      !this.createGigForm.get('suppliesPa').value
    );
  }

  public selectSupplyType(type: number): void {
    switch (type) {
      case 0:
        this.createGigForm.get('suppliesMixer').setValue(!this.createGigForm.get('suppliesMixer').value);
        break;
      case 1:
        this.createGigForm.get('suppliesPa').setValue(!this.createGigForm.get('suppliesPa').value);
        break;
      case 2:
        this.createGigForm.get('suppliesSpeakers').setValue(!this.createGigForm.get('suppliesSpeakers').value);
        break;
      case -1:
        this.createGigForm.get('suppliesMixer').setValue(false);
        this.createGigForm.get('suppliesSpeakers').setValue(false);
        this.createGigForm.get('suppliesPa').setValue(false);
        break;
    }
  }
}
