import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import * as auth0 from 'auth0-js';
import jsCookie from 'js-cookie';
import { BehaviorSubject } from 'rxjs';
import { Pool } from '../../classes/Pool';
import { environment } from '../../environments/environment';
import { GetCurrentUserGQL, RegisterUserGQL } from '../../generated/graphql';
import { apolloCache } from '../../utils/ApolloUtils';
import { AnalyticsService } from './analytics.service';
import { RequestService } from './reqeust.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    private router: Router,
    private request: RequestService,
    private apollo: Apollo,
    private addUser: RegisterUserGQL,
    private analytics: AnalyticsService,
    private getUser: GetCurrentUserGQL,
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  isServer: boolean;
  auth0 = new auth0.WebAuth({
    clientID: environment.Auth0.clientId,
    domain: environment.Auth0.domain,
    redirectUri: environment.Auth0.callbackUrl,
    responseType: 'token id_token',
    scope: 'openid email profile',
  });

  urlPool: Pool<string> = new Pool(2);

  public loginStatusChanges = new BehaviorSubject<boolean>(false);

  private setReturnUrl() {
    if (!!this.urlPool.getItemAt(1)) {
      localStorage.setItem('returnUrl', this.urlPool.getItemAt(1));
    }
  }
  login(username: string, password: string, cb, forcedReturn?: string): void {
    if (!forcedReturn) {
      this.setReturnUrl();
    } else {
      localStorage.setItem('returnUrl', forcedReturn);
    }

    this.analytics.emitEvent('login');

    this.auth0.login(
      {
        username,
        password,
        realm: environment.Auth0.connection,
      },
      (err, _) => {
        cb(err);
      },
    );
  }

  socialLogin(platform?: string): void {
    this.setReturnUrl();
    this.auth0.authorize({
      connection: platform,
    });
  }

  async logout(redirectToHome: boolean = true): Promise<void> {
    jsCookie.remove(`gigital-isLoggedIn-${environment.appEnvironment}`);
    jsCookie.remove(`gigital-token-${environment.appEnvironment}`);

    // Clear store and refetch user (so we know user == null)
    // this.apollo.getClient().store.reset();
    // await this.apollo.getClient().cache.reset();
    await this.apollo.client.cache.reset();
    const userSub = this.getUser.fetch().subscribe((d) => {
      if (redirectToHome) {
        this.router.navigate(['/']);
      }

      // this.apollo.getClient().clearStore();
      this.apollo.client.clearStore();
      this.loginStatusChanges.next(false);

      userSub.unsubscribe();
    });
  }

  handleAuth(): void {
    if (!this.isServer) {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          window.location.hash = '';
          this.localLogin(authResult);
        } else if (err) {
          console.error(`Error authenticating: ${err.error}`);
        }
      });
    }
  }

  async checkLogin(): Promise<void> {
    console.log('check logged in AUTH');
    const valid = this.isValid();
    if (!valid) {
      console.log('check logged in NOT VALID AUTH');
      await this.renewTokens();
    } else {
      console.log('check logged in VALID AUTH');
      this.loginStatusChanges.next(true);
    }
  }

  register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    cb,
    orgId?: string,
    forcedReturn?: string,
  ): void {
    this.auth0.signup({ email, password, connection: environment.Auth0.connection }, (signupErr, data) => {
      if (signupErr) {
        cb(signupErr);
        return;
      }

      this.analytics.emitEvent('signup_complete', { social: false });

      const auth0id = `auth0|${data.Id}`;
      this.addUser.mutate({ user: { email, auth0id, firstName, lastName, social: false, orgId } }).subscribe(() => {
        this.login(
          email,
          password,
          (loginErr) => {
            if (loginErr) {
              cb(loginErr);
            }
          },
          forcedReturn,
        );
      });
    });
  }

  resetPassword(email: string, cb): void {
    this.auth0.changePassword(
      {
        email,
        connection: environment.Auth0.connection,
      },
      (err, data) => {
        cb(err, data);
      },
    );
  }

  private isValid(): boolean {
    console.log('check isValid in AUTH');
    const date = this.getTokenExpirationDate();

    if (date == null) {
      console.log('isValid false in AUTH');
      return false;
    }
    const validity = date.valueOf() > new Date().valueOf();
    console.log('isValid in AUTH', validity);
    return validity;
  }

  public getTokenExpirationDate(): Date {
    console.log('get token exp in AUTH');
    if (!this.token) {
      return null;
    }

    const token = this.token;
    if (!token.idTokenPayload || !token.idTokenPayload.hasOwnProperty('exp')) {
      return null;
    }
    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(token.idTokenPayload.exp);

    return date;
  }

  private renewTokens() {
    console.log('renew tokens in AUTH');
    if (!this.isServer) {
      return new Promise((resolve) => {
        this.auth0.checkSession(
          {
            scope: 'openid email name',
          },
          (err, authResult) => {
            console.log('checkSession in AUTH', err, authResult);
            if (authResult && authResult.accessToken && authResult.idToken) {
              console.log('checkSession success in AUTH');
              resolve(this.localLogin(authResult, true));
            } else if (err) {
              console.log('checkSession err in AUTH');
              console.error(err);
              resolve(this.logout());
            }
          },
        );
      });
    }
    Promise.reject();
  }

  private async localLogin(authResult, renewedToken = false) {
    jsCookie.set(`gigital-token-${environment.appEnvironment}`, authResult, { sameSite: 'Lax' });
    jsCookie.set(`gigital-isLoggedIn-${environment.appEnvironment}`, true, { sameSite: 'Lax' });

    const socialStr: string = authResult.idTokenPayload.sub.split('|')[0];
    const isSocial = socialStr === 'google-oauth2' || socialStr === 'facebook';

    if (isSocial) {
      console.log(authResult);
      const splitName: string[] = authResult.idTokenPayload.name.split(' ');
      const firstName = splitName[0];
      const lastName = splitName[splitName.length - 1];
      await this.addUser
        .mutate({
          user: {
            firstName,
            lastName,
            social: true,
            auth0id: authResult.idTokenPayload.sub,
            email: authResult.idTokenPayload.email,
          },
        })
        .subscribe(() => {
          this.postLocalLogin(authResult, renewedToken);
        });
    } else {
      this.postLocalLogin(authResult, renewedToken);
    }
  }

  private postLocalLogin(authResult, renewedToken = false) {
    apolloCache.writeData({ data: { isLoggedIn: true } });

    this.loginStatusChanges.next(true);
  }

  get isLoggedIn(): boolean {
    if (this.isServer) {
      return !!this.request.cookies[`gigital-isLoggedIn-${environment.appEnvironment}`];
    }
    return !!jsCookie.get(`gigital-isLoggedIn-${environment.appEnvironment}`);
  }

  get token() {
    if (this.isServer) {
      if (this.request.cookies && this.request.cookies[`gigital-token-${environment.appEnvironment}`]) {
        try {
          return JSON.parse(this.request.cookies[`gigital-token-${environment.appEnvironment}`]);
        } catch {
          throw Error(`Couldn't parse JWT in request cookies.`);
        }
      } else {
        return undefined;
      }
    }

    return jsCookie.getJSON(`gigital-token-${environment.appEnvironment}`);
  }
}
