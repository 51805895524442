<h1 mat-dialog-title i18n class="h5 gray-1">Attach a file</h1>
<mat-dialog-content>
  <input type="file" #file (change)="onFileChanges($event.target.files)" style="display: none;" />
  <div fxLayout="column" fxLayoutGap="12px" class="upload-area">
    <div *ngIf="selectedFile" fxLayout="column" fxLayoutAlign="center center">
      <p>{{ selectedFile.name }}</p>
      <small>{{ selectedFileSize }}</small>
    </div>
    <div fxLayout="row" fxLayoutAlign="center">
      <button mat-button (click)="clickSelect()" i18n>Choose file</button>
    </div>
  </div>
  <!-- <div fxLayout="column">
    <small>or select a previosly uploaded file</small>
    <button mat-button (click)="clickGetUserFiles()">Previous files</button>
    <div *ngIf="!!files">
      <div *ngFor="let file of files | async">
        <button mat-button (click)="clickUpload(file.id)">{{ file.fileName }}</button>
        <small>{{ file.mimetype }}</small>
      </div>
    </div>
  </div> -->
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <button [disabled]="fileTooLargeError" mat-flat-button color="primary" (click)="clickUpload()" i18n="@@upload"
      >Upload</button
    >
    <button mat-button (click)="clickCancel()" i18n="@@cancel">Cancel</button>
  </div>
  <div fxLayout="row" fxLayoutAlign="end">
    <mat-error *ngIf="fileTooLargeError" i18n>Your file must be smaller than 10mb.</mat-error>
  </div>
</mat-dialog-content>
