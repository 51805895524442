<div *ngIf="gig | async as gig" class="apply-container" fxLayout="column">
  <a fxHide fxShow.gt-md [disabled]="loading" [routerLink]="['/gigs', gig.id]" class="back-button cursor-pointer"
    ><img src="assets/icons/navigation/expand-left.svg"
  /></a>

  <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="start center" class="page-title">
    <a
      fxShow
      fxHide.gt-md
      [disabled]="loading"
      [routerLink]="['/gigs', gig.id]"
      class="back-button-inline cursor-pointer"
      ><img src="assets/icons/navigation/expand-left.svg"
    /></a>
    <h1 i18n>Confirm and apply</h1>
  </div>
  <label class="action-label" i18n fxShow fxHide.lt-md>Select artist profile to apply as</label>
  <form
    [formGroup]="applyGroup"
    fxLayout="column-reverse"
    fxLayout.gt-md="row"
    fxLayoutGap.lt-md="32px"
    fxLayoutAlign="space-between"
    class="section-info">
    <div fxFlex="45%" fxLayout="column" fxLayoutGap="32px">
      <div fxLayout="column" fxLayoutGap="64px" class="text-inputs-container">
        <div fxLayout="column">
          <label class="action-label" i18n fxHide fxShow.lt-md>Select artist profile to apply as</label>
          <app-artist-dropdown
            [hasApplied]="appliedPages | async"
            (selectionChanges)="selectedPageChanges($event)"
            [artists]="pages | async">
          </app-artist-dropdown>
        </div>
        <div fxLayout="column" fxFlex="66%">
          <div class="message-input-container" fxLayout="column">
            <label class="action-label" i18n>Message</label>
            <textarea
              fxFlex="grow"
              class="gig-text-input"
              formControlName="message"
              i18n-placeholder
              placeholder="Enter message to the gig owner"></textarea>
          </div>
        </div>
      </div>
      <app-contact-info-warn *ngIf="warnContactInfo" (acknowledged)="acknowledgeContactInfo()"></app-contact-info-warn>
      <div fxLayout="row" fxLayoutGap="8px" class="section-actions">
        <button
          [disabled]="loading || pageHasApplied || warnContactInfo || applyGroup.invalid"
          i18n
          (click)="sendApplication()"
          class="gig-button primary"
          >Confirm and apply</button
        >
        <a [disabled]="true" [routerLink]="['/gigs', gig.id]" class="cursor-pointer back-link" i18n>Close</a>
      </div>
    </div>
    <div fxFlex="45%" fxLayout="column">
      <div class="info-card" [ngClass]="{ expanded: showAllSlots || !hasManySlots }">
        <div fxLayout="row" fxLayoutGap="14px">
          <div
            *ngIf="gig.images?.length > 0"
            class="image-container"
            [style.background-image]="displayedImageSafeUrl"
            fxHide
            fxShow.gt-md>
          </div>
          <div fxLayout="column" fxLayoutGap="14px">
            <h2 class="info-title">{{ gig.name }}</h2>
            <div fxLayout="column">
              <label class="info-label" i18n>Location</label>
              <span class="info-body">{{ gig.placeData.formattedAddress }}</span>
            </div>
            <div fxLayout="column">
              <label class="info-label" i18n>Slots selected</label>
              <span class="info-body" i18n>{{ selectedSlots }} out of {{ totalSlots }}</span>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayoutAlign="space-between" class="dates-header">
          <label i18n>Selected dates</label>
          <label i18n>Budget per slot</label>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" *ngFor="let slot of previewSlots | async">
          <span class="info-body">{{ slot.date | apiDate | date: 'dd MMM' }}</span>
          <span class="info-body">{{ slot.price }} SEK</span>
        </div>
        <div
          *ngIf="!showAllSlots && hasManySlots"
          (click)="showAllSlots = true"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="cursor-pointer"
          ><img src="assets/icons/gigs/Expand_down_light.svg"
        /></div>
        <ng-container *ngIf="showAllSlots">
          <div fxLayout="row" fxLayoutAlign="space-between" *ngFor="let slot of restSlots | async">
            <span class="info-body">{{ slot.date | apiDate | date: 'dd MMM' }}</span>
            <span class="info-body">{{ slot.price }} SEK</span>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</div>
