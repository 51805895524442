import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  BookingDateInput,
  CreateBookingGQL,
  Currency,
  EquipmentStatus,
  FindOnePageNoReviewsGQL,
  GigType,
  Page,
  SupplyTypes,
} from '../../../../generated/graphql';
import { contactInfoValidator } from '../../../../utils/contactInfo';
import { dateToApi, timeStringToTimestamp } from '../../../helpers/dates';
import { AnalyticsService } from '../../../services/analytics.service';
import { AuthService } from '../../../services/auth.service';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-artist-detail-book',
  templateUrl: './artist-detail-book.component.html',
  styleUrls: ['./artist-detail-book.component.scss'],
})
export class ArtistDetailBookComponent implements OnInit {
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  public page$: Observable<Page>;

  public isBookingCreated: boolean = false;
  public createRequestForm: FormGroup;
  public isLoading: boolean = false;
  public activeStep: number = 1;

  public pageSlug: string;
  public pageName: string;
  public pageId: number;

  public REQUEST_STEP_DATA: any[] = [
    { number: 1, title: $localize`Event Info` },
    { number: 2, title: $localize`Details` },
    { number: 3, title: $localize`Summary` },
  ];
  public EQUIPMENT_STATUS_ENUM = EquipmentStatus;

  public checkIfStepValid(stepNumber: number): boolean {
    if (stepNumber === this.REQUEST_STEP_DATA[0].number) {
      return (
        this.createRequestForm?.controls?.place?.valid &&
        this.createRequestForm?.controls?.title?.valid &&
        this.createRequestForm?.controls?.message?.valid
      );
    } else if (stepNumber === this.REQUEST_STEP_DATA[1].number) {
      return (
        this.createRequestForm?.controls?.slotDates?.valid &&
        this.createRequestForm?.controls?.startTime?.valid &&
        this.createRequestForm?.controls?.setLength?.valid &&
        this.createRequestForm?.controls?.budgetAll?.valid
      );
    } else {
      return false;
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,

    private getPageGQL: FindOnePageNoReviewsGQL,
    private postBooking: CreateBookingGQL,

    private activeRoute: ActivatedRoute,
    private router: Router,

    private iconService: IconsRegistryService,
    private analytics: AnalyticsService,
    private authService: AuthService,
  ) {
    this.iconService.registerIcons([IconSubsets.NAVIGATION, IconSubsets.EQUIPMENT]);
  }

  async ngOnInit(): Promise<void> {
    this.createRequestForm = new FormGroup({
      type: new FormControl(GigType.Business, Validators.required),
      place: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      message: new FormControl(null, [Validators.required, contactInfoValidator()]),
      slotDates: new FormControl([], [Validators.required]), // Only used to be able to store data in slotDates
      startTime: new FormControl('19:00'),
      setLength: new FormControl('00:30'),
      suppliesMixer: new FormControl(false),
      suppliesSpeakers: new FormControl(false),
      suppliesPa: new FormControl(false),
      budgetAll: new FormControl(null, [Validators.min(1), Validators.required]),
      supplyStatus: new FormControl(null),
      formattedAddress: new FormControl(null),
    });

    this.page$ = this.getPageGQL.watch({ slug: this.activeRoute.snapshot.params.pageId }).valueChanges.pipe(
      map((d) => d.data.findOnePage),
      tap((p) => {
        this.pageId = p.id;
        this.pageName = p.name;
        this.pageSlug = p?.slug;

        if (p?.price && !this.createRequestForm.value.budgetAll) {
          this.createRequestForm.get('budgetAll').setValue(p.price);
        }

        this.setDataFromStorage();
      }),
    );
  }

  private setDataFromStorage(): void {
    if (isPlatformBrowser(this.platformId)) {
      const localStorageData = localStorage.getItem('new_request_input:' + this.pageId);
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        this.createRequestForm.get('type').setValue(parsedData?.type);
        this.createRequestForm.get('place').setValue(parsedData?.place);
        this.createRequestForm.get('formattedAddress').setValue(parsedData?.formattedAddress);
        this.createRequestForm.get('title').setValue(parsedData?.title);
        this.createRequestForm.get('message').setValue(parsedData?.message);

        this.createRequestForm.get('slotDates').setValue(parsedData.slotDates);
        this.createRequestForm.get('startTime').setValue(parsedData.startTime);
        this.createRequestForm.get('setLength').setValue(parsedData.setLength);
        this.createRequestForm.get('budgetAll').setValue(parsedData?.budgetAll);

        this.createRequestForm.get('supplyStatus').setValue(parsedData.supplyStatus);
        this.createRequestForm.get('suppliesMixer').setValue(parsedData.suppliesMixer);
        this.createRequestForm.get('suppliesSpeakers').setValue(parsedData.suppliesSpeakers);
        this.createRequestForm.get('suppliesPa').setValue(parsedData.suppliesPa);
      }
    }
  }

  public changeStep(step: number): void {
    if (step > 0) {
      if (this.activeStep === 1 && !this.checkIfStepValid(1)) {
        this.createRequestForm.controls.place.markAsTouched();
        this.createRequestForm.controls.title.markAsTouched();
        this.createRequestForm.controls.message.markAsTouched();
        return;
      }
      if (this.activeStep === 2 && !this.checkIfStepValid(2)) {
        this.createRequestForm.controls.slotDates.markAsTouched();
        this.createRequestForm.controls.budgetAll.markAsTouched();
      } else {
        if (this.activeStep <= this.REQUEST_STEP_DATA.length) this.activeStep += step;
      }
    } else {
      if (this.activeStep > 1) this.activeStep += step;
    }
  }

  public goToStep(stepNumber: number): void {
    if (stepNumber === 1 || this.checkIfStepValid(stepNumber - 1)) {
      this.activeStep = stepNumber;
    }
  }

  public async sendBookingRequest(): Promise<void> {
    this.isLoading = true;

    if (!this.authService.isLoggedIn) {
      localStorage.setItem(
        'new_request_input:' + this.pageId,
        JSON.stringify({
          ...this.createRequestForm.value,
          createdAt: new Date().toISOString(),
        }),
      );
      localStorage.setItem('forceReturn', '/artists/' + this.pageSlug + '/book');
      localStorage.setItem('returnUrl', '/artists/' + this.pageId + '/book');
      this.router.navigate(['/signup']);
      this.router.navigate(['/signup']);
      return;
    } else {
      this.postBooking
        .mutate({
          booking: {
            pageId: this.pageId,
            type: this.createRequestForm.value.type,
            place: this.createRequestForm.value.place,
            title: this.createRequestForm.value.title,
            message: this.createRequestForm.value.message,
            price: { currency: Currency.Sek, value: parseFloat(this.createRequestForm.value.budgetAll) },
            supplyStatus: this.createRequestForm.value.supplyStatus ? EquipmentStatus.Requested : EquipmentStatus.None,
            supplies: this.getSelectedSupplies(),
            dates: this.getParsedBookingDates(),
          },
        })
        .subscribe((d) => {
          if (d.errors) {
            this.analytics.emitEvent(
              'booking_request_fail',
              { type: d.data.createBooking.type, page_id: this.pageId, page_name: this.pageName },
              { value: d.data.createBooking.dates.reduce((acc, v) => (acc += v.price), 0) },
            );
            throw new Error('Something went wrong with posting booking request');
          } else {
            this.router.navigate(['bookings', d.data.createBooking.id]);
            localStorage.removeItem('new_request_input:' + this.pageId);
            this.analytics.emitEvent(
              'booking_request_complete',
              { type: d.data.createBooking.type, page_id: this.pageId, page_name: this.pageName },
              { value: d.data.createBooking.dates.reduce((acc, v) => (acc += v.price), 0) * 0.4 },
            );
          }
          this.isLoading = false;
        });
    }
  }

  private getSelectedSupplies(): SupplyTypes[] {
    const supplies: SupplyTypes[] = [];
    if (this.createRequestForm.get('suppliesMixer').value) {
      supplies.push(SupplyTypes.Mixer);
    }
    if (this.createRequestForm.get('suppliesSpeakers').value) {
      supplies.push(SupplyTypes.Speakers);
    }
    if (this.createRequestForm.get('suppliesPa').value) {
      supplies.push(SupplyTypes.Pa);
    }

    return supplies;
  }

  private getParsedBookingDates(): BookingDateInput[] {
    return this.createRequestForm.value.slotDates.map((d) => {
      const input: BookingDateInput = {
        date: dateToApi(d),
        price: { currency: Currency.Sek, value: parseFloat(this.createRequestForm.get('budgetAll').value) },
      };

      const start = new Date(timeStringToTimestamp(new Date(d), this.createRequestForm.value.startTime));
      const [hours, minutes] = this.createRequestForm.value.setLength.split(':').map(Number);

      const end = new Date(start.getTime());
      end.setHours(end.getHours() + hours);
      end.setMinutes(end.getMinutes() + minutes);

      input.startTime = timeStringToTimestamp(new Date(d), this.createRequestForm.value.startTime);
      input.endTime = end.toISOString();

      return input;
    });
  }
}
