import { Component, Input, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { BookingDates, GetCurrentUserGQL, User, UserType } from '../../../../../generated/graphql';
import {
  amIBooker,
  DashboardBookingDateStatus,
  getDashboardBookingDateStatus,
} from '../../../../bookings/bookingUtils';
import { MEDIUM_DATE } from '../../../../helpers/dates';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';
import { TranslationsService } from '../../../../services/translations.service';

@Component({
  selector: 'app-dashboard-booking-date-item',
  templateUrl: './dashboard-booking-date-item.component.html',
  styleUrls: ['./dashboard-booking-date-item.component.scss'],
})
export class DashboardBookingDateItemComponent implements OnInit {
  @Input() bookingDate: BookingDates;
  @Input() userType: UserType;

  public amIBooker: boolean;

  BOOKING_DATE_STATUS = DashboardBookingDateStatus;
  DATE_FORMAT = MEDIUM_DATE;
  USER_TYPE = UserType;

  get getGigName(): string {
    return (
      this.bookingDate.booking.appliedTo?.name ||
      $localize`${this.translations.gigType(this.bookingDate.booking.type).translation} gig`
    );
  }

  get getBookingName(): string {
    return (
      this.bookingDate.booking.title ||
      this.bookingDate.booking.appliedTo?.name ||
      $localize`${this.translations.gigType(this.bookingDate.booking.type).translation} gig`
    );
  }

  get bookingDateStatus(): string {
    return getDashboardBookingDateStatus(this.bookingDate);
  }

  m_gigName: string;
  m_bookingName: string;
  m_bookingDateStatus: string;

  constructor(
    private iconsService: IconsRegistryService,
    private translations: TranslationsService,
    private getMe: GetCurrentUserGQL,
  ) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  ngOnInit(): void {
    this.getMe
      .fetch()
      .pipe(
        map(({ data }) => data.me),
        tap((user: User) => (this.amIBooker = amIBooker(this.bookingDate.booking, user.id))),
      )
      .subscribe();
    this.m_gigName = this.getGigName;
    this.m_bookingName = this.getBookingName;
    this.m_bookingDateStatus = this.bookingDateStatus;
  }
}
