<div>
  <div
    *ngIf="!isBookingCreated"
    fxLayoutAlign="stretch start"
    fxLayoutGap="120px"
    fxLayout.lt-md="column"
    fxLayoutGap.lt-md="24px"
    class="request-step container">
    <div fxFlex="316px" fxFlex.lt-md="auto" class="request-step--left">
      <h1 class="request-step--title m-b--20" i18n>Everything looks good?</h1>
      <p class="intro-text" i18n
        >Send your request once you’re ready and we will redirect you to chat with the artist.</p
      >
    </div>

    <mat-divider fxHide fxShow.lt-md class="w--100per m-b--32"></mat-divider>

    <div class="w--100per">
      <div fxFlex="587px" fxFlex.lt-md="auto">
        <h2 class="h6 f-weight--600" i18n>Summary</h2>

        <div fxHide.lt-md *ngIf="page$ | async as page" fxLayout="row" fxLayoutGap="12px" class="m-b--40">
          <app-avatar
            class="artist-profile--avatar"
            [profilePicture]="page.profilePicture"
            [borderRadius]="'2px'"
            [specSize]="35"
            [square]="true"></app-avatar>

          <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="3px">
            <p class="f-weight--600">{{ page.name }}</p>
          </div>
        </div>

        <div fxLayoutAlign="start start" class="m-b--20">
          <div fxFlex fxLayoutAlign="start start" fxLayoutGap="119px" fxLayout.lt-md="column" fxLayoutGap.lt-md="20px">
            <div>
              <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Type of event</h3>
              <p>{{ gigTypeTitle }}</p>
            </div>
            <div>
              <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Location</h3>
              <p>{{ createRequestForm.value.formattedAddress }}</p>
            </div>
          </div>
          <div fxLayoutAlign="start start">
            <button
              class="gig-button transparet f-weight--600 pink p--0 p-t--5"
              (click)="changeStepNumber$.emit(1)"
              i18n
              >Edit</button
            >
          </div>
        </div>

        <div class="description">
          <h3 class="subtitle-1 f-weight--600 m-b--16" i18n>Description</h3>
          <p class="m-b--10">{{ createRequestForm.value.title }}</p>
          <p>{{ createRequestForm.value.message }}</p>
        </div>

        <mat-divider class="m-b--20"></mat-divider>

        <div>
          <div fxFlex>
            <div class="m-b--20">
              <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Date for event</h3>
              <p *ngFor="let date of datesPreviewArray">{{ date }}</p>
            </div>

            <div>
              <div fxFlex="50%" class="m-b--20">
                <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Start time</h3>
                <p>{{ createRequestForm.value?.startTime }}</p>
              </div>
              <div fxFlex="50%" class="m-b--20">
                <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Set-length</h3>
                <p>{{ setLengthToDuration }}</p>
              </div>
            </div>

            <div class="m-b--20">
              <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Techniqal equipment</h3>
              <p
                *ngIf="
                  !createRequestForm.value.suppliesMixer &&
                  !createRequestForm.value.suppliesSpeakers &&
                  !createRequestForm.value.suppliesPa
                "
                i18n
                >None</p
              >
              <p *ngIf="createRequestForm.value.suppliesMixer" i18n>Mixertable</p>
              <p *ngIf="createRequestForm.value.suppliesSpeakers" i18n>Speakers</p>
              <p *ngIf="createRequestForm.value.suppliesPa" i18n>PA system</p>

              <div
                *ngIf="createRequestForm.value.supplyStatus"
                fxLayoutAlign="start center"
                fxLayoutGap="8px"
                class="p-t--8">
                <mat-icon class="green">done</mat-icon>
                <p i18n>Request to rent technical equipment from artist</p>
              </div>
            </div>

            <div class="m-b--20">
              <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Your budget per date</h3>
              <p>{{ createRequestForm.value?.budgetAll | priceSpaceFormat }} {{ CURRENCY.Sek }}</p>
            </div>

            <mat-divider class="m-b--32"></mat-divider>

            <div class="price-details">
              <h3 class="subtitle-1 f-weight--600 m-b--24" i18n>Preliminary price details</h3>
              <div *ngIf="page$ | async as page" fxLayoutAlign="start center" fxLayoutGap="8px" class="m-b--16">
                <app-avatar
                  class="artist-profile--avatar"
                  [profilePicture]="page.profilePicture"
                  [borderRadius]="'4px'"
                  [specSize]="32"
                  [square]="true"></app-avatar>

                <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="3px">
                  <p>{{ page.name }}</p>
                </div>
              </div>

              <div>
                <p fxLayoutAlign="space-between center" class="f-size--15">
                  <span
                    >{{ createRequestForm.value.slotDates?.length }}
                    <span *ngIf="createRequestForm.value.slotDates?.length === 1" i18n>date</span>
                    <span *ngIf="createRequestForm.value.slotDates?.length > 1" i18n>dates</span>&nbsp;x&nbsp;{{
                      createRequestForm.value?.budgetAll | priceSpaceFormat
                    }}
                    {{ CURRENCY.Sek }}
                  </span>

                  <span>{{ bookingDatesPrice }}&nbsp;{{ CURRENCY.Sek }}</span>
                </p>
                <p fxLayoutAlign="space-between center" class="f-size--15 m-b--24">
                  <a [href]="POLICY_LINK" class="gray-1 text-underline"
                    ><span i18n>Booking fee</span> ({{ displayableFee(userFee) }}%)</a
                  >
                  <span>{{ bookingFeeAmount }}&nbsp;{{ CURRENCY.Sek }}</span>
                </p>

                <mat-divider class="m-b--24"></mat-divider>

                <p fxLayoutAlign="space-between center" class="f-weight--600 f-size--15">
                  <span><span i18n>Estimated price</span>&nbsp;({{ CURRENCY.Sek }})</span>
                  <span>{{ bookingEstimatedPrice }}&nbsp;{{ CURRENCY.Sek }}</span>
                </p>
                <span class="fee-prompt" i18n>Moms på 25% tillkommer</span>
              </div>
            </div>
          </div>

          <div fxLayoutAlign="start start">
            <button
              class="gig-button transparet f-weight--600 pink p--0 p-t--5"
              (click)="changeStepNumber$.emit(2)"
              i18n
              >Edit</button
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isBookingCreated" fxLayoutAlign="stretch start" fxLayoutGap="120px" class="request-step container">
    <div fxFlex="316px" class="request-step--left">
      <h1 class="request-step--title m-b--20" i18n>Everything looks good?</h1>
      <p class="intro-text" i18n
        >Send your request once you’re ready and we will redirect you to chat with the artist.</p
      >
    </div>

    <div>
      <div fxLayout="column" fxLayoutGap="28px" fxFlex="587px">
        <p i18n>The request have been sent! Lorem Ipsum usually answers within 48h.</p>

        <p i18n
          >If in hurry we have selected some fast responders that suits your gig. Send the same request by one click.</p
        >
      </div>
    </div>
  </div>
</div>
