import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  DashboardBookings,
  GetDashboardBookingsGQL,
  GetGigsGQL,
  GetPagesByUserGQL,
  Gig,
  GigType,
  Page,
  PossibleReviewsGQL,
} from '../../../../generated/graphql';
import { getBookingPrice } from '../../../helpers/bookings';

@Component({
  selector: 'app-artist-dashboard',
  templateUrl: './artist-dashboard.component.html',
  styleUrls: ['./artist-dashboard.component.scss'],
})
export class ArtistDashboardComponent implements OnInit {
  public dashboardBookings$: Observable<DashboardBookings>;

  public userPages$: Observable<Page[]>;
  public gigs$: Observable<Gig[]>;
  public possibleReviews$: Observable<Page[]>;

  public pendingInvoices: number;
  public reviewsCount: number;

  constructor(
    private getDashboardBookings: GetDashboardBookingsGQL,
    private getUserPages: GetPagesByUserGQL,
    private getGigs: GetGigsGQL,
    private possibleReviews: PossibleReviewsGQL,
  ) {}

  ngOnInit(): void {
    this.gigs$ = this.getGigs
      .watch({
        paging: { limit: 4, skip: 0 },
        filter: { sorting: 'RELEVANCY:DESC', type: [GigType.Business, GigType.Venue] },
      })
      .valueChanges.pipe(map(({ data }) => data.gigs.edges));

    this.userPages$ = this.getUserPages.watch().valueChanges.pipe(map(({ data }) => data.pagesByUser));
    this.getdashboardBookings();
    this.getPossibleReviews();
  }

  public getdashboardBookings(): void {
    this.dashboardBookings$ = this.getDashboardBookings
      .watch({}, { fetchPolicy: 'network-only', nextFetchPolicy: 'network-only' })
      .valueChanges.pipe(
        tap(({ data }) => {
          this.pendingInvoices = data.dashboardBookings.pending
            .map((booking) => getBookingPrice(booking.dates))
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        }),
        map(({ data }) => {
          data.dashboardBookings.pending = data.dashboardBookings.pending.sort(
            (a, b) =>
              parseInt(a.dates.sort((date, nextDate) => parseInt(date.date) - parseInt(nextDate.date))[0].date) -
              parseInt(b.dates.sort((date, nextDate) => parseInt(date.date) - parseInt(nextDate.date))[0].date),
          );
          return data.dashboardBookings;
        }),
      );
  }

  public getPossibleReviews(): void {
    this.possibleReviews$ = this.possibleReviews.watch().valueChanges.pipe(
      map(({ data }) => {
        this.reviewsCount = data.possibleReviews.length;
        return data.possibleReviews;
      }),
    );
  }
}
