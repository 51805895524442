<div class="sticky-notifications top">
  <h3 i18n class="mat-h2 gray-1">Notifications</h3>
</div>
<app-notifications-list isDropdown="true"></app-notifications-list>
<div
  class="sticky-notifications bot cursor-pointer"
  fxLayout="row"
  fxLayoutAlign="center center"
  [routerLink]="['/notifications']"
>
  <a i18n>Show all</a>
</div>
