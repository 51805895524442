<mat-card class="m-width" [class.no-border-radius]="!borderRadius" (click)="onNavigate()" [routerLink]="this.url">
  <div fxFlex fxLayout="column" fxLayoutAlign="center" class="cursor-pointer">
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-between center">
      <div *ngIf="notification.notification.type != notificationType.Generic" fxLayoutAlign="center center">
        <app-avatar
          [user]="notification.notification.sendingUser"
          [profilePicture]="notification.notification.page ? notification.notification.page.profilePicture : null">
        </app-avatar>
      </div>
      <div fxFlex fxLayout="column" fxFlex="grow" class="d-flex flex-column px-8 flex-grow-1">
        <ng-container *ngIf="notification.notification.type != notificationType.Generic; else generic">
          <p class="mat-body-2 gray-1 notification-text">
            <b>
              {{ displayName }}
            </b>
            <ng-container *ngTemplateOutlet="notificationText"></ng-container>
          </p>
        </ng-container>
        <div
          fxFlex
          fxLayout="row"
          fxLayoutGap="8px"
          fxLayoutAlign="start center"
          class="mat-body-2 gray-2 notification-icon">
          <span class="{{ iconColor }}">
            <mat-icon>{{ iconName }}</mat-icon>
          </span>
          <span>
            {{ timeSince }}
          </span>
        </div>
      </div>

      <div class="pl-8">
        <p
          *ngIf="!notification.notification.isRead"
          class="mt-0 read-status"
          [ngClass]="{ 'unread-icon': !notification.notification.isRead }">
          •
        </p>
      </div>
    </div>
  </div>
</mat-card>

<ng-template #generic>
  <p class="mat-body-2 gray-1 notification-text">
    <ng-container [ngSwitch]="notification.notification.meta.status">
      <span *ngSwitchCase="genericNotificationStatus.REVIEW" i18n="notification text|[name] has sent request">
        You can now review <strong>{{ notification.notification.meta.data.pageName }}'s</strong> performance on
        {{ notification.notification.meta.data.date | date: dateFormat }}.
      </span>
    </ng-container>
  </p>
</ng-template>

<ng-template #notificationText>
  <ng-container [ngSwitch]="notification.notification.type">
    <ng-container *ngSwitchCase="notificationType.Booking" [ngSwitch]="notification.notification.meta.status">
      <span *ngSwitchCase="bookingNotificationStatus.ADDED" i18n="notification text|[name] has sent request"
        >has sent a booking request.</span
      >
      <span *ngSwitchCase="bookingNotificationStatus.CANCELLED" i18n="notification text|[name] has cancelled"
        >has cancelled their booking request.</span
      >
      <span *ngSwitchCase="bookingNotificationStatus.DECLINED" i18n="notification text|[name] has declined"
        >has declined your booking request.</span
      >
      <ng-container *ngSwitchCase="bookingNotificationStatus.COMPONENT_ADDED">
        <ng-container *ngTemplateOutlet="modifiedComponent"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="bookingNotificationStatus.COMPONENT_MODIFIED">
        <ng-container *ngTemplateOutlet="modifiedComponent"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="bookingNotificationStatus.COMPONENT_REMOVED">
        <ng-container *ngTemplateOutlet="modifiedComponent"></ng-container>
      </ng-container>

      <span
        *ngSwitchCase="bookingNotificationStatus.COMPONENT_SIGNED"
        i18n="notification text|[name] has agreed to component value"
        >has agreed to the <ng-container *ngTemplateOutlet="componentType"></ng-container> of their booking
        request.</span
      >
      <span
        *ngSwitchCase="bookingNotificationStatus.LEGACY_MODIFIED"
        i18n="notification text|[name] has updated booking"
        >has modified the details of their booking request.</span
      >
      <span *ngSwitchCase="bookingNotificationStatus.LEGACY_ACCEPTED" i18n="notification text|[name] has accepted"
        >has accepted their booking request.</span
      >
      <span *ngSwitchCase="bookingNotificationStatus.SIGNED" i18n="[name] has signed notification"
        >has signed their booking request.</span
      >
    </ng-container>
    <ng-container *ngSwitchCase="notificationType.Channel" [ngSwitch]="notification.notification.meta.status">
      <span *ngSwitchCase="channelNotificationStatus.INVITED_TO" i18n="notification text|[name] has invited you"
        >has invited you to a channel.</span
      >
      <span *ngSwitchCase="channelNotificationStatus.MESSAGE_ADDED" i18n="notification text|[name] has sent a message"
        >has sent {subs, plural, =1 {a message} other {{{subs+1}} messages}}.</span
      >
    </ng-container>
    <ng-container *ngSwitchCase="notificationType.Gig" [ngSwitch]="notification.notification.meta.status">
      <span *ngSwitchCase="gigNotificationStatus.APPLICATION" i18n="notification text|[name] has applied to gig">
        <span *ngIf="notification.subs.length > 0">and {{ notification.subs.length }} more</span> has applied to
        {{ notification.notification.meta.gigName }}.</span
      >
      <span
        *ngSwitchCase="gigNotificationStatus.SLOT_ASSIGNED"
        i18n="notification text|[name] was not booked for gigname"
        >was not selected for <strong>{{ notification.notification.meta.gigName }}</strong
        >'s slot on {{ notification.notification.meta.slot?.date | date: dateFormat }}.</span
      >
    </ng-container>

    <ng-container *ngSwitchCase="notificationType.Page" [ngSwitch]="notification.notification.meta.status">
      <ng-container *ngSwitchCase="pageNotificationStatus.REVIEWED_DENIED">
        <ng-container *ngTemplateOutlet="pageReviewed"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="pageNotificationStatus.REVIEWED_APPROVED">
        <ng-container *ngTemplateOutlet="pageReviewed"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="pageNotificationStatus.REVIEW_RECEIVED">
        <ng-container *ngTemplateOutlet="pageReviewReceived"></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="notificationType.Badge">
      <span i18n>received the {{ BadgeTranslations[notification.notification.meta.badge] }} badge</span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #pageReviewReceived>
  <span i18n="notification text|page has received review"> has received a new review. Find it on your profile.</span>
</ng-template>

<ng-template #pageReviewed>
  <span i18n="notification text|page has been reviewed"> has been reviewed by Gigital staff.</span>
</ng-template>

<ng-template #modifiedComponent>
  <span i18n="notification text|[name] has modified component @@modifiedComponentNotification">
    <ng-container *ngIf="notification.subs.length > 0"
      >'s booking request has {{ notification.subs.length }} new updates.</ng-container
    >
    <ng-container *ngIf="notification.subs.length === 0">
      has modified the <ng-container *ngTemplateOutlet="componentType"></ng-container> of their booking request.
    </ng-container>
  </span>
</ng-template>

<ng-template #componentType>
  <span [ngSwitch]="notification.notification.meta.componentType">
    <ng-container *ngSwitchCase="componentTypes.Date" i18n="@@date">date</ng-container>
    <ng-container *ngSwitchCase="componentTypes.Others" i18n="@@other">other</ng-container>
    <ng-container *ngSwitchCase="componentTypes.Place" i18n="@@location">place</ng-container>
    <ng-container *ngSwitchCase="componentTypes.Price" i18n="@@price">price</ng-container>
  </span>
</ng-template>
