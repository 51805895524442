<div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" class="header border-bottom">
  <a fxShow.lt-sm fxHide class="back-button-inline cursor-pointer" [routerLink]="'../'">
    <mat-icon svgIcon="expand-left" class="expand-left-icon"></mat-icon>
  </a>
  <div fxLayout="column" fxLayoutAlign="center center" class="user-data">
    <p class="user-data--name"> {{ user.firstName }}&nbsp;{{ user.lastName[0] }} </p>
    <span class="user-data--status" [ngClass]="user.isLoggedIn ? 'online' : 'offline'">
      <span *ngIf="user.isLoggedIn" i18n>Online</span>
      <span *ngIf="!user.isLoggedIn" i18n>Offline</span>
    </span>
  </div>
</div>
