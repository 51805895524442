<mat-sidenav-container [hasBackdrop]="false" [class.to-front]="sidenav.opened">
  <!-- 76 = navbar height -->
  <mat-sidenav #sidenav fixedInViewport [fixedTopGap]="0" mode="over">
    <div #sidenavRef class="sidenav-master" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <h3 class="title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <span class="icon-container"><mat-icon class="svg-icon" svgIcon="filter"></mat-icon></span>
          <span i18n>Filter & Sort</span></h3
        >
        <span class="close icon-container" (click)="sidenav.close()"
          ><mat-icon class="svg-icon" svgIcon="close"></mat-icon
        ></span>
      </div>

      <div fxLayout="column" fxLayoutGap="25px" class="tags-container">
        <h4 class="sub-title" i18n>Sort by</h4>
        <app-sort-dropdown (sortChanges)="sortingChanges($event)"></app-sort-dropdown>
      </div>

      <div fxLayout="column" fxLayoutGap="25px" class="tags-container">
        <h4 class="sub-title" i18n>Artist type</h4>
        <app-tag-buttons
          fxLayout="column"
          fxLayoutGap="12px"
          (tagsChanges)="tagsChanges($event)"
          [availableTags]="availableTags"></app-tag-buttons>
      </div>

      <div fxLayout="column" fxLayoutGap="25px" class="locations-container">
        <h4 class="sub-title" i18n>Gig type</h4>
        <app-type-buttons
          fxLayout="column"
          fxLayoutGap="12px"
          (typesChanges)="typesChanges($event)"
          [availableTypes]="availableTypes"></app-type-buttons>
      </div>

      <div fxLayout="column" fxLayoutGap="25px" class="locations-container">
        <h4 class="sub-title" i18n>Provinces</h4>
        <app-places-check-list
          (locationsChanges)="locationsChanges($event)"
          [filterChanges]="filterChanges$"></app-places-check-list>
      </div>

      <div fxLayout="row" fxLayoutAlign="end" class="clear-all-container">
        <span i18n class="clear-all cursor-pointer" (click)="clearFilters()">Clear all</span>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="gigs-list-container">
      <app-list-header
        [gigs]="gigs"
        [availableLocations]="availableLocations"
        [availableTags]="availableTags"
        [availableTypes]="availableTypes"
        (filterChanges)="filterChanges($event)"
        (openFilter)="toggleSideNav()"></app-list-header>
      <mat-divider fxHide fxShow.gt-md></mat-divider>
      <div class="spacer" fxShow fxHide.gt-md></div>
      <div
        fxLayout="column"
        fxLayout.gt-sm="row wrap"
        fxLayoutGap="32px"
        fxLayoutGap.gt-sm="32px grid"
        class="gigs-list">
        <ng-container *ngIf="!completed">
          <app-gig-card
            fxFlex.gt-sm="33.3333%"
            fxFlex.gt-lg="25%"
            *ngFor="let gig of gigs | async"
            [gig]="gig"
            fxLayout="column"></app-gig-card>
        </ng-container>
        <ng-container *ngIf="completedGigs | async as completedGigs">
          <app-gig-card
            fxFlex.gt-sm="33.3333%"
            fxFlex.gt-lg="25%"
            *ngFor="let gig of completedGigs.gigs"
            [gig]="gig"
            [hideActs]="hideActs"
            fxLayout="column"></app-gig-card>
        </ng-container>
      </div>

      <div *ngIf="!completed && gigs | async; else loading" fxLayout="row" fxLayoutAlign="center" class="load-more">
        <button mat-flat-button color="primary" [disabled]="!hasNextPage" (click)="fetchMore()" i18n
          >Load more gigs</button
        >
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-template #loading>
  <div
    fxLayout="column wrap"
    fxLayout.gt-sm="row wrap"
    fxLayoutGap.gt-xs="32px grid"
    fxLayoutGap="32px"
    class="loading"
    *ngIf="!completed">
    <div *ngFor="let item of [].constructor(15)" fxFlex.gt-sm="33.3333%" fxFlex.gt-lg="25%">
      <ngx-skeleton-loader
        [theme]="{
          width: '100%',
          height: '430px',
          'border-radius': '4px',
          'background-color': '#fafafa',
          'margin-bottom': 0
        }">
      </ngx-skeleton-loader>
    </div>
  </div>
</ng-template>
