/// <reference types="google.analytics" />
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare let gtag;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {}

  public emitEvent(category: string, data?: object, monetary?: { currency?: string; value: number }) {
    if (environment.production && typeof gtag === 'function' && !!(window as any).gtag) {
      if (monetary && !monetary.currency) {
        monetary.currency = 'SEK';
      }
      gtag('event', category, { ...data, ...monetary });
    } else {
      console.log('*** DEV *** - Sending GA tracking event:');
      console.log({ category });
      console.log({ data });
      console.log({ monetary });
      console.log('----------');
    }
  }

  public emitEventUA(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null,
  ): void {
    if (typeof ga === 'function' && !!(window as any).ga && !!(window as any).ga.getAll) {
      // Stop error on logging in with ad blockers
      const tracker = ga.getAll()[0];
      if (tracker && environment.production) {
        tracker.send('event', {
          eventCategory,
          eventLabel,
          eventAction,
          eventValue,
        });
      } else {
        console.log('*** DEV *** - Sending GA tracking event:');
        console.log({ eventCategory });
        console.log({ eventLabel });
        console.log({ eventAction });
        console.log({ eventValue });
        console.log('----------');
      }
    }
  }

  public setUserId(userId: number): void {
    const maxTries = 4;
    let tries = 0;
    if (environment.production && typeof gtag === 'function' && !!(window as any).gtag) {
      gtag('config', 'G-S0V8LJLDEY', { user_id: userId });
      this.emitEvent('User id set');
    } else {
      if (tries < maxTries) {
        setTimeout(() => {
          tries += 1;
          this.setUserId(userId);
        }, 250);
      }
    }
  }
}
