<div class="create-artist">
  <h2 class="mat-h2" i18n>Create artist profile</h2>
  <h3 class="mat-body gray-2" i18n>Lets get going! Please note that all settings can be changed later on.</h3>
  <form [formGroup]="createArtistForm">
    <mat-vertical-stepper linear>
      <!-- Ownership  -->
      <mat-step [completed]="ownership.valid">
        <ng-template matStepLabel><h4 class="mat-h3 gray-1">Ownership</h4></ng-template>
        <p class="mat-body-2 gray-2" i18n>
          To deliver a better experience for you and to be able to handle any legal questions we would like to know if
          you are creating this artist profile for yourself or for someone else.
        </p>

        <label class="radio-label" id="artist-ownership-label" i18n>Who will be managing this artist profile?</label>
        <mat-radio-group aria-labelledby="artist-ownership-label" formControlName="ownership" fxLayout="column">
          <mat-radio-button class="mat-body-2 gray-2" value="ARTIST" color="primary" disableRipple i18n>
            I'm managing this artist profile for myself
          </mat-radio-button>
          <mat-radio-button class="mat-body-2 gray-2" value="MANAGER" color="primary" disableRipple i18n>
            I'm managing this artist profile for someone else
          </mat-radio-button>
        </mat-radio-group>

        <div>
          <button
            type="button"
            class="artist-step-button"
            mat-stroked-button
            matStepperNext
            [disabled]="ownership.invalid"
            i18n="@@next"
            >Next</button
          >
        </div>
      </mat-step>

      <!-- Name -->
      <mat-step [completed]="name.valid">
        <ng-template matStepLabel><h4 class="mat-h3 gray-2" i18n>Artist name</h4></ng-template>
        <p class="mat-body-2 gray-2" i18n>
          Your artist name, you probably spent hours thinking of it and it does sound amazing! <br />
          This will be the name that for example shows up in search results.
        </p>
        <mat-form-field>
          <input matInput placeholder="Artist name" formControlName="name" i18n-placeholder />
          <mat-error *ngIf="name.hasError('required')" i18n>
            Artist name is required
          </mat-error>
          <mat-error *ngIf="name.hasError('minlength')" i18n>
            Must be at least 3 characters
          </mat-error>
        </mat-form-field>
        <div>
          <button
            type="button"
            class="artist-step-button"
            mat-stroked-button
            matStepperNext
            [disabled]="name.invalid"
            i18n="@@next"
            >Next</button
          >
          <button class="artist-step-button" mat-stroked-button matStepperPrevious i18n="@@previous">Previous</button>
        </div>
      </mat-step>

      <!-- Type -->
      <mat-step [completed]="name.valid">
        <ng-template matStepLabel><h4 class="mat-h3 gray-2" i18n>Artist type</h4></ng-template>
        <p class="mat-body-2 gray-2" i18n>
          What kind of artist are you? Each artist profile can only be one kind of artist. Are you both a DJ and an
          artist? Create two separate artist profiles!
        </p>
        <label class="radio-label" id="artist-ownership-label" i18n>What kind of artist profile is this?</label>
        <mat-radio-group aria-labelledby="artist-ownership-label" formControlName="type" fxLayout="column">
          <mat-radio-button class="mat-body-2 gray-2" [value]="artistId" color="primary" disableRipple i18n="@@artist">
            Artist
          </mat-radio-button>
          <mat-radio-button class="mat-body-2 gray-2" [value]="djId" color="primary" disableRipple i18n="@@dj">
            DJ
          </mat-radio-button>
          <mat-radio-button class="mat-body-2 gray-2" [value]="bandId" color="primary" disableRipple i18n="@@band">
            Band
          </mat-radio-button>
        </mat-radio-group>

        <div>
          <button
            class="artist-step-button"
            mat-flat-button
            color="primary"
            matStepperNext
            [disabled]="type.invalid || loading"
            (click)="createArtist()"
            i18n
            >Create artist profile</button
          >
          <button class="artist-step-button" mat-stroked-button matStepperPrevious i18n="@@previous">Previous</button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </form>
</div>
