import { isPlatformServer } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injector, NgModule, PLATFORM_ID } from '@angular/core';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Apollo } from 'apollo-angular';
import { HttpBatchLink, HttpLink } from 'apollo-angular/http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { environment } from '../environments/environment';
import introspection from '../generated/introspection.json';
import { apolloCache, initApolloCache } from '../utils/ApolloUtils';
import { ArtistSortingValues } from './models/ArtistSortingOptions';
import { resolvers, typeDefs } from './resolvers';
import { AuthService } from './services/auth.service';

const STATE_KEY: StateKey<any> = makeStateKey<any>('apollo.state');

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspection,
});

declare let NG_CONF;

@NgModule({
  exports: [],
})
export class GraphQLModule {
  isServer: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    private injector: Injector,
    private readonly transferState: TransferState,
    private apollo: Apollo,
    private httpLink: HttpLink,
    private batchLink: HttpBatchLink,
    private auth: AuthService,
  ) {
    this.isServer = isPlatformServer(platformId);
    const conf = this.injector.get('config', {});
    // const useLink = environment.production ? this.batchLink : this.httpLink;
    const useLink = this.httpLink;
    const http = useLink.create({
      uri: (conf && conf.httpApiUrl) || environment.httpApiUrl,
    });

    let link;

    if (this.isServer) {
      link = http;
    } else {
      const ws = new WebSocketLink({
        uri: (conf && conf.wssApiUrl) || environment.wssApiUrl,
        options: {
          timeout: 45000,
          reconnect: true,
          connectionParams: () => ({
            Authorization: `Bearer ${auth.token && auth.token.idToken}`,
          }),
        },
      });

      link = split(
        ({ query }) => {
          let definition = getMainDefinition(query);
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        ws,
        http,
      );
    }

    const authLink = setContext(() => {
      // TODO: Cache token here so we don't have to go to the cache/auth service every time?
      const token = this.auth.token && this.auth.token.idToken;
      if (!token) {
        return {};
      }
      return {
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      // TODO: Show toast and reload here?
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError.message}`);
      }
    });

    const ffDelay = parseInt(process.env.FORCE_FETCH_DELAY);
    this.apollo.create<InMemoryCache>({
      typeDefs,
      resolvers,
      link: authLink.concat(errorLink).concat(link),
      // Suck... Apollo throwing errors for their own types...
      cache: initApolloCache(fragmentMatcher) as any,
      ssrMode: true,
      ssrForceFetchDelay: isNaN(ffDelay) ? 100 : ffDelay,
    });

    this.apollo.client.onResetStore(async () => this.writeDefaults());
    this.apollo.client.onClearStore(async () => this.writeDefaults());

    const isBrowser: boolean = this.transferState.hasKey<any>(STATE_KEY);

    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }

    this.writeDefaults();
  }

  onServer() {
    this.transferState.onSerialize(STATE_KEY, () => {
      return apolloCache.extract();
    });
  }

  onBrowser() {
    const state = this.transferState.get<any>(STATE_KEY, null);
    apolloCache.restore(state);
  }

  writeDefaults() {
    apolloCache.writeData({
      data: {
        isLoggedIn: this.auth.isLoggedIn,
        todos: [],
        selectedApplications: [],
        pageFilter: {
          __typename: 'pageFilter',
          limit: 15,
          skip: 0,
          sorting: ArtistSortingValues.RelevanceDesc,
          priceMax: null,
          priceMin: null,
          tags: [],
          textQuery: null,
          category: null,
          location: null,
          locationRadiusKm: null,
          includesEquipment: null,
        },
      },
    });
  }
}
