<header fxLayout="row" fxLayoutAlign="start center">
  <button (click)="closeFilter()" class="gig-button icon close">
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <ng-content select="[title]"></ng-content>
  <h1 fxFlex class="subtitle-1 f-weight--600 text-center" *ngIf="filterTitle">{{ filterTitle }}</h1>
</header>

<div class="artist-filter-content">
  <div class="artist-filter-container">
    <ng-container>
      <ng-container *ngFor="let section of filterSections">
        <div *ngIf="section.options.length > 0" class="artist-filter">
          <h3 class="subtitle-1 f-weight--600 m-b--28">{{ section.title }}</h3>
          <div fxLayout="row wrap">
            <app-checkbox-input
              *ngFor="let option of section.options | slice: 0:section.numberToShow"
              fxFlex="50%"
              [(ngModel)]="option.active"
              [isThinStyle]="true"
              [label]="getOptionLabel(option)"
              class="d-block m-b--24"></app-checkbox-input>
          </div>

          <a
            *ngIf="section.options.length > 6 && !section.isOpen"
            (click)="openFilterSection(section)"
            class="more-link"
            i18n>
            Show more
          </a>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<footer fxLayoutAlign="space-between center">
  <button class="gig-button transparent text-underline" (click)="clearAll()" i18n>Clear all</button>
  <button class="gig-button primary" (click)="applyFilters()" i18n="@@applyFilter">Apply</button>
</footer>
