<div class="edit-slot">
  <header class="bg-white">
    <h1 class="h5 gray-1" *ngIf="!data.new" i18n
      >Editing slot <strong>{{ data.slot.date | date: dateFormat }}</strong></h1
    >
    <h1 class="h5 gray-1" *ngIf="data.new" i18n>Adding new slot</h1>
  </header>
  <main>
    <form fxLayout="column" fxLayoutGap="18px" [formGroup]="editSlotGroup">
      <div fxLayout="column" fxLayoutGap="8px">
        <label i18n>Date</label>
        <div>
          <input
            type="text"
            class="gig-text-input date-input"
            [matDatepicker]="picker"
            [min]="minDate"
            placeholder="D/M/YYY"
            formControlName="date"
            (click)="picker.open()" />
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <label i18n>Budget</label>
        <input
          class="gig-text-input"
          type="number"
          formControlName="price"
          matInput
          placeholder="Budget"
          i18n-placeholder="@@budget" />
      </div>

      <div fxLayout="column" fxLayoutGap="8px">
        <div fxLayout.lt-md="column" fxLayoutGap.lt-md="8px" fxLayout="row" fxLayoutAlign="space-between">
          <div fxFlex.gt-sm="48%">
            <app-select-input
              label="Select start time"
              [selectOptions]="gigStartTimeOptions"
              formControlName="startTime"
              i18n-label></app-select-input>
          </div>
          <div fxFlex.gt-sm="48%">
            <app-select-input
              label="Select set-length"
              [selectOptions]="timeLengthOptions"
              formControlName="setLength"
              i18n-label></app-select-input>
          </div>
        </div>
      </div>

      <label>Looking for</label>
      <div fxLayout="column" fxLayoutGap="16px">
        <mat-checkbox formControlName="artistBand" color="primary" i18n>Live music</mat-checkbox>
        <mat-checkbox formControlName="dj" i18n="@@dj" color="primary">DJ</mat-checkbox>
      </div>
    </form>
    <div fxLayoutGap="16px" fxLayout="row" fxLayoutAlign="end center" class="edit-slot-actions">
      <button mat-flat-button (click)="close()" [disabled]="loading" i18n="@@cancel">Cancel</button>
      <button mat-flat-button color="primary" (click)="update()" [disabled]="loading"
        ><span i18n *ngIf="data.new">Add new act</span><span i18n *ngIf="!data.new">Update act</span></button
      >
    </div>
  </main>
</div>
