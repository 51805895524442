<a
  fxHide.lt-md
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="data-item"
  [routerLink]="['/bookings', bookingDate.booking.id]">
  <div fxFlex="25%" fxLayout="row" fxLayoutGap="16px">
    <app-avatar
      *ngIf="userType === USER_TYPE.Booker"
      [profilePicture]="bookingDate.booking.page.profilePicture"
      [user]="bookingDate.booking.page.user"
      [medium]="true"></app-avatar>

    <div *ngIf="userType !== USER_TYPE.Booker">
      <div class="image-container">
        <app-cloud-image
          *ngIf="bookingDate.booking.appliedTo?.images?.length > 0"
          class="booking-image"
          [profilePicture]="bookingDate.booking.appliedTo.images[0]"
          [altText]="bookingDate.booking.appliedTo.name + 's image'"
          [maxWidth]="60"
          i18n-altText></app-cloud-image>
        <app-fallback-image
          *ngIf="!bookingDate.booking.appliedTo?.images || bookingDate.booking.appliedTo?.images.length === 0"
          class="booking-image"
          [gig]="bookingDate.booking"
          [borderRadius]="'8px'"
          [textSize]="'6px'"></app-fallback-image>
      </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center">
      <span *ngIf="userType === USER_TYPE.Booker" class="booking-name overflow-ellipsis">{{
        bookingDate.booking.page.name
      }}</span>
      <span *ngIf="userType !== USER_TYPE.Booker" class="booking-name overflow-ellipsis">{{ getBookingName }}</span>
      <span *ngIf="m_bookingDateStatus === BOOKING_DATE_STATUS.CANCELED" class="status-label bg-pastel-red">
        {{ m_bookingDateStatus }}
      </span>
    </div>
  </div>

  <div fxFlex="25%" fxLayout="column">
    <app-gig-date
      [startTime]="bookingDate.startTime"
      [endTime]="bookingDate.endTime"
      [dateFormat]="DATE_FORMAT"
      [date]="bookingDate.date"
      [isTimeDisplay]="true"></app-gig-date>
  </div>

  <div *ngIf="userType === USER_TYPE.Booker" fxFlex="25%" fxLayout="column">
    <a class="gray-13 overflow-ellipsis">{{ m_gigName }}</a>
  </div>

  <div fxFlex="25%" fxLayout="row" fxLayoutAlign="space-between">
    <div fxLayout="column">
      <a *ngIf="amIBooker" class="gray-13">
        {{ bookingDate.price | price: true:bookingDate.booking.user.fee }} {{ bookingDate.priceCurrency }}
      </a>
      <a *ngIf="!amIBooker" class="gray-13">
        {{ bookingDate.price | price: false:bookingDate.booking.user.fee }} {{ bookingDate.priceCurrency }}
      </a>
    </div>

    <mat-icon class="arrow-icon" svgIcon="expand-left"></mat-icon>
  </div>
</a>

<!-- mobile view -->
<a
  fxHide
  fxShow.lt-md
  fxLayout="row"
  fxLayoutGap="8px"
  fxLayoutAlign="space-between center"
  class="data-item mobile"
  [routerLink]="['/bookings', bookingDate.booking.id]">
  <app-avatar
    *ngIf="userType === USER_TYPE.Booker"
    fxFlex="80px"
    [profilePicture]="bookingDate.booking.page.profilePicture"
    [user]="bookingDate.booking.page.user"
    [big]="true"></app-avatar>

  <div *ngIf="userType !== USER_TYPE.Booker">
    <div class="image-container">
      <app-cloud-image
        *ngIf="bookingDate.booking.appliedTo?.images?.length > 0"
        class="booking-image"
        [profilePicture]="bookingDate.booking.appliedTo.images[0]"
        [altText]="bookingDate.booking.appliedTo.name + 's image'"
        [maxWidth]="60"
        i18n-altText></app-cloud-image>
      <app-fallback-image
        *ngIf="!bookingDate.booking.appliedTo?.images || bookingDate.booking.appliedTo?.images.length === 0"
        class="booking-image"
        [gig]="bookingDate.booking"
        [borderRadius]="'8px'"
        [textSize]="'6px'"></app-fallback-image>
    </div>
  </div>

  <div fxLayout="column" fxFlex="grow">
    <span *ngIf="userType === USER_TYPE.Booker" class="booking-name mobile">{{ bookingDate.booking.page.name }}</span>
    <span *ngIf="userType !== USER_TYPE.Booker" class="booking-name mobile">{{ m_bookingNameName }}</span>

    <div fxLayout="column" class="m-b--4">
      <app-gig-date
        [startTime]="bookingDate.startTime"
        [endTime]="bookingDate.endTime"
        [dateFormat]="DATE_FORMAT"
        [date]="bookingDate.date"
        [isTimeDisplay]="true"></app-gig-date>
    </div>

    <span *ngIf="bookingDateStatus === BOOKING_DATE_STATUS.CANCELED" class="status-label bg-pastel-red">
      {{ m_bookingDateStatus }}
    </span>
  </div>

  <mat-icon class="arrow-icon mobile" svgIcon="expand-left"></mat-icon>
</a>
