import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ArtistDetailPreviewModalComponent } from '../artist-detail-preview-modal/artist-detail-preview-modal.component';

@Component({
  selector: 'app-artist-detail-preview',
  templateUrl: './artist-detail-preview.component.html',
  styleUrls: ['./artist-detail-preview.component.scss'],
})
export class ArtistDetailPreviewComponent implements OnInit {
  public pageId: string;

  public constructor(private modal: MatDialog, private route: ActivatedRoute, private location: Location) {}

  public ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe((params) => {
      this.pageId = params.pageId;
    });

    this.modal
      .open(ArtistDetailPreviewModalComponent, {
        data: { pageId: this.pageId },
        panelClass: ['gigital-modal', 'full-width'],
        maxWidth: '100vw',
      })
      .afterClosed()
      .subscribe(() => this.location.back());
  }
}
