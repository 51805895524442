import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { LeaveReviewGQL, Page, PossibleLeanReviewsDocument } from '../../../../generated/graphql';
import { getArtistTypeTags } from '../../../helpers/pages';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-quick-review-card',
  templateUrl: './quick-review-card.component.html',
  styleUrls: ['./quick-review-card.component.scss'],
})
export class QuickReviewCardComponent implements OnInit {
  @Input() reviewPage: Page;

  @Output() onModeChanged = new EventEmitter<boolean>();

  public isEditMode: boolean = false;
  public form: FormGroup;

  public getArtistTypeTags = getArtistTypeTags;

  get safeCssUrl() {
    const url = `url(https://res.cloudinary.com/hthruuqgk/image/upload/f_auto,fl_progressive,q_auto/c_limit,w_1000/v1/${this.reviewPage.profilePicture?.fileName})`;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  m_safeCssUrl;

  constructor(
    private iconsService: IconsRegistryService,
    private leaveReview: LeaveReviewGQL,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  ngOnInit() {
    this.form = new FormGroup({
      recommended: new FormControl(null, Validators.required),
      message: new FormControl(null, [Validators.required, Validators.minLength(5)]),
    });

    this.m_safeCssUrl = this.safeCssUrl;
  }

  public quickReview(): void {
    this.isEditMode = true;
    this.onModeChanged.next(this.isEditMode);
  }

  public postReview(): void {
    this.leaveReview
      .mutate(
        {
          artistId: this.reviewPage.id,
          input: {
            message: this.form.value.message,
            recommended: this.form.value.recommended === 'true' ? true : false,
          },
        },
        {
          refetchQueries: [
            {
              query: PossibleLeanReviewsDocument,
            },
          ],
        },
      )
      .subscribe(
        () => {
          this.isEditMode = false;
          this.onModeChanged.next(this.isEditMode);
          this.snackbar.open($localize`Successfully saved!`, undefined, { duration: 3000 });
        },
        () => this.snackbar.open($localize`Could not save changes. Try again`, undefined, { duration: 3000 }),
      );
  }
}
