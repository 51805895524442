<div class="container" *ngIf="user | async as user">
  <h1 class="h4 title" i18n>Welcome, {{ user.firstName }}</h1>

  <app-customer-dashboard *ngIf="userType === USER_TYPE.Booker"></app-customer-dashboard>
  <app-artist-dashboard *ngIf="userType !== USER_TYPE.Booker"></app-artist-dashboard>

  <section>
    <h2 class="gray-1 f-weight--600 m-b--24" i18n>News from Gigital</h2>
    <app-blog-posts-preview
      fxHide.lt-sm="true"
      *ngIf="userType"
      utmSource="dashboard"
      noPadding="true"
      [user]="user"
      [isFullScreen]="userType === USER_TYPE.Artist">
    </app-blog-posts-preview>

    <app-blog-posts-preview
      fxHide="true"
      fxShow.lt-sm="true"
      *ngIf="userType"
      utmSource="dashboard"
      noPadding="true"
      [user]="user"
      [isFullScreen]="false">
    </app-blog-posts-preview>
  </section>
</div>

<ng-template #loading>
  <div style="margin-top: 64px; margin-bottom: 64px" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center">
    <mat-spinner [diameter]="64"></mat-spinner>
    <p i18n="@@loading">Loading sent invites...</p>
  </div>
</ng-template>
