import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../shared/shared.module';
import { ReviewDetailsComponent } from './review-details/review-details.component';
import { ReviewsRoutingModule } from './reviews-routing.module';
@NgModule({
  declarations: [ReviewDetailsComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,

    ReviewsRoutingModule,
    SharedModule,

    FlexModule,

    MatSnackBarModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatRadioModule,
    MatExpansionModule,
  ],
  entryComponents: [],
  exports: [],
})
export class ReviewsModule {}
