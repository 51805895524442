<div fxFlex fxLayout="row" fxLayoutAlign="center" class="paginator">
  <nav fxLayout="row" fxLayoutAlign="center" fxLayoutGap="25px">
    <li (click)="changePage(activePage - 1)">
      <mat-icon class="navigation-arrow" svgIcon="expand-left"></mat-icon>
    </li>
    <li>{{ activePage }} of {{ getTotalPageCount() }}</li>
    <li (click)="changePage(activePage + 1)">
      <mat-icon class="navigation-arrow left" svgIcon="expand-left"></mat-icon>
    </li>
  </nav>

  <div fxLayout="row" class="per-page">
    <span class="hide" i18n>Rows per page:</span>
    <div>
      <button class="unstyled-button" [matMenuTriggerFor]="menu">
        <span fxLayout="row" fxLayoutGap="7px">
          <span> {{ pageSize }}</span>
          <mat-icon class="expand-arrow" svgIcon="expand-down-small"></mat-icon>
        </span>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="setPageSize(option)" *ngFor="let option of pageSizeOptions" mat-menu-item>
          {{ option }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>
