<main fxLayout.gt-md="row" fxLayout="column-reverse" fxLayoutAlign="center center" class="container">
  <div fxFlex.gt-md="40%" class="login">
    <mat-card class="fade-in">
      <mat-card-title class="login-title" i18n> Log in to your account </mat-card-title>
      <mat-card-content>
        <form fxLayout="column" class="login-form" [formGroup]="loginForm" (submit)="onSubmit()">
          <mat-form-field>
            <input matInput id="username" placeholder="Email" formControlName="username" />
            <mat-error *ngIf="username.hasError('email')" i18n> Please enter a valid email address </mat-error>
            <mat-error *ngIf="username.hasError('required')" i18n> Email is required </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Password" type="password" formControlName="password" />
            <mat-error *ngIf="username.hasError('required')" i18n> Password is required </mat-error>
          </mat-form-field>
          <button type="submit" mat-flat-button color="primary" [disabled]="loading" i18n="@@logIn">Log in</button>
          <mat-error *ngIf="apiError" class="submit-error">{{ apiError }}</mat-error>
        </form>
        <div fxLayout="column" class="login-social">
          <p class="gray-3 mat-caption" i18n>or log in with a social account</p>
          <button (click)="onSocialLogin('google-oauth2')" mat-stroked-button [disabled]="loading"
            ><img class="login-social-icon" src="assets/icons/google.png" width="20" height="20" /><span i18n
              >Log in with Google</span
            ></button
          >
          <button (click)="onSocialLogin('facebook')" mat-stroked-button [disabled]="loading"
            ><img class="login-social-icon" src="assets/icons/social-media/facebook.svg" /><span i18n
              >Log in with Facebook</span
            ></button
          >
        </div>
      </mat-card-content>
      <mat-card-actions class="login-links">
        <p class="mat-body-2 gray-2">
          <span i18n>Don't have an account</span>? <a [routerLink]="['/signup']"><span i18n>Sign up here</span></a>
        </p>
        <p class="mat-body-2 gray-2">
          <span i18n>Forgot your password</span>? <a [routerLink]="['/forgot-password']" i18n>Get a new password</a>
        </p>
      </mat-card-actions>
    </mat-card>
  </div>
  <div
    fxFlex.gt-md="60%"
    fxFlexAlign.gt-md="start"
    fxLayout.gt-md="column"
    fxLayoutAlign.gt-md="center"
    class="welcome">
    <h2 class="mat-display-3 gray-1" i18n>Welcome to Gigital</h2>
    <h3 class="mat-display-1 gray-2" i18n>Log in to access your account</h3>
  </div>
</main>
