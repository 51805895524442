<div class="dialog-container" fxFlex fxLayout="column" fxLayoutAlign="space-between start">
  <mat-dialog-content>
    <h1 class="f-size--16 m-b--10" matDialogTitle>{{ data.title }}</h1>
    <span class="f-size--14">{{ data.body }}</span>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px" class="w--100per">
    <button fxFlex class="gig-button stroked small" (click)="dismiss()">{{ data.cancelText }}</button>
    <button fxFlex class="gig-button primary small" (click)="confirm()">{{ data.okText }}</button>
  </mat-dialog-actions>
</div>
