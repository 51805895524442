import { Component, Input, OnInit } from '@angular/core';
import { apiToDate, isTodayDate, isTomorrowDate, MEDIUM_DATE, timestampToTimeString } from '../../../helpers/dates';

@Component({
  selector: 'app-gig-date',
  templateUrl: './gig-date.component.html',
  styleUrls: ['./gig-date.component.scss'],
})
export class GigDateComponent implements OnInit {
  @Input() dateFormat: string = MEDIUM_DATE;
  @Input() isTimeDisplay: boolean;
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() date: string;

  displayDate: Date;

  timestampToTimeString = timestampToTimeString;

  get isToday(): boolean {
    return isTodayDate(this.date);
  }

  get isTomorrow(): boolean {
    return isTomorrowDate(this.date);
  }

  constructor() {}

  ngOnInit(): void {
    this.displayDate = apiToDate(this.date);
    this.displayDate.setUTCHours(0, 0, 0, 0);
  }
}
