import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GetCurrentUserGQL, GigTemplate, GigType, PageCategory, User } from '../../../../../generated/graphql';
import { ImageToBeUploaded } from '../../../../models/ImageToBeUploaded';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';
import { SelectInputOptions } from '../../../../shared/components/inputs/select-input/select-input.component';
import { PreviousGigModalComponent } from '../../../components/previous-gig-modal/previous-gig-modal.component';

@Component({
  selector: 'app-create-gig-form-description',
  templateUrl: './create-gig-form-description.component.html',
  styleUrls: ['./create-gig-form-description.component.scss'],
})
export class CreateGigFormDescriptionComponent implements OnInit {
  @Input() createGigForm: FormGroup;
  @Input() templates: GigTemplate[] = [];
  @Output() templateSelected = new EventEmitter<GigTemplate>();

  public PAGE_CATEGORY = PageCategory;
  public gigTypeOptions: SelectInputOptions[] = [
    { label: $localize`Corporate`, value: GigType.Business },
    { label: $localize`Private`, value: GigType.Private },
    { label: $localize`Venue`, value: GigType.Venue },
    { label: $localize`Other`, value: GigType.Others },
  ];

  get locationLabel(): string {
    return $localize`Location`;
  }

  get titleLabel(): string {
    return $localize`Event title`;
  }

  public constructor(
    private iconsService: IconsRegistryService,
    private sanitizer: DomSanitizer,
    private modals: MatDialog,
    private currentUserGQL: GetCurrentUserGQL,
  ) {
    this.iconsService.registerIcons([IconSubsets.FORMS]);
  }

  currentUser: User;
  ngOnInit() {
    this.currentUserGQL.fetch().subscribe((res) => {
      this.currentUser = res.data.me;
    });
  }

  public saveFormattedAddress(addressEvent: { formatted_address: string }) {
    this.createGigForm.get('formattedAddress').setValue(addressEvent.formatted_address);
  }

  public imgInputSelected(imagesArray: ImageToBeUploaded): void {
    this.createGigForm.get('images').setValue(imagesArray);
  }

  public selectArtistType(artistType: PageCategory): void {
    const index = this.createGigForm.value.pageCategory.indexOf(artistType);

    if (index === -1) {
      this.createGigForm.value.pageCategory.push(artistType);
    } else {
      this.createGigForm.value.pageCategory.splice(index, 1);
    }
  }

  public isArtistTypeSelected(category: PageCategory): boolean {
    if (!this.createGigForm.value.pageCategory.length) {
      return false;
    } else {
      const index = this.createGigForm.value.pageCategory.indexOf(category);
      return index !== -1;
    }
  }

  showTemplatesModal() {
    const ref = this.modals.open(PreviousGigModalComponent, {
      data: { templates: this.templates, orgName: this.currentUser.companyName },
    });

    ref.afterClosed().subscribe((data) => {
      this.templateSelected.emit(data.template);
    });
  }
}
