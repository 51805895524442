import { Pipe, PipeTransform } from '@angular/core';
import { apiToDate } from '../helpers';

@Pipe({
  name: 'apiDate',
})
export class ApiDatePipe implements PipeTransform {
  transform(value: any): any {
    try {
      return apiToDate(value);
    } catch {
      return value;
    }
  }
}
