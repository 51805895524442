import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(private dialog: MatDialog, private breakpointObserver: BreakpointObserver) {}

  matDialogRef: MatDialogRef<any, any>;
  smallDialogSubscription: any;

  open<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    mobileWidth: string,
    data?: MatDialogConfig,
  ): MatDialogRef<T> {
    if (data) {
      data.maxWidth = '100vw';
      data.maxHeight = '100vh';
    }
    this.matDialogRef = this.dialog.open<T>(componentOrTemplateRef, data);

    this.smallDialogSubscription = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(size => {
        if (size.matches) {
          this.matDialogRef.updateSize('100%', '100%');
        } else {
          this.matDialogRef.updateSize(mobileWidth, 'auto');
        }
      });

    return this.matDialogRef as MatDialogRef<T, any>;
  }

  close(): void {
    this.smallDialogSubscription.unsubscribe();
    this.matDialogRef.close();
  }
}
