<div
  fxLayoutAlign="stretch start"
  fxLayoutGap="120px"
  fxLayoutGap.lt-md="26px"
  fxLayout.lt-md="column"
  class="request-step container">
  <div fxFlex="316px" fxFlex.lt-md="auto" class="request-step--left">
    <h1 class="request-step--title m-b--20" i18n>Describe your event</h1>
    <p class="intro-text" i18n
      >Give the artists the necessary information about your event, be clear and descriptive.</p
    >
    <div *ngIf="templates.length > 0 && currentUser" class="templates-container" fxLayout="column" fxLayoutGap="12px">
      <span i18n
        >Concepts for <span class="highlight-text">{{ currentUser.companyName }}</span></span
      >
      <span i18n
        >As a <span class="highlight-text">{{ currentUser.companyName }}</span> venue, you have access to ready to go
        concepts with fixed prices.</span
      >
      <div>
        <button mat-flat-button color="primary" (click)="showTemplatesModal()" i18n>Choose concept here</button>
      </div>
    </div>
  </div>

  <div class="w--100per">
    <div>
      <form fxFlex="406px" fxLayout="column" fxLayoutGap="40px" fxFlex.lt-md="grow" [formGroup]="createGigForm">
        <div
          fxLayout="column"
          fxLayoutAlign="center start"
          fxLayoutGap="12px"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="full-w">
          <p class="f-weight--600" i18n>What type of act are you looking for?</p>

          <div fxLayoutAlign="row" fxLayoutGap="12px" class="full-w" fxLayoutAlign.gt-md="space-around">
            <button
              (click)="selectArtistType(PAGE_CATEGORY.Live)"
              class="image-button cursor-pointer"
              [ngClass]="{ selected: isArtistTypeSelected(PAGE_CATEGORY.Live) }"
              fxLayoutAlign="center center"
              fxFlex="50%">
              <span i18n>Live music</span>
            </button>

            <button
              (click)="selectArtistType(PAGE_CATEGORY.Dj)"
              class="image-button cursor-pointer"
              [ngClass]="{ selected: isArtistTypeSelected(PAGE_CATEGORY.Dj) }"
              fxLayoutAlign="center center"
              fxFlex="50%">
              <span i18n>DJs</span>
            </button>
          </div>
        </div>

        <app-select-input
          label="What type of gig are you planning?"
          [selectOptions]="gigTypeOptions"
          formControlName="gigType"
          i18n-label></app-select-input>

        <div>
          <app-location-input
            [formattedAddress]="createGigForm.value.formattedAddress"
            [invalid]="
              (createGigForm.controls.placeId.touched || createGigForm.controls.placeId.dirty) &&
              createGigForm.controls.placeId.invalid
            "
            (onPlaceSelected$)="saveFormattedAddress($event)"
            locationType="[]"
            placeholder="Location"
            [label]="locationLabel"
            formControlName="placeId"
            i18n-placeholder>
          </app-location-input>
          <div
            *ngIf="
              (createGigForm.controls.placeId.touched || createGigForm.controls.placeId.dirty) &&
              createGigForm.controls.placeId.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="createGigForm.controls.placeId.errors?.required"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>Location is required.</span>
            </p>
          </div>
        </div>

        <div>
          <app-input
            formControlName="name"
            placeholder="Enter event title"
            [label]="titleLabel"
            [invalid]="
              (createGigForm.controls.name.touched || createGigForm.controls.name.dirty) &&
              createGigForm.controls.name.invalid
            "
            i18n-placeholder></app-input>

          <div
            *ngIf="
              (createGigForm.controls.name.touched || createGigForm.controls.name.dirty) &&
              createGigForm.controls.name.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="createGigForm.controls.name.errors?.required"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>Title is required.</span>
            </p>
          </div>
        </div>

        <div>
          <app-input
            label="About the event"
            [multiLine]="true"
            [invalid]="
              (createGigForm.controls.description.touched || createGigForm.controls.description.dirty) &&
              createGigForm.controls.description.invalid
            "
            [rows]="3"
            placeholder="We're looking for a music act that will gets this party going!"
            formControlName="description"
            i18n-placeholder
            i18n-label>
          </app-input>
          <div
            *ngIf="
              (createGigForm.controls.description.touched || createGigForm.controls.description.dirty) &&
              createGigForm.controls.description.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="createGigForm.controls.description.errors?.required"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>Information missing.</span>
            </p>

            <p *ngIf="createGigForm.controls.description.errors?.contactInfo" i18n>
              Contact information can be shared in a later stage
            </p>
          </div>
        </div>

        <app-file-input-drag-and-drop
          label="Event image (optional)"
          [initialFilesArray]="createGigForm.value?.images"
          (fileChange$)="imgInputSelected($event)"
          i18n-label></app-file-input-drag-and-drop>
      </form>
    </div>
  </div>
</div>
