<div
  fxLayoutAlign="stretch start"
  fxLayoutGap="120px"
  fxLayout.lt-md="column"
  fxLayoutGap.lt-md="42px"
  class="request-step container">
  <div fxFlex="316px" fxFlex.lt-md="auto" class="request-step--left">
    <h1 class="request-step--title m-b--20" i18n>Date, budget & equipment</h1>
    <p class="intro-text" i18n>Share event details, keep in mind that they can affect the final price.</p>
  </div>

  <div class="w--100per">
    <div>
      <form fxFlex="406px" fxFlex.lt-md="auto" fxLayout="column" fxLayoutGap="40px" [formGroup]="createGigForm">
        <div>
          <app-date-calendar-input
            [minDate]="todayDate"
            [selectedDates]="selectedDates"
            [invalid]="
              (createGigForm.controls.tempSlotDate.touched || createGigForm.controls.tempSlotDate.dirty) &&
              createGigForm.controls.tempSlotDate.invalid
            "
            formControlName="tempSlotDate"
            label="Date for gig"
            i18n-label></app-date-calendar-input>
          <div
            *ngIf="
              (createGigForm.controls.tempSlotDate.touched || createGigForm.controls.tempSlotDate.dirty) &&
              createGigForm.controls.tempSlotDate.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="createGigForm.controls.tempSlotDate.errors?.required"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>Date is required.</span>
            </p>
          </div>
        </div>

        <app-select-input
          label="Select start time"
          [selectOptions]="gigStartTimeOptions"
          formControlName="startTime"
          i18n-label></app-select-input>

        <app-select-input
          label="Select set-length"
          [selectOptions]="timeLengthOptions"
          formControlName="setLength"
          i18n-label></app-select-input>

        <div
          fxLayout="column"
          fxLayoutAlign="center start"
          fxLayoutGap="12px"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="full-w">
          <p class="f-weight--600" i18n>Technical euiqpment on site</p>
          <div fxLayout="row" fxLayoutGap="12px" class="full-w" fxLayoutAlign.gt-md="space-around">
            <button
              (click)="selectSupplyType(0)"
              class="image-button cursor-pointer"
              [ngClass]="{ selected: this.createGigForm.get('suppliesMixer').value }"
              fxLayoutAlign="start center"
              fxFlex="50%">
              <mat-icon class="svg-icon" svgIcon="mixer"></mat-icon>
              <span i18n>Mixertable</span>
            </button>
            <button
              (click)="selectSupplyType(2)"
              class="image-button cursor-pointer"
              [ngClass]="{ selected: this.createGigForm.get('suppliesSpeakers').value }"
              fxLayoutAlign="start center"
              fxFlex="50%">
              <mat-icon class="svg-icon" svgIcon="speakers"></mat-icon>
              <span i18n>Speakers</span>
            </button>
          </div>
          <div fxLayout="row" fxLayoutGap="12px" class="full-w m-b--24" fxLayoutAlign.gt-md="space-around">
            <button
              (click)="selectSupplyType(1)"
              class="image-button cursor-pointer"
              [ngClass]="{ selected: this.createGigForm.get('suppliesPa').value }"
              fxLayoutAlign="start center"
              fxFlex="50%">
              <mat-icon class="svg-icon pa-system" svgIcon="pa-system"></mat-icon>
              <span i18n>PA system</span>
            </button>
            <button
              (click)="selectSupplyType(-1)"
              class="image-button cursor-pointer"
              [ngClass]="{ selected: areAllSupliesDeselected() }"
              fxLayoutAlign="start center"
              fxFlex="50%">
              <mat-icon class="svg-icon none" svgIcon="none"></mat-icon>
              <span i18n>None</span>
            </button>
          </div>

          <label fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-checkbox-input formControlName="supplyStatus"></app-checkbox-input>
            <span class="f-size--14" i18n>Request artist to bring missing equipment for a fee</span>
          </label>
        </div>

        <div *ngIf="createGigForm.value.pageCategory?.length <= 1">
          <app-input
            formControlName="budgetAll"
            placeholder="0"
            [type]="'number'"
            [label]="budgetAllLabel"
            [mask]="'SEK'"
            [invalid]="
              (createGigForm.controls.budgetAll.touched || createGigForm.controls.budgetAll.dirty) &&
              createGigForm.controls.budgetAll.invalid
            "
            class="d--block"
            i18n-placeholder></app-input>

          <a href="" class="tip text-underline d--block m-b--12"
            ><span i18n>Price incl fees</span> {{ totalPriceWithFee }} SEK (excl VAT)</a
          >

          <div
            *ngIf="
              (createGigForm.controls.budgetAll.touched || createGigForm.controls.budgetAll.dirty) &&
              createGigForm.controls.budgetAll.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="createGigForm.controls.budgetAll.errors?.required"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>Price is required.</span>
            </p>
          </div></div
        >

        <div *ngIf="createGigForm.value.pageCategory?.length > 1">
          <div class="m-b--28">
            <app-input
              formControlName="budgetLive"
              placeholder="0"
              [type]="'number'"
              [label]="budgetLiveLabel"
              [mask]="'SEK'"
              [invalid]="
                (createGigForm.controls.budgetLive.touched || createGigForm.controls.budgetLive.dirty) &&
                createGigForm.controls.budgetLive.invalid
              "
              class="d--block"
              i18n-placeholder></app-input>

            <div
              *ngIf="
                (createGigForm.controls.budgetLive.touched || createGigForm.controls.budgetLive.dirty) &&
                createGigForm.controls.budgetLive.invalid
              "
              class="input-errors">
              <p
                fxLayoutAlign="start center"
                *ngIf="createGigForm.controls.budgetLive.errors?.required"
                class="input-errors--message">
                <mat-icon svgIcon="required"></mat-icon>
                <span i18n>Price is required.</span>
              </p>
            </div>
            <a href="" class="tip text-underline d--block m-b--12"
              ><span i18n="@@approximate">Price incl fees</span> {{ livePriceWithFee }} SEK
              <span i18n>(excl VAT)</span></a
            >
          </div>

          <div>
            <app-input
              formControlName="budgetDJ"
              placeholder="0"
              [type]="'number'"
              [label]="budgetDJLabel"
              [mask]="'SEK'"
              [invalid]="
                (createGigForm.controls.budgetDJ.touched || createGigForm.controls.budgetDJ.dirty) &&
                createGigForm.controls.budgetDJ.invalid
              "
              class="d--block"
              i18n-placeholder></app-input>

            <div
              *ngIf="
                (createGigForm.controls.budgetDJ.touched || createGigForm.controls.budgetDJ.dirty) &&
                createGigForm.controls.budgetDJ.invalid
              "
              class="input-errors">
              <p
                fxLayoutAlign="start center"
                *ngIf="createGigForm.controls.budgetDJ.errors?.required && createGigForm.value.budgetDJ <= 0"
                class="input-errors--message">
                <mat-icon svgIcon="required"></mat-icon>
                <span i18n>Price is required.</span>
              </p>
            </div>
            <a href="" class="tip text-underline d--block m-b--12"
              ><span i18n>Price incl fees</span> {{ djPriceWithFee }} SEK (excl VAT)</a
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
