import { Tag } from '../../generated/graphql';

export enum TagGroupEnum {
  GENRE = 1,
  FEELING = 2,
  CONTEXT = 3,
  CATEGORY = 4,
  LOCATION = 6,
  MUSIC_TYPE = 10,
  PERFORMER_TYPE = 11,
  NEW_GENRE = 12,
  INSTRUMENT = 13,
  SUB_GENRE = 14,
}

export const PageCategoryTitle = {
  LIVE: $localize`Live`,
  DJ: $localize`DJ`,
};

export const getFilteredTags = (tags: Tag[], tagGroup: TagGroupEnum): Tag[] => {
  return tags.filter((tag) => tag.group === tagGroup);
};

export const getArtistTypeTags = (tags: Tag[]): string => {
  return tags
    .filter((tag) => tag.group === 4)
    .map((tag) => tag.name)
    .join(', ');
};
