import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Image } from '../../../generated/graphql';
import { ImageToBeUploaded } from '../../models/ImageToBeUploaded';

@Component({
  selector: 'app-image-upload-area',
  templateUrl: './image-upload-area.component.html',
  styleUrls: ['./image-upload-area.component.scss'],
})
export class ImageUploadAreaComponent implements OnInit {
  @Input()
  uploadedImage: Image;
  @Input()
  pillText: string;

  @Output()
  imageSelected = new EventEmitter<ImageToBeUploaded>();

  @Output()
  imageRemoved = new EventEmitter<ImageToBeUploaded | Image>();

  imageData: ImageToBeUploaded;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  async onDrop(event: DragEvent) {
    event.preventDefault();

    // only 1 file or not image
    if (event.dataTransfer.items.length > 1 || event.dataTransfer.items[0].type.indexOf('image/') === -1) {
      return;
    }

    var file = event.dataTransfer.items[0].getAsFile();
    this.addSelectedImage(file);
  }
  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }
  imgInputSelected(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    if (target.files.length > 1 || target.files[0].type.indexOf('image/') === -1) {
      return;
    }
    this.addSelectedImage(target.files[0]);
  }

  addSelectedImage(file: File) {
    this.imageData = {
      img: file,
      objectUrl: window.URL.createObjectURL(file),
      safeCssUrl: this.sanitizer.bypassSecurityTrustUrl(`url(${window.URL.createObjectURL(file)})`),
    };
    this.uploadedImage = null;
    this.imageSelected.next(this.imageData);
  }

  removeImage() {
    this.imageRemoved.next(!!this.imageData ? this.imageData : this.uploadedImage);
    this.imageData = null;
    this.uploadedImage = null;
  }
}
