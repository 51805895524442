<div
  class="gigs-list-container"
  fxLayout="column"
  fxLayoutGap="16px"
  fxLayoutGap.gt-md="24px"
  fxLayoutAlign="center center">
  <h1 i18n fxLayout="row" fxLayoutAlign="center" fxLayoutGap="12px"><span>Gigs on Gigital</span></h1>
  <span i18n class="open-text"
    ><span class="highlight-number">{{ openGigs }}</span> gigs and
    <span class="highlight-number">{{ openSlots }}</span> slots available</span
  >
  <ng-container *ngIf="isLoggedIn">
    <h3 i18n *ngIf="!isBooker">Find a gig that fits you and apply with your artist profile.</h3>
    <h3 i18n *ngIf="isBooker">By posting a gig you can find the perfect artist match for your event.</h3>
  </ng-container>
</div>
