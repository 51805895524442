import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Booking, BookingDates, GetCurrentUserGQL, NewTypes, UserType } from '../../../../../generated/graphql';
import { bookingDateInThePast, getStatus } from '../../../../bookings/bookingUtils';
import { MEDIUM_DATE } from '../../../../helpers/dates';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';
import { TranslationsService } from '../../../../services/translations.service';

@Component({
  selector: 'app-dashboard-booking-item',
  templateUrl: './dashboard-booking-item.component.html',
  styleUrls: ['./dashboard-booking-item.component.scss'],
})
export class DashboardBookingItemComponent implements OnInit {
  @Input() userType: UserType;
  @Input() booking: Booking;

  public isTomorrow: boolean = false;
  public isToday: boolean = false;
  public status: string = '';

  DATE_FORMAT = MEDIUM_DATE;
  NEW_TYPES = NewTypes;
  USER_TYPE = UserType;

  get isBooked(): boolean {
    return this.status === 'done' || this.status === 'review' || this.status === 'completed';
  }
  get isCancelled(): boolean {
    return this.status === 'cancelled';
  }

  get payout(): number {
    return Math.round(this.booking.dates.map((date) => date.price).reduce((prev, curr) => prev + curr) * 0.85);
  }

  get firstBookingDate(): BookingDates {
    return this.booking.dates.sort((a, b) => parseInt(a.date) - parseInt(b.date))[0];
  }

  get getBookingName(): string {
    return (
      this.booking.title ||
      this.booking.appliedTo?.name ||
      $localize`${this.translations.gigType(this.booking.type).translation} gig`
    );
  }

  get acceptedDates(): BookingDates[] {
    return this.booking.dates.filter((date) => date.isAccepted);
  }

  m_isBooked: boolean;
  m_payout: number;
  m_firstBookingDate: BookingDates;
  m_bookingName: string;
  m_acceptedDates: BookingDates[];

  constructor(
    private iconsService: IconsRegistryService,
    private translations: TranslationsService,
    private currentUser: GetCurrentUserGQL,
  ) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  ngOnInit(): void {
    this.booking.dates = this.booking.dates.filter((date) => !bookingDateInThePast(date));

    this.currentUser
      .fetch()
      .pipe(map((d) => d.data.me))
      .subscribe((u) => {
        this.status = getStatus(this.booking, u.id);
      });

    this.m_isBooked = this.isBooked;
    this.m_payout = this.payout;
    this.m_firstBookingDate = this.firstBookingDate;
    this.m_bookingName = this.getBookingName;
    this.m_acceptedDates = this.acceptedDates;
  }
}
