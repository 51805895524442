<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutGap="12px" class="m-b--28">
    <div fxFlex>
      <app-date-calendar-input
        [minDate]="todayDate"
        [selectedDates]="[date]"
        [singleDate]="true"
        [invalid]="
          (formGroup.controls.date.touched || formGroup.controls.date.dirty) && formGroup.controls.date.invalid
        "
        formControlName="date"
        label="Date"
        i18n-label></app-date-calendar-input>
      <div
        *ngIf="(formGroup.controls.date.touched || formGroup.controls.date.dirty) && formGroup.controls.date.invalid"
        class="input-errors">
        <p fxLayoutAlign="start center" *ngIf="formGroup.controls.date.errors?.required" class="input-errors--message">
          <mat-icon svgIcon="required"></mat-icon>
          <span i18n>Date is required.</span>
        </p>
      </div>
    </div>
    <div fxFlex>
      <app-select-input
        label="Start time"
        [selectOptions]="gigStartTimeOptions"
        formControlName="startTime"
        i18n-label></app-select-input>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="12px" class="m-b--40">
    <app-select-input
      fxFlex
      label="Performance lenght"
      [selectOptions]="timeLengthOptions"
      formControlName="setLength"
      i18n-label></app-select-input>

    <div fxFlex>
      <app-input
        formControlName="price"
        placeholder="0"
        [type]="'number'"
        label="Price"
        [mask]="'SEK'"
        [invalid]="
          (formGroup.controls.price.touched || formGroup.controls.price.dirty) && formGroup.controls.price.invalid
        "
        class="d--block"
        i18n-label></app-input>

      <div
        *ngIf="(formGroup.controls.price.touched || formGroup.controls.price.dirty) && formGroup.controls.price.invalid"
        class="input-errors">
        <p
          fxLayoutAlign="start center"
          *ngIf="formGroup.controls.price.errors?.required && formGroup.value.price <= 0"
          class="input-errors--message">
          <mat-icon svgIcon="required"></mat-icon>
          <span i18n>Price is required.</span>
        </p>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="12px">
    <button [disabled]="!formGroup.valid" (click)="saveChanges()" class="gig-button primary wide" i18n
      >Save changes</button
    >
    <button (click)="onCancelClick$.next()" class="gig-button transparent" i18n>Cancel</button>
  </div>
</form>
