<h1 mat-dialog-title i18n>Edit gig details</h1>
<mat-dialog-content>
  <form
    [formGroup]="editGroup"
    fxLayout="column"
    fxLayoutAlign="space-between stretch"
    (submit)="update()"
    class="artist-book-form"
  >
    <div fxLayout="column">
      <app-gig-type-input [formGroup]="editGroup"></app-gig-type-input>
      <mat-form-field>
        <input formControlName="name" matInput placeholder="Name your gig" i18n-placeholder />
        <mat-error *ngIf="name.hasError('required')" i18n>
          Name is required
        </mat-error>
        <mat-error *ngIf="name.hasError('minLength')" i18n>
          Name needs to be longer
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <textarea
          formControlName="description"
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="9"
          placeholder="Describe your gig"
        ></textarea>
      </mat-form-field>
      <mat-form-field>
        <input
          [formControl]="placeId"
          matInput
          matGoogleMapsAutocomplete
          placeholder="Location"
          i18n-placeholder="@@location"
          (onAutocompleteSelected)="onAutocompleteSelected($event)"
          #placeIdInput
        />
        <mat-error *ngIf="placeId.hasError('required')" i18n>
          Location is required
        </mat-error>
        <mat-error *ngIf="placeId.hasError('pattern')" i18n>
          Please select a location from the drop down
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" fxLayoutAlign="end center" style="width: 100%">
    <button type="submit" mat-flat-button color="primary" i18n (click)="update()" [disabled]="loading"
      >Update gig details</button
    >
    <button mat-flat-button mat-dialog-close i18n="@@cancel" [disabled]="loading">Cancel</button>
  </div>
</mat-dialog-actions>
