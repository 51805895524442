import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceText',
})
export class ReplaceTextPipe implements PipeTransform {
  transform(value: string, findString: string, replaceValue: string): unknown {
    try {
      return value.split(findString).join(replaceValue);
    } catch {
      return value;
    }
  }
}
