<h1 *ngIf="!getIsMissing('phoneNumber')" matDialogTitle i18n>We need some more info from you!</h1>
<h5 *ngIf="getIsMissing('phoneNumber')" class="f-weight--600 m-b--16" i18n>Welcome to Gigital</h5>

<form [formGroup]="userFormGroup" fxLayout="column" fxLayoutGap="16px" (submit)="updateMissing()">
  <mat-form-field *ngIf="getIsMissing('firstName')">
    <input matInput formControlName="firstName" placeholder="First name" i18n="@@firstName" type="text" />
  </mat-form-field>
  <mat-form-field *ngIf="getIsMissing('lastName')">
    <input matInput formControlName="lastName" placeholder="Last name" i18n="@@lastName" type="text" />
  </mat-form-field>
  <mat-form-field *ngIf="getIsMissing('email')">
    <input matInput formControlName="email" placeholder="Email" i18n-placeholder="@@email" type="email" />
  </mat-form-field>

  <div *ngIf="getIsMissing('type')" fxLayout="column" class="m-b--48">
    <mat-label class="m-b--32" i18n>How are you planning on using Gigital?</mat-label>
    <mat-radio-group
      fxLayout="column"
      fxLayout.gt-md="row"
      fxLayoutGap="16px"
      formControlName="type"
      class="m-b--24"
      *ngIf="getIsMissing('type')">
      <mat-radio-button color="primary" [value]="USER_TYPES.Booker" i18n>I want to book artists</mat-radio-button>
      <mat-radio-button color="primary" [value]="USER_TYPES.Artist" i18n>I am an artist</mat-radio-button>
    </mat-radio-group>
    <app-input
      *ngIf="userFormGroup.get('type').value === USER_TYPES.Booker"
      formControlName="companyName"
      [placeholder]="'Company name'"
      i18n-placeholder>
    </app-input>
  </div>

  <div fxLayout="column" *ngIf="getIsMissing('lang')">
    <mat-label i18n>What is your preferred language?</mat-label>
    <mat-radio-group fxLayout="row" fxLayoutGap="16px" formControlName="lang">
      <mat-radio-button color="primary" [value]="COUNTRIES_ENUM.Sweden" i18n>Swedish</mat-radio-button>
      <mat-radio-button color="primary" [value]="COUNTRIES_ENUM.Global" i18n>English</mat-radio-button>
    </mat-radio-group>
  </div>

  <div fxLayout="column" *ngIf="getIsMissing('receiveTransactional')">
    <mat-label i18n>Do you want to receive email notifications from us?</mat-label>
    <mat-hint i18n>For example when someone sends you a message</mat-hint>
    <mat-radio-group fxLayout="row" fxLayoutGap="16px" formControlName="receiveEmails">
      <mat-radio-button color="primary" [value]="true" i18n>Yes</mat-radio-button>
      <mat-radio-button color="primary" [value]="false" i18n>No</mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- <ng-container *ngIf="userFormGroup.get('type').value === USER_TYPES.Artist || data.user.type === USER_TYPES.Artist"> -->
  <div *ngIf="getIsMissing('phoneNumber')" class="phone-number-container">
    <p class="m-b--32" i18n
      >Your phone number will only be used to communicate important information regarding your bookings.</p
    >
    <app-country-phone-codes-dropdown formControlName="phonePrefix"></app-country-phone-codes-dropdown>
    <app-phone-number-input formControlName="phoneNumber"></app-phone-number-input>
  </div>
  <!-- </ng-container> -->

  <button fxFlexAlign="end" class="gig-button primary wide" type="submit" [disabled]="loading" i18n> Submit </button>
</form>
