import { Badge, GigSlot, NotificationTypeEnum, Review } from '../generated/graphql';

export interface BaseNotification {
  type: NotificationTypeEnum;
}

export enum GenericNotificationStatus {
  REVIEW,
}

export interface GenericNotification extends BaseNotification {
  status: GenericNotificationStatus;
  data: any;
}

export enum PageNotificationStatus {
  REVIEWED_DENIED,
  REVIEWED_APPROVED,
  REVIEW_RECEIVED, // Profile review, not admin review
}

export interface PageNotification extends BaseNotification {
  status: PageNotificationStatus;
  pageId: number;
  review?: Review;
}

export enum ChannelNotificationStatus {
  MESSAGE_ADDED,
  INVITED_TO,
}
export interface ChannelNotification extends BaseNotification {
  channelId: number;
  bookingId?: number;
  status: ChannelNotificationStatus;
  fileId?: number;
}

export enum BookingNotificationStatus {
  ADDED,
  DECLINED,
  CANCELLED,
  SIGNED,
  COMPONENT_ADDED,
  COMPONENT_MODIFIED,
  COMPONENT_REMOVED,
  LEGACY_MODIFIED, // For old notifications about booking updated
  LEGACY_ACCEPTED, // For old accept book request
  COMPONENT_SIGNED,
}
export interface BookingNotification extends BaseNotification {
  bookingId: number;
  componentId?: number;
  componentType?: string;
  date?: Date;
  place?: any | null;
  status: BookingNotificationStatus;
  profilePicture?: any | null;
}

export enum GigNotificationStatus {
  APPLICATION,
  TIME_UPDATE,
  TIME_EXPIRED,
  APPLICATION_DENIED,
  SLOT_ASSIGNED, // Gig owner booked someone else
}

export interface GigNotification extends BaseNotification {
  gigId: number;
  gigName: string;
  applicationId?: number;
  slot?: GigSlot;
  status: GigNotificationStatus;
}

export enum OrganizationNotificationStatus {
  INVITED_TO,
}
export interface OrganizationNotification extends BaseNotification {
  organizationId: number;
  organizationName: string;
  status: OrganizationNotificationStatus;
}

export interface BadgeNotification extends BaseNotification {
  badge: Badge;
}
