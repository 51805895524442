import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as countryPhoneCodes from '../../../../../assets/static/countryPhoneCodes.json';

export interface Country {
  name: string;
  dialCode: string;
  isoCode: string;
  flag: string;
}

@Component({
  selector: 'app-country-phone-codes-dropdown',
  templateUrl: './country-phone-codes-dropdown.component.html',
  styleUrls: ['./country-phone-codes-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CountryPhoneCodesDropdownComponent,
      multi: true,
    },
  ],
})
export class CountryPhoneCodesDropdownComponent implements ControlValueAccessor {
  @Output() onDropdownClose$: EventEmitter<any> = new EventEmitter();

  @Input() disabled: boolean = false;

  @Input() label: string = '';
  @Input() isLightLabel: false;
  @Input() labelTooltip: string = '';

  public selectedCountry: Country;
  public phonePrefix: string;

  public COUNTRY_PHONE_CODES: Country[] = (countryPhoneCodes as any).default;

  set value(dialCode: string) {
    this.selectedCountry = dialCode
      ? this.COUNTRY_PHONE_CODES.find((country) => country.dialCode === dialCode)
      : this.COUNTRY_PHONE_CODES.find((country) => country.name === 'Sweden');
    this.phonePrefix = dialCode;

    this.onTouch(dialCode);
    this.onChange(dialCode);
  }

  get value() {
    return this.phonePrefix;
  }

  public writeValue(dialCode: string): void {
    this.value = dialCode
      ? this.COUNTRY_PHONE_CODES.find((country) => country.dialCode === dialCode).dialCode
      : this.COUNTRY_PHONE_CODES.find((country) => country.name === 'Sweden').dialCode;
  }

  private onChange: any = () => {};
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  private onTouch: any = () => {};
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public selectCountry(country: Country): void {
    this.value = country.dialCode;
    this.onDropdownClose$.emit();
  }
}
