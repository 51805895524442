<div class="booking-bar bg-white">
  <div
    class="gigs-container"
    fxLayout.gt-md="row"
    fxLayout="column"
    fxLayoutGap="8px"
    fxLayoutGap.gt-md="16px"
    fxLayoutAlign.gt-md="start center">
    <p class="gray-1 mat-body select-text" *ngIf="totalApplications === 0" i18n
      >You are still waiting for applications
    </p>
    <p class="gray-1 mat-body select-text" *ngIf="totalApplications > 0 && applications.length === 0" i18n
      ><strong matTooltip="Click on an application to select it" i18n-matTooltip>Select applications</strong> from your
      acts that you would like to go forward with</p
    >
    <p class="gray-1 mat-body selected-text" *ngIf="totalApplications > 0 && applications.length > 0"
      ><strong matTooltip="Click on an application to select it" i18n-matTooltip i18n
        >{{ applications.length }}/{{ totalApplications }} selected</strong
      ></p
    >
    <div
      [class.fix-margins]="applications.length > 0"
      fxLayoutGap.gt-md="16px grid"
      fxLayoutGap="8px"
      fxLayout="column"
      fxLayout.gt-md="row wrap">
      <div *ngFor="let application of applications" fxLayout="row">
        <ng-container *ngTemplateOutlet="bookingPill; context: { application: application }"></ng-container>
      </div>
    </div>
    <div>
      <button
        mat-flat-button
        color="primary"
        i18n="@@sendBookingRequest"
        class="submit"
        [disabled]="applications.length === 0"
        (click)="submit()"
        >Send booking request{{ applications.length === 1 ? '' : 's' }}</button
      >
    </div>
  </div>
</div>

<ng-template #bookingPill let-application="application">
  <div class="application" fxLayout fxLayoutGap="8px" fxLayoutAlign="center center">
    <app-avatar
      [profilePicture]="application.booking.page.profilePicture"
      (click)="openApplication(application)"
      matTooltip="View artists application"
      i18n-matTooltip
      target="blank"></app-avatar>
    <div>
      <h3 class="mat-body bright-red page-name">{{ application.booking.page.name }}</h3>
      <h4 class="mat-body-2 gray-2">{{ application.slot.date | date }}</h4>
    </div>
    <button
      mat-icon-button
      (click)="remove(application)"
      i18n
      i18n-matTooltip
      matTooltip="Remove selection"
      class="remove">
      <mat-icon class="gray-3">clear</mat-icon>
    </button>
  </div>
</ng-template>
