<input type="file" (change)="fileChangeEvent($event)" #select accept="image/*" />
<div fxLayout="column">
  <p *ngIf="loading" class="mat-body gray-1">Loading image...</p>
  <div fxFlex="100" class="crop" *ngIf="image">
    <image-cropper
      #cropper
      [imageFile]="image"
      [imageChangedEvent]="imageChangedEvent"
      [aspectRatio]="data.aspect"
      [alignImage]="'left'"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      onlyScaleDown
    ></image-cropper>
  </div>
  <div *ngIf="hasError" fxLayout="row" fxLayoutAlign="center center">
    <mat-error i18n>Something went wrong when trying to display your image</mat-error>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" class="select-image">
    <button mat-flat-button color="primary" (click)="openSelect()">
      <ng-container i18n *ngIf="!image">Select image</ng-container>
      <ng-container i18n *ngIf="image">Select new image</ng-container>
    </button>
  </div>
</div>
