<div *ngIf="isCreateMode || page; else loadingTmplt">
  <div class="toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="container">
      <div fxHide.lt-md fxLayoutGap="16px" fxLayoutAlign="start center">
        <app-avatar
          [profilePicture]="page?.profilePicture"
          [medium]="true"
          [user]="page?.user"
          fxFlexAlign="start"></app-avatar>
        <div *ngIf="pageProgress" fxFlex="210px" fxLayout="column" fxLayoutGap="13px" class="progress">
          <mat-progress-bar [value]="pageProgress.progressPercentage"></mat-progress-bar>
          <p class="progress--text">
            <span class="f-weight--600">{{ pageProgress.stepsCompleted }}</span> out of
            <span class="f-weight--600">{{ pageProgress.stepsTotal }}</span> steps completed
          </p>
        </div>
      </div>

      <div fxLayoutAlign="space-between center" fxLayoutGap="20px" class="w--100per">
        <div>
          <button
            *ngIf="!isSidenavOpened"
            fxHide
            fxShow.lt-md
            class="gig-button stroked small"
            (click)="sidenav.open()"
            i18n>
            Show menu
          </button>
          <button
            *ngIf="isSidenavOpened"
            fxHide
            fxShow.lt-md
            class="gig-button stroked small"
            (click)="sidenav.close()"
            i18n>
            Hide menu
          </button>
        </div>
        <div fxFlex.lt-sm fxLayoutAlign.lt-sm="end center" fxLayoutGap="20px" fxLayoutGap.lt-sm="12px">
          <button *ngIf="page?.id" fxHide fxShow.lt-sm [routerLink]="['preview']" class="gig-button stroked" i18n>
            <mat-icon svgIcon="eye" class="eye-icon"></mat-icon>
          </button>

          <button *ngIf="page?.id" fxHide.lt-sm [routerLink]="['preview']" class="gig-button stroked" i18n
            >Show preview</button
          >
          <button
            *ngIf="page?.reviewStatus === REVIEW_STATUS.New"
            (click)="sendPageForReview()"
            [disabled]="!canBeReviewed"
            class="gig-button primary"
            i18n
            >Send for review</button
          >
        </div>
      </div>
    </div>
  </div>

  <mat-sidenav-container>
    <mat-sidenav
      [autoFocus]="false"
      class="artist-details-sidenav"
      [mode]="isMobile ? 'push' : 'side'"
      [opened]="!isMobile"
      (openedChange)="onSidenavChange($event)"
      #sidenav>
      <div class="container container-left">
        <div fxHide fxShow.lt-md fxLayoutGap="16px" fxLayoutAlign="start center" class="m-b--45">
          <app-avatar
            [profilePicture]="page?.profilePicture"
            [medium]="true"
            [user]="page?.user"
            fxFlexAlign="start"></app-avatar>
          <div *ngIf="pageProgress" fxFlex="210px" fxLayout="column" fxLayoutGap="13px" class="progress">
            <mat-progress-bar [value]="pageProgress.progressPercentage"></mat-progress-bar>
            <p class="progress--text">
              <span class="f-weight--600">{{ pageProgress.stepsCompleted }}</span> out of
              <span class="f-weight--600">{{ pageProgress.stepsTotal }}</span> steps completed
            </p>
          </div>
        </div>
        <ul class="progress-steps">
          <li
            *ngFor="let step of pageProgress.steps"
            fxLayout="row"
            fxLayoutGap="8px"
            fxLayoutAlign="start center"
            class="progress-step"
            [ngClass]="{ active: openedStepNumber === PROGRESS_STEP_NUMBER[step.type] }"
            (click)="onTabChange(step)">
            <mat-icon class="progress-step--icon" [svgIcon]="step.completed ? 'done-green' : 'done-gray'"> </mat-icon>
            <div fxLayout="column" fxLayoutGap="4px">
              <div fxLayoutAlign="start center" fxLayoutGap="8px">
                <h3 class="progress-step--name">{{ step.name }}</h3>
                <span *ngIf="step.isNew && !step.denied" class="progress-step--status-label bg-bright-red" i18n
                  >New</span
                >
                <span *ngIf="step.denied" class="progress-step--status-label bg-orange" i18n>Feedback</span>
              </div>
              <p class="progress-step--description">{{ step.description }}</p>
            </div>
          </li>
        </ul>

        <form *ngIf="page?.id" [formGroup]="urlSlugFormGroup" class="url-slug">
          <div class="m-b--10">
            <app-input
              fxFlex="1 0 270px"
              formControlName="slug"
              (keypress)="onUrlSlugChanged$.next($event)"
              label="Username"
              tabindex="-1"
              [isLightLabel]="true"
              placeholder="Username"
              i18n-placeholder
              i18n-label></app-input>
          </div>
          <span *ngIf="!urlSlugFormGroup.dirty && !urlSlugFormGroup.touched" class="url-slug--description" i18n>
            Your profile will be found at <br />gigital.com/artist/{{ page.slug || 'username' }}
          </span>
        </form>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="full-height">
      <div *ngIf="!isLoading; else loadingTmplt" fxFlex="grow" class="height-100per w--100per">
        <app-artist-detail-edit-import
          *ngIf="openedStepNumber === PROGRESS_STEP_NUMBER.IMPORT_CONTENT"
          [pageId]="page?.id"
          [isCreateMode]="isCreateMode"
          [pageProgressStep]="getPageProgressStep(PROGRESS_STEP_TYPE.IMPORT_CONTENT)"
          (goToStep$)="goToStep($event)"></app-artist-detail-edit-import>

        <app-artist-detail-basic-info
          *ngIf="openedStepNumber === PROGRESS_STEP_NUMBER.BASIC_INFO"
          [page]="page"
          [pageProgressStep]="getPageProgressStep(PROGRESS_STEP_TYPE.BASIC_INFO)"
          (goToStep$)="goToStep($event)"></app-artist-detail-basic-info>

        <app-artist-detail-tags
          *ngIf="openedStepNumber === PROGRESS_STEP_NUMBER.TAGS"
          [page]="page"
          [pageProgressStep]="getPageProgressStep(PROGRESS_STEP_TYPE.TAGS)"
          (goToStep$)="goToStep($event)"></app-artist-detail-tags>

        <app-artist-edit-media
          *ngIf="openedStepNumber === PROGRESS_STEP_NUMBER.MEDIA"
          [page]="page"
          [pageProgressStep]="getPageProgressStep(PROGRESS_STEP_TYPE.MEDIA)"
          (goToStep$)="goToStep($event)"></app-artist-edit-media>

        <app-artist-detail-faq
          *ngIf="openedStepNumber === PROGRESS_STEP_NUMBER.FAQ"
          [page]="page"
          [pageProgressStep]="getPageProgressStep(PROGRESS_STEP_TYPE.FAQ)"
          (goToStep$)="goToStep($event)"
          (setPageProgress$)="setPageProgress()"></app-artist-detail-faq>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #loadingTmplt>
  <div fxLayout fxLayoutAlign="center center" class="full-height w--100per">
    <div fxLayout="column" fxLayoutGap="48px" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template>
