import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gigital-logo',
  templateUrl: './gigital-logo.component.svg',
  styleUrls: ['./gigital-logo.component.scss'],
})
export class GigitalLogoComponent {
  constructor() {}

  @Input()
  color = '#1e272e';

  @Input()
  height = 34;
}
