import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  Booking,
  GetCurrentUserGQL,
  GetGigGQL,
  GetGigQuery,
  GetGigQueryVariables,
  Gig,
  User,
} from '../../../../generated/graphql';
import { GratificationComponent } from '../../../components/gratification/gratification.component';
import { MetaService, MetaTag } from '../../../services/meta.service';
import { PopupService } from '../../../services/popup.service';

@Component({
  selector: 'app-gig-details',
  templateUrl: './gig-details.component.html',
  styleUrls: ['./gig-details.component.scss'],
})
export class GigDetailsComponent implements OnInit {
  gig: Observable<Gig>;
  applications: Observable<Booking[]>;
  user: Observable<User>;
  gigId: number;
  gigsQuery: QueryRef<GetGigQuery, GetGigQueryVariables>;
  skip = 0;
  hasNextPage = true;

  constructor(
    private getGig: GetGigGQL,
    private activeRoute: ActivatedRoute,
    private getMe: GetCurrentUserGQL,
    private router: ActivatedRoute,
    private popups: PopupService,
    private meta: MetaService,
  ) {}

  ngOnInit() {
    this.gigId = parseInt(this.activeRoute.snapshot.params.gigId, 10);
    this.gigsQuery = this.getGig.watch({ gigId: this.gigId, paging: { limit: 10, skip: this.skip } });
    this.gig = this.gigsQuery.valueChanges.pipe(
      map(({ data }) => {
        this.hasNextPage = data.gigById.hasNextPage;
        this.skip = data.gigById.skip;
        return data.gigById.gig;
      }),
      tap((gig) => {
        this.meta.setLocalizedTitle({
          en: `${gig.name} | Gigital`,
          'sv-se': ` ${gig.name} | Gigital`,
        });

        this.meta.setLocalizedDescription({
          en: `${gig.details}`,
          'sv-se': `${gig.details}`,
        });

        if (gig.images[0]) {
          this.meta.setOgTag(
            MetaTag.ogImage,
            `https://res.cloudinary.com/hthruuqgk/image/upload/f_auto,fl_progressive,q_auto/c_limit,w_100/v1/${gig.images[0].fileName}`,
          );
        }
      }),
    );
    this.user = this.getMe.watch().valueChanges.pipe(map(({ data }) => data.me));

    if (this.router.snapshot.queryParams.first) {
      this.popups.open(GratificationComponent, '800px', {
        data: { contentfulId: '7AR0cuD42ullN59Pyo1qOQ' },
        minWidth: '85vw',
      });
    }
  }

  fetchMoreSlots() {
    this.gigsQuery.fetchMore({
      variables: { gigId: this.gigId, paging: { limit: 10, skip: this.skip } },
      updateQuery: (prev, { fetchMoreResult }) => {
        prev.gigById.skip = fetchMoreResult.gigById.skip;
        prev.gigById.hasNextPage = fetchMoreResult.gigById.hasNextPage;
        prev.gigById.gig.slots = [...prev.gigById.gig.slots, ...fetchMoreResult.gigById.gig.slots];
        return prev;
      },
    });
  }
}
