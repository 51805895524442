<div *ngIf="pages" class="artists-container">
  <a class="show-all" (click)="onShowAllClick()" i18n>Show all</a>
  <carousel
    *ngIf="isBrowser"
    [style]="{ 'z-index': '5' }"
    [cellsToShow]="slidersToShow"
    [cellsToScroll]="1"
    [arrows]="screenWidth > 800 ? true : false"
    [height]="sliderHeight"
    [lightDOM]="true"
    [margin]="24">
    <app-artist-list-item class="carousel-cell" *ngFor="let page of pages" [page]="page"></app-artist-list-item>
  </carousel>
</div>
