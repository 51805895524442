import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-empty-artist-profile',
  templateUrl: './empty-artist-profile.component.html',
  styleUrls: ['./empty-artist-profile.component.scss'],
})
export class EmptyArtistProfileComponent implements OnInit {
  @Input() isFirst: boolean = false;

  constructor(private iconsService: IconsRegistryService, private router: Router, private dialog: MatDialog) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  ngOnInit(): void {}

  public openCreateArtist(): void {
    this.router.navigate(['artists', 'import']);
  }
}
