import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './auth.service';

const TRANSPARENT_ROUTES = ['/', '/for-artists', '/customers'];
const HIDDEN_ROUTES = [/\/gigs\/add/, /((artists\/)(.*)(\/book))/, /\/admin/];

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  transparent = false;
  transparentRoute = false;
  hiddenRoute = false;

  constructor(private router: Router, private auth: AuthService) {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((val: NavigationStart) => {
      this.transparentRoute = !this.auth.isLoggedIn && TRANSPARENT_ROUTES.indexOf(val.url) > -1;
      if (this.transparentRoute && window.pageYOffset === 0) {
        this.transparent = true;
      } else {
        this.transparent = false;
      }

      this.hiddenRoute = false;
      for (const r of HIDDEN_ROUTES) {
        if (r.test(val.url)) {
          this.hiddenRoute = true;
          break;
        }
      }
    });
  }

  checkTransparency(windowScroll: number): void {
    if (!this.transparentRoute) {
      return;
    }
    if (windowScroll > 0) {
      this.transparent = false;
    } else {
      this.transparent = true;
    }
  }
}
