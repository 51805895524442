import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IconSubsets, IconsRegistryService } from '../../../../services/icons-registry.service';

interface ImageToBeUploaded {
  img: File;
  imgSrc: SafeUrl;
  safeCssUrl: SafeUrl;
  isOld: boolean;
  oldId?: number;
}

@Component({
  selector: 'app-file-input-drag-and-drop',
  templateUrl: './file-input-drag-and-drop.component.html',
  styleUrls: ['./file-input-drag-and-drop.component.scss'],
})
export class FileInputDragAndDropComponent implements OnInit {
  @Output() fileChange$: EventEmitter<ImageToBeUploaded[]> = new EventEmitter();
  @Input() initialFilesArray: ImageToBeUploaded[];
  @Input() requiredFileType: string;
  @Input() label: string;

  public filesArray: ImageToBeUploaded[] = [];

  public constructor(private iconsService: IconsRegistryService, private sanitizer: DomSanitizer) {
    this.iconsService.registerIcons([IconSubsets.INPUTS, IconSubsets.ACTIONS]);
  }

  public ngOnInit(): void {
    if (this.initialFilesArray?.length > 0) {
      this.filesArray = this.initialFilesArray;
    }
  }

  public imgInputSelected(files: File[]) {
    if (files.length <= 0 || files[0].type.indexOf('image/') === -1) {
      return;
    }
    Array.from(files)
      .slice(0, 3)
      .forEach((file) => this.addSelectedImage(file));
  }

  public addSelectedImage(file: File): void {
    const newImage: ImageToBeUploaded = {
      img: file,
      imgSrc: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file)),
      safeCssUrl: this.sanitizer.bypassSecurityTrustUrl(`url(${window.URL.createObjectURL(file)})`),
      isOld: false,
    };
    if (this.filesArray.length < 3) {
      this.filesArray.push(newImage);
    } else {
      this.filesArray.splice(0, 1, newImage);
    }
    this.fileChange$.next(this.filesArray);
  }

  async onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer.items.length > 1 || event.dataTransfer.items[0].type.indexOf('image/') === -1) {
      return;
    }
    var file = event.dataTransfer.items[0].getAsFile();
    this.addSelectedImage(file);
  }

  public onDragOver(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public removeImage(index: number): void {
    this.filesArray.splice(index, 1);
  }
}
