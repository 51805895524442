export interface ArtistProgress {
  steps: ArtistProgressStep[];
  stepsTotal: number;
  stepsCompleted: number;
  progressPercentage: number;
}

export interface ArtistProgressStep {
  type: ProgressStepType;
  stepNumber: number;
  name: string;
  description: string;
  completed: boolean;
  isNew: boolean;
  isRequired: boolean;
  denyReason?: string;
  denied: boolean;
  checkFn?(): boolean;
}

export enum ProgressStepType {
  IMPORT_CONTENT = 'IMPORT_CONTENT',
  BASIC_INFO = 'BASIC_INFO',
  TAGS = 'TAGS',
  MEDIA = 'MEDIA',
  AUDIENCE = 'AUDIENCE',
  FAQ = 'FAQ',
}

export enum ProgressStepNumber {
  IMPORT_CONTENT = 1,
  BASIC_INFO = 2,
  TAGS = 3,
  MEDIA = 4,
  FAQ = 5,
}

export interface TranslatedPageStep {
  stepType: string;
  name: string;
  description: string;
}
