import { Component, OnInit } from '@angular/core';
import { GetContentfulEntryGQL, Image } from '../../../../generated/graphql';
import { parseQueryParams } from '../../../../utils/contentful';
import { richTextToHtml } from '../../../helpers';
import { MetaService } from '../../../services/meta.service';
import { TranslationsService } from '../../../services/translations.service';

@Component({
  selector: 'app-harrys',
  templateUrl: './harrys.component.html',
  styleUrls: ['./harrys.component.scss'],
})
export class HarrysComponent implements OnInit {
  constructor(
    private getContentfulEntry: GetContentfulEntryGQL,
    private translations: TranslationsService,
    private meta: MetaService,
  ) {}

  public parseQueryParams = parseQueryParams;
  isLoading = true;
  hero;
  infoBlock;
  tabbedInfo;
  selectedTab = 0;
  testimonials;
  faq;
  // helpVideo;
  logos;

  ngOnInit() {
    // do NOT index this page hihi 🤭
    this.meta.setNoIndex();

    this.getContentfulEntry
      .fetch({ id: '1yGEpuvSnOBhxowuMCAvLE', locale: this.translations.getCurrentLocale() })
      .subscribe((result) => {
        const page = result.data.getContentfulEntry.contentfulData;
        this.hero = page.fields.blocks.find((e) => e.sys.contentType.sys.id === 'hero');
        this.infoBlock = page.fields.blocks.find((e) => e.sys.id === '6956nQEIUY46f00XUS8Xrm');
        this.tabbedInfo = page.fields.blocks.find((e) => e.sys.id === '7dWtA9IguVOzO7LM07NGib');
        this.testimonials = page.fields.blocks.find((e) => e.sys.id === '25huFq59XSlqxM8OJOP5eR');
        this.faq = page.fields.blocks.find((e) => e.sys.id === '6atzUDmdsXW4e9b6uXOZY6');
        // this.helpVideo = page.fields.blocks.find((e) => e.sys.id === '2TZ10aSd5wNRk0g519sFL4');
        // this.logos = page.fields.blocks.find((e) => e.sys.id === '1jAX0LwvUDtqNFG0SESWoc');

        this.isLoading = false;
      });
  }

  imageIdToImage(publicId: string): Image {
    return {
      cropData: {
        x: -1,
        h: -1,
        w: -1,
        y: -1,
      },
      fileName: publicId,
      id: -1,
    };
  }
  richTextToHtml(text) {
    return richTextToHtml(text);
  }
}
