import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Pagination } from '../../../generated/graphql';
import { IconsRegistryService, IconSubsets } from '../../services/icons-registry.service';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Output() onPageChanged: EventEmitter<Pagination> = new EventEmitter();

  @Input() resetPaging$: BehaviorSubject<any>;
  @Input() totalRecords: number = 0;
  @Input() pageSize: number = 10;

  private unsubscribeAll: Subject<any> = new Subject<any>();
  public pageSizeOptions: number[] = [10, 15, 20];
  public activePage: number = 1;

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  public ngOnInit(): void {
    this.resetPaging$.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => this.changePage(1));
  }

  public setPageSize(newPageSize: number): void {
    this.pageSize = newPageSize;
    this.changePage(1);
  }

  public getTotalPageCount(): number {
    let totalPage = 0;
    if (this.totalRecords > 0 && this.pageSize > 0) {
      const pageCount = this.totalRecords / this.pageSize;
      totalPage = Math.ceil(pageCount);
    }
    return totalPage;
  }

  public changePage(newPageNumber: number): void {
    if (newPageNumber >= 1 && newPageNumber <= this.getTotalPageCount()) {
      this.activePage = newPageNumber;
      this.onPageChanged.next({ limit: this.pageSize, skip: (this.activePage - 1) * this.pageSize });
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
