<div class="artists-container" *ngIf="!isLoading; else loading">
  <app-artist-hero [artist]="heros[0]"></app-artist-hero>
  <div class="artist-list-container">
    <ng-container *ngFor="let block of blocks">
      <app-artists-list-contentful *ngIf="isList(block)" [list]="getMappedList(block)"></app-artists-list-contentful>
      <div *ngIf="isInfo(block)" class="info-block-container">
        <div class="info-block-image">
          <cl-image class="cover-img" responsive width="auto"
            [public-id]="createGigInfoBlock.fields.image[0].public_id">
            <cl-transformation quality="auto" fetch-format="auto" flags="progressive"></cl-transformation>
          </cl-image>
        </div>
        <div fxLayout="column" class="info-block-content">
          <h3 fxFlexAlign="center" class="h4 white">{{ createGigInfoBlock.fields.title }}</h3>
          <div class="info-block-content-text" fxFlexAlign="center">
            <div [innerHTML]="toHTMLString(block.fields.body)"></div>
          </div>
          <a [routerLink]="[createGigInfoBlock.fields.ctAs.fields.url]" color="primary" mat-flat-button
            fxFlexAlign="center">{{ createGigInfoBlock.fields.ctAs.fields.title }}</a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #loading>
  <div class="loading-screen" fxLayout fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="48px" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template>
