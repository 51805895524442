<div class="home-landing" *ngIf="userFlowSteps">
  <app-home-landing-header
    [homePageMetaData]="headerMetaData"
    [userFlowSteps]="userFlowSteps"></app-home-landing-header>

  <div class="container">
    <section>
      <ng-container *ngFor="let pageTagList of artistsListsTop">
        <div *ngIf="pageTagList?.pages?.length > 0" class="m-b--28">
          <h2 class="h5 f-weight--600 m-b--28">{{ pageTagList.fields.title }}</h2>
          <app-artist-list-slider
            [pages]="pageTagList?.pages"
            [filters]="pageTagList.fields.ctAs.fields.queryParams"></app-artist-list-slider>
        </div>
      </ng-container>
    </section>

    <section *ngIf="trendingSearchesBlocks?.length > 0" class="m-b--56">
      <h2 fxLayoutAlign="start center" fxLayoutGap="16px" class="h5 f-weight--600 m-b--24">
        <mat-icon svgIcon="trend" class="trend-search-icon"></mat-icon>
        <span i18n>Trending searches</span>
      </h2>
      <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="search-trends-container">
        <app-search-trend-item
          fxFlex="calc(50% - 16px)"
          fxFlex.lt-sm="100%"
          *ngFor="let searchItem of trendingSearchesBlocks"
          [searchTrendItem]="searchItem.fields"></app-search-trend-item>
      </div>
    </section>

    <section class="m-b--68">
      <ng-container *ngFor="let pageTagList of artistsListsBottom">
        <div *ngIf="pageTagList?.pages?.length > 0" class="m-b--28">
          <h2 class="h5 f-weight--600 m-b--24">{{ pageTagList.fields.title }}</h2>
          <app-artist-list-slider
            [pages]="pageTagList?.pages"
            [filters]="pageTagList.fields.ctAs.fields.queryParams"></app-artist-list-slider>
        </div>
      </ng-container>
    </section>

    <section>
      <div fxLayoutAlign="space-between start" fxLayout.lt-md="column" class="post-gig-container">
        <div class="p-t--16">
          <h2 class="h4 f-weight--600 white" i18n>Post a gig and let the artists find you instead.</h2>
          <button class="gig-button primary wide" i18n="@@postGigHome" [routerLink]="['/gigs/add']">Post gig</button>
        </div>
      </div>
    </section>
  </div>
</div>
