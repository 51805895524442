import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetGigsByUserGQL, Gig } from '../../../../generated/graphql';

@Component({
  selector: 'app-my-gigs-list',
  templateUrl: './my-gigs-list.component.html',
  styleUrls: ['./my-gigs-list.component.scss'],
})
export class MyGigsListComponent implements OnInit {
  public gigs: Observable<Gig[]>;
  public LABELS_LIST = [
    { trans: $localize`Gig`, title: 'Gig', width: '180px' },
    { trans: $localize`Date`, title: 'Date', width: '130px' },
    { trans: $localize`Price Before`, title: 'Price Before', width: '112px', mdWidth: '80px' },
    { trans: $localize`Slots booked`, title: 'Slots booked', width: '95px', mdWidth: '70px' },
    { trans: $localize`Status`, title: 'Status', width: '100px' },
  ];
  public policyLink = 'http://help.gigital.se/en/articles/3350100-payment-policy';

  constructor(private getUserGigs: GetGigsByUserGQL) {}

  ngOnInit() {
    this.gigs = this.getUserGigs.watch().valueChanges.pipe(map((d) => d.data.gigsByUser));
  }
}
