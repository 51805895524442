<div
  *ngIf="socialMediaAccountData"
  class="page-card"
  [ngClass]="{ selected: isSelected }"
  (click)="onImportablePageCardClick()">
  <div fxLayout="column" fxLayoutAlign="start center" class="page-card--image">
    <img [src]="socialMediaAccountData.thumbnailImageUrl" />
  </div>
  <p *ngIf="socialMediaAccountData.name">{{ socialMediaAccountData.name }}</p>

  <p *ngIf="socialMediaAccountData?.userName">
    <ng-container *ngIf="socialMediaAccountData.mediaType !== SOCIAL_MEDIA_ACCOUNT_TYPE.Youtube">@</ng-container
    >{{ socialMediaAccountData.userName }}
  </p>
</div>
