<div
  *ngIf="message?.sender.id !== 1; else update"
  [ngClass]="{ mine: currentUser?.id === message?.sender.id }"
  class="channel__message mat-body-2"
  fxLayout="rows">
  <app-avatar [user]="message?.sender" fxFlexAlign="start"></app-avatar>
  <div fxFlex="grow">
    <div class="channel__message-header" fxLayout fxLayoutAlign="start center">
      <p class="channel__message-header-title">{{ message?.sender.firstName }} {{ message?.sender.lastName[0] }}</p>
      <time
        class="mat-caption channel__message-header-date"
        [matTooltip]="message?.createdDate | date: dateFormat"
        matTooltipPosition="above"
        [matTooltipShowDelay]="200"
        >{{ formattedDate }}</time
      >
    </div>
    <div class="channel__message-body">
      <p *ngIf="!message?.subMessages">{{ message?.body }}</p>
      <p *ngFor="let subMessage of message?.subMessages">
        <time
          class="mat-caption channel__message-body-date"
          [matTooltip]="subMessage.createdDate | date: dateFormat"
          matTooltipPosition="above"
          [matTooltipShowDelay]="200"
          >{{ subMessage.createdDate | date: 'HH:mm' }}</time
        >{{ subMessage.body }}</p
      >
    </div>
    <!-- <mat-divider *ngIf="!isLastMessage"></mat-divider> -->
  </div>
</div>

<ng-template #update>
  <app-bot-message [message]="message"></app-bot-message>
</ng-template>
