import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi: true,
    },
  ],
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  @ViewChild('dropdownContent', { static: false }) dropdownContent: ElementRef;

  @Input() onClosed: EventEmitter<any>;

  @Input() isSmallIcon: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() autoClose: boolean = true;

  public opened: boolean = false;
  public selectedItem: any;

  public ngOnInit(): void {
    if (this.onClosed) this.onClosed.subscribe(() => this.close());
  }

  public writeValue(obj: any): void {
    // this.selectValue(obj);
    this.selectedItem = obj;
  }

  public valueChanged(val) {}
  public registerOnChange(fn: any): void {
    this.valueChanged = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  // Close on clicking outside of select dropdown
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.dropdownContent.nativeElement.contains(event.target) && this.opened) {
      console.log('closing :)');
      this.opened = false;
    }
  }

  toggleOpened(event) {
    // Prevent click outside listener from getting triggered.
    // Path is -> Open dropdown & set isOpened to true
    // -> Propogate down and enter the clickout() method which sees we clicked outside and opened has been set to tru
    event.stopPropagation();

    if (!this.isDisabled) {
      this.opened = !this.opened;
    }
  }

  public close(): void {
    this.opened = false;
  }

  selectItem(item, event) {
    event.stopPropagation();

    this.selectValue(item);

    // CVA overwritter
    this.valueChanged(item);
  }

  private selectValue(item) {
    // Just prevent rerendering shit
    if (this.selectedItem != item) {
      this.selectedItem = item;
    }

    if (this.autoClose) {
      this.opened = false;
    }
  }
}
