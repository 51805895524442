import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-checkbox-toggle-slider',
  templateUrl: './checkbox-toggle-slider.component.html',
  styleUrls: ['./checkbox-toggle-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxToggleSliderComponent,
      multi: true,
    },
  ],
})
export class CheckboxToggleSliderComponent implements ControlValueAccessor {
  @Input() switchIcon: string;
  @Input() disabled: boolean;
  @Input() label: string;

  public inputValue: boolean;

  constructor(private iconsService: IconsRegistryService) {
    iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public writeValue(value: boolean): void {
    this.inputValue = value;
  }

  public registerOnChange(fn: any): void {
    this.valueChanged = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {}

  public valueChanged(value) {}

  public inputChanges(event) {
    let value: string = event.target.checked;
    this.valueChanged(value);
  }
}
