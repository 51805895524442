import {DataProxy} from '@apollo/client/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { map } from 'rxjs/operators';
import {
  CreatePageGQL,
  CreatePageMutation,
  GetPagesByUserDocument,
  GetPagesByUserQuery,
  GetTagsGQL,
} from '../../../../generated/graphql';

@Component({
  selector: 'app-create-artist',
  templateUrl: './create-artist.component.html',
  styleUrls: ['./create-artist.component.scss'],
})
export class CreateArtistComponent implements OnInit {
  createArtistForm: FormGroup;
  loading = false;

  djId = 86;
  artistId = 87;
  bandId = 88;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private createPage: CreatePageGQL,
    private dialogRef: MatDialogRef<CreateArtistComponent>,
    private tags: GetTagsGQL,
  ) {}

  ngOnInit() {
    // TODO: Find better
    this.tags
      .fetch()
      .pipe(
        map(({ data }) => {
          this.djId = data.tags.find(t => t.name.toLowerCase() === 'dj').id;
          this.artistId = data.tags.find(t => t.name.toLowerCase() === 'artist').id;
          this.bandId = data.tags.find(t => t.name.toLowerCase() === 'band').id;
          return data.tags;
        }),
      )
      .subscribe();

    this.createArtistForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      ownership: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });
  }

  get name(): AbstractControl {
    return this.createArtistForm.get('name');
  }

  get ownership(): AbstractControl {
    return this.createArtistForm.get('ownership');
  }

  get type(): AbstractControl {
    return this.createArtistForm.get('type');
  }

  createArtist(): void {
    this.loading = true;
    const payload = {
      name: this.name.value,
      ownership: this.ownership.value,
      tags: [parseInt(this.type.value, 10)],
    };

    this.createPage
      .mutate(
        { page: payload },
        {
          update: (store: DataProxy, { data }: { data: CreatePageMutation }) => {
            const cache: GetPagesByUserQuery = store.readQuery({ query: GetPagesByUserDocument });
            cache.pagesByUser.push(data.createPage);
            store.writeQuery({ query: GetPagesByUserDocument, data: cache });
          },
        },
      )
      .subscribe(
        ({ data }) => {
          // TODO: Add toast
          this.dialogRef.close();
          this.router.navigate(['/artists', data.createPage.id, 'edit']);
        },
        error => {
          // TODO: Add toast
          this.loading = false;
          console.error(error);
        },
      );
  }
}
