import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit {
  @Output() fileChange$: EventEmitter<File[]> = new EventEmitter();
  @Input() title: string = $localize`Choose file`;
  @Input() requiredFileType: string;

  public constructor() {}

  public ngOnInit(): void {}

  public onFileInputChange(fileList: FileList): void {
    this.fileChange$.emit(Array.from(fileList));
  }
}
