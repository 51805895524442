import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import {
  AcceptBookingDatesGQL,
  Booking,
  BookingDateInput,
  BookingDates,
  BookingInput,
  BookingStatusEnum,
  CancelBookingDatesGQL,
  CancelBookingGQL,
  CancelReason,
  Currency,
  DeclineBookingGQL,
  EquipmentStatus,
  PageOwnership,
  SupplyTypes,
  UpdateBookingGQL,
  User,
} from '../../../generated/graphql';
import {
  amIBooker,
  canISignBooking,
  getStatus,
  haveISignedBooking,
  havePartnerSignedBooking,
  isBookingOpen,
  isBookingSignedByBoth,
} from '../../bookings/bookingUtils';
import { EditSuppliesComponent } from '../../bookings/components/edit-supplies/edit-supplies.component';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../confirm-dialog/confirm-dialog.component';
import { apiToDate } from '../../helpers';
import { dateToApi, timestampToTimeString, timeStringToTimestamp } from '../../helpers/dates';
import { getGoogleMapsUrlFromPlaceId } from '../../helpers/places';
import { IconsRegistryService, IconSubsets } from '../../services/icons-registry.service';
import { TranslationsService } from '../../services/translations.service';
import { BookingSignComponent } from '../booking-sign/booking-sign.component';
import { BookingTravelAllowanceModalComponent } from './components/booking-travel-allowance-modal/booking-travel-allowance-modal.component';
export interface DateForTemplate {
  weekDay: string;
  date: number;
  month: string;
}

export enum HeaderBookingStatus {
  Open = 'OPEN',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
}
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent implements OnInit, OnChanges {
  @Output() onDialogClose: EventEmitter<any> = new EventEmitter<any>();

  @Input() currentUser: User;
  @Input() booking: Booking;
  @Input() loading: boolean;

  @ViewChild('tabs') tabs: MatTabGroup;

  public NO_DESCRIPTION_TEXT = $localize`No description`;
  public HEADER_BOOKING_STATUS = HeaderBookingStatus;
  public PAGE_OWNERSHIP_TYPE = PageOwnership;
  public EQUIPMENT_STATUS = EquipmentStatus;

  public isBookingCompleted: boolean = false;
  public cancelPolicyOpen: boolean = false;
  public dateEditMode: boolean = false;

  public locationGoogleMapUrl: string = '';
  public dateToEdit: BookingDates;

  public showFullDescriptionText: boolean = false;
  public showDescriptionButton: boolean = false;
  public descriptionText: string = '';

  public dateToCancel: BookingDates;

  get userName(): string {
    const formattedLastName = this.booking.user?.lastName.charAt(0) + '.';
    return formattedLastName + ' ' + this.booking.user?.firstName;
  }

  get headerBookingStatus(): HeaderBookingStatus {
    if (this.isBookingOpen) return HeaderBookingStatus.Open;
    if (getStatus(this.booking, this.currentUser.id) === 'expired') return HeaderBookingStatus.Expired;
    if (this.booking.status === BookingStatusEnum.Declined) return HeaderBookingStatus.Declined;
    if (this.booking.status === BookingStatusEnum.Cancelled) return HeaderBookingStatus.Cancelled;
    if (isBookingSignedByBoth(this.booking)) return HeaderBookingStatus.Completed;
  }

  get canIChangeDates(): boolean {
    return (
      getStatus(this.booking, this.currentUser.id) !== 'cancelled' &&
      getStatus(this.booking, this.currentUser.id) !== 'expired' &&
      getStatus(this.booking, this.currentUser.id) !== 'review' &&
      getStatus(this.booking, this.currentUser.id) !== 'done'
    );
  }

  get isBookingOpen(): boolean {
    return isBookingOpen(this.booking, this.currentUser);
  }

  get amIBooker(): boolean {
    return amIBooker(this.booking, this.currentUser.id);
  }

  get showSign(): boolean {
    return !(
      haveISignedBooking(this.booking, this.currentUser.id) ||
      getStatus(this.booking, this.currentUser.id) === 'cancelled' ||
      getStatus(this.booking, this.currentUser.id) === 'completed'
    );
  }

  get isBookingCancelled(): boolean {
    return getStatus(this.booking, this.currentUser.id) === 'cancelled';
  }

  get canISignBooking(): boolean {
    // Booker needs to have set billing info as well
    if (amIBooker(this.booking, this.currentUser.id)) {
      return canISignBooking(this.booking, this.currentUser.id) && !!this.booking.billingInfo;
    }
    return canISignBooking(this.booking, this.currentUser.id);
  }

  get isBookingSignedByBoth(): boolean {
    return isBookingSignedByBoth(this.booking);
  }

  get relevantDates(): BookingDates[] {
    if (this.booking.dates.filter((d) => d.isAccepted).length > 0) {
      return this.booking.dates.filter((d) => d.isAccepted);
    } else {
      return this.booking.dates;
    }
  }

  get haveISignedBooking(): boolean {
    return haveISignedBooking(this.booking, this.currentUser.id);
  }

  get canIEditBooking(): boolean {
    return (
      isBookingOpen(this.booking, this.currentUser) &&
      !(
        haveISignedBooking(this.booking, this.currentUser.id) &&
        havePartnerSignedBooking(this.booking, this.currentUser.id)
      )
    );
  }

  constructor(
    private acceptBookingDatesGQL: AcceptBookingDatesGQL,
    private cancelBookingDatesGQL: CancelBookingDatesGQL,
    private declineBookingMutation: DeclineBookingGQL,
    private cancelBookingMutation: CancelBookingGQL,
    private updateBooking: UpdateBookingGQL,

    private translations: TranslationsService,
    private iconService: IconsRegistryService,
    private snackbar: MatSnackBar,
    private modal: MatDialog,
  ) {
    this.iconService.registerIcons([IconSubsets.NAVIGATION, IconSubsets.ACTIONS, IconSubsets.BOOKING]);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.sortedDates = this.booking.dates.sort((a, b) => {
      const dateA = apiToDate(a.date);
      const dateB = apiToDate(b.date);

      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });
    console.log(this.canIEditBooking);
  }

  public getSetTime(startTime: string, endTime: string): string {
    const startDate = apiToDate(startTime);
    const endDate = apiToDate(endTime);

    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);

    let result = '';
    if (hours > 0) {
      result += `${hours} h`;
    }
    if (minutes > 0) {
      if (result.length > 0) {
        result += ' ';
      }
      result += `${minutes} min`;
    }
    return result;
  }

  public getStartTime(date: string): string {
    return timestampToTimeString(date);
  }

  public declineDate(date: BookingDates): void {
    const dialogText = $localize`Are you sure you want to decline the date`;
    const modalRef = this.modal.open(ConfirmDialogComponent, {
      width: '328px',
      height: '180px',
      data: {
        title: $localize`Delete`,
        body: `${dialogText} ${date.date.toLocaleString()}?`,
        okText: $localize`Delete`,
        cancelText: $localize`Cancel`,
      } as ConfirmDialogData,
    });

    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        console.log('decline date');
      }
    });
  }

  public acceptBookingDate(date: BookingDates, decline?: BookingDates): void {
    let acceptedDates = this.booking.dates.filter((date) => date.isAccepted);
    const index = acceptedDates.findIndex((acceptedDate) => acceptedDate.id === date.id);

    if (index !== -1) {
      acceptedDates.splice(index, 1);
    }

    const acceptedDatesIds = acceptedDates.map((date) => date.id);
    const declines = decline ? [date.id] : [];

    this.acceptBookingDatesGQL
      .mutate({
        accepts: [...acceptedDatesIds, date.id],
        declines: [...declines],
      })
      .subscribe(
        ({ data }) => {
          // this.booking = data.acceptBookingDates;
        },
        () => this.snackbar.open($localize`Could not save changes. Try again`, undefined, { duration: 3000 }),
      );
  }

  public removeBookingDateStatus(date: BookingDates): void {
    if (date.isAccepted) {
      this.acceptBookingDate(date, date);
    } else if (date.cancelledDate !== null) {
      this.cancelBookingDate(date, false);
    }
  }

  public cancelBookingDate(date: BookingDates, isCancelling: boolean): void {
    if (this.isBookingSignedByBoth) {
      this.dateToCancel = date;
      this.cancelPolicyOpen = !this.cancelPolicyOpen;
    } else {
      this.cancelBookingDatesGQL
        .mutate({
          dateIds: [date.id],
          isCancelling: isCancelling,
        })
        .subscribe((res) => {
          console.log(res);
        });
    }
  }

  sortedDates: BookingDates[] = [];
  public ngOnInit(): void {
    console.log(this.booking);
    this.setGoogleMapsUrl();

    this.isBookingCompleted =
      this.booking.status === BookingStatusEnum.Signed &&
      parseInt(this.booking.dates.sort((a, b) => parseInt(a.date) - parseInt(b.date))[0].date) < Date.now();

    if (this.booking?.message.length > 80) {
      this.showDescriptionButton = true;
      this.showFullDescriptionText = false;
      this.descriptionText = this.booking?.message.substring(0, 80) + '...';
    } else {
      this.showDescriptionButton = false;
      this.showFullDescriptionText = true;
      this.descriptionText = this.booking?.message;
    }
  }

  private setGoogleMapsUrl(): void {
    this.locationGoogleMapUrl = getGoogleMapsUrlFromPlaceId(this.booking.place.placeId);
  }

  public getDateTemplate(dateString: string): DateForTemplate {
    const dateObj = apiToDate(dateString);
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const weekDay = weekDays[dateObj.getDay()];
    const date = dateObj.getDate();
    const month = months[dateObj.getMonth()];

    return { weekDay, date, month };
  }

  public openContract(): void {
    this.modal.open(BookingSignComponent, {
      autoFocus: false,
      panelClass: 'contract-modal',
    });
  }

  public closeDialog(): void {
    this.onDialogClose.next();
  }

  public deleteBookingDate(date: BookingDates): void {
    if (!this.isBookingSignedByBoth) {
      const dialogText = $localize`Are you sure you want to delete the date`;
      const modalRef = this.modal.open(ConfirmDialogComponent, {
        width: '328px',
        height: '180px',
        data: {
          title: $localize`Delete`,
          body: `${dialogText} ${apiToDate(date.date).toLocaleDateString(this.translations.getCurrentLocale())}?`,
          okText: $localize`Delete`,
          cancelText: $localize`Cancel`,
        } as ConfirmDialogData,
      });

      modalRef.afterClosed().subscribe((res) => {
        if (res) {
          this.booking.dates = this.booking.dates.filter((d) => d.id !== date.id);
          this.saveChanges();
        }
      });
    } else {
      this.dateToCancel = date;
      this.cancelPolicyOpen = !this.cancelPolicyOpen;
    }
  }

  public openDateEditMode(date?: BookingDates): void {
    this.dateEditMode = true;

    this.dateToEdit = date ? date : null;
  }

  public saveChanges(): void {
    this.updateBooking
      .mutate({
        bookingId: this.booking.id,
        booking: {
          dates: this.booking.dates.map((date) => this.generateDateInput(date)),
        },
      })
      .subscribe(
        () => this.snackbar.open($localize`Succesfully saved changes!`, undefined, { duration: 3000 }),
        () => this.snackbar.open($localize`Could not save changes`, $localize`Try again`, { duration: 3000 }),
      );
  }

  private generateDateInput(date): BookingDateInput {
    return {
      id: date.id || undefined,
      date: dateToApi(apiToDate(date.date)),
      price: {
        value: parseInt(date.price),
        currency: this.booking.dates.find((d) => d.id === date.id)?.priceCurrency || Currency.Sek,
      },
      startTime: timeStringToTimestamp(apiToDate(date.date), date.startTime),
      endTime: timeStringToTimestamp(apiToDate(date.date), date.endTime),
    };
  }

  public openEditSupplies(): void {
    const ref = this.modal.open(EditSuppliesComponent, { data: { booking: this.booking }, minWidth: '400px' });
    ref.afterClosed().subscribe((d) => {
      if (d.supplies) {
        this.updateSupplies(d.supplies, d.supplyStatus);
      }
    });
  }

  public updateSupplies(supplies: SupplyTypes[], supplyStatus?: EquipmentStatus): void {
    this.updateBooking
      .mutate({
        bookingId: this.booking.id,
        booking: {
          supplies,
          supplyStatus,
        },
      })
      .subscribe();
  }

  public cancelBooking(): void {
    const declineText = $localize`Are you sure you want to decline request?`;
    const cancelText = $localize`Are you sure you want to cancel request?`;

    this.modal
      .open(ConfirmDialogComponent, {
        width: '328px',
        height: '180px',
        data: {
          title: this.amIBooker ? $localize`Cancel request` : $localize`Decline request`,
          body: this.amIBooker ? `${cancelText}` : `${declineText}`,
          okText: $localize`Confirm`,
          cancelText: $localize`Cancel`,
        } as ConfirmDialogData,
      })
      .afterClosed()
      .subscribe((confirmation) => {
        if (confirmation) {
          if (this.amIBooker) {
            this.cancelBookingMutation
              .mutate({ bookingId: this.booking.id, cancelReason: CancelReason.NoChoice })
              .subscribe(
                () =>
                  this.snackbar.open($localize`The request was canceled successfully!`, undefined, { duration: 3000 }),
                () => this.snackbar.open($localize`Could not save changes`, $localize`Try again`, { duration: 3000 }),
              );
          } else {
            this.declineBookingMutation
              .mutate({ bookingId: this.booking.id, cancelReason: CancelReason.NoChoice })
              .subscribe(
                () =>
                  this.snackbar.open($localize`The request was declined successfully!`, undefined, { duration: 3000 }),
                () => this.snackbar.open($localize`Could not save changes`, $localize`Try again`, { duration: 3000 }),
              );
          }
        }
      });
  }

  public openTravelAllowanceModal(): void {
    this.modal.open(BookingTravelAllowanceModalComponent, {
      data: {
        booking: this.booking,
      },
      minWidth: '505px',
      panelClass: 'modal-no-padding',
    });
  }

  public toggleDescriptionText() {
    this.showFullDescriptionText = !this.showFullDescriptionText;
    if (this.showFullDescriptionText) {
      this.descriptionText = this.booking?.message;
    } else {
      this.descriptionText = this.booking?.message.substring(0, 80) + '...';
    }
  }

  isLoadingBilling = false;

  public onSelectBillingInfo(id: number): void {
    const bookingInput: BookingInput = { billingId: id };
    this.isLoadingBilling = true;

    this.updateBooking
      .mutate({
        bookingId: this.booking.id,
        booking: bookingInput,
      })
      .subscribe(
        () => {
          this.snackbar.open($localize`Succesfully saved changes!`, undefined, { duration: 3000 });
          this.isLoadingBilling = false;
        },
        (error) => {
          this.snackbar.open($localize`Could not save changes`, $localize`Try again`, { duration: 3000 });
          this.isLoadingBilling = false;
          console.error(error);
        },
      );
  }
}
