<div fxHide.lt-sm class="container">
  <div fxFlex fxLayout fxLayoutAlign="start center" class="filters-toolbar">
    <div class="m-r--16">
      <div fxLayout fxLayoutGap="16px" class="artist-list-filter-type">
        <label class="category-checkbox-label" [ngClass]="{ active: liveSearch }">
          <mat-checkbox
            disableRipple
            color="primary"
            [name]="pageCategory.Live"
            class="category-checkbox-input"
            [(ngModel)]="liveSearch"
            (change)="doSearch()"></mat-checkbox
          >Live
        </label>
        <label class="category-checkbox-label" [ngClass]="{ active: djSearch }">
          <mat-checkbox
            disableRipple
            color="primary"
            [name]="pageCategory.Dj"
            class="category-checkbox-input"
            [(ngModel)]="djSearch"
            (change)="doSearch()"></mat-checkbox
          >DJ
        </label>
      </div>
    </div>

    <span class="separator m-r--16"></span>

    <div fxLayoutAlign="start center" fxLayoutGap="16px" class="filter-overlays-buttons">
      <button
        fxLayoutAlign="start center"
        cdkOverlayOrigin
        #locationOverlay="cdkOverlayOrigin"
        (click)="isLocationFilterOpen = !isLocationFilterOpen"
        class="gig-button stroked">
        <ng-container i18n>Location</ng-container>&nbsp;
        <span fxLayoutAlign="center center" *ngIf="locationActiveFilters > 0" class="active-number">{{
          locationActiveFilters
        }}</span>
        <mat-icon inline class="expand-icon">expand_more</mat-icon>
      </button>

      <button
        fxLayoutAlign="start center"
        cdkOverlayOrigin
        #performerOverlay="cdkOverlayOrigin"
        (click)="isPerformerFilterOpen = !isPerformerFilterOpen"
        class="gig-button stroked"
        [ngClass]="performerActiveTags > 0 ? 'active' : ''">
        <ng-container i18n>Type of perfomance</ng-container>&nbsp;
        <span fxLayoutAlign="center center" *ngIf="performerActiveTags > 0" class="active-number">{{
          performerActiveTags
        }}</span>
        <mat-icon inline class="expand-icon">expand_more</mat-icon>
      </button>

      <button
        fxLayoutAlign="start center"
        cdkOverlayOrigin
        #musicTypeOverlay="cdkOverlayOrigin"
        (click)="isMusicTypeFilterOpen = !isMusicTypeFilterOpen"
        class="gig-button stroked"
        [ngClass]="musicActiveTags > 0 ? 'active' : ''">
        <ng-container i18n>Genres & instruments</ng-container>&nbsp;
        <span fxLayoutAlign="center center" *ngIf="musicActiveTags > 0" class="active-number">{{
          musicActiveTags
        }}</span>
        <mat-icon inline class="expand-icon">expand_more</mat-icon>
      </button>
    </div>

    <div fxLayoutAlign="start center" fxLayoutGap="8px">
      <app-checkbox-toggle-slider
        [(ngModel)]="includesEquipment"
        (ngModelChange)="doSearch()"></app-checkbox-toggle-slider>
      <span class="f-weight--600 f-size--14" i18n>With equipment</span>
    </div>

    <div class="m-left--auto">
      <app-search-input
        #searchTermDesktop
        fxFlex="180px"
        [(ngModel)]="searchTermValue"
        placeholder="Search for artist"
        (ngModelChange)="searchTermValue$.next($event)"
        (onSearchClick$)="searchTermValue$.next($event)"
        i18n-placeholder></app-search-input>
    </div>
  </div>
</div>

<div fxHide fxShow.lt-sm class="container">
  <div fxLayout="column" fxLayoutGap="16px" class="filters-toolbar mobile">
    <app-search-input
      #searchTermDesktop
      [(ngModel)]="searchTermValue"
      placeholder="Search for artist"
      (ngModelChange)="searchTermValue$.next($event)"
      (onSearchClick$)="searchTermValue$.next($event)"
      i18n-placeholder></app-search-input>
    <div fxLayoutGap="16px">
      <button
        fxFlex
        fxLayoutAlign="center center"
        fxLayoutGap="5px"
        cdkOverlayOrigin
        #mobileFilterOverlay="cdkOverlayOrigin"
        (click)="openMobileFilterModal()"
        class="gig-button stroked">
        <mat-icon svgIcon="filter" class="filter-icon"></mat-icon>
        <span fxLayoutAlign="start center" fxLayoutGap="4px">
          <span i18n>Filters</span>&nbsp;
          <span fxLayoutAlign="center center" *ngIf="activeFiltersMobile > 0" class="active-number">{{
            activeFiltersMobile
          }}</span>
        </span>
      </button>
    </div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOpen]="isLocationFilterOpen"
  [cdkConnectedOverlayOrigin]="locationOverlay"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayWidth]="'593px'"
  (backdropClick)="closeFilterOverlay()">
  <app-artist-list-filter-menu-item
    filterTitle="Location"
    [filterOptions]="locationFilterData"
    (doSearch$)="doSearch()"
    (closeFilter$)="isLocationFilterOpen = !isLocationFilterOpen"
    i18n-filterTitle>
  </app-artist-list-filter-menu-item>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOpen]="isPerformerFilterOpen"
  [cdkConnectedOverlayOrigin]="performerOverlay"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayWidth]="'625px'"
  (backdropClick)="closeFilterOverlay()">
  <app-artist-list-filter-menu-item
    filterTitle="Type of performer"
    (doSearch$)="doSearch()"
    [filterOptions]="performerFilterData"
    (closeFilter$)="isPerformerFilterOpen = !isPerformerFilterOpen"
    i18n-filterTitle>
  </app-artist-list-filter-menu-item>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOpen]="isMusicTypeFilterOpen"
  [cdkConnectedOverlayOrigin]="musicTypeOverlay"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayWidth]="'625px'"
  (backdropClick)="closeFilterOverlay()">
  <app-artist-list-filter-menu-item
    filterTitle="Genres & Instruments"
    (doSearch$)="doSearch()"
    [filterOptions]="musicTypeFilterData"
    (closeFilter$)="isMusicTypeFilterOpen = !isMusicTypeFilterOpen"
    i18n-filterTitle>
  </app-artist-list-filter-menu-item>
</ng-template>
