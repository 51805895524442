import { Component, OnInit } from '@angular/core';
import { Intercom } from 'ng-intercom';
import { GetContentfulEntryGQL } from '../../../generated/graphql';
import { richTextToHtml } from '../../helpers';
import { MetaService } from '../../services/meta.service';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
  constructor(
    private intercom: Intercom,
    private meta: MetaService,
    private getContentfulEntry: GetContentfulEntryGQL,
    private translations: TranslationsService,
  ) {}

  isLoading = true;
  hero;
  userCases;
  testimonials;
  customers;
  expanded: number;

  ngOnInit() {
    this.getContentfulEntry
      .fetch({ id: '2OzXeEk7Sr53D8kK0PcYCd', locale: this.translations.getCurrentLocale() })
      .subscribe(result => {
        this.hero = result.data.getContentfulEntry.contentfulData.fields.blocks.find(
          b => b.sys.id === '4rfUnycA76bOMgl9iBsXJU',
        );
        this.userCases = result.data.getContentfulEntry.contentfulData.fields.blocks.find(
          b => b.sys.id === '4adhgHyy58hdXPR81AtlAj',
        );
        this.testimonials = result.data.getContentfulEntry.contentfulData.fields.blocks.find(
          b => b.sys.id === '2ABhk1uPnzo0GP8OMe1XzH',
        );
        this.customers = result.data.getContentfulEntry.contentfulData.fields.blocks.find(
          b => b.sys.id === '485gFjYyDqG5devsG1Aqv2',
        );

        this.isLoading = false;
      });

    this.meta.setLocalizedTitle({
      en: 'Inspiration for you event | Customer cases | Gigital',
      'sv-se': 'Inspiration till ditt event | Kundcases | Gigital',
    });

    this.meta.setLocalizedDescription({
      en: `Get inspired by our customers' events. 200 verified artists, DJ's and bands. Find and book your favorite. Free registration. Safe bookings.`,
      'sv-se':
        'Inspireras av hur våra kunder skapar event. Hitta och boka över 200 artister, band och DJs till ditt event. Gratis registrering - trygg och säker bokning.',
    });
  }

  openContact(): void {
    this.intercom.showNewMessage();
  }

  richTextToHtml(text) {
    return richTextToHtml(text);
  }
}
