import { BehaviorSubject } from 'rxjs';

export class Pool<T> {
  private _items: T[] = [];
  private poolLength = 5;

  get items() {
    return this._items;
  }

  poolChanges: BehaviorSubject<T[]> = new BehaviorSubject(this._items);

  /**
   * Creates a pool of type T.
   * Will add items up to the max length, after which it will start removing the oldest item.
   * @param length Length of the pool
   * @param fillItem Optional item to fill the pool with
   */
  constructor(length: number, fillItem?: T) {
    this.poolLength = length;
    if (fillItem) {
      this.fill(fillItem);
      this.poolChanges.next(this._items);
    }
  }

  /**
   * Fills the pool
   * @param item Object to fill the pool with
   */
  fill(item: T): T[] {
    this._items.fill(item, 0, this.poolLength);
    this.poolChanges.next(this._items);
    return this._items;
  }

  /**
   * Adds a new item to the pool.
   * If the new length is greater than the max allowed length, the oldest item will be removed
   * @param item
   */
  push(item: T): T[] {
    if (this._items.length + 1 > this.poolLength) {
      this._items.pop();
    }
    this._items.unshift(item);
    this.poolChanges.next(this._items);
    return this._items;
  }

  /**
   * Returns the last item and removes it from the pool
   */
  pop(): T {
    const pop = this._items.pop();
    this.poolChanges.next(this._items);
    return pop;
  }

  /**
   * Returns item at index
   * @param index
   */
  getItemAt(index: number): T {
    return this._items[index];
  }

  // TODO: Rename lol
  /**
   * Run a lambda function on all elements in the pool
   * @param lambda Function to run
   */
  runLambda(lambda: (item: T) => T[]) {
    for (const item of this._items) {
      lambda(item);
    }
    this.poolChanges.next(this._items);
    return this._items;
  }
}
