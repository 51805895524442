<h1 class="h5 gray-1 bg-white gig-booking-header" matDialogTitle i18n>Send booking requests</h1>
<mat-dialog-content>
  <main class="bg-gray-5 gig-booking" *ngIf="dates" fxLayout="column" fxLayoutGap="16px">
    <h2 class="h6 gray-1" i18n>Your selected artists</h2>
    <div
      *ngFor="let date of dates | keyvalue"
      class="mat-elevation-z1 bg-white date"
      fxLayout="column"
      fxLayoutGap="16px">
      <h3 class="mat-h3 gray-1">{{ date.key | date: dateFormat }}</h3>
      <div *ngFor="let application of date.value" fxLayout fxLayoutGap="48px" fxLayoutAlign="space-between center">
        <div fxLayout fxLayoutGap="16px" fxLayoutAlign="start center">
          <app-avatar [profilePicture]="application.page.profilePicture"></app-avatar>
          <h4 class="mat-body bright-red">{{ application.page.name }}</h4>
        </div>
        <div class="offered-price">
          <label class="small-label gray-2" i18n>Offered price</label>
          <p class="mat-body-2 gray-1"
            >{{ application.slot.price }} {{ application.slot.priceCurrency.toUpperCase() }}
          </p>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" class="actions">
      <button mat-flat-button color="primary" (click)="submit()" i18n [disabled]="loading"
        >Send booking requests</button
      >
      <button mat-flat-button (click)="cancel()" i18n="@@cancel" [disabled]="loading">Cancel</button>
    </div>
    <!-- TODO: Add info about nonbinding request here -->
  </main>
</mat-dialog-content>
