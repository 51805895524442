import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CountriesEnum, UpdateUserGQL, User, UserType } from '../../../generated/graphql';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-missing-user-info',
  templateUrl: './missing-user-info.component.html',
  styleUrls: ['./missing-user-info.component.scss'],
})
export class MissingUserInfoComponent implements OnInit {
  public userFormGroup: FormGroup;
  public loading = false;

  public COUNTRIES_ENUM = CountriesEnum;
  public USER_TYPES = UserType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    private dialogRef: MatDialogRef<MissingUserInfoComponent>,
    private analytics: AnalyticsService,
    private updateUser: UpdateUserGQL,
    private snackbar: MatSnackBar,
    private router: Router,
  ) {}

  ngOnInit() {
    this.initForm();
    this.setFormValues();
    this.userFormGroup.updateValueAndValidity();
  }

  public initForm(): void {
    this.userFormGroup = new FormGroup({
      firstName: new FormControl('', []),
      lastName: new FormControl('', []),
      email: new FormControl('', []),
      receiveEmails: new FormControl(true, []),
      lang: new FormControl(CountriesEnum.Sweden, []),
      type: new FormControl(null, []),
      companyName: new FormControl(''),
      phonePrefix: new FormControl(this.data.user?.phonePrefix || '+46'),
      phoneNumber: new FormControl(''),
    });
  }

  public setFormValues(): void {
    this.userFormGroup.get('firstName').setValue(this.data.user.firstName);
    this.userFormGroup.get('lastName').setValue(this.data.user.lastName);
    this.userFormGroup.get('email').setValue(this.data.user.email);
    this.userFormGroup.get('receiveEmails').setValue(this.data.user.receiveTransactional);
    this.userFormGroup.get('lang').setValue(this.data.user.lang);
    this.userFormGroup.get('type').setValue(this.data.user.type || UserType.Booker);

    if (this.getIsMissing('firstName')) this.userFormGroup.get('firstName').setValidators([Validators.required]);
    if (this.getIsMissing('lastName')) this.userFormGroup.get('lastName').setValidators([Validators.required]);
    if (this.getIsMissing('lastName')) this.userFormGroup.get('lastName').setValidators([Validators.required]);
    if (this.getIsMissing('email'))
      this.userFormGroup.get('email').setValidators([Validators.required, Validators.email]);
    if (this.getIsMissing('receiveTransactional')) {
      this.userFormGroup.get('receiveEmails').setValidators([Validators.required]);
    }
    if (this.getIsMissing('lang')) this.userFormGroup.get('lang').setValidators([Validators.required]);
    if (this.getIsMissing('phoneNumber') && this.data.user.type === UserType.Artist) {
      this.userFormGroup.get('phoneNumber').setValidators([Validators.required]);
      this.userFormGroup.get('phonePrefix').setValidators([Validators.required]);
    }
  }

  public getIsMissing(type: string): boolean {
    return !!this.data.user.missingData.find((t) => t === type);
  }

  public updateMissing(): void {
    if (!this.userFormGroup.valid) return;

    this.loading = true;

    this.updateUser
      .mutate({
        editUser: {
          email: !this.data.user.isSocial ? this.userFormGroup.get('email').value : undefined,
          firstName: this.userFormGroup.get('firstName').value,
          lang: this.userFormGroup.get('lang').value,
          lastName: this.userFormGroup.get('lastName').value,
          receiveTransactional: this.userFormGroup.get('receiveEmails').value,
          type: this.userFormGroup.get('type').value,
          companyName: this.userFormGroup.get('companyName').value,
          phonePrefix: this.userFormGroup.get('phonePrefix').value,
          phoneNumber: this.userFormGroup.get('phoneNumber').value,
        },
      })
      .subscribe(
        () => {
          this.dialogRef.close(true);
          this.loading = false;

          this.snackbar.open($localize`Your data has been successfully updated!`, undefined, { duration: 3000 });

          if (!!this.userFormGroup.get('type').value && this.userFormGroup.get('type').value === UserType.Artist) {
            this.router.navigate(['/user/artists']);
            this.analytics.emitEvent('create_account_complete_artist', { type: 'artist' }, { value: 100 });
          } else {
            const coName = this.userFormGroup.get('companyName').value;
            if (!!coName) {
              this.analytics.emitEvent(
                'create_account_complete_booker_company',
                {
                  type: 'booker',
                  companyName: coName,
                },
                { value: 4000 },
              );
            } else {
              this.analytics.emitEvent('create_account_complete_booker_private', { type: 'booker' }, { value: 1000 });
            }
          }
        },
        (error) => {
          this.snackbar.open($localize`Could not save changes.`, $localize`Try again`, { duration: 3000 });
          console.error(error);
        },
      );
  }
}
