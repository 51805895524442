<aside class="artist-list-filter-side">
  <div class="artist-list-filter-query">
    <mat-form-field floatLabel="never">
      <input
        class="mat-body-2 gray-2"
        matInput
        id="searchTermMobile"
        placeholder="Search"
        #searchTermMobile
        [(ngModel)]="searchTermValue"
      />
      <button mat-button mat-icon-button matSuffix *ngIf="searchTermValue" (click)="searchTermValue = ''; doSearch()">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="artist-list-filter-type" fxLayout="column">
    <mat-checkbox
      *ngFor="let type of artistType"
      disableRipple
      color="primary"
      [name]="type.name"
      class="gray-2 mat-body-1"
      [(ngModel)]="type.active"
      (change)="doSearch()"
      >{{ type.name }}</mat-checkbox
    >
  </div>
  <div class="artist-list-filter-sorting">
    <button mat-button [matMenuTriggerFor]="sortingMenu"
      ><span i18n>Sorting:</span> {{ sortingOptions[activeSortingIndex].name }}&nbsp;<mat-icon inline
        >expand_more</mat-icon
      ></button
    >
  </div>
  <div fxLayout="column">
    <h3 class="mat-h2 gray-1" i18n="@@genre">Genre</h3>
    <div fxLayout="column wrap" class="artist-list-filter-column">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        *ngFor="let genre of genres"
        disableRipple
        color="primary"
        [name]="genre.name"
        class="gray-2 mat-body"
        [(ngModel)]="genre.active"
        (change)="doSearch()"
        >{{ genre.name }}</mat-checkbox
      >
    </div>
  </div>
  <div fxLayout="column">
    <h3 class="mat-h2 gray-1" i18n="@@city">City</h3>
    <div fxLayout="column wrap" class="artist-list-filter-column">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        *ngFor="let location of locations"
        disableRipple
        color="primary"
        [name]="location.name"
        class="gray-2 mat-body"
        [(ngModel)]="location.active"
        (change)="doSearch()"
        >{{ location.name }}</mat-checkbox
      >
    </div>
  </div>
</aside>

<mat-menu #sortingMenu="matMenu" class="artist-list-filter-menu-container">
  <button
    mat-menu-item
    *ngFor="let option of sortingOptions; let i = index"
    (click)="activeSortingIndex = i; doSearch()"
    >{{ option.name }}</button
  >
</mat-menu>
