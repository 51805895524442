import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ExistingPlace,
  GetCurrentUserGQL,
  Gig,
  GigType,
  PlaceEntry,
  Tag,
  TagEntry,
  TypeEntry,
  UserType,
} from '../../../../../generated/graphql';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss'],
})
export class ListHeaderComponent implements OnInit, OnDestroy {
  @Input()
  gigs: Gig[];

  @Input()
  availableLocations: Observable<PlaceEntry[]>;

  @Input()
  availableTags: Observable<TagEntry[]>;

  @Input()
  availableTypes: Observable<TypeEntry[]>;

  @Output()
  filterChanges = new EventEmitter<{ locations: ExistingPlace[]; tags: Tag[]; types: GigType[]; sorting: string }>();

  @Output()
  openFilter = new EventEmitter();

  selectedLocations: ExistingPlace[] = [];
  selectedTags: Tag[] = [];
  selectedTypes: GigType[] = [];
  selectedSort = 'RELEVANCY:DESC';

  isBooker: boolean = true;
  isBookerSub: Subscription;

  constructor(private iconsService: IconsRegistryService, private user: GetCurrentUserGQL) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  ngOnInit(): void {
    const query = this.user.watch().valueChanges.pipe(
      tap((d) => {
        const user = d.data.me;
        if (user) {
          this.isBooker = user.type === UserType.Booker;
          if (this.isBooker && user.hasSearchablePage) {
            this.isBooker = false;
          }
        }
      }),
    );

    // why can't we call you with | async pipe???
    this.isBookerSub = query.subscribe((u) => {});
  }

  ngOnDestroy(): void {
    this.isBookerSub.unsubscribe();
  }

  onTagsChanges(ev: Tag[]) {
    this.selectedTags = ev;
    this.emitFilterChange();
  }
  onLocationsChanges(ev: ExistingPlace[]) {
    this.selectedLocations = ev;
    this.emitFilterChange();
  }

  onTypesChanges(ev: GigType[]) {
    this.selectedTypes = ev;
    this.emitFilterChange();
  }

  onSortChanges(ev: string) {
    this.selectedSort = ev;
    this.emitFilterChange();
  }

  getSelectedPlacesTitle() {
    if (this.selectedLocations.length === 0) {
      return $localize`Locations: All`;
    } else {
      return this.selectedLocations.map((s) => s.longName).join(', ');
    }
  }

  emitFilterChange() {
    this.filterChanges.emit({
      locations: this.selectedLocations,
      tags: this.selectedTags,
      types: this.selectedTypes,
      sorting: this.selectedSort,
    });
  }
}
