<div fxHide.lt-sm *ngIf="gallery?.length > 0" fxLayout="row wrap" class="media-items-list">
  <div
    *ngFor="let image of gallery"
    igxDrag
    igxDrop
    #dragRef
    fxFlex="262px"
    fxFlex.lt-sm="366px"
    id="{{ image.id }}"
    (dropped)="onImageDropped($event)"
    (dragStart)="dragStartHandler(image.id)"
    (ghostCreate)="ghostCreateHandler(dragRef)"
    (dragEnd)="dragEndHandler(dragRef)"
    (enter)="onEnterHandler($event)"
    [ghostClass]="'drag_ghost_class'"
    class="media-item-container">
    <div fxLayout="column" class="media-item">
      <div fxLayoutAlign="space-between center" class="media-item--top">
        <span fxLayoutGap="5px" fxLayoutAlign="start center">
          <mat-icon class="media-item--drag-icon">reorder</mat-icon>
          <span i18n>Drag to reorder</span>
        </span>
        <span *ngIf="image?.order === 0" i18n>Primary</span>
      </div>
      <div class="image" [style.background-image]="getImageURL(image)"> </div>
      <div class="media-item--bottom" fxLayoutAlign="flex-end">
        <button class="gig-button transparent" igxDragIgnore (click)="deleteImage(image.id)" i18n>Delete</button>
      </div>
    </div>
  </div>
</div>

<div fxHide fxShow.lt-sm>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="24px"
    cdkDropList
    (cdkDropListDropped)="dropImageOnMobile($event)">
    <div fxFlex="235px" *ngFor="let image of gallery" cdkDrag>
      <div fxLayout="column" class="media-item">
        <div fxLayoutAlign="space-between center" class="media-item--top">
          <span fxLayoutGap="5px" fxLayoutAlign="start center">
            <mat-icon class="media-item--drag-icon">reorder</mat-icon>
            <span i18n>Drag to reorder</span>
          </span>
          <span *ngIf="image?.order === 0" i18n>Primary</span>
        </div>
        <div class="image" [style.background-image]="getImageURL(image)"> </div>
        <div class="media-item--bottom" fxLayoutAlign="flex-end">
          <button class="gig-button transparent" igxDragIgnore (click)="deleteImage(image.id)" i18n>Delete</button>
        </div>
      </div>
    </div>
  </div>
</div>
