<section>
  <h3 *ngIf="!isEditMode" class="m-b--36" i18n>Add new billing info</h3>
  <h3 *ngIf="isEditMode" class="m-b--36" i18n>Edit billing info</h3>

  <form fxLayout="column" [formGroup]="billingDetailsForm" (submit)="saveChanges()">
    <div class="m-b--32" fxLayout="column" fxLayoutGap="12px">
      <div class="warning-box" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon class="yellow-icon" svgIcon="info-fill"></mat-icon>
        <span i18n>Your invoice information must comply with your invoice practices.</span>
      </div>
      <app-input
        placeholder="Anderssons AB or Anders Andersson"
        label="Company or your name"
        i18n-label
        formControlName="coName"
        i18n-placeholder></app-input>
      <app-input
        label="Organization or social security number"
        i18n-label
        placeholder="XXXXXX-xxxx"
        formControlName="orgNumber"
        i18n-placeholder></app-input>
      <app-input
        label="Invoice address"
        i18n-label
        placeholder="Storgatan 1, 111 22 Stockholm"
        formControlName="address"
        i18n-placeholder></app-input>
      <app-input
        label="Invoice e-mail"
        i18n-label
        placeholder="invoice@company.com"
        formControlName="email"
        i18n-placeholder></app-input>
      <app-input
        label="Reference"
        labelTooltip="Optional"
        i18n-label-tooltip
        i18n-label
        placeholder="Project123"
        formControlName="reference"
        i18n-placeholder></app-input>
    </div>

    <button
      [disabled]="billingDetailsForm.invalid || loading"
      class="gig-button primary f-size--16 m-b--12"
      type="submit"
      i18n
      >Save</button
    >
    <button type="button" (click)="close()" class="gig-button stroked f-size--16" i18n>Close</button>
  </form>
</section>
