<div class="artist-list--hero">
  <app-cloud-image class="artist-list--hero-cover" [backgroundPicture]="artist.page.backgroundPicture" [cover]="true">
  </app-cloud-image>
  <div class="page-hero-video" [class.show]="showVideo">
    <youtube-player #ytVideo [videoId]="videoId" [width]="videoWidth" [height]="videoWidth / 1.77"> </youtube-player>
  </div>
  <div class="artist-list--hero__text container" fxFlex.gt-md="70">
    <span fxLayout fxLayoutAlign="start center" class="small-label white">
      <app-gigital-logo color="#ffffff" height="12"></app-gigital-logo> verified
    </span>
    <h2 class="h2 white">{{ artist.page.name }}</h2>
    <p class="white mat-body">{{ artist.page.presentation }}</p>
    <p class="white mat-body-2"
      ><strong>{{ artist.page.tags[0].name }}</strong> based in
      <strong>{{ locationTag?.name || 'the World' }}</strong>
    </p>
    <p class="white mat-body-2" i18n
      >Price starting at <strong>{{ artist.page.price }} SEK</strong></p
    >
    <div fxLayout.gt-md="row" fxLayout="column" fxLayoutGap="32px" class="artist-list--hero__actions">
      <a
        mat-stroked-button
        class="white-stroked"
        *ngIf="isLoggedIn"
        [routerLink]="['/artists', artist.page.slug, 'book']"
        i18n="@@sendBookingRequest"
        >Send booking request</a
      >

      <a *ngIf="!isLoggedIn" mat-stroked-button class="white-stroked" [routerLink]="['/login']" i18n>Contact artist</a>
      <a mat-flat-button color="primary" [routerLink]="['/artists/search']" i18n="@@seeSimilarArtists"
        >See all artists</a
      >
    </div>
  </div>
  <div class="container">
    <div class="search-box">
      <div class="artist-list-filter-query white" fxHide.lt-md>
        <mat-form-field floatLabel="never">
          <input
            class="mat-body-2 white search-bar"
            matInput
            id="searchTermDesktop"
            placeholder="Search for eg. artist name"
            i18n-placeholder
            #searchInput />
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
