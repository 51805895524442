<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" class="gig-application" fxFlex="96px">
  <div
    (click)="openApplication()"
    [matTooltip]="
      bookingId ? 'Click to see request' : selected ? 'Click to deselect application' : 'Click to select application'
    "
    matTooltipPosition="above"
    class="gig-application-meta"
    i18n-matTooltop>
    <div class="application-image-container">
      <div class="partner-pill" *ngIf="application.page.ownership === PageOwnership.Subagent"
        ><span i18n>Partner</span></div
      >
      <app-cloud-image
        class="application-image"
        [class.selected]="selected"
        [profilePicture]="application.page.profilePicture"
        [altText]="application.page.name + 's profile picture'"
        i18n-altText></app-cloud-image>
      <div fxLayout="column" fxLayoutAlign="center center" class="hover-text"><span i18n>View</span></div>
    </div>

    <p class="application-name">{{ application.page.name }}</p>
  </div>
  <div
    fxShow
    fxHide.lt-md
    (click)="selectApplication()"
    class="check-it"
    fxLayout="column"
    fxLayoutAlign="start center"
    *ngIf="!bookingId">
    <mat-icon *ngIf="!selected" class="unchecked" svgIcon="check-ring-light" [class.selected]="selected"></mat-icon>
    <mat-icon *ngIf="selected" class="checked" svgIcon="checked-ring-green" [class.selected]="selected"></mat-icon>
    <span class="select-hint" *ngIf="!selected" i18n>Select</span>
    <span class="select-hint" *ngIf="selected" i18n>Deselect</span>
  </div>
</div>
