import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  loading = false;
  apiError: string;
  apiSuccess: string;

  constructor(private fb: FormBuilder, private auth: AuthService, private meta: MetaService) {}

  ngOnInit(): void {
    this.meta.setLocalizedTitle({
      en: 'Forgot password? Change Password | Gigital',
      'sv-se': 'Glömt lösenord - ändra ditt lösenord | Gigital',
    });

    this.meta.setLocalizedDescription({
      en: `Have you forgotten your password? 200 verified artists, DJ's and bands. Find and book your favorite. Free registration. Safe bookings.`,
      'sv-se':
        'Har du glömt ditt lösenord? Ändra ditt lösenord här. Hitta och boka över 200 artister, band och DJs till ditt event.',
    });

    this.resetPasswordForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
    });
  }

  get username(): AbstractControl {
    return this.resetPasswordForm.get('username');
  }

  onSubmit(): void {
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.apiError = undefined;
    this.apiSuccess = undefined;

    const callback = (error, data) => {
      this.loading = false;
      if (error) {
        this.apiError = error.description;
      }

      if (data) {
        this.apiSuccess = data;
      }
    };

    this.auth.resetPassword(this.username.value, callback);
  }
}
