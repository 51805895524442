<app-modal title="Videos and songs" i18n-title (save)="save()">
  <main class="bg-gray-5">
    <div class="input mat-elevation-z1 bg-white">
      <h2 class="h6 gray-1">Add a new song or video</h2>
      <p class="mat-body gray-1" i18n>Type in or paste an url in the field below to automatically add it.</p>
      <p class="mat-body-2 gray-2"
        >YouTube, Vimeo, Spotify and SoundCloud links are currently supported.
        <a href="#" target="blank">Need help? Click here.</a></p
      >
      <form [formGroup]="urlInGroup">
        <mat-form-field style="width: 100%">
          <span matPrefix>
            <mat-icon class="gray-2">link</mat-icon>
          </span>
          <input
            class="form-control"
            type="text"
            placeholder="URL (link to video or song)"
            formControlName="url"
            name="inputMedia"
            (paste)="addMedia()"
            matInput
            i18n-placeholder />
        </mat-form-field>
      </form>
      <mat-error *ngIf="noMatch" i18n>Det verkar som att du skrev in en länk som vi inte kan visa upp.</mat-error>
    </div>
    <p class="text-center" *ngIf="hasSpotifyArtist" i18n
      >Du har redan lagt till dina populäraste låtar från spotify. Om du vill ändra lista behöver du ta bort den som
      redan finns.</p
    >
    <div class="medias">
      <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
        <mat-tab label="Videos ({{ medias?.videos.length }})">
          <ng-template matTabContent>
            <p *ngIf="!medias.videos || medias.videos.length === 0" class="mat-body gray-2 text-center empty" i18n>
              Du har inte lagt till några videos än.
            </p>
            <div fxLayout="row wrap" fxLayoutGap="16px">
              <div
                fxFlex.gt-md="0 0 calc(33.333% - 16px)"
                fxLayout="column"
                fxLayoutAlign="center"
                *ngFor="let video of medias.videos; let i = index"
                class="media">
                <app-media-iframe [srcLink]="video"></app-media-iframe>
                <div class="media-actions text-center">
                  <button (click)="removeMedia('videos', i)" mat-icon-button>
                    <mat-icon>remove_circle</mat-icon>
                  </button>

                  <button (click)="highlightMedia('videos', i)" *ngIf="!video.isHighlight" mat-icon-button>
                    <mat-icon>star</mat-icon>
                  </button>
                  <button (click)="highlightMedia('videos', i, true)" *ngIf="video.isHighlight" mat-icon-button>
                    <mat-icon class="yellow">star</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Spotify ({{ medias?.spotify.length }})" id="tabSpotify">
          <ng-template matTabContent>
            <div class="page-profile-media songs mt-0">
              <p *ngIf="!medias.spotify || medias.spotify.length === 0" class="mat-body gray-2 text-center empty" i18n>
                Du har inte lagt till några låtar från Spotify än.
              </p>
              <div fxLayout="row wrap" fxLayoutGap="16px">
                <div
                  [fxFlex.gt-md]="spotify.isHighlight ? '0 0 calc(100% - 16px)' : '0 0 calc(50% - 16px)'"
                  fxLayout="column"
                  fxLayoutAlign="center"
                  *ngFor="let spotify of medias?.spotify; let i = index"
                  class="artist-detail-media-song media">
                  <app-media-iframe
                    [srcLink]="spotify"
                    [iframeNgClass]="'id-' + spotify.socialMediaId.toString()"
                    [iframeClass]="'w-100 audio'"
                    [isArtist]="spotify.isHighlight">
                  </app-media-iframe>
                  <div class="media-actions text-center">
                    <button (click)="removeMedia('spotify', i)" mat-icon-button>
                      <mat-icon>remove_circle</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="SoundCloud ({{ medias?.soundcloud.length }})" id="tabSoundcloud">
          <ng-template matTabContent>
            <div class="page-profile-media songs mt-0">
              <p
                *ngIf="!medias.soundcloud || medias.soundcloud.length === 0"
                class="mat-body gray-2 text-center empty"
                i18n>
                Du har inte lagt till några låtar från SoundCloud än.
              </p>
              <div fxLayout="row wrap" fxLayoutGap="16px">
                <div
                  fxFlex.gt-md="0 0 calc(50% - 16px)"
                  fxLayout="column"
                  fxLayoutAlign="center"
                  class="artist-detail-media-song media"
                  *ngFor="let soundcloud of medias?.soundcloud; let i = index">
                  <app-media-iframe
                    [srcLink]="soundcloud"
                    [iframeNgClass]="'id-' + soundcloud.socialMediaId.toString()"
                    [iframeClass]="'soundcloud w-100'">
                  </app-media-iframe>
                  <div class="media-actions text-center">
                    <button (click)="removeMedia('soundcloud', i)" mat-icon-button>
                      <mat-icon>remove_circle</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="MixCloud ({{ medias?.mixCloud.length }})">
          <ng-template matTabContent>
            <p *ngIf="!medias.mixCloud || medias.mixCloud.length === 0" class="mat-body gray-2 text-center empty" i18n>
              Du har inte lagt till några videos än.
            </p>
            <div fxLayout="row wrap" fxLayoutGap="16px">
              <div
                fxFlex.gt-md="0 0 calc(50% - 16px)"
                fxLayout="column"
                fxLayoutAlign="center"
                *ngFor="let mixCloud of medias.mixCloud; let i = index"
                class="media">
                <app-media-iframe [srcLink]="mixCloud"> </app-media-iframe>
                <div class="media-actions text-center">
                  <button (click)="removeMedia('mixCloud', i)" mat-icon-button>
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </main>
</app-modal>
