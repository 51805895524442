<div fxLayout="column" class="pricing">
  <span class="base-price">
    {{ getBaseDatesPrice | priceSpaceFormat }}
    {{ calculatedPricing.currency || 'SEK' }}
  </span>
  <p class="m-b--24" i18n>Preliminary price</p>

  <mat-divider class="m-b--24"></mat-divider>

  <ng-container *ngFor="let date of acceptedDates">
    <div fxLayoutAlign="space-between center" class="m-b--12">
      <span>{{ date.date | date: DATE_FORMAT | lowercase }} *</span>
      <span>
        {{ date.price | priceSpaceFormat }}
        {{ calculatedPricing?.currency || 'SEK' }}
      </span>
    </div>
  </ng-container>

  <div fxLayoutAlign="space-between center" class="m-b--12">
    <span i18n>Equipment rent*</span>
    <span>
      {{ calculatedPricing.supplies || 0 | priceSpaceFormat }}
      {{ calculatedPricing.currency || 'SEK' }}
    </span>
  </div>

  <div fxLayoutAlign="space-between center" class="m-b--12">
    <span i18n>Travel allowance</span>
    <span>{{ calculatedPricing.travel || 0 | priceSpaceFormat }} {{ calculatedPricing.currency || 'SEK' }}</span>
  </div>

  <div fxLayoutAlign="space-between center" fxLayoutGap="4px" class="m-b--12" *ngIf="amIBooker && fullPenaltyPrice > 0">
    <span i18n>Late cancellation 0-7 days *</span>
    <span style="text-align: right"
      >{{ fullPenaltyPrice || 0 | priceSpaceFormat }} {{ calculatedPricing.currency || 'SEK' }}</span
    >
  </div>
  <div fxLayoutAlign="space-between center" fxLayoutGap="4px" class="m-b--24" *ngIf="amIBooker && halfPenaltyPrice > 0">
    <span i18n>Late cancellation 7-29 days *</span>
    <span style="text-align: right"
      >{{ halfPenaltyPrice || 0 | priceSpaceFormat }} {{ calculatedPricing.currency || 'SEK' }}</span
    >
  </div>

  <mat-divider class="m-b--16"></mat-divider>

  <div class="price-info">
    <p *ngIf="amIBooker" class="m-b--16">
      <span i18n>* A fee of</span>&nbsp;
      <span class="bold-info">{{
        this.acceptedDates.length === 0
          ? ourPercentage(this.calculatedPricing.booker.fee) + '%'
          : this.calculatedPricing.booker.feePrice + ' SEK'
      }}</span
      >&nbsp;
      <span i18n
        >will be added to the booking and equipment price. This fee helps us to provide safe bookings and payments as
        well as around the clock help from our dedicated team.</span
      >
    </p>
    <p *ngIf="!amIBooker" class="m-b--16">
      <span i18n>* For you as an artist, a fee of</span>&nbsp;
      <span class="bold-info">{{
        this.acceptedDates.length === 0
          ? ourPercentage(this.calculatedPricing.artist.fee) + '%'
          : this.calculatedPricing.artist.feePrice + ' SEK'
      }}</span
      >&nbsp;
      <span i18n
        >will be deducted from the booking and equipment price. The fee is reinvested in sales & marketing - so that
        more gigs can be available to you.</span
      >
    </p>

    <mat-divider class="m-b--16"></mat-divider>

    <p fxLayoutAlign="space-between center" class="estimated" *ngIf="acceptedDates.length > 0 && !amIBooker">
      <span i18n>Payout after fees</span>&nbsp;
      <span
        >{{ calculatedPricing.artist.final | priceSpaceFormat }}&nbsp;{{ calculatedPricing.currency || 'SEK' }}</span
      >
    </p>
    <p fxLayoutAlign="space-between center" class="estimated" *ngIf="acceptedDates.length > 0 && amIBooker">
      <span i18n>End price</span>
      <span
        >{{ calculatedPricing.booker.final | priceSpaceFormat }}&nbsp;{{ calculatedPricing.currency || 'SEK' }}</span
      ></p
    >
    <p style="margin-top: 8px" i18n>Prices are stated excl VAT (25%)</p>
  </div>
</div>
