import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SearchInputComponent,
      multi: true,
    },
  ],
})
export class SearchInputComponent implements ControlValueAccessor {
  @Output() onSearchClick$: EventEmitter<any> = new EventEmitter();

  @Input() isImportStyles: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';

  public searchValue: string = '';

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public writeValue(searchString: string): void {
    this.searchValue = searchString;
  }

  public registerOnChange(fn: any): void {
    this.valueChanged = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public valueChanged(val) {}

  public inputChanges(event) {
    let value: string = event.target.value;
    this.valueChanged(value);
  }

  public onSearchClick() {
    this.onSearchClick$.emit(this.searchValue);
  }
}
