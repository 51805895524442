import { Component, ElementRef, ViewChild } from '@angular/core';
import { ArtistListFilterComponent } from '../artist-list-filter/artist-list-filter.component';

@Component({
  selector: 'app-artist-list-filter-side',
  templateUrl: './artist-list-filter-side.component.html',
  styleUrls: ['./artist-list-filter-side.component.scss'],
})
export class ArtistListFilterSideComponent extends ArtistListFilterComponent {
  @ViewChild('searchTermMobile', { static: true })
  searchTerm: ElementRef;
}
