import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { MappedContentfulArtistList } from '../../../utils/contentful';

@Component({
  selector: 'app-artists-list-contentful',
  templateUrl: './artists-list-contentful.component.html',
  styleUrls: ['./artists-list-contentful.component.scss'],
})
export class ArtistsListContentfulComponent implements OnInit {
  @Input()
  list: MappedContentfulArtistList;

  constructor(breakPointObserver: BreakpointObserver) {
    breakPointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result) => {
        if (result.matches) {
          const keys = Object.keys(result.breakpoints);
          const match = keys.find((k) => result.breakpoints[k]);
        }
      });
  }

  ngOnInit() {}
}
