import { Component, Input, OnInit } from '@angular/core';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-search-trend-item',
  templateUrl: './search-trend-item.component.html',
  styleUrls: ['./search-trend-item.component.scss'],
})
export class SearchTrendItemComponent implements OnInit {
  @Input() searchTrendItem: any;

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public ngOnInit(): void {}
}
