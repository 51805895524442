import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  Badge,
  CreatePageGQL,
  Page,
  PageCategory,
  UpdatePageBasicsGQL,
  UpdatePageImagesGQL,
} from '../../../../../../generated/graphql';
import { ArtistProgressStep, ProgressStepNumber } from '../../../../../models/ArtistProgress';
import { IconsRegistryService, IconSubsets } from '../../../../../services/icons-registry.service';
import { InfoBarType } from '../../../../../shared/components/info-bar/info-bar.component';
import { SelectInputOptions } from '../../../../../shared/components/inputs/select-input/select-input.component';
import { ImageSelectedEvent } from '../../../../components/image-cropper/image-cropper.component';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-artist-detail-basic-info',
  templateUrl: './artist-detail-basic-info.component.html',
  styleUrls: ['./artist-detail-basic-info.component.scss'],
})
export class ArtistDetailBasicInfoComponent implements OnInit {
  @Output() goToStep$: EventEmitter<number> = new EventEmitter<number>();

  @Input() pageProgressStep: ArtistProgressStep;
  @Input() page: Page;

  public profilePictureToSave: ImageSelectedEvent;
  public isLoading: boolean = false;
  public formGroup: FormGroup;

  public INFO_BAR_TEXT = $localize`We recommend high quality profile pictures showing people behind the act. Logos and text are discouraged on profile pictures.`;
  public PROGRESS_STEP_NUMBER = ProgressStepNumber;
  public PAGE_CATEGORY_ENUM = PageCategory;
  public INFO_BAR_TYPE = InfoBarType;

  public pageCategoryOptions: SelectInputOptions[] = Object.keys(PageCategory).map((key) => {
    return {
      value: PageCategory[key],
      label: key,
    };
  });

  public equipmentOptions: { name: string; value: any }[] = [
    { name: $localize`Yes`, value: true },
    { name: $localize`No`, value: false },
  ];

  get artistNameLabel(): string {
    return $localize`Artist name`;
  }

  get locationLabel(): string {
    return $localize`Location`;
  }

  get minimumPrice(): number {
    const isDj = this.formGroup.get('category').value === PageCategory.Dj;
    return isDj ? 3000 : 5000;
  }

  get priceAboveMinimum(): boolean {
    const price = this.formGroup.get('price').value;
    return price > 1 && price >= this.minimumPrice;
  }

  public constructor(
    private updatePageImagesGQL: UpdatePageImagesGQL,
    private updatePageBasicsGQL: UpdatePageBasicsGQL,
    private createPageGQL: CreatePageGQL,

    private iconsService: IconsRegistryService,
    private snackbar: MatSnackBar,
    private renderer: Renderer2,
    private router: Router,
  ) {
    this.iconsService.registerIcons([IconSubsets.FORMS, IconSubsets.NAVIGATION]);
  }

  public ngOnInit(): void {
    this.initForm();
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
  }

  private initForm(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(this.page?.name, [Validators.required]),
      category: new FormControl(this.page?.category || PageCategory.Live, [Validators.required]),
      placeId: new FormControl(this.page?.placeData?.placeId, [Validators.required]),
      canTravel: new FormControl(this.page?.badges?.includes(Badge.OwnTravel)),
      hasEquipment: new FormControl(this.page?.badges?.includes(Badge.OwnEquipment)),
      price: new FormControl(this.page?.price, [Validators.required, Validators.min(1)]),
      presentation: new FormControl(this.page?.presentation, [Validators.required]),
    });
  }

  public onLocationSelected(event: PlaceResult): void {
    console.log(event);
  }

  public savePageBasicInfo(): void {
    if (!this.formGroup.valid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        control.markAsTouched();
      });
      return;
    } else {
      if (this.formGroup.touched || this.formGroup.dirty) {
        if (this.page.id) {
          this.updatePageBasics();
        } else {
          this.createNewPageWithBasics();
        }
      } else {
        this.goToStep$.emit(1);
      }
    }
  }

  private updatePageBasics(): void {
    this.isLoading = true;
    this.updatePageBasicsGQL
      .mutate({
        id: this.page.id,
        ...this.formGroup.value,
      })
      .subscribe(() => {
        this.isLoading = false;
        this.snackbar.open($localize`Your basic info updated succesfully`, undefined, { duration: 3000 });
        this.goToStep$.emit(1);
      });
  }

  private createNewPageWithBasics(): void {
    this.createPageGQL
      .mutate({
        page: {
          ...this.formGroup.value,
        },
      })
      .subscribe(({ data }) => {
        this.page.id = data.createPage.id;
        if (this.profilePictureToSave) {
          this.updateProfilePicture(this.profilePictureToSave);
        }
        this.isLoading = false;
        this.snackbar.open($localize`Your artist page created succesfully`, undefined, { duration: 3000 });
        this.router.navigate(['../artists', data.createPage.slug, 'edit'], {
          queryParams: { step: ProgressStepNumber.TAGS },
        });
      }),
      (error) => {
        this.snackbar.open(error, undefined, { duration: 3000 });
      };
  }

  public updateProfilePicture(image: ImageSelectedEvent) {
    if (this.page.id) {
      let imageData = {
        pageId: this.page.id,
        profilePicture: {
          cropData: image.cropData,
          image: image.image,
        },
      };

      this.updatePageImagesGQL.mutate(imageData, { context: { useMultipart: true } }).subscribe(() => {
        this.snackbar.open($localize`Your profile picture updated succesfully`, undefined, { duration: 3000 });
      });
    } else {
      this.profilePictureToSave = image;
    }
  }
}
