import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingSignComponent } from '../../../components/booking-sign/booking-sign.component';

@Component({
  selector: 'app-bookings-detail-sign',
  templateUrl: './bookings-detail-sign.component.html',
  styleUrls: ['./bookings-detail-sign.component.scss'],
})
export class BookingsDetailSignComponent implements OnInit {
  constructor(private modal: MatDialog, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    const modalRef = this.modal.open(BookingSignComponent, { autoFocus: false });
    modalRef.afterClosed().subscribe((success: boolean) => {
      if (!success) {
        this.router.navigate(['../'], { relativeTo: this.route });
      }
    });
  }
}
