<app-dropdown *ngIf="selectedPage" [onClosed]="onDropdownClosed$">
  <div selected fxFlex="grow" class="dropdown-item" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
    <app-avatar
      *ngIf="selectedPage.profilePicture; else defaultAvatar"
      [profilePicture]="selectedPage.profilePicture"
      [mini]="mini"></app-avatar>

    <span class="name-text">{{ selectedPage.name }}</span>
    <span *ngIf="hasPageApplied(selectedPage)" class="already-applied-hint" i18n>Already applied</span>
  </div>
  <div
    options
    *ngFor="let artist of artists"
    fxFlex="grow"
    class="dropdown-item"
    fxLayout="row"
    fxLayoutGap="15px"
    fxLayoutAlign="start center"
    (click)="selectPage(artist, $event)">
    <app-avatar
      *ngIf="artist.profilePicture; else defaultAvatar"
      [profilePicture]="artist.profilePicture"
      [mini]="mini"></app-avatar>
    <span class="name-text">{{ artist.name }}</span>
    <span *ngIf="hasPageApplied(artist)" class="already-applied-hint space-right" i18n>Already applied</span>
  </div>
</app-dropdown>

<ng-template #defaultAvatar>
  <img class="profile-picture" src="assets/images/default-avatar.svg" />
</ng-template>
