import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Arbitrary object */
  Object: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. BigInt can represent values between -(2^53) + 1 and 2^53 - 1.  */
  BigInt: any;
  /** Date custom scalar type */
  Date: any;
  Upload: any;
};



export type Query = {
  hello?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
  billingInfos: Array<Maybe<Billing>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  pages: PageFeed;
  findOnePage?: Maybe<Page>;
  pagesByUser?: Maybe<Array<Maybe<Page>>>;
  filterPages?: Maybe<FilterFeed>;
  pageTextSearch?: Maybe<FilterFeed>;
  getPageLists?: Maybe<Array<Maybe<PageList>>>;
  findOneList?: Maybe<PageList>;
  getPageReviews?: Maybe<PageReviews>;
  getImportablePageData?: Maybe<ImportablePages>;
  getFilterablePagePlaces?: Maybe<Array<Maybe<ExistingCountry>>>;
  getPageTagLists?: Maybe<Array<Maybe<PageTagListItem>>>;
  gigs?: Maybe<GigFeed>;
  completedGigs?: Maybe<CompletedGigs>;
  gigById?: Maybe<SlotFeed>;
  gigsByUser?: Maybe<Array<Maybe<Gig>>>;
  dashboardGigs?: Maybe<DashboardResponse>;
  applicationsByUser?: Maybe<ApplicationsFeed>;
  existingPlaces: Array<Maybe<PlaceEntry>>;
  getTemplates?: Maybe<Array<Maybe<GigTemplate>>>;
  openGigs?: Maybe<OpenGigs>;
  getNotifications?: Maybe<NotificationFeed>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  channelById?: Maybe<Channel>;
  channelsByUser?: Maybe<Array<Maybe<Channel>>>;
  filesByUser?: Maybe<Array<Maybe<GigitalFile>>>;
  bookingsByUser?: Maybe<BookingsByUser>;
  bookingDatesByUser?: Maybe<BookingDatesFeed>;
  reviewsByUser?: Maybe<Array<Maybe<Review>>>;
  bookingById?: Maybe<Booking>;
  bookingsCount?: Maybe<BookingsCount>;
  dashboardBookings?: Maybe<DashboardBookings>;
  getCalculatedPricing?: Maybe<Array<Maybe<Booking>>>;
  cacheHealth?: Maybe<CacheInfo>;
  getLoggedInUsers?: Maybe<Array<Maybe<AdminUser>>>;
  admins?: Maybe<Array<Maybe<AdminUser>>>;
  byId?: Maybe<AdminUser>;
  allUsers?: Maybe<AdminGetAllUsers>;
  userSearch?: Maybe<Array<Maybe<AdminUser>>>;
  getPagesAdmin?: Maybe<AdminPageList>;
  adminPageTextSearch?: Maybe<Array<Maybe<Page>>>;
  getExistingPagePlaces?: Maybe<Array<Maybe<Tag>>>;
  adminSearchBookings?: Maybe<AdminBookings>;
  adminGetBookingsByPage?: Maybe<Array<Maybe<Booking>>>;
  adminGetBookingPaymentStatus?: Maybe<AdminBookingPaymentInfo>;
  adminGetGigs?: Maybe<AdminGigFeed>;
  adminGigById?: Maybe<AdminGig>;
  adminInfo?: Maybe<AdminHome>;
  getEvents?: Maybe<Array<Maybe<Event>>>;
  getLoggedEvents?: Maybe<LoggedEventPage>;
  getContentfulEntry?: Maybe<ContentfulData>;
  motd?: Maybe<Array<Maybe<Motd>>>;
  getPageFaqTemplates?: Maybe<PageFaqTemplatesFeed>;
  reviewById?: Maybe<ArtistReview>;
  reviewByBookingDate?: Maybe<ArtistReview>;
  reviewByPage?: Maybe<Array<Maybe<ArtistReview>>>;
  possibleReviews?: Maybe<Array<Maybe<Page>>>;
  adminReviews?: Maybe<AdminReviewFeed>;
  adminSearchReview?: Maybe<Array<Maybe<Review>>>;
  adminReviewById?: Maybe<Review>;
  inviteByPublicId?: Maybe<Invite>;
  getInvitesByUser?: Maybe<InviteFeed>;
  adminGetInvites?: Maybe<AdminInviteFeed>;
  adminSearchInvites?: Maybe<Array<Maybe<AdminInvite>>>;
  adminFindOneInvite: AdminInvite;
  adminFindOneOrg?: Maybe<AdminOrganization>;
  adminFindAllOrgs?: Maybe<OrganizationsFeed>;
  getPaymentMethods?: Maybe<Scalars['Object']>;
  getPaymentHistory?: Maybe<Scalars['Object']>;
};


export type QueryPagesArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  tagFilter?: Maybe<Scalars['String']>;
};


export type QueryFindOnePageArgs = {
  pageId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryFilterPagesArgs = {
  filter: FilterPages;
  paginate: Pagination;
};


export type QueryPageTextSearchArgs = {
  query?: Maybe<Scalars['String']>;
  paginate: Pagination;
};


export type QueryGetPageListsArgs = {
  country?: Maybe<CountriesEnum>;
  gigType?: Maybe<GigType>;
};


export type QueryFindOneListArgs = {
  idOrSlug?: Maybe<Scalars['String']>;
};


export type QueryGetPageReviewsArgs = {
  pageId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  paging: Pagination;
};


export type QueryGetImportablePageDataArgs = {
  userName?: Maybe<Scalars['String']>;
  platforms: Array<SocialMediaAccountType>;
};


export type QueryGetPageTagListsArgs = {
  tagLists?: Maybe<Array<Maybe<PageTagListInput>>>;
};


export type QueryGigsArgs = {
  filter?: Maybe<GigFilter>;
  paging?: Maybe<Pagination>;
};


export type QueryGigByIdArgs = {
  gigId?: Maybe<Scalars['Int']>;
  paging?: Maybe<Pagination>;
};


export type QueryApplicationsByUserArgs = {
  type: ApplicationType;
  paging: Pagination;
};


export type QueryGetNotificationsArgs = {
  paging?: Maybe<Pagination>;
  includeUnread: Scalars['Boolean'];
};


export type QueryChannelByIdArgs = {
  channelId?: Maybe<Scalars['Int']>;
};


export type QueryChannelsByUserArgs = {
  paging?: Maybe<Pagination>;
};


export type QueryBookingsByUserArgs = {
  type?: Maybe<BookingFeedType>;
};


export type QueryBookingDatesByUserArgs = {
  paging: Pagination;
};


export type QueryBookingByIdArgs = {
  bookingId: Scalars['Int'];
};


export type QueryGetCalculatedPricingArgs = {
  bookingIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryByIdArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryAllUsersArgs = {
  paging?: Maybe<Pagination>;
  sorting?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryUserSearchArgs = {
  searchText: Scalars['String'];
  type?: Maybe<UserType>;
};


export type QueryGetPagesAdminArgs = {
  paging?: Maybe<Pagination>;
  sorting?: Maybe<Scalars['String']>;
  filter?: Maybe<AdminPagesFilter>;
};


export type QueryAdminPageTextSearchArgs = {
  textQuery: Scalars['String'];
};


export type QueryAdminSearchBookingsArgs = {
  searchText: Scalars['String'];
  searchOps: BookingSearchOps;
  paging?: Maybe<Pagination>;
  sorting?: Maybe<Scalars['String']>;
};


export type QueryAdminGetBookingsByPageArgs = {
  pageId: Scalars['Int'];
};


export type QueryAdminGetBookingPaymentStatusArgs = {
  bookingId: Scalars['Int'];
};


export type QueryAdminGetGigsArgs = {
  paging: Pagination;
};


export type QueryAdminGigByIdArgs = {
  gigId: Scalars['Int'];
};


export type QueryGetLoggedEventsArgs = {
  filter?: Maybe<EventLogsFilter>;
  paging?: Maybe<Pagination>;
};


export type QueryGetContentfulEntryArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
};


export type QueryMotdArgs = {
  all?: Maybe<Scalars['Boolean']>;
};


export type QueryGetPageFaqTemplatesArgs = {
  paging: Pagination;
};


export type QueryReviewByIdArgs = {
  id: Scalars['Int'];
};


export type QueryReviewByBookingDateArgs = {
  id: Scalars['Int'];
};


export type QueryReviewByPageArgs = {
  pageId: Scalars['Int'];
};


export type QueryAdminReviewsArgs = {
  onlyReviewed?: Maybe<Scalars['Boolean']>;
  recommended: Scalars['Boolean'];
  paging: Pagination;
};


export type QueryAdminSearchReviewArgs = {
  term: Scalars['String'];
};


export type QueryAdminReviewByIdArgs = {
  reviewId: Scalars['Int'];
};


export type QueryInviteByPublicIdArgs = {
  publicId?: Maybe<Scalars['String']>;
};


export type QueryGetInvitesByUserArgs = {
  paging: Pagination;
};


export type QueryAdminGetInvitesArgs = {
  paging: Pagination;
};


export type QueryAdminSearchInvitesArgs = {
  search: InviteSearch;
};


export type QueryAdminFindOneInviteArgs = {
  publicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};


export type QueryAdminFindOneOrgArgs = {
  id?: Maybe<Scalars['Int']>;
  orgId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryAdminFindAllOrgsArgs = {
  paging: Pagination;
};

export type User = {
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  complete?: Maybe<Scalars['Boolean']>;
  newsletter?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  billings?: Maybe<Array<Maybe<Billing>>>;
  isSocial?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  fee?: Maybe<Scalars['Float']>;
  lang?: Maybe<CountriesEnum>;
  receiveTransactional?: Maybe<Scalars['Boolean']>;
  missingData?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyName?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  phoneNumber?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  hasSearchablePage?: Maybe<Scalars['Boolean']>;
  reviewerName?: Maybe<Scalars['String']>;
  isLoggedIn?: Maybe<Scalars['Boolean']>;
  organizations?: Maybe<Array<Maybe<Organization>>>;
};

export type Role = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Billing = {
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  companyName?: Maybe<Scalars['String']>;
  orgNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export enum CountriesEnum {
  Global = 'GLOBAL',
  Europe = 'EUROPE',
  Sweden = 'SWEDEN',
  Norway = 'NORWAY',
  Denmark = 'DENMARK',
  Finland = 'FINLAND',
  Germany = 'GERMANY',
  Usa = 'USA',
  Uk = 'UK'
}

export enum UserType {
  None = 'NONE',
  Booker = 'BOOKER',
  Artist = 'ARTIST',
  BookerAndArtist = 'BOOKER_AND_ARTIST'
}

export type Organization = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<User>>>;
  bookingFee?: Maybe<Scalars['Float']>;
  artistFee?: Maybe<Scalars['Float']>;
  emailInvitePatterns?: Maybe<Array<Maybe<Scalars['String']>>>;
  gigTemplates?: Maybe<Array<Maybe<GigTemplate>>>;
  blogTags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GigTemplate = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  type?: Maybe<GigType>;
  deadline?: Maybe<GigDeadlineEnum>;
  defaultPrice?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  org?: Maybe<Organization>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export enum GigType {
  Business = 'BUSINESS',
  Shopping = 'SHOPPING',
  Festival = 'FESTIVAL',
  Club = 'CLUB',
  Gala = 'GALA',
  Others = 'OTHERS',
  Private = 'PRIVATE',
  Venue = 'VENUE'
}

export enum GigDeadlineEnum {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH'
}

export type Tag = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['Int']>;
  categoryRestrictions?: Maybe<Array<Maybe<PageCategory>>>;
  allowedBy?: Maybe<Array<Maybe<Tag>>>;
  parent?: Maybe<Tag>;
  children?: Maybe<Array<Maybe<Tag>>>;
  translations?: Maybe<SimpleTranslation>;
};

export enum PageCategory {
  Live = 'LIVE',
  Dj = 'DJ'
}

export type SimpleTranslation = {
  sv?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
};

export type PageFeed = {
  edges: Array<Page>;
  pageInfo?: Maybe<PageInfo>;
};

export type Page = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<PageCategory>;
  slug?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  badges?: Maybe<Array<Maybe<Badge>>>;
  createdDate?: Maybe<Scalars['String']>;
  dateModified?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  links?: Maybe<Array<Maybe<PageLink>>>;
  profilePicture?: Maybe<Image>;
  backgroundPicture?: Maybe<Image>;
  pageStatus?: Maybe<PageStatusEnum>;
  reviewStatus?: Maybe<ReviewStatusEnum>;
  ownership?: Maybe<PageOwnership>;
  searchable?: Maybe<Scalars['Boolean']>;
  denyReasons?: Maybe<Array<Maybe<DenyReason>>>;
  gallery?: Maybe<Array<Maybe<Image>>>;
  faq?: Maybe<Array<Maybe<PageFaqAnswer>>>;
  positiveReviews?: Maybe<Scalars['Int']>;
  negativeReviews?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Float']>;
  signedDates?: Maybe<Scalars['Int']>;
  placeData?: Maybe<BookingPlace>;
  placeName?: Maybe<Scalars['String']>;
};

export enum Badge {
  OwnEquipment = 'OWN_EQUIPMENT',
  OwnTravel = 'OWN_TRAVEL',
  Bookings_10 = 'BOOKINGS_10',
  Bookings_25 = 'BOOKINGS_25',
  Bookings_50 = 'BOOKINGS_50',
  Bookings_75 = 'BOOKINGS_75',
  Bookings_100 = 'BOOKINGS_100',
  FastResponder = 'FAST_RESPONDER'
}

export type PageLink = {
  id?: Maybe<Scalars['Int']>;
  socialMediaId?: Maybe<Scalars['Int']>;
  socialMediaTypeId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  isHighlight?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
};

export type Image = {
  id?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  cropData?: Maybe<ImageCropData>;
  order?: Maybe<Scalars['Int']>;
};

export type ImageCropData = {
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  w?: Maybe<Scalars['Float']>;
  h?: Maybe<Scalars['Float']>;
};

export enum PageStatusEnum {
  New = 'NEW',
  Edited = 'EDITED',
  Locked = 'LOCKED',
  Inactive = 'INACTIVE',
  Deleted = 'DELETED'
}

export enum ReviewStatusEnum {
  New = 'NEW',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Denied = 'DENIED'
}

export enum PageOwnership {
  Artist = 'ARTIST',
  Manager = 'MANAGER',
  Subagent = 'SUBAGENT'
}

export type DenyReason = {
  title?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['String']>;
};

export type PageFaqAnswer = {
  lang?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
};

export type BookingPlace = {
  placeId?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  components?: Maybe<Array<Maybe<Scalars['Object']>>>;
  name?: Maybe<Scalars['String']>;
  geometry?: Maybe<PlaceGeometry>;
};


export type PlaceGeometry = {
  location?: Maybe<LatLong>;
  viewport?: Maybe<PlaceGeometryViewport>;
};

export type LatLong = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type PlaceGeometryViewport = {
  northeast?: Maybe<LatLong>;
  southwest?: Maybe<LatLong>;
};

export type PageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type FilterPages = {
  tags?: Maybe<Array<Maybe<TagFilter>>>;
  priceMin?: Maybe<Scalars['Int']>;
  priceMax?: Maybe<Scalars['Int']>;
  sorting: Scalars['String'];
  textQuery?: Maybe<Scalars['String']>;
  category?: Maybe<PageCategory>;
  includesEquipment?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['String']>;
  location?: Maybe<Array<LatLongInput>>;
  locationRadiusKm?: Maybe<Scalars['Int']>;
};

export type TagFilter = {
  tagId: Scalars['Int'];
  points: Scalars['Int'];
};

export type LatLongInput = {
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type Pagination = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type FilterFeed = {
  edges: Array<Page>;
  skip?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['Int']>;
  tagMatches?: Maybe<Array<Maybe<TagMatches>>>;
  existingTags?: Maybe<Array<Maybe<Tag>>>;
  tagHits?: Maybe<Array<Maybe<TagMatches>>>;
};

export type TagMatches = {
  tagId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type PageList = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pages?: Maybe<Array<Maybe<Page>>>;
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  gigType?: Maybe<GigType>;
  country?: Maybe<CountriesEnum>;
  order?: Maybe<Array<Maybe<Scalars['Int']>>>;
  slug?: Maybe<Scalars['String']>;
};

export type PageReviews = {
  reviews?: Maybe<Array<Maybe<Review>>>;
  total?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
};

export type Review = {
  id?: Maybe<Scalars['Int']>;
  page?: Maybe<Page>;
  recommended?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  bookingDate?: Maybe<BookingDates>;
  user?: Maybe<User>;
  reviewer?: Maybe<ExternalBooker>;
  canUsePublic?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
  playDate?: Maybe<Scalars['Date']>;
  reviewDate?: Maybe<Scalars['Date']>;
  gigType?: Maybe<GigType>;
  status?: Maybe<GigReviewStatus>;
};

export type BookingDates = {
  id: Scalars['Int'];
  booking?: Maybe<Booking>;
  addedBy?: Maybe<User>;
  price?: Maybe<Scalars['BigInt']>;
  priceCurrency?: Maybe<Currency>;
  date?: Maybe<Scalars['String']>;
  soundCheckDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  gigSlot?: Maybe<GigSlot>;
  review?: Maybe<Review>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  cancelledBy?: Maybe<User>;
  cancelledDaysBefore?: Maybe<Scalars['Int']>;
  cancelledDate?: Maybe<Scalars['Date']>;
  wasSignedWhenCancelled?: Maybe<Scalars['Boolean']>;
  cancelledReason?: Maybe<Scalars['String']>;
};

export type Booking = {
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  page?: Maybe<Page>;
  title?: Maybe<Scalars['String']>;
  appliedTo?: Maybe<Gig>;
  gigSlots?: Maybe<Array<Maybe<GigSlot>>>;
  channel?: Maybe<Channel>;
  status?: Maybe<BookingStatusEnum>;
  signatures?: Maybe<Array<Maybe<Signature>>>;
  organization?: Maybe<Booking>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<GigType>;
  place?: Maybe<BookingPlace>;
  others?: Maybe<BookingOthers>;
  billingInfo?: Maybe<Billing>;
  isBooker?: Maybe<Scalars['Boolean']>;
  dateModified?: Maybe<Scalars['String']>;
  dates?: Maybe<Array<Maybe<BookingDates>>>;
  createdDate?: Maybe<Scalars['String']>;
  supplies?: Maybe<Array<Maybe<SupplyTypes>>>;
  supplyStatus?: Maybe<EquipmentStatus>;
  supplyPrice?: Maybe<Scalars['Int']>;
  travelPrice?: Maybe<Scalars['Int']>;
  calculatedPricing?: Maybe<CalculatedPricing>;
  newTypes?: Maybe<Array<Maybe<NewTypes>>>;
  cancelledBy?: Maybe<User>;
  cancelledDaysBefore?: Maybe<Scalars['Int']>;
  cancelledDate?: Maybe<Scalars['Date']>;
  wasSignedWhenCancelled?: Maybe<Scalars['Boolean']>;
  wantsInvoice?: Maybe<Scalars['Boolean']>;
  amountPaid?: Maybe<Scalars['Int']>;
};

export type Gig = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  details?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  placeData?: Maybe<Scalars['Object']>;
  type?: Maybe<GigType>;
  slots?: Maybe<Array<Maybe<GigSlot>>>;
  isOwner?: Maybe<Scalars['Boolean']>;
  deadline?: Maybe<GigDeadlineEnum>;
  images?: Maybe<Array<Maybe<Image>>>;
  supplies?: Maybe<Array<Maybe<SupplyTypes>>>;
  slotsCount?: Maybe<Scalars['Int']>;
  slotsOpen?: Maybe<Scalars['Int']>;
  availableTags?: Maybe<Array<Maybe<Tag>>>;
  limitedData?: Maybe<Scalars['Boolean']>;
  newApplications?: Maybe<Scalars['Int']>;
  isRequestingSupplies?: Maybe<Scalars['Boolean']>;
  extraTags?: Maybe<Array<Maybe<GigTags>>>;
};

export type GigSlot = {
  id?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  date?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['BigInt']>;
  deadline?: Maybe<Scalars['String']>;
  applications?: Maybe<Array<Maybe<Booking>>>;
  gig?: Maybe<Gig>;
  status?: Maybe<SlotStatus>;
  priceHidden?: Maybe<Scalars['Boolean']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};


export enum SlotStatus {
  Empty = 'EMPTY',
  Applications = 'APPLICATIONS',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Assigned = 'ASSIGNED'
}

export enum SupplyTypes {
  Mics = 'MICS',
  Mixer = 'MIXER',
  Pa = 'PA',
  Speakers = 'SPEAKERS'
}

export enum GigTags {
  NewSlots = 'NEW_SLOTS',
  NewGig = 'NEW_GIG',
  HighPrice = 'HIGH_PRICE',
  HighChance = 'HIGH_CHANCE',
  ActiveUser = 'ACTIVE_USER'
}

export type Channel = {
  id?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<User>>>;
  messages?: Maybe<Array<Maybe<ChannelMessage>>>;
  booking?: Maybe<Booking>;
  invitedUsers?: Maybe<Array<Maybe<ChannelInvitation>>>;
};

export type ChannelMessage = {
  id?: Maybe<Scalars['Int']>;
  body?: Maybe<Scalars['String']>;
  sender?: Maybe<User>;
  conversationId?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['String']>;
  file?: Maybe<GigitalFile>;
  readBy?: Maybe<Array<Maybe<User>>>;
};

export type GigitalFile = {
  id?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  fileExtension?: Maybe<Scalars['String']>;
};

export type ChannelInvitation = {
  invitedUser?: Maybe<User>;
  invitedBy?: Maybe<User>;
  channel?: Maybe<Channel>;
  status?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum BookingStatusEnum {
  Request = 'REQUEST',
  Pending = 'PENDING',
  WaitingForOtherSign = 'WAITING_FOR_OTHER_SIGN',
  Signed = 'SIGNED',
  Application = 'APPLICATION',
  ApplicationDenied = 'APPLICATION_DENIED',
  ApplicationCancelled = 'APPLICATION_CANCELLED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  ApplicationLegacyAccepted = 'APPLICATION_LEGACY_ACCEPTED',
  LegacyAccepted = 'LEGACY_ACCEPTED'
}

export type Signature = {
  id?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  valid?: Maybe<Scalars['Boolean']>;
  isBooker?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BookingComponentItemType>;
};


export enum BookingComponentItemType {
  Price = 'PRICE',
  Date = 'DATE',
  Others = 'OTHERS',
  Booking = 'BOOKING',
  Place = 'place',
  BillingInfo = 'BILLING_INFO',
  Travel = 'TRAVEL',
  Supplies = 'SUPPLIES'
}

export type BookingOthers = {
  others: Scalars['String'];
  addedBy: Scalars['Int'];
  signedBy: Array<Scalars['Int']>;
  createdDate: Scalars['String'];
};

export enum EquipmentStatus {
  None = 'NONE',
  Requested = 'REQUESTED',
  Supplied = 'SUPPLIED'
}

export type CalculatedPricing = {
  artist?: Maybe<BookingPartyPricing>;
  booker?: Maybe<BookingPartyPricing>;
  baseDates?: Maybe<Scalars['Float']>;
  travel?: Maybe<Scalars['Float']>;
  supplies?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
};

export type BookingPartyPricing = {
  final?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  feePrice?: Maybe<Scalars['Float']>;
  penaltyFee?: Maybe<Scalars['Float']>;
  penaltyTotal?: Maybe<Scalars['Float']>;
};

export enum Currency {
  Sek = 'SEK',
  Nok = 'NOK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Usd = 'USD'
}

export enum NewTypes {
  Application = 'APPLICATION',
  RequestArtist = 'REQUEST_ARTIST',
  RequestBooker = 'REQUEST_BOOKER'
}

export type ExternalBooker = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export enum GigReviewStatus {
  Visible = 'VISIBLE',
  Hidden = 'HIDDEN',
  Delted = 'DELTED',
  Invite = 'INVITE'
}

export enum SocialMediaAccountType {
  Youtube = 'YOUTUBE',
  Spotify = 'SPOTIFY',
  Mixcloud = 'MIXCLOUD'
}

export type ImportablePages = {
  youtube?: Maybe<Array<Maybe<SocialMediaAccountData>>>;
  spotify?: Maybe<Array<Maybe<SocialMediaAccountData>>>;
  mixcloud?: Maybe<Array<Maybe<SocialMediaAccountData>>>;
};

export type SocialMediaAccountData = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  thumbnailImageUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ExistingCountry = {
  name?: Maybe<Scalars['String']>;
  places?: Maybe<Array<Maybe<ExistingPlace>>>;
};

export type ExistingPlace = {
  types: Array<Scalars['String']>;
  longName: Scalars['String'];
  geometry?: Maybe<PlaceGeometry>;
  count?: Maybe<Scalars['Int']>;
};

export type PageTagListInput = {
  tagIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  category?: Maybe<PageCategory>;
};

export type PageTagListItem = {
  tagIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  pages?: Maybe<Array<Maybe<Page>>>;
};

export type GigFilter = {
  locations?: Maybe<Array<ExistingPlaceInput>>;
  tags?: Maybe<Array<Scalars['Int']>>;
  type?: Maybe<Array<GigType>>;
  sorting?: Maybe<Scalars['String']>;
};

export type ExistingPlaceInput = {
  types: Array<Scalars['String']>;
  longName: Scalars['String'];
};

export type GigFeed = {
  edges: Array<Gig>;
  skip?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  availableFilter?: Maybe<AvailableGigFilter>;
};

export type AvailableGigFilter = {
  tags?: Maybe<Array<Maybe<TagEntry>>>;
  locations?: Maybe<Array<Maybe<PlaceEntry>>>;
  types?: Maybe<Array<Maybe<TypeEntry>>>;
};

export type TagEntry = {
  tag: Tag;
  amount: Scalars['Int'];
};

export type PlaceEntry = {
  place: ExistingPlace;
  amount: Scalars['Int'];
};

export type TypeEntry = {
  type: GigType;
  amount: Scalars['Int'];
};

export type CompletedGigs = {
  gigs?: Maybe<Array<Maybe<Gig>>>;
  numAssigned?: Maybe<Scalars['Int']>;
};

export type SlotFeed = {
  gig?: Maybe<Gig>;
  skip?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type DashboardResponse = {
  gigs?: Maybe<Array<Maybe<Gig>>>;
  count?: Maybe<Scalars['Int']>;
};

export enum ApplicationType {
  All = 'ALL',
  Pending = 'PENDING',
  Finalizing = 'FINALIZING',
  Declined = 'DECLINED',
  Booked = 'BOOKED'
}

export type ApplicationsFeed = {
  edges: Array<BookingDates>;
  skip?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['Int']>;
};

export type OpenGigs = {
  gigs?: Maybe<Scalars['Int']>;
  slots?: Maybe<Scalars['Int']>;
};

export type NotificationFeed = {
  notifications?: Maybe<Array<Maybe<NotificationWithSubs>>>;
  unread?: Maybe<Array<Maybe<NotificationWithSubs>>>;
  skip?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type NotificationWithSubs = {
  notification?: Maybe<Notification>;
  subs?: Maybe<Array<Maybe<Notification>>>;
};

export type Notification = {
  id?: Maybe<Scalars['Int']>;
  sendingUser?: Maybe<User>;
  meta?: Maybe<Scalars['Object']>;
  isRead?: Maybe<Scalars['Boolean']>;
  createdDate?: Maybe<Scalars['Date']>;
  type?: Maybe<NotificationTypeEnum>;
  status?: Maybe<NotificationStatusEnum>;
  page?: Maybe<Page>;
};

export enum NotificationTypeEnum {
  Booking = 'BOOKING',
  Channel = 'CHANNEL',
  Gig = 'GIG',
  Organization = 'ORGANIZATION',
  Page = 'PAGE',
  Generic = 'GENERIC',
  Badge = 'BADGE'
}

export enum NotificationStatusEnum {
  New = 'NEW',
  Removed = 'REMOVED',
  Updated = 'UPDATED'
}

export enum BookingFeedType {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Others = 'OTHERS',
  Signed = 'SIGNED',
  Upcoming = 'UPCOMING'
}

export type BookingsByUser = {
  open?: Maybe<Array<Maybe<Booking>>>;
  openLength?: Maybe<Scalars['Int']>;
  closed?: Maybe<Array<Maybe<Booking>>>;
  signed?: Maybe<Array<Maybe<Booking>>>;
  others?: Maybe<Array<Maybe<Booking>>>;
};

export type BookingDatesFeed = {
  dates?: Maybe<Array<Maybe<BookingDates>>>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
};

export type BookingsCount = {
  open?: Maybe<Scalars['Int']>;
  signed?: Maybe<Scalars['Int']>;
  closed?: Maybe<Scalars['Int']>;
  others?: Maybe<Scalars['Int']>;
};

export type DashboardBookings = {
  upcomingDates?: Maybe<Array<Maybe<BookingDates>>>;
  upcomingCount?: Maybe<Scalars['Int']>;
  pending?: Maybe<Array<Maybe<Booking>>>;
  pendingCount?: Maybe<Scalars['Int']>;
};

export type CacheInfo = {
  stats?: Maybe<CacheStats>;
  keys?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CacheStats = {
  keys?: Maybe<Scalars['Float']>;
  hits?: Maybe<Scalars['Float']>;
  misses?: Maybe<Scalars['Float']>;
  ksize?: Maybe<Scalars['Float']>;
  vsize?: Maybe<Scalars['Float']>;
};

export type AdminUser = {
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  complete?: Maybe<Scalars['Boolean']>;
  newsletter?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  billings?: Maybe<Array<Maybe<Billing>>>;
  isSocial?: Maybe<Scalars['Boolean']>;
  socialType?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<GigitalFile>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  gigs?: Maybe<Array<Maybe<Gig>>>;
  images?: Maybe<Array<Maybe<Image>>>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  pages?: Maybe<Array<Maybe<AdminPage>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  createdDate?: Maybe<Scalars['String']>;
  dateModified?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  type?: Maybe<UserType>;
  companyName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  sentInvites?: Maybe<Array<Maybe<AdminInvite>>>;
  claimedInvites?: Maybe<Array<Maybe<AdminInvite>>>;
  reviews?: Maybe<Array<Maybe<Review>>>;
  lastLogin?: Maybe<Scalars['String']>;
};

export type AdminPage = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['String']>;
  dateModified?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  links?: Maybe<Array<Maybe<PageLink>>>;
  profilePicture?: Maybe<Image>;
  backgroundPicture?: Maybe<Image>;
  pageStatus?: Maybe<PageStatusEnum>;
  reviewStatus?: Maybe<ReviewStatusEnum>;
  ownership?: Maybe<PageOwnership>;
  searchable?: Maybe<Scalars['Boolean']>;
  denyReasons?: Maybe<Array<Maybe<DenyReason>>>;
  positiveReviews?: Maybe<Scalars['Int']>;
  negativeReviews?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Float']>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  replyRate?: Maybe<Scalars['Int']>;
  missedBookings?: Maybe<Scalars['Int']>;
};

export type AdminInvite = {
  id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  inviterUser?: Maybe<User>;
  acceptedUser?: Maybe<User>;
  email?: Maybe<Scalars['String']>;
  claimed: Scalars['Boolean'];
  createdDate?: Maybe<Scalars['Date']>;
  dateModified?: Maybe<Scalars['Date']>;
  review?: Maybe<Review>;
  artistReview?: Maybe<ArtistReview>;
};

export type ArtistReview = {
  id?: Maybe<Scalars['Int']>;
  page?: Maybe<Page>;
  user?: Maybe<User>;
  reviewer?: Maybe<ExternalBooker>;
  reviewDate?: Maybe<Scalars['Date']>;
  status?: Maybe<GigReviewStatus>;
  message?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
};

export type AdminGetAllUsers = {
  users?: Maybe<Array<Maybe<AdminUser>>>;
  total?: Maybe<Scalars['Int']>;
};

export type AdminPagesFilter = {
  searchable?: Maybe<Scalars['Boolean']>;
  edited?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['Int']>>>;
  categoryTags?: Maybe<Array<Maybe<Scalars['Int']>>>;
  searchString?: Maybe<Scalars['String']>;
};

export type AdminPageList = {
  pages?: Maybe<Array<Maybe<AdminPage>>>;
  meta?: Maybe<AdminListMeta>;
};

export type AdminListMeta = {
  total?: Maybe<Scalars['Int']>;
};

export type BookingSearchOps = {
  bookerName?: Maybe<Scalars['Boolean']>;
  bookerEmail?: Maybe<Scalars['Boolean']>;
  pageName?: Maybe<Scalars['Boolean']>;
  pageUserName?: Maybe<Scalars['Boolean']>;
  pageEmail?: Maybe<Scalars['Boolean']>;
  gigName?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Array<BookingStatusEnum>>;
  id?: Maybe<Scalars['Boolean']>;
  pageId?: Maybe<Scalars['Boolean']>;
  created?: Maybe<DateRange>;
  signed?: Maybe<DateRange>;
  playDates?: Maybe<DateRange>;
};

export type DateRange = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type AdminBookings = {
  bookings?: Maybe<Array<Maybe<Booking>>>;
  meta?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};

export type AdminBookingPaymentInfo = {
  paid?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type AdminGigFeed = {
  total?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<AdminGig>>>;
};

export type AdminGig = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  deadline?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  placeData?: Maybe<Scalars['Object']>;
  price?: Maybe<Scalars['BigInt']>;
  createdDate?: Maybe<Scalars['String']>;
  dateModified?: Maybe<Scalars['String']>;
  type?: Maybe<GigType>;
  details?: Maybe<Scalars['String']>;
  slots?: Maybe<Array<Maybe<GigSlot>>>;
  images?: Maybe<Array<Maybe<Image>>>;
  supplies?: Maybe<Array<Maybe<SupplyTypes>>>;
};

export type AdminHome = {
  daysBookings?: Maybe<Array<Maybe<Booking>>>;
  weekBookings?: Maybe<Array<Maybe<Booking>>>;
  monthBookings?: Maybe<Array<Maybe<Booking>>>;
  yearBookings?: Maybe<Array<Maybe<Booking>>>;
  currentYearBookings?: Maybe<Array<Maybe<Booking>>>;
  daysPages?: Maybe<Array<Maybe<Page>>>;
  weekPages?: Maybe<Array<Maybe<Page>>>;
  monthPages?: Maybe<Array<Maybe<Page>>>;
  yearPages?: Maybe<Array<Maybe<Page>>>;
  currentYearPages?: Maybe<Array<Maybe<Page>>>;
  daysUsers?: Maybe<Array<Maybe<User>>>;
  weekUsers?: Maybe<Array<Maybe<User>>>;
  monthUsers?: Maybe<Array<Maybe<User>>>;
  yearUsers?: Maybe<Array<Maybe<User>>>;
  currentYearUsers?: Maybe<Array<Maybe<User>>>;
  daysGigs?: Maybe<Array<Maybe<Gig>>>;
  weekGigs?: Maybe<Array<Maybe<Gig>>>;
  monthGigs?: Maybe<Array<Maybe<Gig>>>;
  yearGigs?: Maybe<Array<Maybe<Gig>>>;
  currentYearGigs?: Maybe<Array<Maybe<Gig>>>;
  admins?: Maybe<Array<Maybe<AdminUser>>>;
  loggedIn?: Maybe<Array<Maybe<User>>>;
};

export type Event = {
  name?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Object']>;
};

export type EventLogsFilter = {
  name?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  initiator?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  refId?: Maybe<Scalars['Int']>;
};

export type LoggedEventPage = {
  events?: Maybe<Array<Maybe<LoggedEvent>>>;
  count?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type LoggedEvent = {
  name?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  initiator?: Maybe<User>;
  level?: Maybe<Scalars['Int']>;
  entityRefId?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['Object']>;
  createdDate?: Maybe<Scalars['Date']>;
};

export type ContentfulData = {
  contentfulData?: Maybe<Scalars['Object']>;
  artistLists?: Maybe<Array<Maybe<ContentfulArtistList>>>;
  artistHeros?: Maybe<Array<Maybe<ContentfulArtistItem>>>;
};

export type ContentfulArtistList = {
  listId?: Maybe<Scalars['String']>;
  pages?: Maybe<Array<ContentfulArtistItem>>;
};

export type ContentfulArtistItem = {
  page?: Maybe<Page>;
  customDescription?: Maybe<Scalars['String']>;
};

export type Motd = {
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  level?: Maybe<MotdLevelEnum>;
  createdBy?: Maybe<User>;
  endDate?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export enum MotdLevelEnum {
  Info = 'INFO',
  Warning = 'WARNING',
  Critical = 'CRITICAL',
  Success = 'SUCCESS'
}

export type PageFaqTemplatesFeed = {
  templates?: Maybe<Array<Maybe<PageFaqQuestionTemplate>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PageFaqQuestionTemplate = {
  id?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<Maybe<IntlPageFaqQuestionTemplate>>>;
};

export type IntlPageFaqQuestionTemplate = {
  lang?: Maybe<CountriesEnum>;
  question?: Maybe<Scalars['String']>;
};

export type AdminReviewFeed = {
  reviews?: Maybe<Array<Maybe<Review>>>;
  skip?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type Invite = {
  id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  artistReview?: Maybe<ArtistReview>;
  claimed: Scalars['Boolean'];
  createdDate?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['Date']>;
  completed?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type InviteFeed = {
  total?: Maybe<Scalars['Int']>;
  invites?: Maybe<Array<Maybe<Invite>>>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
};

export type AdminInviteFeed = {
  edges?: Maybe<Array<Maybe<AdminInvite>>>;
  skip?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['Int']>;
};

export type InviteSearch = {
  query: Scalars['String'];
  userName?: Maybe<Scalars['Boolean']>;
  userEmail?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['Boolean']>;
  invitedEmail?: Maybe<Scalars['Boolean']>;
};

export type AdminOrganization = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<AdminUser>>>;
  bookingFee?: Maybe<Scalars['Float']>;
  artistFee?: Maybe<Scalars['Float']>;
  emailInvitePatterns?: Maybe<Array<Maybe<Scalars['String']>>>;
  gigTemplates?: Maybe<Array<Maybe<GigTemplate>>>;
  blogTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type OrganizationsFeed = {
  organizations?: Maybe<Array<Maybe<AdminOrganization>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  hello?: Maybe<Scalars['String']>;
  addUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  addBillingToUser?: Maybe<User>;
  createPage?: Maybe<Page>;
  createPageFromSocialMedia?: Maybe<Page>;
  updatePage?: Maybe<Page>;
  sendPageForReview?: Maybe<Page>;
  updatePageImages?: Maybe<Page>;
  updatePageBasics?: Maybe<Page>;
  updatePageTags?: Maybe<Page>;
  updatePageMedia?: Maybe<Page>;
  updatePageFaq?: Maybe<Page>;
  updatePageGallery?: Maybe<Page>;
  updatePageWithSocialMedia?: Maybe<Page>;
  setPageArchived?: Maybe<Page>;
  setNotificationsRead?: Maybe<Array<Maybe<Notification>>>;
  createGig?: Maybe<Gig>;
  deleteGig?: Maybe<Gig>;
  updateGig?: Maybe<Gig>;
  applyToGig?: Maybe<Booking>;
  denyApplication?: Maybe<Booking>;
  cancelApplication?: Maybe<Booking>;
  acceptApplications?: Maybe<Array<Maybe<GigSlot>>>;
  editApplication?: Maybe<Booking>;
  postMessage?: Maybe<ChannelMessage>;
  inviteUserToChannel?: Maybe<ChannelInvitation>;
  inviteExternalToChannel?: Maybe<ChannelInvitation>;
  cancelInvitationToChannel?: Maybe<ChannelInvitation>;
  declineInvitationToChannel?: Maybe<ChannelInvitation>;
  acceptInvitationToChannel?: Maybe<ChannelInvitation>;
  createBooking?: Maybe<Booking>;
  updateBooking?: Maybe<Booking>;
  transferBookingOwnership?: Maybe<Booking>;
  signBooking?: Maybe<Booking>;
  cancelBooking?: Maybe<Booking>;
  declineBooking?: Maybe<Booking>;
  addBookingComponent?: Maybe<Booking>;
  acceptBookingDates?: Maybe<Booking>;
  cancelBookingDates?: Maybe<Booking>;
  cacheFlush?: Maybe<CacheInfo>;
  deleteCacheKey?: Maybe<CacheInfo>;
  createNotification?: Maybe<Notification>;
  updateUserFee?: Maybe<AdminUser>;
  adminUpdateUserType?: Maybe<AdminUser>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  getDownloadableData?: Maybe<AdminUser>;
  updateBilling?: Maybe<Billing>;
  deleteBilling?: Maybe<Billing>;
  setPageReviewStatus?: Maybe<Page>;
  createPageList?: Maybe<PageList>;
  updatePageList?: Maybe<PageList>;
  updateOrder?: Maybe<Array<Maybe<Page>>>;
  updatePageFee?: Maybe<Page>;
  setPageOwnership?: Maybe<Page>;
  adminUpdateBookingStatus?: Maybe<Booking>;
  adminCancelBooking?: Maybe<Booking>;
  adminRemoveBookingDates?: Maybe<Booking>;
  adminUpdateBookingPrices?: Maybe<Booking>;
  addMOTD?: Maybe<Motd>;
  setMOTDActive?: Maybe<Motd>;
  sendEvent?: Maybe<EventSendResult>;
  addPageFaqQuestionTemplate?: Maybe<PageFaqQuestionTemplate>;
  updatePageFaqQuestionTemplate?: Maybe<PageFaqQuestionTemplate>;
  deletePageFaqQuestionTemplate?: Maybe<Scalars['Boolean']>;
  imageUpload?: Maybe<Image>;
  editReview?: Maybe<ArtistReview>;
  leaveReview?: Maybe<ArtistReview>;
  adminEditReviews?: Maybe<Array<Maybe<Review>>>;
  addReviewInvite?: Maybe<Invite>;
  leaveExternalReview?: Maybe<Invite>;
  claimInvite?: Maybe<Invite>;
  deleteInvite?: Maybe<Invite>;
  adminCreateOrg?: Maybe<AdminOrganization>;
  adminAddOrgEmailPattern?: Maybe<AdminOrganization>;
  adminRemoveOrgEmailPattern?: Maybe<AdminOrganization>;
  adminAddBlogTag?: Maybe<AdminOrganization>;
  adminRemoveBlogTag?: Maybe<AdminOrganization>;
  adminRemoveUserFromOrganization?: Maybe<AdminOrganization>;
  adminAddUserToOrg?: Maybe<AdminOrganization>;
  adminEditOrg?: Maybe<AdminOrganization>;
  adminAddGigTemplateToOrg?: Maybe<AdminOrganization>;
  adminUpdateGigTemplate?: Maybe<GigTemplate>;
  createSetupIntent?: Maybe<Scalars['String']>;
  removePaymentMethod?: Maybe<Scalars['Boolean']>;
};


export type MutationAddUserArgs = {
  user: RegisterUser;
};


export type MutationUpdateUserArgs = {
  user: EditUser;
};


export type MutationAddBillingToUserArgs = {
  billing: BillingInput;
};


export type MutationCreatePageArgs = {
  page: PageInput;
};


export type MutationCreatePageFromSocialMediaArgs = {
  page: SocialMediaAccountDataInput;
};


export type MutationUpdatePageArgs = {
  page: PageInput;
};


export type MutationSendPageForReviewArgs = {
  pageId: Scalars['Int'];
};


export type MutationUpdatePageImagesArgs = {
  pageId: Scalars['Int'];
  profilePicture?: Maybe<ImageInput>;
  backgroundPicture?: Maybe<ImageInput>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  backgroundPictureUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdatePageBasicsArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
  category: PageCategory;
  placeId: Scalars['String'];
  canTravel: Scalars['Boolean'];
  price: Scalars['Int'];
  presentation: Scalars['String'];
  hasEquipment: Scalars['Boolean'];
};


export type MutationUpdatePageTagsArgs = {
  pageId: Scalars['Int'];
  tagIds: Array<Scalars['Int']>;
};


export type MutationUpdatePageMediaArgs = {
  pageId: Scalars['Int'];
  links: Array<LinkInput>;
};


export type MutationUpdatePageFaqArgs = {
  pageId: Scalars['Int'];
  faq: Array<FaqInput>;
};


export type MutationUpdatePageGalleryArgs = {
  pageId: Scalars['Int'];
  images: Array<ImageInputWithOrder>;
};


export type MutationUpdatePageWithSocialMediaArgs = {
  pageId: Scalars['Int'];
  socialMediaData: SocialMediaAccountDataInput;
};


export type MutationSetPageArchivedArgs = {
  pageId: Scalars['Int'];
  archived: Scalars['Boolean'];
};


export type MutationSetNotificationsReadArgs = {
  notificationIds?: Maybe<Array<Scalars['Int']>>;
  all?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateGigArgs = {
  gig: GigInput;
};


export type MutationDeleteGigArgs = {
  gigId: Scalars['Int'];
  deleted: Scalars['Boolean'];
};


export type MutationUpdateGigArgs = {
  gig: GigInput;
};


export type MutationApplyToGigArgs = {
  application: GigApply;
};


export type MutationDenyApplicationArgs = {
  applicationId: Scalars['Int'];
  slotId: Scalars['Int'];
};


export type MutationCancelApplicationArgs = {
  applicationId: Scalars['Int'];
  dateId: Scalars['Int'];
};


export type MutationAcceptApplicationsArgs = {
  accepts: Array<AcceptApplicationInput>;
};


export type MutationEditApplicationArgs = {
  applicationId: Scalars['Int'];
  price?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};


export type MutationPostMessageArgs = {
  message: MessageInput;
};


export type MutationInviteUserToChannelArgs = {
  channelId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationInviteExternalToChannelArgs = {
  channelId?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
};


export type MutationCancelInvitationToChannelArgs = {
  channelId: Scalars['Int'];
  invitationId: Scalars['Int'];
};


export type MutationDeclineInvitationToChannelArgs = {
  channelId: Scalars['Int'];
  invitationId: Scalars['Int'];
};


export type MutationAcceptInvitationToChannelArgs = {
  channelId: Scalars['Int'];
  invitationId: Scalars['Int'];
};


export type MutationCreateBookingArgs = {
  booking: BookingInput;
};


export type MutationUpdateBookingArgs = {
  booking: BookingInput;
  bookingId: Scalars['Int'];
};


export type MutationTransferBookingOwnershipArgs = {
  bookingId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationSignBookingArgs = {
  bookingId: Scalars['Int'];
};


export type MutationCancelBookingArgs = {
  cancelReason: CancelReason;
  message?: Maybe<Scalars['String']>;
  bookingId: Scalars['Int'];
};


export type MutationDeclineBookingArgs = {
  cancelReason: CancelReason;
  message?: Maybe<Scalars['String']>;
  bookingId: Scalars['Int'];
};


export type MutationAddBookingComponentArgs = {
  bookingId: Scalars['Int'];
  component: BookingComponentItemType;
  data: Scalars['String'];
};


export type MutationAcceptBookingDatesArgs = {
  accepts?: Maybe<Array<Scalars['Int']>>;
  declines?: Maybe<Array<Scalars['Int']>>;
};


export type MutationCancelBookingDatesArgs = {
  dateIds?: Maybe<Array<Scalars['Int']>>;
  isCancelling: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
};


export type MutationDeleteCacheKeyArgs = {
  key?: Maybe<Scalars['String']>;
};


export type MutationCreateNotificationArgs = {
  notification: CreateNotificationInput;
};


export type MutationUpdateUserFeeArgs = {
  userId: Scalars['Int'];
  fee: Scalars['Float'];
};


export type MutationAdminUpdateUserTypeArgs = {
  userId: Scalars['Int'];
  type: UserType;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['Int'];
};


export type MutationGetDownloadableDataArgs = {
  userId: Scalars['Int'];
};


export type MutationUpdateBillingArgs = {
  billing: BillingInput;
};


export type MutationDeleteBillingArgs = {
  id: Scalars['Int'];
};


export type MutationSetPageReviewStatusArgs = {
  pageId: Scalars['Int'];
  status: ReviewStatusEnum;
  reasons?: Maybe<Array<DenyReasonInput>>;
};


export type MutationCreatePageListArgs = {
  list?: Maybe<PageListInput>;
};


export type MutationUpdatePageListArgs = {
  listId: Scalars['Int'];
  list?: Maybe<PageListInput>;
};


export type MutationUpdateOrderArgs = {
  orders?: Maybe<Array<Maybe<OrderUpdateInput>>>;
};


export type MutationUpdatePageFeeArgs = {
  pageId: Scalars['Int'];
  fee: Scalars['Float'];
};


export type MutationSetPageOwnershipArgs = {
  pageId: Scalars['Int'];
  ownership?: Maybe<PageOwnership>;
};


export type MutationAdminUpdateBookingStatusArgs = {
  bookingId: Scalars['Int'];
  status: BookingStatusEnum;
};


export type MutationAdminCancelBookingArgs = {
  bookingId: Scalars['Int'];
};


export type MutationAdminRemoveBookingDatesArgs = {
  dateIds: Array<Scalars['Int']>;
};


export type MutationAdminUpdateBookingPricesArgs = {
  id: Scalars['Int'];
  dates?: Maybe<Array<AdminBookingDatePrice>>;
  travel?: Maybe<Scalars['Int']>;
  supplies?: Maybe<Scalars['Int']>;
};


export type MutationAddMotdArgs = {
  message: Scalars['String'];
  severity: MotdLevelEnum;
  endDate?: Maybe<Scalars['String']>;
};


export type MutationSetMotdActiveArgs = {
  motdId: Scalars['Int'];
  active: Scalars['Boolean'];
};


export type MutationSendEventArgs = {
  event?: Maybe<EventInput>;
  userId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
};


export type MutationAddPageFaqQuestionTemplateArgs = {
  question?: Maybe<PageFaqQuestionTemplateInput>;
};


export type MutationUpdatePageFaqQuestionTemplateArgs = {
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<PageFaqQuestionTemplateInput>;
};


export type MutationDeletePageFaqQuestionTemplateArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationImageUploadArgs = {
  image: Scalars['Upload'];
  cropData: ImageCropDataInput;
};


export type MutationEditReviewArgs = {
  reviewId: Scalars['Int'];
  input: ArtistReviewInput;
};


export type MutationLeaveReviewArgs = {
  artistId: Scalars['Int'];
  input?: Maybe<ArtistReviewInput>;
};


export type MutationAdminEditReviewsArgs = {
  reviews: EditReviewInput;
};


export type MutationAddReviewInviteArgs = {
  reviewInvite: ReviewInvite;
};


export type MutationLeaveExternalReviewArgs = {
  input: LeaveInviteReviewInput;
};


export type MutationClaimInviteArgs = {
  invitePublicId: Scalars['String'];
};


export type MutationDeleteInviteArgs = {
  inviteId: Scalars['Int'];
};


export type MutationAdminCreateOrgArgs = {
  orgName: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationAdminAddOrgEmailPatternArgs = {
  orgId: Scalars['Int'];
  pattern: Scalars['String'];
};


export type MutationAdminRemoveOrgEmailPatternArgs = {
  orgId: Scalars['Int'];
  pattern: Scalars['String'];
};


export type MutationAdminAddBlogTagArgs = {
  orgId: Scalars['Int'];
  tag: Scalars['String'];
};


export type MutationAdminRemoveBlogTagArgs = {
  orgId: Scalars['Int'];
  tag: Scalars['String'];
};


export type MutationAdminRemoveUserFromOrganizationArgs = {
  id: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationAdminAddUserToOrgArgs = {
  id: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationAdminEditOrgArgs = {
  id: Scalars['Int'];
  editInput: AdminOrgEditInput;
};


export type MutationAdminAddGigTemplateToOrgArgs = {
  id: Scalars['Int'];
  gig: AdminGigTemplateInput;
};


export type MutationAdminUpdateGigTemplateArgs = {
  id: Scalars['Int'];
  gig: AdminGigTemplateInput;
};


export type MutationRemovePaymentMethodArgs = {
  id: Scalars['String'];
};

export type RegisterUser = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  auth0id: Scalars['String'];
  social: Scalars['Boolean'];
  orgId?: Maybe<Scalars['String']>;
};

export type EditUser = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  receiveTransactional?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<CountriesEnum>;
  type?: Maybe<UserType>;
  companyName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
};

export type BillingInput = {
  id?: Maybe<Scalars['Int']>;
  orgId?: Maybe<Scalars['Int']>;
  coName: Scalars['String'];
  orgNumber: Scalars['String'];
  address: Scalars['String'];
  email: Scalars['String'];
  reference: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
};

export type PageInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['Int']>>;
  presentation?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  links?: Maybe<Array<LinkInput>>;
  profilePicture?: Maybe<ImageInput>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  backgroundPicture?: Maybe<ImageInput>;
  backgroundPictureUrl?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  ownership?: Maybe<PageOwnership>;
  category?: Maybe<PageCategory>;
  hasEquipment?: Maybe<Scalars['Boolean']>;
  canTravel?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['String']>;
};

export type LinkInput = {
  socialMediaId: Scalars['Int'];
  socialMediaTypeId: Scalars['Int'];
  url: Scalars['String'];
  isHighlight: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
};

export type ImageInput = {
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Upload']>;
  cropData?: Maybe<ImageCropDataInput>;
  delete?: Maybe<Scalars['Boolean']>;
};


export type ImageCropDataInput = {
  x1: Scalars['Float'];
  x2: Scalars['Float'];
  y1: Scalars['Float'];
  y2: Scalars['Float'];
};

export type SocialMediaAccountDataInput = {
  name: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  medias?: Maybe<Array<Maybe<SocialMediaAccountMediaInput>>>;
  platforms?: Maybe<Array<SocialMediaAccountPlatformInput>>;
};

export type SocialMediaAccountMediaInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<SocialMediaAccountType>;
};

export type SocialMediaAccountPlatformInput = {
  id: Scalars['String'];
  type: SocialMediaAccountType;
  userName?: Maybe<Scalars['String']>;
};

export type FaqInput = {
  lang: Scalars['String'];
  question: Scalars['String'];
  answer: Scalars['String'];
};

export type ImageInputWithOrder = {
  image: ImageInput;
  order?: Maybe<Scalars['Int']>;
};

export type GigInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  placeId: Scalars['String'];
  slots?: Maybe<Array<GigSlotInput>>;
  type: GigType;
  details?: Maybe<Scalars['String']>;
  deadline?: Maybe<GigDeadlineEnum>;
  userId?: Maybe<Scalars['Int']>;
  delete?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<ImageInput>>;
  oldImages?: Maybe<Array<Scalars['Int']>>;
  supplies?: Maybe<Array<SupplyTypes>>;
  isRequestingSupplies?: Maybe<Scalars['Boolean']>;
};

export type GigSlotInput = {
  id?: Maybe<Scalars['Int']>;
  tags: Array<Scalars['Int']>;
  date: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  delete?: Maybe<Scalars['Boolean']>;
  nullPrice?: Maybe<Scalars['Boolean']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type GigApply = {
  pageId: Scalars['Int'];
  message: Scalars['String'];
  slots: Array<ApplySlot>;
};

export type ApplySlot = {
  id: Scalars['Int'];
  price: Scalars['Int'];
};

export type AcceptApplicationInput = {
  id: Scalars['Int'];
  dates: Array<ApplySlot>;
};

export type MessageInput = {
  channelId: Scalars['Int'];
  body: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
  existingFileId?: Maybe<Scalars['Int']>;
  fileExtension?: Maybe<Scalars['String']>;
};

export type BookingInput = {
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['Int']>;
  type?: Maybe<GigType>;
  price?: Maybe<BookingPriceInput>;
  dates?: Maybe<Array<BookingDateInput>>;
  dateDescription?: Maybe<Scalars['String']>;
  others?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  billingId?: Maybe<Scalars['Int']>;
  supplyStatus?: Maybe<EquipmentStatus>;
  supplyPrice?: Maybe<Scalars['Int']>;
  travelPrice?: Maybe<Scalars['Int']>;
  supplies?: Maybe<Array<SupplyTypes>>;
};

export type BookingPriceInput = {
  value: Scalars['Int'];
  currency: Currency;
};

export type BookingDateInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  soundCheck?: Maybe<Scalars['String']>;
  price?: Maybe<BookingPriceInput>;
  description?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export enum CancelReason {
  NotAvailable = 'NOT_AVAILABLE',
  BadFit = 'BAD_FIT',
  Other = 'OTHER',
  Cancelled = 'CANCELLED',
  BookedOtherAct = 'BOOKED_OTHER_ACT',
  Health = 'HEALTH',
  NoChoice = 'NO_CHOICE'
}

export type CreateNotificationInput = {
  senderUserId: Scalars['Int'];
  receivingUserId: Scalars['Int'];
  meta: Scalars['String'];
  pageId?: Maybe<Scalars['Int']>;
};

export type DenyReasonInput = {
  title: Scalars['String'];
  extra: Scalars['String'];
};

export type PageListInput = {
  name?: Maybe<Scalars['String']>;
  pageIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  active?: Maybe<Scalars['Boolean']>;
  gigType?: Maybe<GigType>;
  country?: Maybe<CountriesEnum>;
  description?: Maybe<Scalars['String']>;
};

export type OrderUpdateInput = {
  pageId: Scalars['Int'];
  order: Scalars['Int'];
};

export type AdminBookingDatePrice = {
  id: Scalars['Int'];
  price: Scalars['Int'];
};

export type EventInput = {
  name: Scalars['String'];
  metadata: Scalars['Object'];
};

export type EventSendResult = {
  success?: Maybe<Scalars['Boolean']>;
  event?: Maybe<Event>;
};

export type PageFaqQuestionTemplateInput = {
  question: Array<IntlPageFaqQuestionTemplateInput>;
};

export type IntlPageFaqQuestionTemplateInput = {
  lang: CountriesEnum;
  question: Scalars['String'];
};

export type ArtistReviewInput = {
  message?: Maybe<Scalars['String']>;
  recommended: Scalars['Boolean'];
};

export type EditReviewInput = {
  canUsePublic: Scalars['Boolean'];
  reviews: Array<ReviewInput>;
};

export type ReviewInput = {
  id: Scalars['Int'];
  reviewer?: Maybe<Reviewer>;
  recommended: Scalars['Boolean'];
  message: Scalars['String'];
  gigType: GigType;
  status?: Maybe<GigReviewStatus>;
};

export type Reviewer = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export type ReviewInvite = {
  pageId: Scalars['Int'];
  email: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type LeaveInviteReviewInput = {
  publicId: Scalars['String'];
  recommended: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
};

export type AdminOrgEditInput = {
  name?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  bookingFee?: Maybe<Scalars['Float']>;
  artistFee?: Maybe<Scalars['Float']>;
};

export type AdminGigTemplateInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  defaultPrice?: Maybe<Scalars['Int']>;
  type?: Maybe<GigType>;
  hidden?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['Int']>>;
};

export type Subscription = {
  hello?: Maybe<Scalars['String']>;
  channelMessages?: Maybe<ChannelMessage>;
  notification?: Maybe<Notification>;
  bookingChanges?: Maybe<Booking>;
};


export type SubscriptionHelloArgs = {
  id: Scalars['Int'];
};


export type SubscriptionChannelMessagesArgs = {
  channelId?: Maybe<Scalars['Int']>;
};


export type SubscriptionBookingChangesArgs = {
  bookingId: Scalars['Int'];
};

export type SocialMediaAccountMedia = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<SocialMediaAccountType>;
};

export type File = {
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export enum GigCreateType {
  Default = 'DEFAULT',
  Template = 'TEMPLATE'
}

export enum BotMessageType {
  FileAttached = 'FILE_ATTACHED',
  ComponentAdded = 'COMPONENT_ADDED',
  ComponentRemoved = 'COMPONENT_REMOVED',
  ComponentModified = 'COMPONENT_MODIFIED',
  ComponentSigned = 'COMPONENT_SIGNED',
  ContractSigned = 'CONTRACT_SIGNED',
  ContractDeclinedArtist = 'CONTRACT_DECLINED_ARTIST',
  ContractDeclinedBooker = 'CONTRACT_DECLINED_BOOKER',
  TravelPriceChanged = 'TRAVEL_PRICE_CHANGED',
  SuppliesRequest = 'SUPPLIES_REQUEST',
  SuppliesPriceChange = 'SUPPLIES_PRICE_CHANGE',
  SuppliesSigned = 'SUPPLIES_SIGNED'
}

export type BotSenderUser = {
  userId: Scalars['Int'];
  fullName: Scalars['String'];
};

export type BotSenderPage = {
  pageId: Scalars['Int'];
  pageName: Scalars['String'];
};

export type BotMessage = {
  type: BotMessageType;
  isPage: Scalars['Boolean'];
  sender: BotSenderUser;
  page?: Maybe<BotSenderPage>;
  componentType?: Maybe<BookingComponentItemType>;
};

export type RealtimeConnection = {
  userSub?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ExternalBookerInput = {
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AddMotdMutationVariables = Exact<{
  message: Scalars['String'];
  severity: MotdLevelEnum;
  endDate?: Maybe<Scalars['String']>;
}>;


export type AddMotdMutation = { addMOTD?: Maybe<MotdFieldsFragment> };

export type AddPageFaqQuestionTemplateMutationVariables = Exact<{
  question?: Maybe<PageFaqQuestionTemplateInput>;
}>;


export type AddPageFaqQuestionTemplateMutation = { addPageFaqQuestionTemplate?: Maybe<{ questions?: Maybe<Array<Maybe<Pick<IntlPageFaqQuestionTemplate, 'lang' | 'question'>>>> }> };

export type AdminAddBlogTagMutationVariables = Exact<{
  orgId: Scalars['Int'];
  tag: Scalars['String'];
}>;


export type AdminAddBlogTagMutation = { adminAddBlogTag?: Maybe<AdminOrganizationFieldsFragment> };

export type AdminAddGigTemplateToOrgMutationVariables = Exact<{
  id: Scalars['Int'];
  gig: AdminGigTemplateInput;
}>;


export type AdminAddGigTemplateToOrgMutation = { adminAddGigTemplateToOrg?: Maybe<AdminOrganizationFieldsFragment> };

export type AdminAddOrgEmailPatternMutationVariables = Exact<{
  orgId: Scalars['Int'];
  pattern: Scalars['String'];
}>;


export type AdminAddOrgEmailPatternMutation = { adminAddOrgEmailPattern?: Maybe<AdminOrganizationFieldsFragment> };

export type AdminAddUserToOrgMutationVariables = Exact<{
  id: Scalars['Int'];
  userId: Scalars['Int'];
}>;


export type AdminAddUserToOrgMutation = { adminAddUserToOrg?: Maybe<AdminOrganizationFieldsFragment> };

export type AdminCancelBookingMutationVariables = Exact<{
  bookingId: Scalars['Int'];
}>;


export type AdminCancelBookingMutation = { adminCancelBooking?: Maybe<BookingFieldsFragment> };

export type AdminCreateOrganizatonMutationVariables = Exact<{
  orgName: Scalars['String'];
  orgId: Scalars['String'];
}>;


export type AdminCreateOrganizatonMutation = { adminCreateOrg?: Maybe<AdminOrganizationFieldsFragment> };

export type AdminGetReviewByIdQueryVariables = Exact<{
  reviewId: Scalars['Int'];
}>;


export type AdminGetReviewByIdQuery = { adminReviewById?: Maybe<(
    Pick<Review, 'id' | 'recommended' | 'message' | 'playDate' | 'reviewDate'>
    & { bookingDate?: Maybe<(
      Pick<BookingDates, 'price' | 'priceCurrency' | 'date'>
      & { booking?: Maybe<Pick<Booking, 'id'>> }
    )>, page?: Maybe<(
      Pick<Page, 'id' | 'name'>
      & { profilePicture?: Maybe<ImageFieldsFragment> }
    )>, user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>>, reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'company' | 'email'>> }
  )> };

export type GetAdminsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminsQuery = { admins?: Maybe<Array<Maybe<AdminUserFieldsFragment>>> };

export type AdminGetBookingPaymentStatusQueryVariables = Exact<{
  bookingId: Scalars['Int'];
}>;


export type AdminGetBookingPaymentStatusQuery = { adminGetBookingPaymentStatus?: Maybe<Pick<AdminBookingPaymentInfo, 'paid' | 'status'>> };

export type AdminGetBookingsByPageQueryVariables = Exact<{
  pageId: Scalars['Int'];
}>;


export type AdminGetBookingsByPageQuery = { adminGetBookingsByPage?: Maybe<Array<Maybe<BookingFieldsFragment>>> };

export type GetExistingPagePlacesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExistingPagePlacesQuery = { getExistingPagePlaces?: Maybe<Array<Maybe<Pick<Tag, 'id' | 'name' | 'group'>>>> };

export type AdminGetGigsQueryVariables = Exact<{
  paging: Pagination;
}>;


export type AdminGetGigsQuery = { adminGetGigs?: Maybe<(
    Pick<AdminGigFeed, 'total'>
    & { edges?: Maybe<Array<Maybe<AdminGigFieldsFragment>>> }
  )> };

export type AdminFindOneInviteQueryVariables = Exact<{
  publicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
}>;


export type AdminFindOneInviteQuery = { adminFindOneInvite: (
    Pick<AdminInvite, 'id' | 'publicId' | 'email'>
    & { inviterUser?: Maybe<UserFieldsFragment>, acceptedUser?: Maybe<UserFieldsFragment>, review?: Maybe<(
      Pick<Review, 'id' | 'message' | 'recommended' | 'canUsePublic'>
      & { reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'company' | 'email'>> }
    )> }
  ) };

export type AdminGetOrganizationsQueryVariables = Exact<{
  paging: Pagination;
}>;


export type AdminGetOrganizationsQuery = { adminFindAllOrgs?: Maybe<(
    Pick<OrganizationsFeed, 'total'>
    & { organizations?: Maybe<Array<Maybe<AdminOrganizationFieldsFragment>>> }
  )> };

export type AdminGetReviewsQueryVariables = Exact<{
  onlyReviewed?: Maybe<Scalars['Boolean']>;
  recommended: Scalars['Boolean'];
  paging: Pagination;
}>;


export type AdminGetReviewsQuery = { adminReviews?: Maybe<(
    Pick<AdminReviewFeed, 'skip' | 'total' | 'hasNextPage'>
    & { reviews?: Maybe<Array<Maybe<(
      Pick<Review, 'id' | 'recommended' | 'message' | 'playDate' | 'reviewDate'>
      & { bookingDate?: Maybe<(
        Pick<BookingDates, 'price' | 'priceCurrency' | 'date'>
        & { booking?: Maybe<Pick<Booking, 'id'>> }
      )>, page?: Maybe<(
        Pick<Page, 'id' | 'name'>
        & { profilePicture?: Maybe<ImageFieldsFragment> }
      )>, user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>>, reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'company' | 'email'>> }
    )>>> }
  )> };

export type AdminGetUserByIdQueryVariables = Exact<{
  userId?: Maybe<Scalars['Int']>;
}>;


export type AdminGetUserByIdQuery = { byId?: Maybe<(
    { bookings?: Maybe<Array<Maybe<(
      Pick<Booking, 'id' | 'type' | 'travelPrice'>
      & { page?: Maybe<(
        Pick<Page, 'id' | 'name'>
        & { user?: Maybe<Pick<User, 'email'>> }
      )>, calculatedPricing?: Maybe<BookingCalculatedPricingFragment> }
    )>>>, sentInvites?: Maybe<Array<Maybe<Pick<AdminInvite, 'id' | 'email' | 'publicId' | 'claimed'>>>>, claimedInvites?: Maybe<Array<Maybe<Pick<AdminInvite, 'id' | 'email' | 'publicId' | 'claimed'>>>>, reviews?: Maybe<Array<Maybe<(
      Pick<Review, 'id' | 'recommended' | 'reviewDate' | 'playDate' | 'message'>
      & { page?: Maybe<Pick<Page, 'id' | 'name'>> }
    )>>> }
    & AdminUserFieldsFragment
  )> };

export type AdminGetUsersQueryVariables = Exact<{
  paging?: Maybe<Pagination>;
  sorting?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  searchString?: Maybe<Scalars['String']>;
}>;


export type AdminGetUsersQuery = { allUsers?: Maybe<(
    Pick<AdminGetAllUsers, 'total'>
    & { users?: Maybe<Array<Maybe<Pick<AdminUser, 'id' | 'firstName' | 'lastName' | 'email' | 'companyName' | 'createdDate' | 'dateModified' | 'fee' | 'phoneNumber' | 'phonePrefix'>>>> }
  )> };

export type AdminGigByIdQueryVariables = Exact<{
  gigId: Scalars['Int'];
}>;


export type AdminGigByIdQuery = { adminGigById?: Maybe<AdminGigFieldsFragment> };

export type AdminInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminInfoQuery = { adminInfo?: Maybe<{ daysBookings?: Maybe<Array<Maybe<Pick<Booking, 'id'>>>>, weekBookings?: Maybe<Array<Maybe<Pick<Booking, 'id'>>>>, monthBookings?: Maybe<Array<Maybe<Pick<Booking, 'id'>>>>, yearBookings?: Maybe<Array<Maybe<Pick<Booking, 'id'>>>>, currentYearBookings?: Maybe<Array<Maybe<Pick<Booking, 'id'>>>>, daysPages?: Maybe<Array<Maybe<Pick<Page, 'id'>>>>, weekPages?: Maybe<Array<Maybe<Pick<Page, 'id'>>>>, monthPages?: Maybe<Array<Maybe<Pick<Page, 'id'>>>>, yearPages?: Maybe<Array<Maybe<Pick<Page, 'id'>>>>, currentYearPages?: Maybe<Array<Maybe<Pick<Page, 'id'>>>>, daysUsers?: Maybe<Array<Maybe<Pick<User, 'id'>>>>, weekUsers?: Maybe<Array<Maybe<Pick<User, 'id'>>>>, monthUsers?: Maybe<Array<Maybe<Pick<User, 'id'>>>>, yearUsers?: Maybe<Array<Maybe<Pick<User, 'id'>>>>, currentYearUsers?: Maybe<Array<Maybe<Pick<User, 'id'>>>>, daysGigs?: Maybe<Array<Maybe<Pick<Gig, 'id'>>>>, weekGigs?: Maybe<Array<Maybe<Pick<Gig, 'id'>>>>, monthGigs?: Maybe<Array<Maybe<Pick<Gig, 'id'>>>>, yearGigs?: Maybe<Array<Maybe<Pick<Gig, 'id'>>>>, currentYearGigs?: Maybe<Array<Maybe<Pick<Gig, 'id'>>>>, loggedIn?: Maybe<Array<Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>>>>, admins?: Maybe<Array<Maybe<Pick<AdminUser, 'id' | 'firstName' | 'lastName' | 'email'>>>> }> };

export type AdminGetInvitesListQueryVariables = Exact<{
  paging: Pagination;
}>;


export type AdminGetInvitesListQuery = { adminGetInvites?: Maybe<(
    Pick<AdminInviteFeed, 'total' | 'hasNextPage' | 'skip'>
    & { edges?: Maybe<Array<Maybe<(
      Pick<AdminInvite, 'id' | 'publicId' | 'email' | 'claimed'>
      & { inviterUser?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>>, review?: Maybe<Pick<Review, 'id'>> }
    )>>> }
  )> };

export type GetLoggedInUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoggedInUsersQuery = { getLoggedInUsers?: Maybe<Array<Maybe<AdminUserFieldsFragment>>> };

export type AdminRemoveBlogTagMutationVariables = Exact<{
  orgId: Scalars['Int'];
  tag: Scalars['String'];
}>;


export type AdminRemoveBlogTagMutation = { adminRemoveBlogTag?: Maybe<AdminOrganizationFieldsFragment> };

export type AdminRemoveBookingDatesMutationVariables = Exact<{
  dateIds: Array<Scalars['Int']>;
}>;


export type AdminRemoveBookingDatesMutation = { adminRemoveBookingDates?: Maybe<BookingFieldsFragment> };

export type AdminRemoveOrgEmailPatternMutationVariables = Exact<{
  orgId: Scalars['Int'];
  pattern: Scalars['String'];
}>;


export type AdminRemoveOrgEmailPatternMutation = { adminRemoveOrgEmailPattern?: Maybe<AdminOrganizationFieldsFragment> };

export type AdminRemoveUserFromOrgMutationVariables = Exact<{
  id: Scalars['Int'];
  userId: Scalars['Int'];
}>;


export type AdminRemoveUserFromOrgMutation = { adminRemoveUserFromOrganization?: Maybe<AdminOrganizationFieldsFragment> };

export type AdminSearchBookingQueryVariables = Exact<{
  searchText: Scalars['String'];
  searchOps: BookingSearchOps;
  paging?: Maybe<Pagination>;
  sorting?: Maybe<Scalars['String']>;
}>;


export type AdminSearchBookingQuery = { adminSearchBookings?: Maybe<(
    Pick<AdminBookings, 'meta' | 'price'>
    & { bookings?: Maybe<Array<Maybe<(
      Pick<Booking, 'id' | 'status' | 'createdDate' | 'message' | 'supplies' | 'amountPaid' | 'supplyStatus' | 'supplyPrice' | 'travelPrice' | 'newTypes'>
      & { calculatedPricing?: Maybe<BookingCalculatedPricingFragment>, billingInfo?: Maybe<BillingFieldsFragment>, user?: Maybe<UserFieldsFragment>, page?: Maybe<(
        Pick<Page, 'fee'>
        & PageFieldsFragment
      )>, appliedTo?: Maybe<GigFieldsFragment>, channel?: Maybe<ChannelFieldsFragment>, dates?: Maybe<Array<Maybe<BookingDatesFieldsFragment>>>, place?: Maybe<Pick<BookingPlace, 'placeId' | 'formattedAddress' | 'components' | 'name'>>, others?: Maybe<Pick<BookingOthers, 'addedBy' | 'signedBy' | 'createdDate' | 'others'>> }
    )>>> }
  )> };

export type AdminSearchPageQueryVariables = Exact<{
  text: Scalars['String'];
}>;


export type AdminSearchPageQuery = { adminPageTextSearch?: Maybe<Array<Maybe<(
    Pick<Page, 'dateModified' | 'createdDate'>
    & PageFieldsFragment
  )>>> };

export type SearchReviewQueryVariables = Exact<{
  term: Scalars['String'];
}>;


export type SearchReviewQuery = { adminSearchReview?: Maybe<Array<Maybe<(
    Pick<Review, 'id' | 'recommended' | 'message'>
    & { bookingDate?: Maybe<(
      Pick<BookingDates, 'price' | 'priceCurrency' | 'date'>
      & { booking?: Maybe<Pick<Booking, 'id'>> }
    )>, page?: Maybe<(
      Pick<Page, 'id' | 'name'>
      & { profilePicture?: Maybe<ImageFieldsFragment> }
    )>, user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>> }
  )>>> };

export type AdminUpdateBookingPricesMutationVariables = Exact<{
  id: Scalars['Int'];
  dates?: Maybe<Array<AdminBookingDatePrice>>;
  travel: Scalars['Int'];
  supplies: Scalars['Int'];
}>;


export type AdminUpdateBookingPricesMutation = { adminUpdateBookingPrices?: Maybe<(
    Pick<Booking, 'id' | 'travelPrice' | 'supplyPrice'>
    & { dates?: Maybe<Array<Maybe<Pick<BookingDates, 'id' | 'price'>>>> }
  )> };

export type AdminUpdateGigTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
  gig: AdminGigTemplateInput;
}>;


export type AdminUpdateGigTemplateMutation = { adminUpdateGigTemplate?: Maybe<AdminGigTemplateFieldsFragment> };

export type AdminUpdateOrganizationMutationVariables = Exact<{
  id: Scalars['Int'];
  editInput: AdminOrgEditInput;
}>;


export type AdminUpdateOrganizationMutation = { adminEditOrg?: Maybe<AdminOrganizationFieldsFragment> };

export type AdminUserSearchQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;


export type AdminUserSearchQuery = { userSearch?: Maybe<Array<Maybe<(
    { bookings?: Maybe<Array<Maybe<(
      Pick<Booking, 'id' | 'type'>
      & { page?: Maybe<Pick<Page, 'id' | 'name'>> }
    )>>>, sentInvites?: Maybe<Array<Maybe<Pick<AdminInvite, 'id' | 'email' | 'publicId' | 'claimed'>>>>, claimedInvites?: Maybe<Array<Maybe<Pick<AdminInvite, 'id' | 'email' | 'publicId' | 'claimed'>>>>, reviews?: Maybe<Array<Maybe<(
      Pick<Review, 'id' | 'recommended' | 'playDate' | 'message'>
      & { page?: Maybe<Pick<Page, 'id' | 'name'>> }
    )>>> }
    & AdminUserFieldsFragment
  )>>> };

export type CreateListMutationVariables = Exact<{
  list?: Maybe<PageListInput>;
}>;


export type CreateListMutation = { createPageList?: Maybe<ListFieldsFragment> };

export type DeleteCacheKeyMutationVariables = Exact<{
  key?: Maybe<Scalars['String']>;
}>;


export type DeleteCacheKeyMutation = { deleteCacheKey?: Maybe<CacheFieldsFragment> };

export type DeletePageFaqTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePageFaqTemplateMutation = Pick<Mutation, 'deletePageFaqQuestionTemplate'>;

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type DeleteUserMutation = Pick<Mutation, 'deleteUser'>;

export type FlushCacheMutationVariables = Exact<{ [key: string]: never; }>;


export type FlushCacheMutation = { cacheFlush?: Maybe<CacheFieldsFragment> };

export type AdminGigFieldsFragment = (
  Pick<AdminGig, 'id' | 'name' | 'placeId' | 'deleted' | 'placeData' | 'createdDate' | 'dateModified' | 'details' | 'type' | 'supplies'>
  & { user?: Maybe<UserFieldsFragment>, images?: Maybe<Array<Maybe<ImageFieldsFragment>>>, slots?: Maybe<Array<Maybe<GigSlotFieldsFragment>>> }
);

export type AdminGigTemplateFieldsFragment = (
  Pick<GigTemplate, 'id' | 'name' | 'details' | 'type' | 'deadline' | 'defaultPrice' | 'hidden'>
  & { tags?: Maybe<Array<Maybe<Pick<Tag, 'id' | 'name'>>>>, user?: Maybe<UserFieldsFragment> }
);

export type AdminOrganizationFieldsFragment = (
  Pick<AdminOrganization, 'id' | 'name' | 'orgId' | 'bookingFee' | 'artistFee' | 'emailInvitePatterns' | 'blogTags'>
  & { users?: Maybe<Array<Maybe<(
    Pick<AdminUser, 'type'>
    & AdminUserFieldsFragment
  )>>>, gigTemplates?: Maybe<Array<Maybe<AdminGigTemplateFieldsFragment>>> }
);

export type AdminPageFieldsFragment = (
  Pick<AdminPage, 'id' | 'name' | 'presentation' | 'price' | 'pageStatus' | 'reviewStatus' | 'ownership' | 'order' | 'slug' | 'searchable' | 'positiveReviews' | 'negativeReviews' | 'createdDate' | 'dateModified'>
  & { user?: Maybe<UserFieldsFragment>, tags?: Maybe<Array<Maybe<TagFieldsFragment>>>, links?: Maybe<Array<Maybe<Pick<PageLink, 'id' | 'socialMediaId' | 'socialMediaTypeId' | 'url' | 'isHighlight'>>>>, profilePicture?: Maybe<ImageFieldsFragment>, backgroundPicture?: Maybe<ImageFieldsFragment>, denyReasons?: Maybe<Array<Maybe<Pick<DenyReason, 'title' | 'extra'>>>>, bookings?: Maybe<Array<Maybe<BookingFieldsFragment>>> }
);

export type AdminUserFieldsFragment = (
  Pick<AdminUser, 'id' | 'firstName' | 'lastName' | 'email' | 'complete' | 'newsletter' | 'isAdmin' | 'fee' | 'isSocial' | 'socialType' | 'createdDate' | 'dateModified' | 'type' | 'phoneNumber' | 'phonePrefix'>
  & { billings?: Maybe<Array<Maybe<(
    Pick<Billing, 'fee'>
    & BillingFieldsFragment
  )>>>, files?: Maybe<Array<Maybe<FileFieldsFragment>>>, bookings?: Maybe<Array<Maybe<BookingFieldsFragment>>>, gigs?: Maybe<Array<Maybe<GigFieldsFragment>>>, images?: Maybe<Array<Maybe<ImageFieldsFragment>>>, channels?: Maybe<Array<Maybe<ChannelFieldsFragment>>>, notifications?: Maybe<Array<Maybe<NotificationFieldsFragment>>>, pages?: Maybe<Array<Maybe<AdminPageFieldsFragment>>> }
);

export type ArtistAdminFieldsFragment = (
  Pick<AdminPage, 'dateModified' | 'createdDate'>
  & AdminPageFieldsFragment
);

export type CacheFieldsFragment = (
  Pick<CacheInfo, 'keys'>
  & { stats?: Maybe<Pick<CacheStats, 'keys' | 'hits' | 'misses' | 'ksize' | 'vsize'>> }
);

export type PageFaqQuestionTemplateFieldsFragment = (
  Pick<PageFaqQuestionTemplate, 'id'>
  & { questions?: Maybe<Array<Maybe<Pick<IntlPageFaqQuestionTemplate, 'lang' | 'question'>>>> }
);

export type GetAdminArtistsQueryVariables = Exact<{
  paging?: Maybe<Pagination>;
  sorting?: Maybe<Scalars['String']>;
  filter?: Maybe<AdminPagesFilter>;
}>;


export type GetAdminArtistsQuery = { getPagesAdmin?: Maybe<{ pages?: Maybe<Array<Maybe<(
      Pick<AdminPage, 'fee'>
      & ArtistAdminFieldsFragment
    )>>>, meta?: Maybe<Pick<AdminListMeta, 'total'>> }> };

export type GetCacheHealthQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCacheHealthQuery = { cacheHealth?: Maybe<CacheFieldsFragment> };

export type GetEventLogsQueryVariables = Exact<{
  filter?: Maybe<EventLogsFilter>;
  paging?: Maybe<Pagination>;
}>;


export type GetEventLogsQuery = { getLoggedEvents?: Maybe<(
    Pick<LoggedEventPage, 'count' | 'hasNextPage'>
    & { events?: Maybe<Array<Maybe<(
      Pick<LoggedEvent, 'name' | 'action' | 'level' | 'entityRefId' | 'data' | 'createdDate'>
      & { initiator?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
    )>>> }
  )> };

export type GetEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventsQuery = { getEvents?: Maybe<Array<Maybe<Pick<Event, 'name' | 'metadata'>>>> };

export type GetPageFaqTemplatesQueryVariables = Exact<{
  paging: Pagination;
}>;


export type GetPageFaqTemplatesQuery = { getPageFaqTemplates?: Maybe<(
    Pick<PageFaqTemplatesFeed, 'count'>
    & { templates?: Maybe<Array<Maybe<PageFaqQuestionTemplateFieldsFragment>>> }
  )> };

export type DownloadUserDataMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type DownloadUserDataMutation = { getDownloadableData?: Maybe<AdminUserFieldsFragment> };

export type SendEventMutationVariables = Exact<{
  event: EventInput;
  userId: Scalars['Int'];
  createdAt?: Maybe<Scalars['Date']>;
}>;


export type SendEventMutation = { sendEvent?: Maybe<(
    Pick<EventSendResult, 'success'>
    & { event?: Maybe<Pick<Event, 'name' | 'metadata'>> }
  )> };

export type SetMotdActiveMutationVariables = Exact<{
  motdId: Scalars['Int'];
  active: Scalars['Boolean'];
}>;


export type SetMotdActiveMutation = { setMOTDActive?: Maybe<MotdFieldsFragment> };

export type SetPageOwnershipMutationVariables = Exact<{
  pageId: Scalars['Int'];
  ownership: PageOwnership;
}>;


export type SetPageOwnershipMutation = { setPageOwnership?: Maybe<Pick<Page, 'id' | 'ownership'>> };

export type SetReviewStatusMutationVariables = Exact<{
  pageId: Scalars['Int'];
  status: ReviewStatusEnum;
  denyReasons?: Maybe<Array<DenyReasonInput>>;
}>;


export type SetReviewStatusMutation = { setPageReviewStatus?: Maybe<(
    Pick<Page, 'dateModified' | 'createdDate'>
    & PageFieldsFragment
  )> };

export type UpdateBookingStatusMutationVariables = Exact<{
  bookingId: Scalars['Int'];
  status: BookingStatusEnum;
}>;


export type UpdateBookingStatusMutation = { adminUpdateBookingStatus?: Maybe<Pick<Booking, 'id' | 'status'>> };

export type UpdatePageListMutationVariables = Exact<{
  listId: Scalars['Int'];
  list?: Maybe<PageListInput>;
}>;


export type UpdatePageListMutation = { updatePageList?: Maybe<ListFieldsFragment> };

export type UpdateOrderMutationVariables = Exact<{
  orders?: Maybe<Array<Maybe<OrderUpdateInput>>>;
}>;


export type UpdateOrderMutation = { updateOrder?: Maybe<Array<Maybe<PageFieldsFragment>>> };

export type UpdatePageFeeMutationVariables = Exact<{
  pageId: Scalars['Int'];
  fee: Scalars['Float'];
}>;


export type UpdatePageFeeMutation = { updatePageFee?: Maybe<Pick<Page, 'id' | 'fee'>> };

export type UpdateUserFeeMutationVariables = Exact<{
  userId: Scalars['Int'];
  fee: Scalars['Float'];
}>;


export type UpdateUserFeeMutation = { updateUserFee?: Maybe<Pick<AdminUser, 'id' | 'fee'>> };

export type UpdateUserTypeMutationVariables = Exact<{
  userId: Scalars['Int'];
  type: UserType;
}>;


export type UpdateUserTypeMutation = { adminUpdateUserType?: Maybe<Pick<AdminUser, 'id' | 'firstName' | 'lastName' | 'type'>> };

export type AcceptBookingDatesMutationVariables = Exact<{
  accepts?: Maybe<Array<Scalars['Int']>>;
  declines?: Maybe<Array<Scalars['Int']>>;
}>;


export type AcceptBookingDatesMutation = { acceptBookingDates?: Maybe<{ dates?: Maybe<Array<Maybe<BookingDatesFieldsFragment>>> }> };

export type CancelBookingMutationVariables = Exact<{
  cancelReason: CancelReason;
  message?: Maybe<Scalars['String']>;
  bookingId: Scalars['Int'];
}>;


export type CancelBookingMutation = { cancelBooking?: Maybe<(
    Pick<Booking, 'id' | 'status'>
    & { signatures?: Maybe<Array<Maybe<(
      Pick<Signature, 'id' | 'createdDate' | 'valid' | 'isBooker' | 'type'>
      & { user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
    )>>> }
  )> };

export type CancelBookingDatesMutationVariables = Exact<{
  dateIds?: Maybe<Array<Scalars['Int']>>;
  isCancelling: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
}>;


export type CancelBookingDatesMutation = { cancelBookingDates?: Maybe<{ dates?: Maybe<Array<Maybe<BookingDatesFieldsFragment>>> }> };

export type CreateBookingMutationVariables = Exact<{
  booking: BookingInput;
}>;


export type CreateBookingMutation = { createBooking?: Maybe<BookingFieldsFragment> };

export type DeclineBookingMutationVariables = Exact<{
  cancelReason: CancelReason;
  message?: Maybe<Scalars['String']>;
  bookingId: Scalars['Int'];
}>;


export type DeclineBookingMutation = { declineBooking?: Maybe<(
    Pick<Booking, 'id' | 'status'>
    & { signatures?: Maybe<Array<Maybe<(
      Pick<Signature, 'id' | 'createdDate' | 'valid' | 'isBooker' | 'type'>
      & { user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
    )>>> }
  )> };

export type BookingFieldsFragment = (
  Pick<Booking, 'id' | 'wantsInvoice' | 'amountPaid' | 'status' | 'message' | 'title' | 'type' | 'isBooker' | 'createdDate' | 'supplies' | 'supplyStatus' | 'supplyPrice' | 'travelPrice' | 'newTypes' | 'cancelledDaysBefore' | 'cancelledDate' | 'wasSignedWhenCancelled'>
  & { billingInfo?: Maybe<BillingFieldsFragment>, signatures?: Maybe<Array<Maybe<(
    Pick<Signature, 'id' | 'createdDate' | 'valid' | 'isBooker' | 'type'>
    & { user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
  )>>>, user?: Maybe<UserFieldsFragment>, page?: Maybe<(
    Pick<Page, 'id' | 'name' | 'slug' | 'fee' | 'ownership'>
    & { profilePicture?: Maybe<ImageFieldsFragment>, user?: Maybe<UserFieldsFragment> }
  )>, channel?: Maybe<ChannelFieldsFragment>, dates?: Maybe<Array<Maybe<BookingDatesFieldsFragment>>>, place?: Maybe<Pick<BookingPlace, 'placeId' | 'formattedAddress' | 'components' | 'name'>>, others?: Maybe<Pick<BookingOthers, 'addedBy' | 'signedBy' | 'createdDate' | 'others'>>, appliedTo?: Maybe<(
    Pick<Gig, 'deadline' | 'deleted' | 'details' | 'id' | 'name' | 'placeId' | 'type'>
    & { images?: Maybe<Array<Maybe<ImageFieldsFragment>>> }
  )>, calculatedPricing?: Maybe<BookingCalculatedPricingFragment>, cancelledBy?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>> }
);

export type BookingCalculatedPricingFragment = (
  Pick<CalculatedPricing, 'baseDates' | 'travel' | 'supplies' | 'currency'>
  & { artist?: Maybe<BookingPartyPricingFragment>, booker?: Maybe<BookingPartyPricingFragment> }
);

export type BookingDatesFieldsFragment = (
  Pick<BookingDates, 'id' | 'date' | 'soundCheckDate' | 'price' | 'priceCurrency' | 'description' | 'cancelledDate' | 'cancelledDaysBefore' | 'startTime' | 'endTime' | 'isAccepted'>
  & { cancelledBy?: Maybe<UserFieldsFragment>, addedBy?: Maybe<UserFieldsFragment>, gigSlot?: Maybe<Pick<GigSlot, 'id'>>, review?: Maybe<Pick<Review, 'id' | 'recommended' | 'message' | 'canUsePublic' | 'visible'>> }
);

export type BookingPartyPricingFragment = Pick<BookingPartyPricing, 'final' | 'fee' | 'feePrice' | 'penaltyFee' | 'penaltyTotal'>;

export type GetBookingByIdQueryVariables = Exact<{
  bookingId: Scalars['Int'];
}>;


export type GetBookingByIdQuery = { bookingById?: Maybe<BookingFieldsFragment> };

export type GetBookingsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBookingsCountQuery = { bookingsCount?: Maybe<Pick<BookingsCount, 'open' | 'closed' | 'signed' | 'others'>> };

export type GetDashboardBookingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardBookingsQuery = { dashboardBookings?: Maybe<(
    Pick<DashboardBookings, 'pendingCount' | 'upcomingCount'>
    & { pending?: Maybe<Array<Maybe<BookingFieldsFragment>>>, upcomingDates?: Maybe<Array<Maybe<(
      { booking?: Maybe<BookingFieldsFragment> }
      & BookingDatesFieldsFragment
    )>>> }
  )> };

export type GetUserBookingsQueryVariables = Exact<{
  type?: Maybe<BookingFeedType>;
}>;


export type GetUserBookingsQuery = { bookingsByUser?: Maybe<(
    Pick<BookingsByUser, 'openLength'>
    & { open?: Maybe<Array<Maybe<BookingEdgesFragment>>>, closed?: Maybe<Array<Maybe<BookingEdgesFragment>>>, signed?: Maybe<Array<Maybe<BookingEdgesFragment>>>, others?: Maybe<Array<Maybe<BookingEdgesFragment>>> }
  )> };

export type BookingEdgesFragment = (
  Pick<Booking, 'id' | 'status' | 'type' | 'createdDate'>
  & { user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>>, page?: Maybe<(
    Pick<Page, 'id' | 'name'>
    & { profilePicture?: Maybe<ImageFieldsFragment> }
  )>, dates?: Maybe<Array<Maybe<BookingDatesFieldsFragment>>>, appliedTo?: Maybe<(
    Pick<Gig, 'id' | 'name' | 'type'>
    & { images?: Maybe<Array<Maybe<ImageFieldsFragment>>> }
  )>, place?: Maybe<Pick<BookingPlace, 'name' | 'formattedAddress'>>, signatures?: Maybe<Array<Maybe<(
    Pick<Signature, 'id' | 'createdDate' | 'valid' | 'isBooker' | 'type'>
    & { user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
  )>>> }
);

export type GetReviewsByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReviewsByUserQuery = { reviewsByUser?: Maybe<Array<Maybe<(
    Pick<Review, 'id' | 'canUsePublic' | 'visible' | 'recommended'>
    & { page?: Maybe<(
      Pick<Page, 'id' | 'name' | 'slug'>
      & { profilePicture?: Maybe<ImageFieldsFragment> }
    )>, bookingDate?: Maybe<(
      Pick<BookingDates, 'id' | 'date'>
      & { booking?: Maybe<Pick<Booking, 'id' | 'type'>> }
    )> }
  )>>> };

export type SignBookingMutationVariables = Exact<{
  bookingId: Scalars['Int'];
}>;


export type SignBookingMutation = { signBooking?: Maybe<BookingFieldsFragment> };

export type SubscribeToBookingUpdatesSubscriptionVariables = Exact<{
  bookingId: Scalars['Int'];
}>;


export type SubscribeToBookingUpdatesSubscription = { bookingChanges?: Maybe<BookingFieldsFragment> };

export type UpdateBookingMutationVariables = Exact<{
  bookingId: Scalars['Int'];
  booking: BookingInput;
}>;


export type UpdateBookingMutation = { updateBooking?: Maybe<BookingFieldsFragment> };

export type ChannelFieldsFragment = (
  Pick<Channel, 'id'>
  & { users?: Maybe<Array<Maybe<UserFieldsFragment>>>, messages?: Maybe<Array<Maybe<ChannelMessageFieldsFragment>>> }
);

export type ChannelMessageFieldsFragment = (
  Pick<ChannelMessage, 'id' | 'body' | 'createdDate'>
  & { sender?: Maybe<UserFieldsFragment>, file?: Maybe<FileFieldsFragment> }
);

export type GetChannelByIdQueryVariables = Exact<{
  channelId: Scalars['Int'];
}>;


export type GetChannelByIdQuery = { channelById?: Maybe<ChannelFieldsFragment> };

export type SubscribeToChannelSubscriptionVariables = Exact<{
  channelId: Scalars['Int'];
}>;


export type SubscribeToChannelSubscription = { channelMessages?: Maybe<ChannelMessageFieldsFragment> };

export type FileFieldsFragment = Pick<GigitalFile, 'id' | 'fileName' | 'mimetype' | 'fileExtension'>;

export type GigitalImageFragment = (
  Pick<Image, 'id' | 'fileName'>
  & { cropData?: Maybe<Pick<ImageCropData, 'x' | 'y' | 'w' | 'h'>> }
);

export type ImageFieldsFragment = (
  Pick<Image, 'id' | 'fileName' | 'order'>
  & { cropData?: Maybe<Pick<ImageCropData, 'x' | 'y' | 'w' | 'h'>> }
);

export type ListFieldsFragment = (
  Pick<PageList, 'id' | 'slug' | 'name' | 'active' | 'gigType' | 'description' | 'country' | 'order'>
  & { pages?: Maybe<Array<Maybe<PageFieldsFragment>>> }
);

export type MotdFieldsFragment = (
  Pick<Motd, 'id' | 'level' | 'message' | 'active' | 'endDate'>
  & { createdBy?: Maybe<UserFieldsFragment> }
);

export type OrganizationFieldsFragment = (
  Pick<Organization, 'id' | 'name' | 'orgId' | 'bookingFee' | 'artistFee' | 'emailInvitePatterns' | 'blogTags'>
  & { users?: Maybe<Array<Maybe<(
    Pick<User, 'type'>
    & UserFieldsFragment
  )>>>, gigTemplates?: Maybe<Array<Maybe<AdminGigTemplateFieldsFragment>>> }
);

export type GetArtistsListsQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
}>;


export type GetArtistsListsQuery = { getContentfulEntry?: Maybe<(
    Pick<ContentfulData, 'contentfulData'>
    & { artistLists?: Maybe<Array<Maybe<(
      Pick<ContentfulArtistList, 'listId'>
      & { pages?: Maybe<Array<(
        Pick<ContentfulArtistItem, 'customDescription'>
        & { page?: Maybe<ListFragmentsFragment> }
      )>> }
    )>>>, artistHeros?: Maybe<Array<Maybe<(
      Pick<ContentfulArtistItem, 'customDescription'>
      & { page?: Maybe<(
        { backgroundPicture?: Maybe<ImageFieldsFragment> }
        & ListFragmentsFragment
      )> }
    )>>> }
  )> };

export type ListFragmentsFragment = (
  Pick<Page, 'id' | 'name' | 'presentation' | 'price' | 'slug' | 'positiveReviews'>
  & { links?: Maybe<Array<Maybe<Pick<PageLink, 'id' | 'socialMediaId' | 'socialMediaTypeId' | 'url' | 'isHighlight'>>>>, tags?: Maybe<Array<Maybe<TagFieldsFragment>>>, profilePicture?: Maybe<ImageFieldsFragment> }
);

export type GetContentfulEntryQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
}>;


export type GetContentfulEntryQuery = { getContentfulEntry?: Maybe<Pick<ContentfulData, 'contentfulData'>> };

export type GetListsQueryVariables = Exact<{
  country?: Maybe<CountriesEnum>;
  gigType?: Maybe<GigType>;
}>;


export type GetListsQuery = { getPageLists?: Maybe<Array<Maybe<ListFieldsFragment>>> };

export type GetMotdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMotdQuery = { motd?: Maybe<Array<Maybe<MotdFieldsFragment>>> };

export type GetUserFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserFilesQuery = { filesByUser?: Maybe<Array<Maybe<FileFieldsFragment>>> };

export type AcceptApplicationMutationVariables = Exact<{
  accepts: Array<AcceptApplicationInput>;
}>;


export type AcceptApplicationMutation = { acceptApplications?: Maybe<Array<Maybe<(
    { gig?: Maybe<Pick<Gig, 'id'>> }
    & GigSlotFieldsFragment
  )>>> };

export type ApplicationsByUserQueryVariables = Exact<{
  type: ApplicationType;
  paging: Pagination;
}>;


export type ApplicationsByUserQuery = { applicationsByUser?: Maybe<(
    Pick<ApplicationsFeed, 'skip' | 'hasNextPage' | 'total'>
    & { edges: Array<(
      { booking?: Maybe<BookingFieldsFragment> }
      & BookingDatesFieldsFragment
    )> }
  )> };

export type ApplyToGigMutationVariables = Exact<{
  application: GigApply;
}>;


export type ApplyToGigMutation = { applyToGig?: Maybe<BookingFieldsFragment> };

export type CancelApplicationMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  dateId: Scalars['Int'];
}>;


export type CancelApplicationMutation = { cancelApplication?: Maybe<(
    Pick<Booking, 'status'>
    & BookingFieldsFragment
  )> };

export type CompletedGigsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompletedGigsQuery = { completedGigs?: Maybe<(
    Pick<CompletedGigs, 'numAssigned'>
    & { gigs?: Maybe<Array<Maybe<GigFieldsFragment>>> }
  )> };

export type CreateGigMutationVariables = Exact<{
  gig: GigInput;
}>;


export type CreateGigMutation = { createGig?: Maybe<Pick<Gig, 'id'>> };

export type DeleteGigMutationVariables = Exact<{
  gigId: Scalars['Int'];
  deleted: Scalars['Boolean'];
}>;


export type DeleteGigMutation = { deleteGig?: Maybe<Pick<Gig, 'id' | 'deleted'>> };

export type DenyApplicationMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  slotId: Scalars['Int'];
}>;


export type DenyApplicationMutation = { denyApplication?: Maybe<(
    Pick<Booking, 'status'>
    & BookingFieldsFragment
  )> };

export type GigSlotFieldsFragment = (
  Pick<GigSlot, 'id' | 'date' | 'status' | 'deadline' | 'price' | 'priceHidden' | 'startTime' | 'endTime'>
  & { tags?: Maybe<Array<Maybe<TagFieldsFragment>>>, applications?: Maybe<Array<Maybe<(
    Pick<Booking, 'id' | 'status' | 'message'>
    & { page?: Maybe<(
      Pick<Page, 'id' | 'name' | 'slug' | 'presentation' | 'ownership'>
      & { links?: Maybe<Array<Maybe<Pick<PageLink, 'id' | 'socialMediaId' | 'socialMediaTypeId' | 'url' | 'isHighlight'>>>>, tags?: Maybe<Array<Maybe<TagFieldsFragment>>>, profilePicture?: Maybe<ImageFieldsFragment>, user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
    )>, dates?: Maybe<Array<Maybe<BookingDatesFieldsFragment>>>, place?: Maybe<Pick<BookingPlace, 'placeId' | 'formattedAddress' | 'components' | 'name'>>, others?: Maybe<Pick<BookingOthers, 'addedBy' | 'signedBy' | 'createdDate' | 'others'>> }
  )>>> }
);

export type GigFieldsFragment = (
  Pick<Gig, 'id' | 'name' | 'details' | 'placeId' | 'deleted' | 'placeData' | 'type' | 'isOwner' | 'deadline' | 'limitedData' | 'slotsCount' | 'slotsOpen' | 'supplies' | 'isRequestingSupplies' | 'extraTags'>
  & { user?: Maybe<Pick<User, 'id'>>, slots?: Maybe<Array<Maybe<GigSlotFieldsFragment>>>, images?: Maybe<Array<Maybe<ImageFieldsFragment>>>, availableTags?: Maybe<Array<Maybe<TagFieldsFragment>>> }
);

export type GetDashboardGigsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardGigsQuery = { dashboardGigs?: Maybe<(
    Pick<DashboardResponse, 'count'>
    & { gigs?: Maybe<Array<Maybe<(
      Pick<Gig, 'newApplications'>
      & GigFieldsFragment
    )>>> }
  )> };

export type GetExistingGigPlacesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExistingGigPlacesQuery = { existingPlaces: Array<Maybe<(
    Pick<PlaceEntry, 'amount'>
    & { place: Pick<ExistingPlace, 'types' | 'longName'> }
  )>> };

export type GetGigQueryVariables = Exact<{
  gigId: Scalars['Int'];
  paging?: Maybe<Pagination>;
}>;


export type GetGigQuery = { gigById?: Maybe<(
    Pick<SlotFeed, 'skip' | 'hasNextPage'>
    & { gig?: Maybe<(
      Pick<Gig, 'deleted'>
      & GigFieldsFragment
    )> }
  )> };

export type GetGigApplyToQueryVariables = Exact<{
  gigId: Scalars['Int'];
}>;


export type GetGigApplyToQuery = { gigById?: Maybe<{ gig?: Maybe<(
      Pick<Gig, 'id' | 'name' | 'details' | 'placeId' | 'placeData' | 'type'>
      & { images?: Maybe<Array<Maybe<ImageFieldsFragment>>>, slots?: Maybe<Array<Maybe<(
        Pick<GigSlot, 'id' | 'date' | 'price'>
        & { applications?: Maybe<Array<Maybe<{ page?: Maybe<Pick<Page, 'id' | 'name'>> }>>> }
      )>>> }
    )> }> };

export type GetGigsQueryVariables = Exact<{
  paging?: Maybe<Pagination>;
  filter?: Maybe<GigFilter>;
}>;


export type GetGigsQuery = { gigs?: Maybe<(
    Pick<GigFeed, 'skip' | 'hasNextPage'>
    & { edges: Array<(
      Pick<Gig, 'id' | 'name' | 'placeId' | 'deleted' | 'placeData' | 'type' | 'isOwner' | 'deadline' | 'limitedData' | 'slotsCount' | 'slotsOpen' | 'extraTags'>
      & { user?: Maybe<Pick<User, 'id'>>, slots?: Maybe<Array<Maybe<(
        Pick<GigSlot, 'id' | 'date' | 'status' | 'deadline'>
        & { tags?: Maybe<Array<Maybe<TagFieldsFragment>>> }
      )>>>, images?: Maybe<Array<Maybe<ImageFieldsFragment>>>, availableTags?: Maybe<Array<Maybe<TagFieldsFragment>>> }
    )>, availableFilter?: Maybe<{ tags?: Maybe<Array<Maybe<(
        Pick<TagEntry, 'amount'>
        & { tag: Pick<Tag, 'id' | 'name'> }
      )>>>, locations?: Maybe<Array<Maybe<(
        Pick<PlaceEntry, 'amount'>
        & { place: Pick<ExistingPlace, 'longName' | 'types'> }
      )>>>, types?: Maybe<Array<Maybe<Pick<TypeEntry, 'type' | 'amount'>>>> }> }
  )> };

export type GetOpenGigsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOpenGigsQuery = { openGigs?: Maybe<Pick<OpenGigs, 'gigs' | 'slots'>> };

export type GetTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTemplatesQuery = { getTemplates?: Maybe<Array<Maybe<(
    Pick<GigTemplate, 'id' | 'name' | 'details' | 'type' | 'deadline' | 'defaultPrice'>
    & { tags?: Maybe<Array<Maybe<Pick<Tag, 'id' | 'name'>>>>, org?: Maybe<Pick<Organization, 'id' | 'name' | 'orgId'>> }
  )>>> };

export type GetGigsByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGigsByUserQuery = { gigsByUser?: Maybe<Array<Maybe<(
    Pick<Gig, 'deleted'>
    & GigFieldsFragment
  )>>> };

export type UpdateGigMutationVariables = Exact<{
  gig: GigInput;
}>;


export type UpdateGigMutation = { updateGig?: Maybe<GigFieldsFragment> };

export type ImageUploadMutationVariables = Exact<{
  image: Scalars['Upload'];
  cropData: ImageCropDataInput;
}>;


export type ImageUploadMutation = { imageUpload?: Maybe<ImageFieldsFragment> };

export type ClaimInviteMutationVariables = Exact<{
  publicId: Scalars['String'];
}>;


export type ClaimInviteMutation = { claimInvite?: Maybe<Pick<Invite, 'id' | 'publicId' | 'claimed'>> };

export type DeleteInviteMutationVariables = Exact<{
  inviteId: Scalars['Int'];
}>;


export type DeleteInviteMutation = { deleteInvite?: Maybe<Pick<Invite, 'id' | 'deleted'>> };

export type GetInvitesByUserQueryVariables = Exact<{
  paging: Pagination;
}>;


export type GetInvitesByUserQuery = { getInvitesByUser?: Maybe<(
    Pick<InviteFeed, 'total' | 'hasNextPage' | 'skip'>
    & { invites?: Maybe<Array<Maybe<(
      Pick<Invite, 'deleted' | 'createdDate' | 'id' | 'email' | 'claimed' | 'expiryDate' | 'completed'>
      & { artistReview?: Maybe<(
        Pick<ArtistReview, 'id' | 'recommended'>
        & { reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'company' | 'email'>> }
      )> }
    )>>> }
  )> };

export type GetReviewInviteQueryVariables = Exact<{
  publicId: Scalars['String'];
}>;


export type GetReviewInviteQuery = { inviteByPublicId?: Maybe<(
    Pick<Invite, 'id' | 'publicId' | 'email' | 'claimed' | 'message'>
    & { artistReview?: Maybe<(
      Pick<ArtistReview, 'recommended' | 'message' | 'reviewDate'>
      & { page?: Maybe<(
        Pick<Page, 'name' | 'slug' | 'id'>
        & { profilePicture?: Maybe<ImageFieldsFragment>, tags?: Maybe<Array<Maybe<TagFieldsFragment>>> }
      )>, reviewer?: Maybe<Pick<ExternalBooker, 'company' | 'name' | 'email'>> }
    )> }
  )> };

export type LeaveInviteReviewMutationVariables = Exact<{
  input: LeaveInviteReviewInput;
}>;


export type LeaveInviteReviewMutation = { leaveExternalReview?: Maybe<(
    Pick<Invite, 'id' | 'publicId'>
    & { artistReview?: Maybe<(
      Pick<ArtistReview, 'id' | 'recommended' | 'message'>
      & { reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'company' | 'email'>> }
    )> }
  )> };

export type SendReviewInviteMutationVariables = Exact<{
  reviewInvite: ReviewInvite;
}>;


export type SendReviewInviteMutation = { addReviewInvite?: Maybe<(
    Pick<Invite, 'id' | 'publicId' | 'email' | 'message'>
    & { artistReview?: Maybe<{ reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'company' | 'email'>> }> }
  )> };

export type NotificationFieldsFragment = (
  Pick<Notification, 'id' | 'isRead' | 'meta' | 'createdDate' | 'type'>
  & { sendingUser?: Maybe<Pick<User, 'firstName' | 'lastName'>>, page?: Maybe<PageFieldsFragment> }
);

export type NotificationWithSubsFieldsFragment = { notification?: Maybe<NotificationFieldsFragment>, subs?: Maybe<Array<Maybe<NotificationFieldsFragment>>> };

export type GetNotificationsQueryVariables = Exact<{
  paging?: Maybe<Pagination>;
  getUnread: Scalars['Boolean'];
}>;


export type GetNotificationsQuery = { getNotifications?: Maybe<(
    Pick<NotificationFeed, 'hasNextPage' | 'skip'>
    & { notifications?: Maybe<Array<Maybe<NotificationWithSubsFieldsFragment>>>, unread?: Maybe<Array<Maybe<NotificationWithSubsFieldsFragment>>> }
  )> };

export type SetNotificationsReadMutationVariables = Exact<{
  notifications?: Maybe<Array<Scalars['Int']>>;
  all?: Maybe<Scalars['Boolean']>;
}>;


export type SetNotificationsReadMutation = { setNotificationsRead?: Maybe<Array<Maybe<NotificationFieldsFragment>>> };

export type NotificationSubscriptionSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationSubscriptionSubscription = { notification?: Maybe<NotificationFieldsFragment> };

export type CreatePageMutationVariables = Exact<{
  page: PageInput;
}>;


export type CreatePageMutation = { createPage?: Maybe<PageFieldsFragment> };

export type CreatePageFromSocialMediaMutationVariables = Exact<{
  page: SocialMediaAccountDataInput;
}>;


export type CreatePageFromSocialMediaMutation = { createPageFromSocialMedia?: Maybe<PageFieldsFragment> };

export type FindOnePageNoReviewsQueryVariables = Exact<{
  pageId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
}>;


export type FindOnePageNoReviewsQuery = { findOnePage?: Maybe<(
    Pick<Page, 'fee'>
    & PageFieldsFragment
  )> };

export type BasicTagFieldsFragment = Pick<Tag, 'id' | 'name' | 'group'>;

export type ImportablePageFieldsFragment = { youtube?: Maybe<Array<Maybe<SocialMediaAccountDataFieldsFragment>>>, spotify?: Maybe<Array<Maybe<SocialMediaAccountDataFieldsFragment>>>, mixcloud?: Maybe<Array<Maybe<SocialMediaAccountDataFieldsFragment>>> };

export type PageFieldsFragment = (
  Pick<Page, 'id' | 'name' | 'category' | 'presentation' | 'price' | 'pageStatus' | 'reviewStatus' | 'ownership' | 'order' | 'slug' | 'searchable' | 'positiveReviews' | 'negativeReviews' | 'badges' | 'signedDates'>
  & { user?: Maybe<UserFieldsFragment>, tags?: Maybe<Array<Maybe<TagFieldsFragment>>>, links?: Maybe<Array<Maybe<PageLinkFieldsFragment>>>, profilePicture?: Maybe<ImageFieldsFragment>, backgroundPicture?: Maybe<ImageFieldsFragment>, denyReasons?: Maybe<Array<Maybe<Pick<DenyReason, 'title' | 'extra'>>>>, placeData?: Maybe<(
    Pick<BookingPlace, 'placeId' | 'formattedAddress' | 'components' | 'name'>
    & { geometry?: Maybe<{ location?: Maybe<Pick<LatLong, 'lat' | 'lng'>>, viewport?: Maybe<{ northeast?: Maybe<Pick<LatLong, 'lat' | 'lng'>>, southwest?: Maybe<Pick<LatLong, 'lat' | 'lng'>> }> }> }
  )>, gallery?: Maybe<Array<Maybe<ImageFieldsFragment>>>, faq?: Maybe<Array<Maybe<Pick<PageFaqAnswer, 'lang' | 'question' | 'answer'>>>> }
);

export type PageBasicFieldsFragment = (
  Pick<Page, 'id' | 'name' | 'category' | 'presentation' | 'price'>
  & { placeData?: Maybe<(
    Pick<BookingPlace, 'placeId' | 'formattedAddress' | 'components' | 'name'>
    & { geometry?: Maybe<{ location?: Maybe<Pick<LatLong, 'lat' | 'lng'>>, viewport?: Maybe<{ northeast?: Maybe<Pick<LatLong, 'lat' | 'lng'>>, southwest?: Maybe<Pick<LatLong, 'lat' | 'lng'>> }> }> }
  )> }
);

export type PageLinkFieldsFragment = Pick<PageLink, 'id' | 'socialMediaId' | 'socialMediaTypeId' | 'url' | 'isHighlight' | 'order'>;

export type SocialMediaAccountDataFieldsFragment = Pick<SocialMediaAccountData, 'id' | 'name' | 'userName' | 'thumbnailImageUrl' | 'description'>;

export type SocialMediaAccountMediaFieldsFragment = Pick<SocialMediaAccountMedia, 'id' | 'type'>;

export type TagFieldsFragment = (
  Pick<Tag, 'id' | 'name' | 'group' | 'categoryRestrictions'>
  & { allowedBy?: Maybe<Array<Maybe<BasicTagFieldsFragment>>>, parent?: Maybe<BasicTagFieldsFragment>, children?: Maybe<Array<Maybe<BasicTagFieldsFragment>>>, translations?: Maybe<Pick<SimpleTranslation, 'sv' | 'en'>> }
);

export type GetFilterablePagePlacesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFilterablePagePlacesQuery = { getFilterablePagePlaces?: Maybe<Array<Maybe<(
    Pick<ExistingCountry, 'name'>
    & { places?: Maybe<Array<Maybe<(
      Pick<ExistingPlace, 'longName'>
      & { geometry?: Maybe<{ location?: Maybe<Pick<LatLong, 'lng' | 'lat'>> }> }
    )>>> }
  )>>> };

export type GetFilteredPagesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<TagFilter>>>;
  priceMin?: Maybe<Scalars['Int']>;
  priceMax?: Maybe<Scalars['Int']>;
  sorting: Scalars['String'];
  textQuery?: Maybe<Scalars['String']>;
  category?: Maybe<PageCategory>;
  location?: Maybe<Array<LatLongInput>>;
  locationRadiusKm?: Maybe<Scalars['Int']>;
  includesEquipment?: Maybe<Scalars['Boolean']>;
}>;


export type GetFilteredPagesQuery = { filterPages?: Maybe<(
    Pick<FilterFeed, 'count' | 'skip' | 'hasNextPage'>
    & { edges: Array<(
      Pick<Page, 'id' | 'name' | 'slug' | 'positiveReviews' | 'category' | 'price'>
      & { tags?: Maybe<Array<Maybe<TagFieldsFragment>>>, profilePicture?: Maybe<GigitalImageFragment>, links?: Maybe<Array<Maybe<Pick<PageLink, 'id' | 'socialMediaId' | 'socialMediaTypeId' | 'url' | 'isHighlight'>>>> }
    )>, tagMatches?: Maybe<Array<Maybe<Pick<TagMatches, 'tagId' | 'count'>>>>, existingTags?: Maybe<Array<Maybe<TagFieldsFragment>>>, tagHits?: Maybe<Array<Maybe<Pick<TagMatches, 'tagId' | 'count'>>>> }
  )> };

export type GetImportablePageDataQueryVariables = Exact<{
  userName?: Maybe<Scalars['String']>;
  platforms: Array<SocialMediaAccountType>;
}>;


export type GetImportablePageDataQuery = { getImportablePageData?: Maybe<ImportablePageFieldsFragment> };

export type GetOneListQueryVariables = Exact<{
  idOrSlug: Scalars['String'];
}>;


export type GetOneListQuery = { findOneList?: Maybe<ListFieldsFragment> };

export type FindOnePageQueryVariables = Exact<{
  pageId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  paging: Pagination;
}>;


export type FindOnePageQuery = { findOnePage?: Maybe<(
    Pick<Page, 'fee'>
    & PageFieldsFragment
  )>, getPageReviews?: Maybe<(
    Pick<PageReviews, 'total' | 'skip' | 'hasNextPage'>
    & { reviews?: Maybe<Array<Maybe<(
      Pick<Review, 'id' | 'recommended' | 'message' | 'reviewDate' | 'playDate'>
      & { reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'company'>>, user?: Maybe<Pick<User, 'firstName' | 'lastName' | 'companyName' | 'reviewerName'>>, bookingDate?: Maybe<{ booking?: Maybe<Pick<Booking, 'type'>> }> }
    )>>> }
  )> };

export type GetPageGalleryQueryVariables = Exact<{
  pageId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  paging: Pagination;
}>;


export type GetPageGalleryQuery = { findOnePage?: Maybe<{ gallery?: Maybe<Array<Maybe<ImageFieldsFragment>>> }> };

export type GetPageMediaQueryVariables = Exact<{
  pageId?: Maybe<Scalars['Int']>;
}>;


export type GetPageMediaQuery = { findOnePage?: Maybe<{ links?: Maybe<Array<Maybe<PageLinkFieldsFragment>>>, gallery?: Maybe<Array<Maybe<ImageFieldsFragment>>> }> };

export type GetPageReviewsQueryVariables = Exact<{
  pageId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  paging: Pagination;
}>;


export type GetPageReviewsQuery = { getPageReviews?: Maybe<(
    Pick<PageReviews, 'total' | 'skip' | 'hasNextPage'>
    & { reviews?: Maybe<Array<Maybe<(
      Pick<Review, 'recommended' | 'message' | 'reviewDate' | 'playDate'>
      & { reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'company'>>, user?: Maybe<Pick<User, 'firstName' | 'lastName' | 'companyName' | 'reviewerName'>>, bookingDate?: Maybe<{ booking?: Maybe<Pick<Booking, 'type'>> }> }
    )>>> }
  )> };

export type GetPageTagListsQueryVariables = Exact<{
  tagLists?: Maybe<Array<Maybe<PageTagListInput>>>;
}>;


export type GetPageTagListsQuery = { getPageTagLists?: Maybe<Array<Maybe<(
    Pick<PageTagListItem, 'tagIds'>
    & { pages?: Maybe<Array<Maybe<(
      Pick<Page, 'id' | 'name' | 'category' | 'price' | 'slug'>
      & { profilePicture?: Maybe<ImageFieldsFragment>, links?: Maybe<Array<Maybe<PageLinkFieldsFragment>>> }
    )>>> }
  )>>> };

export type GetPagesByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPagesByUserQuery = { pagesByUser?: Maybe<Array<Maybe<PageFieldsFragment>>> };

export type GetPagesByUserLeanQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPagesByUserLeanQuery = { pagesByUser?: Maybe<Array<Maybe<(
    Pick<Page, 'id' | 'name' | 'price' | 'reviewStatus'>
    & { profilePicture?: Maybe<ImageFieldsFragment>, tags?: Maybe<Array<Maybe<Pick<Tag, 'id' | 'group' | 'name'>>>>, links?: Maybe<Array<Maybe<Pick<PageLink, 'id'>>>> }
  )>>> };

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = { tags?: Maybe<Array<Maybe<TagFieldsFragment>>> };

export type SendPageForReviewMutationVariables = Exact<{
  pageId: Scalars['Int'];
}>;


export type SendPageForReviewMutation = { sendPageForReview?: Maybe<PageFieldsFragment> };

export type SetPageArchivedMutationVariables = Exact<{
  pageId: Scalars['Int'];
  archived: Scalars['Boolean'];
}>;


export type SetPageArchivedMutation = { setPageArchived?: Maybe<Pick<Page, 'id' | 'pageStatus'>> };

export type UpdatePageMutationVariables = Exact<{
  page: PageInput;
}>;


export type UpdatePageMutation = { updatePage?: Maybe<PageFieldsFragment> };

export type UpdatePageBasicsMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  category: PageCategory;
  placeId: Scalars['String'];
  canTravel: Scalars['Boolean'];
  price: Scalars['Int'];
  hasEquipment: Scalars['Boolean'];
  presentation: Scalars['String'];
}>;


export type UpdatePageBasicsMutation = { updatePageBasics?: Maybe<PageBasicFieldsFragment> };

export type UpdatePageFaqMutationVariables = Exact<{
  pageId: Scalars['Int'];
  faq: Array<FaqInput>;
}>;


export type UpdatePageFaqMutation = { updatePageFaq?: Maybe<{ faq?: Maybe<Array<Maybe<Pick<PageFaqAnswer, 'lang' | 'question' | 'answer'>>>> }> };

export type UpdatePageWithSocialMediaMutationVariables = Exact<{
  pageId: Scalars['Int'];
  socialMediaData: SocialMediaAccountDataInput;
}>;


export type UpdatePageWithSocialMediaMutation = { updatePageWithSocialMedia?: Maybe<PageFieldsFragment> };

export type UpdatePageGalleryMutationVariables = Exact<{
  pageId: Scalars['Int'];
  images: Array<ImageInputWithOrder>;
}>;


export type UpdatePageGalleryMutation = { updatePageGallery?: Maybe<{ gallery?: Maybe<Array<Maybe<ImageFieldsFragment>>> }> };

export type UpdatePageImagesMutationVariables = Exact<{
  pageId: Scalars['Int'];
  profilePicture?: Maybe<ImageInput>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  backgroundPicture?: Maybe<ImageInput>;
  backgroundPictureUrl?: Maybe<Scalars['String']>;
}>;


export type UpdatePageImagesMutation = { updatePageImages?: Maybe<{ profilePicture?: Maybe<ImageFieldsFragment>, backgroundPicture?: Maybe<ImageFieldsFragment> }> };

export type UpdatePageMediaMutationVariables = Exact<{
  pageId: Scalars['Int'];
  links: Array<LinkInput>;
}>;


export type UpdatePageMediaMutation = { updatePageMedia?: Maybe<{ links?: Maybe<Array<Maybe<PageLinkFieldsFragment>>> }> };

export type UpdatePageSlugMutationVariables = Exact<{
  page: PageInput;
}>;


export type UpdatePageSlugMutation = { updatePage?: Maybe<Pick<Page, 'slug'>> };

export type UpdatePageTagsMutationVariables = Exact<{
  pageId: Scalars['Int'];
  tagIds: Array<Scalars['Int']>;
}>;


export type UpdatePageTagsMutation = { updatePageTags?: Maybe<{ tags?: Maybe<Array<Maybe<TagFieldsFragment>>> }> };

export type CreateSetupIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSetupIntentMutation = Pick<Mutation, 'createSetupIntent'>;

export type GetPaymentHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentHistoryQuery = Pick<Query, 'getPaymentHistory'>;

export type GetPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentMethodsQuery = Pick<Query, 'getPaymentMethods'>;

export type RemovePaymentMethodMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemovePaymentMethodMutation = Pick<Mutation, 'removePaymentMethod'>;

export type PostChannelMessageMutationVariables = Exact<{
  channelId: Scalars['Int'];
  body: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
  existingFileId?: Maybe<Scalars['Int']>;
  fileExtension?: Maybe<Scalars['String']>;
}>;


export type PostChannelMessageMutation = { postMessage?: Maybe<ChannelMessageFieldsFragment> };

export type EditReviewsAdminMutationVariables = Exact<{
  reviews: EditReviewInput;
}>;


export type EditReviewsAdminMutation = { adminEditReviews?: Maybe<Array<Maybe<Pick<Review, 'id' | 'message' | 'recommended' | 'status'>>>> };

export type ArtistReviewFieldsFragment = (
  Pick<ArtistReview, 'id' | 'reviewDate' | 'status' | 'message' | 'recommended'>
  & { page?: Maybe<PageFieldsFragment>, user?: Maybe<UserFieldsFragment>, reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'email' | 'company'>> }
);

export type LeaveReviewMutationVariables = Exact<{
  artistId: Scalars['Int'];
  input?: Maybe<ArtistReviewInput>;
}>;


export type LeaveReviewMutation = { leaveReview?: Maybe<(
    Pick<ArtistReview, 'id' | 'reviewDate' | 'status' | 'message' | 'recommended'>
    & { reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'email' | 'company'>> }
  )> };

export type PossibleLeanReviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type PossibleLeanReviewsQuery = { possibleReviews?: Maybe<Array<Maybe<Pick<Page, 'id'>>>> };

export type PossibleReviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type PossibleReviewsQuery = { possibleReviews?: Maybe<Array<Maybe<PageFieldsFragment>>> };

export type GetReviewByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetReviewByIdQuery = { reviewById?: Maybe<(
    Pick<ArtistReview, 'id' | 'recommended' | 'message'>
    & { page?: Maybe<(
      Pick<Page, 'name' | 'slug' | 'id'>
      & { profilePicture?: Maybe<ImageFieldsFragment> }
    )> }
  )> };

export type GetReviewByPadeIdQueryVariables = Exact<{
  pageId: Scalars['Int'];
}>;


export type GetReviewByPadeIdQuery = { reviewByPage?: Maybe<Array<Maybe<(
    Pick<ArtistReview, 'id' | 'recommended' | 'message' | 'reviewDate' | 'status'>
    & { page?: Maybe<Pick<Page, 'id' | 'name'>>, user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'reviewerName'>>, reviewer?: Maybe<Pick<ExternalBooker, 'name' | 'email' | 'company'>> }
  )>>> };

export type AddBillingToUserMutationVariables = Exact<{
  billing: BillingInput;
}>;


export type AddBillingToUserMutation = { addBillingToUser?: Maybe<UserFieldsFragment> };

export type RegisterUserMutationVariables = Exact<{
  user: RegisterUser;
}>;


export type RegisterUserMutation = { addUser?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>> };

export type DeleteBillingMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBillingMutation = { deleteBilling?: Maybe<BillingFieldsFragment> };

export type BillingFieldsFragment = Pick<Billing, 'id' | 'companyName' | 'orgNumber' | 'address' | 'email' | 'reference'>;

export type UserFieldsFragment = (
  Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'complete' | 'newsletter' | 'fee' | 'type' | 'isSocial' | 'missingData' | 'lang' | 'receiveTransactional' | 'hasSearchablePage' | 'phoneNumber' | 'phonePrefix'>
  & { roles?: Maybe<Array<Maybe<Pick<Role, 'id' | 'name'>>>>, billings?: Maybe<Array<Maybe<BillingFieldsFragment>>>, organizations?: Maybe<Array<Maybe<Pick<Organization, 'id' | 'name' | 'orgId' | 'bookingFee' | 'artistFee' | 'emailInvitePatterns' | 'blogTags'>>>> }
);

export type GetBillingInfosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBillingInfosQuery = { billingInfos: Array<Maybe<BillingFieldsFragment>> };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { me?: Maybe<(
    Pick<User, 'companyName' | 'type'>
    & UserFieldsFragment
  )> };

export type GetUserAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAdminQuery = { me?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'isAdmin'>> };

export type UpdateBillingMutationVariables = Exact<{
  billing: BillingInput;
}>;


export type UpdateBillingMutation = { updateBilling?: Maybe<(
    Pick<Billing, 'fee'>
    & BillingFieldsFragment
  )> };

export type UpdateUserMutationVariables = Exact<{
  editUser: EditUser;
}>;


export type UpdateUserMutation = { updateUser?: Maybe<(
    Pick<User, 'companyName'>
    & UserFieldsFragment
  )> };

export const BillingFieldsFragmentDoc = gql`
    fragment billingFields on Billing {
  id
  companyName
  orgNumber
  address
  email
  reference
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on User {
  id
  firstName
  lastName
  email
  complete
  newsletter
  fee
  type
  roles {
    id
    name
  }
  billings {
    ...billingFields
  }
  isSocial
  missingData
  lang
  receiveTransactional
  hasSearchablePage
  organizations {
    id
    name
    orgId
    bookingFee
    artistFee
    emailInvitePatterns
    blogTags
  }
  phoneNumber
  phonePrefix
}
    ${BillingFieldsFragmentDoc}`;
export const ImageFieldsFragmentDoc = gql`
    fragment imageFields on Image {
  id
  fileName
  order
  cropData {
    x
    y
    w
    h
  }
}
    `;
export const BasicTagFieldsFragmentDoc = gql`
    fragment basicTagFields on Tag {
  id
  name
  group
}
    `;
export const TagFieldsFragmentDoc = gql`
    fragment tagFields on Tag {
  id
  name
  group
  categoryRestrictions
  allowedBy {
    ...basicTagFields
  }
  parent {
    ...basicTagFields
  }
  children {
    ...basicTagFields
  }
  translations {
    sv
    en
  }
}
    ${BasicTagFieldsFragmentDoc}`;
export const BookingDatesFieldsFragmentDoc = gql`
    fragment bookingDatesFields on BookingDates {
  id
  date
  soundCheckDate
  price
  priceCurrency
  description
  cancelledBy {
    ...userFields
  }
  cancelledDate
  cancelledDaysBefore
  addedBy {
    ...userFields
  }
  gigSlot {
    id
  }
  review {
    id
    recommended
    message
    canUsePublic
    visible
  }
  startTime
  endTime
  isAccepted
}
    ${UserFieldsFragmentDoc}`;
export const GigSlotFieldsFragmentDoc = gql`
    fragment gigSlotFields on GigSlot {
  id
  date
  status
  deadline
  price
  priceHidden
  startTime
  endTime
  tags {
    ...tagFields
  }
  applications {
    id
    status
    page {
      id
      name
      slug
      presentation
      ownership
      links {
        id
        socialMediaId
        socialMediaTypeId
        url
        isHighlight
      }
      tags {
        ...tagFields
      }
      profilePicture {
        ...imageFields
      }
      user {
        id
        firstName
        lastName
      }
    }
    dates {
      ...bookingDatesFields
    }
    place {
      placeId
      formattedAddress
      components
      name
    }
    others {
      addedBy
      signedBy
      createdDate
      others
    }
    message
  }
}
    ${TagFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${BookingDatesFieldsFragmentDoc}`;
export const AdminGigFieldsFragmentDoc = gql`
    fragment adminGigFields on AdminGig {
  id
  name
  user {
    ...userFields
  }
  images {
    ...imageFields
  }
  placeId
  deleted
  placeData
  createdDate
  dateModified
  details
  type
  slots {
    ...gigSlotFields
  }
  supplies
}
    ${UserFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${GigSlotFieldsFragmentDoc}`;
export const FileFieldsFragmentDoc = gql`
    fragment fileFields on GigitalFile {
  id
  fileName
  mimetype
  fileExtension
}
    `;
export const ChannelMessageFieldsFragmentDoc = gql`
    fragment channelMessageFields on ChannelMessage {
  id
  body
  sender {
    ...userFields
  }
  createdDate
  file {
    ...fileFields
  }
}
    ${UserFieldsFragmentDoc}
${FileFieldsFragmentDoc}`;
export const ChannelFieldsFragmentDoc = gql`
    fragment channelFields on Channel {
  id
  users {
    ...userFields
  }
  messages {
    ...channelMessageFields
  }
}
    ${UserFieldsFragmentDoc}
${ChannelMessageFieldsFragmentDoc}`;
export const BookingPartyPricingFragmentDoc = gql`
    fragment bookingPartyPricing on BookingPartyPricing {
  final
  fee
  feePrice
  penaltyFee
  penaltyTotal
}
    `;
export const BookingCalculatedPricingFragmentDoc = gql`
    fragment bookingCalculatedPricing on CalculatedPricing {
  artist {
    ...bookingPartyPricing
  }
  booker {
    ...bookingPartyPricing
  }
  baseDates
  travel
  supplies
  currency
}
    ${BookingPartyPricingFragmentDoc}`;
export const BookingFieldsFragmentDoc = gql`
    fragment bookingFields on Booking {
  id
  wantsInvoice
  amountPaid
  status
  message
  title
  type
  isBooker
  createdDate
  supplies
  billingInfo {
    ...billingFields
  }
  signatures {
    id
    createdDate
    user {
      id
      firstName
      lastName
    }
    valid
    isBooker
    type
  }
  user {
    ...userFields
  }
  page {
    id
    name
    slug
    fee
    ownership
    profilePicture {
      ...imageFields
    }
    user {
      ...userFields
    }
  }
  channel {
    ...channelFields
  }
  dates {
    ...bookingDatesFields
  }
  place {
    placeId
    formattedAddress
    components
    name
  }
  others {
    addedBy
    signedBy
    createdDate
    others
  }
  appliedTo {
    deadline
    deleted
    details
    id
    name
    placeId
    type
    images {
      ...imageFields
    }
  }
  supplies
  supplyStatus
  supplyPrice
  travelPrice
  newTypes
  calculatedPricing {
    ...bookingCalculatedPricing
  }
  cancelledBy {
    id
    firstName
    lastName
    email
  }
  cancelledDaysBefore
  cancelledDate
  wasSignedWhenCancelled
}
    ${BillingFieldsFragmentDoc}
${UserFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${ChannelFieldsFragmentDoc}
${BookingDatesFieldsFragmentDoc}
${BookingCalculatedPricingFragmentDoc}`;
export const GigFieldsFragmentDoc = gql`
    fragment gigFields on Gig {
  id
  name
  details
  user {
    id
  }
  placeId
  deleted
  placeData
  type
  slots {
    ...gigSlotFields
  }
  isOwner
  deadline
  images {
    ...imageFields
  }
  limitedData
  slotsCount
  slotsOpen
  availableTags {
    ...tagFields
  }
  supplies
  isRequestingSupplies
  extraTags
}
    ${GigSlotFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${TagFieldsFragmentDoc}`;
export const PageLinkFieldsFragmentDoc = gql`
    fragment pageLinkFields on PageLink {
  id
  socialMediaId
  socialMediaTypeId
  url
  isHighlight
  order
}
    `;
export const PageFieldsFragmentDoc = gql`
    fragment pageFields on Page {
  id
  name
  category
  presentation
  price
  pageStatus
  reviewStatus
  ownership
  pageStatus
  reviewStatus
  order
  slug
  searchable
  positiveReviews
  negativeReviews
  user {
    ...userFields
  }
  tags {
    ...tagFields
  }
  links {
    ...pageLinkFields
  }
  profilePicture {
    ...imageFields
  }
  backgroundPicture {
    ...imageFields
  }
  denyReasons {
    title
    extra
  }
  placeData {
    placeId
    formattedAddress
    components
    name
    geometry {
      location {
        lat
        lng
      }
      viewport {
        northeast {
          lat
          lng
        }
        southwest {
          lat
          lng
        }
      }
    }
  }
  gallery {
    ...imageFields
  }
  faq {
    lang
    question
    answer
  }
  badges
  signedDates
}
    ${UserFieldsFragmentDoc}
${TagFieldsFragmentDoc}
${PageLinkFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const NotificationFieldsFragmentDoc = gql`
    fragment notificationFields on Notification {
  id
  sendingUser {
    firstName
    lastName
  }
  isRead
  meta
  createdDate
  page {
    ...pageFields
  }
  type
}
    ${PageFieldsFragmentDoc}`;
export const AdminPageFieldsFragmentDoc = gql`
    fragment adminPageFields on AdminPage {
  id
  name
  presentation
  price
  pageStatus
  reviewStatus
  ownership
  pageStatus
  reviewStatus
  order
  slug
  searchable
  positiveReviews
  negativeReviews
  createdDate
  dateModified
  user {
    ...userFields
  }
  tags {
    ...tagFields
  }
  links {
    id
    socialMediaId
    socialMediaTypeId
    url
    isHighlight
  }
  profilePicture {
    ...imageFields
  }
  backgroundPicture {
    ...imageFields
  }
  denyReasons {
    title
    extra
  }
  bookings {
    ...bookingFields
  }
}
    ${UserFieldsFragmentDoc}
${TagFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${BookingFieldsFragmentDoc}`;
export const AdminUserFieldsFragmentDoc = gql`
    fragment adminUserFields on AdminUser {
  id
  firstName
  lastName
  email
  complete
  newsletter
  isAdmin
  fee
  billings {
    ...billingFields
    fee
  }
  isSocial
  socialType
  files {
    ...fileFields
  }
  bookings {
    ...bookingFields
  }
  gigs {
    ...gigFields
  }
  images {
    ...imageFields
  }
  channels {
    ...channelFields
  }
  notifications {
    ...notificationFields
  }
  pages {
    ...adminPageFields
  }
  createdDate
  dateModified
  type
  phoneNumber
  phonePrefix
  socialType
}
    ${BillingFieldsFragmentDoc}
${FileFieldsFragmentDoc}
${BookingFieldsFragmentDoc}
${GigFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${ChannelFieldsFragmentDoc}
${NotificationFieldsFragmentDoc}
${AdminPageFieldsFragmentDoc}`;
export const AdminGigTemplateFieldsFragmentDoc = gql`
    fragment adminGigTemplateFields on GigTemplate {
  id
  name
  details
  type
  deadline
  defaultPrice
  hidden
  tags {
    id
    name
  }
  user {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const AdminOrganizationFieldsFragmentDoc = gql`
    fragment adminOrganizationFields on AdminOrganization {
  id
  name
  orgId
  users {
    ...adminUserFields
    type
  }
  bookingFee
  artistFee
  emailInvitePatterns
  blogTags
  gigTemplates {
    ...adminGigTemplateFields
  }
}
    ${AdminUserFieldsFragmentDoc}
${AdminGigTemplateFieldsFragmentDoc}`;
export const ArtistAdminFieldsFragmentDoc = gql`
    fragment artistAdminFields on AdminPage {
  ...adminPageFields
  dateModified
  createdDate
}
    ${AdminPageFieldsFragmentDoc}`;
export const CacheFieldsFragmentDoc = gql`
    fragment cacheFields on CacheInfo {
  stats {
    keys
    hits
    misses
    ksize
    vsize
  }
  keys
}
    `;
export const PageFaqQuestionTemplateFieldsFragmentDoc = gql`
    fragment pageFaqQuestionTemplateFields on PageFaqQuestionTemplate {
  id
  questions {
    lang
    question
  }
}
    `;
export const BookingEdgesFragmentDoc = gql`
    fragment bookingEdges on Booking {
  id
  status
  type
  user {
    id
    firstName
    lastName
  }
  page {
    id
    name
    profilePicture {
      ...imageFields
    }
  }
  dates {
    ...bookingDatesFields
  }
  appliedTo {
    id
    name
    images {
      ...imageFields
    }
    type
  }
  place {
    name
    formattedAddress
  }
  createdDate
  signatures {
    id
    createdDate
    user {
      id
      firstName
      lastName
    }
    valid
    isBooker
    type
  }
}
    ${ImageFieldsFragmentDoc}
${BookingDatesFieldsFragmentDoc}`;
export const GigitalImageFragmentDoc = gql`
    fragment gigitalImage on Image {
  id
  fileName
  cropData {
    x
    y
    w
    h
  }
}
    `;
export const ListFieldsFragmentDoc = gql`
    fragment listFields on PageList {
  id
  slug
  name
  active
  gigType
  description
  country
  order
  pages {
    ...pageFields
  }
}
    ${PageFieldsFragmentDoc}`;
export const MotdFieldsFragmentDoc = gql`
    fragment motdFields on MOTD {
  id
  level
  message
  active
  endDate
  createdBy {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const OrganizationFieldsFragmentDoc = gql`
    fragment organizationFields on Organization {
  id
  name
  orgId
  users {
    ...userFields
    type
  }
  bookingFee
  artistFee
  emailInvitePatterns
  blogTags
  gigTemplates {
    ...adminGigTemplateFields
  }
}
    ${UserFieldsFragmentDoc}
${AdminGigTemplateFieldsFragmentDoc}`;
export const ListFragmentsFragmentDoc = gql`
    fragment listFragments on Page {
  id
  name
  presentation
  price
  slug
  positiveReviews
  links {
    id
    socialMediaId
    socialMediaTypeId
    url
    isHighlight
  }
  tags {
    ...tagFields
  }
  profilePicture {
    ...imageFields
  }
}
    ${TagFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const NotificationWithSubsFieldsFragmentDoc = gql`
    fragment notificationWithSubsFields on NotificationWithSubs {
  notification {
    ...notificationFields
  }
  subs {
    ...notificationFields
  }
}
    ${NotificationFieldsFragmentDoc}`;
export const SocialMediaAccountDataFieldsFragmentDoc = gql`
    fragment socialMediaAccountDataFields on SocialMediaAccountData {
  id
  name
  userName
  thumbnailImageUrl
  description
}
    `;
export const ImportablePageFieldsFragmentDoc = gql`
    fragment importablePageFields on ImportablePages {
  youtube {
    ...socialMediaAccountDataFields
  }
  spotify {
    ...socialMediaAccountDataFields
  }
  mixcloud {
    ...socialMediaAccountDataFields
  }
}
    ${SocialMediaAccountDataFieldsFragmentDoc}`;
export const PageBasicFieldsFragmentDoc = gql`
    fragment pageBasicFields on Page {
  id
  name
  category
  presentation
  price
  placeData {
    placeId
    formattedAddress
    components
    name
    geometry {
      location {
        lat
        lng
      }
      viewport {
        northeast {
          lat
          lng
        }
        southwest {
          lat
          lng
        }
      }
    }
  }
}
    `;
export const SocialMediaAccountMediaFieldsFragmentDoc = gql`
    fragment socialMediaAccountMediaFields on SocialMediaAccountMedia {
  id
  type
}
    `;
export const ArtistReviewFieldsFragmentDoc = gql`
    fragment artistReviewFields on ArtistReview {
  id
  page {
    ...pageFields
  }
  user {
    ...userFields
  }
  reviewer {
    name
    email
    company
  }
  reviewDate
  status
  message
  recommended
}
    ${PageFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;
export const AddMotdDocument = gql`
    mutation addMOTD($message: String!, $severity: MOTDLevelEnum!, $endDate: String) {
  addMOTD(message: $message, severity: $severity, endDate: $endDate) {
    ...motdFields
  }
}
    ${MotdFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddMotdGQL extends Apollo.Mutation<AddMotdMutation, AddMotdMutationVariables> {
    document = AddMotdDocument;
    
  }
export const AddPageFaqQuestionTemplateDocument = gql`
    mutation addPageFaqQuestionTemplate($question: PageFaqQuestionTemplateInput) {
  addPageFaqQuestionTemplate(question: $question) {
    questions {
      lang
      question
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddPageFaqQuestionTemplateGQL extends Apollo.Mutation<AddPageFaqQuestionTemplateMutation, AddPageFaqQuestionTemplateMutationVariables> {
    document = AddPageFaqQuestionTemplateDocument;
    
  }
export const AdminAddBlogTagDocument = gql`
    mutation AdminAddBlogTag($orgId: Int!, $tag: String!) {
  adminAddBlogTag(orgId: $orgId, tag: $tag) {
    ...adminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminAddBlogTagGQL extends Apollo.Mutation<AdminAddBlogTagMutation, AdminAddBlogTagMutationVariables> {
    document = AdminAddBlogTagDocument;
    
  }
export const AdminAddGigTemplateToOrgDocument = gql`
    mutation AdminAddGigTemplateToOrg($id: Int!, $gig: AdminGigTemplateInput!) {
  adminAddGigTemplateToOrg(id: $id, gig: $gig) {
    ...adminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminAddGigTemplateToOrgGQL extends Apollo.Mutation<AdminAddGigTemplateToOrgMutation, AdminAddGigTemplateToOrgMutationVariables> {
    document = AdminAddGigTemplateToOrgDocument;
    
  }
export const AdminAddOrgEmailPatternDocument = gql`
    mutation AdminAddOrgEmailPattern($orgId: Int!, $pattern: String!) {
  adminAddOrgEmailPattern(orgId: $orgId, pattern: $pattern) {
    ...adminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminAddOrgEmailPatternGQL extends Apollo.Mutation<AdminAddOrgEmailPatternMutation, AdminAddOrgEmailPatternMutationVariables> {
    document = AdminAddOrgEmailPatternDocument;
    
  }
export const AdminAddUserToOrgDocument = gql`
    mutation AdminAddUserToOrg($id: Int!, $userId: Int!) {
  adminAddUserToOrg(id: $id, userId: $userId) {
    ...adminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminAddUserToOrgGQL extends Apollo.Mutation<AdminAddUserToOrgMutation, AdminAddUserToOrgMutationVariables> {
    document = AdminAddUserToOrgDocument;
    
  }
export const AdminCancelBookingDocument = gql`
    mutation adminCancelBooking($bookingId: Int!) {
  adminCancelBooking(bookingId: $bookingId) {
    ...bookingFields
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminCancelBookingGQL extends Apollo.Mutation<AdminCancelBookingMutation, AdminCancelBookingMutationVariables> {
    document = AdminCancelBookingDocument;
    
  }
export const AdminCreateOrganizatonDocument = gql`
    mutation adminCreateOrganizaton($orgName: String!, $orgId: String!) {
  adminCreateOrg(orgName: $orgName, orgId: $orgId) {
    ...adminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminCreateOrganizatonGQL extends Apollo.Mutation<AdminCreateOrganizatonMutation, AdminCreateOrganizatonMutationVariables> {
    document = AdminCreateOrganizatonDocument;
    
  }
export const AdminGetReviewByIdDocument = gql`
    query adminGetReviewById($reviewId: Int!) {
  adminReviewById(reviewId: $reviewId) {
    id
    recommended
    message
    playDate
    reviewDate
    bookingDate {
      booking {
        id
      }
      price
      priceCurrency
      date
    }
    page {
      id
      name
      profilePicture {
        ...imageFields
      }
    }
    user {
      id
      firstName
      lastName
      email
    }
    reviewer {
      name
      company
      email
    }
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminGetReviewByIdGQL extends Apollo.Query<AdminGetReviewByIdQuery, AdminGetReviewByIdQueryVariables> {
    document = AdminGetReviewByIdDocument;
    
  }
export const GetAdminsDocument = gql`
    query getAdmins {
  admins {
    ...adminUserFields
  }
}
    ${AdminUserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAdminsGQL extends Apollo.Query<GetAdminsQuery, GetAdminsQueryVariables> {
    document = GetAdminsDocument;
    
  }
export const AdminGetBookingPaymentStatusDocument = gql`
    query adminGetBookingPaymentStatus($bookingId: Int!) {
  adminGetBookingPaymentStatus(bookingId: $bookingId) {
    paid
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminGetBookingPaymentStatusGQL extends Apollo.Query<AdminGetBookingPaymentStatusQuery, AdminGetBookingPaymentStatusQueryVariables> {
    document = AdminGetBookingPaymentStatusDocument;
    
  }
export const AdminGetBookingsByPageDocument = gql`
    query adminGetBookingsByPage($pageId: Int!) {
  adminGetBookingsByPage(pageId: $pageId) {
    ...bookingFields
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminGetBookingsByPageGQL extends Apollo.Query<AdminGetBookingsByPageQuery, AdminGetBookingsByPageQueryVariables> {
    document = AdminGetBookingsByPageDocument;
    
  }
export const GetExistingPagePlacesDocument = gql`
    query getExistingPagePlaces {
  getExistingPagePlaces {
    id
    name
    group
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetExistingPagePlacesGQL extends Apollo.Query<GetExistingPagePlacesQuery, GetExistingPagePlacesQueryVariables> {
    document = GetExistingPagePlacesDocument;
    
  }
export const AdminGetGigsDocument = gql`
    query adminGetGigs($paging: Pagination!) {
  adminGetGigs(paging: $paging) {
    total
    edges {
      ...adminGigFields
    }
  }
}
    ${AdminGigFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminGetGigsGQL extends Apollo.Query<AdminGetGigsQuery, AdminGetGigsQueryVariables> {
    document = AdminGetGigsDocument;
    
  }
export const AdminFindOneInviteDocument = gql`
    query adminFindOneInvite($publicId: String, $id: Int) {
  adminFindOneInvite(publicId: $publicId, id: $id) {
    id
    publicId
    email
    inviterUser {
      ...userFields
    }
    acceptedUser {
      ...userFields
    }
    review {
      id
      reviewer {
        name
        company
        email
      }
      message
      recommended
      canUsePublic
    }
  }
}
    ${UserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminFindOneInviteGQL extends Apollo.Query<AdminFindOneInviteQuery, AdminFindOneInviteQueryVariables> {
    document = AdminFindOneInviteDocument;
    
  }
export const AdminGetOrganizationsDocument = gql`
    query adminGetOrganizations($paging: Pagination!) {
  adminFindAllOrgs(paging: $paging) {
    organizations {
      ...adminOrganizationFields
    }
    total
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminGetOrganizationsGQL extends Apollo.Query<AdminGetOrganizationsQuery, AdminGetOrganizationsQueryVariables> {
    document = AdminGetOrganizationsDocument;
    
  }
export const AdminGetReviewsDocument = gql`
    query adminGetReviews($onlyReviewed: Boolean, $recommended: Boolean!, $paging: Pagination!) {
  adminReviews(onlyReviewed: $onlyReviewed, recommended: $recommended, paging: $paging) {
    reviews {
      id
      recommended
      message
      playDate
      reviewDate
      bookingDate {
        booking {
          id
        }
        price
        priceCurrency
        date
      }
      page {
        id
        name
        profilePicture {
          ...imageFields
        }
      }
      user {
        id
        firstName
        lastName
        email
      }
      reviewer {
        name
        company
        email
      }
    }
    skip
    total
    hasNextPage
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminGetReviewsGQL extends Apollo.Query<AdminGetReviewsQuery, AdminGetReviewsQueryVariables> {
    document = AdminGetReviewsDocument;
    
  }
export const AdminGetUserByIdDocument = gql`
    query adminGetUserById($userId: Int) {
  byId(userId: $userId) {
    ...adminUserFields
    bookings {
      id
      type
      page {
        id
        name
        user {
          email
        }
      }
      travelPrice
      calculatedPricing {
        ...bookingCalculatedPricing
      }
    }
    sentInvites {
      id
      email
      publicId
      claimed
    }
    claimedInvites {
      id
      email
      publicId
      claimed
    }
    reviews {
      id
      recommended
      reviewDate
      playDate
      message
      page {
        id
        name
      }
    }
  }
}
    ${AdminUserFieldsFragmentDoc}
${BookingCalculatedPricingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminGetUserByIdGQL extends Apollo.Query<AdminGetUserByIdQuery, AdminGetUserByIdQueryVariables> {
    document = AdminGetUserByIdDocument;
    
  }
export const AdminGetUsersDocument = gql`
    query adminGetUsers($paging: Pagination, $sorting: String, $type: UserType, $searchString: String) {
  allUsers(paging: $paging, sorting: $sorting, type: $type, searchString: $searchString) {
    users {
      id
      firstName
      lastName
      email
      companyName
      createdDate
      dateModified
      fee
      phoneNumber
      phonePrefix
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminGetUsersGQL extends Apollo.Query<AdminGetUsersQuery, AdminGetUsersQueryVariables> {
    document = AdminGetUsersDocument;
    
  }
export const AdminGigByIdDocument = gql`
    query adminGigById($gigId: Int!) {
  adminGigById(gigId: $gigId) {
    ...adminGigFields
  }
}
    ${AdminGigFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminGigByIdGQL extends Apollo.Query<AdminGigByIdQuery, AdminGigByIdQueryVariables> {
    document = AdminGigByIdDocument;
    
  }
export const AdminInfoDocument = gql`
    query adminInfo {
  adminInfo {
    daysBookings {
      id
    }
    weekBookings {
      id
    }
    monthBookings {
      id
    }
    yearBookings {
      id
    }
    currentYearBookings {
      id
    }
    daysPages {
      id
    }
    weekPages {
      id
    }
    monthPages {
      id
    }
    yearPages {
      id
    }
    currentYearPages {
      id
    }
    daysUsers {
      id
    }
    weekUsers {
      id
    }
    monthUsers {
      id
    }
    yearUsers {
      id
    }
    currentYearUsers {
      id
    }
    daysGigs {
      id
    }
    weekGigs {
      id
    }
    monthGigs {
      id
    }
    yearGigs {
      id
    }
    currentYearGigs {
      id
    }
    loggedIn {
      id
      firstName
      lastName
      email
    }
    admins {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminInfoGQL extends Apollo.Query<AdminInfoQuery, AdminInfoQueryVariables> {
    document = AdminInfoDocument;
    
  }
export const AdminGetInvitesListDocument = gql`
    query adminGetInvitesList($paging: Pagination!) {
  adminGetInvites(paging: $paging) {
    edges {
      id
      publicId
      email
      claimed
      inviterUser {
        id
        firstName
        lastName
      }
      review {
        id
      }
    }
    total
    hasNextPage
    skip
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminGetInvitesListGQL extends Apollo.Query<AdminGetInvitesListQuery, AdminGetInvitesListQueryVariables> {
    document = AdminGetInvitesListDocument;
    
  }
export const GetLoggedInUsersDocument = gql`
    query getLoggedInUsers {
  getLoggedInUsers {
    ...adminUserFields
  }
}
    ${AdminUserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLoggedInUsersGQL extends Apollo.Query<GetLoggedInUsersQuery, GetLoggedInUsersQueryVariables> {
    document = GetLoggedInUsersDocument;
    
  }
export const AdminRemoveBlogTagDocument = gql`
    mutation AdminRemoveBlogTag($orgId: Int!, $tag: String!) {
  adminRemoveBlogTag(orgId: $orgId, tag: $tag) {
    ...adminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminRemoveBlogTagGQL extends Apollo.Mutation<AdminRemoveBlogTagMutation, AdminRemoveBlogTagMutationVariables> {
    document = AdminRemoveBlogTagDocument;
    
  }
export const AdminRemoveBookingDatesDocument = gql`
    mutation adminRemoveBookingDates($dateIds: [Int!]!) {
  adminRemoveBookingDates(dateIds: $dateIds) {
    ...bookingFields
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminRemoveBookingDatesGQL extends Apollo.Mutation<AdminRemoveBookingDatesMutation, AdminRemoveBookingDatesMutationVariables> {
    document = AdminRemoveBookingDatesDocument;
    
  }
export const AdminRemoveOrgEmailPatternDocument = gql`
    mutation AdminRemoveOrgEmailPattern($orgId: Int!, $pattern: String!) {
  adminRemoveOrgEmailPattern(orgId: $orgId, pattern: $pattern) {
    ...adminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminRemoveOrgEmailPatternGQL extends Apollo.Mutation<AdminRemoveOrgEmailPatternMutation, AdminRemoveOrgEmailPatternMutationVariables> {
    document = AdminRemoveOrgEmailPatternDocument;
    
  }
export const AdminRemoveUserFromOrgDocument = gql`
    mutation AdminRemoveUserFromOrg($id: Int!, $userId: Int!) {
  adminRemoveUserFromOrganization(id: $id, userId: $userId) {
    ...adminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminRemoveUserFromOrgGQL extends Apollo.Mutation<AdminRemoveUserFromOrgMutation, AdminRemoveUserFromOrgMutationVariables> {
    document = AdminRemoveUserFromOrgDocument;
    
  }
export const AdminSearchBookingDocument = gql`
    query adminSearchBooking($searchText: String!, $searchOps: BookingSearchOps!, $paging: Pagination, $sorting: String) {
  adminSearchBookings(searchText: $searchText, searchOps: $searchOps, paging: $paging, sorting: $sorting) {
    bookings {
      id
      status
      createdDate
      message
      supplies
      amountPaid
      calculatedPricing {
        ...bookingCalculatedPricing
      }
      billingInfo {
        ...billingFields
      }
      supplies
      supplyStatus
      supplyPrice
      travelPrice
      newTypes
      user {
        ...userFields
      }
      page {
        ...pageFields
        fee
      }
      appliedTo {
        ...gigFields
      }
      channel {
        ...channelFields
      }
      dates {
        ...bookingDatesFields
      }
      place {
        placeId
        formattedAddress
        components
        name
      }
      others {
        addedBy
        signedBy
        createdDate
        others
      }
    }
    meta
    price
  }
}
    ${BookingCalculatedPricingFragmentDoc}
${BillingFieldsFragmentDoc}
${UserFieldsFragmentDoc}
${PageFieldsFragmentDoc}
${GigFieldsFragmentDoc}
${ChannelFieldsFragmentDoc}
${BookingDatesFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminSearchBookingGQL extends Apollo.Query<AdminSearchBookingQuery, AdminSearchBookingQueryVariables> {
    document = AdminSearchBookingDocument;
    
  }
export const AdminSearchPageDocument = gql`
    query adminSearchPage($text: String!) {
  adminPageTextSearch(textQuery: $text) {
    ...pageFields
    dateModified
    createdDate
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminSearchPageGQL extends Apollo.Query<AdminSearchPageQuery, AdminSearchPageQueryVariables> {
    document = AdminSearchPageDocument;
    
  }
export const SearchReviewDocument = gql`
    query searchReview($term: String!) {
  adminSearchReview(term: $term) {
    id
    recommended
    message
    bookingDate {
      booking {
        id
      }
      price
      priceCurrency
      date
    }
    page {
      id
      name
      profilePicture {
        ...imageFields
      }
    }
    user {
      id
      firstName
      lastName
      email
    }
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchReviewGQL extends Apollo.Query<SearchReviewQuery, SearchReviewQueryVariables> {
    document = SearchReviewDocument;
    
  }
export const AdminUpdateBookingPricesDocument = gql`
    mutation adminUpdateBookingPrices($id: Int!, $dates: [AdminBookingDatePrice!], $travel: Int!, $supplies: Int!) {
  adminUpdateBookingPrices(id: $id, dates: $dates, travel: $travel, supplies: $supplies) {
    id
    dates {
      id
      price
    }
    travelPrice
    supplyPrice
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminUpdateBookingPricesGQL extends Apollo.Mutation<AdminUpdateBookingPricesMutation, AdminUpdateBookingPricesMutationVariables> {
    document = AdminUpdateBookingPricesDocument;
    
  }
export const AdminUpdateGigTemplateDocument = gql`
    mutation adminUpdateGigTemplate($id: Int!, $gig: AdminGigTemplateInput!) {
  adminUpdateGigTemplate(id: $id, gig: $gig) {
    ...adminGigTemplateFields
  }
}
    ${AdminGigTemplateFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminUpdateGigTemplateGQL extends Apollo.Mutation<AdminUpdateGigTemplateMutation, AdminUpdateGigTemplateMutationVariables> {
    document = AdminUpdateGigTemplateDocument;
    
  }
export const AdminUpdateOrganizationDocument = gql`
    mutation adminUpdateOrganization($id: Int!, $editInput: AdminOrgEditInput!) {
  adminEditOrg(id: $id, editInput: $editInput) {
    ...adminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminUpdateOrganizationGQL extends Apollo.Mutation<AdminUpdateOrganizationMutation, AdminUpdateOrganizationMutationVariables> {
    document = AdminUpdateOrganizationDocument;
    
  }
export const AdminUserSearchDocument = gql`
    query adminUserSearch($searchText: String!) {
  userSearch(searchText: $searchText) {
    ...adminUserFields
    bookings {
      id
      type
      page {
        id
        name
      }
    }
    sentInvites {
      id
      email
      publicId
      claimed
    }
    claimedInvites {
      id
      email
      publicId
      claimed
    }
    reviews {
      id
      recommended
      playDate
      message
      page {
        id
        name
      }
    }
  }
}
    ${AdminUserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminUserSearchGQL extends Apollo.Query<AdminUserSearchQuery, AdminUserSearchQueryVariables> {
    document = AdminUserSearchDocument;
    
  }
export const CreateListDocument = gql`
    mutation createList($list: PageListInput) {
  createPageList(list: $list) {
    ...listFields
  }
}
    ${ListFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateListGQL extends Apollo.Mutation<CreateListMutation, CreateListMutationVariables> {
    document = CreateListDocument;
    
  }
export const DeleteCacheKeyDocument = gql`
    mutation deleteCacheKey($key: String) {
  deleteCacheKey(key: $key) {
    ...cacheFields
  }
}
    ${CacheFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCacheKeyGQL extends Apollo.Mutation<DeleteCacheKeyMutation, DeleteCacheKeyMutationVariables> {
    document = DeleteCacheKeyDocument;
    
  }
export const DeletePageFaqTemplateDocument = gql`
    mutation deletePageFaqTemplate($id: Int!) {
  deletePageFaqQuestionTemplate(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePageFaqTemplateGQL extends Apollo.Mutation<DeletePageFaqTemplateMutation, DeletePageFaqTemplateMutationVariables> {
    document = DeletePageFaqTemplateDocument;
    
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: Int!) {
  deleteUser(userId: $userId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
  }
export const FlushCacheDocument = gql`
    mutation flushCache {
  cacheFlush {
    ...cacheFields
  }
}
    ${CacheFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FlushCacheGQL extends Apollo.Mutation<FlushCacheMutation, FlushCacheMutationVariables> {
    document = FlushCacheDocument;
    
  }
export const GetAdminArtistsDocument = gql`
    query getAdminArtists($paging: Pagination, $sorting: String, $filter: AdminPagesFilter) {
  getPagesAdmin(paging: $paging, sorting: $sorting, filter: $filter) {
    pages {
      ...artistAdminFields
      fee
    }
    meta {
      total
    }
  }
}
    ${ArtistAdminFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAdminArtistsGQL extends Apollo.Query<GetAdminArtistsQuery, GetAdminArtistsQueryVariables> {
    document = GetAdminArtistsDocument;
    
  }
export const GetCacheHealthDocument = gql`
    query getCacheHealth {
  cacheHealth {
    ...cacheFields
  }
}
    ${CacheFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCacheHealthGQL extends Apollo.Query<GetCacheHealthQuery, GetCacheHealthQueryVariables> {
    document = GetCacheHealthDocument;
    
  }
export const GetEventLogsDocument = gql`
    query getEventLogs($filter: EventLogsFilter, $paging: Pagination) {
  getLoggedEvents(filter: $filter, paging: $paging) {
    events {
      name
      action
      initiator {
        id
        firstName
        lastName
      }
      level
      entityRefId
      data
      createdDate
    }
    count
    hasNextPage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventLogsGQL extends Apollo.Query<GetEventLogsQuery, GetEventLogsQueryVariables> {
    document = GetEventLogsDocument;
    
  }
export const GetEventsDocument = gql`
    query getEvents {
  getEvents {
    name
    metadata
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventsGQL extends Apollo.Query<GetEventsQuery, GetEventsQueryVariables> {
    document = GetEventsDocument;
    
  }
export const GetPageFaqTemplatesDocument = gql`
    query getPageFaqTemplates($paging: Pagination!) {
  getPageFaqTemplates(paging: $paging) {
    templates {
      ...pageFaqQuestionTemplateFields
    }
    count
  }
}
    ${PageFaqQuestionTemplateFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPageFaqTemplatesGQL extends Apollo.Query<GetPageFaqTemplatesQuery, GetPageFaqTemplatesQueryVariables> {
    document = GetPageFaqTemplatesDocument;
    
  }
export const DownloadUserDataDocument = gql`
    mutation downloadUserData($userId: Int!) {
  getDownloadableData(userId: $userId) {
    ...adminUserFields
  }
}
    ${AdminUserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DownloadUserDataGQL extends Apollo.Mutation<DownloadUserDataMutation, DownloadUserDataMutationVariables> {
    document = DownloadUserDataDocument;
    
  }
export const SendEventDocument = gql`
    mutation sendEvent($event: EventInput!, $userId: Int!, $createdAt: Date) {
  sendEvent(event: $event, userId: $userId, createdAt: $createdAt) {
    success
    event {
      name
      metadata
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendEventGQL extends Apollo.Mutation<SendEventMutation, SendEventMutationVariables> {
    document = SendEventDocument;
    
  }
export const SetMotdActiveDocument = gql`
    mutation setMOTDActive($motdId: Int!, $active: Boolean!) {
  setMOTDActive(motdId: $motdId, active: $active) {
    ...motdFields
  }
}
    ${MotdFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetMotdActiveGQL extends Apollo.Mutation<SetMotdActiveMutation, SetMotdActiveMutationVariables> {
    document = SetMotdActiveDocument;
    
  }
export const SetPageOwnershipDocument = gql`
    mutation setPageOwnership($pageId: Int!, $ownership: PageOwnership!) {
  setPageOwnership(pageId: $pageId, ownership: $ownership) {
    id
    ownership
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetPageOwnershipGQL extends Apollo.Mutation<SetPageOwnershipMutation, SetPageOwnershipMutationVariables> {
    document = SetPageOwnershipDocument;
    
  }
export const SetReviewStatusDocument = gql`
    mutation setReviewStatus($pageId: Int!, $status: ReviewStatusEnum!, $denyReasons: [DenyReasonInput!]) {
  setPageReviewStatus(pageId: $pageId, status: $status, reasons: $denyReasons) {
    ...pageFields
    dateModified
    createdDate
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetReviewStatusGQL extends Apollo.Mutation<SetReviewStatusMutation, SetReviewStatusMutationVariables> {
    document = SetReviewStatusDocument;
    
  }
export const UpdateBookingStatusDocument = gql`
    mutation UpdateBookingStatus($bookingId: Int!, $status: BookingStatusEnum!) {
  adminUpdateBookingStatus(bookingId: $bookingId, status: $status) {
    id
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookingStatusGQL extends Apollo.Mutation<UpdateBookingStatusMutation, UpdateBookingStatusMutationVariables> {
    document = UpdateBookingStatusDocument;
    
  }
export const UpdatePageListDocument = gql`
    mutation updatePageList($listId: Int!, $list: PageListInput) {
  updatePageList(listId: $listId, list: $list) {
    ...listFields
  }
}
    ${ListFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageListGQL extends Apollo.Mutation<UpdatePageListMutation, UpdatePageListMutationVariables> {
    document = UpdatePageListDocument;
    
  }
export const UpdateOrderDocument = gql`
    mutation updateOrder($orders: [OrderUpdateInput]) {
  updateOrder(orders: $orders) {
    ...pageFields
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrderGQL extends Apollo.Mutation<UpdateOrderMutation, UpdateOrderMutationVariables> {
    document = UpdateOrderDocument;
    
  }
export const UpdatePageFeeDocument = gql`
    mutation UpdatePageFee($pageId: Int!, $fee: Float!) {
  updatePageFee(pageId: $pageId, fee: $fee) {
    id
    fee
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageFeeGQL extends Apollo.Mutation<UpdatePageFeeMutation, UpdatePageFeeMutationVariables> {
    document = UpdatePageFeeDocument;
    
  }
export const UpdateUserFeeDocument = gql`
    mutation updateUserFee($userId: Int!, $fee: Float!) {
  updateUserFee(userId: $userId, fee: $fee) {
    id
    fee
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserFeeGQL extends Apollo.Mutation<UpdateUserFeeMutation, UpdateUserFeeMutationVariables> {
    document = UpdateUserFeeDocument;
    
  }
export const UpdateUserTypeDocument = gql`
    mutation UpdateUserType($userId: Int!, $type: UserType!) {
  adminUpdateUserType(userId: $userId, type: $type) {
    id
    firstName
    lastName
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserTypeGQL extends Apollo.Mutation<UpdateUserTypeMutation, UpdateUserTypeMutationVariables> {
    document = UpdateUserTypeDocument;
    
  }
export const AcceptBookingDatesDocument = gql`
    mutation acceptBookingDates($accepts: [Int!], $declines: [Int!]) {
  acceptBookingDates(accepts: $accepts, declines: $declines) {
    dates {
      ...bookingDatesFields
    }
  }
}
    ${BookingDatesFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptBookingDatesGQL extends Apollo.Mutation<AcceptBookingDatesMutation, AcceptBookingDatesMutationVariables> {
    document = AcceptBookingDatesDocument;
    
  }
export const CancelBookingDocument = gql`
    mutation cancelBooking($cancelReason: CancelReason!, $message: String, $bookingId: Int!) {
  cancelBooking(cancelReason: $cancelReason, message: $message, bookingId: $bookingId) {
    id
    status
    signatures {
      id
      createdDate
      user {
        id
        firstName
        lastName
      }
      valid
      isBooker
      type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelBookingGQL extends Apollo.Mutation<CancelBookingMutation, CancelBookingMutationVariables> {
    document = CancelBookingDocument;
    
  }
export const CancelBookingDatesDocument = gql`
    mutation cancelBookingDates($dateIds: [Int!], $isCancelling: Boolean!, $reason: String) {
  cancelBookingDates(dateIds: $dateIds, isCancelling: $isCancelling, reason: $reason) {
    dates {
      ...bookingDatesFields
    }
  }
}
    ${BookingDatesFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelBookingDatesGQL extends Apollo.Mutation<CancelBookingDatesMutation, CancelBookingDatesMutationVariables> {
    document = CancelBookingDatesDocument;
    
  }
export const CreateBookingDocument = gql`
    mutation createBooking($booking: BookingInput!) {
  createBooking(booking: $booking) {
    ...bookingFields
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBookingGQL extends Apollo.Mutation<CreateBookingMutation, CreateBookingMutationVariables> {
    document = CreateBookingDocument;
    
  }
export const DeclineBookingDocument = gql`
    mutation declineBooking($cancelReason: CancelReason!, $message: String, $bookingId: Int!) {
  declineBooking(cancelReason: $cancelReason, message: $message, bookingId: $bookingId) {
    id
    status
    signatures {
      id
      createdDate
      user {
        id
        firstName
        lastName
      }
      valid
      isBooker
      type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeclineBookingGQL extends Apollo.Mutation<DeclineBookingMutation, DeclineBookingMutationVariables> {
    document = DeclineBookingDocument;
    
  }
export const GetBookingByIdDocument = gql`
    query getBookingById($bookingId: Int!) {
  bookingById(bookingId: $bookingId) {
    ...bookingFields
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBookingByIdGQL extends Apollo.Query<GetBookingByIdQuery, GetBookingByIdQueryVariables> {
    document = GetBookingByIdDocument;
    
  }
export const GetBookingsCountDocument = gql`
    query getBookingsCount {
  bookingsCount {
    open
    closed
    signed
    others
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBookingsCountGQL extends Apollo.Query<GetBookingsCountQuery, GetBookingsCountQueryVariables> {
    document = GetBookingsCountDocument;
    
  }
export const GetDashboardBookingsDocument = gql`
    query GetDashboardBookings {
  dashboardBookings {
    pending {
      ...bookingFields
    }
    pendingCount
    upcomingDates {
      ...bookingDatesFields
      booking {
        ...bookingFields
      }
    }
    upcomingCount
  }
}
    ${BookingFieldsFragmentDoc}
${BookingDatesFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDashboardBookingsGQL extends Apollo.Query<GetDashboardBookingsQuery, GetDashboardBookingsQueryVariables> {
    document = GetDashboardBookingsDocument;
    
  }
export const GetUserBookingsDocument = gql`
    query getUserBookings($type: BookingFeedType) {
  bookingsByUser(type: $type) {
    open {
      ...bookingEdges
    }
    closed {
      ...bookingEdges
    }
    signed {
      ...bookingEdges
    }
    others {
      ...bookingEdges
    }
    openLength
  }
}
    ${BookingEdgesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserBookingsGQL extends Apollo.Query<GetUserBookingsQuery, GetUserBookingsQueryVariables> {
    document = GetUserBookingsDocument;
    
  }
export const GetReviewsByUserDocument = gql`
    query getReviewsByUser {
  reviewsByUser {
    id
    canUsePublic
    visible
    recommended
    page {
      id
      name
      profilePicture {
        ...imageFields
      }
      slug
    }
    bookingDate {
      id
      date
      booking {
        id
        type
      }
    }
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReviewsByUserGQL extends Apollo.Query<GetReviewsByUserQuery, GetReviewsByUserQueryVariables> {
    document = GetReviewsByUserDocument;
    
  }
export const SignBookingDocument = gql`
    mutation signBooking($bookingId: Int!) {
  signBooking(bookingId: $bookingId) {
    ...bookingFields
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SignBookingGQL extends Apollo.Mutation<SignBookingMutation, SignBookingMutationVariables> {
    document = SignBookingDocument;
    
  }
export const SubscribeToBookingUpdatesDocument = gql`
    subscription subscribeToBookingUpdates($bookingId: Int!) {
  bookingChanges(bookingId: $bookingId) {
    ...bookingFields
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscribeToBookingUpdatesGQL extends Apollo.Subscription<SubscribeToBookingUpdatesSubscription, SubscribeToBookingUpdatesSubscriptionVariables> {
    document = SubscribeToBookingUpdatesDocument;
    
  }
export const UpdateBookingDocument = gql`
    mutation updateBooking($bookingId: Int!, $booking: BookingInput!) {
  updateBooking(booking: $booking, bookingId: $bookingId) {
    ...bookingFields
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookingGQL extends Apollo.Mutation<UpdateBookingMutation, UpdateBookingMutationVariables> {
    document = UpdateBookingDocument;
    
  }
export const GetChannelByIdDocument = gql`
    query getChannelById($channelId: Int!) {
  channelById(channelId: $channelId) {
    ...channelFields
  }
}
    ${ChannelFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetChannelByIdGQL extends Apollo.Query<GetChannelByIdQuery, GetChannelByIdQueryVariables> {
    document = GetChannelByIdDocument;
    
  }
export const SubscribeToChannelDocument = gql`
    subscription subscribeToChannel($channelId: Int!) {
  channelMessages(channelId: $channelId) {
    ...channelMessageFields
  }
}
    ${ChannelMessageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscribeToChannelGQL extends Apollo.Subscription<SubscribeToChannelSubscription, SubscribeToChannelSubscriptionVariables> {
    document = SubscribeToChannelDocument;
    
  }
export const GetArtistsListsDocument = gql`
    query getArtistsLists($id: String, $name: String, $locale: String!) {
  getContentfulEntry(id: $id, name: $name, locale: $locale) {
    contentfulData
    artistLists {
      listId
      pages {
        customDescription
        page {
          ...listFragments
        }
      }
    }
    artistHeros {
      customDescription
      page {
        ...listFragments
        backgroundPicture {
          ...imageFields
        }
      }
    }
  }
}
    ${ListFragmentsFragmentDoc}
${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetArtistsListsGQL extends Apollo.Query<GetArtistsListsQuery, GetArtistsListsQueryVariables> {
    document = GetArtistsListsDocument;
    
  }
export const GetContentfulEntryDocument = gql`
    query getContentfulEntry($id: String, $name: String, $locale: String!) {
  getContentfulEntry(id: $id, name: $name, locale: $locale) {
    contentfulData
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContentfulEntryGQL extends Apollo.Query<GetContentfulEntryQuery, GetContentfulEntryQueryVariables> {
    document = GetContentfulEntryDocument;
    
  }
export const GetListsDocument = gql`
    query getLists($country: CountriesEnum, $gigType: GigType) {
  getPageLists(country: $country, gigType: $gigType) {
    ...listFields
  }
}
    ${ListFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetListsGQL extends Apollo.Query<GetListsQuery, GetListsQueryVariables> {
    document = GetListsDocument;
    
  }
export const GetMotdDocument = gql`
    query getMOTD {
  motd {
    ...motdFields
  }
}
    ${MotdFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMotdGQL extends Apollo.Query<GetMotdQuery, GetMotdQueryVariables> {
    document = GetMotdDocument;
    
  }
export const GetUserFilesDocument = gql`
    query getUserFiles {
  filesByUser {
    ...fileFields
  }
}
    ${FileFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserFilesGQL extends Apollo.Query<GetUserFilesQuery, GetUserFilesQueryVariables> {
    document = GetUserFilesDocument;
    
  }
export const AcceptApplicationDocument = gql`
    mutation acceptApplication($accepts: [AcceptApplicationInput!]!) {
  acceptApplications(accepts: $accepts) {
    ...gigSlotFields
    gig {
      id
    }
  }
}
    ${GigSlotFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptApplicationGQL extends Apollo.Mutation<AcceptApplicationMutation, AcceptApplicationMutationVariables> {
    document = AcceptApplicationDocument;
    
  }
export const ApplicationsByUserDocument = gql`
    query applicationsByUser($type: ApplicationType!, $paging: Pagination!) {
  applicationsByUser(type: $type, paging: $paging) {
    edges {
      ...bookingDatesFields
      booking {
        ...bookingFields
      }
    }
    skip
    hasNextPage
    total
  }
}
    ${BookingDatesFieldsFragmentDoc}
${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplicationsByUserGQL extends Apollo.Query<ApplicationsByUserQuery, ApplicationsByUserQueryVariables> {
    document = ApplicationsByUserDocument;
    
  }
export const ApplyToGigDocument = gql`
    mutation applyToGig($application: GigApply!) {
  applyToGig(application: $application) {
    ...bookingFields
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplyToGigGQL extends Apollo.Mutation<ApplyToGigMutation, ApplyToGigMutationVariables> {
    document = ApplyToGigDocument;
    
  }
export const CancelApplicationDocument = gql`
    mutation cancelApplication($applicationId: Int!, $dateId: Int!) {
  cancelApplication(applicationId: $applicationId, dateId: $dateId) {
    ...bookingFields
    status
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelApplicationGQL extends Apollo.Mutation<CancelApplicationMutation, CancelApplicationMutationVariables> {
    document = CancelApplicationDocument;
    
  }
export const CompletedGigsDocument = gql`
    query completedGigs {
  completedGigs {
    gigs {
      ...gigFields
    }
    numAssigned
  }
}
    ${GigFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CompletedGigsGQL extends Apollo.Query<CompletedGigsQuery, CompletedGigsQueryVariables> {
    document = CompletedGigsDocument;
    
  }
export const CreateGigDocument = gql`
    mutation createGig($gig: GigInput!) {
  createGig(gig: $gig) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGigGQL extends Apollo.Mutation<CreateGigMutation, CreateGigMutationVariables> {
    document = CreateGigDocument;
    
  }
export const DeleteGigDocument = gql`
    mutation deleteGig($gigId: Int!, $deleted: Boolean!) {
  deleteGig(gigId: $gigId, deleted: $deleted) {
    id
    deleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGigGQL extends Apollo.Mutation<DeleteGigMutation, DeleteGigMutationVariables> {
    document = DeleteGigDocument;
    
  }
export const DenyApplicationDocument = gql`
    mutation denyApplication($applicationId: Int!, $slotId: Int!) {
  denyApplication(applicationId: $applicationId, slotId: $slotId) {
    ...bookingFields
    status
  }
}
    ${BookingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DenyApplicationGQL extends Apollo.Mutation<DenyApplicationMutation, DenyApplicationMutationVariables> {
    document = DenyApplicationDocument;
    
  }
export const GetDashboardGigsDocument = gql`
    query GetDashboardGigs {
  dashboardGigs {
    gigs {
      ...gigFields
      newApplications
    }
    count
  }
}
    ${GigFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDashboardGigsGQL extends Apollo.Query<GetDashboardGigsQuery, GetDashboardGigsQueryVariables> {
    document = GetDashboardGigsDocument;
    
  }
export const GetExistingGigPlacesDocument = gql`
    query getExistingGigPlaces {
  existingPlaces {
    place {
      types
      longName
    }
    amount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetExistingGigPlacesGQL extends Apollo.Query<GetExistingGigPlacesQuery, GetExistingGigPlacesQueryVariables> {
    document = GetExistingGigPlacesDocument;
    
  }
export const GetGigDocument = gql`
    query getGig($gigId: Int!, $paging: Pagination) {
  gigById(gigId: $gigId, paging: $paging) {
    gig {
      ...gigFields
      deleted
    }
    skip
    hasNextPage
  }
}
    ${GigFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGigGQL extends Apollo.Query<GetGigQuery, GetGigQueryVariables> {
    document = GetGigDocument;
    
  }
export const GetGigApplyToDocument = gql`
    query getGigApplyTo($gigId: Int!) {
  gigById(gigId: $gigId, paging: {limit: 10, skip: 0}) {
    gig {
      id
      name
      details
      placeId
      placeData
      type
      images {
        ...imageFields
      }
      slots {
        id
        date
        applications {
          page {
            id
            name
          }
        }
        price
      }
    }
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGigApplyToGQL extends Apollo.Query<GetGigApplyToQuery, GetGigApplyToQueryVariables> {
    document = GetGigApplyToDocument;
    
  }
export const GetGigsDocument = gql`
    query getGigs($paging: Pagination, $filter: GigFilter) {
  gigs(paging: $paging, filter: $filter) {
    edges {
      id
      name
      user {
        id
      }
      placeId
      deleted
      placeData
      type
      isOwner
      deadline
      slots {
        id
        date
        status
        deadline
        tags {
          ...tagFields
        }
      }
      images {
        ...imageFields
      }
      limitedData
      slotsCount
      slotsOpen
      availableTags {
        ...tagFields
      }
      extraTags
    }
    skip
    hasNextPage
    availableFilter {
      tags {
        tag {
          id
          name
        }
        amount
      }
      locations {
        place {
          longName
          types
        }
        amount
      }
      types {
        type
        amount
      }
    }
  }
}
    ${TagFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGigsGQL extends Apollo.Query<GetGigsQuery, GetGigsQueryVariables> {
    document = GetGigsDocument;
    
  }
export const GetOpenGigsDocument = gql`
    query getOpenGigs {
  openGigs {
    gigs
    slots
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOpenGigsGQL extends Apollo.Query<GetOpenGigsQuery, GetOpenGigsQueryVariables> {
    document = GetOpenGigsDocument;
    
  }
export const GetTemplatesDocument = gql`
    query getTemplates {
  getTemplates {
    id
    name
    details
    type
    deadline
    defaultPrice
    tags {
      id
      name
    }
    org {
      id
      name
      orgId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTemplatesGQL extends Apollo.Query<GetTemplatesQuery, GetTemplatesQueryVariables> {
    document = GetTemplatesDocument;
    
  }
export const GetGigsByUserDocument = gql`
    query getGigsByUser {
  gigsByUser {
    ...gigFields
    deleted
  }
}
    ${GigFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGigsByUserGQL extends Apollo.Query<GetGigsByUserQuery, GetGigsByUserQueryVariables> {
    document = GetGigsByUserDocument;
    
  }
export const UpdateGigDocument = gql`
    mutation updateGig($gig: GigInput!) {
  updateGig(gig: $gig) {
    ...gigFields
  }
}
    ${GigFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGigGQL extends Apollo.Mutation<UpdateGigMutation, UpdateGigMutationVariables> {
    document = UpdateGigDocument;
    
  }
export const ImageUploadDocument = gql`
    mutation imageUpload($image: Upload!, $cropData: ImageCropDataInput!) {
  imageUpload(image: $image, cropData: $cropData) {
    ...imageFields
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ImageUploadGQL extends Apollo.Mutation<ImageUploadMutation, ImageUploadMutationVariables> {
    document = ImageUploadDocument;
    
  }
export const ClaimInviteDocument = gql`
    mutation claimInvite($publicId: String!) {
  claimInvite(invitePublicId: $publicId) {
    id
    publicId
    claimed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClaimInviteGQL extends Apollo.Mutation<ClaimInviteMutation, ClaimInviteMutationVariables> {
    document = ClaimInviteDocument;
    
  }
export const DeleteInviteDocument = gql`
    mutation deleteInvite($inviteId: Int!) {
  deleteInvite(inviteId: $inviteId) {
    id
    deleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteInviteGQL extends Apollo.Mutation<DeleteInviteMutation, DeleteInviteMutationVariables> {
    document = DeleteInviteDocument;
    
  }
export const GetInvitesByUserDocument = gql`
    query getInvitesByUser($paging: Pagination!) {
  getInvitesByUser(paging: $paging) {
    total
    hasNextPage
    skip
    invites {
      deleted
      createdDate
      id
      email
      claimed
      expiryDate
      completed
      artistReview {
        id
        recommended
        reviewer {
          name
          company
          email
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvitesByUserGQL extends Apollo.Query<GetInvitesByUserQuery, GetInvitesByUserQueryVariables> {
    document = GetInvitesByUserDocument;
    
  }
export const GetReviewInviteDocument = gql`
    query getReviewInvite($publicId: String!) {
  inviteByPublicId(publicId: $publicId) {
    id
    publicId
    email
    claimed
    message
    artistReview {
      page {
        name
        slug
        id
        profilePicture {
          ...imageFields
        }
        tags {
          ...tagFields
        }
      }
      recommended
      message
      reviewDate
      reviewer {
        company
        name
        email
      }
    }
  }
}
    ${ImageFieldsFragmentDoc}
${TagFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReviewInviteGQL extends Apollo.Query<GetReviewInviteQuery, GetReviewInviteQueryVariables> {
    document = GetReviewInviteDocument;
    
  }
export const LeaveInviteReviewDocument = gql`
    mutation leaveInviteReview($input: LeaveInviteReviewInput!) {
  leaveExternalReview(input: $input) {
    id
    publicId
    artistReview {
      id
      recommended
      message
      reviewer {
        name
        company
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LeaveInviteReviewGQL extends Apollo.Mutation<LeaveInviteReviewMutation, LeaveInviteReviewMutationVariables> {
    document = LeaveInviteReviewDocument;
    
  }
export const SendReviewInviteDocument = gql`
    mutation sendReviewInvite($reviewInvite: ReviewInvite!) {
  addReviewInvite(reviewInvite: $reviewInvite) {
    id
    publicId
    email
    message
    artistReview {
      reviewer {
        name
        company
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendReviewInviteGQL extends Apollo.Mutation<SendReviewInviteMutation, SendReviewInviteMutationVariables> {
    document = SendReviewInviteDocument;
    
  }
export const GetNotificationsDocument = gql`
    query getNotifications($paging: Pagination, $getUnread: Boolean!) {
  getNotifications(paging: $paging, includeUnread: $getUnread) {
    notifications {
      ...notificationWithSubsFields
    }
    unread {
      ...notificationWithSubsFields
    }
    hasNextPage
    skip
  }
}
    ${NotificationWithSubsFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNotificationsGQL extends Apollo.Query<GetNotificationsQuery, GetNotificationsQueryVariables> {
    document = GetNotificationsDocument;
    
  }
export const SetNotificationsReadDocument = gql`
    mutation setNotificationsRead($notifications: [Int!], $all: Boolean) {
  setNotificationsRead(notificationIds: $notifications, all: $all) {
    ...notificationFields
  }
}
    ${NotificationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetNotificationsReadGQL extends Apollo.Mutation<SetNotificationsReadMutation, SetNotificationsReadMutationVariables> {
    document = SetNotificationsReadDocument;
    
  }
export const NotificationSubscriptionDocument = gql`
    subscription notificationSubscription {
  notification {
    ...notificationFields
  }
}
    ${NotificationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationSubscriptionGQL extends Apollo.Subscription<NotificationSubscriptionSubscription, NotificationSubscriptionSubscriptionVariables> {
    document = NotificationSubscriptionDocument;
    
  }
export const CreatePageDocument = gql`
    mutation createPage($page: PageInput!) {
  createPage(page: $page) {
    ...pageFields
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePageGQL extends Apollo.Mutation<CreatePageMutation, CreatePageMutationVariables> {
    document = CreatePageDocument;
    
  }
export const CreatePageFromSocialMediaDocument = gql`
    mutation createPageFromSocialMedia($page: SocialMediaAccountDataInput!) {
  createPageFromSocialMedia(page: $page) {
    ...pageFields
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePageFromSocialMediaGQL extends Apollo.Mutation<CreatePageFromSocialMediaMutation, CreatePageFromSocialMediaMutationVariables> {
    document = CreatePageFromSocialMediaDocument;
    
  }
export const FindOnePageNoReviewsDocument = gql`
    query findOnePageNoReviews($pageId: Int, $slug: String) {
  findOnePage(pageId: $pageId, slug: $slug) {
    ...pageFields
    fee
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindOnePageNoReviewsGQL extends Apollo.Query<FindOnePageNoReviewsQuery, FindOnePageNoReviewsQueryVariables> {
    document = FindOnePageNoReviewsDocument;
    
  }
export const GetFilterablePagePlacesDocument = gql`
    query getFilterablePagePlaces {
  getFilterablePagePlaces {
    name
    places {
      longName
      geometry {
        location {
          lng
          lat
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFilterablePagePlacesGQL extends Apollo.Query<GetFilterablePagePlacesQuery, GetFilterablePagePlacesQueryVariables> {
    document = GetFilterablePagePlacesDocument;
    
  }
export const GetFilteredPagesDocument = gql`
    query getFilteredPages($limit: Int, $skip: Int, $tags: [TagFilter], $priceMin: Int, $priceMax: Int, $sorting: String!, $textQuery: String, $category: PageCategory, $location: [LatLongInput!], $locationRadiusKm: Int, $includesEquipment: Boolean) {
  filterPages(filter: {tags: $tags, priceMin: $priceMin, priceMax: $priceMax, sorting: $sorting, textQuery: $textQuery, category: $category, location: $location, locationRadiusKm: $locationRadiusKm, includesEquipment: $includesEquipment}, paginate: {limit: $limit, skip: $skip}) {
    edges {
      id
      name
      slug
      positiveReviews
      category
      tags {
        ...tagFields
      }
      profilePicture {
        ...gigitalImage
      }
      links {
        id
        socialMediaId
        socialMediaTypeId
        url
        isHighlight
      }
      price
    }
    tagMatches {
      tagId
      count
    }
    count
    skip
    hasNextPage
    existingTags {
      ...tagFields
    }
    tagHits {
      tagId
      count
    }
  }
}
    ${TagFieldsFragmentDoc}
${GigitalImageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFilteredPagesGQL extends Apollo.Query<GetFilteredPagesQuery, GetFilteredPagesQueryVariables> {
    document = GetFilteredPagesDocument;
    
  }
export const GetImportablePageDataDocument = gql`
    query getImportablePageData($userName: String, $platforms: [SocialMediaAccountType!]!) {
  getImportablePageData(userName: $userName, platforms: $platforms) {
    ...importablePageFields
  }
}
    ${ImportablePageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetImportablePageDataGQL extends Apollo.Query<GetImportablePageDataQuery, GetImportablePageDataQueryVariables> {
    document = GetImportablePageDataDocument;
    
  }
export const GetOneListDocument = gql`
    query getOneList($idOrSlug: String!) {
  findOneList(idOrSlug: $idOrSlug) {
    ...listFields
  }
}
    ${ListFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOneListGQL extends Apollo.Query<GetOneListQuery, GetOneListQueryVariables> {
    document = GetOneListDocument;
    
  }
export const FindOnePageDocument = gql`
    query findOnePage($pageId: Int, $slug: String, $paging: Pagination!) {
  findOnePage(pageId: $pageId, slug: $slug) {
    ...pageFields
    fee
  }
  getPageReviews(pageId: $pageId, slug: $slug, paging: $paging) {
    reviews {
      id
      recommended
      message
      reviewer {
        name
        company
      }
      user {
        firstName
        lastName
        companyName
        reviewerName
      }
      reviewDate
      playDate
      bookingDate {
        booking {
          type
        }
      }
    }
    total
    skip
    hasNextPage
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindOnePageGQL extends Apollo.Query<FindOnePageQuery, FindOnePageQueryVariables> {
    document = FindOnePageDocument;
    
  }
export const GetPageGalleryDocument = gql`
    query getPageGallery($pageId: Int, $slug: String, $paging: Pagination!) {
  findOnePage(pageId: $pageId, slug: $slug) {
    gallery {
      ...imageFields
    }
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPageGalleryGQL extends Apollo.Query<GetPageGalleryQuery, GetPageGalleryQueryVariables> {
    document = GetPageGalleryDocument;
    
  }
export const GetPageMediaDocument = gql`
    query getPageMedia($pageId: Int) {
  findOnePage(pageId: $pageId) {
    links {
      ...pageLinkFields
    }
    gallery {
      ...imageFields
    }
  }
}
    ${PageLinkFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPageMediaGQL extends Apollo.Query<GetPageMediaQuery, GetPageMediaQueryVariables> {
    document = GetPageMediaDocument;
    
  }
export const GetPageReviewsDocument = gql`
    query getPageReviews($pageId: Int, $slug: String, $paging: Pagination!) {
  getPageReviews(pageId: $pageId, slug: $slug, paging: $paging) {
    reviews {
      recommended
      message
      reviewer {
        name
        company
      }
      user {
        firstName
        lastName
        companyName
        reviewerName
      }
      reviewDate
      playDate
      bookingDate {
        booking {
          type
        }
      }
    }
    total
    skip
    hasNextPage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPageReviewsGQL extends Apollo.Query<GetPageReviewsQuery, GetPageReviewsQueryVariables> {
    document = GetPageReviewsDocument;
    
  }
export const GetPageTagListsDocument = gql`
    query getPageTagLists($tagLists: [PageTagListInput]) {
  getPageTagLists(tagLists: $tagLists) {
    tagIds
    pages {
      id
      name
      category
      price
      slug
      profilePicture {
        ...imageFields
      }
      links {
        ...pageLinkFields
      }
    }
  }
}
    ${ImageFieldsFragmentDoc}
${PageLinkFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPageTagListsGQL extends Apollo.Query<GetPageTagListsQuery, GetPageTagListsQueryVariables> {
    document = GetPageTagListsDocument;
    
  }
export const GetPagesByUserDocument = gql`
    query getPagesByUser {
  pagesByUser {
    ...pageFields
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPagesByUserGQL extends Apollo.Query<GetPagesByUserQuery, GetPagesByUserQueryVariables> {
    document = GetPagesByUserDocument;
    
  }
export const GetPagesByUserLeanDocument = gql`
    query getPagesByUserLean {
  pagesByUser {
    id
    name
    profilePicture {
      ...imageFields
    }
    tags {
      id
      group
      name
    }
    links {
      id
    }
    price
    reviewStatus
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPagesByUserLeanGQL extends Apollo.Query<GetPagesByUserLeanQuery, GetPagesByUserLeanQueryVariables> {
    document = GetPagesByUserLeanDocument;
    
  }
export const GetTagsDocument = gql`
    query getTags {
  tags {
    ...tagFields
  }
}
    ${TagFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsGQL extends Apollo.Query<GetTagsQuery, GetTagsQueryVariables> {
    document = GetTagsDocument;
    
  }
export const SendPageForReviewDocument = gql`
    mutation SendPageForReview($pageId: Int!) {
  sendPageForReview(pageId: $pageId) {
    ...pageFields
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SendPageForReviewGQL extends Apollo.Mutation<SendPageForReviewMutation, SendPageForReviewMutationVariables> {
    document = SendPageForReviewDocument;
    
  }
export const SetPageArchivedDocument = gql`
    mutation setPageArchived($pageId: Int!, $archived: Boolean!) {
  setPageArchived(pageId: $pageId, archived: $archived) {
    id
    pageStatus
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetPageArchivedGQL extends Apollo.Mutation<SetPageArchivedMutation, SetPageArchivedMutationVariables> {
    document = SetPageArchivedDocument;
    
  }
export const UpdatePageDocument = gql`
    mutation updatePage($page: PageInput!) {
  updatePage(page: $page) {
    ...pageFields
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageGQL extends Apollo.Mutation<UpdatePageMutation, UpdatePageMutationVariables> {
    document = UpdatePageDocument;
    
  }
export const UpdatePageBasicsDocument = gql`
    mutation updatePageBasics($id: Int!, $name: String!, $category: PageCategory!, $placeId: String!, $canTravel: Boolean!, $price: Int!, $hasEquipment: Boolean!, $presentation: String!) {
  updatePageBasics(id: $id, name: $name, category: $category, placeId: $placeId, canTravel: $canTravel, price: $price, hasEquipment: $hasEquipment, presentation: $presentation) {
    ...pageBasicFields
  }
}
    ${PageBasicFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageBasicsGQL extends Apollo.Mutation<UpdatePageBasicsMutation, UpdatePageBasicsMutationVariables> {
    document = UpdatePageBasicsDocument;
    
  }
export const UpdatePageFaqDocument = gql`
    mutation updatePageFaq($pageId: Int!, $faq: [FaqInput!]!) {
  updatePageFaq(pageId: $pageId, faq: $faq) {
    faq {
      lang
      question
      answer
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageFaqGQL extends Apollo.Mutation<UpdatePageFaqMutation, UpdatePageFaqMutationVariables> {
    document = UpdatePageFaqDocument;
    
  }
export const UpdatePageWithSocialMediaDocument = gql`
    mutation updatePageWithSocialMedia($pageId: Int!, $socialMediaData: SocialMediaAccountDataInput!) {
  updatePageWithSocialMedia(pageId: $pageId, socialMediaData: $socialMediaData) {
    ...pageFields
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageWithSocialMediaGQL extends Apollo.Mutation<UpdatePageWithSocialMediaMutation, UpdatePageWithSocialMediaMutationVariables> {
    document = UpdatePageWithSocialMediaDocument;
    
  }
export const UpdatePageGalleryDocument = gql`
    mutation updatePageGallery($pageId: Int!, $images: [ImageInputWithOrder!]!) {
  updatePageGallery(pageId: $pageId, images: $images) {
    gallery {
      ...imageFields
    }
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageGalleryGQL extends Apollo.Mutation<UpdatePageGalleryMutation, UpdatePageGalleryMutationVariables> {
    document = UpdatePageGalleryDocument;
    
  }
export const UpdatePageImagesDocument = gql`
    mutation updatePageImages($pageId: Int!, $profilePicture: ImageInput, $profilePictureUrl: String, $backgroundPicture: ImageInput, $backgroundPictureUrl: String) {
  updatePageImages(pageId: $pageId, profilePicture: $profilePicture, profilePictureUrl: $profilePictureUrl, backgroundPicture: $backgroundPicture, backgroundPictureUrl: $backgroundPictureUrl) {
    profilePicture {
      ...imageFields
    }
    backgroundPicture {
      ...imageFields
    }
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageImagesGQL extends Apollo.Mutation<UpdatePageImagesMutation, UpdatePageImagesMutationVariables> {
    document = UpdatePageImagesDocument;
    
  }
export const UpdatePageMediaDocument = gql`
    mutation updatePageMedia($pageId: Int!, $links: [LinkInput!]!) {
  updatePageMedia(pageId: $pageId, links: $links) {
    links {
      ...pageLinkFields
    }
  }
}
    ${PageLinkFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageMediaGQL extends Apollo.Mutation<UpdatePageMediaMutation, UpdatePageMediaMutationVariables> {
    document = UpdatePageMediaDocument;
    
  }
export const UpdatePageSlugDocument = gql`
    mutation updatePageSlug($page: PageInput!) {
  updatePage(page: $page) {
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageSlugGQL extends Apollo.Mutation<UpdatePageSlugMutation, UpdatePageSlugMutationVariables> {
    document = UpdatePageSlugDocument;
    
  }
export const UpdatePageTagsDocument = gql`
    mutation updatePageTags($pageId: Int!, $tagIds: [Int!]!) {
  updatePageTags(pageId: $pageId, tagIds: $tagIds) {
    tags {
      ...tagFields
    }
  }
}
    ${TagFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageTagsGQL extends Apollo.Mutation<UpdatePageTagsMutation, UpdatePageTagsMutationVariables> {
    document = UpdatePageTagsDocument;
    
  }
export const CreateSetupIntentDocument = gql`
    mutation createSetupIntent {
  createSetupIntent
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSetupIntentGQL extends Apollo.Mutation<CreateSetupIntentMutation, CreateSetupIntentMutationVariables> {
    document = CreateSetupIntentDocument;
    
  }
export const GetPaymentHistoryDocument = gql`
    query getPaymentHistory {
  getPaymentHistory
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaymentHistoryGQL extends Apollo.Query<GetPaymentHistoryQuery, GetPaymentHistoryQueryVariables> {
    document = GetPaymentHistoryDocument;
    
  }
export const GetPaymentMethodsDocument = gql`
    query getPaymentMethods {
  getPaymentMethods
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaymentMethodsGQL extends Apollo.Query<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables> {
    document = GetPaymentMethodsDocument;
    
  }
export const RemovePaymentMethodDocument = gql`
    mutation removePaymentMethod($id: String!) {
  removePaymentMethod(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemovePaymentMethodGQL extends Apollo.Mutation<RemovePaymentMethodMutation, RemovePaymentMethodMutationVariables> {
    document = RemovePaymentMethodDocument;
    
  }
export const PostChannelMessageDocument = gql`
    mutation postChannelMessage($channelId: Int!, $body: String!, $file: Upload, $existingFileId: Int, $fileExtension: String) {
  postMessage(message: {channelId: $channelId, body: $body, file: $file, existingFileId: $existingFileId, fileExtension: $fileExtension}) {
    ...channelMessageFields
  }
}
    ${ChannelMessageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PostChannelMessageGQL extends Apollo.Mutation<PostChannelMessageMutation, PostChannelMessageMutationVariables> {
    document = PostChannelMessageDocument;
    
  }
export const EditReviewsAdminDocument = gql`
    mutation editReviewsAdmin($reviews: EditReviewInput!) {
  adminEditReviews(reviews: $reviews) {
    id
    message
    recommended
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditReviewsAdminGQL extends Apollo.Mutation<EditReviewsAdminMutation, EditReviewsAdminMutationVariables> {
    document = EditReviewsAdminDocument;
    
  }
export const LeaveReviewDocument = gql`
    mutation leaveReview($artistId: Int!, $input: ArtistReviewInput) {
  leaveReview(artistId: $artistId, input: $input) {
    id
    reviewer {
      name
      email
      company
    }
    reviewDate
    status
    message
    recommended
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LeaveReviewGQL extends Apollo.Mutation<LeaveReviewMutation, LeaveReviewMutationVariables> {
    document = LeaveReviewDocument;
    
  }
export const PossibleLeanReviewsDocument = gql`
    query possibleLeanReviews {
  possibleReviews {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PossibleLeanReviewsGQL extends Apollo.Query<PossibleLeanReviewsQuery, PossibleLeanReviewsQueryVariables> {
    document = PossibleLeanReviewsDocument;
    
  }
export const PossibleReviewsDocument = gql`
    query possibleReviews {
  possibleReviews {
    ...pageFields
  }
}
    ${PageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PossibleReviewsGQL extends Apollo.Query<PossibleReviewsQuery, PossibleReviewsQueryVariables> {
    document = PossibleReviewsDocument;
    
  }
export const GetReviewByIdDocument = gql`
    query getReviewById($id: Int!) {
  reviewById(id: $id) {
    id
    page {
      name
      profilePicture {
        ...imageFields
      }
      slug
      id
    }
    recommended
    message
  }
}
    ${ImageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReviewByIdGQL extends Apollo.Query<GetReviewByIdQuery, GetReviewByIdQueryVariables> {
    document = GetReviewByIdDocument;
    
  }
export const GetReviewByPadeIdDocument = gql`
    query getReviewByPadeId($pageId: Int!) {
  reviewByPage(pageId: $pageId) {
    id
    page {
      id
      name
    }
    recommended
    message
    reviewDate
    status
    user {
      id
      firstName
      lastName
      email
      reviewerName
    }
    reviewer {
      name
      email
      company
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReviewByPadeIdGQL extends Apollo.Query<GetReviewByPadeIdQuery, GetReviewByPadeIdQueryVariables> {
    document = GetReviewByPadeIdDocument;
    
  }
export const AddBillingToUserDocument = gql`
    mutation addBillingToUser($billing: BillingInput!) {
  addBillingToUser(billing: $billing) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddBillingToUserGQL extends Apollo.Mutation<AddBillingToUserMutation, AddBillingToUserMutationVariables> {
    document = AddBillingToUserDocument;
    
  }
export const RegisterUserDocument = gql`
    mutation registerUser($user: RegisterUser!) {
  addUser(user: $user) {
    id
    firstName
    lastName
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterUserGQL extends Apollo.Mutation<RegisterUserMutation, RegisterUserMutationVariables> {
    document = RegisterUserDocument;
    
  }
export const DeleteBillingDocument = gql`
    mutation deleteBilling($id: Int!) {
  deleteBilling(id: $id) {
    ...billingFields
  }
}
    ${BillingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBillingGQL extends Apollo.Mutation<DeleteBillingMutation, DeleteBillingMutationVariables> {
    document = DeleteBillingDocument;
    
  }
export const GetBillingInfosDocument = gql`
    query getBillingInfos {
  billingInfos {
    ...billingFields
  }
}
    ${BillingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBillingInfosGQL extends Apollo.Query<GetBillingInfosQuery, GetBillingInfosQueryVariables> {
    document = GetBillingInfosDocument;
    
  }
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  me {
    ...userFields
    companyName
    type
  }
}
    ${UserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurrentUserGQL extends Apollo.Query<GetCurrentUserQuery, GetCurrentUserQueryVariables> {
    document = GetCurrentUserDocument;
    
  }
export const GetUserAdminDocument = gql`
    query getUserAdmin {
  me {
    id
    firstName
    lastName
    isAdmin
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserAdminGQL extends Apollo.Query<GetUserAdminQuery, GetUserAdminQueryVariables> {
    document = GetUserAdminDocument;
    
  }
export const UpdateBillingDocument = gql`
    mutation updateBilling($billing: BillingInput!) {
  updateBilling(billing: $billing) {
    ...billingFields
    fee
  }
}
    ${BillingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBillingGQL extends Apollo.Mutation<UpdateBillingMutation, UpdateBillingMutationVariables> {
    document = UpdateBillingDocument;
    
  }
export const UpdateUserDocument = gql`
    mutation updateUser($editUser: EditUser!) {
  updateUser(user: $editUser) {
    ...userFields
    companyName
  }
}
    ${UserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
  }

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    