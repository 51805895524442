import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { GetTagsGQL, Gig, GigSlot, Tag } from '../../../../../../generated/graphql';
import { LONG_DATE, apiToDate } from '../../../../../helpers';
import {
  getGigSlotEndTimeISO,
  getGigSlotSetLength,
  getTimeLengthOptionsForSelect,
  getTimeOptionsForSelectDropdown,
  timeStringToTimestamp,
  timestampToTimeString,
} from '../../../../../helpers/dates';
import { GigsService } from '../../../../../services/gigs.service';
import { SelectInputOptions } from '../../../../../shared/components/inputs/select-input/select-input.component';

@Component({
  selector: 'app-gig-edit-slot',
  templateUrl: './gig-edit-slot.component.html',
  styleUrls: ['./gig-edit-slot.component.scss'],
})
export class GigEditSlotComponent implements OnInit {
  editSlotGroup = new FormGroup({
    date: new FormControl('', [Validators.required]),
    price: new FormControl(0),
    dj: new FormControl(false),
    artistBand: new FormControl(false),
    priceOverride: new FormControl(false),
    startTime: new FormControl('19:00'),
    setLength: new FormControl('00:30'),
    endTime: new FormControl(null),
  });

  categoryTags: Tag[];
  minDate = new Date();
  dateFormat = LONG_DATE;

  loading = false;
  public timeLengthOptions: SelectInputOptions[] = [];
  public gigStartTimeOptions: SelectInputOptions[] = [];

  get date(): AbstractControl {
    return this.editSlotGroup.get('date');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { slot: GigSlot; gig: Gig; new?: boolean },
    private getTags: GetTagsGQL,
    private dialogRef: MatDialogRef<GigEditSlotComponent>,
    private gigsService: GigsService,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.timeLengthOptions = getTimeLengthOptionsForSelect();
    this.gigStartTimeOptions = getTimeOptionsForSelectDropdown();

    this.getTags
      .watch()
      .valueChanges.pipe(map((d) => d.data.tags))
      .subscribe((d) => {
        this.categoryTags = d.filter((t) => t.group === 4);
        // TODO: Do tag smart
        const djTag = d.find((t) => t.name.toLowerCase() === 'dj');
        const artistTag = d.find((t) => t.name.toLowerCase() === 'artist');
        const bandTag = d.find((t) => t.name.toLowerCase() === 'band');
        this.editSlotGroup
          .get('dj')
          .setValue(this.data.slot ? !!this.data.slot.tags.find((dt) => dt.id === djTag.id) : true);
        this.editSlotGroup
          .get('artistBand')
          .setValue(
            this.data.slot ? !!this.data.slot.tags.find((dt) => dt.id === artistTag.id || dt.id === bandTag.id) : true,
          );

        this.editSlotGroup.get('price').setValue(this.data.slot ? this.data.slot.price : this.data.gig.slots[0].price);
        this.editSlotGroup
          .get('date')
          .setValue(this.data.slot ? apiToDate(this.data.slot.date) : apiToDate(this.data.gig.slots[0].date));
        this.editSlotGroup.updateValueAndValidity();

        this.editSlotGroup
          .get('startTime')
          .setValue(this.data.slot?.startTime ? timestampToTimeString(this.data.slot.startTime) : undefined);

        this.editSlotGroup
          .get('setLength')
          .setValue(
            this.data.slot?.startTime && this.data.slot?.endTime
              ? getGigSlotSetLength(this.data.slot?.startTime, this.data.slot?.endTime)
              : undefined,
          );
      });

    this.editSlotGroup.get('priceOverride').valueChanges.subscribe((e) => {
      e ? this.editSlotGroup.get('price').disable() : this.editSlotGroup.get('price').enable();
    });
  }

  update() {
    // TODO: Don't do this idiotically
    const tags = [];
    if (this.editSlotGroup.get('dj').value) {
      const t = this.categoryTags.find((t) => t.name.toLowerCase() === 'dj');
      if (t) tags.push(t.id);
    }
    if (this.editSlotGroup.get('artistBand').value) {
      const at = this.categoryTags.find((t) => t.name.toLowerCase() === 'artist');
      if (at) tags.push(at.id);
      const t = this.categoryTags.find((t) => t.name.toLowerCase() === 'band');
      if (t) tags.push(t.id);
    }
    this.loading = true;

    const startTime = !!this.editSlotGroup.value.startTime
      ? timeStringToTimestamp(this.editSlotGroup.get('date').value, this.editSlotGroup.value.startTime)
      : undefined;

    this.gigsService
      .updateSlot(this.data.gig, {
        tags,
        id: !!this.data.new ? undefined : this.data.slot.id,
        date: this.editSlotGroup.get('date').value,
        price: this.editSlotGroup.get('price').value,
        startTime: startTime,
        endTime: getGigSlotEndTimeISO(startTime, this.editSlotGroup.value.setLength),
      })
      .subscribe(
        () => {
          const newSlotString = $localize`Added new slot`;
          const oldSlotString = $localize`Saved slot`;
          this.loading = false;
          this.dialogRef.close();
          this.snackbar.open(this.data.new ? newSlotString : oldSlotString, undefined, { duration: 3000 });
        },
        (error) => {
          const newSlotString = $localize`Could't save new slot`;
          const oldSlotString = $localize`Could't save slot`;
          this.loading = false;
          this.snackbar.open(this.data.new ? newSlotString : oldSlotString, undefined, { duration: 3000 });
          console.error(error);
        },
      );
  }

  close() {
    this.dialogRef.close();
  }
}
