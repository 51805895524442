import { Tag } from '../generated/graphql';

export const tagArraysEquils = (arrayA: Tag[], arrayB: Tag[]): boolean => {
  return (
    Array.isArray(arrayA) &&
    Array.isArray(arrayB) &&
    arrayA.length === arrayB.length &&
    arrayA.every((tag) => arrayB.findIndex((t) => t.id === tag.id) > -1)
  );
};
