import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ExistingPlace, GetTagsGQL, Tag, TagEntry } from '../../../../../generated/graphql';
import { GigsService } from '../../../../services/gigs.service';

@Component({
  selector: 'app-tag-buttons',
  templateUrl: './tag-buttons.component.html',
  styleUrls: ['./tag-buttons.component.scss'],
})
export class TagButtonsComponent implements OnInit {
  @Output() tagsChanges = new EventEmitter<Tag[]>();
  @Input() resetFilter$: EventEmitter<any>;

  selectedTags: Tag[] = [];
  typeTags: Tag[] = [];
  availableTagsSubscription: Subscription;

  @Input()
  set availableTags(ref: Observable<TagEntry[]>) {
    if (ref && !this.availableTagsSubscription) {
      this.availableTagsSubscription = ref.subscribe((entries) => {
        const tagNames = entries.map((t) => t.tag.name.toLowerCase());
        const hasDj = tagNames.indexOf('dj') > -1;
        const hasArtistBand = tagNames.indexOf('artist') > -1 || tagNames.indexOf('band') > -1;

        this.filterGroup.get('djTags').disable({ emitEvent: false });
        this.filterGroup.get('artistBandTags').disable({ emitEvent: false });

        if (hasDj && hasArtistBand) {
          this.filterGroup.get('allTags').enable({ emitEvent: false });
          this.filterGroup.get('djTags').enable({ emitEvent: false });
          this.filterGroup.get('artistBandTags').enable({ emitEvent: false });
        } else if (hasDj) {
          this.filterGroup.get('djTags').enable({ emitEvent: false });
        } else if (hasArtistBand) {
          this.filterGroup.get('artistBandTags').enable({ emitEvent: false });
        }
      });
    }
  }

  filterChangesSub: Subscription;

  @Input()
  set filterChanges(ref: Observable<{ locations: ExistingPlace[]; tags: number[] }>) {
    if (ref && !this.filterChangesSub) {
      this.filterChangesSub = ref.subscribe((v) => {
        this.filterGroup.get('allTags').setValue(false, { emitEvent: false });
        this.filterGroup.get('artistBandTags').setValue(false, { emitEvent: false });
        this.filterGroup.get('djTags').setValue(false, { emitEvent: false });

        const abt = this.artistBandTags;
        if (v.tags.indexOf(this.djTag.id) > -1) {
          this.filterGroup.get('djTags').setValue(true, { emitEvent: false });
        } else if (v.tags.indexOf(abt[0].id) > -1 && v.tags.indexOf(abt[1].id) > -1) {
          this.filterGroup.get('artistBandTags').setValue(true, { emitEvent: false });
        } else {
          this.filterGroup.get('allTags').setValue(true, { emitEvent: false });
        }
      });
    }
  }

  filterGroup = new FormGroup({
    allTags: new FormControl(true),
    artistBandTags: new FormControl(false),
    djTags: new FormControl(false),
  });
  constructor(private getTags: GetTagsGQL, private gigService: GigsService) {}

  get djTag() {
    if (!this.typeTags) {
      return undefined;
    }
    return this.typeTags.find((t) => t.name.toLowerCase() === 'dj');
  }

  get artistBandTags() {
    if (!this.typeTags) {
      return undefined;
    }
    return [
      this.typeTags.find((t) => t.name.toLowerCase() === 'artist'),
      this.typeTags.find((t) => t.name.toLowerCase() === 'band'),
    ];
  }

  ngOnInit(): void {
    this.getTags.watch().valueChanges.subscribe((t) => {
      this.typeTags = t.data.tags.filter((t) => t.group === 4);

      if (this.gigService.urlFilter.tags) {
        const hasDj = this.gigService.urlFilter.tags.includes(this.djTag.id);
        const hasAB =
          this.gigService.urlFilter.tags.includes(this.artistBandTags[0].id) ||
          this.gigService.urlFilter.tags.includes(this.artistBandTags[1].id);
        if (hasDj && !hasAB) {
          this.filterGroup.get('djTags').setValue(true);
        } else if (hasAB && !hasDj) {
          this.filterGroup.get('artistBandTags').setValue(true);
        }

        this.gigService.filterHasUpdated.tags = true;
      }
    });

    this.filterGroup.get('allTags').valueChanges.subscribe((d) => {
      if (d) {
        this.selectedTags = this.typeTags;
        this.filterGroup.get('artistBandTags').setValue(false);
        this.filterGroup.get('djTags').setValue(false);
        this.tagsChanges.emit(this.selectedTags);
      }
    });

    this.filterGroup.get('artistBandTags').valueChanges.subscribe((d) => {
      if (d) {
        this.selectedTags = this.artistBandTags;
        this.filterGroup.get('allTags').setValue(false);
        this.filterGroup.get('djTags').setValue(false);
        this.tagsChanges.emit(this.selectedTags);
      }
    });

    this.filterGroup.get('djTags').valueChanges.subscribe((d) => {
      if (d) {
        this.selectedTags = [this.djTag];
        this.filterGroup.get('artistBandTags').setValue(false);
        this.filterGroup.get('allTags').setValue(false);
        this.tagsChanges.emit(this.selectedTags);
      }
    });

    if (this.resetFilter$) {
      this.resetFilter$.pipe(take(1)).subscribe(() => this.toggleFormControl('allTags'));
    }
  }

  toggleFormControl(key: string, event?: Event) {
    event?.preventDefault();
    event?.stopPropagation();
    if (this.filterGroup.get(key).disabled || !!this.filterGroup.get(key).value) {
      return;
    }
    this.filterGroup.get(key).setValue(!this.filterGroup.get(key).value);
  }
}
