<div fxLayout="column" *ngIf="billings | async as billings">
  <ng-container *ngIf="!addMode">
    <h2 class="gray-1 f-size--16 m--0">
      <span class="bright-red f-size--12" i18n *ngIf="!booking.billingInfo?.id">
        Add invoice information to be able to sign the booking
      </span>
    </h2>
    <mat-accordion fxLayout="column" fxLayoutGap="8px">
      <mat-expansion-panel *ngFor="let billing of billings">
        <mat-expansion-panel-header>
          <mat-panel-title fxFlex="nogrow" class="mat-body gray-1">{{ billing.companyName }}</mat-panel-title>
          <mat-panel-description fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="booking.billingInfo?.id === billing.id" class="green">check</mat-icon>
            <button
              mat-flat-button
              color="primary"
              *ngIf="booking.billingInfo?.id !== billing.id"
              (click)="selectBilling(billing.id, $event)"
              [disabled]="
                loading || booking.status === bookingStatus.Cancelled || booking.status === bookingStatus.Declined
              ">
              Select
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-contract-billing-detail [billing]="billing"></app-contract-billing-detail>
      </mat-expansion-panel>
    </mat-accordion>
    <button
      [disabled]="booking.status === bookingStatus.Cancelled || booking.status === bookingStatus.Declined"
      mat-button
      (click)="addBillingInfo()"
      class="text-underline f-weight--400"
      fxFlexAlign="start">
      <span class="text-underline">+ <span i18n>Add new</span></span>
    </button>
  </ng-container>

  <ng-container *ngIf="addMode">
    <app-add-billing-info (onClose)="addMode = !addMode"></app-add-billing-info>
  </ng-container>
</div>
