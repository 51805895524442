import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Booking, BookingDates, BookingStatusEnum, GetCurrentUserGQL, User } from '../../../../generated/graphql';
import { LONG_DATE, SHORT_DATE } from '../../../helpers';
import { getBookingPrice } from '../../../helpers/bookings';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';
import { TranslationsService } from '../../../services/translations.service';
import { amIBooker, getStatus } from '../../bookingUtils';

@Component({
  selector: 'app-booking-card',
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.scss'],
})
export class BookingCardComponent implements OnInit {
  @Input() booking: Booking;
  @Input() color = 'orange';
  @Input() message = '';
  @Input() small = false;
  @Input() displayNotice = false;

  public bookingStatus$: Subject<string> = new Subject();

  public dateFormat = SHORT_DATE;
  public amIBooker: boolean;
  public totalPrice = 0;

  BOOKING_STATUS = BookingStatusEnum;
  getBookingPrice = getBookingPrice;

  get acceptedDates(): BookingDates[] {
    return this.booking?.dates.filter((date) => date.isAccepted);
  }

  get bookingPrice(): number {
    if (this.acceptedDates.length > 0) {
      return getBookingPrice(this.acceptedDates);
    } else {
      return getBookingPrice(this.booking.dates);
    }
  }

  constructor(
    private iconService: IconsRegistryService,
    public translations: TranslationsService,
    private getMe: GetCurrentUserGQL,
  ) {
    this.iconService.registerIcons([IconSubsets.NAVIGATION]);
  }

  ngOnInit() {
    this.getMe
      .watch()
      .valueChanges.pipe(
        map(({ data }) => data.me),
        tap((user: User) => this.bookingStatus$.next(this.getBookingStatus(user.id))),
        tap((user: User) => (this.amIBooker = amIBooker(this.booking, user.id))),
      )
      .subscribe();

    this.dateFormat = this.small ? SHORT_DATE : LONG_DATE;
  }

  public getBookingStatus(userId): string {
    switch (getStatus(this.booking, userId)) {
      case 'new':
      case 'waitingMe': {
        return $localize`Waiting for you`;
      }
      case 'waitingOther': {
        return $localize`Waiting for counterpart`;
      }
      case 'expired': {
        return 'expired';
      }
      case 'cancelled': {
        return 'other';
      }
    }
  }
}
