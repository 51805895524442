import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxInputComponent),
      multi: true,
    },
  ],
})
export class CheckboxInputComponent implements ControlValueAccessor {
  @Input() label: string;

  @Input() isThinStyle: boolean;

  public isChecked: boolean = false;
  public disabled: boolean = false;

  constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.INPUTS]);
  }

  public onChange: any = () => {};
  public onTouch: any = () => {};

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(checked: boolean) {
    this.isChecked = checked;
  }

  public onModelChange(value: boolean) {
    this.isChecked = value;
    this.onChange(value);
  }
}
