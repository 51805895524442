import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Gig, SlotStatus } from '../../../../../../generated/graphql';
import { apiToDate } from '../../../../../helpers';
import { getGigMetaData, getTagsList, GigMeta } from '../../../../../helpers/gigs';

@Component({
  selector: 'app-gig-details-overview',
  templateUrl: './gig-details-overview.component.html',
  styleUrls: ['./gig-details-overview.component.scss'],
})
export class GigDetailsOverviewComponent implements OnInit, OnChanges {
  @Input()
  gig: Gig;

  meta: GigMeta;
  maxPrice: number;
  tagsList: string;
  freeSlots: number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gig) {
      this.meta = getGigMetaData(this.gig);
    }
  }

  ngOnInit() {
    this.updateVisuals();
    this.meta = getGigMetaData(this.gig);
  }

  private updateVisuals() {
    this.maxPrice = this.getMaxPrice();
    this.tagsList = getTagsList(this.gig);
    this.freeSlots = this.getFreeSlots();
  }

  private getMaxPrice() {
    return Math.max(...this.gig.slots.map(s => s.price));
  }

  private getFreeSlots() {
    return this.gig.slots.reduce((a, b) => {
      if (apiToDate(b.deadline) > new Date() && b.status !== SlotStatus.Assigned) {
        return a + 1;
      }
      return a;
    }, 0);
  }
}
