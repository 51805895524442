<div fxLayout="column" class="phone-input">
  <p
    *ngIf="!!label"
    fxLayoutAlign="start center"
    fxLayoutGap="6px"
    class="phone-input--label"
    [ngClass]="{ light: isLightLabel }">
    <span>{{ label }}</span>
    <mat-icon *ngIf="!!labelTooltip" [matTooltip]="labelTooltip" svgIcon="info-fill"></mat-icon>
  </p>

  <app-dropdown fxFlex="grow" [onClosed]="onDropdownClose$">
    <div selected class="selected">
      <span i18n>{{ selectedCountry.name }} ({{ selectedCountry.dialCode }})</span>
    </div>
    <div options fxLayout="column" class="options-list">
      <span
        *ngFor="let country of COUNTRY_PHONE_CODES; index as i"
        (click)="selectCountry(COUNTRY_PHONE_CODES[i])"
        class="options-list--item">
        {{ country.name }} ({{ country.dialCode }})
      </span>
    </div>
  </app-dropdown>
</div>
