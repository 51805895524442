import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BookingDates, GetCurrentUserGQL, GetPagesByUserGQL, Page, UserType } from '../../../../generated/graphql';

@Component({
  selector: 'app-dashboard-booking-dates',
  templateUrl: './dashboard-booking-dates.component.html',
  styleUrls: ['./dashboard-booking-dates.component.scss'],
})
export class DashboardBookingDatesComponent implements OnInit {
  @Input() upcomingDates: BookingDates[];
  @Input() upcomingCount: number;

  public userPages$: Observable<Page[]>;

  public userType: UserType;

  USER_TYPE = UserType;

  constructor(private getCurrentUser: GetCurrentUserGQL, private getUserPages: GetPagesByUserGQL) {}

  ngOnInit() {
    this.getCurrentUser
      .fetch()
      .pipe(tap(({ data }) => (this.userType = data.me.type || UserType.Booker)))
      .subscribe();

    this.userPages$ = this.getUserPages.watch().valueChanges.pipe(map(({ data }) => data.pagesByUser));
  }
}
