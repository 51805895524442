import { Injectable, Inject, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable({ providedIn: 'root' })
export class RequestService {
  constructor(@Optional() @Inject(REQUEST) private request: any) {}

  get cookies() {
    if (this.request) {
      return this.request.cookies;
    }
  }
}
