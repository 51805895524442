import { Component, Input, OnInit } from '@angular/core';
import { Gig, GigSlot, SlotStatus } from '../../../../../generated/graphql';
import { isSlotExpired } from '../../../../../utils/gigSlots';
import { isDateInThePast } from '../../../../bookings/bookingUtils';
import { MEDIUM_DATE } from '../../../../helpers/dates';
import { getGigStatus } from '../../../../helpers/gigs';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';
import { TranslationsService } from '../../../../services/translations.service';

@Component({
  selector: 'app-customer-gigs-dashboard-item',
  templateUrl: './customer-gigs-dashboard-item.component.html',
  styleUrls: ['./customer-gigs-dashboard-item.component.scss'],
})
export class CustomerGigsDashboardItemComponent implements OnInit {
  @Input() gig: Gig;

  DATE_FORMAT = MEDIUM_DATE;

  get isBooked(): boolean {
    return this.gig.slots.filter((s) => s.status === SlotStatus.Assigned).length === this.gig.slots.length;
  }

  get remainingSlots(): GigSlot[] {
    return this.gig.slots.filter(
      (s) => !isSlotExpired(s) && s.status != SlotStatus.Assigned && s.status != SlotStatus.Closed,
    );
  }

  get gigApplications(): number {
    return this.gig.slots
      .map((slot) => slot.applications.length)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  }

  get gigStatus(): string {
    return getGigStatus(this.gig);
  }

  get gigName(): string {
    return this.gig.name || $localize`${this.translations.gigType(this.gig.type).translation} gig`;
  }

  constructor(private iconsService: IconsRegistryService, private translations: TranslationsService) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  ngOnInit(): void {
    this.gig.slots = this.gig.slots
      .filter((slot) => !isDateInThePast(slot.date))
      .sort((a, b) => parseInt(a.date) - parseInt(b.date));
  }
}
