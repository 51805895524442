<main fxLayout="column" class="container">
  <app-back-arrow-title [titleText]="'My applications'" class="m-b--90" i18n-titleText></app-back-arrow-title>

  <div *ngIf="bookingDates$ | async as bookingDates; else loading" fxLayout="column" fxFlex="100%">
    <div
      class="empty-applications-list"
      *ngIf="bookingDates.length === 0 && (typeFilter$ | async) === APPLICATION_TYPE.All"
      fxFlex="0 0 100%"
      fxLayout="column"
      fxLayoutAlign="center center">
      <img src="assets/images/empty-applications-list.svg" class="illustration fade-in" />
      <h2 class="mat-h1" i18n>You don't have any gig applications yet</h2>
      <p class="mat-body-1 gray-2" i18n>
        It's really easy to apply to gigs you are interested in.<br />Get started by clicking the button below!
      </p>
      <a mat-flat-button color="primary" [routerLink]="['/gigs']" i18n>Browse gigs</a>
    </div>

    <ng-container
      *ngIf="bookingDates.length > 0 || (bookingDates.length === 0 && (typeFilter$ | async) !== APPLICATION_TYPE.All)">
      <div fxFlexAlign.lt-sm="center" class="m-b--32 dropdown-wrap">
        <app-dropdown fxFlex="323px" fxFlex.lt-sm="100%" class="location-dropdown">
          <div selected fxFlex="grow" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
            <div class="icon-container">
              <h3 class="title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                <span class="icon-container"><mat-icon class="svg-icon" svgIcon="filter"></mat-icon></span>
                <span i18n>Filter &amp; Sort</span>
              </h3>
            </div>
          </div>
          <div options fxFlex="grow" class="dropdown-item selections" fxLayout="column">
            <h4 class="dropdown-title m-b--30" i18n>By status</h4>
            <div>
              <section fxLayout="column" fxLayoutGap="16px">
                <mat-label *ngFor="let type of APPLICATION_TYPE_NAMES; index as i">
                  <mat-checkbox color="primary" [checked]="selectedCheckbox === i" (change)="filterType(type.type, i)">
                    {{ type.trans | titlecase }}
                  </mat-checkbox>
                </mat-label>
              </section>
            </div>
          </div>
        </app-dropdown>
      </div>

      <ul fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center" class="labels-list">
        <li
          [fxFlex]="label.width"
          [fxFlex.lt-md]="label.mdWidth || label.width"
          [fxHide.lt-md]="label.mdHide"
          fxHide.lt-sm="true"
          *ngFor="let label of LABELS_LIST"
          [ngClass]="{ 'text-right': label.title === 'Price' }">
          {{ label.trans }}
          <a *ngIf="label.title === 'Price Before'" [href]="POLICY_LINK" i18n>FEE</a>
        </li>
      </ul>
      <p class="text-center" *ngIf="bookingDates.length === 0 && (typeFilter$ | async) !== APPLICATION_TYPE.All" i18n>
        No matches found
      </p>
      <app-application-card class="m-b--10" *ngFor="let date of bookingDates" [date]="date"> </app-application-card>

      <app-paginator
        *ngIf="bookingDates.length > 0"
        [totalRecords]="totalAmount"
        [pageSize]="pageSize"
        [resetPaging$]="typeFilter$"
        (onPageChanged)="changePage($event)">
      </app-paginator>
    </ng-container>
  </div>

  <ng-template #loading>
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="32px grid" fxFlex="100%">
      <div *ngFor="let item of [].constructor(15)" fxFlex.gt-sm="100%" fxFlex.gt-lg="25%">
        <ngx-skeleton-loader
          [theme]="{
            width: '100%',
            height: '130px',
            'border-radius': '4px',
            'background-color': '#fafafa',
            'margin-bottom': 0
          }">
        </ngx-skeleton-loader>
      </div>
    </div>
  </ng-template>
</main>
