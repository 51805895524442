<main class="booking-request">
  <div fxLayoutAlign="start center" fxLayoutAlign.lt-md="center center" class="booking-request--header container">
    <a [routerLink]="['/']" class="gigital-logo">
      <app-gigital-logo fxHide.lt-md [height]="34"></app-gigital-logo>
      <app-gigital-logo fxHide fxShow.lt-md [height]="24"></app-gigital-logo>
    </a>

    <div fxHide.lt-md fxLayoutAlign="start center" class="progress-bar">
      <div
        fxLayoutAlign="start center"
        *ngFor="let step of REQUEST_STEP_DATA; let i = index"
        (click)="goToStep(step.number)"
        [ngClass]="{ cursor: checkIfStepValid(step.number - 1) || step.number === 1 }"
        class="progress-step">
        <span
          fxLayoutAlign="center center"
          [ngClass]="{ active: activeStep === step.number || checkIfStepValid(step.number) }"
          class="progress-step--number"
          >{{ step.number }}</span
        >
        <span
          [ngClass]="{ active: activeStep === step.number || checkIfStepValid(step.number) }"
          class="progress-step--title"
          >{{ step.title }}</span
        >
        <span *ngIf="i < REQUEST_STEP_DATA.length - 1">
          <mat-icon svgIcon="expand-right-gray" class="expand-right-icon"></mat-icon>
        </span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end" class="close-bar">
      <a fxHide.lt-md [routerLink]="['..']" class="f-size--16 f-weight--600 gray-1" i18n>Exit</a>
      <a fxHide fxShow.lt-md [routerLink]="['..']" class="f-size--16 f-weight--600 gray-1" i18n>
        <mat-icon svgIcon="close"></mat-icon>
      </a>
    </div>
  </div>

  <div class="booking-request--content">
    <div fxHide fxShow.lt-md class="progress-bar-mobile container">
      <span class="">
        <span i18n>Step</span>&nbsp;{{ activeStep }}&nbsp;<span i18n>of</span>&nbsp;{{ REQUEST_STEP_DATA.length }}
      </span>
    </div>

    <app-artist-detail-book-description
      *ngIf="activeStep === REQUEST_STEP_DATA[0].number"
      [page$]="page$"
      [createRequestForm]="createRequestForm">
    </app-artist-detail-book-description>
    <app-artist-detail-book-details
      *ngIf="activeStep === REQUEST_STEP_DATA[1].number"
      [page$]="page$"
      [createRequestForm]="createRequestForm">
    </app-artist-detail-book-details>
    <app-artist-detail-book-summary
      *ngIf="activeStep === REQUEST_STEP_DATA[2].number"
      [page$]="page$"
      [createRequestForm]="createRequestForm"
      [isBookingCreated]="isBookingCreated"
      (changeStepNumber$)="activeStep = $event">
    </app-artist-detail-book-summary>
  </div>

  <div fxLayoutAlign="space-between center" class="booking-request--footer container">
    <button
      *ngIf="activeStep > 1"
      fxLayoutAlign="start center"
      fxLayoutGap="12px"
      (click)="changeStep(-1)"
      class="gig-button transparent gray-1 small">
      <mat-icon svgIcon="back-arrow"></mat-icon>
      <span i18n>Back</span></button
    >

    <button
      *ngIf="activeStep <= 2"
      fxLayoutAlign="start center"
      fxLayoutGap="12px"
      (click)="changeStep(1)"
      class="gig-button primary small m-left--auto">
      <span i18n>Continue</span><mat-icon svgIcon="arrow-right-light"></mat-icon
    ></button>

    <button
      *ngIf="activeStep === 3"
      fxLayoutAlign="start center"
      fxLayoutGap="12px"
      (click)="sendBookingRequest()"
      class="gig-button primary small m-left--auto">
      <span i18n>Send request</span><mat-icon svgIcon="arrow-right-light"></mat-icon
    ></button>
  </div>
</main>
