<footer>
  <div fxHide.lt-md class="expandable-footer">
    <div
      fxLayout="column"
      fxLayoutAlign="end stretch"
      class="expandable-footer--content-container"
      [@detailExpand]="expanded ? 'expanded' : 'collapsed'">
      <div class="expandable-footer--content">
        <ng-container *ngTemplateOutlet="footerContent"></ng-container>
      </div>
    </div>

    <div fxHide.lt-md #footerHeader [ngClass]="{ expanded: expanded }" class="expandable-footer--header">
      <div fxFlex class="footer-content">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="start center"
          class="container"
          fxLayoutAlign.lt-md="space-between start">
          <div fxLayout="row" fxLayout.lt-md="column">
            <div class="copyright">
              <p>© 2023 Gigital</p>
            </div>

            <div fxLayoutAlign="start center" fxLayoutGap="16px" class="social-links m-r--64">
              <a fxLayoutAlign="center center" href="https://www.instagram.com/gigital/" target="_blank">
                <mat-icon svgIcon="instagram-black"></mat-icon>
              </a>
              <a fxLayoutAlign="center center" href="https://www.facebook.com/gigitalswe" target="_blank">
                <mat-icon svgIcon="facebook-black"></mat-icon>
              </a>
            </div>
          </div>

          <nav>
            <ul fxLayout="row" fxLayoutGap="7px" class="nav-list">
              <li>
                <a
                  [href]="'http://help.gigital.se/' + urlLocale + '/articles/3315741-terms-of-use'"
                  target="_blank"
                  i18n
                  >Terms</a
                >
              </li>
              <span>·</span>
              <li>
                <a
                  [href]="'http://help.gigital.se/' + urlLocale + '/articles/3304374-privacy-policy'"
                  target="_blank"
                  i18n
                  >Privacy</a
                >
              </li>
              <span>·</span>
              <li> <a href="http://gigital.com/blog" target="_blank" i18n>Blog</a> </li>
            </ul>
          </nav>

          <div fxLayout="start center" fxLayoutGap="12px" class="expandable-footer--toggle" (click)="togglePanel()">
            <span i18n>Help & resources</span>
            <mat-icon svgIcon="expand-down-bold"></mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxHide fxShow.lt-md class="mobile-footer">
    <ng-container *ngTemplateOutlet="footerContent"></ng-container>
  </div>
</footer>

<ng-template #footerContent>
  <div
    fxLayoutAlign="space-between start"
    fxLayoutGap="119px"
    fxLayout.lt-md="column"
    fxLayoutGap.lt-md="32px"
    #expandedContent
    class="expanded-footer-content container">
    <div>
      <app-gigital-logo fxHide.lt-md></app-gigital-logo>
      <app-gigital-logo fxHide fxShow.lt-md [height]="30"></app-gigital-logo>
    </div>
    <div
      fxFlex
      fxLayoutAlign="space-between start"
      fxLayout.lt-md="column"
      fxLayoutGap.lt-md="24px"
      class="expanded-navigation">
      <div class="expanded-navigation--menu">
        <h2 class="subtitle-1 f-weight--600" i18n>Navigation</h2>
        <nav fxLayout="column">
          <li><a href="/login" i18n>Log in</a></li>
          <li><a href="/signup" i18n>Sign up</a></li>
          <li><a href="/" i18n>Homepage</a></li>
          <li><a href="/for-artists" i18n>For artists</a></li>
          <li><a href="/pricing" i18n>Pricing</a></li>
          <li><a href="/how-it-works" i18n>How it works</a></li>
        </nav>
      </div>

      <div class="expanded-navigation--menu">
        <h2 class="subtitle-1 f-weight--600" i18n>Resources</h2>
        <nav fxLayout="column">
          <li
            ><a [href]="'http://help.gigital.se/' + urlLocale + '/collections/388383-for-artists'" target="_blank" i18n
              >FAQ for artists</a
            ></li
          >
          <li
            ><a [href]="'http://help.gigital.se/' + urlLocale + '/collections/388408-faq-bokare'" target="_blank" i18n
              >FAQ for bookers</a
            ></li
          >
          <li><a href="http://gigital.com/blog" target="_blank" i18n>Blog</a></li>
        </nav>
      </div>

      <div class="expanded-navigation--menu">
        <h2 class="subtitle-1 f-weight--600" i18n>Legal</h2>
        <nav fxLayout="column">
          <li>
            <a [href]="'http://help.gigital.se/' + urlLocale + '/articles/3304374-privacy-policy'" target="_blank" i18n
              >Privacy policy</a
            >
          </li>
          <li
            ><a
              [href]="'http://help.gigital.se/' + urlLocale + '/articles/3304388-cancellation-policy'"
              target="_blank"
              i18n
              >Cancellation policy</a
            ></li
          >
          <li
            ><a [href]="'http://help.gigital.se/' + urlLocale + '/articles/3315741-terms-of-use'" target="_blank" i18n
              >Terms of use</a
            ></li
          >
        </nav>
      </div>

      <div class="expanded-navigation--menu">
        <h2 class="subtitle-1 f-weight--600" i18n>Contact</h2>
        <nav fxLayout="column">
          <li>
            <a href="mailto: Info@Gigital.com" i18n>info@gigital.com</a>
          </li>
        </nav>
      </div>

      <div class="expanded-navigation--menu">
        <h2 class="subtitle-1 f-weight--600" i18n>Follow us</h2>
        <div fxLayoutAlign="start center" fxLayoutGap="16px" class="social-links m-r--64">
          <a fxLayoutAlign="center center" href="https://www.instagram.com/gigital/" target="_blank">
            <mat-icon svgIcon="instagram-black"></mat-icon>
          </a>
          <a fxLayoutAlign="center center" href="https://www.facebook.com/gigitalswe" target="_blank">
            <mat-icon svgIcon="facebook-black"></mat-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
