import { Component, OnInit } from '@angular/core';
import { GetContentfulEntryGQL } from '../../../generated/graphql';
import { zeroPad } from '../../helpers';
import { MetaService } from '../../services/meta.service';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksWorkComponent implements OnInit {
  constructor(
    private getContentfulEntry: GetContentfulEntryGQL,
    private translations: TranslationsService,
    private meta: MetaService,
  ) {}

  isLoading = true;
  wizard: any;

  ngOnInit() {
    this.getContentfulEntry
      .fetch({ name: 'How does it work?', locale: this.translations.getCurrentLocale() })
      .subscribe((result) => {
        this.meta.setContentfulSeoData(result.data.getContentfulEntry.contentfulData.fields);
        const page = result.data.getContentfulEntry.contentfulData;
        this.wizard = page.fields.blocks.find((e) => e.sys.contentType.sys.id === 'wizard');
        this.isLoading = false;
      });
  }

  zeroPad(number) {
    return zeroPad(number);
  }
}
