import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { User, UserType } from '../../../generated/graphql';
import { flat } from '../../helpers';
import { BlogService } from '../../services/blog.service';

export const OVERLAY_GRADIENT =
  ' linear-gradient(20deg, rgba(0, 0, 0, 0.671732) 11.49%, rgba(0, 0, 0, 0.8) 41.9%, rgba(0, 0, 0, 0.396316) 56.87%, rgba(0, 0, 0, 0) 71.65%)';
@Component({
  selector: 'app-blog-posts-preview',
  templateUrl: './blog-posts-preview.component.html',
  styleUrls: ['./blog-posts-preview.component.scss'],
})
export class BlogPostsPreviewComponent implements OnInit {
  @Input() isFullScreen: boolean = false;
  @Input() noPadding: boolean;
  @Input() utmSource: string;
  @Input() tags: string[];
  @Input() limit: number;
  @Input() user: User;

  public displayedPostIndex: number = 0;
  public postsCount: number = 0;

  overlayGradient = OVERLAY_GRADIENT;

  constructor(public blog: BlogService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.user && (!this.tags || this.tags.length === 0)) {
      switch (this.user.type) {
        case UserType.Artist:
          this.tags = ['artistnews'];
          break;
        case UserType.Booker:
          this.tags = ['bookernews'];
          break;
        case UserType.BookerAndArtist:
        case UserType.None:
        default:
          this.tags = ['bookernews', 'artistnews'];
          break;
      }

      let tags: string[] = [];
      if (this.user?.organizations) {
        tags = flat<string>(this.user.organizations.map((o) => o.blogTags));
      }
      this.tags = [...this.tags, ...tags];
      this.blog.getNews(3, tags, 'dashboard');
    }
    this.blog.getNews(10, this.tags, this.utmSource);
  }

  public getSafeCssUrl(imageUrl: string): SafeUrl {
    const url = `${this.overlayGradient}, url(${imageUrl})`;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public onPreviousClick(): void {
    this.displayedPostIndex = this.displayedPostIndex > 0 ? this.displayedPostIndex - 1 : this.displayedPostIndex;
  }

  public onNextClick(): void {
    this.displayedPostIndex =
      this.displayedPostIndex < this.blog.posts.length - 1 ? this.displayedPostIndex + 1 : this.displayedPostIndex;
  }
}
