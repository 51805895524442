<div fxLayout="column" class="phone-input">
  <p
    *ngIf="!!label"
    fxLayoutAlign="start center"
    fxLayoutGap="6px"
    class="phone-input--label"
    [ngClass]="{ light: isLightLabel }">
    <span>{{ label }}</span>
    <mat-icon *ngIf="!!labelTooltip" [matTooltip]="labelTooltip" svgIcon="info-fill"></mat-icon>
  </p>

  <div class="input-wrap">
    <span *ngIf="phonePrefix" class="phone-prefix">{{ phonePrefix }}</span>
    <input
      fxFlex="grow"
      type="text"
      (keypress)="keyPressNumbers($event)"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      i18n-placeholder
      [disabled]="disabled" />
  </div>
</div>
