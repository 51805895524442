import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface CardImageData {
  crop: {
    x: number;
    y: number;
  };
  size: string;
}

@Component({
  selector: 'app-image-card-dashboard',
  templateUrl: './image-card-dashboard.component.html',
  styleUrls: ['./image-card-dashboard.component.scss'],
})
export class ImageCardDashboardComponent implements OnInit {
  @Input()
  imageName: string;

  @Input()
  imageData: CardImageData;

  @Input()
  flipH = false;

  @Input()
  title: string;
  @Input()
  subTitle: string;
  @Input()
  buttonName: string;
  @Input()
  buttonUrl: string;

  safeCssUrl: SafeUrl;
  overlayGradient =
    'linear-gradient(21deg, rgba(0, 0, 0, 0.4) 11.49%, rgba(0, 0, 0, 0.4) 59.74%, rgba(0, 0, 0, 0.198158) 64.76%, rgba(0, 0, 0, 0) 71.65%)';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const flip = this.flipH ? '/a_hflip' : '';
    const url = `${this.overlayGradient}, url(https://res.cloudinary.com/hthruuqgk/image/upload/f_auto,fl_progressive,q_auto/c_limit,w_1000${flip}/v1/${this.imageName})`;
    this.safeCssUrl = this.sanitizer.bypassSecurityTrustUrl(url);
  }

  loaded() {}
  error() {}
}
