import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageCategory } from '../../../../../generated/graphql';
import { IconSubsets, IconsRegistryService } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-artist-list-filter-mobile-modal',
  templateUrl: './artist-list-filter-mobile-modal.component.html',
  styleUrls: ['./artist-list-filter-mobile-modal.component.scss'],
})
export class ArtistListFilterMobileModalComponent implements OnInit {
  public _category: PageCategory | null = null;
  public includesEquipment: boolean = false;
  public _isLiveSelected: boolean = false;
  public _isDjSelected: boolean = false;

  public isLocationsOpen: boolean = false;
  public numberOptionsToShow: number = 5;
  public filterSections: any;

  public PAGE_CATEGORY = PageCategory;

  public get category() {
    if (this.isDjSelected) return this.PAGE_CATEGORY.Dj;
    if (this.isLiveSelected) return this.PAGE_CATEGORY.Live;
    return null;
  }

  get isLiveSelected(): boolean {
    return this._isLiveSelected;
  }

  set isLiveSelected(val: boolean) {
    this._isLiveSelected = val;
    if (val) {
      this._isDjSelected = false;
    }
  }

  get isDjSelected() {
    return this._isDjSelected;
  }

  set isDjSelected(val) {
    this._isDjSelected = val;
    if (val) {
      this._isLiveSelected = false;
    }
  }

  get liveSearch() {
    return this._isLiveSelected;
  }

  set liveSearch(val) {
    this._isLiveSelected = val;
    if (val) {
      this._isDjSelected = false;
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { filters },
    public activeModal: MatDialogRef<ArtistListFilterMobileModalComponent>,
    private iconsService: IconsRegistryService,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public ngOnInit(): void {
    if (this.data.filters.category === PageCategory.Live) this.isLiveSelected = true;
    if (this.data.filters.category === PageCategory.Dj) this.isDjSelected = true;
    this.includesEquipment = this.data.filters.includesEquipment;

    this.filterSections = [
      {
        title: $localize`Genre`,
        options: this.data.filters.genreTags,
        isOpen: false,
        numberToShow: 8,
      },
      {
        title: $localize`Type of music`,
        options: this.data.filters.musicianTags,
        isOpen: false,
        numberToShow: 8,
      },
      {
        title: $localize`Type of performer`,
        options: this.data.filters.performerTags,
        isOpen: false,
        numberToShow: 8,
      },
      {
        title: $localize`Instruments`,
        options: this.data.filters.instrumentsTags,
        isOpen: false,
        numberToShow: 8,
      },
    ];
  }

  public getLabel(option): string {
    let label = option?.name || option?.longName;
    if (!!label || !!option.hits) {
      label = label + ' (' + option.hits + ')';
    }
    return label;
  }

  public openFilterSection(filter): void {
    filter.numberToShow = filter.options.length;
    filter.isOpen = !filter.isOpen;
  }

  public doSearch(): void {
    this.activeModal.close({
      category: this.category,
      tags: [
        ...this.data.filters.musicianTags,
        ...this.data.filters.performerTags,
        ...this.data.filters.genreTags,
        ...this.data.filters.instrumentsTags,
      ],
      includesEquipment: this.includesEquipment,
    });
  }

  public clearAll(): void {
    this.data.filters.location.forEach((country) => country.places.forEach((place) => (place.active = false)));
    this.data.filters.performerTags.forEach((tag) => (tag.active = false));
    this.data.filters.musicianTags.forEach((tag) => (tag.active = false));
    this.data.filters.genreTags.forEach((tag) => (tag.active = false));
    this.data.filters.instrumentsTags.forEach((tag) => (tag.active = false));
    this.isLiveSelected = false;
    this.isDjSelected = false;
    this.includesEquipment = false;
  }
}
