<section>
  <div fxLayoutAlign="start center" class="title-wrap m-b--36">
    <mat-icon class="pointer" (click)="goBack.next()" svgIcon="expand-left"></mat-icon>
    <h1 *ngIf="!dateToCancel" class="h6 f-weight--600" i18n>Canceling signed booking</h1>
    <h1 *ngIf="!!dateToCancel" class="h6 f-weight--600" i18n>Cancel signed date</h1>
  </div>

  <form [formGroup]="cancellationForm" class="m-b--30">
    <p class="subtitle-1 f-weight--600 m-b--24">Reason</p>

    <mat-radio-group formControlName="cancelReason" fxLayout="column" fxLayoutGap="24px" class="m-b--4">
      <mat-radio-button *ngFor="let reason of reasonOptions" [value]="reason.value" color="primary">{{
        reason.name
      }}</mat-radio-button>
    </mat-radio-group>

    <div *ngIf="cancellationForm.value.cancelReason === CANCEL_REASON.Other" class="p-l--32">
      <app-input formControlName="message" [multiLine]="true" [rows]="2"></app-input>
    </div>

    <mat-divider class="m-t--32 m-b--32"></mat-divider>

    <ng-container *ngIf="amIBooker && isBookingSignedByBoth">
      <p class="subtitle-1 f-weight--600 m-b--12" i18n>Cancellation policy</p>
      <p class="m-b--8"
        ><span i18n>Cancellation is free of charge until</span>&nbsp;<span class="f-weight--600" i18n>{{
          freeCancelUntil | date: DATE_FORMAT
        }}</span>
      </p>
      <p class="subtitle-1 f-weight--600 m-b--24 cursor text-underline" (click)="openCancellationPolicyModal()" i18n
        >More information</p
      >
    </ng-container>
  </form>

  <div fxLayoutAlign="start center" fxLayoutGap="12px">
    <button (click)="onBookingCancel()" class="gig-button stroked bright-red" i18n>Cancel request</button>
    <button (click)="goBack.next()" class="gig-button transparent" i18n>Back</button>
  </div>
</section>
