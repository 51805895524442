<main class="container">
  <section>
    <h1 class="h5" i18n="@@privacyPolicy">Privacy policy</h1>
    <p class="small-p mb-4">
      Du kanske också är intresserad av våra
      <a href="http://help.gigital.se/juridiskt/anvandarvillkor" target="_blank" rel="noopener">användarvillkor</a>
      eller
      <!-- <a [routerLink]="['/legal/cancellation']">avbokningspolicy</a> -->
      <a href="http://help.gigital.se/en/articles/3304388-cancellation-policy" target="_blank" rel="noreferrer"
        >avbokningspolicy</a
      >
      ?
    </p>
    <span class="dash bg-gray-4 mb-2 ml-0"></span>

    <!-- Conversations -->
    <div class="privacy-text">
      <h2 class="h5">1. Insamling av information</h2>
      <p>
        Gigital samlar in information från dig när du registrerar dig på vår webbplats, loggar in på ditt konto, gör ett
        köp, deltar i en tävling och/eller när du loggar ut. Den insamlade informationen inkluderar ditt namn, din
        e-postadress, ditt telefonnummer, fakturauppgifter och/eller kreditkort. Dessutom tar Gigital automatiskt emot
        och sparar information från din dator och webbläsare, inklusive din IP-adress, uppgifter om programvara och
        hårdvara och den begärda sidan.
      </p>

      <h2 class="h5 mt-5">2. Användning av information</h2>
      <p>Den information Gigital samlar in från dig kan användas för att:</p>
      <ul class="p">
        <li>Göra din upplevelse personlig och tillgodose dina personliga behov</li>
        <li>Tillhandahålla anpassade annonser</li>
        <li>Förbättra vår hemsida</li>
        <li>Förbättra vår kundservice och ditt behov av hjälp</li>
        <li>Kontakta dig via e-post</li>
        <li>Administrera en tävling, ett erbjudande eller en undersökning</li>
      </ul>

      <h2 class="h5 mt-5">3. Bokningssäkerhet</h2>
      <p>
        Gigital är den enda aktören som äger informationen som samlas in på www.gigital.se. Din personligt
        identifierbara information kommer inte att säljas, utbytas, överföras eller delas vidare till något annat
        företag, i något syfte, utan ditt samtycke, förutom då så krävs för att uppfylla en begäran och/eller
        överföring, t.ex. för att genomföra en bokning.
      </p>

      <h2 class="h5 mt-5">4. Utlämnande till tredje part</h2>
      <p>
        Gigital säljer, handlar, eller på annat sätt överför, inte personligt identifierbar information till utomstående
        parter. Detta inkluderar inte betrodd tredjepart som hjälper oss att driva Gigitals webbplats eller vårt
        företag, med kravet att dessa parter godkänner att hålla informationen konfidentiell.
      </p>
      <p>
        Gigital anser att det är nödvändigt att dela information i syfte att undersöka, förhindra eller vidta åtgärder
        mot illegala aktiviteter, misstänkt bedrägeri, situationer som medför en potentiell risk för en persons fysiska
        säkerhet, brott mot våra användarvillkor eller andra tillfällen då lagen kräver så.
      </p>
      <p>
        Allmänna uppgifter kan dock delas vidare till utomstående parter för marknadsföring, i annonssyfte eller andra
        ändamål.
      </p>

      <h2 class="h5 mt-5">5. Informationsskydd</h2>
      <p>
        Gigital vidtar en rad olika säkerhetsåtgärder för att skydda dina personliga uppgifter. Gigitalanvänder sig av
        avancerade krypteringsmetoder för att skydda känsliga uppgifter som överförs över internet. Gigital skyddar
        dessutom dina uppgifter offline. Endast medarbetare som ska uträtta ett specifikt jobb (t.ex. fakturering eller
        kundservice), får tillgång till personligt identifierbar information. De datorer/servrar som används för att
        lagra personligt identifierbar information lagras i en säker miljö.
      </p>

      <h2 class="h5 mt-5">Använder vi oss av cookies?</h2>
      <p>
        Ja. Gigitals cookies förbättrar tillgången till webbplatsen och identifierar återkommande besökare. Dessutom
        förbättrar Gigitals cookies användarupplevelsen genom att spåra och målanpassa användarens intressen. Vår
        användning av cookies kopplas över huvud taget inte till personligt identifierbar information på vår webbplats.
      </p>

      <h2 class="h5 mt-5">6. Analysverktyg</h2>
      <p>
        Vi använder analysverktyg från tredje part för att mäta mönster i tjänstens trafik och användning. Verktygen
        samlar in information som skickas från din enhet eller vår tjänst, t.ex. de webbplatser du besöker,
        tilläggsprogram och annan information som hjälper oss att förbättra tjänsten. Vi samlar in och använder den här
        statistiska informationen tillsammans med statistik från andra användare på ett sätt som gör att den inte kan
        kopplas till enskilda användare.
      </p>

      <h2 class="h5 mt-5">7. Avsluta prenumeration</h2>
      <p>
        Gigital använder den e-postadress du förser oss med för att skicka information och uppdateringar som angår dina
        bokningar, enstaka nyhetsbrev, relaterad bokninginformation etc. Om du inte längre vill ta emot sådana
        meddelanden hittar du information om hur du avslutar prenumerationen i slutet av varje e-postmeddelande.
      </p>

      <h2 class="h5 mt-5">8. Samtycke</h2>
      <p>Genom att använda vår webbplats godkänner du vår integritetspolicy.</p>
    </div>
  </section>
</main>
