import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Review } from '../../../generated/graphql';

@Component({
  selector: 'app-reviews-thumbs',
  templateUrl: './reviews-thumbs.component.html',
  styleUrls: ['./reviews-thumbs.component.scss'],
})
export class ReviewsThumbsComponent {
  @Input()
  numReviews = 0;

  @Input()
  reviews: Review[] = [];

  @Input()
  reviewsObservable: Observable<Review[]>;
  constructor() {}
}
