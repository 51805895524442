<mat-sidenav-container [class.overflow-visible]="!overlayHidden">
  <mat-sidenav
    #artistListSidenav
    role="navigation"
    fixedInViewport
    fixedTopGap="76"
    (openedStart)="overlayHidden = true"
    (closed)="overlayHidden = false">
    <app-artist-list-filter-side
      *ngIf="isHandset && (tags | async) as tags; else loadingTags"
      [tags]="tags"
      [matches]="matches"
      [hits]="hits"
      [filterablePlaces]="filterablePlaces | async"></app-artist-list-filter-side>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="container" *ngIf="list">
      <app-editorial-list class="lists" [obsList]="list" [split]="true"></app-editorial-list>
    </div>
    <header>
      <app-artist-list-filter
        *ngIf="tags | async as tags; else loadingTags"
        [tags]="tags"
        [matches]="matches"
        [hits]="hits"
        [filterableCountries]="filterablePlaces | async"
        (sidenavToggle)="artistListSidenav.toggle()"></app-artist-list-filter>
    </header>
    <main class="container">
      <!-- <div *ngIf="!list">
        <app-editorial-list-list></app-editorial-list-list>
      </div> -->

      <div
        *ngIf="displayPages | async as pages; else loading"
        class="artist-list"
        fxLayout="row wrap"
        fxLayoutGap.gt-sm="32px grid"
        fxLayoutGap="16px grid">
        <p class="label gray-2 result-counter" *ngIf="pages.totalPageCount > 0" fxFlex="grow" i18n
          >{{ pages.totalPageCount }} result{{ pages.totalPageCount > 1 ? 's' : '' }} found</p
        >

        <ng-container>
          <app-artist-list-item
            *ngFor="let page of pages.matching"
            [page]="page"
            class="artist-list-item"
            fxFlex="0 0 50%"
            fxFlex.gt-xs="0 0 33.33%"
            fxFlex.gt-sm="0 0 25%"
            fxFlex.gt-md="0 0 20%"></app-artist-list-item>
        </ng-container>

        <div
          class="artist-list-no-results"
          *ngIf="!paging && pages.matching.length > 0 && pages.fill.length > 0"
          fxFlex="100%">
          <h3 class="mat-h1 gray-1" i18n>Related results</h3>
          <p class="mat-body-1 gray-2" i18n>We found some more artists that may interest you based on your search.</p>
        </div>

        <ng-container>
          <app-artist-list-item
            *ngFor="let page of pages.fill"
            [page]="page"
            class="artist-list-item"
            fxFlex="0 0 50%"
            fxFlex.gt-xs="0 0 33.33%"
            fxFlex.gt-sm="0 0 25%"
            fxFlex.gt-md="0 0 20%"></app-artist-list-item>
        </ng-container>
        <div class="artist-list-no-results" *ngIf="!paging && !filtering" fxFlex="100%">
          <h3 class="mat-h1 gray-1" i18n *ngIf="pages.matching.length + pages.fill.length === 0">No search results</h3>
          <p class="mat-body-1 gray-2" i18n
            >Can't find what you are looking for? You can always
            <a (click)="openIntercom()" class="cursor-pointer">contact us</a> to get personal help.</p
          >
        </div>
        <ng-container *ngIf="pageFeed | async as pageFeed">
          <div class="artist-list-load-more" *ngIf="!paging && !filtering && pageFeed && pageFeed.hasNextPage">
            <a mat-flat-button color="primary" (click)="onFetchMore()" i18n>Load more artists</a>
          </div>
        </ng-container>
        <ng-container *ngIf="paging || filtering" [ngTemplateOutlet]="loadingItems"></ng-container>
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #loading>
  <div class="artist-list-loading" fxLayout="row wrap" fxLayoutGap.gt-sm="32px grid" fxLayoutGap="16px grid">
    <ng-container [ngTemplateOutlet]="loadingItems"></ng-container>
  </div>
</ng-template>
<ng-template #loadingItems>
  <div
    *ngFor="let item of [].constructor(15)"
    fxFlex="0 0 50%"
    fxFlex.gt-xs="0 0 33.33%"
    fxFlex.gt-sm="0 0 25%"
    fxFlex.gt-md="0 0 20%">
    <ngx-skeleton-loader
      class="artist-list-loading-image"
      [theme]="{ width: '100%', 'border-radius': '2px', 'background-color': '#fafafa' }">
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{
        width: '90%',
        'border-radius': '0',
        height: '26px',
        'background-color': '#fafafa',
        'margin-bottom': '8px'
      }">
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{
        width: '80%',
        'border-radius': '0',
        height: '24px',
        'background-color': '#fafafa',
        'margin-bottom': '0px'
      }">
    </ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #loadingTags>
  <ngx-skeleton-loader
    [theme]="{ width: '100%', height: '90px', 'margin-bottom': '-5px', 'background-color': '#fafafa' }">
  </ngx-skeleton-loader>
</ng-template>
