export enum socialMediaId {
  FACEBOOK = 1,
  TWITTER = 2,
  INSTAGRAM = 3,
  GOOGLE_PLUS = 4,
  WEBPAGE = 5,
  YOUTUBE = 6,
  SOUND_CLOUD = 7,
  SPOTIFY = 8,
  VIMEO = 9,
  MIX_CLOUD = 10
}