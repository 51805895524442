import { Booking, BookingDates, BookingStatusEnum, Signature, User } from '../../generated/graphql';

export const bookingDateInThePast = (bd: BookingDates) => {
  const parsed = parseInt(bd.date);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return !isNaN(parsed) ? parsed < yesterday.getTime() : (bd.date as unknown as Date).getTime() < yesterday.getTime();
};

export const inThePast = (booking: Booking): boolean => {
  if (new Date(booking.dates[0].date) < new Date()) {
    return true;
  }
  return false;
};

export const isDateInThePast = (date: string) => {
  const parsed = parseInt(date);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return !isNaN(parsed) ? parsed < yesterday.getTime() : (date as unknown as Date).getTime() < yesterday.getTime();
};

export const atLeastOneDateAccepted = (dates: BookingDates[]): boolean => {
  return dates.some((date) => date.isAccepted);
};

export const getStatus = (booking: Booking, userId: number): string => {
  if (booking.status === BookingStatusEnum.Cancelled || booking.status === BookingStatusEnum.Declined) {
    return 'cancelled';
  }

  if (booking.status === BookingStatusEnum.Pending && booking.dates.every((date) => bookingDateInThePast(date))) {
    return 'expired';
  }

  if (!atLeastOneDateAccepted(booking.dates)) {
    return 'waitingForAcceptingDates';
  }

  if (booking.status === BookingStatusEnum.Signed && inThePast(booking)) {
    if (
      booking.dates.filter(
        (d) => (d.review.recommended === null || d.review.recommended === undefined) && bookingDateInThePast(d),
      ).length > 0
    ) {
      return 'review';
    }
    return 'done';
  }

  if (isBookingSignedByBoth(booking)) return 'completed';

  if (booking.signatures?.length === 0) return 'new';

  const validSignatures = booking.signatures?.filter((signature: Signature): boolean => signature.valid);

  if (validSignatures && validSignatures.length === 1) {
    if (!!booking.signatures.find((s) => s.user?.id === userId)) {
      return 'waitingOther';
    }
    return 'waitingMe';
  }
};

export const amIBooker = (booking: Booking, userId: number): boolean => {
  return booking.user.id === userId;
};

export const haveISignedBooking = (booking: Booking, userId: number): boolean => {
  if (!booking.signatures || booking.signatures.length === 0) return false;
  const mySignature = booking.signatures.find((s) => s.user.id === userId && s.valid);
  return !!mySignature;
};

export const havePartnerSignedBooking = (booking: Booking, myUserId: number): boolean => {
  const validSignatures = booking.signatures?.filter((signature: Signature): boolean => signature.valid);

  if (!validSignatures || validSignatures.length === 0) return false;
  const parentSigniture = validSignatures.find((signiture) => signiture.user?.id !== myUserId);
  return !!parentSigniture;
};

export const haveISignedBookingDates = (booking: Booking, userId: number): boolean => {
  return amIBooker(booking, userId) ? true : atLeastOneDateAccepted(booking.dates);
};

export const havePartnerSignedBookingDates = (booking: Booking, userId: number): boolean => {
  return amIBooker(booking, userId) ? atLeastOneDateAccepted(booking.dates) : true;
};

export const canISignBooking = (booking: Booking, userId: number): boolean => {
  return !(
    haveISignedBooking(booking, userId) ||
    !(haveISignedBookingDates(booking, userId) && havePartnerSignedBookingDates(booking, userId)) ||
    getStatus(booking, userId) === 'cancelled' ||
    getStatus(booking, userId) === 'completed'
  );
};

export const isBookingSignedByBoth = (booking: Booking): boolean => {
  if (!booking.signatures || booking.signatures.length === 0) return false;

  if (booking.status === BookingStatusEnum.Signed) {
    const bookerSignature = booking.signatures.find((s) => s.isBooker && s.valid);
    const artistSignature = booking.signatures.find((s) => !s.isBooker && s.valid);
    return !!(bookerSignature && artistSignature);
  }
  return false;
};

export const DashboardBookingDateStatus = {
  BOOKED: $localize`Booked`,
  CANCELED: $localize`Canceled`,
};

export const getDashboardBookingDateStatus = (bookingDate: BookingDates): string => {
  if (
    bookingDate.booking.status === BookingStatusEnum.Cancelled ||
    bookingDate.booking.status === BookingStatusEnum.Declined
  ) {
    return DashboardBookingDateStatus.CANCELED;
  } else if (
    (bookingDate.isAccepted && bookingDate.booking.status === BookingStatusEnum.Signed) ||
    (bookingDate.isAccepted && isBookingSignedByBoth(bookingDate.booking))
  ) {
    return DashboardBookingDateStatus.BOOKED;
  }
};

export const isBookingOpen = (booking: Booking, currentUser: User): boolean => {
  return (
    getStatus(booking, currentUser.id) !== 'cancelled' &&
    getStatus(booking, currentUser.id) !== 'expired' &&
    getStatus(booking, currentUser.id) !== 'review' &&
    getStatus(booking, currentUser.id) !== 'done' &&
    getStatus(booking, currentUser.id) !== 'completed'
  );
};
