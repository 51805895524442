import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-iframe',
  templateUrl: './media-iframe.component.html',
  styleUrls: ['./media-iframe.component.scss'],
})
export class MediaIframeComponent implements OnInit {
  @Input() srcLink: string;
  @Input() iframeClass: string;
  @Input() iframeNgClass: string;
  @Input() isArtist: boolean;

  constructor() {}

  ngOnInit(): void {}
}
