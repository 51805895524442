import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArtistsHomeComponent } from '../pages/artists-home/artists-home.component';
import { ArtistDetailBookComponent } from './pages/artist-detail-book/artist-detail-book.component';
import { ArtistDetailEditComponent } from './pages/artist-detail-edit/artist-detail-edit.component';
import { ArtistDetailPreviewComponent } from './pages/artist-detail/artist-detail-preview/artist-detail-preview.component';
import { ArtistDetailComponent } from './pages/artist-detail/artist-detail.component';
import { ArtistImportProfileComponent } from './pages/artist-import-profile/artist-import-profile.component';
import { ArtistListComponent } from './pages/artist-list/artist-list.component';

const routes: Routes = [
  {
    path: '',
    component: ArtistsHomeComponent,
  },
  {
    path: 'import',
    component: ArtistImportProfileComponent,
  },
  {
    path: 'create',
    component: ArtistDetailEditComponent,
  },
  {
    path: 'search',
    component: ArtistListComponent,
  },
  {
    path: ':pageId',
    component: ArtistDetailComponent,
  },
  {
    path: ':pageId/book',
    component: ArtistDetailBookComponent,
  },
  {
    path: ':pageId/edit',
    component: ArtistDetailEditComponent,
  },
  {
    path: ':pageId/edit/preview',
    component: ArtistDetailPreviewComponent,
  },
  {
    path: 'list/:listId',
    component: ArtistListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ArtistsRoutingModule {}
