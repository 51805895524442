import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SocialMediaAccountType } from '../../../../generated/graphql';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';
import { ExtendedSocialMediaAccountData } from '../artist-import-form/artist-import-form.component';

export enum ImportablePageCardTypeEnum {
  YOUTUBE = 'youtube',
  SPOTIFY = 'spotify',
  MIXCLOUD = 'mixcloud',
}
@Component({
  selector: 'app-importable-page-card',
  templateUrl: './importable-page-card.component.html',
  styleUrls: ['./importable-page-card.component.scss'],
})
export class ImportablePageCardComponent implements OnInit {
  @Output() onImportablePageCardClick$: EventEmitter<ExtendedSocialMediaAccountData> = new EventEmitter<any>();

  @Input() socialMediaAccountData: ExtendedSocialMediaAccountData;
  @Input() isSelected: boolean;

  public IMPORTABLE_PAGE_CARD_TYPE = ImportablePageCardTypeEnum;
  public SOCIAL_MEDIA_ACCOUNT_TYPE = SocialMediaAccountType;

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.SOCIAL_MEDIA]);
  }

  public ngOnInit(): void {}

  public onImportablePageCardClick() {
    this.onImportablePageCardClick$.emit(this.socialMediaAccountData);
  }
}
