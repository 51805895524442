import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Booking, BookingDates, CalculatedPricing, GetCurrentUserGQL, User } from '../../../../../generated/graphql';
import { displayableFee } from '../../../../helpers/bookings';
import { SHORT_DATE_NO_YEAR } from '../../../../helpers/dates';

@Component({
  selector: 'app-booking-price-details',
  templateUrl: './booking-price-details.component.html',
  styleUrls: ['./booking-price-details.component.scss'],
})
export class BookingPriceDetailsComponent implements OnInit, OnChanges {
  @Input() calculatedPricing: CalculatedPricing;
  @Input() relevantDates: BookingDates[];
  @Input() isEquipmentSupply: boolean;
  @Input() dates: BookingDates[];
  @Input() amIBooker: boolean;
  @Input() booking: Booking;

  public DATE_FORMAT = SHORT_DATE_NO_YEAR;

  fullPenaltyPrice: number = 0;
  halfPenaltyPrice: number = 0;

  get acceptedDates() {
    let activeDates = this.relevantDates.filter((d) => d.isAccepted).filter((d) => d.cancelledDate === null);

    if (activeDates.length <= 0) {
      return this.dates;
    } else return activeDates;
  }

  ourPercentage = (value: number) => Math.round(Math.abs((value - 1) * 100));

  public POLICY_LINK = $localize`http://help.gigital.se/en/articles/3350100-payment-policy`;

  public displayableFee = displayableFee;
  currentUser$: Observable<User>;
  currentUser: User;
  constructor(private getUser: GetCurrentUserGQL) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.halfPenaltyPrice = this.dates
      .filter((d) => {
        return d.cancelledDaysBefore <= 29 && d.cancelledDaysBefore > 7;
      })
      .reduce((acc, d) => this.getPenaltyPrice(acc, d, 0.5), 0);
    this.fullPenaltyPrice = this.dates
      .filter((d) => {
        return d.cancelledDaysBefore <= 7;
      })
      .reduce((acc, d) => this.getPenaltyPrice(acc, d, 1), 0);
  }

  getPenaltyPrice = (acc: number, date: BookingDates, penalty: number) => {
    if (date.cancelledBy?.id === this.booking.user.id) {
      const price = date.price * (this.booking.billingInfo.fee || this.booking.user.fee) * penalty;
      return acc + price;
    }

    return 0;
  };

  ngOnInit(): void {
    this.currentUser$ = this.getUser.fetch().pipe(map((res) => res.data.me));
    this.currentUser$.subscribe((user) => (this.currentUser = user));
  }

  get getBaseDatesPrice(): number {
    let activeDatesPrice = this.acceptedDates.reduce((total, d) => total + d.price, 0);
    return Math.round(activeDatesPrice + this.calculatedPricing?.supplies + this.calculatedPricing?.travel);
  }
}
