import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { GigType, Page } from '../../../../../generated/graphql';
import { IconSubsets, IconsRegistryService } from '../../../../services/icons-registry.service';
import { SelectInputOptions } from '../../../../shared/components/inputs/select-input/select-input.component';

@Component({
  selector: 'app-artist-detail-book-description',
  templateUrl: './artist-detail-book-description.component.html',
  styleUrls: ['./artist-detail-book-description.component.scss'],
})
export class ArtistDetailBookDescriptionComponent {
  @Input() createRequestForm: FormGroup;
  @Input() page$: Observable<Page>;

  public gigTypeOptions: SelectInputOptions[] = [
    { label: $localize`Corporate`, value: GigType.Business },
    { label: $localize`Private`, value: GigType.Private },
    { label: $localize`Venue`, value: GigType.Venue },
    { label: $localize`Other`, value: GigType.Others },
  ];

  get locationLabel(): string {
    return $localize`Location`;
  }

  get titleLabel(): string {
    return $localize`Event title`;
  }

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.FORMS]);
  }

  public saveFormattedAddress(addressEvent: { formatted_address: string }) {
    this.createRequestForm.get('formattedAddress').setValue(addressEvent.formatted_address);
  }
}
