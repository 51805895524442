import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreatePageFromSocialMediaGQL,
  GetImportablePageDataGQL,
  ImportablePages,
  SocialMediaAccountData,
  SocialMediaAccountDataInput,
  SocialMediaAccountPlatformInput,
  SocialMediaAccountType,
  UpdatePageWithSocialMediaGQL,
} from '../../../../generated/graphql';
import { getEnumValues } from '../../../helpers';
import { ProgressStepNumber } from '../../../models/ArtistProgress';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';
import { ImportablePageCardTypeEnum } from '../importable-page-card/importable-page-card.component';

export interface ExtendedSocialMediaAccountData extends SocialMediaAccountData {
  mediaType: SocialMediaAccountType;
}
interface SelectedImportableData {
  YOUTUBE: ExtendedSocialMediaAccountData;
  SPOTIFY: ExtendedSocialMediaAccountData;
  MIXCLOUD: ExtendedSocialMediaAccountData;
}

@Component({
  selector: 'app-artist-import-form',
  templateUrl: './artist-import-form.component.html',
  styleUrls: ['./artist-import-form.component.scss'],
})
export class ArtistImportFormComponent implements OnInit {
  @Output() nextStepClick$: EventEmitter<any> = new EventEmitter<any>();

  @Input() isEditMode: boolean = false;
  @Input() pageId: number;

  public onDropdownClose$: EventEmitter<any> = new EventEmitter();

  public importablePageData$: Observable<ImportablePages>;

  public selectedImportableData: SelectedImportableData = { YOUTUBE: null, SPOTIFY: null, MIXCLOUD: null };

  public selectedMediaPlatform: SocialMediaAccountType = SocialMediaAccountType.Youtube;
  public loading: boolean = false;
  public importForm: FormGroup;

  public SOCIAL_MEDIA_ACCOUNT_TYPE = SocialMediaAccountType;
  public IMPORTABLE_PAGE_TYPE = ImportablePageCardTypeEnum;
  public PROGRESS_STEP_NUMBER = ProgressStepNumber;
  public getEnumValues = getEnumValues;

  public get selectedProfilesLength(): number {
    let length: number = 0;
    Object.keys(this.selectedImportableData).map((key) => {
      if (this.selectedImportableData[key]) length++;
    });
    return length;
  }

  public constructor(
    private createPageFromSocialMediaGQL: CreatePageFromSocialMediaGQL,
    private updatePageWithSocialMediaGQL: UpdatePageWithSocialMediaGQL,
    private getImportablePageDataGQL: GetImportablePageDataGQL,

    private iconsService: IconsRegistryService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
  ) {
    this.iconsService.registerIcons([IconSubsets.SOCIAL_MEDIA, IconSubsets.NAVIGATION, IconSubsets.ACTIONS]);
  }

  get importablePageCardTypes(): string[] {
    return Object.keys(this.IMPORTABLE_PAGE_TYPE).map((m) => m.toUpperCase());
  }

  get socialMediaAccountTypes(): string[] {
    return Object.keys(this.SOCIAL_MEDIA_ACCOUNT_TYPE);
  }

  public ngOnInit(): void {
    this.importForm = new FormGroup({
      searchText: new FormControl('', Validators.required),
    });

    this.setSelectedDataFromStorage();
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
  }

  private setSelectedDataFromStorage(): void {
    if (this.isEditMode && localStorage.getItem('importablePageData')) {
      if (this.pageId) {
        let dataFromStorage: Map<number, SelectedImportableData> = new Map<number, SelectedImportableData>(
          JSON.parse(localStorage.getItem('importablePageData')),
        );
        if (dataFromStorage.has(this.pageId)) {
          this.selectedImportableData = dataFromStorage.get(this.pageId);
        }
      }
    }
  }

  public onMediaPlatformSelect(platform: SocialMediaAccountType): void {
    this.selectedMediaPlatform = platform;
    if (this.importForm.value.searchText) {
      this.onSearchClick();
    }
    this.onDropdownClose$.emit();
  }

  public onSearchClick(): void {
    this.importablePageData$ = this.getImportablePageDataGQL
      .watch({ userName: this.importForm.value.searchText, platforms: [this.selectedMediaPlatform] })
      .valueChanges.pipe(
        map(({ data }) => data.getImportablePageData),
        map((getImportablePageData) => {
          if (this.isEditMode) {
            Object.keys(getImportablePageData).map((key) => {
              if (getImportablePageData[key]) {
                getImportablePageData[key] = getImportablePageData[key].slice(0, 4);
              }
            });
          }
          return getImportablePageData;
        }),
        map((getImportablePageData) => {
          return getImportablePageData[this.selectedMediaPlatform.toLowerCase()]
            ? getImportablePageData[this.selectedMediaPlatform.toLowerCase()]
            : [];
        }),
        map((getImportablePageData) => {
          getImportablePageData.forEach((pageData, index) => {
            (pageData as ExtendedSocialMediaAccountData).mediaType = this.selectedMediaPlatform;
          });
          this.loading = false;
          return getImportablePageData;
        }),
      );
  }

  public onImportablePageCardClick(importableData: ExtendedSocialMediaAccountData): void {
    this.selectedImportableData[importableData.mediaType] =
      this.selectedImportableData[importableData.mediaType]?.id === importableData.id ? null : importableData;
  }

  public isImportablePageSelected(importableData: ExtendedSocialMediaAccountData) {
    if (!!importableData) {
      if (!!this.selectedImportableData[importableData.mediaType]) {
        if (this.selectedImportableData[importableData.mediaType].id === importableData.id) {
          return true;
        } else return false;
      } else return false;
    } else return false;
  }

  public onRemoveMediaClick(mediaType: SocialMediaAccountType): void {
    this.selectedImportableData[this.SOCIAL_MEDIA_ACCOUNT_TYPE[mediaType]] = null;
  }

  public onSaveDataClick(): void {
    if (
      this.selectedImportableData.YOUTUBE ||
      this.selectedImportableData.MIXCLOUD ||
      this.selectedImportableData.SPOTIFY
    ) {
      if (this.isEditMode && !!this.pageId) {
        this.updatePageByImportedData();
      } else this.createNewPageOnImportedData();
    } else this.nextStepClick$.next();
  }

  private setImportableDataToStorage(): void {
    let importableDataToStorage: Map<number, SelectedImportableData> = new Map<number, SelectedImportableData>();

    if (!!localStorage.getItem('importablePageData')) {
      importableDataToStorage = new Map<number, SelectedImportableData>(
        JSON.parse(localStorage.getItem('importablePageData')),
      );
    }

    importableDataToStorage.set(this.pageId, this.selectedImportableData);
    localStorage.setItem('importablePageData', JSON.stringify(Array.from(importableDataToStorage)));
  }

  private createNewPageOnImportedData(): void {
    this.loading = true;
    this.createPageFromSocialMediaGQL.mutate({ page: this.mapImportableData() }).subscribe(({ data }) => {
      this.loading = false;
      this.pageId = data.createPageFromSocialMedia.id;
      this.setImportableDataToStorage();
      this.router.navigate(['../', data.createPageFromSocialMedia.slug, 'edit', 'preview'], {
        relativeTo: this.route,
      });
    });
  }

  private updatePageByImportedData(): void {
    this.loading = true;

    this.updatePageWithSocialMediaGQL
      .mutate({
        pageId: this.pageId,
        socialMediaData: this.mapImportableData(),
      })
      .subscribe(({ data }) => {
        this.loading = false;
        this.setImportableDataToStorage();
        this.snackbar.open($localize`Page has been successfully updated`, undefined, { duration: 3000 });
        this.router.navigate(['../', 'artists', data.updatePageWithSocialMedia.slug, 'edit']);
        this.nextStepClick$.next();
      });
  }

  private mapImportableData(): SocialMediaAccountDataInput {
    const importableData: SocialMediaAccountDataInput = {
      name: this.fetchImportableDataProperty('name', ''),
      description: this.fetchImportableDataProperty('description', ''),
      profilePictureUrl: this.fetchImportableDataProperty('thumbnailImageUrl', ''),
      platforms: this.mapImportableMedias(),
    };
    return importableData;
  }

  private mapImportableMedias(): SocialMediaAccountPlatformInput[] {
    let importableMedias = [];
    Object.keys(this.selectedImportableData).forEach((key) => {
      if (this.selectedImportableData[key]) {
        importableMedias.push({
          id: this.selectedImportableData[key].id,
          type: this.selectedImportableData[key].mediaType,
        });
      }
    });
    return importableMedias;
  }

  private fetchImportableDataProperty<T>(prop: string, defaultValue: T): T {
    if (
      this.selectedImportableData[this.SOCIAL_MEDIA_ACCOUNT_TYPE.Spotify] &&
      this.selectedImportableData[this.SOCIAL_MEDIA_ACCOUNT_TYPE.Spotify][prop]
    ) {
      return this.selectedImportableData[this.SOCIAL_MEDIA_ACCOUNT_TYPE.Spotify][prop];
    } else if (
      this.selectedImportableData[this.SOCIAL_MEDIA_ACCOUNT_TYPE.Youtube] &&
      this.selectedImportableData[this.SOCIAL_MEDIA_ACCOUNT_TYPE.Youtube][prop]
    ) {
      return this.selectedImportableData[this.SOCIAL_MEDIA_ACCOUNT_TYPE.Youtube][prop];
    } else if (
      this.selectedImportableData[this.SOCIAL_MEDIA_ACCOUNT_TYPE.Mixcloud] &&
      this.selectedImportableData[this.SOCIAL_MEDIA_ACCOUNT_TYPE.Mixcloud][prop]
    ) {
      return this.selectedImportableData[this.SOCIAL_MEDIA_ACCOUNT_TYPE.Mixcloud][prop];
    } else return defaultValue;
  }
}
