<section fxFlex="100%" *ngIf="channel" fxLayout="column" class="main">
  <app-channel-header [user]="getPartner()" [booking]="booking"></app-channel-header>

  <div fxHide fxShow.lt-sm fxLayout="row" fxLayoutAlign="space-between center" class="booking-preview border-bottom">
    <div>
      <h4 *ngIf="booking.title" class="m--0 m-b--4 text-underline f-weight--600">{{ booking.title }}</h4>
      <h4 *ngIf="!booking.title" class="m--0 m-b--4 text-underline f-weight--600">
        {{ booking?.title ? booking.title : (booking.type | titlecase) + ' gig' }}
      </h4>
      <span class="f-size--12" fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="flex-start center">
        <span>{{ datesStatus }}</span>
        <span class="dot-separator"></span>
        <span>{{ booking.dates.length || '0' }} dates</span>
      </span>
    </div>
    <div>
      <button mat-flat-button class="gig-button black" (click)="openDetails()" i18n>Details</button>
    </div>
  </div>

  <div fxFlex="calc(100% - 105px)" fxFlex.lt-sm="calc(100% - 174px)" fxLayout="column">
    <div fxFlex="calc(100% - 151px)" class="messages-container" #messagesContainer>
      <div class="messages-list">
        <span *ngIf="booking" class="date">
          {{ booking.createdDate | apiDate | date }}
        </span>

        <div class="policy-message m-b--24">
          <p fxLayoutAlign="start center" fxLayoutGap="8px" class="policy-message--header">
            <mat-icon svgIcon="info-fill" class="info-icon"></mat-icon>
            <span i18n>Booking terms</span>
          </p>

          <div class="policy-message--content">
            <p i18n
              >You can now chat with the artist. Contact details can be shared after the booking has been signed. In
              some cases, Gigital may pass on contact details to interested parties.</p
            >
            <p i18n
              >Within the framework of the terms of use, this booking needs to be completed on Gigital. Violation of the
              terms of use may result in a fine and suspension from the platform</p
            >
            <p>
              <span i18n>Read more about the</span>&nbsp;<a [href]="termsLink" i18n>Booking Terms</a>&nbsp;<span i18n
                >and the</span
              >&nbsp;<a [href]="policyLink" i18n>Cancellation Policy</a>
            </p>
          </div>
        </div>

        <app-booking-status
          *ngIf="currentUser.id"
          [status]="bookingStatus"
          [amIBooker]="amIBooker"></app-booking-status>

        <div class="p-t--20 text-left" fxLayout="column" fxLayoutAlign="end normal">
          <div>
            <app-channel-message *ngFor="let message of channel?.groupedMessages; let i = index" [message]="message">
            </app-channel-message>
          </div>
        </div>
      </div>
    </div>

    <app-contact-info-warn *ngIf="warnContactInfo" (acknowledged)="acknowledgeContactInfo()"></app-contact-info-warn>

    <form fxLayout="row" class="channel-form" *ngIf="showInput">
      <div fxFlex="100%" class="message-input">
        <textarea
          [formControl]="messageBody"
          [disabled]="isBookingStale"
          class="channel-form--textarea"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          placeholder="Type a message"></textarea>

        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayoutGap.lt-sm="8px" class="message-input--buttons">
          <button [disabled]="isBookingStale" (click)="uploadFile()" class="attach">
            <mat-icon svgIcon="attach-file"></mat-icon>
          </button>

          <button [disabled]="isBookingStale" (click)="submitMessage()" class="send">
            <mat-icon svgIcon="send-message"></mat-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
</section>

<div class="channel--loading" *ngIf="loading">
  <ngx-skeleton-loader
    [theme]="{
      width: '100%',
      height: '100%',
      'border-radius': '2px',
      'background-color': '#eee'
    }">
  </ngx-skeleton-loader>
</div>

<!-- [disabled]="!messageBody.value || messageLoading || warnContactInfo" -->
