import { Component, Input, OnInit } from '@angular/core';
import { Booking, User } from '../../../../../generated/graphql';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-channel-header',
  templateUrl: './channel-header.component.html',
  styleUrls: ['./channel-header.component.scss'],
})
export class ChannelHeaderComponent implements OnInit {
  @Input() booking: Booking;
  @Input() user: User;

  constructor(private iconService: IconsRegistryService) {
    this.iconService.registerIcons([IconSubsets.NAVIGATION]);
  }

  ngOnInit(): void {}
}
