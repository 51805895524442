import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetGigsByUserGQL, Gig, GigTemplate } from '../../../../generated/graphql';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-previous-gig-modal',
  templateUrl: './previous-gig-modal.component.html',
  styleUrls: ['./previous-gig-modal.component.scss'],
})
export class PreviousGigModalComponent implements OnInit {
  gigs: Observable<Gig[]>;
  hasTemplates = false;
  constructor(
    private dialogRef: MatDialogRef<PreviousGigModalComponent>,
    private iconsService: IconsRegistryService,
    private userGig: GetGigsByUserGQL,

    @Inject(MAT_DIALOG_DATA) public data: { templates: GigTemplate[] },
  ) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  ngOnInit(): void {
    this.hasTemplates = !!this.data?.templates;
    if (!this.hasTemplates) {
      this.gigs = this.userGig.watch().valueChanges.pipe(map((d) => d.data.gigsByUser));
    }
  }

  selectGig(gig: Gig) {
    this.dialogRef.close({ gig });
  }

  selectTemplate(template: GigTemplate) {
    this.dialogRef.close({ template });
  }
}
