import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { take } from 'rxjs/operators';
import {
  GetPageMediaGQL,
  LinkInput,
  Page,
  PageLink,
  UpdatePageGalleryGQL,
  UpdatePageMediaGQL,
} from '../../../../../../generated/graphql';
import { filterAudioMediaLinks, filterVideoMediaLinks, sortMediaByOrder } from '../../../../../../utils/pageMedia';
import { ArtistProgressStep, ProgressStepNumber } from '../../../../../models/ArtistProgress';
import { IconsRegistryService, IconSubsets } from '../../../../../services/icons-registry.service';
import { PopupService } from '../../../../../services/popup.service';
import { InfoBarType } from '../../../../../shared/components/info-bar/info-bar.component';
import { EditMediaModalComponent } from '../../../../components/edit-media-modal/edit-media-modal.component';
import { ExtendedImage } from './artist-edit-media-gallery/artist-edit-media-gallery.component';

@Component({
  selector: 'app-artist-edit-media',
  templateUrl: './artist-edit-media.component.html',
  styleUrls: ['./artist-edit-media.component.scss'],
})
export class ArtistEditMediaComponent implements OnInit {
  @Output() goToStep$: EventEmitter<number> = new EventEmitter<number>();

  @Input() pageProgressStep: ArtistProgressStep;
  @Input() page: Page;

  public gallery: ExtendedImage[] = [];
  public video: PageLink[] = [];
  public audio: PageLink[] = [];

  public isLoading: boolean = true;

  public PROGRESS_STEP_NUMBER = ProgressStepNumber;
  public INFO_BAR_TYPE = InfoBarType;

  public constructor(
    private updatePageGalleryGQL: UpdatePageGalleryGQL,
    private updatePageMediaGQL: UpdatePageMediaGQL,
    private getPageMediaGQL: GetPageMediaGQL,

    public iconsService: IconsRegistryService,
    public popupService: PopupService,
    private renderer: Renderer2,
  ) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  public ngOnInit(): void {
    this.getPageMediaGQL
      .fetch({ pageId: this.page.id }, { fetchPolicy: 'network-only' })
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.isLoading = false;
        this.audio = sortMediaByOrder(data.findOnePage.links.filter(filterAudioMediaLinks));
        this.video = sortMediaByOrder(data.findOnePage.links.filter(filterVideoMediaLinks));
        this.gallery = sortMediaByOrder(data.findOnePage.gallery).map((image) => {
          return { ...image, delete: false };
        });
      });
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
  }

  public openMediaModal(): void {
    this.popupService
      .open(EditMediaModalComponent, '800px', {
        data: { videos: [...this.video], songs: [...this.audio] },
        panelClass: 'gigital-modal',
      })
      .afterClosed()
      .subscribe((d: { links?: any[] }) => {
        if (d && d.links) {
          this.video = sortMediaByOrder(d.links.filter(filterVideoMediaLinks));
          this.audio = sortMediaByOrder(d.links.filter(filterAudioMediaLinks));
          this.updatePageMedia();
        }
      });
  }

  public updatePageMedia(): void {
    this.updatePageMediaGQL
      .mutate({
        pageId: this.page.id,
        links: this.video.concat(this.audio).map((link) => {
          return {
            isHighlight: link.order === 0,
            socialMediaId: link.socialMediaId,
            socialMediaTypeId: link.socialMediaTypeId,
            url: link.url,
            order: link.order,
          };
        }) as LinkInput[],
      })
      .subscribe(({ data }) => {
        this.page.links = data.updatePageMedia.links;
        this.audio = sortMediaByOrder(data.updatePageMedia.links.filter(filterAudioMediaLinks));
        this.video = sortMediaByOrder(data.updatePageMedia.links.filter(filterVideoMediaLinks));
      });
  }

  public addNewImagesToGallery(files: File[]): void {
    let params = {
      pageId: this.page.id,
      images: files.map((f) => {
        return { image: { image: f } };
      }),
    };

    this.updatePageGalleryGQL
      .mutate(params, { context: { useMultipart: true } })
      .subscribe(({ data }) => (this.gallery = sortMediaByOrder(data.updatePageGallery.gallery)));
  }

  public updatePageGallery(): void {
    let params = {
      pageId: this.page.id,
      images: this.gallery.map((image) => {
        return { image: { id: image.id, delete: image.delete }, order: image.order };
      }),
    };

    this.updatePageGalleryGQL.mutate(params).subscribe(({ data }) => {
      this.page.gallery = data.updatePageGallery.gallery;
      this.gallery = sortMediaByOrder(data.updatePageGallery.gallery);
    });
  }
}
