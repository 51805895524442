import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Booking,
  BookingStatusEnum,
  Currency,
  GetCurrentUserGQL,
  GetPagesByUserGQL,
  Gig,
  GigSlot,
  Page,
  SlotStatus,
  UpdateGigGQL,
  User,
} from '../../../../../../generated/graphql';
import { apiToDate } from '../../../../../helpers';
import {
  DAY_MONTH_DATE,
  DateDetailsObject,
  HOURS_MINUTES,
  getDateDetailsObject,
  isNextYearOrLater,
} from '../../../../../helpers/dates';
import { IconSubsets, IconsRegistryService } from '../../../../../services/icons-registry.service';
import { SelectApplicantsComponent } from '../../../../components/select-applicants/select-applicants.component';
import { GigEditSlotComponent } from '../gig-edit-slot/gig-edit-slot.component';

interface BookingWithPrice extends Booking {
  price?: number;
  priceCurrency?: Currency;
}

@Component({
  selector: 'app-gig-details-slot-details',
  templateUrl: './gig-details-slot-details.component.html',
  styleUrls: ['./gig-details-slot-details.component.scss'],
})
export class GigDetailsSlotDetailsComponent implements OnInit, OnChanges {
  @Input()
  slot: GigSlot;

  @Input()
  gig: Gig;

  @Input()
  hasApprovedPage: boolean;

  @Input()
  hasApplied: boolean;

  @Output()
  selectSlot = new EventEmitter<GigSlot>();

  isSelected: boolean;
  bookingStatus = BookingStatusEnum;
  title: string;
  pages: Observable<Page[]>;
  datesLeft: number;
  dateFormat = DAY_MONTH_DATE;
  hhmmFormat = HOURS_MINUTES;
  loading = false;

  applications: Booking[];
  requests: Booking[];
  booking: BookingWithPrice;

  slotStatus = SlotStatus;

  currentUser: Observable<User>;
  applicationImages: SafeUrl[] = [];

  dropdownOpened = false;

  isNextYearOrLater = isNextYearOrLater;

  get canSelect() {
    return !this.gig.isOwner && this.datesLeft >= 0;
  }

  public get dateDetails(): DateDetailsObject {
    return getDateDetailsObject(this.slot?.date);
  }

  constructor(
    private modal: MatDialog,
    private updateGig: UpdateGigGQL,
    private getPages: GetPagesByUserGQL,
    private snackbar: MatSnackBar,
    private getMe: GetCurrentUserGQL,
    private sanitizer: DomSanitizer,
    private iconsService: IconsRegistryService,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  ngOnInit(): void {
    this.title = this.getTitle();

    this.currentUser = this.getMe.watch().valueChanges.pipe(map(({ data }) => data.me));
    this.currentUser.subscribe((me) => {
      if (me && !this.pages) {
        this.pages = this.getPages
          .watch()
          .valueChanges.pipe(map((d) => d.data.pagesByUser.filter((p) => p.searchable)));
      }
    });

    this.datesLeft = this.getDatesLeft();

    this.applicationImages = this.setAppliedWithImages(this.slot);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slot && changes.slot.currentValue) {
      const booking: BookingWithPrice = this.slot.applications
        ? this.slot.applications.find((a) => a.status === BookingStatusEnum.Signed)
        : undefined;
      if (this.slot.status === SlotStatus.Assigned && booking) {
        const date = booking.dates.find((d) => d.gigSlot && d.gigSlot.id === this.slot.id);
        if (date) {
          booking.price = date.price;
          booking.priceCurrency = date.priceCurrency;
        }
        this.booking = booking;
        return;
      }

      this.requests = this.slot.applications
        ? this.slot.applications.filter((a) => a.status === BookingStatusEnum.Pending)
        : [];

      this.applications = this.slot.applications
        ? this.slot.applications.filter((a) => a.status === BookingStatusEnum.Application)
        : [];

      this.applicationImages = this.setAppliedWithImages(changes.slot.currentValue);
    }
  }

  private setAppliedWithImages(slot: GigSlot) {
    const urls: SafeUrl[] = [];
    for (const app of slot.applications) {
      if (app.page.profilePicture) {
        urls.push(
          this.sanitizer.bypassSecurityTrustUrl(
            `url(https://res.cloudinary.com/hthruuqgk/image/upload/c_lfill,w_30,h_30,r_max/f_auto,fl_progressive,q_auto/fl_alpha.preserve_transparency/dpr_1.0/v1/${app.page.profilePicture.fileName})`,
          ),
        );
      }
    }
    return urls;
  }

  openEdit(): void {
    const modalRef = this.modal.open(GigEditSlotComponent, {
      minWidth: '38%',
      data: {
        slot: this.slot,
        gig: this.gig,
      },
    });
  }

  openManage(): void {
    this.modal.open(SelectApplicantsComponent, { data: { gig: this.gig } });
  }

  removeSlot(): void {
    this.loading = true;
    this.updateGig
      .mutate({
        gig: {
          id: this.gig.id,
          name: this.gig.name,
          type: this.gig.type,
          placeId: this.gig.placeId,
          slots: [
            {
              id: this.slot.id,
              delete: true,
              date: apiToDate(this.slot.date).toISOString(),
              price: this.slot.price,
              tags: this.slot.tags.map((t) => t.id),
            },
          ],
        },
      })
      .subscribe(
        () => {
          this.snackbar.open($localize`Removed slot`, undefined, {
            duration: 3000,
          });
          this.loading = false;
        },
        (error) => {
          this.snackbar.open($localize`Could not remove slot`, undefined, {
            duration: 3000,
          });
          this.loading = false;
          console.error(error);
        },
      );
  }

  copySlot(): void {
    this.loading = true;
    this.updateGig
      .mutate({
        gig: {
          id: this.gig.id,
          name: this.gig.name,
          type: this.gig.type,
          placeId: this.gig.placeId,
          slots: [
            {
              date: apiToDate(this.slot.date).toISOString(),
              price: this.slot.price,
              tags: this.slot.tags.map((t) => t.id),
              startTime: this.slot.startTime ? apiToDate(this.slot.startTime).toISOString() : undefined,
              endTime: this.slot.endTime ? apiToDate(this.slot.endTime).toISOString() : undefined,
            },
          ],
        },
      })
      .subscribe(
        () => {
          this.snackbar.open($localize`Copied slot`, undefined, {
            duration: 3000,
          });
          this.loading = false;
        },
        (error) => {
          this.snackbar.open($localize`Could not copy slot`, undefined, {
            duration: 3000,
          });
          this.loading = false;
          console.error(error);
        },
      );
  }

  clickSelect() {
    if (!this.canSelect) return;

    this.isSelected = !this.isSelected;

    this.selectSlot.next(this.slot);
  }

  getDatesLeft() {
    const oneDay = 1000 * 60 * 60 * 24;

    const lastDate = apiToDate(this.slot.deadline);
    const start = Date.UTC(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate());

    const now = new Date();
    const end = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());

    return (start - end) / oneDay;
  }

  private getTitle(): string {
    let titles = [];
    if (this.slot.tags.length > 0) {
      if (this.slot.tags.some((tag) => tag.id === 1 || tag.id === 3)) {
        titles.push($localize`Live music`);
      }
      if (this.slot.tags.some((tag) => tag.id === 2)) {
        titles.push($localize`DJ`);
      }
      if (!this.slot.tags.some((tag) => tag.id === 1 || tag.id === 3) && !this.slot.tags.some((tag) => tag.id === 2)) {
        titles.push(' -');
      }
    } else {
      titles.push(' -');
    }

    return titles.join(', ');
  }

  public toggleDropdown() {
    this.dropdownOpened = !this.dropdownOpened;
  }
}
