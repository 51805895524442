import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Page, PageCategory, PageLink } from '../../../../generated/graphql';
import { socialMediaId } from '../../../models/SocialMediaType';
import { AnalyticsService } from '../../../services/analytics.service';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';
import { ArtistListItemVideoComponent } from '../artist-list-item-video/artist-list-item-video.component';

@Component({
  selector: 'app-artist-list-item',
  templateUrl: './artist-list-item.component.html',
  styleUrls: ['./artist-list-item.component.scss'],
})
export class ArtistListItemComponent implements OnInit {
  @Input()
  page: Page;

  @Input()
  small = false;

  @Input()
  darkMode = false;

  pageType: PageCategory;
  pageVideo: PageLink;

  constructor(
    private iconsService: IconsRegistryService,
    private analytics: AnalyticsService,
    private dialog: MatDialog,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public ngOnInit() {
    this.pageType = this.page.category;

    if (this.page.links) {
      this.pageVideo = this.page.links.find(
        (l) => l.isHighlight && (l.socialMediaId === socialMediaId.YOUTUBE || l.socialMediaId === socialMediaId.VIMEO),
      );
    }
  }

  showVideo(event): void {
    event.stopPropagation();
    event.preventDefault();

    this.analytics.emitEvent('artist_list_video_played', { page_id: this.page.id, papge_name: this.page.name });

    this.dialog.open(ArtistListItemVideoComponent, {
      panelClass: 'transparent-dialog',
      backdropClass: 'transparent-dialog',
      data: { page: this.page, pageVideo: this.pageVideo },
    });
  }
}
