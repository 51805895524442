import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetUserFilesGQL, GigitalFile } from '../../../generated/graphql';

interface FileUploadModalData {
  title: string;
  okText: string;
  cancelText: string;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @ViewChild('file', { static: true }) fileInput: ElementRef<HTMLInputElement>;
  data: FileUploadModalData = {
    cancelText: 'Cancel',
    okText: 'Upload',
    title: 'Attach a file',
  };

  selectedFile: File;
  selectedFileSize: string;

  files: Observable<GigitalFile[]>;
  fileTooLargeError = false;

  constructor(
    private dialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public inData: FileUploadModalData,
    private getUserFiles: GetUserFilesGQL,
  ) { }

  ngOnInit() {
    if (!!this.inData) {
      this.data = this.inData;
    }
  }

  clickGetUserFiles() {
    if (!!this.files) {
      return;
    }
    this.files = this.getUserFiles.watch().valueChanges.pipe(
      map(({ data }) => {
        return data.filesByUser;
      }),
    );
    this.files.subscribe(console.log);
  }

  clickSelect() {
    this.fileInput.nativeElement.click();
  }

  clickCancel() {
    this.dialogRef.close(null);
    this.selectedFile = null;
  }

  clickUpload(existingFileId?: number) {
    if (this.selectedFile || existingFileId) {
      this.dialogRef.close({ file: this.selectedFile, existingFileId });
      this.selectedFile = null;
    }
  }

  onFileChanges(d: FileList) {
    this.selectedFile = d[0];
    this.selectedFileSize = this.getFileSize(this.selectedFile.size, 2);
    if (this.selectedFile.size > 10000000) {
      this.fileTooLargeError = true;
    } else {
      this.fileTooLargeError = false;
    }
  }

  getFileSize(bytes: number, decimals: number): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
}
