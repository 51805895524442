<h2 fxHide.lt-sm class="text-center m-b--56">{{ faq.fields.title }}</h2>
<h2 fxHide fxShow.lt-sm class="f-size--22 m-b--36" i18n>FAQ</h2>

<div fxLayoutAlign="center start">
  <div fxFlex="778px" fxFlex.lt-md="100%" fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="center start">
    <app-expansion-panel
      *ngFor="let question of faq.fields.blocks"
      class="w--100per"
      [ngClass]="{ 'multiple-sections': question.sys.id === '5TLPunXmu2nQ3N8PnCu7jS' }"
      [title]="question.fields.title"
      [isHTML]="true"
      [content]="richTextToHtml(question.fields.body)"></app-expansion-panel>
  </div>
</div>
