<iframe
  [src]="srcLink | media"
  [ngClass]="iframeNgClass"
  [class]="iframeClass"
  [class.spotify-artist]="isArtist"
  frameborder="0"
  allowfullscreen
  allow="encrypted-media"
  allowtransparency="true"
  allowtransparency="allowtransparency">
</iframe>
