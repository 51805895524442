import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import {
  CreateGigGQL,
  EquipmentStatus,
  GetTagsGQL,
  GetTemplatesGQL,
  GigDeadlineEnum,
  GigInput,
  GigSlotInput,
  GigTemplate,
  GigType,
  PageCategory,
  SupplyTypes,
  Tag,
} from '../../../../generated/graphql';
import { contactInfoValidator } from '../../../../utils/contactInfo';
import { dataURLtoFile, dateToApi } from '../../../helpers';
import { timeStringToTimestamp } from '../../../helpers/dates';
import { AnalyticsService } from '../../../services/analytics.service';
import { AuthService } from '../../../services/auth.service';
import PlaceResult = google.maps.places.PlaceResult;

interface ImageToBeUploaded {
  img: File;
  imgSrc: SafeUrl;
  safeCssUrl: SafeUrl;
  isOld: boolean;
  oldId?: number;
}
@Component({
  selector: 'app-create-gig-form',
  templateUrl: './create-gig-form.component.html',
  styleUrls: ['./create-gig-form.component.scss'],
})
export class CreateGigFormComponent implements OnInit {
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  public isBookingCreated: boolean = false;
  public isLoading: boolean = false;
  public createGigForm: FormGroup;
  public activeStep: number = 1;
  public categoryTags: Tag[];
  public pageName: string;
  public pageId: number;
  templates: GigTemplate[] = [];

  public CREATE_GIG_STEP_DATA: any[] = [
    { number: 1, title: $localize`Event Info` },
    { number: 2, title: $localize`Details` },
    { number: 3, title: $localize`Summary` },
  ];

  public EQUIPMENT_STATUS_ENUM = EquipmentStatus;

  public constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private sanitizer: DomSanitizer,

    private analyticsService: AnalyticsService,
    private authService: AuthService,

    private createGigGQL: CreateGigGQL,
    private getTagsGQL: GetTagsGQL,
    private getTemplatesGQL: GetTemplatesGQL,

    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.createGigForm = new FormGroup({
      pageCategory: new FormControl([PageCategory.Live]),
      gigType: new FormControl(GigType.Business, Validators.required),
      placeId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required, contactInfoValidator()]),
      images: new FormControl([]),
      tempSlotDate: new FormControl([], [Validators.required]), // Only used to be able to store data in slotDates
      startTime: new FormControl('19:00'),
      setLength: new FormControl('00:30'),
      suppliesMixer: new FormControl(false),
      suppliesSpeakers: new FormControl(false),
      suppliesPa: new FormControl(false),
      budgetAll: new FormControl(null, [Validators.min(1), Validators.required]),
      budgetDJ: new FormControl(null, [Validators.required]),
      budgetLive: new FormControl(null, [Validators.required]),
      supplyStatus: new FormControl(null),
      formattedAddress: new FormControl(null),
    });

    if (isPlatformBrowser(this.platformId)) {
      const localStorageData = localStorage.getItem('new_gig_input');

      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        this.createGigForm.get('pageCategory').setValue(parsedData.pageCategory);
        this.createGigForm.get('gigType').setValue(parsedData.gigType);
        this.createGigForm.get('name').setValue(parsedData?.name);

        this.createGigForm.get('description').setValue(parsedData?.description);
        this.createGigForm.get('placeId').setValue(parsedData?.placeId);
        this.createGigForm.get('formattedAddress').setValue(parsedData?.formattedAddress);

        this.createGigForm.get('budgetAll').setValue(parsedData?.budgetAll);
        this.createGigForm.get('budgetDJ').setValue(parsedData?.budgetDJ);
        this.createGigForm.get('budgetLive').setValue(parsedData?.budgetLive);

        this.createGigForm.get('supplyStatus').setValue(parsedData.supplyStatus);
        this.createGigForm.get('suppliesMixer').setValue(parsedData.suppliesMixer);
        this.createGigForm.get('suppliesSpeakers').setValue(parsedData.suppliesSpeakers);
        this.createGigForm.get('suppliesPa').setValue(parsedData.suppliesPa);

        this.createGigForm.get('tempSlotDate').setValue(parsedData.tempSlotDate);
        this.createGigForm.get('startTime').setValue(parsedData.startTime);
        this.createGigForm.get('setLength').setValue(parsedData.setLength);
        this.createGigForm.get('supplyStatus').setValue(parsedData.supplyStatus);

        let images: ImageToBeUploaded[] = [];

        parsedData.b64images.forEach((i) => {
          const newImage: ImageToBeUploaded = {
            img: dataURLtoFile(i, 'fileName'),
            imgSrc: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(dataURLtoFile(i, 'fileName'))),
            safeCssUrl: this.sanitizer.bypassSecurityTrustUrl(
              `url(${window.URL.createObjectURL(dataURLtoFile(i, 'fileName'))})`,
            ),
            isOld: false,
          };
          images.push(newImage);
        });

        this.createGigForm.get('images').setValue(images);
      }
    }

    this.getTagsGQL
      .watch()
      .valueChanges.pipe(map(({ data }) => data.tags))
      .subscribe(async (ts) => {
        this.categoryTags = ts.filter((t) => t.group === 4);
      });

    this.getTemplatesGQL.fetch().subscribe((d) => {
      this.templates = d.data.getTemplates;
    });
  }

  templateSelected(template: GigTemplate) {
    this.createGigForm.get('name').setValue(template.name);
    this.createGigForm.get('description').setValue(template.details);
    this.createGigForm.get('gigType').setValue(template.type);

    const isDjTag = template.tags.find((t) => t.name.toLowerCase() === 'dj');
    const isArtistTag = template.tags.find((t) => t.name.toLowerCase() === 'artist');
    const isBandTag = template.tags.find((t) => t.name.toLowerCase() === 'band');
    const isLive = isArtistTag || isBandTag;
    if (isLive) {
      this.createGigForm.get('budgetLive').setValue(template.defaultPrice);
      if (!isDjTag) {
        this.createGigForm.get('pageCategory').setValue([PageCategory.Live]);
      }
    }
    if (isDjTag) {
      this.createGigForm.get('budgetDJ').setValue(template.defaultPrice);
      if (!isLive) {
        this.createGigForm.get('pageCategory').setValue([PageCategory.Dj]);
      }
    }

    this.createGigForm.get('budgetAll').setValue(template.defaultPrice);
  }

  public checkIfStepValid(stepNumber: number): boolean {
    if (stepNumber === this.CREATE_GIG_STEP_DATA[0].number) {
      return (
        this.createGigForm?.controls?.placeId?.valid &&
        this.createGigForm?.controls?.name?.valid &&
        this.createGigForm?.controls?.description?.valid
      );
    } else if (stepNumber === this.CREATE_GIG_STEP_DATA[1].number) {
      if (this.createGigForm?.value.pageCategory?.length > 1) {
        return (
          this.createGigForm?.controls?.tempSlotDate?.valid &&
          this.createGigForm?.controls?.startTime?.valid &&
          this.createGigForm?.controls?.setLength?.valid &&
          this.createGigForm?.controls?.budgetLive?.valid &&
          this.createGigForm?.controls?.budgetDJ?.valid
        );
      } else {
        return (
          this.createGigForm?.controls?.tempSlotDate?.valid &&
          this.createGigForm?.controls?.startTime?.valid &&
          this.createGigForm?.controls?.setLength?.valid &&
          this.createGigForm?.controls?.budgetAll?.valid
        );
      }
    } else {
      return false;
    }
  }

  public changeStep(step: number): void {
    if (step > 0) {
      if (this.activeStep === 1 && !this.checkIfStepValid(1)) {
        this.createGigForm.controls.placeId.markAsTouched();
        this.createGigForm.controls.name.markAsTouched();
        this.createGigForm.controls.description.markAsTouched();
        return;
      }
      if (this.activeStep === 2 && !this.checkIfStepValid(2)) {
        this.createGigForm.controls.tempSlotDate.markAsTouched();
        if (this.createGigForm.value.pageCategory.length > 1) {
          this.createGigForm.controls.budgetLive.markAsTouched();
          this.createGigForm.controls.budgetDJ.markAsTouched();
        } else {
          this.createGigForm.controls.budgetAll.markAsTouched();
        }
      } else {
        if (this.activeStep <= this.CREATE_GIG_STEP_DATA.length) this.activeStep += step;
      }
    } else {
      if (this.activeStep > 1) this.activeStep += step;
    }
  }

  public async postGig(): Promise<void> {
    let slotDateInputs: GigSlotInput[] = [];

    const artistTag = this.categoryTags.find((t2) => t2.name.toLowerCase() === 'artist');
    const bandTag = this.categoryTags.find((t3) => t3.name.toLowerCase() === 'band');
    const djTag = this.categoryTags.find((t1) => t1.name.toLowerCase() === 'dj');

    const selectedPageCategory = this.createGigForm.value.pageCategory;
    const slotDates = this.createGigForm.value.tempSlotDate;

    for (let i = 0; i < slotDates?.length; i++) {
      const date = slotDates[i];

      selectedPageCategory.forEach((category, index) => {
        let tags: number[] = [];
        let price: number;

        if (selectedPageCategory.length === 1) {
          price = parseInt(this.createGigForm.value.budgetAll);
        } else if (selectedPageCategory.length > 1) {
          price =
            category === PageCategory.Live
              ? parseInt(this.createGigForm.value.budgetLive)
              : parseInt(this.createGigForm.value.budgetDJ);
        }

        tags = selectedPageCategory[index] === PageCategory.Live ? [artistTag.id, bandTag.id] : [djTag.id];

        const start = new Date(timeStringToTimestamp(new Date(date), this.createGigForm.value.startTime));
        const [hours, minutes] = this.createGigForm.value.setLength.split(':').map(Number);

        const end = new Date(start.getTime());
        end.setHours(end.getHours() + hours);
        end.setMinutes(end.getMinutes() + minutes);

        slotDateInputs.push({
          tags,
          date: dateToApi(date),
          price,
          startTime: timeStringToTimestamp(new Date(date), this.createGigForm.value.startTime),
          endTime: end.toISOString(),
        });
      });
    }

    const newGigData: GigInput = {
      name: this.createGigForm.get('name').value,
      details: this.createGigForm.get('description').value,
      placeId: this.createGigForm.get('placeId').value,
      type: this.createGigForm.get('gigType').value,
      slots: slotDateInputs,
      deadline: GigDeadlineEnum.Day,
      supplies: this.getSelectedSupplies(),
      isRequestingSupplies: this.createGigForm.get('supplyStatus').value,
      images: (this.createGigForm.get('images').value as ImageToBeUploaded[])
        .filter((i) => !i.isOld)
        .map((i) => {
          return {
            image: i.img,
            cropData: {
              x1: -1,
              x2: -1,
              y1: -1,
              y2: -1,
            },
          };
        }),
    };

    if (!this.authService.isLoggedIn) {
      const b64images: string[] = [];
      for (const img of newGigData.images) {
        const b64 = await this.imageToBase64(img.image);
        b64images.push(b64);
      }
      delete newGigData.images;

      localStorage.setItem(
        'new_gig_input',
        JSON.stringify({
          ...this.createGigForm.value,
          b64images,
          createdAt: new Date().toISOString(),
        }),
      );
      localStorage.setItem('forceReturn', 'gigs/add');
      this.router.navigate(['/signup']);
      return;
    } else {
      this.isLoading = true;
      this.createGigGQL.mutate({ gig: newGigData }, { context: { useMultipart: true } }).subscribe(
        (d) => {
          if (!d.errors) {
            this.analyticsService.emitEvent(
              'post_gig_complete',
              { gig_name: newGigData.name, type: newGigData.type },
              { value: newGigData.slots.reduce((acc, v) => (acc += v.price), 0) * 0.1 },
            );
            this.isLoading = false;
            this.router.navigate(['gigs', d.data.createGig.id]);

            // TODO: Re-enable when we have good gratification looks
            // this.router.navigate(['gigs', d.data.createGig.id], { queryParams: { first: 'true' } });
            localStorage.removeItem('new_gig_input');
          }
        },
        (e) => {
          console.error(e);
          this.isLoading = false;
        },
      );
    }
  }

  private getSelectedSupplies(): SupplyTypes[] {
    const supplies: SupplyTypes[] = [];
    if (this.createGigForm.get('suppliesMixer').value) {
      supplies.push(SupplyTypes.Mixer);
    }
    if (this.createGigForm.get('suppliesSpeakers').value) {
      supplies.push(SupplyTypes.Speakers);
    }
    if (this.createGigForm.get('suppliesPa').value) {
      supplies.push(SupplyTypes.Pa);
    }
    return supplies;
  }

  private async imageToBase64(file: File): Promise<string> {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => res(reader.result as string);
    });
  }
}
