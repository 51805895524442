import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  Badge,
  FindOnePageGQL,
  FindOnePageQueryVariables,
  GetCurrentUserGQL,
  Page,
  PageLink,
  Review,
  User,
} from '../../../../generated/graphql';
import { getIdOrSlug } from '../../../../utils/pages';
import { getFilteredTags, PageCategoryTitle, TagGroupEnum } from '../../../helpers/pages';
import { scrollElementIntoViewWithOffset } from '../../../helpers/scroll';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';
import { TranslationsService } from '../../../services/translations.service';
import { ExtendedTag } from '../artist-detail-edit/components/artist-detail-tags/artist-detail-tags.component';

export interface ExtendedPageLink extends PageLink {
  isArtist?: boolean;
}
export interface ReviewsListFullInfo {
  reviews: Review[];
  totalReviews: number;
  reviewsSkip: number;
  hasNextPage: boolean;
  showableReviews: number;
}

@Component({
  selector: 'app-artist-detail',
  templateUrl: './artist-detail.component.html',
  styleUrls: ['./artist-detail.component.scss'],
})
export class ArtistDetailComponent implements OnInit {
  @ViewChild('overviewSection', { static: false }) overviewSection: ElementRef;
  @ViewChild('reviewsSection', { static: false }) reviewsSection: ElementRef;
  @ViewChild('faqSection', { static: false }) faqSection: ElementRef;

  @Input() isPreviewMode: boolean = false;
  @Input() pageId: string;

  public reviewsListFullInfo$: Observable<ReviewsListFullInfo>;
  public reviewsList$: Observable<Review[]>;
  public currentUser$: Observable<User>;
  public page$: Observable<Page>;

  public pageLoading: boolean = true;
  public isOwner: boolean = false;
  public isFastResponder: boolean;
  public currentUser: User;
  public pageSlug: string;

  public reviewsSkip: number = 0;
  public reviewsTake: number = 4;

  public PAGE_CATEGORY_TITLE = PageCategoryTitle;
  public PAGE_TAG_GROUP = TagGroupEnum;
  public PAGE_BADGES = Badge;

  public getFilteredTags = getFilteredTags;

  get currentLocale(): string {
    return this.translationsService.getCurrentLocale();
  }

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    private getCurrentUserGQL: GetCurrentUserGQL,
    private getPage: FindOnePageGQL,

    public translationsService: TranslationsService,
    public iconsService: IconsRegistryService,
    public viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.iconsService.registerIcons([IconSubsets.BADGES]);
  }

  public ngOnInit() {
    this.getCurrentUserGQL.fetch().subscribe((d) => {
      this.currentUser = d.data.me;
    });

    if (!this.isPreviewMode) {
      this.route.params.pipe(take(1)).subscribe((params) => {
        this.setPage(params.pageId);
      });
    } else {
      this.setPage(this.pageId);
    }
  }

  private setPage(paramId: string): void {
    const { pageId, slug } = getIdOrSlug(paramId);
    const searchOps: FindOnePageQueryVariables = { paging: { limit: this.reviewsTake, skip: this.reviewsSkip } };

    if (!isNaN(pageId)) {
      searchOps.pageId = pageId;
    } else {
      searchOps.slug = paramId;
    }

    this.page$ = this.getPage.fetch(searchOps, { fetchPolicy: 'network-only' }).pipe(
      map(({ data, loading }) => {
        this.pageLoading = loading;
        this.pageSlug = data.findOnePage.slug;
        this.isOwner = !!this.currentUser ? data.findOnePage.user.id === this.currentUser.id : false;
        this.isFastResponder = data.findOnePage?.badges.includes(Badge.FastResponder);

        data.findOnePage.tags.map((tag) => {
          const extendedTag = tag as ExtendedTag;
          if (this.currentLocale === 'sv-se') {
            extendedTag.translatedName = tag.translations?.sv || tag.name;
          } else {
            extendedTag.translatedName = tag.translations?.en || tag.name;
          }
          return extendedTag;
        });
        return data.findOnePage;
      }),
    );

    this.reviewsListFullInfo$ = this.getPage.watch(searchOps).valueChanges.pipe(
      map(({ data }) => {
        return {
          reviews: data.getPageReviews.reviews as Review[],
          totalReviews: data.getPageReviews.total,
          reviewsSkip: data.getPageReviews.skip,
          hasNextPage: data.getPageReviews.hasNextPage,
          showableReviews: data.getPageReviews.reviews.length,
        };
      }),
    );

    this.reviewsList$ = this.getPage
      .watch(searchOps)
      .valueChanges.pipe(map(({ data }) => data.getPageReviews.reviews as Review[]));
  }

  public checkIfDisplayBadges(page: Page): boolean {
    const badgeLength = page?.badges?.length;
    return !!badgeLength && !(badgeLength === 1 && page?.badges[0] === this.PAGE_BADGES.FastResponder);
  }

  public onSendRequestClick(): void {
    if (!this.isOwner) {
      this.router.navigate(['/artists', this.pageSlug || this.pageId, 'book']);
    }
  }

  public scrollToOverviewSection(): void {
    scrollElementIntoViewWithOffset(this.overviewSection.nativeElement);
  }

  public scrollToReviewsSection(): void {
    scrollElementIntoViewWithOffset(this.reviewsSection.nativeElement);
  }

  public scrollToFaqSection(): void {
    scrollElementIntoViewWithOffset(this.faqSection.nativeElement);
  }
}
