<div class="overview">
  <div fxLayout="row wrap" fxLayoutAlign="start space-between" fxLayout.lt-md="column" class="wrap">
    <ng-container *ngIf="page?.signedDates > 0">
      <div fxFlex="calc(50% - 24px)" class="overview--item" fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon svgIcon="verification"></mat-icon>
        <span fxLayout="column" fxLayoutGap="-4px">
          <span class="gray-2">Verified bookings</span>
          <span class="f-weight--600">{{ page.signedDates }}</span>
        </span>
      </div>
    </ng-container>

    <ng-container *ngTemplateOutlet="artistCategory"></ng-container>
    <ng-container *ngTemplateOutlet="verifiedBookings > 0 ? location : price"> </ng-container>
    <ng-container *ngTemplateOutlet="verifiedBookings > 0 ? price : location"> </ng-container>
  </div>
</div>

<ng-template #artistCategory>
  <div fxFlex="calc(50% - 24px)" fxLayoutAlign="start center" fxLayoutGap="16px" class="overview--item">
    <mat-icon svgIcon="note" class="note-icon"></mat-icon>
    <span fxLayout="column" fxLayoutGap="-4px">
      <ng-container>
        <span class="gray-2" i18n>Artist category</span>
        <span *ngIf="page?.category === PAGE_CATEGORY.Live" class="f-weight--600">{{ PAGE_CATEGORY_TITLE.LIVE }}</span>
        <span *ngIf="page?.category === PAGE_CATEGORY.Dj" class="f-weight--600">{{ PAGE_CATEGORY_TITLE.DJ }}</span>
        <span *ngIf="!page.category" class="f-weight--600">-</span>
      </ng-container>
    </span>
  </div>
</ng-template>

<ng-template #price>
  <div fxFlex="calc(50% - 24px)" fxLayoutAlign="start center" fxLayoutGap="16px" class="overview--item">
    <mat-icon svgIcon="price"></mat-icon>
    <span fxLayout="column" fxLayoutGap="-4px">
      <ng-container>
        <span class="gray-2" i18n>Price</span>
        <span *ngIf="page?.price" class="f-weight--600"> <span i18n>From</span> {{ page.price }} SEK</span>
        <span *ngIf="!page.price" class="f-weight--600">-</span>
      </ng-container>
    </span>
  </div>
</ng-template>

<ng-template #location>
  <div fxFlex="calc(50% - 24px)" fxLayoutAlign="start center" fxLayoutGap="16px" class="overview--item">
    <mat-icon svgIcon="location" class="location-icon"></mat-icon>
    <span fxLayout="column" fxLayoutGap="-4px">
      <ng-container>
        <span class="gray-2" i18n>Location</span>
        <span *ngIf="page.placeData?.formattedAddress" class="f-weight--600">
          {{ page.placeData.formattedAddress }}
        </span>
        <span *ngIf="!page.placeData?.formattedAddress" class="f-weight--600">-</span>
      </ng-container>
    </span>
  </div>
</ng-template>
