<div *ngIf="gig | async as gig; else loading">
  <app-gig-details-header [gig]="gig"></app-gig-details-header>
  <app-gig-details-overview [gig]="gig"></app-gig-details-overview>
  <app-gig-details-slots
    id="slots"
    [gig]="gig"
    [hasNextPage]="hasNextPage"
    [loading]="loading"
    (onFetchMore)="fetchMoreSlots()"></app-gig-details-slots>
</div>

<ng-template #loading>
  <div class="loading gigs-container" fxLayout="column">
    <!-- Desktop header -->
    <div fxHide fxShow.gt-md fxLayout="column">
      <ngx-skeleton-loader
        [theme]="{
          width: '10%',
          height: '20px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '12px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '50%',
          height: '30px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '6px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '45%',
          height: '10px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '12px'
        }"></ngx-skeleton-loader>
      <div fxLayout="row" fxLayoutGap="8px">
        <ngx-skeleton-loader
          fxFlex="64%"
          [theme]="{
            height: '600px',
            'border-radius': '2px',
            'background-color': '#fafafa',
            'margin-bottom': '32px',
            'margin-right': '6px'
          }"></ngx-skeleton-loader>
        <ngx-skeleton-loader
          fxFlex="32%"
          [theme]="{
            height: '400px',
            'border-radius': '2px',
            'background-color': '#fafafa',
            'margin-bottom': '32px'
          }"></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader
        [theme]="{
          width: '35%',
          height: '30px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '6px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '100%',
          height: '350px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '32px'
        }"></ngx-skeleton-loader>
    </div>

    <div fxHide fxShow.lt-lg fxLayout="column">
      <ngx-skeleton-loader
        [theme]="{
          width: '25%',
          height: '20px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '12px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '80%',
          height: '30px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '6px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '75%',
          height: '10px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '12px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: 'calc(100% + 40px)',
          height: '250px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '64px',
          'margin-right': '-20px',
          'margin-left': '-20px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '50%',
          height: '30px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '6px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '100%',
          height: '200px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '32px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          width: '100%',
          height: '350px',
          'border-radius': '2px',
          'background-color': '#fafafa',
          'margin-bottom': '32px'
        }"></ngx-skeleton-loader>
    </div>

    <ngx-skeleton-loader
      [theme]="{ width: '50%', height: '48px', 'border-radius': '2px', 'background-color': '#fafafa' }">
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{ width: '10%', height: '40px', 'border-radius': '2px', 'background-color': '#fafafa' }">
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{ width: '30%', height: '30px', 'border-radius': '2px', 'background-color': '#fafafa' }">
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{ width: '100%', height: '94px', 'border-radius': '2px', 'background-color': '#fafafa' }">
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{ width: '100%', height: '94px', 'border-radius': '2px', 'background-color': '#fafafa' }">
    </ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{ width: '100%', height: '94px', 'border-radius': '2px', 'background-color': '#fafafa' }">
    </ngx-skeleton-loader>
  </div>
</ng-template>
