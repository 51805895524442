import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../generated/graphql';
import { ExtendedChannelMessage } from '../../bookings/channelUtils';
import { apiToDate, LONG_DATE, timeSince } from '../../helpers';

@Component({
  selector: 'app-channel-message',
  templateUrl: './channel-message.component.html',
  styleUrls: ['./channel-message.component.scss'],
})
export class ChannelMessageComponent implements OnInit {
  @Input()
  message: ExtendedChannelMessage;

  @Input()
  currentUser: User;

  formattedDate: string;
  dateFormat = `${LONG_DATE} HH:mm`;


  constructor() {}

  ngOnInit() {
    this.formattedDate = timeSince(apiToDate(this.message.createdDate));

  }
}
