import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { ArtistsModule } from '../artists/artists.module';
import { BookingsModule } from '../bookings/bookings.module';
import { ArtistDashboardComponent } from '../components/dashboard/artist-dashboard/artist-dashboard.component';
import { ArtistProfileDashboardComponent } from '../components/dashboard/artist-profile-dashboard/artist-profile-dashboard.component';
import { CustomerDashboardComponent } from '../components/dashboard/customer-dashboard/customer-dashboard.component';
import { CustomerGigsDashboardItemComponent } from '../components/dashboard/customer-gigs-dashboard/customer-gigs-dashboard-item/customer-gigs-dashboard-item.component';
import { CustomerGigsDashboardComponent } from '../components/dashboard/customer-gigs-dashboard/customer-gigs-dashboard.component';
import { DashboardBookingDateItemComponent } from '../components/dashboard/dashboard-booking-dates/dashboard-booking-date-item/dashboard-booking-date-item.component';
import { DashboardBookingDatesComponent } from '../components/dashboard/dashboard-booking-dates/dashboard-booking-dates.component';
import { DashboardLinksComponent } from '../components/dashboard/dashboard-links/dashboard-links.component';
import { DashboardBookingItemComponent } from '../components/dashboard/dashboard-pending-bookings/dashboard-booking-item/dashboard-booking-item.component';
import { DashboardPendingBookingsComponent } from '../components/dashboard/dashboard-pending-bookings/dashboard-pending-bookings.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { ImageCardDashboardComponent } from '../components/dashboard/image-card-dashboard/image-card-dashboard.component';
import { NewsItemComponent } from '../components/news-item/news-item.component';
import { NewsComponent } from '../components/news/news.component';
import { GigsModule } from '../gigs/gigs.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardLinksComponent,
    NewsComponent,
    NewsItemComponent,
    CustomerDashboardComponent,
    ArtistDashboardComponent,
    CustomerGigsDashboardComponent,
    ImageCardDashboardComponent,
    DashboardBookingDatesComponent,
    DashboardBookingItemComponent,
    CustomerGigsDashboardItemComponent,
    ArtistProfileDashboardComponent,
    DashboardPendingBookingsComponent,
    DashboardBookingDateItemComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    FlexModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CloudinaryModule,
    SharedModule,
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatDividerModule,
    GigsModule,
    BookingsModule,
    SharedModule,
    ArtistsModule,
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
