<mat-sidenav-container [class.overflow-visible]="!overlayHidden">
  <mat-sidenav
    class="main"
    #sidenav
    role="navigation"
    fixedInViewport
    (openedStart)="overlayHidden = true"
    (closed)="overlayHidden = false">
    <app-sidenav [user]="user"></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content [class.hidden]="navbarStatus.hiddenRoute" [class.main]="!navbarStatus.transparentRoute">
    <app-navbar *ngIf="!navbarStatus.hiddenRoute" (sidenavToggle)="sidenav.toggle()" [user]="user"></app-navbar>
    <ng-container *ngIf="!auth.isLoggedIn || loginStatusChecked; else loading">
      <router-outlet></router-outlet>
    </ng-container>
    <app-footer *ngIf="showFooter" [user]="user"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-meta></app-meta>

<ng-template #loading>
  <div *ngIf="!loginStatusChecked" class="authorizing bg-gray-5" fxLayout fxLayoutAlign="center center">
    <div>
      <mat-spinner></mat-spinner>
      <p i18n class="mat-body gray-3">Logging in...</p>
    </div>
  </div>
</ng-template>
