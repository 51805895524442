<div class="app-card" fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <div
    class="app-header cursor-pointer"
    fxFlex="180px"
    fxFlex.lt-md="135px"
    fxFlex.lt-sm="180px"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-sm="flex-start"
    [routerLink]="['/gigs', date.booking.appliedTo.id]">
    <app-cloud-image
      *ngIf="date.booking.appliedTo.images && date.booking.appliedTo.images[0]"
      class="app-image m-r--15"
      [cover]="true"
      [routerLink]="'../' + date.booking.id"
      [altText]="date.booking.appliedTo.name + ' picture'"
      [backgroundPicture]="date.booking.appliedTo.images[0]"
      [maxWidth]="45">
    </app-cloud-image>

    <app-fallback-image
      class="app-image m-r--15"
      [borderRadius]="8"
      [textSize]="'5px'"
      *ngIf="!date.booking.appliedTo.images || date.booking.appliedTo.images.length === 0"
      [gig]="date.booking.appliedTo">
    </app-fallback-image>

    <div fxLayout="column" fxLayoutGap.lt-sm="7px">
      <a class="app-title">{{ date.booking.appliedTo.name }}</a>
      <a
        *ngIf="date.booking.page"
        fxHide="true"
        fxHide.lt-sm="false"
        class="text-input-color"
        [routerLink]="['/artists', date.booking.page.slug]">
        {{ date.booking.page.name }}
      </a>
      <time
        fxHide="true"
        fxHide.lt-sm="false"
        class="text-input-color"
        [title]="date.date | apiDate | date: dateFormat"
        [dateTime]="date.date">
        {{ date.date | apiDate | date: dateFormat }}
      </time>
    </div>
  </div>

  <time
    fxFlex="90px"
    fxHide.lt-sm="true"
    class="text-input-color"
    [title]="date.date | apiDate | date: dateFormat"
    [dateTime]="date.date">
    {{ date.date | apiDate | date: dateFormat }}
  </time>

  <p fxFlex="112px" fxFlex.lt-md="80px" fxHide.lt-sm="true" class="text-right">
    {{ date.price | number: '1.0-0':'fr' }} {{ date.priceCurrency }}
  </p>

  <div
    fxFlex="140px"
    fxLayoutAlign="start center"
    fxFlex.lt-md="100px"
    fxHide.lt-sm="true"
    class="profile cursor-pointer"
    [routerLink]="['/artists', date.booking.page.slug]">
    <app-avatar
      fxHide.lt-md="true"
      class="m-r--7 d--block"
      [maxWidth]="32"
      [mini]="true"
      [profilePicture]="date.booking.page?.profilePicture"
      [user]="date.booking.page?.user"></app-avatar>

    <p *ngIf="date.booking.page">{{ date.booking.page.name }}</p>
  </div>

  <div
    fxFlex="100px"
    fxFlex.lt-md="140px"
    fxLayout.lt-md="row-reverse"
    fxLayoutAlign.lt-md="space-around center"
    fxLayout.lt-sm="column"
    fxLayoutAlign.lt-sm="start space-around">
    <div class="actions-dropdown text-right" fxHide="true" fxHide.lt-md="false">
      <button mat-icon-button [matMenuTriggerFor]="appActionsMenu">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #appActionsMenu="matMenu">
        <button
          mat-menu-item
          [disabled]="appStatus !== APPLICATION_STATUS.FINALIZING"
          [routerLink]="['/bookings', date.booking.id]">
          <span i18n>Go to request</span>
        </button>
        <button mat-menu-item [disabled]="appStatus !== APPLICATION_STATUS.PENDING" (click)="clickCancelApplication()">
          <span i18n>Cancell Application</span>
        </button>
      </mat-menu>
    </div>
    <div fxFlex.lt-md="100px" fxFlex.lt-sm="100%" class="status-wrap">
      <app-gig-status class="d--i-block" [applicationStatus]="appStatus"></app-gig-status>
    </div>
  </div>

  <div fxFlex="120px" fxLayout.lt-sm="column" fxHide.lt-md="true">
    <button
      i18n
      class="unstyled-button"
      (click)="clickCancelApplication()"
      *ngIf="appStatus === APPLICATION_STATUS.PENDING">
      Cancel
    </button>

    <button
      i18n
      class="unstyled-button"
      [routerLink]="['/bookings', date.booking.id]"
      *ngIf="appStatus === APPLICATION_STATUS.FINALIZING">
      Go to request
    </button>
  </div>
</div>
