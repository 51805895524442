import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Booking, EquipmentStatus, UpdateBookingGQL } from '../../../../../generated/graphql';

@Component({
  selector: 'app-edit-supplies-price-modal',
  templateUrl: './edit-supplies-price-modal.component.html',
  styleUrls: ['./edit-supplies-price-modal.component.scss'],
})
export class EditSuppliesPriceModalComponent implements OnInit {
  public supplyPriceForm: FormGroup;
  public booking: Booking;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditSuppliesPriceModalComponent>,
    private updateBookingGQL: UpdateBookingGQL,
    private snackbar: MatSnackBar,
  ) {}

  public ngOnInit(): void {
    this.booking = this.data.booking;
    this.supplyPriceForm = new FormGroup({
      price: new FormControl(this.data.booking?.supplyPrice, Validators.required),
    });
  }

  public saveSupplyPrice(): void {
    if (this.supplyPriceForm?.value?.price > 1 && this.supplyPriceForm?.value?.price !== this.booking.supplyPrice) {
      this.updateBookingGQL
        .mutate({
          bookingId: this.booking.id,
          booking: {
            supplyStatus: EquipmentStatus.Supplied,
            supplyPrice: this.supplyPriceForm.value.price,
          },
        })
        .subscribe(
          (result) => {
            this.snackbar.open($localize`Equipment status was successfully updated!`, undefined, { duration: 3000 });
            this.dialogRef.close();
          },
          (error) => {
            this.snackbar.open($localize`Could not save changes.`, $localize`Try again`, { duration: 3000 });
            console.error(error);
          },
        );
    }
  }
}
