import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PhoneNumberInputComponent,
      multi: true,
    },
  ],
})
export class PhoneNumberInputComponent implements ControlValueAccessor {
  @Input() placeholder: string = $localize`Phone number`;
  @Input() disabled: boolean = false;
  @Input() phonePrefix: string;

  @Input() label: string = '';
  @Input() isLightLabel: false;
  @Input() labelTooltip: string = '';

  public phoneNumber: string;

  set value(number: string) {
    this.phoneNumber = number;
    this.onChange(number);
    this.onTouch(number);
  }

  get value(): string {
    return this.phoneNumber;
  }

  public writeValue(number: string): void {
    this.value = number;
  }

  private onChange: any = () => {};
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  private onTouch: any = () => {};
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public keyPressNumbers(event: KeyboardEvent): void {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
}
