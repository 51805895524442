<form
  [formGroup]="supplyFormGroup"
  (submit)="saveSupplayStatus()"
  class="equipment-request expansion-panel"
  [ngClass]="{ opened: isFormExpanded, supplied: booking.supplyStatus === EQUIPMENT_STATUS.Supplied }">
  <div class="expansion-panel--header">
    <div>
      <div fxLayoutAlign="space-between">
        <div fxLayoutAlign="start start" class="equipment-request--title m-b--12">
          <mat-icon
            [svgIcon]="booking.supplyStatus === EQUIPMENT_STATUS.Supplied ? 'request-light' : 'request'"
            [ngClass]="{ supplied: booking.supplyStatus === EQUIPMENT_STATUS.Supplied }"></mat-icon>
          <div>
            <h2 class="subtitle-1 f-size--15 f-weight--600" i18n>Request from booker</h2>
            <p class="f-size--15" i18n>Rent your equipment for a fee</p>
          </div>
        </div>
        <div>
          <div fxLayout="column" fxLayoutGap="8px" class="equipment-request--status">
            <span
              *ngIf="booking.supplyStatus === EQUIPMENT_STATUS.Requested"
              class="status-label bg-pastel-yellow"
              i18n>
              Pending
            </span>
            <span *ngIf="booking.supplyStatus === EQUIPMENT_STATUS.Supplied" class="status-label bg-pastel-green" i18n>
              Accepted
            </span>
          </div>
        </div></div
      >

      <p *ngIf="amIBooker && booking.supplyStatus === EQUIPMENT_STATUS.Supplied" class="m-b--16" i18n
        >Price set at
        <span class="f-size--15 f-weight--600">{{ booking.supplyPrice }} {{ booking.dates[0].priceCurrency }}</span>
      </p>
    </div>

    <button
      *ngIf="!amIBooker && booking.supplyStatus !== EQUIPMENT_STATUS.Supplied"
      type="button"
      [disabled]="!canIEditBooking"
      (click)="openSupplyPriceModal()"
      class="gig-button black m-left--auto"
      i18n
      >Accept</button
    >
  </div>

  <div
    *ngIf="!amIBooker && booking.supplyStatus === EQUIPMENT_STATUS.Supplied"
    class="expansion-panel--content"
    [@expansion]>
    <mat-divider></mat-divider>

    <div fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <p fxLayoutAlign="start center" fxLayoutGap="4px">
          <span class="f-size--15 gray-13" i18n>Rent price</span>
          <mat-icon svgIcon="info-fill" matTooltip="Your price" class="info-icon"></mat-icon>
        </p>

        <span class="f-weight--600 f-size--16">
          {{ booking?.supplyPrice | priceSpaceFormat }} {{ booking.dates[0]?.priceCurrency }}
        </span>
      </div>

      <div fxLayoutAlign="end start" fxLayoutGap="8px">
        <button type="button" *ngIf="canIEditBooking" (click)="declineSupplyRequest()" class="gig-button action" i18n>
          <mat-icon svgIcon="trash-bin-2"></mat-icon>
        </button>

        <button type="button" *ngIf="canIEditBooking" (click)="openSupplyPriceModal()" class="gig-button action" i18n>
          <mat-icon svgIcon="edit"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</form>
