<main fxLayout.gt-md="row" fxLayout="column-reverse" fxLayoutAlign="center center" class="container">
  <div fxFlex.gt-md="40%" class="signup">
    <mat-card class="fade-in">
      <mat-card-title class="signup-title" i18n> Create an account </mat-card-title>
      <mat-card-content>
        <form fxLayout="column" class="signup-form" [formGroup]="loginForm" (submit)="onSubmit()">
          <mat-form-field>
            <input matInput id="firstName" placeholder="First name" formControlName="firstName" i18n-placeholder />
            <mat-error *ngIf="firstName.hasError('required')" i18n> First name is required </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput id="lastName" placeholder="Last name" formControlName="lastName" i18n-placeholder />
            <mat-error *ngIf="lastName.hasError('required')" i18n> Last name is required </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput id="username" placeholder="Email" formControlName="username" i18n-placeholder />
            <mat-error *ngIf="username.hasError('email')" i18n> Please enter a valid email address </mat-error>
            <mat-error *ngIf="username.hasError('required')" i18n> Email is required </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              id="password"
              placeholder="Password"
              type="password"
              formControlName="password"
              i18n-placeholder />
            <mat-error *ngIf="password.hasError('required')" i18n> Password is required </mat-error>
            <mat-error *ngIf="password.hasError('minlength')" i18n>
              Password needs to be atleast 6 characters
            </mat-error>
          </mat-form-field>
          <button type="submit" mat-flat-button color="primary" [disabled]="loading" i18n>Sign up</button>
          <mat-error *ngIf="apiError" class="submit-error">{{ apiError }}</mat-error>
        </form>
        <div fxLayout="column" class="signup-social">
          <p class="gray-3 mat-caption" i18n>or sign up with a social account</p>
          <button (click)="onSocialSignup('google-oauth2')" mat-stroked-button [disabled]="loading" i18n
            ><img class="signup-social-icon" src="assets/icons/google.png" width="20" height="20" />Sign up with
            Google</button
          >
          <button (click)="onSocialSignup('facebook')" mat-stroked-button [disabled]="loading" i18n
            ><img class="signup-social-icon" src="assets/icons/social-media/facebook.svg" />Sign up with
            Facebook</button
          >
        </div>
      </mat-card-content>
      <mat-card-actions class="signup-links">
        <p class="mat-body-2 gray-2" i18n> Already have an account? <a [routerLink]="['/login']">Log in here</a> </p>
      </mat-card-actions>
    </mat-card>
  </div>
  <div
    fxFlex.gt-md="60%"
    fxFlexAlign.gt-md="start"
    fxLayout.gt-md="column"
    fxLayoutAlign.gt-md="center"
    class="welcome">
    <h2 class="mat-display-3 gray-1" i18n>Welcome to Gigital</h2>
    <h3 class="mat-display-1 gray-2" i18n>Sign up to access all features</h3>
  </div>
</main>
