import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en-GB';
import localeSv from '@angular/common/locales/sv';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CloudinaryConfiguration, CloudinaryModule } from '@cloudinary/angular-5.x';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { Cloudinary } from 'cloudinary-core';
import { IgxGridModule } from 'igniteui-angular';
import { IntercomModule } from 'ng-intercom';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PrebootModule } from 'preboot';
import { environment } from '../environments/environment';
import { GigitalErrorHandler, initLogging } from '../logging';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArtistsModule } from './artists/artists.module';
import { EditMediaModalComponent } from './artists/components/edit-media-modal/edit-media-modal.component';
import { ImageCropperComponent } from './artists/components/image-cropper/image-cropper.component';
import { ReviewReadModalComponent } from './artists/components/review-read-modal/review-read-modal.component';
import { TagsModalComponent } from './artists/components/tags-modal/tags-modal.component';
import { UploadImageModalComponent } from './artists/pages/artist-detail-edit/components/upload-image-modal/upload-image-modal.component';
import { AuthorizedComponent } from './components/authorized.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/footer.component';
import { EventFormComponent } from './components/home/event-form/event-form.component';
import { HomeArtistsComponent } from './components/home/home-artists/home-artists.component';
import { MetaComponent } from './components/meta/meta.component';
import { MissingUserInfoComponent } from './components/missing-user-info/missing-user-info.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { GraphQLModule } from './graphql.module';
import { GigitalDateAdapter } from './helpers/dates';
import { NotificationsModule } from './notifications/notifications.module';
import { CancellationPolicyComponent } from './pages/cancellation-policy/cancellation-policy.component';
import { CompanyComponent } from './pages/company/company.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { ForArtistsComponent } from './pages/for-artists/for-artists.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeLandingHeaderComponent } from './pages/home-landing/home-landing-header/home-landing-header.component';
import { HomeLandingComponent } from './pages/home-landing/home-landing.component';
import { HomeComponent } from './pages/home/home.component';
import { HowItWorksWorkComponent } from './pages/how-it-works/how-it-works.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HarrysComponent } from './pages/partners/harrys/harrys.component';
import { NordicChoiceComponent } from './pages/partners/nordic-choice/nordic-choice.component';
import { PhilsBurgerComponent } from './pages/partners/phils-burger/phils-burger.component';
import { PricingCalculatorComponent } from './pages/pricing/pricing-calculator/pricing-calculator.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SignupComponent } from './pages/signup/signup.component';
import { TosComponent } from './pages/tos/tos.component';
import { LeaveReviewModalComponent } from './reviews/leave-review-modal/leave-review-modal.component';
import { SharedModule } from './shared/shared.module';

initLogging();

registerLocaleData(localeSv, 'sv-se');
registerLocaleData(localeEn, 'en');

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.DOMAIN,
  },
  position: 'bottom',
  theme: 'block',
  type: 'info',
  content: {
    message: $localize`By using this website, you agree to our`,
    dismiss: 'Ok',
    deny: '',
    link: $localize`cookie policy.`,
    href: 'http://help.gigital.se/en/articles/3315741-terms-of-use',
  },
  autoAttach: false,
};

@NgModule({
  declarations: [
    AppComponent,
    AuthorizedComponent,
    LoginComponent,
    SignupComponent,
    NavbarComponent,
    ForgotPasswordComponent,
    FooterComponent,
    SidenavComponent,
    CancellationPolicyComponent,
    PrivacyComponent,
    PricingComponent,
    PricingCalculatorComponent,
    TosComponent,
    CustomersComponent,
    MetaComponent,
    NotFoundComponent,
    HomeComponent,
    HomeLandingComponent,
    HomeArtistsComponent,
    HomeLandingHeaderComponent,
    FaqComponent,
    EventFormComponent,
    MissingUserInfoComponent,
    EditMediaModalComponent,
    TagsModalComponent,
    UploadImageModalComponent,
    ImageCropperComponent,
    // Move to artists module
    ReviewReadModalComponent,
    HowItWorksWorkComponent,
    ForArtistsComponent,
    CompanyComponent,
    NordicChoiceComponent,
    PhilsBurgerComponent,
    HarrysComponent,
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    FlexLayoutModule.withConfig({ ssrObserveBreakpoints: ['lt-md', 'gt-md', 'xs'] }),
    BrowserModule.withServerTransition({ appId: 'gigital' }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDzA2Hr1EKTdDk_wszG-2w-rTznkiThleo',
      libraries: ['places'],
    }),
    IntercomModule.forRoot({
      appId: environment.INTERCOM_APP_ID,
      updateOnRouterChange: true,
    }),
    MatGoogleMapsAutocompleteModule,
    PrebootModule.withConfig({ appRoot: 'app-root', replay: false }), // https://github.com/angular/preboot/issues/75
    CloudinaryModule.forRoot({ Cloudinary }, { cloud_name: 'hthruuqgk' } as CloudinaryConfiguration),
    TransferHttpCacheModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    BrowserTransferStateModule,
    MatToolbarModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatInputModule,
    MatMenuModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatCardModule,
    NotificationsModule,
    MatSidenavModule,
    MatDialogModule,
    MatButtonModule,
    SharedModule,
    MatGoogleMapsAutocompleteModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatRadioModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    DashboardModule,
    MatTabsModule,
    MatExpansionModule,
    MatChipsModule,
    MatSnackBarModule,
    ImageCropperModule,
    ArtistsModule,
    OverlayModule,
    IgxGridModule,
  ],
  exports: [],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GigitalErrorHandler,
    },
    { provide: DateAdapter, useClass: GigitalDateAdapter },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MissingUserInfoComponent,
    EditMediaModalComponent,
    TagsModalComponent,
    UploadImageModalComponent,
    // TODO: This is fucked, for some reason these will not be rendered unless in app module.
    // Should work in ng 10 as entry components are not needed in ivy
    // Move to bookings module in ng 10
    LeaveReviewModalComponent,
    // Move to artists module
    ReviewReadModalComponent,
  ],
})
export class AppModule {}
