import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  GetGigGQL,
  Gig,
  GigInput,
  GigType,
  Image,
  ImageInput,
  SupplyTypes,
  UpdateGigGQL,
} from '../../../../generated/graphql';
import { contactInfoValidator } from '../../../../utils/contactInfo';
import { ImageToBeUploaded, isImageToBeUploaded } from '../../../models/ImageToBeUploaded';
import { TranslatedEnum, TranslationsService } from '../../../services/translations.service';
import PlaceResult = google.maps.places.PlaceResult;

interface DeletableImage extends Image {
  delete: boolean;
}

@Component({
  selector: 'app-edit-gig',
  templateUrl: './edit-gig.component.html',
  styleUrls: ['./edit-gig.component.scss'],
})
export class EditGigComponent implements OnInit {
  @ViewChild('placeIdInput', { read: FormControlDirective, static: false })
  placeIdInput: FormControlDirective;

  @ViewChild('placeIdInput', { static: false })
  placeIdHtml: ElementRef;
  mixer: AbstractControl;
  pa: AbstractControl;
  speakers: AbstractControl;
  none: AbstractControl;

  get placeId(): AbstractControl {
    return this.editGroup.get('placeId');
  }

  constructor(
    private getGig: GetGigGQL,
    private route: ActivatedRoute,
    private translations: TranslationsService,
    private editGig: UpdateGigGQL,
    private router: Router,
  ) {}

  public gig: Observable<Gig>;

  public loading = false;

  public editGroup = new FormGroup({
    id: new FormControl(),
    images: new FormControl([]),
    imagesToBeUploaded: new FormControl([]),
    name: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.minLength(3)]),
    description: new FormControl('', [contactInfoValidator()]),
    type: new FormControl(),
    placeId: new FormControl('', [Validators.required]),

    suppliesMixer: new FormControl(false),
    suppliesSpeakers: new FormControl(false),
    suppliesPa: new FormControl(false),
    suppliesNone: new FormControl(true),
    isRequestingSupplies: new FormControl(false),
  });

  get description(): AbstractControl {
    return this.editGroup.get('description');
  }

  // just a static, cached, reference
  public images: Image[];

  public types: TranslatedEnum<GigType>[];

  private ogImages: Image[];

  ngOnInit(): void {
    this.types = this.translations.getGigTypesList();

    const gigId = parseInt(this.route.snapshot.params.gigId);

    const gigsQuery = this.getGig.watch({
      gigId,
      paging: { limit: 10, skip: 0 },
    });

    this.gig = gigsQuery.valueChanges.pipe(
      map((d) => {
        return d.data.gigById.gig;
      }),
      tap((gig) => {
        if (!gig.isOwner) {
          this.navigateToGig();
          return;
        }
        this.buildFormGroup(gig);
        this.ogImages = gig.images;
        setTimeout(() => {
          this.placeIdInput.control.setValue(gig.placeId, { emitModelToViewChange: false });
          (this.placeIdHtml.nativeElement as HTMLInputElement).value = gig.placeData.formattedAddress;
        }, 0);
      }),
    );
  }

  private buildFormGroup(gig: Gig) {
    this.editGroup.get('images').valueChanges.subscribe((v) => {
      this.images = v
        .filter((v) => !v.delete)
        .sort((a, b) => {
          if (!!a && !!b) {
            return 0;
          }
          if (!!a && !b) {
            return -1;
          }
          return 1;
        });
    });

    this.editGroup.get('images').setValue(
      gig.images.map((v) => {
        return { ...v, delete: false };
      }),
    );
    this.editGroup.get('id').setValue(gig.id);
    this.editGroup.get('name').setValue(gig.name);
    this.editGroup.get('description').setValue(gig.details);
    this.editGroup.get('placeId').setValue(gig.placeData.placeId);
    this.editGroup.get('type').setValue(this.translations.gigType(gig.type));
    this.editGroup.get('isRequestingSupplies').setValue(gig.isRequestingSupplies);

    this.mixer = this.editGroup.get('suppliesMixer');
    this.pa = this.editGroup.get('suppliesPa');
    this.speakers = this.editGroup.get('suppliesSpeakers');
    this.none = this.editGroup.get('suppliesNone');

    this.mixer.setValue(gig.supplies.includes(SupplyTypes.Mixer));
    this.pa.setValue(gig.supplies.includes(SupplyTypes.Pa));
    this.speakers.setValue(gig.supplies.includes(SupplyTypes.Speakers));
    this.none.setValue(!this.mixer.value && !this.pa.value && !this.speakers.value);

    this.editGroup.get('type').setValue(this.translations.gigType(gig.type));

    this.mixer.valueChanges.subscribe((v) => {
      this.toggleNone();
    });
    this.pa.valueChanges.subscribe((v) => {
      this.toggleNone();
    });
    this.speakers.valueChanges.subscribe((v) => {
      this.toggleNone();
    });

    this.none.valueChanges.subscribe((v) => {
      if (v) {
        this.none.disable({ emitEvent: false, onlySelf: true });
        this.mixer.setValue(false, { emitEvent: false, onlySelf: true });
        this.pa.setValue(false, { emitEvent: false, onlySelf: true });
        this.speakers.setValue(false, { emitEvent: false, onlySelf: true });
      } else {
        this.none.enable({ emitEvent: false, onlySelf: true });
      }
    });

    this.editGroup.updateValueAndValidity();
  }

  toggleNone() {
    this.none.setValue(!(this.mixer.value || this.pa.value || this.speakers.value));
  }

  getSelectedSupplies() {
    const supplies: SupplyTypes[] = [];
    if (this.mixer.value) {
      supplies.push(SupplyTypes.Mixer);
    }
    if (this.speakers.value) {
      supplies.push(SupplyTypes.Speakers);
    }
    if (this.pa.value) {
      supplies.push(SupplyTypes.Pa);
    }

    return supplies;
  }

  imageRemoved(image: ImageToBeUploaded | DeletableImage) {
    if (isImageToBeUploaded(image)) {
      const toBeUploaded = this.editGroup.get('imagesToBeUploaded').value;
      const index = toBeUploaded.findIndex((i) => i === image);
      toBeUploaded[index] = undefined;
      this.editGroup.get('imagesToBeUploaded').setValue(toBeUploaded);
    } else {
      var images = this.editGroup.get('images').value as DeletableImage[];
      const index = images.findIndex((i) => i === image);
      images[index].delete = true;
      this.editGroup.get('images').setValue(images);
    }
  }

  imageSelected(image: ImageToBeUploaded) {
    const toBeUploaded = this.editGroup.get('imagesToBeUploaded').value;
    this.editGroup.get('imagesToBeUploaded').setValue([...toBeUploaded, image]);
  }

  selectItem(item) {
    this.editGroup.get('type').setValue(item);
  }

  onAutocompleteSelected(result: PlaceResult): void {
    this.placeIdInput.control.setValue(result.place_id, { emitModelToViewChange: false });
  }

  updateGig() {
    if (this.editGroup.invalid) {
      return;
    }

    this.loading = true;

    const imagesToDelete = (this.editGroup.get('images').value as DeletableImage[]).filter((gi) => gi.delete);
    const uploadInput = (this.editGroup.get('imagesToBeUploaded').value as ImageToBeUploaded[]).map((itb) => {
      return {
        image: itb.img,
        cropData: {
          x1: -1,
          x2: -1,
          y1: -1,
          y2: -1,
        },
      };
    });
    let imageInput: ImageInput[] = imagesToDelete.map((itd) => {
      return { id: itd.id, delete: true };
    });
    imageInput = [...imageInput, ...uploadInput];
    const input: GigInput = {
      id: this.editGroup.get('id').value,
      name: this.editGroup.get('name').value,
      details: this.editGroup.get('description').value,
      placeId: this.editGroup.get('placeId').value,
      type: this.editGroup.get('type').value.type,
      supplies: this.getSelectedSupplies(),
      isRequestingSupplies: this.editGroup.get('isRequestingSupplies').value,
    };

    if (imageInput.length > 0) {
      input.images = imageInput;
    }

    this.editGig.mutate({ gig: input }, { context: { useMultipart: true } }).subscribe((d) => {
      this.loading = false;
      this.navigateToGig();
    });
  }

  private navigateToGig() {
    this.router.navigate(['/gigs', parseInt(this.route.snapshot.params.gigId)]);
  }
}
