import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CountriesEnum,
  FaqInput,
  GetPageFaqTemplatesGQL,
  Page,
  PageFaqAnswer,
  PageFaqQuestionTemplate,
  UpdatePageFaqGQL,
} from '../../../../../../generated/graphql';
import { ArtistProgressStep, ProgressStepNumber } from '../../../../../models/ArtistProgress';
import { IconsRegistryService, IconSubsets } from '../../../../../services/icons-registry.service';
import { TranslationsService } from '../../../../../services/translations.service';
import { InfoBarType } from '../../../../../shared/components/info-bar/info-bar.component';

@Component({
  selector: 'app-artist-detail-faq',
  templateUrl: './artist-detail-faq.component.html',
  styleUrls: ['./artist-detail-faq.component.scss'],
})
export class ArtistDetailFaqComponent implements OnInit {
  @Output() goToStep$: EventEmitter<number> = new EventEmitter<number>();
  @Output() setPageProgress$: EventEmitter<any> = new EventEmitter<any>();

  @Input() pageProgressStep: ArtistProgressStep;
  @Input() page: Page;

  public pageFaqTemplates$: Observable<PageFaqQuestionTemplate[]>;

  public isLoading: boolean = false;

  public PROGRESS_STEP_NUMBER = ProgressStepNumber;
  public INFO_BAR_TYPE = InfoBarType;

  public get currentLocation() {
    return this.translationsService.getCurrentLocale();
  }

  public constructor(
    private getPageFaqTemplatesGQL: GetPageFaqTemplatesGQL,
    public updatePageFaqGQL: UpdatePageFaqGQL,

    private translationsService: TranslationsService,
    private iconsService: IconsRegistryService,
    private renderer: Renderer2,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public ngOnInit(): void {
    this.pageFaqTemplates$ = this.getPageFaqTemplatesGQL.fetch({ paging: { limit: 50, skip: 0 } }).pipe(
      map(({ data }) => data.getPageFaqTemplates.templates),
      map((faqTemplates) => {
        faqTemplates.forEach((template) => {
          template.questions = template.questions.filter((question) => {
            if (this.currentLocation === 'sv-se') {
              return question.lang === CountriesEnum.Sweden;
            } else if (this.currentLocation === 'en-US' || this.currentLocation === 'en-x-source') {
              return question.lang === CountriesEnum.Global;
            }
          });
        });
        return faqTemplates.filter((template) => template.questions.length > 0);
      }),
    );
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
  }

  public isAnswerExist(questionTemplate: PageFaqQuestionTemplate): boolean {
    if (this.page.faq?.length > 0) {
      return !!(this.page.faq.filter((a) => a.question === questionTemplate.questions[0].question).length > 0);
    }
    return false;
  }

  public updatePageFaqAnswer(pageFaqAnswer: PageFaqAnswer): void {
    if (this.page.faq?.length > 0) {
      const index = this.page.faq.findIndex((answer) => answer.question === pageFaqAnswer.question);
      if (index > -1) {
        this.page.faq[index] = pageFaqAnswer;
      } else this.page.faq.push(pageFaqAnswer);
    } else {
      this.page.faq = [];
      this.page.faq.push(pageFaqAnswer);
    }

    this.updatePageFaqAnswers();
  }

  public removePageFaqAnswer(pageFaqAnswer: PageFaqAnswer): void {
    const pageFaqAnswerIndex = this.page.faq.findIndex((answer) => answer.answer === pageFaqAnswer.answer);
    this.page.faq.splice(pageFaqAnswerIndex, 1);
    this.updatePageFaqAnswers();
  }

  private updatePageFaqAnswers(): void {
    this.page.faq.forEach((answer) => delete answer['__typename']);
    this.isLoading = true;
    this.updatePageFaqGQL
      .mutate({
        pageId: this.page.id,
        faq: this.page.faq as FaqInput[],
      })
      .subscribe(({ data }) => {
        this.page.faq = data.updatePageFaq.faq;
        this.isLoading = false;
        this.setPageProgress$.next();
      });
  }
}
