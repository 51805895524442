<div class="gig-card" fxLayout="row" fxFlex="100%">
  <div
    class="gig-content"
    fxFlex="100%"
    fxLayoutGap.lt-md="10px"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-sm="space-between start">
    <div
      class="gig-header cursor-pointer"
      fxFlex="180px"
      fxLayout="row"
      fxLayoutGap="15px"
      fxLayoutGap.lt-sm="10px"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-sm="start start">
      <app-cloud-image
        *ngIf="gig.images[0]"
        class="gig-image"
        [cover]="true"
        [routerLink]="'../' + gig.id"
        [altText]="gig.name + ' picture'"
        [backgroundPicture]="gig.images[0]"
        [maxWidth]="45">
      </app-cloud-image>

      <app-fallback-image
        [borderRadius]="8"
        [textSize]="'5px'"
        class="gig-image"
        *ngIf="!gig.images || gig.images.length === 0"
        [gig]="gig">
      </app-fallback-image>

      <div fxLayout="column" fxLayoutGap="10px">
        <a class="gig-title" [routerLink]="'../' + gig.id">{{ gig.name }}</a>

        <p class="slots-small" fxFlex [fxHide]="true" [fxHide.lt-sm]="false">
          {{ slotsBooked }}/{{ gig.slots.length }} slots booked
        </p>

        <time
          fxFlex
          [fxHide]="true"
          [fxHide.lt-sm]="false"
          class="mat-body-2 gray-2 time-small"
          [title]="firstDate | date: firstDateFormat"
          [dateTime]="firstDate">
          {{ firstDate | date: firstDateFormat }}
          <ng-container *ngIf="doDatesDiffer"> - {{ lastDate | date: dateFormat }} </ng-container>
        </time>
      </div>
    </div>

    <time
      fxFlex="130px"
      [fxHide.lt-sm]="true"
      class="mat-body-2 gray-2 time"
      [title]="firstDate | date: firstDateFormat"
      [dateTime]="firstDate">
      {{ firstDate | date: firstDateFormat }}
      <ng-container *ngIf="doDatesDiffer"> - {{ lastDate | date: dateFormat }} </ng-container>
    </time>

    <p fxFlex="112px" fxFlex.lt-md="80px" [fxHide.lt-sm]="true" class="text-right">
      {{ totalPrice | number: '1.0-0':'fr' }} SEK
    </p>

    <p fxFlex="95px" fxFlex.lt-md="70px" [fxHide.lt-sm]="true" class="slots">
      {{ slotsBooked }}/{{ gig.slots.length }}
    </p>

    <div class="status" fxFlex="100px" fxLayout.lt-sm="row" fxLayoutAlign.lt-sm="flex-end">
      <app-gig-status [gigStatus]="gigStatus"></app-gig-status>
    </div>
  </div>
</div>
