<div *ngIf="iList" [fxLayout]="split ? 'row' : 'column'" class="editorial-list">
  <div [fxFlex]="split ? '50%' : '100%'">
    <div *ngIf="gigType" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="header">
      <app-plectrum
        [plectrumId]="meta.plectrumId"
        [size]="48"
        [iconSize]="20"
        [background]="meta.color"
        [icon]="meta.icon"
      ></app-plectrum>
      <h3 class="label {{ meta.color }}">{{ translations.gigType(this.gigType).translation }}</h3>
    </div>
    <p *ngIf="showDescription" class="mat-body gray-2 editorial-list-description">{{ iList.description }}</p>
  </div>
  <div [fxFlex]="split ? '50%' : '100%'" fxLayout="column">
    <h3 *ngIf="showTitle" class="gray-1 h6">{{ iList.name }}</h3>
    <div fxLayout="row wrap" fxLayoutGap="16px grid" fxFlex="100%" class="editorial-list-artists">
      <app-artist-list-item
        *ngFor="let page of iList.pages.slice(0, 4)"
        [small]="true"
        [page]="page"
        fxFlex="0 0 50%"
        fxFlex.gt-sm="0 0 25%"
      ></app-artist-list-item>
    </div>
  </div>
</div>
