import { Component, Input } from '@angular/core';
import { richTextToHtml } from '../../helpers';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  @Input() faq: any;

  public constructor() {}

  public richTextToHtml(richText): any {
    return richTextToHtml(richText);
  }
}
