import { apiToDate } from '../app/helpers';
import { GigSlot, SlotStatus } from '../generated/graphql';

export const isSlotOpen = (slot: GigSlot) => {
  return !isSlotExpired(slot) && !isSlotBooked(slot) && slot.status !== SlotStatus.Closed;
};

export const isSlotBooked = (slot: GigSlot) => {
  return slot.status === SlotStatus.Assigned;
};

export const isSlotExpired = (slot: GigSlot) => {
  return getSlotDatesLeft(slot) < 0;
};

export const getSlotDatesLeft = (slot: GigSlot): number => {
  const oneDay = 1000 * 60 * 60 * 24;

  const lastDate = apiToDate(slot.date);
  const start = Date.UTC(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate());

  const now = new Date();
  const end = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());

  return (start - end) / oneDay;
};
