<div fxLayout="column" fxLayoutAlign="start center" class="badge">
  <ng-container *ngIf="badge === 'TOP_3_LIVE'">
    <span class="badge--title" i18n>Top 3 Live</span>
    <mat-icon svgIcon="top-artist" class="badge--icon top-3"></mat-icon>
    <span class="badge--title">2018</span>
  </ng-container>

  <ng-container *ngIf="badge === 'TOP_3_DJ'">
    <span class="badge--title" i18n>Top 3 DJ</span>
    <mat-icon svgIcon="top-artist" class="badge--icon top-3"></mat-icon>
    <span class="badge--title">2018</span>
  </ng-container>

  <ng-container *ngIf="badge === BADGE_TYPES.OwnEquipment">
    <span class="badge--title" i18n>Own Equipment</span>
    <mat-icon svgIcon="own-equipment" class="badge--icon"></mat-icon>
  </ng-container>

  <ng-container *ngIf="badge === BADGE_TYPES.OwnTravel">
    <span class="badge--title" i18n>Open for travel</span>
    <mat-icon svgIcon="own-travel" class="badge--icon"></mat-icon>
  </ng-container>

  <ng-container *ngIf="badge === 'CUSTOMER_PICK'">
    <span class="badge--title" i18n>Customer Pick</span>
    <mat-icon svgIcon="customer-pick" class="badge--icon"></mat-icon>
  </ng-container>

  <ng-container
    *ngIf="
      badge === BADGE_TYPES.Bookings_10 ||
      badge === BADGE_TYPES.Bookings_25 ||
      badge === BADGE_TYPES.Bookings_50 ||
      badge === BADGE_TYPES.Bookings_75 ||
      badge === BADGE_TYPES.Bookings_100
    ">
    <div fxFlex fxLayout="column" fxLayoutAlign="center">
      <span fxLayout="column">
        <span *ngIf="badge === BADGE_TYPES.Bookings_10" class="badge--bookings-number">10+</span>
        <span *ngIf="badge === BADGE_TYPES.Bookings_25" class="badge--bookings-number">25+</span>
        <span *ngIf="badge === BADGE_TYPES.Bookings_50" class="badge--bookings-number">50+</span>
        <span *ngIf="badge === BADGE_TYPES.Bookings_75" class="badge--bookings-number">75+</span>
        <span *ngIf="badge === BADGE_TYPES.Bookings_100" class="badge--bookings-number">100+</span>

        <span class="badge--title" i18n>Verified bookings</span>
      </span>
    </div>
  </ng-container>
</div>
