import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-contact-info-warn',
  templateUrl: './contact-info-warn.component.html',
  styleUrls: ['./contact-info-warn.component.scss'],
})
export class ContactInfoWarnComponent implements OnInit {
  @Output()
  acknowledged = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  acknowledge() {
    this.acknowledged.next();
  }
}
