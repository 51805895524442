import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconSubsets, IconsRegistryService } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputComponent,
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() type: string = 'text';

  @Input() multiLine: boolean = false;
  @Input() rows: number = 1;
  @Input() specHeight: number;

  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;

  @Input() label: string = '';
  @Input() isLightLabel: false;
  @Input() labelTooltip: string = '';

  @Input() mask: string = '';

  @Input() maxChars: number = 0;
  @Input() showMaxChars: boolean = false;

  @Input() hasError: boolean = false;
  @Input() errorMessage: string = '';

  @Input() uppercase: false;

  public displayError = '';
  public textValue: string = '';

  get value() {
    return this.textValue;
  }

  get translatedLabel(): string {
    if (this.label) {
      return $localize`${this.label}`;
    }
    return null;
  }

  set value(value) {
    this.textValue = value;

    this.onTouch(value);
    this.onChange(value);
  }

  constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  writeValue(obj: any): void {
    this.textValue = obj;
  }

  private onChange: any = () => {};
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  private onTouch: any = () => {};
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  inputChanges(event) {
    let value: string = event.target.value;

    // CVA overwritter
    this.value = value;

    this.hasError = !this.showMaxChars && value.length > this.maxChars;

    if (!this.showMaxChars && value.length > this.maxChars) {
      this.displayError = $localize`Too many characters`;
    } else {
      this.displayError = this.errorMessage;
    }
  }

  public keyPressNumbers(event: KeyboardEvent): void {
    if (this.type === 'number') {
      var charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    }
  }
}
