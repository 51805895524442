<div fxHide.lt-sm *ngIf="audioArray?.length > 0" fxLayout="row wrap" class="media-items-list">
  <div
    *ngFor="let audio of audioArray"
    igxDrag
    igxDrop
    #dragRef
    fxFlex="395px"
    fxFlex.lt-sm="366px"
    id="{{ audio.id }}"
    (dropped)="onAudioDropped($event)"
    (dragStart)="dragStartHandler(audio.id)"
    (ghostCreate)="ghostCreateHandler(dragRef)"
    (dragEnd)="dragEndHandler(dragRef)"
    (enter)="onEnterHandler($event)"
    [ghostClass]="'drag_ghost_class'"
    class="media-item-container">
    <div fxLayout="column" class="media-item" [ngClass]="'audio-' + audio.socialMediaId">
      <div fxLayoutAlign="space-between center" class="media-item--top">
        <span fxLayoutGap="5px" fxLayoutAlign="start center">
          <mat-icon class="media-item--drag-icon">reorder</mat-icon>
          <span i18n>Drag to reorder</span>
        </span>
        <span *ngIf="audio?.order === 0" i18n>Primary</span>
      </div>
      <div class="iframe-container" [ngClass]="'iframe-container-' + audio.socialMediaId">
        <app-media-iframe
          [srcLink]="audio"
          [iframeNgClass]="'id-' + audio.socialMediaId.toString()"
          [isArtist]="audio.isArtist">
        </app-media-iframe>
      </div>
      <div class="media-item--bottom" fxLayoutAlign="flex-end">
        <button class="gig-button transparent" igxDragIgnore (click)="deleteMedia(audioArray, audio.id)" i18n
          >Delete</button
        >
      </div>
    </div>
  </div>
</div>

<div fxHide fxShow.lt-sm>
  <div fxLayout="column" fxLayoutAlign="start stretch" cdkDropList (cdkDropListDropped)="dropAudioOnMobile($event)">
    <div *ngFor="let audio of audioArray" cdkDrag>
      <div fxLayout="column" class="media-item" [ngClass]="'audio-' + audio.socialMediaId">
        <div fxLayoutAlign="space-between center" class="media-item--top">
          <span fxLayoutGap="5px" fxLayoutAlign="start center">
            <mat-icon class="media-item--drag-icon">reorder</mat-icon>
            <span i18n>Drag to reorder</span>
          </span>
          <span *ngIf="audio?.order === 0" i18n>Primary</span>
        </div>
        <div class="iframe-container" [ngClass]="'iframe-container-' + audio.socialMediaId">
          <app-media-iframe
            [srcLink]="audio"
            [iframeNgClass]="'id-' + audio.socialMediaId.toString()"
            [isArtist]="audio.isArtist">
          </app-media-iframe>
        </div>
        <div class="media-item--bottom" fxLayoutAlign="flex-end">
          <button class="gig-button transparent" igxDragIgnore (click)="deleteMedia(audioArray, audio.id)" i18n
            >Delete</button
          >
        </div>
      </div>
    </div>
  </div>
</div>
