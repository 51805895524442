<span class="">
  <span *ngIf="isToday" class="f-weight--600" i18n>Today</span>
  <span *ngIf="isTomorrow" class="f-weight--600" i18n>Tomorrow</span>
  <span *ngIf="!isToday && !isTomorrow">{{ displayDate | date: dateFormat }}</span>

  <span *ngIf="isTimeDisplay">&nbsp; | &nbsp;</span>

  <span *ngIf="isTimeDisplay" [ngClass]="{ 'f-weight--600': isToday || isTomorrow }">
    <span *ngIf="isTimeDisplay && !startTime" i18n>No set-time</span>
    <span *ngIf="startTime">{{ timestampToTimeString(startTime) }}</span>
    <span *ngIf="endTime">-</span>
    <span *ngIf="endTime">{{ timestampToTimeString(endTime) }}</span>
  </span>
</span>
