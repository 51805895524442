import { Booking, BookingDates, Channel, ChannelMessage } from '../../generated/graphql';

export interface ExtendedBooking extends Booking {
  totalPrice: any;
  channel: ExtendedChannel;
}

export interface ExtendedChannel extends Channel {
  groupedMessages?: ExtendedChannelMessage[];
}

export interface ExtendedDate extends BookingDates {
  selected: boolean;
}

export interface ExtendedChannelMessage extends ChannelMessage {
  subMessages?: MultipleChannelMessageBody[];
}

export interface MultipleChannelMessageBody {
  body: string;
  createdDate: string;
}

export const extendBooking = (booking) => {
  return Object.assign({}, booking);
};

// Group messages by sender and within time threshold
export const groupMessages = (messages: ExtendedChannelMessage[]): ExtendedChannelMessage[] => {
  const sameSender = (lastMessage: ExtendedChannelMessage, message: ExtendedChannelMessage): boolean => {
    return lastMessage.sender.id === message.sender.id;
  };

  const FIVE_MINUTES = 5 * 60 * 1000;
  const withinThreshold = (lastMessage: ExtendedChannelMessage, message: ExtendedChannelMessage): boolean => {
    return parseInt(message.createdDate, 10) - parseInt(lastMessage.createdDate, 10) <= FIVE_MINUTES;
  };

  const messagesCopy = JSON.parse(JSON.stringify(messages));
  let lastMessageRef: ExtendedChannelMessage;
  let lastMessageTimeRef: ExtendedChannelMessage;

  if (messagesCopy.length > 1) {
    const groupedMessages: ExtendedChannelMessage[] = messagesCopy
      .sort((a, b) => {
        if (a && b) {
          return (a.createdDate as any) - (b.createdDate as any);
        }
        return 0;
      })
      .map((message) => {
        if (!message || !lastMessageRef || message.sender.id === 1) return message; // Bot messages
        if (
          lastMessageRef &&
          sameSender(lastMessageRef, message) &&
          withinThreshold(lastMessageTimeRef || lastMessageRef, message)
        ) {
          if (!lastMessageRef.subMessages) {
            lastMessageRef.subMessages = [{ body: lastMessageRef.body, createdDate: lastMessageRef.createdDate }];
          }
          lastMessageRef.subMessages.push({ body: message.body, createdDate: message.createdDate });
          lastMessageTimeRef = message;
          return;
        }

        lastMessageTimeRef = message;
        lastMessageRef = message;
        return message;
      })
      .filter((m) => m);

    return groupedMessages;
  }
  return messagesCopy;
};
