import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GetCurrentUserGQL, GetPagesByUserGQL, Gig, GigSlot, Page, User } from '../../../../../../generated/graphql';
import { isSlotBooked, isSlotOpen } from '../../../../../../utils/gigSlots';
import { SelectApplicantsComponent } from '../../../../components/select-applicants/select-applicants.component';
import { GigEditSlotComponent } from '../gig-edit-slot/gig-edit-slot.component';

export interface ExtendedGigSlot extends GigSlot {
  isSelected: boolean | undefined;
}

@Component({
  selector: 'app-gig-details-slots',
  templateUrl: './gig-details-slots.component.html',
  styleUrls: ['./gig-details-slots.component.scss'],
})
export class GigDetailsSlotsComponent implements OnInit, OnChanges {
  @Input()
  gig: Gig;

  @Input()
  hasNextPage: boolean;

  @Input()
  loading: boolean;

  @Output()
  onFetchMore = new EventEmitter();

  hasSearchable = false;
  pages: Observable<Page[]>;
  currentUser: Observable<User>;

  constructor(
    private modal: MatDialog,
    private getPages: GetPagesByUserGQL,
    private getMe: GetCurrentUserGQL,
    private router: Router,
  ) {}

  selectedSlots: GigSlot[] = [];
  slots: { open: GigSlot[]; closed: GigSlot[] } = {
    open: [],
    closed: [],
  };

  ngOnInit() {
    this.currentUser = this.getMe.watch().valueChanges.pipe(map(({ data }) => data.me));
    this.currentUser.subscribe((me) => {
      if (me && !this.pages) {
        this.pages = this.getPages.watch().valueChanges.pipe(
          map((d) => d.data.pagesByUser),
          // map((d) => d.data.pagesByUser.filter((p) => p.searchable)),
          tap((d) => {
            this.hasSearchable = d.length > 0;
          }),
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gig) {
      this.mapGigSlots(changes.gig.currentValue.slots);
    }
  }

  openApply() {
    this.router.navigate(['gigs', this.gig.id, 'apply'], {
      queryParams: { slots: this.selectedSlots.map((s) => s.id.toString()).join(',') },
    });
  }

  openSelect() {
    this.modal.open(SelectApplicantsComponent, { minWidth: '75%', data: { gig: this.gig } });
  }

  openNew() {
    const modalRef = this.modal.open(GigEditSlotComponent, {
      minWidth: '38%',
      data: {
        gig: this.gig,
        new: true,
      },
    });
  }

  selectSlot(slot: GigSlot) {
    const index = this.selectedSlots.findIndex((s) => s.id === slot.id);
    if (index > -1) {
      this.selectedSlots.splice(index, 1);
    } else {
      this.selectedSlots.push(slot);
    }
  }

  private mapGigSlots(slots: GigSlot[]): void {
    this.slots.open = [];
    this.slots.closed = [];

    if (this.gig.limitedData) {
      for (let i = 0; i < this.gig.slotsOpen; i++) {
        this.slots.open.push({
          date: '0',
          tags: this.gig.availableTags,
          price: 0,
          applications: [],
          priceHidden: true,
        });
      }
    } else {
      for (const s of slots) {
        if (isSlotOpen(s)) {
          this.slots.open.push(s);
        } else if (isSlotBooked(s)) {
          this.slots.closed.push(s);
        }
      }
      console.log(this.slots);
    }
  }

  fetchMoreSlots() {
    this.onFetchMore.next();
  }
}
