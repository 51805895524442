<a
  routerLink="{{ searchTrendItem?.searchParams?.fields?.url }}"
  [queryParams]="
    searchTrendItem?.searchParams?.fields.queryParams &&
    (searchTrendItem.searchParams.fields.queryParams | parseQueryParams)
  "
  *ngIf="searchTrendItem"
  fxLayoutAlign="start center"
  fxLayoutGap="24px"
  class="search-trend">
  <!-- <img [src]="searchTrendItem.trendImage?.fields.file.url" class="search-trend--img" /> -->
  <mat-icon svgIcon="loupe" class="search-trend--icon"></mat-icon>
  <span class="search-trend--title">{{ searchTrendItem.trendTitle }}</span>
</a>
