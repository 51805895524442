import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, Optional, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';

declare let NG_CONF;

export enum MetaTag {
  description = 'description',
  ogDescription = 'og:description',
  ogTitle = 'og:title',
  ogType = 'og:type',
  ogImage = 'og:image',
  ogUrl = 'og:url',
  ogSiteName = 'og:site_name',
}

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private fallbackDescriptions = {
    en: `Live music for kickoff, office party, officeparty. 200 verified artists, DJ's and bands. Find and book your favorite. Free registration. Safe bookings.`,
    'sv-se': `Hitta och boka över 200 professionella artister, band och DJs till ditt event. Gratis registrering - trygg och säker bokning.`,
  };
  private fallbackTitles = {
    en: `Book artists, live bands and DJs | Gigital`,
    'sv-se': 'Boka artister, band och DJs | Gigital',
  };

  get fallbackDescription() {
    return this.fallbackDescriptions[this.localeId];
  }
  set fallbackDescription(val) {
    this.fallbackDescriptions[this.localeId] = val;
  }
  get fallbackTitle() {
    return this.fallbackTitles[this.localeId];
  }
  set fallbackTitle(val) {
    this.fallbackTitles[this.localeId] = val;
  }
  public fallbackImage =
    'https://res.cloudinary.com/hthruuqgk/image/upload/f_auto,fl_progressive,q_auto:low/v1567516416/web/ogImage_cw8adl.jpg';

  isBrowser = false;
  constructor(
    private titles: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(LOCALE_ID) private localeId: string,
    @Optional() @Inject(REQUEST) private request: any,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  public setLocalizedTitle(titleObject: { [locale: string]: string }): void {
    this.setTitle(titleObject[this.localeId]);
    this.setUrl();
  }

  public setLocalizedDescription(descriptionObject: { [locale: string]: string }): void {
    this.setDescription(descriptionObject[this.localeId]);
  }

  /**
   * Set the page title.
   * @param newTitle New title to set
   * @param appendGigital Append "' | Gigital'" to the end of the title?
   */
  public setTitle(newTitle: string, appendGigital: boolean = false) {
    const nuTitle = appendGigital ? `${newTitle} | Gigital` : newTitle;
    this.titles.setTitle(nuTitle);
    this.meta.updateTag({ property: 'og:title', content: nuTitle });
  }

  /**
   * Set the page description.
   * @param newDescription New description to set
   */
  public setDescription(newDescription: string) {
    this.setMetaTag(MetaTag.description, newDescription);
    this.meta.updateTag({ property: 'og:description', content: newDescription });
  }

  /**
   * Set page meta tags.
   * @param tagName Name of the tag to modify. Eg. "description" or "og:description"
   * @param newContent String containing the description
   * @param page If this is an artist page, send the page data
   */
  public setMetaTag(tagName: string, newContent: string) {
    if (!!this.meta.getTag(`name="${tagName}"`)) {
      this.meta.updateTag({ name: tagName, content: newContent });
    } else {
      this.meta.addTag({ name: tagName, content: newContent });
    }
  }

  public setHreflangRoute(url: string): void {
    if (url && url.length > 0) {
      const hrefLangLinks = this.document.querySelectorAll('link[rel=alternate]');
      hrefLangLinks.forEach(link => {
        const lang = link.getAttribute('hreflang');
        const href = `https://www.gigital.com/${lang}${url}`;
        link.setAttribute('href', href);
      });
    }
  }

  /**
   * Set open graph tag
   * @param tagName Name of the tag to modify Eg. "description" or "og:description"
   * @param newContent String containing the description
   */
  public setOgTag(tagName: MetaTag, newContent: string) {
    if (!!this.meta.getTag(`property="${tagName}"`)) {
      this.meta.updateTag({ property: tagName, content: newContent });
    } else {
      this.meta.addTag({ property: tagName, content: newContent });
    }
  }

  public setNoIndex() {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

  /**
   * Set the title with default fallback data for other meta tags
   * @param newTitle The title to set.
   * @param appendGigital Append "| Gigital" to the end of the title?
   */
  public setTitleWithDefaults(newTitle: string, appendGigital: boolean = true, url?: string) {
    this.setTitle(newTitle, appendGigital);
    this.setUrl(url);
    this.setMetaTag(MetaTag.description, this.fallbackDescription);
    this.setOgTag(MetaTag.ogDescription, this.fallbackDescription);
    this.setOgTag(MetaTag.ogImage, this.fallbackImage);
    this.setOgTag(MetaTag.ogSiteName, 'Gigital');
  }

  private setUrl(url?: string): void {
    if (!!url && url.includes('admin')) {
      this.setNoIndex();
    } else {
      if (this.meta.getTag('name=robots')) {
        this.meta.removeTag('name=robots');
      }
    }

    let _url = '';
    if (this.isBrowser) {
      _url = window.location.href;
    } else {
      _url = `https://${this.request.hostname}${this.request.originalUrl}`;
    }
    this.setOgTag(MetaTag.ogUrl, _url);
  }

  public setContentfulSeoData(contentfulPage: any): void {
    if (contentfulPage.seoTitle && contentfulPage.seoTitle.length > 0) {
      this.setTitle(contentfulPage.seoTitle);
      this.setUrl();
    }
    if (contentfulPage.seoDescription && contentfulPage.seoDescription.length > 0) {
      this.setDescription(contentfulPage.seoDescription);
      this.setMetaTag(MetaTag.description, contentfulPage.seoDescription);
    }
  }
}
