import { Component, Input, OnInit } from '@angular/core';
import { Page } from '../../../../generated/graphql';

@Component({
  selector: 'app-artist-profile-dashboard',
  templateUrl: './artist-profile-dashboard.component.html',
  styleUrls: ['./artist-profile-dashboard.component.scss'],
})
export class ArtistProfileDashboardComponent implements OnInit {
  @Input() userPages: Page[];

  public displayedPageIndex: number = 0;

  constructor() {}

  ngOnInit(): void {}

  public onPreviousClick(): void {
    this.displayedPageIndex = this.displayedPageIndex > 0 ? this.displayedPageIndex - 1 : this.displayedPageIndex;
  }

  public onNextClick(): void {
    this.displayedPageIndex =
      this.displayedPageIndex < this.userPages.length ? this.displayedPageIndex + 1 : this.displayedPageIndex;
  }
}
