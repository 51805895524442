import { Component, OnInit } from '@angular/core';
import { GetContentfulEntryGQL } from '../../../generated/graphql';
import { richTextToHtml } from '../../helpers';
import { MetaService } from '../../services/meta.service';
import { TranslationsService } from '../../services/translations.service';

@Component({
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  constructor(
    private getContentfulEntry: GetContentfulEntryGQL,
    private translations: TranslationsService,
    private meta: MetaService,
  ) {}

  isLoading = true;
  infoBlock;
  timeline;
  selectedYear = 0;

  ngOnInit() {
    this.getContentfulEntry
      .fetch({ id: '4Euht7zIJVWnvCRairma6s', locale: this.translations.getCurrentLocale() })
      .subscribe(result => {
        const page = result.data.getContentfulEntry.contentfulData;
        this.meta.setContentfulSeoData(page);
        this.infoBlock = page.fields.blocks.find(e => e.sys.id === '2o5j6rIggGuMizJALeTgn6');
        this.timeline = page.fields.blocks.find(e => e.sys.id === '1Sis6MSvNGC9CxvX9QyRV7');
        this.isLoading = false;
      });
  }

  richTextToHtml(text) {
    return richTextToHtml(text);
  }
}
