import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageLink } from '../../../../../../../generated/graphql';

@Component({
  selector: 'app-artist-edit-media-video',
  templateUrl: './artist-edit-media-video.component.html',
  styleUrls: ['./artist-edit-media-video.component.scss'],
})
export class ArtistEditMediaVideoComponent {
  @Output() onMediaChanged$: EventEmitter<PageLink[]> = new EventEmitter();
  @Input() videoArray: PageLink[];

  public dropVideoTileId: number;
  public dragVideoId: number;

  public dragStartHandler(id: number): void {
    this.dragVideoId = id;
  }

  public dragEndHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'visible';
  }

  public ghostCreateHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'hidden';
  }

  public onVideoDropped(ev) {
    ev.drag.dropFinished();
  }

  public onEnterHandler(ev): void {
    this.dropVideoTileId = parseInt(ev.owner.element.nativeElement.id, 10);

    if (this.dragVideoId === this.dropVideoTileId) return;

    const dragIndex = this.videoArray.findIndex((video) => video.id === this.dragVideoId);
    const dropIndex = this.videoArray.findIndex((video) => video.id === this.dropVideoTileId);

    this.swapVideo(dragIndex, dropIndex);

    this.videoArray.find((video) => video.id === this.dragVideoId).order = dropIndex;

    if (dragIndex < dropIndex) {
      for (let i = dragIndex; i < dropIndex; i++) {
        this.videoArray[i].order--;
        this.videoArray[i].isHighlight = this.videoArray[i].order === 0;
      }
    } else {
      for (let i = dropIndex + 1; i <= dragIndex; i++) {
        this.videoArray[i].order++;
        this.videoArray[i].isHighlight = this.videoArray[i].order === 0;
      }
    }

    this.onMediaChanged$.emit(this.videoArray);
  }

  private swapVideo(dragIndex: number, dropIndex: number) {
    const tempObj = this.videoArray[dragIndex];
    this.videoArray.splice(dragIndex, 1);
    this.videoArray.splice(dropIndex, 0, tempObj);
  }

  public deleteMedia(mediaArray: PageLink[], mediaId: number): void {
    mediaArray.forEach((media, index) => {
      if (media.id === mediaId) mediaArray.splice(index, 1);
    });

    this.onMediaChanged$.emit(this.videoArray);
  }

  public dropVideoOnMobile(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.videoArray, event.previousIndex, event.currentIndex);
  }
}
