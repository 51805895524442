<div class="allowance-modal">
  <div fxLayoutAlign="end" class="allowance-modal--header">
    <mat-icon svgIcon="close" (click)="closeTravelAllowanceModal()"></mat-icon>
  </div>

  <div class="allowance-modal--content">
    <h5 class="h5 f-weight--600 m-b--24" i18n>Travel expenses</h5>
    <p class="m-b--30" i18n>Select sum for the travel compensation</p>

    <app-dropdown [onClosed]="closeDropdown$">
      <div selected class="dropdown-option">
        {{ selectedAllowanceValue }} {{ isOptionSelected ? booking.dates[0].priceCurrency : '' }}
      </div>
      <div options fxLayout="column">
        <span *ngFor="let option of allowanceOptions" (click)="onOptionSelected(option)" class="dropdown-option">
          {{ option }} {{ booking.dates[0].priceCurrency }}
        </span>
      </div>
    </app-dropdown>
  </div>

  <div fxLayoutAlign="end" class="allowance-modal--footer">
    <button
      class="gig-button primary f-size--16 wide"
      [disabled]="!isOptionSelected"
      (click)="updateBookingTravelAllowance()"
      i18n>
      Save
    </button>
  </div>
</div>
