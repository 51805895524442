<div fxLayout="column" fxLayoutGap="8px" class="form-input">
  <p
    *ngIf="!!label"
    fxLayoutAlign="start center"
    fxLayoutGap="6px"
    class="form-input--label"
    [ngClass]="{ light: isLightLabel }">
    <span>{{ label }}</span>
    <mat-icon *ngIf="!!labelTooltip" [matTooltip]="labelTooltip" svgIcon="info-fill"></mat-icon>
  </p>
  <app-dropdown [onClosed]="onDropdownClose$">
    <div selected class="dropdown-option">
      {{ selectedOption?.label }}
    </div>
    <div options fxLayout="column">
      <span
        fxFlex
        *ngFor="let option of selectOptions"
        (click)="onOptionSelected(option.value)"
        class="dropdown-option">
        {{ option?.label }}
      </span>
    </div>
  </app-dropdown>
</div>
