import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { Currency, GetCurrentUserGQL, GigType, PageCategory } from '../../../../../generated/graphql';
import { displayableFee } from '../../../../helpers/bookings';
import { formatDuration } from '../../../../helpers/dates';
import { TranslationsService } from '../../../../services/translations.service';

@Component({
  selector: 'app-create-gig-form-summary',
  templateUrl: './create-gig-form-summary.component.html',
  styleUrls: ['./create-gig-form-summary.component.scss'],
})
export class CreateGigFormSummaryComponent implements OnInit {
  @Output() changeStepNumber$: EventEmitter<number> = new EventEmitter();

  @Input() createGigForm: FormGroup;
  @Input() isGigCreated: boolean;

  public userFee: number = 1.15;

  public POLICY_LINK = $localize`http://help.gigital.se/en/articles/3350100-payment-policy`;
  public CURRENCY = Currency;

  public displayableFee = displayableFee;
  public PAGE_CATEGORY = PageCategory;

  public get gigTypeTitle(): string {
    switch (this.createGigForm.value.gigType) {
      case GigType.Business:
        return $localize`Corporate`;
      case GigType.Private:
        return $localize`Private`;
      case GigType.Venue:
        return $localize`Venue`;
      case GigType.Others:
        return $localize`Others`;
      default:
        return $localize`Corporate`;
    }
  }

  public get datesPreviewArray(): string[] {
    const locale = this.translationsService.getCurrentLocale();
    return this.createGigForm.value.tempSlotDate
      .map((d) => new Date(d))
      .map((d) => {
        const weekday = [
          $localize`Sunday`,
          $localize`Monday`,
          $localize`Tuesday`,
          $localize`Wednesday`,
          $localize`Thursday`,
          $localize`Friday`,
          $localize`Saturday`,
        ];
        const day = weekday[d.getDay()];
        const date = d.getDate();
        const month = new Intl.DateTimeFormat(locale, { month: 'short' }).format(d);
        const year = d.getFullYear();
        return `${day}, ${date} ${month} ${year}`;
      });
  }

  public get setLengthToDuration(): string {
    return formatDuration(this.createGigForm.value.setLength);
  }

  public get typeOfArt(): string {
    let artTypes: string[] = [];
    if (this.createGigForm.value.pageCategory.indexOf(PageCategory.Live) !== -1) {
      artTypes.push($localize`Live music`);
    }
    if (this.createGigForm.value.pageCategory.indexOf(PageCategory.Dj) !== -1) {
      artTypes.push($localize`DJs`);
    }
    return artTypes.join(', ');
  }

  public get bookingDatesPrice(): number {
    if (this.createGigForm.value.pageCategory?.length > 1) {
      return Math.round(
        this.createGigForm.value.tempSlotDate.length *
          (parseInt(this.createGigForm.value.budgetLive) + parseInt(this.createGigForm.value?.budgetDJ)),
      );
    } else {
      return Math.round(this.createGigForm.value.tempSlotDate.length * parseInt(this.createGigForm.value.budgetAll));
    }
  }

  public get liveDatesPrice(): number {
    return Math.round(this.createGigForm.value.tempSlotDate.length * parseInt(this.createGigForm.value.budgetLive));
  }

  public get djDatesPrice(): number {
    return Math.round(this.createGigForm.value.tempSlotDate.length * parseInt(this.createGigForm.value.budgetDJ));
  }

  public get bookingFeeAmount(): number {
    return Math.round(this.bookingDatesPrice * this.userFee - this.bookingDatesPrice);
  }

  public get bookingEstimatedPrice(): number {
    return Math.round(this.bookingDatesPrice * this.userFee);
  }

  public get currentLocale(): string {
    return this.translationsService.getCurrentLocale();
  }

  public constructor(private translationsService: TranslationsService, private getCurrentUserGQL: GetCurrentUserGQL) {}

  public ngOnInit(): void {
    this.getCurrentUserGQL
      .watch()
      .valueChanges.pipe(
        map(({ data }) => data.me),
        tap((cu) => {
          console.log(cu);
          this.userFee = cu?.fee || 1.15;
        }),
      )
      .subscribe();
  }
}
