import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {
  ExistingCountry,
  ExistingPlace,
  GetFilterablePagePlacesGQL,
  PageCategory,
  Tag,
  TagFilter,
  TagMatches,
} from '../../../../generated/graphql';
import { TagGroupEnum } from '../../../helpers/pages';
import { ArtistSortingOption, ArtistSortingValues } from '../../../models/ArtistSortingOptions';
import { GET_PAGE_FILTER, SET_PAGE_FILTER } from '../../../resolvers';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';
import { TranslationsService } from '../../../services/translations.service';
import { ArtistListFilterMobileModalComponent } from '../artist-list-filter-mobile/artist-list-filter-mobile-modal/artist-list-filter-mobile-modal.component';

const DEFAULT_LIMIT = 15;
interface ExtendedTag extends Tag {
  translatedName?: string;
  active?: boolean;
  hits: number;
}

const OVERLAY_POSITIONS: ConnectionPositionPair[] = [
  {
    offsetX: 0,
    offsetY: 5,
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    panelClass: null,
  },
];

const ARTIST_SORTING_OPTIONS: ArtistSortingOption[] = [
  {
    name: $localize`Most relevant`,
    value: ArtistSortingValues.RelevanceDesc,
  },
  {
    name: $localize`Price - high to low`,
    value: ArtistSortingValues.PriceDesc,
  },
  {
    name: $localize`Price - low to high`,
    value: ArtistSortingValues.PriceAsc,
  },
];

export interface ExtendedExistingPlace extends ExistingPlace {
  active: boolean;
}

@Component({
  selector: 'app-artist-list-filter',
  templateUrl: './artist-list-filter.component.html',
  styleUrls: ['./artist-list-filter.component.scss'],
})
export class ArtistListFilterComponent implements OnInit, OnDestroy {
  @ViewChild('searchTermDesktop', { static: true }) searchTerm: ElementRef;
  @Output() sidenavToggle = new EventEmitter();

  @Input() filterableContries: ExistingCountry[];
  @Input() matches: Observable<TagMatches[]>;
  @Input() hits: Observable<TagMatches[]>;
  @Input() tags: ExtendedTag[];

  public searchTermValue$: EventEmitter<any> = new EventEmitter();

  public overlayPositions: ConnectionPositionPair[] = OVERLAY_POSITIONS;
  public sortingOptions: ArtistSortingOption[] = ARTIST_SORTING_OPTIONS;

  public isPerformerFilterOpen: boolean = false;
  public isMusicTypeFilterOpen: boolean = false;
  public isLocationFilterOpen: boolean = false;

  public isMobileFiltersOpen: boolean = false;

  public includesEquipment: boolean = false;
  public pageCategory = PageCategory;
  public searchTermValue: string;

  public locationFilterData = { sections: [] };
  public performerFilterData = { sections: [] };
  public musicTypeFilterData = { sections: [] };

  public filtrablePlaces: ExtendedExistingPlace[] = [];
  public instrumentsTags: ExtendedTag[] = [];
  public performerTags: ExtendedTag[] = [];
  public musicianTags: ExtendedTag[] = [];
  public genreTags: ExtendedTag[] = [];
  public matchesArray: TagMatches[] = [];

  public artistType: ExtendedTag[];
  public locations: ExtendedTag[];
  public performer: ExtendedTag[];
  public genres: ExtendedTag[];

  public activeSortingIndex: number = 0;

  limit = 15;

  subscriptions: Subscription[] = [];
  textSearchSubscription;

  _djSearch = false;
  _liveSearch = false;

  public get performerActiveTags(): number {
    return this.performerTags.concat(this.musicianTags).filter((tag) => tag.active).length;
  }

  public get musicActiveTags(): number {
    return this.genreTags.concat(this.instrumentsTags).filter((tag) => tag.active).length;
  }

  public get locationActiveFilters() {
    let activeLocations = [];
    this.filterableContries?.forEach((country) => {
      country.places.forEach((place) => {
        if ((place as ExtendedExistingPlace).active) activeLocations.push(place);
      });
    });
    return activeLocations.length;
  }

  public get activeFiltersMobile(): number {
    let activeFiltersNumber = 0;

    if (this._djSearch || this._liveSearch) activeFiltersNumber++;
    if (this.includesEquipment) activeFiltersNumber++;

    let activeTags = [...this.musicianTags, ...this.performerTags, ...this.genreTags, ...this.instrumentsTags].filter(
      (tag) => tag.active,
    );
    activeFiltersNumber += activeTags.length;
    activeFiltersNumber += this.locationActiveFilters;
    return activeFiltersNumber;
  }

  get djSearch() {
    return this._djSearch;
  }

  set djSearch(val) {
    this._djSearch = val;
    if (val) {
      this._liveSearch = false;
    }
  }

  get liveSearch() {
    return this._liveSearch;
  }

  set liveSearch(val) {
    this._liveSearch = val;
    if (val) {
      this._djSearch = false;
    }
  }

  public get currentLocale(): string {
    return this.translationsService.getCurrentLocale();
  }

  constructor(
    private getFilterablePagePlacesGQL: GetFilterablePagePlacesGQL,

    private translationsService: TranslationsService,
    private iconsService: IconsRegistryService,
    private activeRoute: ActivatedRoute,
    private modalRef: MatDialog,
    private apollo: Apollo,
    private router: Router,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  activeTags(tagType: string): string {
    const activeTagNames = this[tagType].filter((t) => t.active).map((t) => t.name);

    if (activeTagNames.length > 2) {
      return `${activeTagNames.slice(0, 2).join(', ')} + ${activeTagNames.length - 2} more`;
    }

    return activeTagNames.join(', ');
  }

  public ngOnInit() {
    if (!this.filterableContries) {
      this.getFilterablePagePlacesGQL
        .fetch()
        .pipe(map((res) => res.data.getFilterablePagePlaces))
        .subscribe((countries: ExistingCountry[]) => {
          this.filterableContries = countries;
          this.mapFiltrablePlacesData(countries);

          // Set any stored filters
          setTimeout(() => {
            this.setFilter();
          });
        });
    } else {
      this.mapFiltrablePlacesData(this.filterableContries);
    }

    this.matches.subscribe((matches) => {
      this.tags.forEach((tag) => {
        const extendedTag = tag as ExtendedTag;
        if (this.currentLocale === 'sv-se') {
          extendedTag.translatedName = tag.translations?.sv || tag.name;
        } else {
          extendedTag.translatedName = tag.translations?.en || tag.name;
        }

        let match = matches.find((match) => match.tagId === extendedTag.id);
        extendedTag.hits = match?.count;

        return extendedTag;
      });
    });

    this.performerTags = this.tags.filter((tag) => tag.group === TagGroupEnum.PERFORMER_TYPE);
    this.musicianTags = this.tags.filter((tag) => tag.group === TagGroupEnum.MUSIC_TYPE);
    this.genreTags = this.tags.filter((tag) => tag.group === TagGroupEnum.NEW_GENRE);
    this.instrumentsTags = this.tags.filter((tag) => tag.group === TagGroupEnum.INSTRUMENT);

    this.artistType = this.tags.filter((t) => t.group === 4);
    this.genres = this.tags.filter((t) => t.group === 1);
    this.locations = this.tags.filter((t) => t.group === 6);

    this.performerFilterData = {
      sections: [
        { title: $localize`Music type`, options: this.musicianTags },
        { title: $localize`Musician type`, options: this.performerTags },
      ],
    };

    this.musicTypeFilterData = {
      sections: [
        { title: $localize`Genres`, options: this.genreTags },
        { title: $localize`Instruments`, options: this.instrumentsTags },
      ],
    };

    const zap = combineLatest([this.matches, this.hits]);

    zap.subscribe((val) => {
      const matches = val[0];
      const hits = val[1];
      const override = !!hits.find((h) => h.count > 0);

      for (const hit of matches) {
        const tag: ExtendedTag =
          this.genres.find((t) => t.id === hit.tagId) || this.locations.find((t) => t.id === hit.tagId);
        if (tag) {
          tag.hits = hit.count;
        }
      }

      this.genres = this.genres.sort((a, b) => (b.hits || 0) - (a.hits || 0));
      if (override) {
        this.genres = this.genres.map((t) => {
          const hit = hits.find((h) => h.tagId === t.id);
          t.hits = hit?.count || 0;
          return t;
        });
      }

      this.locations = this.locations.sort((a, b) => (b.hits || 0) - (a.hits || 0));
    });

    this.searchTermValue$.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchTerm: string) => {
      this.doSearch(searchTerm);
    });

    const filterSub = this.apollo
      .watchQuery({ query: GET_PAGE_FILTER })
      .valueChanges.pipe(map(({ data }) => (data as any).pageFilter))
      .subscribe((res) => {
        if (res.skip + res.limit !== this.limit) {
          this.limit = res.skip + res.limit;
          this.router.navigate(['/artists/search'], {
            queryParams: { limit: this.limit },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }
      });
    this.subscriptions.push(filterSub);
  }

  private mapFiltrablePlacesData(countries: ExistingCountry[]): void {
    countries.forEach((country) => {
      country.places = country.places.map((place) => ({
        ...place,
        active: false,
      }));
      this.filtrablePlaces = [...this.filtrablePlaces, ...country.places] as ExtendedExistingPlace[];

      this.locationFilterData.sections.push({
        title: country.name,
        options: country.places,
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
  }

  clearFilter(): void {
    this.genres.forEach((t) => (t.active = false));
    this.locations.forEach((t) => (t.active = false));
    this.artistType.forEach((t) => (t.active = true));
    this.activeSortingIndex = 0;
    this.doSearch();
  }

  doSearch(textQuery?: string, limit?: number): void {
    let activePlaces = this.filtrablePlaces.filter((place) => place.active);
    let category: PageCategory | null = null;

    if (this._djSearch) {
      category = PageCategory.Dj;
    } else if (this._liveSearch) {
      category = PageCategory.Live;
    }

    const pageFilter = {
      __typename: 'pageFilter',
      category: category,
      sorting: this.sortingOptions[this.activeSortingIndex].value,
      tags: this.mapActiveTags(),
      textQuery: textQuery || this.searchTermValue || null,
      limit: DEFAULT_LIMIT,
      skip: Math.max((limit || 0) - DEFAULT_LIMIT, 0),
      priceMax: null,
      priceMin: null,
      includesEquipment: this.includesEquipment || null,
    };

    if (activePlaces) {
      // HACK: lol get me out of this codebase
      (activePlaces as any) = activePlaces.map((location) => {
        return {
          lat: location.geometry.location.lat,
          long: location.geometry.location.lng,
        };
      });
      (pageFilter as any).location = activePlaces;
      (pageFilter as any).locationRadiusKm = 40;
    } else {
      (pageFilter as any).location = null;
      (pageFilter as any).locationRadiusKm = null;
    }

    const sub = this.apollo
      .mutate({
        mutation: SET_PAGE_FILTER,
        variables: { pageFilter },
      })
      .subscribe((res) => {});

    this.subscriptions.push(sub);
    this.setUrl();
  }

  private mapActiveTags(): TagFilter[] {
    let activeTags = [];
    activeTags = [...this.musicianTags, ...this.performerTags, ...this.genreTags, ...this.instrumentsTags];
    return activeTags.filter((tag) => tag.active).map((tag) => ({ tagId: tag.id, points: 5 }));
  }

  private setFilter(): void {
    const urlFilters = this.parseUrl();
    const { pageFilter }: any = this.apollo.getClient().cache.readQuery({ query: GET_PAGE_FILTER });

    if (urlFilters) {
      this.parseUrlParams(urlFilters);
    } else {
      if (!this.artistType.find((t) => t.active)) {
        this.artistType.forEach((t) => (t.active = true));
      }

      this.searchTermValue = pageFilter.textQuery;

      this.activeSortingIndex = this.sortingOptions.findIndex((so) => so.value === pageFilter.sorting);

      for (const tag of this.tags) {
        tag.active = false;
      }

      for (const t of pageFilter.tags) {
        const tag =
          this.artistType.find((at) => at.id === t) ||
          this.genres.find((at) => at.id === t) ||
          this.locations.find((at) => at.id === t);
        if (tag) {
          tag.active = true;
        }
      }

      this.setUrl();
    }
  }

  private setUrl(): void {
    const queryParams: Params = {};

    const taggable = [...this.musicianTags, ...this.performerTags, ...this.genreTags, ...this.instrumentsTags].filter(
      (t) => t.active,
    );

    if (taggable.length > 0) {
      queryParams.tags = taggable.map((t) => t.id).join(',');
    }

    if (this._djSearch) {
      queryParams.category = PageCategory.Dj;
    } else if (this._liveSearch) {
      queryParams.category = PageCategory.Live;
    }

    if (this.includesEquipment) {
      queryParams.includesEquipment = true;
    }

    if (!!this.filtrablePlaces.find((t) => t.active)) {
      queryParams.location = this.filtrablePlaces
        .filter((place) => place.active)
        .map((place) => place.longName)
        .join(',');
    }

    if (this.searchTermValue && this.searchTermValue.length > 0) {
      queryParams.textQuery = this.searchTermValue;
    }

    queryParams.sorting = this.sortingOptions[this.activeSortingIndex].value.toLowerCase();
    queryParams.limit = this.limit;

    this.router.navigate(['/artists/search'], {
      queryParams,
      replaceUrl: true,
    });
  }

  private parseUrl(): Params | undefined {
    const queryParams: Params = this.activeRoute.snapshot.queryParams;
    return Object.keys(queryParams).length > 0 ? queryParams : undefined;
  }

  private parseUrlParams(params: Params): any {
    if (params.tags) {
      const checkArrays = [this.instrumentsTags, this.performerTags, this.musicianTags, this.genreTags];
      const tagsArray = params.tags.split(',');
      for (const t of tagsArray) {
        const tid = parseInt(t, 10);
        for (const arr of checkArrays) {
          const found = arr.find((tag) => tag.id === tid);
          if (found) {
            found.active = true;
          }
        }
      }
    }

    if (params.location) {
      const locationsArray = params.location.split(',');
      const locations = this.filtrablePlaces;
      for (const loc of locationsArray) {
        const found = locations.find((l) => l.longName.toLowerCase() === loc.toLowerCase());
        if (found) {
          found.active = true;
        }
      }
    }

    if (params.sorting) {
      const sorting = params.sorting.toUpperCase();
      const index = this.sortingOptions.map((o) => o.value).indexOf(sorting);

      if (index > -1) {
        this.activeSortingIndex = index;
      }
    }

    if (params.limit) {
      this.limit = parseInt(params.limit, 10);
    }

    if (params.category) {
      this._djSearch = params.category.toLowerCase() === 'dj';
      this._liveSearch = params.category.toLowerCase() === 'live';
    }

    if (params.textQuery) {
      this.searchTermValue = params.textQuery;
      this.doSearch(this.searchTermValue, this.limit);
    } else {
      this.doSearch(undefined, this.limit);
    }
  }

  public openMobileFilterModal(): void {
    let category: PageCategory | null = null;

    if (this._djSearch) {
      category = PageCategory.Dj;
    } else if (this._liveSearch) {
      category = PageCategory.Live;
    }

    this.modalRef
      .open(ArtistListFilterMobileModalComponent, {
        height: '100%',
        minHeight: '100dvh',
        minWidth: '100vw',
        panelClass: 'modal-no-padding',
        data: {
          filters: {
            category: category,
            performerTags: this.performerTags,
            genreTags: this.genreTags,
            musicianTags: this.musicianTags,
            location: this.filterableContries,
            instrumentsTags: this.instrumentsTags,
            includesEquipment: this.includesEquipment,
          },
        },
      })
      .afterClosed()
      .subscribe((filterResult) => {
        if (!!filterResult.category) {
          if (filterResult.category === PageCategory.Dj) this.djSearch = true;
          if (filterResult.category === PageCategory.Live) this.liveSearch = true;
        } else {
          this._djSearch = false;
          this._liveSearch = false;
        }

        if (filterResult.category === PageCategory.Dj) this.djSearch = true;
        if (filterResult.category === PageCategory.Live) this.liveSearch = true;

        if (filterResult?.includesEquipment !== null) this.includesEquipment = filterResult.includesEquipment;
        this.doSearch();
      });
  }

  public closeFilterOverlay(): void {
    this.isLocationFilterOpen = false;
    this.isMusicTypeFilterOpen = false;
    this.isPerformerFilterOpen = false;

    this.doSearch();
  }
}
