<div>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px" fxHide.gt-md fxShow>
    <a
      fxFlex="grow"
      fxLayout="column"
      fxLayoutAlign="center"
      [routerLink]="['add']"
      class="gig-button primary"
      *ngIf="isBooker"
      ><span i18n>Post a gig</span></a
    >
    <div class="full-w" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="16px">
      <button fxFlex="grow" class="gig-button stroked filter-button-mobile" (click)="openFilter.emit()">
        <div selected fxFlex="grow" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <div class="icon-container">
            <mat-icon class="dropdown-icon" svgIcon="filter"></mat-icon>
          </div>
          <span class="title" i18n>Filter & Sort</span>
        </div>
      </button>
      <a
        fxFlex="grow"
        fxLayout="column"
        fxLayoutAlign="center"
        [routerLink]="['manage']"
        class="gig-button stroked"
        *ngIf="isBooker"
        ><span i18n>My gigs</span></a
      >
      <a
        fxFlex="grow"
        fxLayout="column"
        fxLayoutAlign="center"
        [routerLink]="['applications']"
        class="gig-button stroked"
        *ngIf="!isBooker"
        ><span i18n>My applications</span></a
      >
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="16px" fxShow.gt-md fxHide>
    <app-dropdown class="fixed-w location-dropdown" [autoClose]="false">
      <div
        selected
        fxFlex="grow"
        class="title-container dropdown-item"
        fxLayout="row"
        fxLayoutGap="12px"
        fxLayoutAlign="start center">
        <div class="icon-container">
          <mat-icon class="dropdown-icon" svgIcon="compass-thick"></mat-icon>
        </div>
        <span class="title">{{ getSelectedPlacesTitle() }}</span>
      </div>
      <div options fxFlex="grow" class="dropdown-item selections" fxLayout="column">
        <h4 class="dropdown-title" i18n>Provinces</h4>
        <app-places-check-list (locationsChanges)="onLocationsChanges($event)"></app-places-check-list>
      </div>
    </app-dropdown>
    <app-dropdown [autoClose]="false" class="fixed-w filter-dropdown">
      <div
        selected
        fxFlex="grow"
        class="title-container dropdown-item"
        fxLayout="row"
        fxLayoutGap="15px"
        fxLayoutAlign="start center">
        <div class="icon-container">
          <mat-icon class="dropdown-icon" svgIcon="filter"></mat-icon>
        </div>
        <span class="title" i18n>Filter & Sort</span>
      </div>
      <div options fxFlex="grow" class="dropdown-item selections" fxLayout="column">
        <h4 class="dropdown-title sort-title" i18n>Sort by</h4>
        <app-sort-dropdown (sortChanges)="onSortChanges($event)"></app-sort-dropdown>
        <h4 class="dropdown-title" i18n>By artist type</h4>
        <app-tag-buttons
          fxFlex="grow"
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutAlign="start center"
          [availableTags]="availableTags"
          (tagsChanges)="onTagsChanges($event)"></app-tag-buttons>
        <h4 class="dropdown-title" i18n>By gig type</h4>
        <app-type-buttons (typesChanges)="onTypesChanges($event)" [availableTypes]="availableTypes"></app-type-buttons>
      </div>
    </app-dropdown>
    <div class="button-height" fxFlex="column" fxLayoutAlign="center" fxFlex="nogrow" *ngIf="isBooker">
      <a [routerLink]="['manage']" class="gig-button stroked" i18n>My gigs</a>
    </div>
    <div class="button-height" fxFlex="column" fxLayoutAlign="center" fxFlex="nogrow" *ngIf="isBooker">
      <a [routerLink]="['add']" class="gig-button primary" i18n>Post a gig </a>
    </div>
    <div class="button-height" fxFlex="column" fxLayoutAlign="center" fxFlex="nogrow" *ngIf="!isBooker">
      <a [routerLink]="['applications']" class="gig-button stroked" i18n>My applications</a>
    </div>
  </div>
</div>
