<div class="container">
  <div fxLayout="column" class="not-found-container">
    <h1>404</h1>
    <h3 i18n>(Page not found)</h3>
    <br />
    <h2 i18n>Did not find what you were looking for?</h2>
    <h2 i18n
      >Browse <a [routerLink]="['/artists']">our artists</a> or
      <a [routerLink]="['/gigs/add']">post a gig</a> and let the artists find you instead.</h2
    >
  </div>
</div>
