import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetCurrentUserGQL, Gig, GigTemplate, User } from '../../../../generated/graphql';
import { COMPANY_REPLACE_STRING } from '../../../../globals';
import { apiToDate } from '../../../helpers';
import { getFirstAndLastSlotDates } from '../../../helpers/dates';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-previous-gig-item',
  templateUrl: './previous-gig-item.component.html',
  styleUrls: ['./previous-gig-item.component.scss'],
})
export class PreviousGigItemComponent implements OnInit {
  @Input()
  gig: Gig;

  @Input()
  template: GigTemplate;

  user: Observable<User>;

  firstDate: Date;
  lastDate: Date;
  dateState: 'single' | 'multi' | 'multiMonth' = 'single';

  // Export to template
  public COMPANY_REPLACE_STRING = COMPANY_REPLACE_STRING;

  constructor(private iconsService: IconsRegistryService, private getCurrentUser: GetCurrentUserGQL) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  ngOnInit(): void {
    this.user = this.getCurrentUser.watch().valueChanges.pipe(
      map((u) => {
        return u.data.me;
      }),
    );
    // Templates don't have dates
    if (!!this.gig) {
      this.firstDate = apiToDate(
        this.gig.slots.sort((a, b) => {
          return parseInt(a.date) - parseInt(b.date);
        })[0].date,
      );

      const { firstDate, lastDate } = getFirstAndLastSlotDates(this.gig.slots);
      this.firstDate = firstDate;
      this.lastDate = lastDate;

      if (this.firstDate.getTime() === this.lastDate.getTime()) {
        this.dateState = 'single';
      } else if (
        this.firstDate.getFullYear() === this.lastDate.getFullYear() &&
        this.firstDate.getMonth() === this.lastDate.getMonth()
      ) {
        // 1-2 månad
        this.dateState = 'multi';
      } else {
        // 1 månad - 2 månad
        this.dateState = 'multiMonth';
      }
    }
  }
}
