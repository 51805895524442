import { Component, Input, OnInit } from '@angular/core';
import { Page, PageCategory } from '../../../../generated/graphql';
import { PageCategoryTitle } from '../../../helpers/pages';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-artist-overview',
  templateUrl: './artist-overview.component.html',
  styleUrls: ['./artist-overview.component.scss'],
})
export class ArtistOverviewComponent implements OnInit {
  @Input() page: Page;

  public verifiedBookings = 0;

  public PAGE_CATEGORY_TITLE = PageCategoryTitle;
  public PAGE_CATEGORY = PageCategory;

  constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ARTIST, IconSubsets.FLAGS]);
  }

  public ngOnInit(): void {}
}
