export enum ArtistSortingValues {
  RelevanceDesc = 'RELEVANCY:DESC',
  PriceDesc = 'PRICE:DESC',
  PriceAsc = 'PRICE:ASC',
}

export interface ArtistSortingOption {
  name: string;
  value: ArtistSortingValues;
}
