<span hidden #fallbackTitle i18n="page title">Book artist, band, DJ | Entertainment for your event</span>
<span hidden #fallbackDescription i18n="page description"
  >Live music for kickoff, business party, officeparty. Over 100 professional artists. No middlemen. Book online in
  three steps.</span
>

<span hidden #types
  >BUSINESS:<span i18n="gig type|business">Corporate</span>,SHOPPING:<span i18n="gig type|shopping">Shopping/PR</span
  >,FESTIVAL:<span i18n="gig type|festival">Festival/Concert</span>,CLUB:<span i18n="gig type|club"
    >Entertainment/Club</span
  >,GALA:<span i18n="gig type|gala">Gala/Awards</span>,PRIDE:<span i18n="gig type|pride">Pride</span>,OTHERS:<span
    i18n="gig type|other"
    >Others</span
  >,PRIVATE:<span i18n="gig type|private">Private</span>,VENUE:<span i18n="gig type|venue">Venue</span></span
>
