<div
  fxLayoutAlign="stretch start"
  fxLayoutGap="120px"
  fxLayoutGap.lt-md="26px"
  fxLayout.lt-md="column"
  class="request-step container">
  <div fxFlex="316px" fxFlex.lt-md="auto" class="request-step--left">
    <h1 class="request-step--title m-b--20" i18n>Describe your event</h1>
    <p class="intro-text">
      <span i18n>Give the artists the necessary information about your event, be clear and descriptive.</span>&nbsp;
      <span fxHide.lt-md i18n>You can always edit the details later on.</span></p
    >
  </div>

  <div class="w--100per" *ngIf="page$ | async as page">
    <div fxLayout="row" fxLayoutGap="12px" class="artist-profile">
      <app-avatar
        class="artist-profile--avatar"
        [profilePicture]="page.profilePicture"
        [borderRadius]="'4px'"
        [specSize]="65"
        [square]="true"></app-avatar>

      <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="3px">
        <p class="artist-profile--name">{{ page.name }}</p>
        <p class="artist-profile--price">
          <span i18n>Artist from</span>&nbsp;
          <span class="f-weight--700">{{ page?.price | priceSpaceFormat }} SEK</span></p
        >
      </div>
    </div>

    <div>
      <form fxFlex="406px" fxLayout="column" fxLayoutGap="40px" fxFlex.lt-md="grow" [formGroup]="createRequestForm">
        <app-select-input
          label="What type of gig are you planning?"
          [selectOptions]="gigTypeOptions"
          formControlName="type"
          i18n-label></app-select-input>

        <div>
          <app-location-input
            [formattedAddress]="createRequestForm.value.formattedAddress"
            [invalid]="
              (createRequestForm.controls.place.touched || createRequestForm.controls.place.dirty) &&
              createRequestForm.controls.place.invalid
            "
            (onPlaceSelected$)="saveFormattedAddress($event)"
            placeholder="Location"
            locationType="[]"
            [label]="locationLabel"
            formControlName="place"
            i18n-placeholder>
          </app-location-input>
          <div
            *ngIf="
              (createRequestForm.controls.place.touched || createRequestForm.controls.place.dirty) &&
              createRequestForm.controls.place.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="createRequestForm.controls.place.errors?.required"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>Location is required.</span>
            </p>
          </div>
        </div>

        <div>
          <app-input
            formControlName="title"
            placeholder="Enter event title"
            [label]="titleLabel"
            [invalid]="
              (createRequestForm.controls.title.touched || createRequestForm.controls.title.dirty) &&
              createRequestForm.controls.title.invalid
            "
            i18n-placeholder></app-input>

          <div
            *ngIf="
              (createRequestForm.controls.title.touched || createRequestForm.controls.title.dirty) &&
              createRequestForm.controls.title.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="createRequestForm.controls.title.errors?.required"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>Title is required.</span>
            </p>
          </div>
        </div>

        <div>
          <app-input
            label="About the event"
            [multiLine]="true"
            [invalid]="
              (createRequestForm.controls.message.touched || createRequestForm.controls.message.dirty) &&
              createRequestForm.controls.message.invalid
            "
            [rows]="3"
            placeholder="We're looking for a music act that will gets this party going!"
            formControlName="message"
            i18n-placeholder
            i18n-label>
          </app-input>
          <div
            *ngIf="
              (createRequestForm.controls.message.touched || createRequestForm.controls.message.dirty) &&
              createRequestForm.controls.message.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="createRequestForm.controls.message.errors?.required"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>Information missing.</span>
            </p>
            <p
              fxLayoutAlign="start center"
              *ngIf="createRequestForm.controls.message.errors?.contactInfo"
              class="input-errors--message">
              <mat-icon svgIcon="required"></mat-icon>
              <span i18n>You'll be able to share contact information after the booking has been signed.</span>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
