import { Component, Input, OnInit } from '@angular/core';
import {
  Badge,
  BookingComponentItemType,
  NotificationFieldsFragmentDoc,
  NotificationTypeEnum,
  NotificationWithSubs,
  SetNotificationsReadGQL,
} from '../../../../generated/graphql';
import { LONG_DATE, timeSince } from '../../../helpers';
import {
  BadgeNotification,
  BookingNotification,
  BookingNotificationStatus,
  ChannelNotification,
  ChannelNotificationStatus,
  GenericNotification,
  GenericNotificationStatus,
  GigNotification,
  GigNotificationStatus,
  PageNotification,
  PageNotificationStatus,
} from '../../../notificationTemplates';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnInit {
  @Input()
  notification: NotificationWithSubs;

  @Input()
  borderRadius = true;

  senderName = '';
  timeSince = '';
  url = '';
  iconName = 'priority_high';
  iconColor = '';

  notificationType = NotificationTypeEnum;
  bookingNotificationStatus = BookingNotificationStatus;
  channelNotificationStatus = ChannelNotificationStatus;
  gigNotificationStatus = GigNotificationStatus;
  pageNotificationStatus = PageNotificationStatus;
  genericNotificationStatus = GenericNotificationStatus;
  componentTypes = BookingComponentItemType;
  badges = Badge;
  subs = 0;

  dateFormat = LONG_DATE;

  BadgeTranslations = {
    [Badge.Bookings_10]: $localize`10 Bookings`,
    [Badge.Bookings_25]: $localize`25 Bookings`,
    [Badge.Bookings_50]: $localize`50 Bookings`,
    [Badge.Bookings_75]: $localize`75 Bookings`,
    [Badge.Bookings_100]: $localize`100 Bookings`,
    [Badge.FastResponder]: $localize`Fast Responder`,
  };
  get displayName() {
    return this.notification.notification.page ? this.notification.notification.page.name : this.senderName;
  }

  constructor(private setNotificationRead: SetNotificationsReadGQL) {}

  ngOnInit() {
    this.senderName = `${this.notification.notification.sendingUser.firstName} ${this.notification.notification.sendingUser.lastName}`;
    if (typeof this.notification.notification.meta == 'string') {
      this.notification.notification.meta = JSON.parse(this.notification.notification.meta);
    }
    this.timeSince = timeSince(new Date(this.notification.notification.createdDate));
    this.parseNotification();
  }

  onNavigate() {
    const oldNote = JSON.parse(JSON.stringify(this.notification.notification));
    if (
      !this.notification.notification.isRead ||
      (!!this.notification.subs && !!this.notification.subs.find((n) => !n.isRead))
    ) {
      this.setNotificationRead
        .mutate(
          { notifications: [this.notification.notification.id, ...this.notification.subs.map((n) => n.id)] },
          {
            update: (store, { data }) => {
              oldNote.isRead = true;
              store.writeFragment({
                fragment: NotificationFieldsFragmentDoc,
                id: `Notification:${this.notification.notification.id}`,
                fragmentName: 'notificationFields',
                data: oldNote,
              });
            },
          },
        )
        .subscribe();
    }
  }

  private parseNotification() {
    switch (this.notification.notification.type) {
      case NotificationTypeEnum.Generic:
        this.iconName = 'assignment';
        this.url = `/bookings/${(this.notification.notification.meta as GenericNotification).data.bookingId}/review`;
        this.iconColor = 'yellow';
        // TODO: Make parse function when more than 1 type of generic notifications
        break;
      case NotificationTypeEnum.Booking:
        this.iconName = 'assignment';
        this.parseBookingNotification(this.notification.notification.meta);
        this.url = `/bookings/${(this.notification.notification.meta as BookingNotification).bookingId}`;
        break;
      case NotificationTypeEnum.Channel:
        this.parseChannelNotification(this.notification.notification.meta);
        this.url = `/bookings/${(this.notification.notification.meta as ChannelNotification).bookingId}`;
        this.iconName = 'chat_bubble';
        break;
      case NotificationTypeEnum.Gig:
        this.iconName = 'assignment';
        this.parseGigNotification(this.notification.notification.meta);
        this.url = `/gigs/${(this.notification.notification.meta as GigNotification).gigId}`;
        break;
      case NotificationTypeEnum.Page:
        this.url = `/artists/${this.notification.notification.page.slug}/edit`;
        this.parsePageNotification(this.notification.notification.meta);
        break;
      case NotificationTypeEnum.Organization:
        // TODO: Parse organization notifications
        break;
      case NotificationTypeEnum.Badge:
        this.url = `/artists/${this.notification.notification.page.slug}`;
        this.parseBadgeNotification(this.notification.notification.meta);
      default:
        break;
    }
  }

  private parsePageNotification(meta: PageNotification) {
    switch (meta.status) {
      case PageNotificationStatus.REVIEW_RECEIVED:
        this.iconName = 'assignment';
        this.iconColor = 'green';
        this.url = `/artists/${this.notification.notification.page.slug}`;
        break;
      case PageNotificationStatus.REVIEWED_APPROVED:
      case PageNotificationStatus.REVIEWED_DENIED:
      default:
        // this.senderName = meta.meta.pageName;
        this.iconName = 'assignment';
        this.iconColor = 'yellow';
        break;
    }
  }

  private parseBookingNotification(meta: BookingNotification) {
    switch (meta.status) {
      case BookingNotificationStatus.ADDED:
        this.iconColor = 'green';
        break;
      case BookingNotificationStatus.CANCELLED:
        this.iconName = 'assignment_late';
        this.iconColor = 'red';
        break;
      case BookingNotificationStatus.DECLINED:
        this.iconName = 'assignment_late';
        this.iconColor = 'red';
        break;
      case BookingNotificationStatus.COMPONENT_ADDED:
      case BookingNotificationStatus.COMPONENT_MODIFIED:
      case BookingNotificationStatus.COMPONENT_REMOVED:
        this.iconColor = 'yellow';
        break;
      case BookingNotificationStatus.LEGACY_MODIFIED:
        this.iconColor = 'yellow';
        break;
      case BookingNotificationStatus.COMPONENT_SIGNED:
        this.iconColor = 'green';
        break;

      case BookingNotificationStatus.LEGACY_ACCEPTED:
        this.iconColor = 'green';
        break;
      case BookingNotificationStatus.SIGNED:
        this.iconName = 'assignment_turned_in';
        this.iconColor = 'green';
        break;
    }
  }
  private parseChannelNotification(meta: ChannelNotification) {
    switch (meta.status) {
      case ChannelNotificationStatus.INVITED_TO:
        break;
      case ChannelNotificationStatus.MESSAGE_ADDED:
        this.subs = this.notification.subs.length;
        this.iconColor = 'blue';
        break;
    }
  }
  private parseGigNotification(meta: GigNotification) {
    switch (meta.status) {
      case GigNotificationStatus.APPLICATION:
        this.iconName = 'assignment_ind';
        this.iconColor = 'green';
        break;
      case GigNotificationStatus.SLOT_ASSIGNED:
      case GigNotificationStatus.APPLICATION_DENIED:
        this.iconName = 'assignment_late';
        this.iconColor = 'yellow';
        break;
      case GigNotificationStatus.TIME_EXPIRED:
      case GigNotificationStatus.TIME_UPDATE:
        break;
    }
  }

  private parseBadgeNotification(meta: BadgeNotification) {
    this.iconColor = 'black';
  }
}
