import { Pipe, PipeTransform } from '@angular/core';
import { apiToDate } from '../helpers';

@Pipe({
  name: 'gigDuration',
})
export class GigDurationPipe implements PipeTransform {
  transform(startTime: string, endTime: string): string {
    const start = apiToDate(startTime);
    const end = apiToDate(endTime);

    const durationMinutes = Math.floor((end.getTime() - start.getTime()) / (1000 * 60));
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;

    let durationString = '';

    if (hours > 0) {
      durationString += hours + ' h';
      if (minutes > 0) {
        durationString += ' ' + minutes + ' min';
      }
    } else {
      durationString = minutes + ' min';
    }

    return durationString;
  }
}
