<form *ngIf="gig | async as gig" [formGroup]="editGroup" class="apply-container" fxLayout="column">
  <a fxHide fxShow.gt-md [disabled]="loading" [routerLink]="['/gigs', gig.id]" class="back-button cursor-pointer"
    ><img src="assets/icons/navigation/expand-left.svg"
  /></a>

  <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="start center" class="page-title">
    <a
      fxShow
      fxHide.gt-md
      [disabled]="loading"
      [routerLink]="['/gigs', gig.id]"
      class="back-button-inline cursor-pointer"
      ><img src="assets/icons/navigation/expand-left.svg"
    /></a>
    <h1 i18n>Edit gig details</h1>
  </div>
  <div fxLayout.lt-md="column" fxLayout="row" fxLayoutGap="112px" fxLayoutGap.lt-md="32px">
    <div fxLayout="column" fxLayoutGap="32px" fxFlex="50%" class="edit-column">
      <div fxLayout="column">
        <label class="action-label" i18n>Gig type</label>
        <app-dropdown formControlName="type">
          <div selected class="dropdown-item full-w" fxLayout="row" fxLayoutAlign="space-between">
            <span>{{ editGroup.get('type').value.translation }}</span>
          </div>
          <div
            options
            class="dropdown-item"
            *ngFor="let item of types"
            fxLayout="row"
            fxLayoutAlign="space-between"
            (click)="selectItem(item, $event)">
            <span>{{ item.translation }}</span>
          </div>
        </app-dropdown>
      </div>
      <div fxLayout="column">
        <label class="action-label" i18n>Location</label>
        <input
          class="gig-text-input"
          matGoogleMapsAutocomplete
          [formControl]="placeId"
          placeholder="Location"
          i18n-placeholder="@@location"
          (onAutocompleteSelected)="onAutocompleteSelected($event)"
          #placeIdInput />
      </div>
      <div fxLayout="column">
        <label class="action-label" i18n>Name your gig</label>
        <app-gig-form-text-input [maxChars]="40" placeholder="Enter name" i18n-placeholder formControlName="name">
        </app-gig-form-text-input>
      </div>
      <div fxLayout="column">
        <label class="action-label" i18n>Describe your gig</label>
        <textarea formControlName="description" class="gig-text-input" rows="5"></textarea>
        <mat-error [ngClass]="{ invisible: !description.hasError('contactInfo') }" i18n>
          Contact information can be shared in a later stage</mat-error
        >
      </div>

      <div fxLayout="column" fxLayoutGap="16px">
        <label class="action-label" i18n>Available equipment</label>
        <div fxLayout="row" class="equipment-checks" fxLayoutGap="24px" fxLayoutGap.lt-md="48px">
          <div fxLayout.lt-md="column" fxLayoutGap.lt-md="16px" fxLayout="row" fxLayoutGap="24px">
            <mat-checkbox i18n color="primary" formControlName="suppliesPa">PA System</mat-checkbox>
            <mat-checkbox i18n color="primary" formControlName="suppliesSpeakers">Speakers</mat-checkbox>
            <mat-checkbox i18n color="primary" formControlName="suppliesMixer">Mixer</mat-checkbox>
          </div>
          <mat-checkbox i18n color="primary" formControlName="suppliesNone">None</mat-checkbox>
        </div>
        <div fxFlexAlign="start" class="m-t--20">
          <label fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-checkbox-input formControlName="isRequestingSupplies"></app-checkbox-input>
            <span i18n>Request artist to bring missing equipment for a fee</span>
          </label>
        </div>
      </div>
    </div>
    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap.lt-md="64px">
      <div fxLayout="column" fxLayoutGap="42px">
        <div fxLayout="column" fxLayoutGap="12px">
          <div fxHide.lt-md fxShow class="main-image-container">
            <app-image-upload-area
              (imageRemoved)="imageRemoved($event)"
              (imageSelected)="imageSelected($event)"
              [uploadedImage]="images[0]">
              <img src="assets/icons/image-upload.svg" />
            </app-image-upload-area>
          </div>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign.lt-md="space-evenly">
            <div fxShow.lt-md fxHide class="image-container small">
              <app-image-upload-area
                (imageRemoved)="imageRemoved($event)"
                (imageSelected)="imageSelected($event)"
                [uploadedImage]="images[0]">
                <img src="assets/icons/actions/image-upload.svg" />
              </app-image-upload-area>
            </div>
            <app-image-upload-area
              (imageRemoved)="imageRemoved($event)"
              (imageSelected)="imageSelected($event)"
              class="image-container small"
              [uploadedImage]="images[1]">
              <img class="preview-icon" src="assets/icons/actions/image-upload.svg" />
            </app-image-upload-area>
            <app-image-upload-area
              (imageRemoved)="imageRemoved($event)"
              (imageSelected)="imageSelected($event)"
              class="image-container small"
              [uploadedImage]="images[2]">
              <img class="preview-icon" src="assets/icons/actions/image-upload.svg" />
            </app-image-upload-area>
          </div>
        </div>
        <div class="info-box" fxLayout="row" fxLayoutGap="8px">
          <img class="info-icon" src="assets/icons/actions/info-fill.svg" />
          <div>
            <p i18n
              >Note that you edit slot details (date,budget,act) by clicking on ‘edit slot’ icon on previous page
            </p>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="32px">
        <a [routerLink]="['/gigs', gig.id]" class="cursor-pointer back-link" i18n [disabled]="loading">Cancel</a>
        <button
          class="gig-button primary"
          (click)="updateGig()"
          [disabled]="loading || !editGroup.valid"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="8px"
          ><span i18n>Update gig details</span>
          <mat-spinner *ngIf="loading" [diameter]="14"></mat-spinner>
        </button>
      </div>
    </div>
  </div>
</form>
