<a
  fxHide.lt-md
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="data-item"
  [routerLink]="['/bookings', booking.id]">
  <div fxFlex="25%" fxLayout="row" fxLayoutGap="16px">
    <app-avatar
      *ngIf="userType === USER_TYPE.Booker"
      [user]="booking.user"
      [profilePicture]="booking.page.profilePicture"
      [medium]="true"></app-avatar>

    <div *ngIf="userType !== USER_TYPE.Booker">
      <div class="image-container">
        <app-cloud-image
          *ngIf="booking.appliedTo?.images?.length > 0"
          class="booking-image"
          [profilePicture]="booking.appliedTo.images[0]"
          [altText]="booking.appliedTo.name + 's image'"
          [maxWidth]="60"
          i18n-altText></app-cloud-image>
        <app-fallback-image
          *ngIf="!booking.appliedTo?.images || booking.appliedTo?.images.length === 0"
          class="booking-image"
          [gig]="booking"
          [borderRadius]="'8px'"
          [textSize]="'6px'"></app-fallback-image>
      </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center">
      <span *ngIf="userType === USER_TYPE.Booker" class="booking-name overflow-ellipsis">{{ booking.page.name }}</span>
      <span *ngIf="userType !== USER_TYPE.Booker" class="booking-name overflow-ellipsis">{{ m_bookingName }}</span>
      <div fxLayout="row" fxLayoutGap="6px">
        <span *ngIf="isCancelled" class="status-label bg-pastel-red" i18n>Cancelled</span>

        <span
          *ngIf="
            (userType === USER_TYPE.Booker && booking.newTypes.includes(NEW_TYPES.RequestBooker)) ||
            (userType !== USER_TYPE.Booker && booking.newTypes.includes(NEW_TYPES.RequestArtist))
          "
          class="status-label bg-pastel-violet"
          i18n>
          New
        </span>
      </div>
    </div>
  </div>

  <div fxFlex="25%" fxLayout="row" fxLayoutGap="6px">
    <app-gig-date [dateFormat]="DATE_FORMAT" [date]="m_firstBookingDate.date"></app-gig-date>
    <span *ngIf="booking.dates.length > 1 && m_acceptedDates.length <= 0"
      >+ {{ booking.dates.length - 1 }} <span i18n>more</span></span
    >
    <span *ngIf="m_acceptedDates.length > 1">+ {{ m_acceptedDates.length - 1 }} <span i18n>more</span></span>
  </div>

  <div *ngIf="userType === USER_TYPE.Booker" fxFlex="25%" fxLayout="column">
    <a class="gray-13 overflow-ellipsis">{{ m_bookingName }}</a>
  </div>

  <div *ngIf="userType != USER_TYPE.Booker" fxFlex="25%" fxLayout="column">
    <a class="gray-13" *ngIf="booking.dates.length > 0"
      >{{ booking.calculatedPricing.artist.final }} {{ booking.calculatedPricing.currency }}</a
    >
  </div>

  <a
    fxFlex="25%"
    fxLayout="row"
    fxLayoutAlign="space-between"
    class="gray-1"
    [routerLink]="['/bookings', booking.id]"
    [ngSwitch]="status">
    <ng-container *ngSwitchCase="'waitingForAcceptingDates'">
      <span *ngIf="userType === USER_TYPE.Booker" i18n>Waiting for counterpart</span>
      <span *ngIf="userType !== USER_TYPE.Booker" i18n>Waiting for you</span>
    </ng-container>

    <ng-container *ngSwitchCase="'waitingOther'">
      <span i18n>Waiting for counterpart</span>
    </ng-container>

    <ng-container *ngSwitchCase="'waitingMe'">
      <span i18n>Waiting for you</span>
    </ng-container>

    <ng-container *ngSwitchCase="'new'">
      <span i18n>Waiting for you</span>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span i18n>See details</span>
    </ng-container>

    <mat-icon class="arrow-icon" svgIcon="expand-left"></mat-icon>
  </a>
</a>

<!-- mobile view -->
<a
  fxHide
  fxShow.lt-md
  fxLayout="row"
  fxLayoutGap="8px"
  fxLayoutAlign="space-between stretch"
  class="data-item mobile"
  [routerLink]="['/bookings', booking.id]">
  <app-avatar
    *ngIf="userType === USER_TYPE.Booker"
    fxFlex="80px"
    [profilePicture]="booking.page.profilePicture"
    [big]="true"></app-avatar>

  <div *ngIf="userType !== USER_TYPE.Booker">
    <div class="image-container">
      <app-cloud-image
        *ngIf="booking.appliedTo?.images?.length > 0"
        class="booking-image"
        [profilePicture]="booking.appliedTo.images[0]"
        [altText]="booking.appliedTo.name + 's image'"
        [maxWidth]="60"
        i18n-altText></app-cloud-image>
      <app-fallback-image
        *ngIf="!booking.appliedTo?.images || booking.appliedTo?.images.length === 0"
        class="booking-image"
        [gig]="booking"
        [borderRadius]="'8px'"
        [textSize]="'6px'"></app-fallback-image>
    </div>
  </div>

  <div fxFlex="grow" fxLayout="column" fxLayoutAlign="start ">
    <span *ngIf="userType === USER_TYPE.Booker" class="booking-name mobile">{{ booking.page.name }}</span>
    <span *ngIf="userType !== USER_TYPE.Booker" class="booking-name mobile">{{ m_bookingName }}</span>

    <div fxLayout="column" class="m-b--4">
      <app-gig-date [dateFormat]="DATE_FORMAT" [date]="m_firstBookingDate.date"></app-gig-date>
    </div>

    <div>
      <span *ngIf="isCancelled" class="status-label bg-pastel-red" i18n>Cancelled</span>

      <span
        *ngIf="
          (userType === USER_TYPE.Booker && booking.newTypes.includes(NEW_TYPES.RequestBooker)) ||
          (userType !== USER_TYPE.Booker && booking.newTypes.includes(NEW_TYPES.RequestArtist))
        "
        class="status-label bg-pastel-violet"
        i18n>
        New
      </span>
    </div>
  </div>

  <mat-icon class="arrow-icon mobile" svgIcon="expand-left"></mat-icon>
</a>
