import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GigType, PageList } from '../../../../generated/graphql';
import { getMetaFromGigType, GigMeta } from '../../../helpers/gigs';
import { TranslationsService } from '../../../services/translations.service';

@Component({
  selector: 'app-editorial-list',
  templateUrl: './editorial-list.component.html',
  styleUrls: ['./editorial-list.component.scss'],
})
export class EditorialListComponent implements OnInit {
  @Input()
  gigType: GigType;

  @Input()
  list: PageList;

  @Input()
  obsList: Observable<PageList>;

  @Input()
  showDescription = true;

  @Input()
  showTitle = true;

  @Input()
  split = false;

  iList: PageList;

  meta: GigMeta;

  constructor(public translations: TranslationsService) {}

  ngOnInit() {
    if (this.gigType) {
      this.meta = getMetaFromGigType(this.gigType);
    }

    if (this.obsList) {
      this.obsList
        .pipe(
          map(pl => {
            this.iList = pl;
            if (pl.gigType) {
              this.gigType = pl.gigType;
              this.meta = getMetaFromGigType(this.gigType);
            }
            return pl;
          }),
        )
        .subscribe();
    } else {
      this.iList = this.list;
    }
  }
}
