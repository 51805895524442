<app-info-bar
  *ngIf="pageProgressStep.denied"
  [infoType]="INFO_BAR_TYPE.Warning"
  [text]="pageProgressStep.denyReason"></app-info-bar>

<section class="d--block container container-right">
  <h1 class="h6 f-weight--600 m-b--15" i18n>FAQ (optional)</h1>
  <p i18n>Add FAQs to your profile to help customers understand the service you provide.</p>
  <p class="m-b--64" i18n>We have selected some of the most common questions.</p>

  <div
    *ngIf="!isLoading && pageFaqTemplates$ | async as questionTemplates; else loadingTmplt"
    fxLayout="column"
    fxLayoutGap="16px"
    fxLayoutGap.lt-md="25px"
    class="m-b--50">
    <app-artist-faq-item
      *ngFor="let answer of page?.faq; let index = index"
      [questionNumber]="index + 1"
      [pageFaqAnswer]="answer"
      (saveFaqAnswers$)="updatePageFaqAnswer($event)"
      (removeFaqAnswer$)="removePageFaqAnswer($event)"></app-artist-faq-item>
    <ng-container *ngFor="let questionTemplate of questionTemplates; let index = index">
      <app-artist-faq-item
        *ngIf="!isAnswerExist(questionTemplate)"
        [questionNumber]="page.faq?.length + index + 1"
        [isAnswerExist]="isAnswerExist(questionTemplate)"
        [questionTemplate]="questionTemplate"
        (saveFaqAnswers$)="updatePageFaqAnswer($event)"></app-artist-faq-item>
    </ng-container>
  </div>

  <div class="m-t--32">
    <button fxLayoutAlign="start center" class="gig-button transparent wide" (click)="goToStep$.emit(-1)">
      <mat-icon fxHide.lt-sm svgIcon="back-arrow"></mat-icon>
      <span i18n>Back</span>
    </button>
  </div>
  <div fxHide fxShow.lt-sm class="step-number">
    <span fxFlex class="text-center">{{ PROGRESS_STEP_NUMBER.FAQ }} <span i18n>of</span> 5</span>
  </div>
</section>

<ng-template #loadingTmplt>
  <div fxLayout fxLayoutAlign="center center" class="height--100per">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="48px">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template>
