<app-modal [title]="title" [hideSave]="true">
  <span *ngIf="contentfulData | async as data">
    <div class="gratification-image">
      <cl-image class="centered" [public-id]="data.image[0].public_id"> </cl-image>
    </div>
    <h2>{{data.secondaryTitle}}</h2>
    <div [innerHtml]="richTextToHtml(data.body)"></div>
    <div>
      <a mat-flat-button color="primary" [routerLink]="[data.ctAs.fields.url]"
        mat-dialog-close>{{data.ctAs.fields.title}}</a>
    </div>
  </span>
</app-modal>
