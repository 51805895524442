import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { map } from 'rxjs/operators';
import { ContentfulArtistItem, GetArtistsListsGQL } from '../../../generated/graphql';
import { extractContentfulArtistsLists, MappedContentfulArtistList } from '../../../utils/contentful';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-artists-home',
  templateUrl: './artists-home.component.html',
  styleUrls: ['./artists-home.component.scss'],
})
export class ArtistsHomeComponent implements OnInit {
  private pageId = '68KprY7OyC6VSTH2So4hYe';
  private lists: MappedContentfulArtistList[] = [];

  heros: ContentfulArtistItem[] = [];
  blocks: any[] = [];
  createGigInfoBlock: any;
  isLoading = true;

  constructor(
    private getArtistsLists: GetArtistsListsGQL,
    private translations: TranslationsService,
    private sanitizer?: DomSanitizer,
  ) {}

  ngOnInit() {
    this.getArtistsLists
      .fetch({ id: this.pageId, locale: this.translations.getCurrentLocale() })
      .pipe(map(d => d.data))
      .subscribe(c => {
        this.lists = extractContentfulArtistsLists(
          c.getContentfulEntry.contentfulData.fields.blocks,
          c.getContentfulEntry.artistLists,
        );
        if (c.getContentfulEntry.artistHeros) {
          this.heros = c.getContentfulEntry.artistHeros;
        }

        this.blocks = c.getContentfulEntry.contentfulData.fields.blocks;
        this.createGigInfoBlock = c.getContentfulEntry.contentfulData.fields.blocks.find(b => this.isInfo(b));

        this.isLoading = false;
      });
  }

  getMappedList(list: any) {
    return this.lists.find(l => l.list.listId === list.sys.id);
  }
  isList(block: any) {
    return block.sys.contentType.sys.id === 'artistList';
  }
  isInfo(block: any) {
    return block.sys.contentType.sys.id === 'infoBlock';
  }
  toHTMLString(doc: any) {
    return this.sanitizer.sanitize(SecurityContext.HTML, documentToHtmlString(doc));
  }
}
