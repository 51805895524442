import { Component, Input } from '@angular/core';
import { PageFaqAnswer } from '../../../../generated/graphql';

@Component({
  selector: 'app-artist-faq-question',
  templateUrl: './artist-faq-question.component.html',
  styleUrls: ['./artist-faq-question.component.scss'],
})
export class ArtistFaqQuestionComponent {
  @Input() pageFaqAnswer: PageFaqAnswer;
}
