<div class="review-card">
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.lt-sm="center center"
    fxLayoutGap="16px"
    fxLayoutGap.lt-sm="18px">
    <div
      *ngIf="reviewPage.profilePicture?.fileName; else defaultPagePicture"
      fxFlex="100px"
      fxFlex.lt-sm="165px"
      class="profile-image"
      [style.background-image]="m_safeCssUrl"
      [style.background-position-x]="'50%'"
      [style.background-position-y]="'50%'"
      [style.background-size]="'cover'">
    </div>

    <div fxFlex="grow" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center" class="review-form">
      <div fxFlex="34%" fxFlex.lt-md="25%" fxLayout="column" fxLayoutGap="1px">
        <h3 class="page-name">{{ reviewPage.name }}</h3>
        <span fxHide.lt-sm *ngIf="reviewPage.tags">{{ getArtistTypeTags(reviewPage.tags) }}</span>
      </div>

      <form
        class="form"
        fxFlex="grow"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-sm="start stretch"
        [formGroup]="form"
        (submit)="postReview()">
        <div fxFlex="grow" fxLayout="column" fxLayoutAlign.gt-md="start start">
          <p *ngIf="!isEditMode" class="form-text m-b--15" i18n>Would you recommend this artist?</p>
          <mat-button-toggle-group
            formControlName="recommended"
            fxLayoutGap="6px"
            [fxHide.lt-md]="isEditMode"
            fxLayoutAlign.lt-sm="center center"
            fxLayoutGap.lt-sm="10px"
            [ngClass]="{ edit: isEditMode }">
            <mat-button-toggle fxLayoutAlign.lt-sm="center center" (change)="quickReview()" value="true">
              <mat-icon class="material-icons" svgIcon="thumb-up"></mat-icon>
            </mat-button-toggle>
            <mat-button-toggle fxLayoutAlign.lt-sm="center center" (change)="quickReview()" value="false">
              <mat-icon class="material-icons" svgIcon="thumb-down"></mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div
          fxFlex="335px"
          fxFlex.lt-md="grow"
          fxLayout.lt-sm="column"
          fxLayoutAlign.lt-sm="start stretch"
          *ngIf="isEditMode">
          <p class="form-text m-b--10" i18n>How was your experience?</p>
          <textarea
            fxHide.lt-sm
            class="gig-text-input m-b--15"
            formControlName="message"
            cdkTextareaAutosize
            cdkAutosizeMinRows="2"
            placeholder="Write a review of artist performance"
            i18n-placeholder></textarea>

          <textarea
            fxHide
            fxShow.lt-sm
            class="gig-text-input m-b--15"
            formControlName="message"
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            placeholder="Write a review of artist performance"
            i18n-placeholder></textarea>

          <button class="gig-button primary f-size--16 w--100per" i18n [disabled]="form.invalid">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #defaultPagePicture>
  <img fxFlex="100px" fxFlex.lt-sm="165px" class="profile-image" src="assets/images/default-avatar-rect.svg" alt="" />
</ng-template>
