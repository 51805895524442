<app-info-bar [infoType]="INFO_BAR_TYPE.InfoMessage" [text]="INFO_BAR_TEXT"></app-info-bar>
<app-info-bar
  *ngIf="pageProgressStep.denied"
  [infoType]="INFO_BAR_TYPE.Warning"
  [text]="pageProgressStep.denyReason"></app-info-bar>

<div *ngIf="allTagsList && !isLoading; else loadingTmplt" class="d--block container container-right">
  <h1 class="h6 f-weight--600" i18n>Tags</h1>

  <div *ngIf="musicTypeTags.length > 0" class="tags-section">
    <h2 class="subtitle-1 f-weight--600 m-b--28" i18n>Type of music</h2>
    <div fxLayoutGap="9px" class="tags-chip-list">
      <ng-container *ngFor="let tag of musicTypeTags">
        <button (click)="onTagClick(tag)" [ngClass]="{ selected: tag.isSelected }" class="tags-chip-list--item">
          {{ tag.translatedName }}
        </button>
      </ng-container>
    </div>
  </div>

  <div *ngIf="typeOfMusicianTags.length > 0 && isMusicTypeTagsSelected" class="tags-section">
    <h2 class="subtitle-1 f-weight--600 m-b--28" i18n>Type of musician</h2>
    <div class="tags-chip-list">
      <div fxFlex="325px" fxFlex.lt-md="100" fxLayoutGap="9px">
        <ng-container *ngFor="let tag of typeOfMusicianTags">
          <button
            *ngIf="isTagAllowed(tag)"
            (click)="onTagClick(tag)"
            [ngClass]="{ selected: tag.isSelected }"
            class="tags-chip-list--item">
            {{ tag.translatedName }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="page?.category === PAGE_CATEGORY.Live">
    <ng-container *ngTemplateOutlet="instrumentsTagsTmpl"></ng-container>
  </ng-container>

  <div *ngIf="genreTags.length > 0" class="tags-section last">
    <h2 class="subtitle-1 f-weight--600 m-b--28" i18n>Genres</h2>
    <div class="tags-chip-list">
      <div fxFlex="560px" fxFlex.lt-md="100" fxLayoutGap="9px">
        <button
          *ngFor="let tag of genreTags"
          (click)="onTagClick(tag)"
          [ngClass]="{ selected: tag.isSelected }"
          class="tags-chip-list--item">
          {{ tag.translatedName }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="subGenreTags.length > 0 && isSubGenresAvailable" class="tags-section last">
    <h2 class="subtitle-1 f-weight--600 m-b--28" i18n>Sub-genres</h2>
    <div class="tags-chip-list">
      <div fxFlex="560px" fxFlex.lt-md="100" fxLayoutGap="9px">
        <ng-container *ngFor="let tag of subGenreTags">
          <button
            *ngIf="isParentSelected(tag)"
            (click)="onTagClick(tag)"
            [ngClass]="{ selected: tag.isSelected }"
            class="tags-chip-list--item">
            {{ tag.translatedName }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="page?.category === PAGE_CATEGORY.Dj">
    <ng-container *ngTemplateOutlet="instrumentsTagsTmpl"></ng-container>
  </ng-container>

  <div fxLayoutAlign="space-between center" class="m-t--32">
    <button fxLayoutAlign="start center" class="gig-button transparent wide" (click)="goToStep$.emit(-1)">
      <mat-icon fxHide.lt-sm svgIcon="back-arrow"></mat-icon>
      <span i18n>Back</span>
    </button>
    <span fxFlex fxHide fxShow.lt-sm class="text-center">{{ PROGRESS_STEP_NUMBER.TAGS }} <span i18n>of</span> 5</span>
    <button class="gig-button primary wide" (click)="updatePageTags()" i18n>Next</button>
  </div>
</div>

<ng-template #loadingTmplt>
  <div fxLayout fxLayoutAlign="center center" class="height--100per">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="48px">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template>

<ng-template #instrumentsTagsTmpl>
  <div *ngIf="instrumentsTags.length > 0" class="tags-section last">
    <h2 class="subtitle-1 f-weight--600 m-b--4" i18>
      <ng-container i18n>Instruments (optional)</ng-container>
    </h2>
    <p class="m-b--28" i18n>State instrument that can be played live in your performance.</p>

    <div class="tags-chip-list">
      <div fxFlex="560px" fxFlex.lt-md="100" fxLayoutGap="9px">
        <button
          *ngFor="let tag of instrumentsTags"
          (click)="onTagClick(tag)"
          [ngClass]="{ selected: tag.isSelected }"
          class="tags-chip-list--item">
          {{ tag.translatedName }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
