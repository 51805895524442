import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import {
  Booking,
  BookingDates,
  BookingInput,
  EquipmentStatus,
  GetBookingByIdGQL,
  GetCurrentUserGQL,
  GetPaymentMethodsGQL,
  Signature,
  SignBookingGQL,
  UpdateBookingGQL,
  User,
} from '../../../generated/graphql';
import { amIBooker, haveISignedBooking } from '../../bookings/bookingUtils';
import { LONG_DATE } from '../../helpers';
import { displayableFee, getBookingPrice, getFeeAmount } from '../../helpers/bookings';
import { timestampToTimeString } from '../../helpers/dates';
import { AnalyticsService } from '../../services/analytics.service';
import { IconsRegistryService, IconSubsets } from '../../services/icons-registry.service';
import { TranslationsService } from '../../services/translations.service';
@Component({
  selector: 'app-booking-sign',
  templateUrl: './booking-sign.component.html',
  styleUrls: ['./booking-sign.component.scss'],
})
export class BookingSignComponent implements OnInit {
  @ViewChild('stripeCard') stripeCard: StripeCardComponent;

  booking: Observable<Booking>;
  currentUser: Observable<User>;

  bookerSignature: Signature;
  artistSignature: Signature;

  loadingSign = false;
  bookingId: number;
  dateFormat = LONG_DATE;
  currentUserId: number;
  feePerc = 0;

  isLoading: boolean = true;

  timestampToTimeString = timestampToTimeString;
  getBookingPrice = getBookingPrice;
  haveISigned = haveISignedBooking;
  displayableFee = displayableFee;
  getFeeAmount = getFeeAmount;
  abs = Math.abs;
  amIBooker: boolean;

  acceptedDates: BookingDates[];

  public POLICY_LINK = $localize`http://help.gigital.se/en/articles/3350100-payment-policy`;
  public EQUIPMENT_STATUS = EquipmentStatus;

  cardGroup = new FormGroup({
    personName: new FormControl('', [Validators.required]),
  });

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'sv',
  };

  paymentGroup = new FormGroup({
    invoice: new FormControl(false),
  });

  paymentMethods: Observable<any[]>;
  hasPaymentMethods: boolean;

  constructor(
    private iconService: IconsRegistryService,
    private route: ActivatedRoute,
    private getBooking: GetBookingByIdGQL,
    private getCurrentUser: GetCurrentUserGQL,
    private signBooking: SignBookingGQL,
    private snackbar: MatSnackBar,
    private analytics: AnalyticsService,
    public translations: TranslationsService,
    private updateBooking: UpdateBookingGQL,
    private stripeService: StripeService,
    private getPaymentMethods: GetPaymentMethodsGQL,
  ) {
    this.iconService.registerIcons([IconSubsets.NAVIGATION, IconSubsets.EQUIPMENT]);
  }

  ngOnInit(): void {
    this.route.firstChild.firstChild.params.subscribe((params) => {
      this.setBooking(parseInt(params.bookingId, 10));
    });

    this.updatePaymentMethods();

    this.booking.pipe(first()).subscribe((b) => {
      this.paymentGroup.get('invoice').setValue(b.wantsInvoice);
      this.paymentGroup.get('invoice').valueChanges.subscribe((val) => {});
    });
  }

  updatePaymentMethods() {
    this.paymentMethods = this.getPaymentMethods.fetch().pipe(
      map(({ data }) => data.getPaymentMethods),
      tap((v) => {
        this.hasPaymentMethods = v.length > 0;
      }),
    );
  }

  createStripeToken() {
    const name = this.cardGroup.get('personName').value;
    this.stripeService.createToken(this.stripeCard.element, { name }).subscribe((res) => {
      if (res.token) {
        console.log(res);
      } else if (res.error) {
        console.error(res.error.message);
      }
    });
  }

  public onSelectBillingInfo(id: number): void {
    const bookingInput: BookingInput = { billingId: id };
    this.isLoading = true;

    this.updateBooking
      .mutate({
        bookingId: this.bookingId,
        booking: bookingInput,
      })
      .subscribe(
        () => {
          this.snackbar.open($localize`Succesfully saved changes!`, undefined, { duration: 3000 });
          this.isLoading = false;
        },
        (error) => {
          this.snackbar.open($localize`Could not save changes`, $localize`Try again`, { duration: 3000 });
          this.isLoading = false;
          console.error(error);
        },
      );
  }

  public sign(bookingId: number, customerType: string): void {
    this.loadingSign = true;

    this.signBooking.mutate({ bookingId }).subscribe(
      ({ data }) => {
        this.snackbar.open($localize`You have successfully signed a contract`, undefined, {
          duration: 3000,
        });

        const bookingValue = data.signBooking.dates.reduce((a, b) => a + b.price, 0);
        this.analytics.emitEvent('booking_signed', { type: data.signBooking.type }, { value: bookingValue });

        this.loadingSign = false;
      },
      () => this.snackbar.open($localize`Could not sign contract`, undefined, { duration: 3000 }),
    );
  }

  private setBooking(bookingId: number): void {
    let booking: Booking;
    this.booking = this.getBooking.watch({ bookingId }).valueChanges.pipe(
      map(({ data }) => {
        this.bookerSignature = data.bookingById.signatures.find((s) => s.isBooker && s.valid);
        this.artistSignature = data.bookingById.signatures.find((s) => !s.isBooker && s.valid);
        this.bookingId = bookingId;
        this.acceptedDates = data.bookingById.dates.filter((d) => d.isAccepted);

        booking = data.bookingById;

        return data.bookingById;
      }),
    );
    this.currentUser = this.getCurrentUser.watch().valueChanges.pipe(
      map(({ data }) => data.me),
      tap((cu) => {
        this.currentUserId = cu.id;
        this.amIBooker = amIBooker(booking, cu.id);
        if (amIBooker(booking, cu.id)) {
          this.feePerc = booking.user.fee;
        } else {
          this.feePerc = booking.page.fee;
        }
      }),
    );
    this.isLoading = false;
  }

  cardAdded() {
    this.hasPaymentMethods = true;
  }
}
