<section class="bg-gray-5" *ngIf="wizard" fxLayout="column">
  <header class="wizard__heading">
    <h2 class="h4 gray-1">{{ wizard.fields.title }}</h2>
    <h3 class="mat-body gray-1">{{ wizard.fields.secondaryTitle }}</h3>
  </header>
  <div class="bg-gray-5 container" fxLayout="column">
    <div class="wizard">
      <div
        class="wizard__step"
        *ngFor="let step of wizard.fields.steps; let i = index"
        fxLayout
        fxLayoutAlign="center center">
        <div
          class="wizard__step-image"
          fxFlex="50%"
          fxLayout
          fxLayoutAlign="end center"
          *ngIf="step.fields.image"
          fxHide.lt-lg>
          <cl-image [public-id]="step.fields.image[0].public_id" appLazyLoad attr.alt="Artist filtering">
            <cl-transformation quality="auto" fetch-format="auto" flags="progressive" responsive> </cl-transformation>
          </cl-image>
        </div>
        <div fxHide.lt-md class="wizard__timeline-dot" fxFlex="24px" fxLayout fxLayoutAlign="center center">
          <span></span>
        </div>
        <div class="wizard__step-content" fxFlex="100%" fxFlex.gt-sm="75%" fxFlex.gt-md="50%">
          <span class="wizard__step-counter">{{ zeroPad(i + 1) }}</span>
          <h3 class="h6 gray-1">{{ step.fields.title }}</h3>
          <p class="mat-body gray-2">{{ step.fields.description.content[0].content[0].value }}</p>
          <ul class="wizard__feature-list">
            <li *ngFor="let block of step.fields.descriptionBlocks" fxLayout>
              <div
                [class]="'bg-' + block.fields.color + ' wizard__feature-list-icon'"
                fxFlex="0 0 auto"
                fxLayout
                fxLayoutAlign="center center">
                <mat-icon class="white">{{ block.fields.iconText }}</mat-icon>
              </div>
              <div>
                <h4 [class]="block.fields.color + ' button'">{{ block.fields.title }}</h4>
                <p class="mat-body-2 gray-2">{{ block.fields.body.content[0].content[0].value }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="wizard__cta bg-white" fxFlex="60%" fxFlexAlign="center">
    <h3 class="h4 gray-1">{{ wizard.fields.ctaTitle }}</h3>
    <p class="mat-body gray-2">{{ wizard.fields.ctaDescription }}</p>
    <div fxLayout fxLayoutGap="24px">
      <a mat-flat-button color="primary" [routerLink]="[wizard.fields.ctaActionUrl]">{{
        wizard.fields.ctaActionTitle
      }}</a>
      <a mat-stroked-button [routerLink]="[wizard.fields.ctaSecondaryActionLink]">{{
        wizard.fields.ctaSecondaryActionTitle
      }}</a>
    </div>
  </div>
</section>
<!-- <ng-template #loading>
  <div class="loading-screen" fxLayout fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="48px" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template> -->
