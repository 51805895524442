import { Component, Input, OnInit } from '@angular/core';
import { SupplyTypes } from '../../../generated/graphql';
import { IconsRegistryService, IconSubsets } from '../../services/icons-registry.service';

export enum EquipmentIconsClasses {
  SPEAKERS = 'speakers',
  MICS = 'speakers',
  MIXER = 'mixer',
  PA = 'pa-system',
}

@Component({
  selector: 'app-equipment-icons',
  templateUrl: './equipment-icons.component.html',
  styleUrls: ['./equipment-icons.component.scss'],
})
export class EquipmentIconsComponent implements OnInit {
  @Input() equipments: SupplyTypes[];

  SupplyTypes = SupplyTypes;
  EquipmentIconsClasses = EquipmentIconsClasses;

  constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.EQUIPMENT]);
  }

  ngOnInit(): void {}
}
