import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Page } from '../../../../generated/graphql';

@Component({
  selector: 'app-artist-list-slider',
  templateUrl: './artist-list-slider.component.html',
  styleUrls: ['./artist-list-slider.component.scss'],
})
export class ArtistListSliderComponent {
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  @Input() pages: Page[];
  @Input() filters: any;

  public isBrowser: boolean = false;
  public screenWidth: number;

  get slidersToShow(): number {
    let numberOfSliders = 4;
    if (this.screenWidth <= 600) {
      numberOfSliders = 1.4;
    } else if (this.screenWidth >= 600 && this.screenWidth <= 840) {
      numberOfSliders = 2;
    } else if (this.screenWidth >= 840 && this.screenWidth <= 1200) {
      numberOfSliders = 3;
    }
    return numberOfSliders;
  }

  get sliderHeight(): number {
    let sliderHeight = 400;
    if (this.screenWidth <= 420) {
      sliderHeight = 370;
    } else if (this.screenWidth > 420 && this.screenWidth <= 500) {
      sliderHeight = 450;
    } else if (this.screenWidth > 500 && this.screenWidth <= 600) {
      sliderHeight = 500;
    } else if (this.screenWidth > 670 && this.screenWidth <= 840) {
      sliderHeight = 480;
    } else if (this.screenWidth > 960 && this.screenWidth <= 1200) {
      sliderHeight = 500;
    } else if (this.screenWidth > 1500 && this.screenWidth <= 2600) {
      sliderHeight = 500;
    } else if (this.screenWidth > 2600) {
      sliderHeight = 600;
    }
    return sliderHeight;
  }

  constructor(@Inject(PLATFORM_ID) private platformId: object, private router: Router) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.getScreenSize();
  }

  public onShowAllClick(): void {
    const queryParams: Params = {};
    const parsedFilters = JSON.parse(this.filters);

    if (parsedFilters.tagIds && parsedFilters.tagIds.length > 0) queryParams.tags = parsedFilters.tagIds.join();
    if (parsedFilters.category) queryParams.category = parsedFilters.category;
    if (parsedFilters.location) queryParams.location = parsedFilters.location;

    this.router.navigate(['/artists/search'], {
      queryParams,
      replaceUrl: true,
    });
  }
}
