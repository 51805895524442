import { Component, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface SelectInputOptions {
  value: string;
  label: string;
}

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectInputComponent,
      multi: true,
    },
  ],
})
export class SelectInputComponent implements ControlValueAccessor {
  @Input() selectOptions: SelectInputOptions[];
  @Input() disabled: boolean = false;

  @Input() label: string;
  @Input() isLightLabel: false;

  @Input() iconName: string;

  public onDropdownClose$: EventEmitter<any> = new EventEmitter();

  public selectedOption: SelectInputOptions;
  public selectedOptionValue: string;

  get value() {
    return this.selectedOptionValue;
  }

  set value(value) {
    this.selectedOption = this.selectOptions.find((option) => option.value === value);
    this.selectedOptionValue = value;

    this.onTouch(value);
    this.onChange(value);
  }

  public constructor() {}

  public setDisabledState(isDisabled: boolean): void {}

  public writeValue(value: any): void {
    this.selectedOption = this.selectOptions.find((option) => option.value === value);
    this.selectedOptionValue = value;

    if (!this.selectedOptionValue) {
      this.selectedOption = this.selectOptions[0];
      this.selectedOptionValue = this.selectedOption.value;
    }

    this.onOptionSelected(this.selectedOptionValue);
  }

  private onChange: any = () => {};
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  private onTouch: any = () => {};
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public onOptionSelected(option: string): void {
    this.value = option;
    this.onDropdownClose$.emit();
  }
}
