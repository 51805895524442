import { Component, OnInit } from '@angular/core';
import { IconSubsets, IconsRegistryService } from '../../../../../../services/icons-registry.service';

@Component({
  selector: 'app-cancellation-policy-modal',
  templateUrl: './cancellation-policy-modal.component.html',
  styleUrls: ['./cancellation-policy-modal.component.scss'],
})
export class CancellationPolicyModalComponent implements OnInit {
  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public ngOnInit(): void {}
}
