<ng-container *ngIf="userPages">
  <app-empty-artist-profile *ngIf="userPages.length <= 0" [isFirst]="userPages.length <= 0"> </app-empty-artist-profile>

  <ng-container *ngIf="userPages.length > 0">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between start"
      fxLayout.lt-md="column"
      fxLayoutGap.lt-md="0px"
      fxLayoutAlign.lt-md="stretch stretch">
      <div fxLayout="column" fxFlex="362px" fxFlex.lt-md="grow" class="artists-list">
        <app-slider-controls
          [disabledLeft]="displayedPageIndex <= 0"
          [disabledRight]="displayedPageIndex >= userPages.length"
          (onPreviousClick)="onPreviousClick()"
          (onNextClick)="onNextClick()">
        </app-slider-controls>

        <div class="sliders-wrap">
          <ng-container *ngFor="let page of userPages; let i = index">
            <app-artist-profile-card *ngIf="displayedPageIndex === i" [page]="page"></app-artist-profile-card>
          </ng-container>
          <app-empty-artist-profile
            *ngIf="displayedPageIndex === userPages.length"
            [isFirst]="userPages.length <= 0"></app-empty-artist-profile>
        </div>
      </div>
      <div fxFlex="flex-grow"> </div>
    </div>
  </ng-container>
</ng-container>
