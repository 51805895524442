import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GetCurrentUserGQL, GetOpenGigsGQL, UserType } from '../../../../generated/graphql';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-gigs-header',
  templateUrl: './gigs-header.component.html',
  styleUrls: ['./gigs-header.component.scss'],
})
export class GigsHeaderComponent implements OnInit {
  isBooker: boolean = true;
  isBookerSub: Subscription;
  openGigs: number;
  openSlots: number;

  public get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  constructor(private user: GetCurrentUserGQL, private getGigs: GetOpenGigsGQL, private authService: AuthService) {}

  ngOnInit() {
    const query = this.user.watch().valueChanges.pipe(
      tap((d) => {
        this.isBooker = d.data?.me?.type === UserType.Booker;
        if (this.isBooker && d.data.me.hasSearchablePage) {
          this.isBooker = false;
        }
      }),
    );

    this.getGigs.fetch().subscribe((d) => {
      this.openGigs = d.data.openGigs.gigs;
      this.openSlots = d.data.openGigs.slots;
    });

    // why can't we call you with | async pipe???
    this.isBookerSub = query.subscribe((u) => {});
  }
}
