<ul class="icons-container" fxLayout="row" fxLayoutGap="30px">
  <li
    fxLayout="column"
    fxLayoutAlign="space-between center"
    fxLayoutGap="5px"
    *ngFor="let equipment of equipments"
    class="icon-container">
    <mat-icon
      class="svg-icon"
      [ngClass]="EquipmentIconsClasses[equipment]"
      [svgIcon]="EquipmentIconsClasses[equipment]"></mat-icon>
    <span class="text-center" *ngIf="equipment === SupplyTypes.Speakers" i18n>Speakers</span>
    <span class="text-center" *ngIf="equipment === SupplyTypes.Mixer" i18n>Mixertable</span>
    <span class="text-center" *ngIf="equipment === SupplyTypes.Pa" i18n>PA system</span>
  </li>
</ul>
