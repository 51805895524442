import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetNotificationsGQL,
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  Notification,
  NotificationSubscriptionGQL,
  NotificationWithSubs,
  NotificationWithSubsFieldsFragment,
} from '../../generated/graphql';
import { flat } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public notifications: Observable<NotificationWithSubs[]>;
  private notificationSubsription: Subscription;

  curSkip = 10;
  hasNextPage = true;

  getNotesQuery: QueryRef<GetNotificationsQuery, GetNotificationsQueryVariables>;

  unreads = 0;
  canDisplayBadge = false;

  constructor(
    private notificationSub: NotificationSubscriptionGQL,
    private getNotifications: GetNotificationsGQL,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  /**
   * Hide the number over the bell
   */
  setNotificationsHalfRead() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('notesDisplayed', new Date().toISOString());
      this.canDisplayBadge = false;
    }
  }

  private sortNotifications(a: NotificationWithSubsFieldsFragment, b: NotificationWithSubsFieldsFragment) {
    if (a.notification.isRead && !b.notification.isRead) return 1;
    if (b.notification.isRead && !a.notification.isRead) return -1;
    return b.notification.createdDate - a.notification.createdDate;
  }

  private combineNotifications(notes: NotificationWithSubsFieldsFragment[]) {
    let combined: NotificationWithSubs[] = [];
    combined = notes.map((n, i, arr) => {
      if (typeof n.notification.meta === 'string') n.notification.meta = JSON.parse(n.notification.meta);
      return n;
    });
    return combined;
  }

  subscribeToNotifications() {
    // Don't subscribe on the server, or if we already are subscribed
    if (!isPlatformBrowser(this.platformId) || !!this.notifications) return;
    this.unsubscribeFromNotifications();

    // BUG: Tries to get notifications when logging out
    this.getNotesQuery = this.getNotifications.watch({ getUnread: false, paging: { limit: 10, skip: 0 } });

    this.notifications = this.getNotesQuery.valueChanges.pipe(
      map(({ data }) => {
        if (!data) {
          console.warn('No notification data?');
          return [];
        }
        const notes = data.getNotifications.unread;
        this.unreads = data.getNotifications.notifications.filter((n) => !n.notification.isRead).length;
        data.getNotifications.notifications.map((e) => {
          if (!notes.find((x) => e.notification.id === x.notification.id)) {
            notes.push(e);
          }
        });

        const latest: Notification = [...notes.map((n) => n.notification), ...flat(notes.map((n) => n.subs))].sort(
          (a: Notification, b: Notification) => b.createdDate - a.createdDate,
        )[0];

        if (latest) {
          const notesDisplayed = localStorage.getItem('notesDisplayed');
          if (!!notesDisplayed) {
            const hiddenAt = new Date(notesDisplayed);
            if (hiddenAt.getTime() < new Date(latest.createdDate).getTime()) {
              this.canDisplayBadge = true;
            }
          } else {
            this.canDisplayBadge = true;
          }
        }

        return this.combineNotifications(notes).sort(this.sortNotifications);
      }),
    );

    this.getNotesQuery.subscribeToMore({
      document: this.notificationSub.document,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        if (prev.getNotifications) {
          prev.getNotifications.notifications = [
            ...prev.getNotifications.notifications,
            { __typename: 'NotificationWithSubs', notification: subscriptionData.data.notification, subs: [] },
          ].sort(this.sortNotifications);
        }
        return prev;
      },
    });
  }

  fetchMoreNotifications() {
    if (!this.hasNextPage) {
      return;
    }
    try {
      this.getNotesQuery.fetchMore({
        query: this.getNotifications.document,
        variables: { getUnread: false, paging: { limit: 10, skip: this.curSkip } },
        updateQuery: (prev, { fetchMoreResult }) => {
          this.curSkip = fetchMoreResult.getNotifications.skip;
          this.hasNextPage = fetchMoreResult.getNotifications.hasNextPage;
          if (!!prev && prev.getNotifications) {
            prev.getNotifications.notifications = [
              ...prev.getNotifications.notifications,
              ...fetchMoreResult.getNotifications.notifications,
            ];
          }
          return prev;
        },
      });
    } catch (error) {
      console.error('notifications.service.ts - fetchMoreNotifications()', error);
    }
  }

  unsubscribeFromNotifications() {
    if (!!this.notificationSubsription && !this.notificationSubsription.closed) {
      this.notificationSubsription.unsubscribe();
    }
  }
}
