<div>
  <div class="m-b--24">
    <mat-icon svgIcon="close" mat-dialog-close class="cursor"></mat-icon>
  </div>
  <div>
    <h1 class="h5 f-weight--600 m-b--32" i18n>Cancellation policy</h1>

    <div class="m-b--24">
      <p class="subtitle-1 f-weight--600 m-b--20" i18n>For customers</p>
      <ul fxLayout="column" fxLayoutGap="10px">
        <li><span class="f-weight--600" i18n>30 days before the gig:</span>&nbsp;<span>no charge</span></li>
        <li
          ><span class="f-weight--600" i18n>29 days before the gig:</span>&nbsp;<span
            >Charged 50% of the total price</span
          ></li
        >
        <li
          ><span class="f-weight--600" i18n>7 days before the gig:</span>&nbsp;<span
            >Charged 100% of the total price</span
          ></li
        >
      </ul>
    </div>

    <mat-divider class="m-t--24 m-b--24"></mat-divider>

    <div class="m-b--24">
      <p class="subtitle-1 f-weight--600 m-b--20" i18n>For artists</p>
      <ul fxLayout="column" fxLayoutGap="10px">
        <li i18n>If an artist cancels, the customer will not be charged</li>
        <li i18n>The artist has no right to compensation if artist cancels the gig</li>
      </ul>
    </div>

    <mat-divider class="m-t--24 m-b--24"></mat-divider>

    <div class="m-b--24">
      <p class="subtitle-1 f-weight--600 m-b--20" i18n>Rebooking</p>
      <ul fxLayout="column" fxLayoutGap="10px">
        <li i18n>Rebooking is free of charge if the booked artist accepts the terms of the new gig.</li>
      </ul>
    </div>
  </div>
</div>
