import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetContentfulEntryGQL } from '../../../generated/graphql';
import { richTextToHtml } from '../../helpers';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-gratification',
  templateUrl: './gratification.component.html',
  styleUrls: ['./gratification.component.scss'],
})
export class GratificationComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { contentfulId: string },
    private modalRef: MatDialogRef<GratificationComponent>,
    private contentful: GetContentfulEntryGQL,
    private translations: TranslationsService,
  ) {}

  title: string;
  contentfulData: Observable<any>;
  ngOnInit(): void {
    this.contentfulData = this.contentful
      .watch({ id: this.data.contentfulId, locale: this.translations.getCurrentLocale() })
      .valueChanges.pipe(map(({ data }) => data.getContentfulEntry.contentfulData.fields));
    this.contentfulData.subscribe(d => {
      this.title = d.title;
    });
  }

  richTextToHtml(text) {
    return richTextToHtml(text);
  }
}
