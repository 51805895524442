import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Image } from '../../../generated/graphql';

@Component({
  selector: 'app-cloud-image',
  templateUrl: './cloud-image.component.html',
  styleUrls: ['./cloud-image.component.scss'],
})
export class CloudImageComponent implements OnInit {
  @Input()
  profilePicture: Image;

  @Input()
  backgroundPicture: Image;

  @Input()
  cover = false;

  @Input()
  altText: string;

  @Input()
  maxWidth: number = 500;

  @Output()
  pictureLoaded = new EventEmitter<boolean>();
  @Output()
  pictureError = new EventEmitter<boolean>();

  pic: Image;
  constructor() {}

  ngOnInit() {
    this.pic = this.profilePicture || this.backgroundPicture;
  }

  loaded() {
    this.pictureLoaded.next(true);
  }
  error() {
    this.pictureError.next(true);
  }
}
