import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { IconSubsets, IconsRegistryService } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  animations: [
    trigger('expansion', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('225ms ease-out', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [style({ opacity: 1 }), animate('225ms ease-in', style({ height: 0, opacity: 0 }))]),
    ]),
  ],
})
export class ExpansionPanelComponent {
  @Input() isHTML: boolean = false;
  @Input() content: string;
  @Input() title: string;

  public isPanelOpened: boolean = false;

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }
}
