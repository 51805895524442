import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { GigType } from '../../generated/graphql';
import { ProgressStepType, TranslatedPageStep } from '../models/ArtistProgress';

export interface TranslatedEnum<T> {
  type: string;
  translation: string;
  original: T;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  translatedTypes: TranslatedEnum<GigType>[] = [];
  translatedSteps: TranslatedPageStep[] = [];
  permittedGigTypes = [GigType.Business, GigType.Venue, GigType.Private, GigType.Others];
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  getCurrentLocale(): string {
    let locale = this.locale;
    if (locale === 'en') locale = 'en-US';
    return locale;
  }

  gigType(type: GigType): TranslatedEnum<GigType> {
    if (!this.permittedGigTypes.includes(type)) {
      return (
        this.translatedTypes.find((t) => t.type === GigType.Others) || { type: '', translation: '', original: type }
      );
    }
    return this.translatedTypes.find((t) => t.type === type) || { type: '', translation: '', original: type };
  }

  getGigTypesList(): TranslatedEnum<GigType>[] {
    let out: TranslatedEnum<GigType>[] = [];
    for (const type of this.permittedGigTypes) {
      out.push(this.gigType(type));
    }
    return out;
  }

  pageStep(type: ProgressStepType) {
    return this.translatedSteps.find((t) => t.stepType === type) || { stepType: '', description: '', name: '' };
  }
}
