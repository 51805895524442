import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetCurrentUserGQL, Page, User } from '../../../../../generated/graphql';
import { getTimeLengthOptionsForSelect, getTimeOptionsForSelectDropdown } from '../../../../helpers/dates';
import { SelectInputOptions } from '../../../../shared/components/inputs/select-input/select-input.component';

@Component({
  selector: 'app-artist-detail-book-details',
  templateUrl: './artist-detail-book-details.component.html',
  styleUrls: ['./artist-detail-book-details.component.scss'],
})
export class ArtistDetailBookDetailsComponent implements OnInit {
  @Input() createRequestForm: FormGroup;
  @Input() page$: Observable<Page>;

  public gigStartTimeOptions: SelectInputOptions[] = [];
  public timeLengthOptions: SelectInputOptions[] = [];
  public todayDate: Date;
  public user: User;

  public get budgetLabel(): string {
    return $localize`Set your budget per date or act`;
  }

  public get selectedDates(): Date[] {
    return this.createRequestForm.value.slotDates.map((d) => new Date(d));
  }

  public get totalPriceWithFee(): number {
    return Math.round(
      this.createRequestForm.value.budgetAll * this.createRequestForm.value.slotDates.length * (this.user?.fee || 1.15),
    );
  }

  public constructor(private getCurrentUserGQL: GetCurrentUserGQL) {}

  public ngOnInit(): void {
    this.todayDate = new Date();

    this.gigStartTimeOptions = getTimeOptionsForSelectDropdown();
    this.timeLengthOptions = getTimeLengthOptionsForSelect();

    this.getCurrentUserGQL
      .watch()
      .valueChanges.pipe(map((d) => (this.user = d.data.me)))
      .subscribe();
  }

  public areAllSupliesDeselected(): boolean {
    return (
      !this.createRequestForm.get('suppliesMixer').value &&
      !this.createRequestForm.get('suppliesSpeakers').value &&
      !this.createRequestForm.get('suppliesPa').value
    );
  }

  public selectSupplyType(type: number): void {
    switch (type) {
      case 0:
        this.createRequestForm.get('suppliesMixer').setValue(!this.createRequestForm.get('suppliesMixer').value);
        break;
      case 1:
        this.createRequestForm.get('suppliesPa').setValue(!this.createRequestForm.get('suppliesPa').value);
        break;
      case 2:
        this.createRequestForm.get('suppliesSpeakers').setValue(!this.createRequestForm.get('suppliesSpeakers').value);
        break;
      case -1:
        this.createRequestForm.get('suppliesMixer').setValue(false);
        this.createRequestForm.get('suppliesSpeakers').setValue(false);
        this.createRequestForm.get('suppliesPa').setValue(false);
        break;
    }
  }
}
