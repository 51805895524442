export const LONG_DATE_WITH_TIME = 'd MMMM yyyy HH:mm';
export const LONG_DATE = 'd MMMM yyyy';
export const MEDIUM_DATE = 'd MMM yyyy';
export const SHORT_DATE = 'd MMM yy';
export const SHORT_DATE_NO_YEAR = 'd MMM';
export const SHORT_DATE_WITH_TIME = 'd MMM yy HH:mm';
export const DAY_MONTH_DATE = 'EEEE · d MMM';
export const ONLY_DAY = 'd';
export const DAY_MONTH = 'd MMM';
export const MONTH_YEAR = 'MMMM yyyy';
export const HOURS_MINUTES = 'HH:mm';

export interface DateDetailsObject {
  weekDay: string;
  day: number;
  month: string;
  year: string;
}

export const isISOString = (iso: any) => {
  if (typeof iso !== 'string') return false;
  const test =
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/;
  return test.test(iso);
};

export const apiToDate = (apiDate: string): Date => {
  if (isISOString(apiDate)) {
    return new Date(apiDate);
  }
  return new Date(parseInt(apiDate, 10));
};

export const dateToApi = (date: Date): string => {
  if (typeof date === 'string') {
    return date as any;
  }

  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  date.setUTCHours(0, 0, 0, 0);

  return date.toISOString();
};

export const multiDateToApi = (dates: Date[]) => {
  return dates.map((d) => {
    if (typeof d === 'string') {
      return d as any;
    }
    return dateToApi(d);
  });
};

const extractNumbersFromTimeString = (timeString: string) => {
  const regex = /(\d{1,2}):(\d{2})\s*(am|pm)?/i; // Adding the 'i' flag for case-insensitivity
  const match = timeString.match(regex);

  if (match) {
    const hour = parseInt(match[1]);
    const minute = parseInt(match[2]);
    const period = match[3] ? match[3].toUpperCase() : ''; // Convert to uppercase if provided

    return { hour, minute, period };
  } else {
    throw new Error('Invalid time string format');
  }
};

export const timeStringToTimestamp = (date: Date, time: string): string => {
  let _time = time;
  if (_time.indexOf(':') === -1) {
    const datum = apiToDate(_time);
    _time = datum.getHours() + ':' + datum.getMinutes();
  }

  date.setHours(+_time.split(':')[0]);
  date.setMinutes(+_time.split(':')[1]);

  return date.toISOString();
  // return dateToApi(date);
};

export const timestampToTimeString = (time: string): string => {
  const hours = apiToDate(time).getHours();
  const minutes = apiToDate(time).getMinutes();

  return `${minTwoDigits(hours)}:${minTwoDigits(minutes)}`;
};

export const areDatesSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const isTodayDate = (date: string): boolean => {
  const checkDate = apiToDate(date);
  const todayDate = new Date();

  return (
    checkDate.getDate() === todayDate.getDate() &&
    checkDate.getMonth() === todayDate.getMonth() &&
    checkDate.getFullYear() === todayDate.getFullYear()
  );
};

export const isTomorrowDate = (date: string): boolean => {
  const checkDate = apiToDate(date);
  const todayDate = new Date();

  const tomorrowDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() + 1);

  return (
    tomorrowDate.getFullYear() == checkDate.getFullYear() &&
    tomorrowDate.getMonth() == checkDate.getMonth() &&
    tomorrowDate.getDate() == checkDate.getDate()
  );
};

export const getFirstAndLastSlotDates = (slots: GigSlot[]) => {
  let sortedDateSlots = slots.sort((a, b) => {
    return parseInt(a.date) - parseInt(b.date);
  });

  return {
    firstDate: apiToDate(sortedDateSlots[0].date),
    lastDate: apiToDate(sortedDateSlots[sortedDateSlots.length - 1].date),
  };
};

export const defineFirstDateFormat = (slots: GigSlot[]): string => {
  let { firstDate, lastDate } = getFirstAndLastSlotDates(slots);

  if (firstDate.getTime() === lastDate.getTime()) {
    return MEDIUM_DATE;
  } else if (firstDate.getFullYear() === lastDate.getFullYear() && firstDate.getMonth() === lastDate.getMonth()) {
    return ONLY_DAY;
  } else if (firstDate.getFullYear() === lastDate.getFullYear()) {
    return DAY_MONTH;
  } else {
    return MEDIUM_DATE;
  }
};

const year = $localize`year`;
const yearPlural = $localize`years`;
const month = $localize`month`;
const monthPlural = $localize`months`;
const day = $localize`day`;
const dayPlural = $localize`days`;
const hour = $localize`hour`;
const hourPlural = $localize`hours`;
const minute = $localize`minute`;
const minutePlural = $localize`minutes`;
const ago = $localize`ago`;
const justNow = $localize`just now`;
export const timeSince = (date: Date): string => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let intervalType;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = year;
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = month;
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = day;
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = hour;
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = minute;
          } else {
            return justNow;
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    switch (intervalType) {
      case year:
        intervalType = yearPlural;
        break;
      case month:
        intervalType = monthPlural;
        break;
      case day:
        intervalType = dayPlural;
        break;
      case hour:
        intervalType = hourPlural;
        break;
      case minute:
        intervalType = minutePlural;
        break;

      default:
        break;
    }
  }

  return `${interval} ${intervalType} ${ago}`;
};

export const getTimeOptionsForSelectDropdown = (): SelectInputOptions[] => {
  let result = [];
  const startTimeToday = new Date();
  startTimeToday.setHours(19, 0, 0);
  const endTimeTomorrow = new Date();
  endTimeTomorrow.setDate(endTimeTomorrow.getDate() + 1);
  endTimeTomorrow.setHours(23, 30, 0);

  while (startTimeToday < endTimeTomorrow) {
    const timeValue = startTimeToday.toLocaleTimeString(['sv-SE'], { hour: '2-digit', minute: '2-digit' });
    const timeLabel = startTimeToday.toLocaleTimeString(['sv-SE'], { hour: 'numeric', minute: '2-digit' });
    result.push({ value: timeValue, label: timeLabel });
    startTimeToday.setMinutes(startTimeToday.getMinutes() + 30);
  }

  const startTimeTomorrow = new Date(endTimeTomorrow);
  startTimeTomorrow.setHours(0, 0, 0);

  const endTimeTomorrowMidnight = new Date(startTimeTomorrow);
  endTimeTomorrowMidnight.setHours(23, 30, 0);

  while (startTimeTomorrow < endTimeTomorrowMidnight) {
    const timeValue = startTimeTomorrow.toLocaleTimeString(['sv-SE'], { hour: '2-digit', minute: '2-digit' });
    const timeLabel = startTimeTomorrow.toLocaleTimeString(['sv-SE'], { hour: 'numeric', minute: '2-digit' });

    result.push({ value: timeValue, label: timeLabel });
    startTimeTomorrow.setMinutes(startTimeTomorrow.getMinutes() + 30);
  }

  return result.filter((obj, index, arr) => {
    return arr.map((mapObj) => mapObj.value).indexOf(obj.value) === index;
  });
};

export const getTimeLengthOptionsForSelect = (): SelectInputOptions[] => {
  const timeArray = [];
  const hourValues = [1, 2, 3, 4, 5];
  const minuteValues = ['', '.30'];

  timeArray.push({ value: '00:30', label: '30min' });
  hourValues.forEach((hour) => {
    minuteValues.forEach((minute) => {
      let timeLabel = '';
      if (hour === 1 && minute === '') {
        timeLabel = '1h';
      } else if (hour === 1 && minute === '.30') {
        timeLabel = '1h 30min';
      } else if (hour > 1 && minute === '') {
        timeLabel = `${hour}h`;
      } else if (hour > 1 && minute === '.30') {
        timeLabel = `${hour}h 30min`;
      }

      const timeValue = `${hour.toString().padStart(2, '0')}:${minute === '.30' ? 30 : '00'}`;
      timeArray.push({ value: timeValue, label: timeLabel });
    });
  });

  return timeArray;
};

export const formatDuration = (timeString: string): string => {
  const [hours, minutes] = timeString.split(':').map(Number);

  if (hours === 0 && minutes === 0) {
    return '0 min';
  } else if (hours === 0) {
    return `${minutes} min`;
  } else if (minutes === 0) {
    return `${hours} hour`;
  } else {
    return `${hours} hour ${minutes} min`;
  }
};

export const getGigSlotEndTimeISO = (startISODate: string, setLength: string): string => {
  const startDate = apiToDate(startISODate);
  const [hours, minutes] = setLength.split(':').map(Number);
  startDate.setHours(startDate.getHours() + hours);
  startDate.setMinutes(startDate.getMinutes() + minutes);
  return startDate.toISOString();
};

export const getGigSlotSetLength = (startTimeISO: string, endTimeISO: string): string => {
  const start = apiToDate(startTimeISO);
  const end = apiToDate(endTimeISO);
  const durationMs = end.getTime() - start.getTime();
  const durationMinutes = Math.floor(durationMs / (1000 * 60));
  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;
  const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return formattedDuration;
};

export const getDateDetailsObject = (isoDate: string): DateDetailsObject => {
  const date = apiToDate(isoDate);
  const daysOfWeek = [
    $localize`Sun`,
    $localize`Mon`,
    $localize`Tue`,
    $localize`Wed`,
    $localize`Thu`,
    $localize`Fri`,
    $localize`Sat`,
  ];
  const months = [
    $localize`Jan`,
    $localize`Feb`,
    $localize`Mar`,
    $localize`Apr`,
    $localize`May`,
    $localize`Jun`,
    $localize`Jul`,
    $localize`Aug`,
    $localize`Sep`,
    $localize`Oct`,
    $localize`Nov`,
    $localize`Dec`,
  ];

  const weekDay = daysOfWeek[date.getDay()];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);

  return {
    weekDay,
    day,
    month,
    year,
  };
};

export const isNextYearOrLater = (isoDate: string): boolean => {
  const currentDate = new Date();
  const inputDate = apiToDate(isoDate);

  const currentYear = currentDate.getFullYear();
  const inputYear = inputDate.getFullYear();

  return inputYear >= currentYear + 1;
};

import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { GigSlot } from '../../generated/graphql';
import { SelectInputOptions } from '../shared/components/inputs/select-input/select-input.component';
import { minTwoDigits } from './index';

@Injectable()
export class GigitalDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}
