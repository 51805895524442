import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Intercom } from 'ng-intercom';
import { map } from 'rxjs/operators';
import { DeleteInviteGQL, GetCurrentUserGQL, Invite, UserType } from '../../../generated/graphql';
import { InviteExternalReviewComponent } from '../../invites/pages/invite-external-review/invite-external-review.component';
import { PopupService } from '../../services/popup.service';
import { CreateArtistComponent } from '../../user/components/create-artist/create-artist.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public userType: UserType = UserType.Booker;
  public USER_TYPE = UserType;

  private nowDate = new Date();

  constructor(
    private getCurrentUser: GetCurrentUserGQL,
    private deleteInvite: DeleteInviteGQL,
    private popupService: PopupService,
    private intercom: Intercom,
    private dialog: MatDialog,
  ) {}

  user = this.getCurrentUser.fetch({}, {}).pipe(
    map(({ data }) => {
      this.userType = data.me.type || UserType.Booker;
      return data.me;
    }),
  );

  ngOnInit() {}

  openIntercom() {
    if ((window as any).Intercom.booted) {
      this.intercom.showNewMessage();
    }
  }

  openCreateArtist(): void {
    this.dialog.open(CreateArtistComponent);
  }

  openInviteReview(): void {
    this.popupService.open(InviteExternalReviewComponent, '800px', {
      panelClass: 'gigital-modal',
    });
  }

  getIcon(invite: Invite) {
    if (invite.deleted || this.nowDate.getTime() > new Date(invite.expiryDate).getTime()) {
      return { icon: 'clear', color: 'red' };
    }
    if (invite.completed) {
      return { icon: 'done', color: 'green' };
    }
    return { icon: 'schedule', color: 'yellow' };
  }

  cancelInvite(invite: Invite, event: Event) {
    event.stopPropagation();
    this.deleteInvite.mutate({ inviteId: invite.id }).subscribe(console.log);
  }
}
