<label class="action-label" matDialogTitle i18n>Editing available equipment</label>
<form [formGroup]="editGroup" fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" class="equipment-checks" fxLayoutGap="48px">
    <div fxLayout="column" fxLayoutGap="16px">
      <mat-checkbox i18n color="primary" formControlName="suppliesPa">PA System</mat-checkbox>
      <mat-checkbox i18n color="primary" formControlName="suppliesSpeakers">Speakers</mat-checkbox>
      <mat-checkbox i18n color="primary" formControlName="suppliesMixer">Mixer</mat-checkbox>
    </div>
    <mat-checkbox i18n color="primary" formControlName="suppliesNone">None</mat-checkbox>
  </div>

  <div fxFlexAlign="start" class="m-t--20">
    <label fxLayoutAlign="start center" fxLayoutGap="8px">
      <app-checkbox-input formControlName="supplyStatus"></app-checkbox-input>
      <span i18n>Request artist to bring missing equipment for a fee</span>
    </label>
  </div>
</form>

<div class="line-space"></div>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" class="actions">
  <button class="gig-button square secondary" i18n matDialogClose>Close</button>
  <button mat-flat-button color="primary" (click)="save()" i18n>Save</button>
</mat-dialog-actions>
