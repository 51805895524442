import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Booking, Gig, GigSlot } from '../../../../generated/graphql';
import { isSlotOpen } from '../../../../utils/gigSlots';
import { GET_SELECTED_APPLICATIONS, SELECT_APPLICATION } from '../../../resolvers';
import { GigApplicationSummaryComponent } from '../gig-application-summary/gig-application-summary.component';
import { GigBookingComponent } from '../gig-booking/gig-booking.component';

export interface Application {
  slot: GigSlot;
  booking: Booking;
}

@Component({
  selector: 'app-gigs-booking-bar',
  templateUrl: './gigs-booking-bar.component.html',
  styleUrls: ['./gigs-booking-bar.component.scss'],
})
export class GigsBookingBarComponent implements OnInit {
  @Input()
  gig: Gig;

  applications: Application[] = [];
  totalApplications;

  constructor(private apollo: Apollo, private modal: MatDialog) {}

  ngOnInit(): void {
    this.apollo
      .watchQuery({
        query: GET_SELECTED_APPLICATIONS,
      })
      .valueChanges.pipe(map(({ data }) => (data as any).selectedApplications))
      .subscribe((selectedApplications: [{ applicationId: number; slotId: number }]) => {
        this.applications = [];
        selectedApplications.forEach(a => {
          const slot = this.gig.slots.find(s => s.id === a.slotId);
          const booking = slot && slot.applications.find(app => app.id === a.applicationId);
          if (slot && booking) {
            this.applications.push({ slot, booking });
          }
        });
      });

    this.totalApplications = this.gig.slots
      .filter(s => isSlotOpen(s))
      .reduce((acc, s) => acc + s.applications.length, 0);
  }

  remove(removeApplication: Application): void {
    const application = {
      applicationId: removeApplication.booking.id,
      slotId: removeApplication.slot.id,
    };

    const sub = this.apollo
      .mutate({
        mutation: SELECT_APPLICATION,
        variables: { application },
      })
      .subscribe();

    sub.unsubscribe();
  }

  openApplication(application): void {
    this.modal.open(GigApplicationSummaryComponent, { data: { application: application.booking, gig: this.gig } });
  }

  submit(): void {
    this.modal.open(GigBookingComponent, { data: { applications: this.applications, gig: this.gig } });
  }
}
