<div class="profile-picture-uploader">
  <button
    fxLayoutAlign="center center"
    fxLayoutGap="3px"
    (click)="changeProfilePicture($event)"
    class="profile-picture-uploader--button">
    <mat-icon class="white">add_photo_alternate</mat-icon>
    <span i18n>Edit prolife photo</span>
  </button>

  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="12px">
    <!-- no profile picture -->
    <img
      *ngIf="!localProfilePicturePreview && !profilePicture"
      class="profile-picture-uploader--preview"
      src="assets/images/default-avatar.svg" />

    <span
      *ngIf="!localProfilePicturePreview && !profilePicture"
      (click)="changeProfilePicture($event)"
      class="add-photo"
      i18n>
      Add profile photo
    </span>

    <!-- profile picture preview -->
    <img
      *ngIf="!!localProfilePicturePreview"
      class="profile-picture-uploader--preview"
      [src]="localProfilePicturePreview" />

    <!-- uploaded picture -->
    <app-avatar
      *ngIf="profilePicture && !localProfilePicturePreview"
      [profilePicture]="profilePicture"
      [user]="user"
      [specSize]="size"></app-avatar>

    <span
      *ngIf="localProfilePicturePreview || profilePicture"
      (click)="changeProfilePicture($event)"
      class="add-photo"
      i18n>
      Update photo
    </span>
  </div>
</div>
