<a [routerLink]="['/artists/search']" routerLinkActive="bright-red" mat-menu-item i18n="@@artists">Artists</a>
<ng-container *ngIf="user | async as user; else loggedOut">
  <a [routerLink]="['/gigs']" routerLinkActive="bright-red" mat-menu-item i18n="@@gigs">Gigs</a>
  <a [routerLink]="['/bookings']" routerLinkActive="bright-red" mat-menu-item i18n="@@bookings">Bookings</a>
  <a [routerLink]="['/user/settings']" routerLinkActive="bright-red" mat-menu-item i18n="@@settings">Settings</a>
  <a [routerLink]="['/user/artists']" routerLinkActive="bright-red" mat-menu-item i18n="@@yourArtistProfiles"
    >Your artist profiles</a
  >
  <button (click)="logout()" routerLinkActive="bright-red" mat-menu-item i18n="@@logout">Logout</button>
  <app-notifications-dropdown></app-notifications-dropdown>
</ng-container>
<ng-template #loggedOut>
  <a [routerLink]="['/company']" routerLinkActive="bright-red" mat-menu-item i18n="@@company">Company</a>
  <a [routerLink]="['/pricing']" routerLinkActive="bright-red" mat-menu-item i18n="@@pricing">Pricing</a>
  <a [routerLink]="['/how-it-works']" routerLinkActive="bright-red" mat-menu-item i18n="@@howItWorks">How it works</a>
  <a [routerLink]="['/for-artists']" routerLinkActive="bright-red" mat-menu-item i18n="@@forArtists">For artists</a>
  <div fxHide.gt-xs>
    <a [routerLink]="['/login']" routerLinkActive="bright-red" mat-menu-item i18n="@@login">Log in</a>
    <a [routerLink]="['/signup']" routerLinkActive="bright-red" mat-menu-item i18n="@@signUp">Sign up</a>
  </div>
</ng-template>
