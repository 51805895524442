import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {
  DenyReason,
  FindOnePageNoReviewsGQL,
  FindOnePageNoReviewsQueryVariables,
  Page,
  PageStatusEnum,
  ReviewStatusEnum,
  SendPageForReviewGQL,
  UpdatePageSlugGQL,
} from '../../../../generated/graphql';
import {
  ArtistProgress,
  ArtistProgressStep,
  ProgressStepNumber,
  ProgressStepType,
} from '../../../models/ArtistProgress';
import { ArtistProgressService } from '../../../services/artist-progress.service';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';
import { InfoBarType } from '../../../shared/components/info-bar/info-bar.component';
@Component({
  selector: 'app-artist-detail-edit',
  templateUrl: './artist-detail-edit.component.html',
  styleUrls: ['./artist-detail-edit.component.scss'],
})
export class ArtistDetailEditComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  public onUrlSlugChanged$: EventEmitter<any> = new EventEmitter();
  public page$: Observable<Page>;

  public pageProgress: ArtistProgress;
  public openedStepNumber: number = 1;
  public denyReasonOthers: DenyReason;
  public urlSlugFormGroup: FormGroup;
  public page: Page;

  public isSidenavOpened: boolean = false;
  public reviewLoading: boolean = false;
  public isCreateMode: boolean = false;
  public isEditMode: boolean = false;
  public isLoading: boolean = true;
  public isMobile: boolean = false;

  public INFO_BAR_BASIC_TEXT = $localize`We recommend high quality profile pictures showing people behind the act. Logos and text are discouraged on profile pictures.`;
  public PROGRESS_STEP_NUMBER = ProgressStepNumber;
  public PROGRESS_STEP_TYPE = ProgressStepType;
  public REVIEW_STATUS = ReviewStatusEnum;
  public PAGE_STATUS = PageStatusEnum;
  public INFO_BAR_TYPE = InfoBarType;

  get canBeReviewed(): boolean {
    return this.pageProgress.steps.filter((step) => step.isRequired).every((step) => step.completed);
  }

  constructor(
    private artistProgressService: ArtistProgressService,
    private breakpointObserver: BreakpointObserver,
    private iconsService: IconsRegistryService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,

    private updatePageSlugGQL: UpdatePageSlugGQL,
    private sendForReview: SendPageForReviewGQL,
    private getPage: FindOnePageNoReviewsGQL,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public ngOnInit() {
    this.urlSlugFormGroup = new FormGroup({
      slug: new FormControl(null, [Validators.required]),
    });

    this.isMobile = this.breakpointObserver.isMatched('(max-width: 960px)');

    if (this.router.url.includes('create')) {
      this.isCreateMode = true;
      this.page = {};
      this.setPageProgress();
      this.openedStepNumber = this.openedStepNumber + 1;
      this.isLoading = false;
    } else {
      this.route.params.subscribe((params) => {
        this.setPage(params.pageId);
      });
      if (this.route.snapshot.queryParams.step) {
        this.openedStepNumber = parseFloat(this.route.snapshot.queryParams.step);
      }
    }

    this.onUrlSlugChanged$.pipe(debounceTime(2000), distinctUntilChanged()).subscribe(() => {
      this.updatePageSlug();
    });
  }

  private setPage(paramId: string): void {
    const pageId = parseInt(paramId, 10);
    const searchOps: FindOnePageNoReviewsQueryVariables = {};

    if (!isNaN(pageId)) {
      searchOps.pageId = pageId;
    } else {
      searchOps.slug = paramId;
    }

    this.getPage
      .watch(searchOps, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(
        map(({ data, loading }) => {
          this.urlSlugFormGroup.get('slug').setValue(data.findOnePage.slug);
          this.page = data.findOnePage;
          this.isLoading = loading;
          this.setPageProgress();
          if (data.findOnePage.denyReasons) {
            this.denyReasonOthers = data.findOnePage.denyReasons.find((dr) => dr.title === 'Other');
          }
          return data.findOnePage;
        }),
      )
      .subscribe();
  }

  public onSidenavChange(isSidenavOpened: boolean): void {
    this.isSidenavOpened = isSidenavOpened;
  }

  public setPageProgress(): void {
    this.pageProgress = this.artistProgressService.getArtistProgress(this.page);
  }

  public getPageProgressStep(section: ProgressStepType): ArtistProgressStep {
    return this.pageProgress.steps.find((step) => step.type === section);
  }

  public onTabChange(nextStep: ArtistProgressStep): void {
    if (this.page.id) {
      this.openedStepNumber = nextStep.stepNumber;
      this.changeRouterParam();
      this.setPageProgress();
    }
  }

  public goToStep(stepNumberOffset: number): void {
    const newStepNumber = this.openedStepNumber + stepNumberOffset;
    if (newStepNumber >= 1 && newStepNumber <= this.pageProgress.stepsTotal) {
      this.openedStepNumber = newStepNumber;
      this.changeRouterParam();
      this.setPageProgress();
    }
  }

  public changeRouterParam(): void {
    this.router.navigate([], {
      queryParams: { step: this.openedStepNumber },
      queryParamsHandling: 'merge',
    });
  }

  public sendPageForReview(): void {
    this.isLoading = true;
    if (this.pageProgress.stepsTotal === this.pageProgress.stepsCompleted) {
      this.sendForReview.mutate({ pageId: this.page.id }).subscribe(() => {
        this.isLoading = false;
        this.snackbar.open($localize`Succesfully saved changes!`, undefined, { duration: 3000 });
      });
    }
  }

  public updatePageSlug(): void {
    this.updatePageSlugGQL
      .mutate({
        page: {
          id: this.page.id,
          slug: this.urlSlugFormGroup.value.slug,
        },
      })
      .subscribe(({ data }) => {
        this.router.navigate(['../', 'artists', data.updatePage.slug, 'edit']);
        this.snackbar.open($localize`Succesfully saved changes!`, undefined, { duration: 3000 });
      });
  }
}
