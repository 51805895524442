import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Booking, BookingDateInput, BookingDates, Currency, UpdateBookingGQL } from '../../../../../generated/graphql';
import { apiToDate } from '../../../../helpers';
import {
  dateToApi,
  getGigSlotSetLength,
  getTimeLengthOptionsForSelect,
  getTimeOptionsForSelectDropdown,
  timestampToTimeString,
  timeStringToTimestamp,
} from '../../../../helpers/dates';
import { SelectInputOptions } from '../../../../shared/components/inputs/select-input/select-input.component';

@Component({
  selector: 'app-edit-booking-date-form',
  templateUrl: './edit-booking-date-form.component.html',
  styleUrls: ['./edit-booking-date-form.component.scss'],
})
export class EditBookingDateFormComponent implements OnInit {
  @Input() bookingDate: BookingDates;
  @Input() booking: Booking;

  @Output() onCancelClick$: EventEmitter<any> = new EventEmitter();

  public gigStartTimeOptions: SelectInputOptions[] = [];
  public timeLengthOptions: SelectInputOptions[] = [];

  public formGroup: FormGroup;
  public todayDate: Date;
  public date: Date;

  public constructor(private updateBookingGQL: UpdateBookingGQL, private snackbar: MatSnackBar) {}

  public ngOnInit(): void {
    this.todayDate = new Date();

    this.date = this.todayDate;
    if (this.bookingDate?.date) {
      const d = apiToDate(this.bookingDate.date);
      d.setDate(d.getDate() - 1);
      this.date = d;
    }

    console.log(this.todayDate);

    this.gigStartTimeOptions = getTimeOptionsForSelectDropdown();
    this.timeLengthOptions = getTimeLengthOptionsForSelect();

    if (this.bookingDate) {
      this.formGroup = new FormGroup({
        date: new FormControl([this.date], Validators.required),
        startTime: new FormControl(timestampToTimeString(this.bookingDate?.startTime)),
        setLength: new FormControl(getGigSlotSetLength(this.bookingDate?.startTime, this.bookingDate?.endTime)),
        price: new FormControl(this.bookingDate?.price, Validators.required),
      });
    } else {
      this.formGroup = new FormGroup({
        date: new FormControl([new Date()], Validators.required),
        startTime: new FormControl('19:00', Validators.required),
        setLength: new FormControl('00:30', Validators.required),
        price: new FormControl(null, Validators.required),
      });
    }
  }

  public saveChanges(): void {
    const bookingDatesInput = this.booking.dates.map((date) => this.generateDateInput(date));

    let newBooking: BookingDateInput = {
      id: this.bookingDate?.id || undefined,
      date: dateToApi(this.formGroup.value.date[0]),
      price: {
        value: parseInt(this.formGroup.value.price),
        currency: this.booking?.calculatedPricing?.currency || Currency.Sek,
      },
    };

    const start = new Date(timeStringToTimestamp(new Date(this.formGroup.value.date), this.formGroup.value.startTime));
    const [hours, minutes] = this.formGroup.value.setLength.split(':').map(Number);

    const end = new Date(start.getTime());
    end.setHours(end.getHours() + hours);
    end.setMinutes(end.getMinutes() + minutes);

    newBooking.startTime = timeStringToTimestamp(new Date(this.formGroup.value.date), this.formGroup.value.startTime);
    newBooking.endTime = end.toISOString();

    const editableDateIndex = bookingDatesInput.findIndex((d) => d.id === this.bookingDate?.id);
    if (editableDateIndex > -1) {
      bookingDatesInput[editableDateIndex] = newBooking;
    } else {
      bookingDatesInput.push(newBooking);
    }

    this.updateBookingGQL
      .mutate({
        bookingId: this.booking.id,
        booking: {
          dates: bookingDatesInput,
        },
      })
      .subscribe(
        ({ data }) => {
          this.snackbar.open($localize`Succesfully saved changes!`, undefined, { duration: 3000 });
          this.onCancelClick$.next();
        },
        () => this.snackbar.open($localize`Could not save changes`, $localize`Try again`, { duration: 3000 }),
      );
  }

  private generateDateInput(date): BookingDateInput {
    return {
      id: date.id || undefined,
      date: dateToApi(apiToDate(date.date)),
      price: {
        value: parseInt(date.price),
        currency: this.booking.dates.find((d) => d.id === date.id)?.priceCurrency || Currency.Sek,
      },
      startTime: date.startTime ? timeStringToTimestamp(new Date(parseInt(date.date)), date.startTime) : '',
      endTime: date.endTime ? timeStringToTimestamp(new Date(parseInt(date.date)), date.endTime) : '',
    };
  }
}
