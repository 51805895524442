import { Component, OnInit } from '@angular/core';
import { GetContentfulEntryGQL } from '../../../generated/graphql';
import { richTextToHtml } from '../../helpers';
import { MetaService } from '../../services/meta.service';
import { TranslationsService } from '../../services/translations.service';

@Component({
  templateUrl: './for-artists.component.html',
  styleUrls: ['./for-artists.component.scss'],
})
export class ForArtistsComponent implements OnInit {
  constructor(
    private getContentfulEntry: GetContentfulEntryGQL,
    private translations: TranslationsService,
    private meta: MetaService,
  ) {}

  isLoading = true;
  hero;
  infoBlock;
  tabbedInfo;
  selectedTab = 0;
  testimonials;
  faq;

  ngOnInit() {
    this.getContentfulEntry
      .fetch({ name: 'For artists', locale: this.translations.getCurrentLocale() })
      .subscribe((result) => {
        const page = result.data.getContentfulEntry.contentfulData;
        this.meta.setContentfulSeoData(page.fields);
        this.hero = page.fields.blocks.find((e) => e.sys.contentType.sys.id === 'hero');
        this.infoBlock = page.fields.blocks.find((e) => e.sys.id === '1MxHOho3FTy7GI2wND51Dq');
        this.tabbedInfo = page.fields.blocks.find((e) => e.sys.id === 'UyY6LcEmdLVdU5jOdB7jh');
        this.testimonials = page.fields.blocks.find((e) => e.sys.id === '22Gdi7X7RcZ3ULyOdO0E7M');
        this.faq = page.fields.blocks.find((e) => e.sys.id === '5JquXpv9gws7WhQKeOzKLY');
        this.isLoading = false;
      });
  }

  richTextToHtml(text) {
    return richTextToHtml(text);
  }
}
