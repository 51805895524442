import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageLink } from '../../../../generated/graphql';
import { socialMediaId } from '../../../models/SocialMediaType';

@Component({
  selector: 'app-edit-media-modal',
  templateUrl: './edit-media-modal.component.html',
  styleUrls: ['./edit-media-modal.component.scss'],
})
export class EditMediaModalComponent implements OnInit, AfterViewInit {
  constructor(
    public activeModal: MatDialogRef<EditMediaModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { videos: PageLink[]; songs: PageLink[] },
  ) {}
  inputMediaLink = '';
  hasMatched = false;
  showHelp = false;
  noMatch = false;
  hasSpotifyArtist = false;
  private regexes = {
    // tslint:disable-next-line:max-line-length
    youtube:
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
    spotifyTrack: /^((spotify:|https:|http:)\/\/[a-z]+\.spotify\.com\/track)/,
    spotifyArtist: /^((spotify:|https:|http:)\/\/[a-z]+\.spotify\.com\/artist)/,
    soundcloud: /^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/,
    vimeo: /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/,
    mixCloud: /^https?:\/\/(www\.)?(mixcloud\.com)\/(.*)$/,
  };

  medias: {
    spotify: PageLink[];
    soundcloud: PageLink[];
    videos: PageLink[];
    mixCloud: PageLink[];
  };

  urlInGroup = new FormGroup({
    url: new FormControl(''),
  });

  selected = new FormControl(0);

  ngOnInit() {
    this.medias = {
      videos: this.data.videos,
      spotify: this.data.songs.filter((s) => s.socialMediaId === socialMediaId.SPOTIFY),
      soundcloud: this.data.songs.filter((s) => s.socialMediaId === socialMediaId.SOUND_CLOUD),
      mixCloud: this.data.songs.filter((s) => s.socialMediaId === socialMediaId.MIX_CLOUD),
    };
  }

  closeModal() {
    this.activeModal.close();
  }

  ngAfterViewInit() {
    Promise.resolve(null).then(() => {
      // this.selected.setValue(1);
    });
  }

  highlightMedia(type: string, index: number, remove: boolean = false) {
    this.medias[type] = this.medias[type].map((s) => {
      s.isHighlight = false;
      return s;
    });
    if (!remove) {
      this.medias[type][index].isHighlight = true;
    }
  }

  removeMedia(type: string, index: number) {
    this.medias[type].splice(index, 1);
  }

  async addMedia() {
    // Wait 1 tick for the formgroup to update its values
    await new Promise((resolve) => setTimeout(resolve));

    this.inputMediaLink = this.urlInGroup.get('url').value;

    if (this.inputMediaLink.length === 0) {
      return;
    }

    const matchResult = this.testMatchingUrl(this.inputMediaLink);

    let pageMedia: PageLink = {
      url: this.inputMediaLink,
      socialMediaTypeId: 2,
      socialMediaId: -1,
      order: -1,
    };

    this.noMatch = false;
    this.hasSpotifyArtist = false;

    switch (matchResult) {
      case 'youtube':
        pageMedia.socialMediaId = socialMediaId.YOUTUBE;
        this.medias.videos.push(pageMedia);
        if (this.medias.videos.length === 1) {
          this.highlightMedia('videos', 0);
        }
        break;
      case 'soundcloud':
        pageMedia.socialMediaId = socialMediaId.SOUND_CLOUD;
        this.medias.soundcloud.push(pageMedia);
        break;
      case 'spotifyTrack':
        pageMedia.socialMediaId = socialMediaId.SPOTIFY;
        this.medias.spotify.push(pageMedia);
        break;
      case 'spotifyArtist':
        if ((this.medias.spotify as PageLink[]).find((t) => t.isHighlight)) {
          this.hasSpotifyArtist = true;
          pageMedia = null;
          this.hasMatched = false;
          return;
        }
        pageMedia.socialMediaId = socialMediaId.SPOTIFY;
        pageMedia.isHighlight = true;
        this.medias.spotify.push(pageMedia);
        break;
      case 'vimeo':
        pageMedia.socialMediaId = socialMediaId.VIMEO;
        this.medias.videos.push(pageMedia);
        if (this.medias.videos.length === 1) {
          this.highlightMedia('videos', 0);
        }
        break;
      case 'mixCloud':
        pageMedia.socialMediaId = socialMediaId.MIX_CLOUD;
        this.medias.mixCloud.push(pageMedia);
        break;

      default:
        this.hasMatched = false;
        pageMedia = null;
        this.noMatch = true;
        return;
    }

    this.hasMatched = false;

    switch (pageMedia.socialMediaId) {
      case socialMediaId.YOUTUBE:
      case socialMediaId.VIMEO:
        this.selected.setValue(0);
        break;
      case socialMediaId.SOUND_CLOUD:
        this.selected.setValue(2);
        break;
      case socialMediaId.SPOTIFY:
        this.selected.setValue(1);
        break;
      default:
        break;
    }

    if (!this.noMatch) {
      this.inputMediaLink = '';
      this.urlInGroup.get('url').setValue('');
    }
  }

  private testMatchingUrl(url: string): string | null {
    for (const reg in this.regexes) {
      if (this.regexes.hasOwnProperty(reg)) {
        const element: RegExp = this.regexes[reg];
        if (element.test(url)) {
          this.hasMatched = true;
          this.showHelp = false;
          return reg;
        }
      }
    }
    return null;
  }

  save() {
    const links = [...this.medias.soundcloud, ...this.medias.spotify, ...this.medias.videos, ...this.medias.mixCloud];
    this.activeModal.close({ links });
  }
}
