import { ValidationErrors, ValidatorFn } from '@angular/forms';

export const contactInfoValidator =
  (includeUrls = false): ValidatorFn =>
  (control): ValidationErrors | null => {
    if (doesTextContainContactInfo(control.value)) {
      return { contactInfo: { value: control.value } };
    }
    if (includeUrls && doesTextContainUrl(control.value)) {
      return { contactInfo: { value: control.value } };
    }
    return null;
  };

export const doesTextContainContactInfo = (inputText: string) => {
  return !!inputText && (doesTextContaintEmail(inputText) || doesTextContaintPhoneNumber(inputText));
};

export const doesTextContaintEmail = (text: string) => {
  // tslint:disable-next-line:max-line-length
  const mailReg =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gm;
  return !!text.match(mailReg);
};
export const doesTextContaintPhoneNumber = (text: string) => {
  // Matches numbers longer than 8 chars starting with 0 or + and can include spaces or dashes
  const phoneReg = /(?=.{8,})((\b[0]|[+])[1-9])([ -]|)\d+([ -]\d+)*/g;
  return !!text.match(phoneReg);
};

export const doesTextContainUrl = (text: string) => {
  // tslint:disable-next-line:max-line-length
  const urlReg =
    /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
  return !!text.match(urlReg);
};
