import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PriceInputComponent,
      multi: true,
    },
  ],
})
export class PriceInputComponent implements ControlValueAccessor {
  @Input() currencyShorthand: string = 'SEK';

  @Input() isDisabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() placeholder: number = 0;

  @Input() label: string = '';
  @Input() isLightLabel: false;
  @Input() labelTooltip: string = '';

  @ViewChild('priceInput', { static: true }) priceInput: ElementRef<HTMLInputElement>;

  public priceValue: number = 0;

  get value() {
    return this.priceValue;
  }

  set value(value: number) {
    this.priceValue = value;

    this.onTouch(value);
    this.onChange(value);
  }

  public get priceInputWidth(): string {
    return this.priceValue ? this.priceValue.toString().length * 10 + 'px' : '10px';
  }

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: any): void {
    this.value = value;
    this.priceInput.nativeElement.value = value;
  }

  private onChange: any = () => {};
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  private onTouch: any = () => {};
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public inputChanges(event) {
    let priceValue: number = parseInt(event.target.value) || 0;
    this.value = priceValue;
  }
}
