<div *ngIf="questionTemplate || pageFaqAnswer" class="question-container">
  <div class="expansion-panel" [ngClass]="{ opened: isPanelOpened }">
    <div fxLayoutAlign="space-between center" class="expansion-panel--header">
      <p>
        <span class="f-weight--600" i18n>Question {{ questionNumber }}</span>
        <span fxHide.lt-md> - </span>
        <span *ngIf="!pageFaqAnswer && questionTemplate" fxHide.lt-md>{{
          questionTemplate.questions[0]?.question
        }}</span>
        <span *ngIf="pageFaqAnswer" fxHide.lt-md>{{ pageFaqAnswer.question }}</span>
      </p>
      <app-checkbox-toggle-slider
        (change)="onToggleClick()"
        [(ngModel)]="isQuestionActive"></app-checkbox-toggle-slider>
    </div>

    <p fxHide fxShow.lt-md class="f-weight--600 question-text-mobile">
      <span *ngIf="questionTemplate">{{ questionTemplate.questions[0].question }}</span>
      <span *ngIf="pageFaqAnswer">{{ pageFaqAnswer.question }}</span>
    </p>

    <div fxLayout="column" fxLayoutGap="34px" *ngIf="!isEditMode && pageFaqAnswer?.answer" class="p-t--40">
      <p>{{ pageFaqAnswer.answer }}</p>
      <button fxFlexAlign="end" class="gig-button stroked" (click)="isEditMode = !isEditMode" i18n> Edit </button>
    </div>

    <div *ngIf="isEditMode" class="expansion-panel--content" [@expansion]>
      <form [formGroup]="questionForm" fxLayout="column">
        <app-input
          *ngIf="isEditMode"
          [multiLine]="true"
          [rows]="5"
          formControlName="answer"
          class="m-b--12"
          i18n-placeholder
          i18n-label>
        </app-input>

        <button
          *ngIf="isEditMode"
          fxFlexAlign="end"
          class="gig-button primary"
          [disabled]="!questionForm.valid || !questionForm.dirty || (questionForm.touched && !questionForm.valid)"
          (click)="savePageAnswer()"
          i18n>
          Save
        </button>
      </form>
    </div>
  </div>
</div>
