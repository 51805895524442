<div
  class="avatar"
  [ngStyle]="{ 'width.px': specSize, 'height.px': specSize, 'border-radius': borderRadius }"
  [ngClass]="{
    'avatar--tiny': tiny,
    'avatar--mini': mini,
    'avatar--small': small,
    'avatar--medium': medium,
    'avatar--intermediate': intermediate,
    'avatar--big': big,
    'avatar--large': large,
    'avatar--huge': huge,
    'avatar--ballin': ballin,
    'avatar--humongous': humongous,
    'show-bg': !profilePictureLoaded,
    'no-overflow': noOverflow,
    round: !square
  }"
  fxLayoutAlign="center center">
  <span *ngIf="customText" [title]="customText">{{ customText }}</span>

  <app-cloud-image
    [maxWidth]="maxWidth"
    [class.fill]="fill"
    *ngIf="profilePicture && !profilePictureError"
    (pictureLoaded)="picLoaded()"
    (pictureError)="picError()"
    [profilePicture]="profilePicture"
    [altText]="'Profile picture'"
    i18n-altText>
  </app-cloud-image>
  <span *ngIf="!profilePicture" class="deafault-picture">
    <img src="assets/images/default-avatar.svg" />
  </span>
</div>
