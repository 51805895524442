<section fxLayout="column" *ngIf="booking && currentUser && !cancelPolicyOpen">
  <div class="booking-header">
    <h2 class="h6 f-weight--600" i18n>Booking details</h2>
    <div fxLayoutAlign="end center" class="">
      <mat-icon fxHide fxShow.lt-sm class="close gray-1 pointer" (click)="closeDialog()" inline>close</mat-icon>
    </div>
  </div>

  <div class="booking-content">
    <div class="booking-content--header border-bottom">
      <div fxLayout="row" fxLayoutGap="10px" class="m-b--30">
        <div class="image-container">
          <app-cloud-image
            *ngIf="booking.appliedTo?.images?.length > 0"
            class="booking-image"
            [cover]="true"
            [routerLink]="'../' + booking?.id"
            [altText]="booking.appliedTo?.name + ' picture'"
            [backgroundPicture]="booking.appliedTo?.images[0]"
            [maxWidth]="117"
            i18n-altText>
          </app-cloud-image>

          <app-fallback-image
            *ngIf="!booking.appliedTo?.images || booking.appliedTo?.images.length === 0"
            class="booking-image"
            [gig]="booking"
            [borderRadius]="'8px'"
            [textSize]="'10px'"></app-fallback-image>
        </div>

        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="4px" class="p-t--8">
          <h3 class="booking-subtitle">{{ booking.type | titlecase }} gig #{{ booking.id }}</h3>

          <p>
            <span *ngIf="headerBookingStatus === HEADER_BOOKING_STATUS.Open" class="booking-status open" i18n
              >Open</span
            >

            <span *ngIf="headerBookingStatus === HEADER_BOOKING_STATUS.Completed" class="booking-status completed" i18n
              >Completed</span
            >

            <span *ngIf="headerBookingStatus === HEADER_BOOKING_STATUS.Declined" class="booking-status cancelled" i18n
              >Declined</span
            >

            <span *ngIf="headerBookingStatus === HEADER_BOOKING_STATUS.Cancelled" class="booking-status cancelled" i18n
              >Cancelled</span
            >

            <span *ngIf="headerBookingStatus === HEADER_BOOKING_STATUS.Expired" class="booking-status cancelled" i18n
              >Date expired</span
            >
          </p>
        </div>
      </div>

      <div fxLayoutGap="22px" class="m-b--24">
        <p fxFlex="75px" class="gray-2" i18n>Location</p>
        <a class="gray-1" [href]="locationGoogleMapUrl" target="blank">{{ booking.place.formattedAddress }}</a>
      </div>

      <div fxLayoutGap="22px" class="m-b--24">
        <p fxFlex="75px" class="gray-2" i18n>Parties</p>
        <div fxLayout="row" fxLayoutGap="24px">
          <div
            fxLayoutAlign="start center"
            fxLayoutGap="6px"
            [routerLink]="['/artists', booking.page?.slug ? booking.page?.slug : booking.page?.id]"
            routerLinkActive="router-link-active"
            class="cursor">
            <app-avatar
              *ngIf="booking.page.profilePicture"
              [profilePicture]="booking.page.profilePicture"
              [tiny]="true">
            </app-avatar>
            <span>{{ booking.page?.name }}</span>
          </div>
          <div fxLayoutAlign="start center" fxLayoutGap="6px">
            <app-avatar *ngIf="booking.user" [user]="booking.user" [tiny]="true"> </app-avatar>
            <span>{{ userName }}</span>
          </div>
        </div>
      </div>

      <div fxLayoutGap="22px">
        <p fxFlex="75px" class="gray-2" i18n="@@bookingDescription">Description</p>
        <div class="description">
          <p>{{ descriptionText }}</p>
          <button *ngIf="showDescriptionButton" (click)="toggleDescriptionText()" class="description--show-more">
            <span *ngIf="!showFullDescriptionText" i18n>Read more</span>
            <span *ngIf="showFullDescriptionText" i18n>Read less</span>
          </button>
        </div>
      </div>
    </div>

    <div class="booking-section dates">
      <div fxLayoutAlign="start center" fxLayoutGap="18px" class="m-b--24">
        <mat-icon svgIcon="dates-calendar"></mat-icon>
        <h2 class="booking-subtitle"><span i18n>Proposed dates</span> ({{ booking.dates?.length }})</h2>
      </div>

      <div>
        <div fxLayoutAlign="space-between center" *ngFor="let date of sortedDates" class="booking-date">
          <div fxLayoutAlign="start stretch" fxLayoutGap="9px">
            <div fxFlex="80px" fxLayout="column" fxLayoutAlign="start center" class="booking-date--date">
              <span>{{ getDateTemplate(date.date)?.weekDay }}</span>
              <span class="big">{{ getDateTemplate(date.date)?.date }}</span>
              <span>{{ getDateTemplate(date.date)?.month }}</span>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between start" class="booking-date--details">
              <div fxLayout="column" fxLayoutGap="8px" class="equipment-request--status">
                <span *ngIf="date.cancelledDate !== null && !isBookingOpen" class="status-label cancelled" i18n
                  >Cancelled</span
                >
                <span *ngIf="date.cancelledDate !== null && isBookingOpen" class="status-label cancelled" i18n
                  >Declined</span
                >
                <span *ngIf="!date.isAccepted && date.cancelledDate === null" class="status-label bg-pastel-yellow" i18n
                  >Pending</span
                >
                <span *ngIf="date.isAccepted && date.cancelledDate === null" class="status-label bg-pastel-green" i18n
                  >Accepted</span
                >
              </div>
              <div class="price">{{ date.price | priceSpaceFormat }} kr</div>
              <div class="time">
                <span *ngIf="date?.startTime && date?.endTime; else notSet">
                  <span>{{ getSetTime(date.startTime, date.endTime) }}</span
                  ><span>&nbsp;·&nbsp;{{ getStartTime(date.startTime) }}</span>
                </span>
              </div>
            </div>
          </div>

          <div *ngIf="!amIBooker">
            <button
              *ngIf="(date.cancelledDate !== null || date.isAccepted) && isBookingOpen"
              (click)="removeBookingDateStatus(date)"
              class="gig-button transparent"
              i18n
              >Undo</button
            >
            <button
              *ngIf="!date.isAccepted && date.cancelledDate === null && !isBookingCancelled"
              (click)="cancelBookingDate(date, true)"
              class="gig-button transparent"
              i18n
              >Decline</button
            >
            <button
              *ngIf="isBookingSignedByBoth && date.cancelledDate === null && !isBookingCancelled"
              (click)="cancelBookingDate(date, true)"
              class="gig-button transparent"
              i18n
              >Cancel</button
            >
            <button
              *ngIf="!date.isAccepted && isBookingOpen && date.cancelledDate === null"
              (click)="acceptBookingDate(date)"
              class="gig-button black"
              i18n
              >Accept</button
            >
          </div>

          <div *ngIf="amIBooker" fxLayoutAlign="end start" fxLayoutGap="8px">
            <button
              type="button"
              *ngIf="canIChangeDates && date.cancelledDate === null && booking.dates.length > 1"
              (click)="deleteBookingDate(date)"
              class="gig-button action"
              i18n>
              <mat-icon svgIcon="trash-bin-2"></mat-icon>
            </button>

            <button
              type="button"
              *ngIf="canIEditBooking"
              (click)="openDateEditMode(date)"
              class="gig-button action"
              i18n>
              <mat-icon svgIcon="edit"></mat-icon>
            </button>
          </div>
        </div>
      </div>

      <button
        *ngIf="canIEditBooking && isBookingOpen && amIBooker"
        (click)="openDateEditMode()"
        class="gig-button transparent active-link-color"
        >+ Add date</button
      >
    </div>

    <div class="booking-section">
      <div fxLayout="column" fxLayoutGap="12px" *ngIf="!amIBooker">
        <div fxLayoutAlign="start center" fxLayoutGap="18px"
          ><mat-icon svgIcon="travel-allowance" class="subtitle-icon"></mat-icon
          ><h4 class="booking-subtitle" i18n>Travel allowance</h4>
        </div>
        <div class="booking-section--content">
          <span *ngIf="booking.travelPrice">{{ booking.travelPrice }} {{ booking.dates[0].priceCurrency }}</span>
          <span *ngIf="!booking.travelPrice || (!booking.travelPrice && !canIEditBooking)" i18n>not set</span>
        </div>
      </div>
      <div *ngIf="amIBooker" fxLayoutAlign="space-between start" class="m-b--4">
        <div>
          <div fxLayoutAlign="space-between center" fxLayoutGap="18px"
            ><mat-icon svgIcon="travel-allowance" class="subtitle-icon"></mat-icon
            ><h4 class="booking-subtitle" i18n>Travel allowance</h4></div
          >
          <div class="booking-section--content">
            <span *ngIf="booking.travelPrice">{{ booking.travelPrice }} {{ booking.dates[0].priceCurrency }}</span>
            <span *ngIf="!booking.travelPrice || (!booking.travelPrice && !canIEditBooking)" i18n>not set</span>
          </div>
        </div>

        <button
          *ngIf="booking.travelPrice && canIEditBooking"
          class="gig-button stroked small"
          (click)="openTravelAllowanceModal()"
          i18n>
          Edit
        </button>
        <button
          *ngIf="!booking.travelPrice && amIBooker && canIEditBooking"
          (click)="openTravelAllowanceModal()"
          class="gig-button primary black"
          i18n>
          Add
        </button>
      </div>
    </div>

    <div class="booking-section">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayoutAlign="start center" fxLayoutGap="18px"
          ><mat-icon svgIcon="technical-equipment" class="subtitle-icon"></mat-icon
          ><h4 class="booking-subtitle" i18n>Technical equipment</h4></div
        >
        <button *ngIf="amIBooker && canIEditBooking" class="gig-button stroked small" (click)="openEditSupplies()" i18n>
          Edit
        </button>
      </div>
      <div *ngIf="!booking.supplies?.length" class="booking-section--content">
        <span i18n>none</span>
      </div>
      <app-equipment-icons
        *ngIf="booking.supplies?.length > 0"
        class="d--block m-b--30"
        [equipments]="booking.supplies"></app-equipment-icons>

      <app-booking-equipment-request
        *ngIf="booking.supplyStatus !== EQUIPMENT_STATUS.None"
        [canIEditBooking]="canIEditBooking"
        [currentUser]="currentUser"
        [amIBooker]="amIBooker"
        [booking]="booking"
        class="d--block m-t--22"></app-booking-equipment-request>
    </div>

    <div fxLayout="column" class="booking-section pricing">
      <app-booking-price-details
        [isEquipmentSupply]="booking.supplyStatus === EQUIPMENT_STATUS.Supplied"
        [calculatedPricing]="booking.calculatedPricing"
        [relevantDates]="relevantDates"
        [dates]="booking.dates"
        [amIBooker]="amIBooker"
        [booking]="booking">
      </app-booking-price-details>
    </div>

    <div fxLayout="column" fxLayoutGap="12px" *ngIf="amIBooker" class="booking-section payment">
      <div fxLayoutAlign="start center" fxLayoutGap="18px"
        ><h4 class="booking-subtitle" i18n>Billing powered by Stripe</h4></div
      >
      <div>
        <p class="stripe-text" i18n
          >Payment options include paying by card, bank transfer or Klarna. You can chose your preferred payment option
          after you've received the invoice from Gigital. The invoice i sent out no earlier than 7 days before the first
          gig date.</p
        >
      </div>
      <div fxLayoutAlign="start center" fxLayoutGap="18px"><h4 class="booking-subtitle" i18n>Invoice details</h4></div>
      <app-contract-billing-info
        [booking]="booking"
        [loading]="isLoadingBilling"
        (selectBillingInfo)="onSelectBillingInfo($event)"></app-contract-billing-info>
      <!-- <app-settings-details-billing-info [currentUser]="currentUser"></app-settings-details-billing-info> -->
    </div>
  </div>

  <div fxLayoutAlign="stretch center" fxLayoutGap="9px" class="booking-footer">
    <button fxFlex (click)="openContract()" *ngIf="haveISignedBooking" class="gig-button primary white">
      <span i18n>View contract</span>
    </button>

    <button
      fxFlex
      *ngIf="showSign"
      [disabled]="!canISignBooking"
      (click)="openContract()"
      class="gig-button primary white">
      <span i18n>Sign booking</span>
    </button>

    <button
      fxFlex
      fxLayoutAlign="center center"
      *ngIf="!isBookingCompleted && !isBookingCancelled"
      (click)="cancelPolicyOpen = !cancelPolicyOpen"
      class="gig-button stroked">
      <span *ngIf="isBookingSignedByBoth" i18n>Cancel booking</span>
      <span *ngIf="!isBookingSignedByBoth" i18n>Decline booking</span>
    </button>
  </div>

  <div *ngIf="dateEditMode" class="edit-date-window">
    <div fxLayoutAlign="start center" class="title-wrap m-b--45">
      <mat-icon class="close gray-1 pointer" (click)="dateEditMode = !dateEditMode" svgIcon="expand-left"></mat-icon>
      <h1 class="h6 f-weight--600" i18n>Edit date</h1>
    </div>
    <app-edit-booking-date-form
      [booking]="booking"
      [bookingDate]="dateToEdit"
      (onCancelClick$)="dateEditMode = !dateEditMode"></app-edit-booking-date-form>
  </div>
</section>

<ng-container *ngIf="cancelPolicyOpen && !isBookingCancelled">
  <app-cancel-booking
    [booking]="booking"
    [dateToCancel]="dateToCancel"
    [currentUser]="currentUser"
    (goBack)="cancelPolicyOpen = !cancelPolicyOpen"></app-cancel-booking>
</ng-container>

<ng-container> </ng-container>

<ng-template #notSet>
  <span i18n>Time not set</span>
</ng-template>
