import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { GetTagsGQL, Page, PageCategory, Tag, UpdatePageTagsGQL } from '../../../../../../generated/graphql';
import { tagArraysEquils } from '../../../../../../utils/tagsUtils';
import { TagGroupEnum } from '../../../../../helpers/pages';
import { ArtistProgressStep, ProgressStepNumber } from '../../../../../models/ArtistProgress';
import { IconsRegistryService, IconSubsets } from '../../../../../services/icons-registry.service';
import { TranslationsService } from '../../../../../services/translations.service';
import { InfoBarType } from '../../../../../shared/components/info-bar/info-bar.component';

export interface ExtendedTag extends Tag {
  translatedName: string;
  isSelected: boolean | undefined;
}
@Component({
  selector: 'app-artist-detail-tags',
  templateUrl: './artist-detail-tags.component.html',
  styleUrls: ['./artist-detail-tags.component.scss'],
})
export class ArtistDetailTagsComponent implements OnInit {
  @Output() goToStep$: EventEmitter<number> = new EventEmitter<number>();

  @Input() pageProgressStep: ArtistProgressStep;
  @Input() page: Page;

  public allTagsList: ExtendedTag[];
  public isLoading: boolean = true;

  public INFO_BAR_TEXT = $localize`Select all genres you cover so we can get to know you and your music better. Tags also affect which searches your appear in, be as specific as you can.`;
  public PROGRESS_STEP_NUMBER = ProgressStepNumber;
  public PAGE_CATEGORY = PageCategory;
  public INFO_BAR_TYPE = InfoBarType;

  get musicTypeTags(): ExtendedTag[] {
    return this.allTagsList.filter((tag) => tag.group === TagGroupEnum.MUSIC_TYPE);
  }

  get isMusicTypeTagsSelected(): boolean {
    return this.musicTypeTags.filter((tag) => tag.isSelected).length > 0;
  }

  get typeOfMusicianTags(): ExtendedTag[] {
    return this.allTagsList.filter((tag) => tag.group === TagGroupEnum.PERFORMER_TYPE);
  }

  get instrumentsTags(): ExtendedTag[] {
    return this.allTagsList.filter((tag) => tag.group === TagGroupEnum.INSTRUMENT);
  }

  get genreTags(): ExtendedTag[] {
    return this.allTagsList.filter((tag) => tag.group === TagGroupEnum.NEW_GENRE);
  }

  get subGenreTags(): ExtendedTag[] {
    return this.allTagsList.filter((tag) => tag.group === TagGroupEnum.SUB_GENRE);
  }

  get isSubGenresAvailable(): boolean {
    return this.subGenreTags.filter((tag) => this.isParentSelected(tag)).length > 0;
  }

  get currentLocale(): string {
    return this.translationsService.getCurrentLocale();
  }

  public constructor(
    private updatePageTagsGQL: UpdatePageTagsGQL,
    private getTagsGQL: GetTagsGQL,

    private translationsService: TranslationsService,
    private iconsService: IconsRegistryService,
    private snackbar: MatSnackBar,
    private renderer: Renderer2,
  ) {
    this.iconsService.registerIcons([IconSubsets.NAVIGATION]);
  }

  public ngOnInit(): void {
    this.getTagsGQL
      .fetch({}, { fetchPolicy: 'network-only' })
      .pipe(
        map(({ data }) => {
          return data.tags
            .filter((tag) => tag.categoryRestrictions.includes(this.page.category || PageCategory.Live))
            .map((tag) => {
              const extendedTag = tag as ExtendedTag;
              if (this.currentLocale === 'sv-se') {
                extendedTag.translatedName = tag.translations?.sv || tag.name;
              } else {
                extendedTag.translatedName = tag.translations?.en || tag.name;
              }
              return extendedTag;
            })
            .map((tag) => {
              const extendedTag = tag as ExtendedTag;
              this.isLoading = false;
              if (this.page?.tags && this.page?.tags.length > 0) {
                extendedTag.isSelected = this.page.tags.filter((pageTag) => pageTag.id === tag.id).length > 0;
                extendedTag.children.map((childTag) => {
                  (childTag as ExtendedTag).isSelected =
                    this.page.tags.filter((pageTag) => pageTag.id === childTag.id).length > 0;
                  return childTag as ExtendedTag;
                });
              }

              return extendedTag;
            });
        }),
      )
      .subscribe((tags) => (this.allTagsList = tags as ExtendedTag[]));

    this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
  }

  public onTagClick(activeTag: ExtendedTag): void {
    const tagIndex = this.allTagsList.findIndex((t) => t.id === activeTag.id);
    this.allTagsList[tagIndex].isSelected = !this.allTagsList[tagIndex].isSelected;

    if (!activeTag.isSelected && activeTag.children.length > 0) {
      activeTag.children.forEach((child) => {
        (child as ExtendedTag).isSelected = false;
        const childTagIndex = this.allTagsList.findIndex((t) => t.id === child.id);
        this.allTagsList[childTagIndex].isSelected = false;
      });

      this.allTagsList.forEach((tag) => {
        const needBeAllowed = tag.allowedBy.find((t) => t.id === activeTag.id);
        if (needBeAllowed) {
          tag.isSelected = false;
        }
      });
    }
  }

  public updatePageTags(): void {
    let selectedTags = this.allTagsList.filter((tag) => tag.isSelected);

    this.allTagsList.forEach((tag) => {
      selectedTags = selectedTags.concat(
        tag.children.filter(
          (tag) => (tag as ExtendedTag).isSelected && !(selectedTags.findIndex((t) => t.id === tag.id) > -1),
        ) as ExtendedTag[],
      );
    });

    if (!tagArraysEquils(this.page.tags, selectedTags)) {
      this.isLoading = true;

      this.updatePageTagsGQL
        .mutate({
          pageId: this.page.id,
          tagIds: selectedTags.map((tag) => tag.id),
        })
        .subscribe((res) => {
          this.isLoading = false;
          this.page.tags = res.data.updatePageTags.tags;
          this.snackbar.open($localize`Your tags have been successfully updated`, undefined, { duration: 3000 });
          this.goToStep$.emit(1);
        });
    } else {
      this.goToStep$.emit(1);
    }
  }

  public isTagAllowed(tag: ExtendedTag): boolean {
    let allowedBySelectedTags: ExtendedTag[] = [];

    tag.allowedBy.forEach((allowedByTag) => {
      allowedBySelectedTags = allowedBySelectedTags.concat(
        this.allTagsList.filter((tag) => tag.id === allowedByTag.id && tag.isSelected),
      );
    });

    return allowedBySelectedTags.length > 0;
  }

  public isParentSelected(tag: ExtendedTag): boolean {
    const parentTag = this.allTagsList.find((t) => t.id === tag?.parent?.id);
    return parentTag?.isSelected;
  }
}
