<div class="booking-status bg-blue" *ngIf="status === 'ready'">
  <p class="mat-body white" i18n
    >This booking is ready to be signed
    <button mat-flat-button color="primary" [routerLink]="['sign']">View contract and sign</button></p
  >
</div>

<div class="booking-status bg-gray-11" *ngIf="status === 'waitingForAcceptingDates'">
  <app-gigital-logo height="12"></app-gigital-logo>

  <p *ngIf="!amIBooker" class="mat-body gray-1 f-size--12" i18n
    >Accept proposed dates to be able to sign the contract</p
  >
  <p *ngIf="amIBooker" class="mat-body gray-1 f-size--12" i18n
    >Waiting for the artist to accept and sign propsed dates</p
  >
</div>

<div class="booking-status bg-blue" *ngIf="status === 'waitingOther'">
  <p class="mat-body white" i18n>Waiting for your counterparty’s signing</p>
</div>

<div class="booking-status bg-blue" *ngIf="status === 'waitingMe'">
  <p class="mat-body white" i18n>Waiting for your signing</p>
</div>

<div class="booking-status bg-green" *ngIf="status === 'completed'">
  <p class="mat-body white" i18n>This booking is complete </p>
</div>

<div class="booking-status bg-green" *ngIf="status === 'done'">
  <p class="mat-body white" i18n>This event has already occured</p>
</div>

<div class="booking-status bg-blue" *ngIf="status === 'review'">
  <p class="mat-body white" i18n
    >You can now review this performance
    <button mat-flat-button color="primary" [routerLink]="['review']">Leave review</button></p
  >
</div>

<div class="booking-status bg-red" *ngIf="status === 'cancelled'">
  <p class="mat-body white" i18n>This booking is cancelled</p>
</div>
