import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChannelMessage, GigitalFile } from '../../../generated/graphql';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss'],
})
export class FilesListComponent implements OnInit {
  public messages: ChannelMessage[];

  constructor(
    private dialogRef: MatDialogRef<FilesListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChannelMessage[],
  ) {}

  public ngOnInit() {
    if (!!this.data && this.data.length > 0) {
      this.messages = this.data;
    }
  }

  public clickCancel(): void {
    this.dialogRef.close();
  }

  public getFileDownloadName(file: GigitalFile): string {
    return `${file.fileName}.${file.fileExtension}`;
  }

  public getDownloadUrl(file: GigitalFile): string {
    return `https://res.cloudinary.com/hthruuqgk/raw/upload/v1566908756/${file.fileName}`;
  }
}
