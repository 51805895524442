<div>
  <div
    *ngIf="!isGigCreated"
    fxLayoutAlign="stretch start"
    fxLayoutGap="120px"
    fxLayout.lt-md="column"
    fxLayoutGap.lt-md="24px"
    class="create-gig-step container">
    <div fxFlex="316px" fxFlex.lt-md="auto" class="create-gig-step--left">
      <h1 class="create-gig-step--title m-b--20" i18n>Everything looks good?</h1>
      <p class="intro-text" i18n>Post your gig to see what artists are available and interested.</p>
    </div>

    <mat-divider fxHide fxShow.lt-md class="w--100per m-b--32"></mat-divider>

    <div class="w--100per">
      <div fxFlex="587px" fxFlex.lt-md="auto">
        <h2 class="h6 f-weight--600" i18n>Summary</h2>

        <div fxLayoutAlign="space-between start">
          <div *ngIf="createGigForm.value.images.length > 0" class="m-b--20">
            <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Event image</h3>
            <div
              *ngIf="createGigForm.value.images[0]"
              class="image-preview"
              [style.background-image]="createGigForm.value.images[0].safeCssUrl">
            </div>
          </div>
          <div fxLayoutAlign="start start" class="m-left--auto">
            <button
              class="gig-button transparet f-weight--600 pink p--0 p-t--5"
              [ngClass]="{ bottom: !createGigForm.value.images[0] }"
              (click)="changeStepNumber$.emit(1)"
              i18n
              >Edit</button
            >
          </div>
        </div>
        <div fxLayoutAlign="start start" class="m-b--20">
          <div fxFlex fxLayoutAlign="start start" fxLayoutGap="119px" fxLayout.lt-md="column" fxLayoutGap.lt-md="20px">
            <div fxFlex="50%">
              <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Type of event</h3>
              <p>{{ gigTypeTitle }}</p>
            </div>
            <div fxFlex="50%">
              <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Location</h3>
              <p>{{ createGigForm.value.formattedAddress }}</p>
            </div>
          </div>
        </div>

        <div class="m-b--20">
          <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Type of act</h3>
          <p>{{ typeOfArt }}</p>
        </div>

        <div class="description">
          <h3 class="subtitle-1 f-weight--600 m-b--16" i18n>Description</h3>
          <p class="m-b--10">{{ createGigForm.value?.name }}</p>
          <p>{{ createGigForm.value?.description }}</p>
        </div>

        <mat-divider class="m-b--20"></mat-divider>

        <div>
          <div fxFlex>
            <div fxLayoutAlign="start start" fxLayoutGap="119px" fxLayout.lt-md="column" fxLayoutGap.lt-md="20px">
              <div fxFlex="50%" class="m-b--20">
                <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Date for event</h3>
                <p *ngFor="let date of datesPreviewArray">{{ date }}</p>
              </div>

              <div fxFlex="50%" class="m-b--20">
                <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Start time</h3>
                <p>{{ createGigForm.value?.startTime }}</p>
              </div>
            </div>

            <div fxLayoutAlign="start start" fxLayoutGap="119px" fxLayout.lt-md="column" fxLayoutGap.lt-md="20px">
              <div fxFlex="50%" class="m-b--20">
                <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Techniqal equipment</h3>
                <p
                  *ngIf="
                    !createGigForm.value.suppliesMixer &&
                    !createGigForm.value.suppliesSpeakers &&
                    !createGigForm.value.suppliesPa
                  "
                  i18n
                  >None</p
                >
                <p *ngIf="createGigForm.value.suppliesMixer" i18n>Mixertable</p>
                <p *ngIf="createGigForm.value.suppliesSpeakers" i18n>Speakers</p>
                <p *ngIf="createGigForm.value.suppliesPa" i18n>PA system</p>
              </div>

              <div fxFlex="50%" class="m-b--20">
                <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Set-length</h3>
                <p>{{ setLengthToDuration }}</p>
              </div>
            </div>

            <div
              *ngIf="createGigForm.value.supplyStatus"
              fxLayoutAlign="start center"
              fxLayoutGap="8px"
              class="p-t--8 m-b--20">
              <mat-icon class="green">done</mat-icon>
              <p i18n>Request to rent technical equipment from artist</p>
            </div>

            <div class="m-b--20">
              <h3 class="subtitle-1 f-weight--600 m-b--8" i18n>Your budget per date</h3>
              <p *ngIf="createGigForm.value?.pageCategory.length === 1 && createGigForm.value?.budgetAll"
                >{{ createGigForm.value?.budgetAll | priceSpaceFormat }} {{ CURRENCY.Sek }}</p
              >

              <ng-container *ngIf="createGigForm.value?.pageCategory.length > 1">
                <p *ngIf="createGigForm.value?.budgetLive"
                  ><span i18n>Live music</span>&nbsp;-&nbsp;{{ createGigForm.value?.budgetLive | priceSpaceFormat }}
                  {{ CURRENCY.Sek }}</p
                >
                <p *ngIf="createGigForm.value?.budgetDJ"
                  ><span i18n>DJs</span>&nbsp;-&nbsp;{{ createGigForm.value?.budgetDJ | priceSpaceFormat }}
                  {{ CURRENCY.Sek }}</p
                >
              </ng-container>
            </div>
          </div>
          <div fxLayoutAlign="start start">
            <button
              class="gig-button transparet f-weight--600 pink p--0 p-t--5"
              (click)="changeStepNumber$.emit(2)"
              i18n
              >Edit</button
            >
          </div>
        </div>

        <mat-divider class="m-b--32"></mat-divider>

        <div class="price-details">
          <h3 class="subtitle-1 f-weight--600 m-b--24" i18n>Preliminary cost details</h3>

          <div>
            <ng-container *ngIf="createGigForm.value.pageCategory?.length > 1">
              <p fxLayoutAlign="space-between center" class="f-size--15 m-b--16">
                <span i18n>Live music budget</span>
                <span
                  >{{ createGigForm.value.tempSlotDate?.length }}
                  <span *ngIf="createGigForm.value.tempSlotDate?.length === 1" i18n>date</span>
                  <span *ngIf="createGigForm.value.tempSlotDate?.length > 1" i18n>dates</span>&nbsp;x&nbsp;{{
                    createGigForm.value?.budgetLive | priceSpaceFormat
                  }}
                  {{ CURRENCY.Sek }}&nbsp;
                </span>
              </p>

              <p fxLayoutAlign="space-between center" class="f-size--15 m-b--24">
                <span i18n>DJ budget</span>
                <span
                  >{{ createGigForm.value.tempSlotDate?.length }}
                  <span *ngIf="createGigForm.value.tempSlotDate?.length === 1" i18n>date</span>
                  <span *ngIf="createGigForm.value.tempSlotDate?.length > 1" i18n>dates</span>&nbsp;x&nbsp;{{
                    createGigForm.value?.budgetDJ | priceSpaceFormat
                  }}
                  {{ CURRENCY.Sek }}&nbsp;
                </span>
              </p>
            </ng-container>

            <ng-container *ngIf="createGigForm.value.pageCategory?.length <= 1">
              <p fxLayoutAlign="space-between center" class="f-size--15 m-b--16">
                <span *ngIf="createGigForm.value?.pageCategory[0] === PAGE_CATEGORY.Live" i18n>Live music budget</span>
                <span *ngIf="createGigForm.value?.pageCategory[0] === PAGE_CATEGORY.Dj" i18n>DJ budget</span>

                <span
                  >{{ createGigForm.value.tempSlotDate?.length }}
                  <span *ngIf="createGigForm.value.tempSlotDate?.length === 1" i18n>date</span>
                  <span *ngIf="createGigForm.value.tempSlotDate?.length > 1" i18n>dates</span>&nbsp;x&nbsp;{{
                    createGigForm.value?.budgetAll | priceSpaceFormat
                  }}
                  {{ CURRENCY.Sek }}&nbsp;
                </span>
              </p>
            </ng-container>

            <mat-divider class="m-b--24"></mat-divider>

            <p class="f-size--15">
              <span i18n>You will not be charged until you complete one or several bookings. </span>
              <span *ngIf="currentLocale === 'sv-se'">
                <span>För bekräftade bokningar tillkommer</span>&nbsp;<a
                  [href]="POLICY_LINK"
                  class="gray-1 text-underline"
                  >Gigitals avgift*</a
                >&nbsp;
              </span>
              <span *ngIf="currentLocale === 'en'">
                <a [href]="POLICY_LINK" class="gray-1 text-underline">Gigital's fee*</a>&nbsp;<span
                  >is added to all complete bookings.</span
                >&nbsp;
              </span>
              <span i18n>Prices are shown excluding VAT of 25%.</span></p
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isGigCreated" fxLayoutAlign="stretch start" fxLayoutGap="120px" class="create-gig-step container">
    <div fxFlex="316px" class="create-gig-step--left">
      <h1 class="create-gig-step--title m-b--20" i18n>Everything looks good?</h1>
      <p class="intro-text" i18n>Post your gig once you are ready and we will redirect you to chat with the artist</p>
    </div>

    <div>
      <div fxLayout="column" fxLayoutGap="28px" fxFlex="587px">
        <p i18n>The request have been sent! Lorem Ipsum usually answers within 48h.</p>

        <p i18n
          >If in hurry we have selected some fast responders that suits your gig. Send the same request by one click.</p
        >
      </div>
    </div>
  </div>
</div>
