import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardBookings, GetDashboardBookingsGQL, Page, PossibleReviewsGQL } from '../../../../generated/graphql';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss'],
})
export class CustomerDashboardComponent implements OnInit {
  public dashboardBookings$: Observable<DashboardBookings>;
  public possibleReviews$: Observable<Page[]>;

  public reviewsCount: number;

  constructor(private possibleReviews: PossibleReviewsGQL, private getDashboardBookings: GetDashboardBookingsGQL) {}

  ngOnInit(): void {
    this.getdashboardBookings();
    this.getPossibleReviews();
  }

  public getdashboardBookings(): void {
    this.dashboardBookings$ = this.getDashboardBookings.watch().valueChanges.pipe(
      map(({ data }) => {
        data.dashboardBookings.pending = data.dashboardBookings.pending.sort(
          (a, b) =>
            parseInt(a.dates.sort((date, nextDate) => parseInt(date.date) - parseInt(nextDate.date))[0].date) -
            parseInt(b.dates.sort((date, nextDate) => parseInt(date.date) - parseInt(nextDate.date))[0].date),
        );
        return data.dashboardBookings;
      }),
    );
  }

  public getPossibleReviews(): void {
    this.possibleReviews$ = this.possibleReviews.watch().valueChanges.pipe(
      map(({ data }) => {
        this.reviewsCount = data.possibleReviews.length;
        return data.possibleReviews;
      }),
    );
  }
}
