import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Page } from '../../../generated/graphql';

@Component({
  selector: 'app-artist-dropdown',
  templateUrl: './artist-dropdown.component.html',
  styleUrls: ['./artist-dropdown.component.scss'],
})
export class ArtistDropdownComponent implements OnInit, OnChanges {
  @Input()
  artists: Page[];

  @Input()
  hasApplied: Page[] = [];

  @Input()
  mini: boolean = false;

  @Output()
  selectionChanges = new EventEmitter<{ page: Page; hasApplied: boolean }>();

  onDropdownClosed$: EventEmitter<any> = new EventEmitter();

  selectedPage: Page;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.artists?.currentValue) {
      this.setPage(changes.artists.currentValue[0]);
    }
  }

  ngOnInit(): void {
    if (this.artists) {
      this.setPage(this.artists[0]);
    }
  }

  selectPage(page: Page, event) {
    // Just prevent rerendering shit
    if (this.selectedPage != page) {
      this.setPage(page);
    }
    this.onDropdownClosed$.next();
  }

  private setPage(page: Page) {
    this.selectedPage = page;
    this.selectionChanges.next({ page: page, hasApplied: this.hasPageApplied(page) });
  }

  hasPageApplied(page: Page) {
    return this.hasApplied.map((p) => p.id).includes(page.id);
  }
}
