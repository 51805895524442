<main>
  <div class="hero-container" *ngIf="headerData">
    <div class="hero-image">
      <cl-image
        fxShow
        fxHide.lt-sm
        [public-id]="headerData.fields.backgroundImage[0].public_id"
        style="width: 100%; height: 100%"
        dpr="auto"
        responsive
        width="auto"
        crop="scale"
        appLazyLoad
        attr.alt="Cajsa Frangquist">
        <cl-transformation quality="auto" fetch-format="auto" flags="progressive"></cl-transformation>
      </cl-image>

      <cl-image
        fxHide
        fxShow.lt-sm
        [public-id]="headerData.fields.backgroundImageMobile[0].public_id"
        style="width: 100%; height: 100%"
        dpr="auto"
        responsive
        width="auto"
        crop="scale"
        appLazyLoad
        attr.alt="Cajsa Frangquist">
        <cl-transformation quality="auto" fetch-format="auto" flags="progressive"></cl-transformation>
      </cl-image>
    </div>

    <div class="hero container">
      <div fxLayoutAlign="center" class="hero-text">
        <h1 fxFlex="700px" fxFlex.lt-sm="100%" class="h3 white text-center f-weight--600">{{
          headerData.fields.title
        }}</h1>
      </div>
    </div>
  </div>
  <div
    fxLayoutGap="30px"
    fxLayoutAlign="center start"
    fxLayout.lt-sm="column"
    fxLayoutAlign.lt-sm="start center"
    class="price-range container">
    <div fxFlex="384px" fxFlex.lt-md="auto" fxLayout="column" fxLayoutAlign="start start" class="range-snippet">
      <span class="range-snippet--type" i18n>DJ</span>
      <span class="range-snippet--price m-b--24">3 500 - 10 000 kr</span>
      <mat-divider class="w--100per m-b--20"></mat-divider>

      <div class="range-snippet--dependence-list">
        <h2 class="subtitle-2 m-b--16" i18n>Price varies depending on</h2>
        <ul>
          <li i18n>Length of gig</li>
          <li i18n>Distance to event</li>
          <li i18n>Customer’s demands</li>
        </ul>
      </div>

      <button
        [routerLink]="['/artists/search']"
        [queryParams]="{ category: 'DJ' }"
        class="gig-button stroked w--100per bright-red f-size--14"
        i18n
        >Explore DJ's</button
      >
    </div>
    <div fxFlex="384px" fxFlex.lt-md="auto" fxLayout="column" fxLayoutAlign="start start" class="range-snippet">
      <span class="range-snippet--type" i18n>Live music</span>
      <span class="range-snippet--price m-b--24">5 000 - 50 000 kr</span>
      <mat-divider class="w--100per m-b--20"></mat-divider>
      <div class="range-snippet--dependence-list">
        <h2 class="subtitle-2 m-b--16" i18n>Price varies depending on</h2>
        <ul>
          <li i18n>Number of musicians</li>
          <li i18n>Distance to event</li>
          <li i18n>Customer’s demands</li>
        </ul>
      </div>
      <button
        [routerLink]="['/artists/search']"
        [queryParams]="{ category: 'LIVE' }"
        class="gig-button stroked w--100per bright-red f-size--14"
        i18n
        >Explore Live</button
      >
    </div>
  </div>

  <div class="hero-info-container container">
    <div
      class="hero-info"
      fxLayout.gt-sm="row"
      fxLayout="column"
      fxLayoutGap="89px"
      fxLayoutGap.lt-md="36px"
      fxLayoutGap.lt-sm="52px">
      <div class="hero-info-block" *ngFor="let infoBlock of infoBlocks" fxFlex="1/3">
        <h3 class="h5 f-weight--600">{{ infoBlock.fields.ctAs.fields.title }} </h3>
        <div class="hero-info-block--text" [innerHTML]="richTextToHtml(infoBlock.fields.body)"></div>
      </div>
    </div>
  </div>

  <div *ngIf="!!bannerData" class="contact-us-container container">
    <div class="contact-us-banner">
      <div class="contact-us-banner--bg">
        <cl-image
          fxHide.lt-sm
          [public-id]="bannerData.fields.backgroundImage[0].public_id"
          style="width: 100%; height: 100%"
          dpr="auto"
          responsive
          width="auto"
          crop="scale"
          appLazyLoad
          attr.alt="">
          <cl-transformation quality="auto" fetch-format="auto" flags="progressive"></cl-transformation>
        </cl-image>
        <cl-image
          fxHide
          fxShow.lt-sm
          [public-id]="bannerData.fields.backgroundImageMobile[0].public_id"
          style="width: 100%; height: 100%"
          dpr="auto"
          responsive
          width="auto"
          crop="scale"
          appLazyLoad
          attr.alt="">
          <cl-transformation quality="auto" fetch-format="auto" flags="progressive"></cl-transformation>
        </cl-image>
      </div>
      <div fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="end center" class="contact-us-banner--content">
        <h3 fxHide.lt-sm class="white">{{ bannerData.fields.title }}</h3>
        <p fxHide.lt-sm class="white m-b--24">{{ bannerData.fields.body.content[0].content[0].value }}</p>
        <button (click)="openIntercom()" class="gig-button light">{{ bannerData.fields.ctaLabel }}</button>
      </div>
    </div>
  </div>

  <div *ngIf="!!trustedByData" class="trusted-by container">
    <h3 fxHide.lt-sm class="h5 f-weight--600 text-center m-b--70">{{ trustedByData.fields.title }}</h3>

    <div
      fxLayoutAlign="space-between center"
      fxLayoutGap="90px"
      fxLayoutGap.lt-md="36px"
      fxLayout.lt-sm="row wrap"
      fxLayoutAlign.lt-sm="center center"
      fxLayoutGap.lt-sm="20px"
      class="trusted-by--logo-list">
      <div
        fxFlex="1/4"
        fxFlex.lt-sm="calc(33.3% - 20px)"
        *ngFor="let logo of trustedByData.fields.blocks[0].fields.blocks">
        <cl-image
          [public-id]="logo.fields.image[0].public_id"
          style="width: 100%; height: 100%"
          dpr="auto"
          responsive
          width="auto"
          crop="scale"
          appLazyLoad
          attr.alt="">
          <cl-transformation quality="auto" fetch-format="auto"></cl-transformation>
        </cl-image>
      </div>
    </div>

    <div
      fxLayoutAlign="center stretch"
      fxLayoutGap="32px"
      fxLayout.lt-sm="column"
      fxLayoutGap.lt-sm="24px"
      class="trusted-by--reviews">
      <div
        fxFlex="1/3"
        fxFlex.lt-sm="100%"
        *ngFor="let review of trustedByData.fields.blocks[1].fields.blocks"
        class="company-review">
        <div
          fxLayoutAlign="center center"
          [ngClass]="{
            'nordic-logo': review.fields.companyLogo[0].public_id === 'web/nordic_choice_gigital_logo_qabomo'
          }"
          class="company-review--logo m-b--8">
          <cl-image
            [public-id]="review.fields.companyLogo[0].public_id"
            dpr="auto"
            width="auto"
            crop="scale"
            appLazyLoad
            attr.alt="">
            <cl-transformation quality="auto" fetch-format="auto" flags="progressive"></cl-transformation>
          </cl-image>
        </div>
        <p class="company-review--text m-b--16"> "{{ review.fields?.reviewText }}"</p>
        <div fxLayoutAlign="start center" fxLayoutGap="16px" class="company-review--reviwer">
          <div
            *ngIf="review?.fields?.reviewerImage"
            [clBackgroundImage]="review?.fields?.reviewerImage[0]?.public_id"
            class="avatar">
            <cl-transformation quality="auto" fetch-format="auto" flags="progressive" responsive></cl-transformation>
          </div>
          <div>
            <p class="reviwer-name">{{ review.fields.reviewerName }}</p>
            <p class="reviwer-location">{{ review.fields.reviewerLocation }}</p>
          </div>
          <div fxLayout="column"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="faqData">
    <div class="faq">
      <app-faq [faq]="faqData"></app-faq>
    </div>
  </div>
</main>
