<a [routerLink]="['/bookings', booking.id]">
  <div class="card">
    <div fxLayout fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutGap.lt-sm="9px">
        <div *ngIf="amIBooker" fxLayout="column" fxLayoutAlign="center" fxLayout.sm="column">
          <app-avatar
            fxHide.lt-sm
            [medium]="true"
            [user]="booking.page.user"
            [profilePicture]="booking.page.profilePicture"></app-avatar>

          <app-avatar
            fxHide
            fxShow.lt-sm
            [intermediate]="true"
            [user]="booking.page.user"
            [profilePicture]="booking.page.profilePicture"></app-avatar>
        </div>

        <div *ngIf="!amIBooker">
          <div class="image-container">
            <app-cloud-image
              *ngIf="booking.appliedTo?.images?.length > 0"
              class="booking-image"
              [profilePicture]="booking.appliedTo.images[0]"
              [altText]="booking.appliedTo.name + 's image'"
              [maxWidth]="60"
              i18n-altText></app-cloud-image>
            <app-fallback-image
              *ngIf="!booking.appliedTo?.images || booking.appliedTo?.images.length === 0"
              class="booking-image"
              [gig]="booking"
              [borderRadius]="'10px'"
              [textSize]="'6px'"></app-fallback-image>
          </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="11px" fxLayoutGap.lt-sm="8px" class="h--100per">
          <h2 class="h6 gray-1 f-weight--500" fxLayoutAlign="start start" [ngClass]="{ 'artist-view': !amIBooker }">
            <a
              fxHide.lt-sm
              [routerLink]="['/artists', booking?.page?.id]"
              matTooltip="Go to artist page"
              i18n-matTooltip
              class="gray-1">
              {{ booking.page.name }}
            </a>
            &nbsp;<mat-icon class="svg-icon arrows" svgIcon="sync-arrows"></mat-icon>&nbsp;
            <a
              [routerLink]="['/gigs', booking.appliedTo.id]"
              *ngIf="booking.appliedTo"
              matTooltip="Go to gig"
              i18n-matTooltip
              class="gray-1">
              {{ booking.appliedTo.name }}
            </a>

            <span *ngIf="!booking.appliedTo" i18n="gigtype gig">
              {{ translations.gigType(booking.type).translation }} gig</span
            >
          </h2>

          <span
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
            fxLayoutAlign.lt-sm="start start"
            fxLayoutGap.lt-sm="8px"
            fxLayout.lt-sm="column"
            class="booking-info">
            <span fxHide.lt-sm>
              {{ booking.place?.name }}
              <span *ngIf="!booking.place.name" i18n>No place</span>
            </span>
            <span fxHide.lt-sm class="dot-separator"></span>

            <span fxHide.lt-sm> {{ bookingPrice }}&nbsp;{{ booking.dates[0]?.priceCurrency }} </span>

            <span fxHide.lt-sm class="dot-separator"></span>

            <span *ngIf="acceptedDates.length > 0; else noAcceptedDates">
              {{ acceptedDates[0].date | apiDate | date: dateFormat }}
              <span *ngIf="acceptedDates.length > 1">+ {{ acceptedDates.length - 1 }} more</span>
            </span>
            <ng-template #noAcceptedDates>
              <span>
                {{ booking.dates[0].date | apiDate | date: dateFormat }}
                <span *ngIf="booking.dates.length > 1">+ {{ booking.dates.length - 1 }} more</span>
              </span>
            </ng-template>

            <span fxHide fxShow.lt-sm fxFlexOrder.lt-sm="3" *ngIf="booking.status !== BOOKING_STATUS.Signed">
              {{ bookingPrice }}&nbsp;{{ booking.dates[0]?.priceCurrency }}
            </span>

            <span
              fxHide
              fxShow.lt-sm
              *ngIf="booking.status === BOOKING_STATUS.Signed"
              class="booking-status booked"
              i18n>
              Booked
            </span>
          </span>
        </div>
      </div>

      <div *ngIf="bookingStatus$ | async as status; else goToIcon" fxFlex="27%" fxFlex.lt-sm="30px">
        <div *ngIf="status !== 'other' && status !== 'expired'" fxFlex="100%" fxLayoutAlign="space-between center">
          <span fxHide.lt-sm class="booking-status">{{ status }} </span>
          <mat-icon class="svg-icon arrow" svgIcon="expand-left"></mat-icon>
        </div>

        <div
          *ngIf="status === 'other' || status === 'expired'"
          fxHide.lt-sm
          fxFlex="100%"
          fxLayoutAlign="flex-end center">
          <div fxFlex="34%" fxLayoutAlign="flex-start center">
            <span *ngIf="booking.status === BOOKING_STATUS.Cancelled" class="booking-status cancelled" i18n>
              Cancelled
            </span>
            <span *ngIf="booking.status === BOOKING_STATUS.Declined" class="booking-status cancelled" i18n>
              Declined
            </span>
            <span *ngIf="status === 'expired'" class="booking-status cancelled" i18n> Date expired </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>

<ng-template #goToIcon>
  <mat-icon class="svg-icon arrow" svgIcon="expand-left"></mat-icon>
</ng-template>
