<div class="header-container">
  <div class="header-container--bg">
    <cl-image
      [public-id]="homePageMetaData.backgroundImage[0].public_id"
      style="width: 100%; height: 100%"
      dpr="auto"
      responsive
      width="auto"
      crop="scale"
      appLazyLoad
      attr.alt="Cajsa Frangquist">
      <cl-transformation quality="auto" fetch-format="auto" flags="progressive"></cl-transformation>
    </cl-image>
  </div>

  <div class="header-content-container container">
    <div fxLayout="column" fxLayoutAlign="start center" class="header-content">
      <h1 class="h3 header-content--title">{{ homePageMetaData.title }}</h1>
      <button
        fxLayoutAlign="center center"
        fxLayoutGap="4px"
        class="gig-button header-content--button"
        [routerLink]="['/' + homePageMetaData.ctaUrl]">
        <mat-icon svgIcon="loupe"></mat-icon>
        <span>{{ homePageMetaData.ctaLabel }}</span>
      </button>
    </div>
  </div>
</div>

<div class="container user-flow-container">
  <div class="user-flow" fxLayoutAlign="center" fxLayoutAlign.lt-md="start stretch" fxLayoutGap="16px">
    <div
      class="flow-step"
      *ngFor="let step of userFlowSteps; let i = index"
      fxFlex="1/4"
      fxFlex.lt-sm="236px"
      [ngClass]="'flow-step-' + i">
      <span fxLayoutAlign="start center" fxLayoutGap="8px" class="m-b--12">
        <span fxLayoutAlign="center center" class="flow-step--number">
          <span>{{ i + 1 }}</span>
        </span>
        <h3 class="flow-step--title">{{ step.fields.stepTitle }}</h3>
      </span>
      <p class="flow-step--text">{{ step.fields.stepDescription.content[0].content[0].value }}</p>
    </div>
  </div>
</div>
