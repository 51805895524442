<div fxLayout="column" fxLayoutGap="8px" class="contract-container">
  <div fxLayout="column">
    <label class="small-label gray-2" i18n>Company or your name</label>
    <p class="mat-body-2 gray-1">{{ billing.companyName }}</p>
  </div>
  <div fxLayout="column">
    <label class="small-label gray-2" i18n>Organization or social security number</label>
    <p class="mat-body-2 gray-1">{{ billing.orgNumber }}</p>
  </div>
  <div fxLayout="column">
    <label class="small-label gray-2" i18n>Invoice address</label>
    <p class="mat-body-2 gray-1">{{ billing.address }}</p>
  </div>
  <div fxLayout="column">
    <label class="small-label gray-2" i18n>Reference</label>
    <p class="mat-body-2 gray-1">{{ billing.reference }}</p>
  </div>
  <div fxLayout="column">
    <label class="small-label gray-2" i18n>Invoice e-mail</label>
    <p class="mat-body-2 gray-1">{{ billing.email }}</p>
  </div>
</div>
