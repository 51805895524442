import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BookingSignComponent } from '../components/booking-sign/booking-sign.component';
import { BookingComponent } from '../components/booking/booking.component';
import { BookingHeaderComponent } from '../components/booking/components/booking-header/booking-header.component';
import { CancellationPolicyModalComponent } from '../components/booking/components/booking-header/cancel-booking/cancellation-policy-modal/cancellation-policy-modal.component';
import { BookingPriceDetailsComponent } from '../components/booking/components/booking-price-details/booking-price-details.component';
import { BookingTravelAllowanceModalComponent } from '../components/booking/components/booking-travel-allowance-modal/booking-travel-allowance-modal.component';
import { CancelModalComponent } from '../components/booking/components/cancel-modal/cancel-modal.component';
import { EditBookingDateFormComponent } from '../components/booking/components/edit-booking-date-form/edit-booking-date-form.component';
import { ContractBillingDetailComponent } from '../components/contract-billing-detail/contract-billing-detail.component';
import { ContractBillingInfoComponent } from '../components/contract-billing-info/contract-billing-info.component';
import { ContractComponentHeaderComponent } from '../components/contract-component-header/contract-component-header.component';
import { ContractOtherComponent } from '../components/contract-other/contract-other.component';
import { ContractPlaceComponent } from '../components/contract-place/contract-place.component';
import { ContractPriceComponent } from '../components/contract-price/contract-price.component';
import { DateComponent } from '../components/date/date.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { FilesListComponent } from '../components/files-list/files-list.component';
import { PriceInformationComponent } from '../components/price-information/price-information.component';
import { TodosComponent } from '../components/todos/todos.component';
import { GigsModule } from '../gigs/gigs.module';
import { InputModule } from '../input/input.module';
import { LeaveReviewModalComponent } from '../reviews/leave-review-modal/leave-review-modal.component';
import { ReviewsModule } from '../reviews/reviews.module';
import { SharedModule } from '../shared/shared.module';
import { BookingsRoutingModule } from './bookings-routing.module';
import { BookingDetailsModalComponent } from './components/booking-details-modal/booking-details-modal.component';
import { BookingEquipmentRequestComponent } from './components/booking-equipment-request/booking-equipment-request.component';
import { EditSuppliesPriceModalComponent } from './components/booking-equipment-request/edit-supplies-price-modal/edit-supplies-price-modal.component';
import { BookingsFilterComponent } from './components/bookings-filter/bookings-filter.component';
import { EditSuppliesComponent } from './components/edit-supplies/edit-supplies.component';
import { QuickReviewCardComponent } from './components/quick-review-card/quick-review-card.component';
import { QuickReviewSliderComponent } from './components/quick-review-slider/quick-review-slider.component';
import { BookingsDetailSignComponent } from './pages/bookings-detail-sign/bookings-detail-sign.component';
import { BookingsDetailComponent } from './pages/bookings-detail/bookings-detail.component';
import { BookingsListComponent } from './pages/bookings-list/bookings-list.component';

@NgModule({
  declarations: [
    BookingsListComponent,
    BookingsDetailComponent,
    DateComponent,
    BookingComponent,
    BookingHeaderComponent,
    TodosComponent,
    FileUploadComponent,
    ContractPriceComponent,
    ContractComponentHeaderComponent,
    PriceInformationComponent,
    ContractOtherComponent,
    BookingsDetailSignComponent,
    BookingSignComponent,
    ContractBillingInfoComponent,
    ContractBillingDetailComponent,
    ContractPlaceComponent,
    QuickReviewCardComponent,
    LeaveReviewModalComponent,
    FilesListComponent,
    BookingDetailsModalComponent,
    EditSuppliesComponent,
    QuickReviewSliderComponent,
    BookingsFilterComponent,
    BookingEquipmentRequestComponent,
    EditSuppliesPriceModalComponent,
    BookingTravelAllowanceModalComponent,
    BookingPriceDetailsComponent,
    CancelModalComponent,
    CancellationPolicyModalComponent,
    EditSuppliesPriceModalComponent,
    EditBookingDateFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BookingsRoutingModule,
    MatListModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatMenuModule,
    MatTabsModule,
    MatStepperModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTableModule,
    MatCardModule,
    MatProgressBarModule,
    MatRadioModule,
    FlexLayoutModule,
    MatBadgeModule,
    SharedModule,
    InputModule,
    NgxSkeletonLoaderModule,
    ReviewsModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    GigsModule,
  ],
  exports: [BookingComponent, QuickReviewSliderComponent],
  entryComponents: [
    CancellationPolicyModalComponent,
    BookingTravelAllowanceModalComponent,
    FileUploadComponent,
    BookingSignComponent,
    FilesListComponent,
  ],
})
export class BookingsModule {}
