import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { AcceptApplicationGQL, AcceptApplicationInput, Booking, GetGigGQL, Gig } from '../../../../generated/graphql';
import { LONG_DATE } from '../../../helpers';
import { CLEAR_APPLICATIONS, GET_SELECTED_APPLICATIONS, SELECT_APPLICATION } from '../../../resolvers';
import { AnalyticsService } from '../../../services/analytics.service';
import { Application } from '../gigs-booking-bar/gigs-booking-bar.component';

export interface ExtendedBooking extends Booking {
  slot?: {
    id: any;
    date: string;
    price: string;
    priceCurrency: string;
  };
}

@Component({
  selector: 'app-gig-booking',
  templateUrl: './gig-booking.component.html',
  styleUrls: ['./gig-booking.component.scss'],
})
export class GigBookingComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { gig: Gig; applications: Booking[] },

    private modalRef: MatDialogRef<GigBookingComponent>,
    private apollo: Apollo,
    private acceptApplications: AcceptApplicationGQL,
    private analytics: AnalyticsService,
    private getGig: GetGigGQL,
    private snackbar: MatSnackBar,
  ) {}

  loading = false;

  dates: { [key: string]: ExtendedBooking[] } = {};
  dateFormat = LONG_DATE;

  ngOnInit(): void {
    this.apollo
      .watchQuery({
        query: GET_SELECTED_APPLICATIONS,
      })
      .valueChanges.pipe(map(({ data }) => (data as any).selectedApplications))
      .subscribe((selectedApplications: [{ applicationId: number; slotId: number }]) => {
        selectedApplications.forEach((a) => {
          const slot = this.data.gig.slots.find((s) => s.id === a.slotId);
          if (!this.dates[slot.date]) {
            this.dates[slot.date] = [];
          }

          const application: ExtendedBooking = slot.applications.find((app) => app.id === a.applicationId);
          const selectedDate = application.dates.find((d) => d.gigSlot.id === slot.id);
          const newSlot = {
            id: selectedDate.id,
            date: slot.date,
            price: selectedDate.price,
            priceCurrency: selectedDate.priceCurrency,
          };

          this.dates[slot.date].push({ ...application, slot: newSlot });
        });
      });
  }

  selectApplication(applicationInput: Application): void {
    const application = {
      applicationId: applicationInput.booking.id,
      slotId: applicationInput.slot.id,
    };
    const sub = this.apollo
      .mutate({
        mutation: SELECT_APPLICATION,
        variables: { application },
      })
      .subscribe();

    sub.unsubscribe();
  }

  submit(): void {
    this.loading = true;
    const input: AcceptApplicationInput[] = this.getAcceptApplicationInput();

    this.acceptApplications
      .mutate({
        accepts: input,
      })
      .subscribe(
        () => {
          this.loading = false;
          const sub = this.apollo
            .mutate({
              mutation: CLEAR_APPLICATIONS,
            })
            .subscribe();
          sub.unsubscribe();
          this.snackbar.open($localize`Sent booking requests`, undefined, {
            duration: 3000,
          });
          this.analytics.emitEvent('gig_accepted_applications', { accepted_applications_amount: input.length });
          this.apollo.client.resetStore();
          this.modalRef.close();
        },
        (err) => {
          this.loading = false;
          this.snackbar.open($localize`Could not send booking requests`, undefined, {
            duration: 3000,
          });
          console.error(err);
        },
      );
  }

  cancel(): void {
    this.modalRef.close();
  }

  private getAcceptApplicationInput(): AcceptApplicationInput[] {
    let bookings: ExtendedBooking[] = [];
    for (const [key, value] of Object.entries(this.dates)) {
      bookings = [...bookings, ...value];
    }

    const input = [];

    for (const booking of bookings.map((b) => ({
      id: b.id,
      dates: [{ id: b.slot.id, price: parseInt(b.slot.price, 10) }],
    }))) {
      const existing = input.find((i) => i.id === booking.id);
      if (!!existing) {
        existing.dates.push(...booking.dates);
      } else {
        input.push(booking);
      }
    }

    return input;
  }
}
