<div
  class="card"
  [style.background-image]="safeCssUrl"
  [style.background-position-x]="imageData.crop.x + '%'"
  [style.background-position-y]="imageData.crop.y + '%'"
  [style.background-size]="imageData.size"
  fxLayout="column"
  fxLayoutGap="32px"
  fxLayoutAlign="end start">
  <div class="text-container" fxLayout="column" fxLayoutGap="8px">
    <span class="title">{{ title }}</span>
    <span class="sub-title">{{ subTitle }}</span>
  </div>
  <a class="button" [routerLink]="[buttonUrl]">{{ buttonName }}</a>
</div>
