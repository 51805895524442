<app-modal
  [preventScroll]="true"
  [hideSave]="true"
  [hideClose]="true"
  [saveButtonName]="showSaveButtonName"
  (save)="onSave()">
  <div title fxLayout="column" fxLayoutAlign="center start">
    <span i18n class="title"
      >Application for&nbsp;<span class="thick">{{ data.slot.date | date: dateFormat }}</span></span
    >
  </div>
  <div class="modal-container" *ngIf="page | async as page; else loading" fxFlex="grow" fxLayout="column">
    <div>
      <div fxLayout="row" fxLayoutAlign="end end" class="select-button">
        <button mat-flat-button color="primary" (click)="onSave()">{{ showSaveButtonName }}</button>
      </div>
      <div fxShow fxHide.gt-md class="mobile-select-spacer"></div>
    </div>
    <div
      class="header"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="26px"
      fxLayoutGap.lt-md="6px"
      fxLayoutAlign="start center">
      <div class="avatar-container">
        <app-avatar
          [square]="true"
          [maxWidth]="160"
          [profilePicture]="page.profilePicture"
          [ballin]="true"
          [fill]="true"></app-avatar>
      </div>
      <div fxLayout="column" fxLayoutGap="12px" fxLayoutGap.lt-md="6px" fxLayoutAlign.lt-md="center center">
        <h2 class="page-name h6 gray-1"
          ><a [routerLink]="['/artists', page.slug]" target="_blank">{{ page.name }}</a></h2
        >
        <p class="info-tags" i18n *ngIf="page.ownership !== PageOwnership.Subagent"
          ><span class="thick">{{ this.pageTags.type?.name }}</span> based in
          <span class="thick">{{ this.pageTags.location?.name }}</span></p
        >
        <p class="info-tags" i18n *ngIf="page.ownership === PageOwnership.Subagent"
          ><span class="thick">Partner</span> with coverage in <span class="thick">Europe</span></p
        >
        <div fxLayout="row" fxLayoutGap="4px" class="recommendations" *ngIf="page.positiveReviews > 0">
          <span i18n>Recommendations</span>
          <app-reviews-thumbs *ngIf="page.positiveReviews" [numReviews]="page.positiveReviews"> </app-reviews-thumbs>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="4px">
          <div *ngFor="let tag of pageTags.genres" class="tag-chip">{{ tag.name }}</div>
          <div *ngFor="let tag of pageTags.mood" class="tag-chip">{{ tag.name }}</div>
          <div *ngFor="let tag of pageTags.context" class="tag-chip">{{ tag.name }}</div>
        </div>
      </div>
    </div>
    <div class="links" fxLayout="row" fxShow fxHide.lt-md>
      <a
        (click)="scrollToElement(false, applicationEl)"
        class="cursor-pointer"
        [class.highlighted]="!isReviewsHighlit"
        i18n
        >Application</a
      >
      <a
        (click)="scrollToElement(true)"
        class="cursor-pointer"
        [class.highlighted]="isReviewsHighlit"
        *ngIf="page.positiveReviews > 0"
        i18n
        >Reviews</a
      >
    </div>

    <div class="application-data" fxLayout="column" fxLayoutGap="48px">
      <div #applicationEl class="application" fxLayout="column" fxLayoutGap="48px">
        <div fxLayout="column" fxLayoutGap="16px">
          <p class="text-body">{{ data.application.message }}</p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px" class="preview-video-container" *ngIf="!!video">
          <div fxLayout="column" fxLayoutGap="16px">
            <label i18n>Videos</label>
            <div class="video-16-9">
              <div class="video-16-9-loading" fxLayout fxLayoutAlign="center center">
                <mat-spinner [diameter]="64"></mat-spinner>
              </div>
              <app-media-iframe [srcLink]="video"></app-media-iframe>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="16px" *ngIf="songs.length > 0">
            <label i18n>Songs</label>
            <div fxLayout="column" fxLayoutGap="16px">
              <div *ngFor="let song of songs.slice(0, 2)" fxFlex="100%" class="artist-detail-media-song">
                <app-media-iframe
                  [srcLink]="song"
                  [iframeNgClass]="'id-' + song.socialMediaId.toString()"
                  [isArtist]="song.isArtist">
                </app-media-iframe>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="16px">
          <label i18n>Biography</label>
          <p class="text-body">{{ data.application.page.presentation }}</p>
        </div>
      </div>
      <div #rav class="reviews" fxLayout="column" fxLayoutGap="16px" *ngIf="reviews | async as reviews">
        <ng-container *ngIf="page.positiveReviews > 0">
          <label i18n>Reviews</label>
          <div
            class="review"
            fxLayout="row"
            fxLayoutGap="15px"
            fxLayoutGap.lt-md="18px"
            *ngFor="let review of reviews.reviews">
            <div class="review-avatar-container">
              <app-avatar [customText]="reviewerNames[review.id]"></app-avatar>
            </div>
            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div fxLayout="column">
                  <span class="review-name">{{ review.user?.reviewerName || review.reviewer.name }}</span>
                  <span class="review-org">{{ review.user?.companyName || review.reviewer?.company || '' }}</span>
                </div>
                <span class="review-date">{{ review.reviewDate | apiDate | date }}</span>
              </div>
              <span class="review-message">{{ review.message }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</app-modal>

<ng-template #loading>
  <div style="width: 100%; height: 100%" fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
