import { Component, Input, OnInit } from '@angular/core';


// TODO: Move to utils somewhere
const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + min;
};

@Component({
  selector: 'app-plectrum',
  templateUrl: './plectrum.component.html',
  styleUrls: ['./plectrum.component.scss']
})
export class PlectrumComponent implements OnInit {

  @Input()
  size = 64;
  @Input()
  plectrumId = getRandomInt(1, 7);
  @Input()
  background = 'gray-3';
  @Input()
  icon: string;
  @Input()
  iconSize: number;

  constructor() { }

  ngOnInit() {
  }

}
