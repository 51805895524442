import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { BookingFeedType, BookingsByUser, BookingsCount } from '../../../../generated/graphql';

@Component({
  selector: 'app-bookings-filter',
  templateUrl: './bookings-filter.component.html',
  styleUrls: ['./bookings-filter.component.scss'],
})
export class BookingsFilterComponent implements OnInit {
  @Output() onFilterSelect: EventEmitter<BookingFeedType> = new EventEmitter<BookingFeedType>();

  @Input() bookings$: Observable<BookingsByUser>;
  @Input() selectedFilter: BookingFeedType;
  @Input() bookingsCount: BookingsCount;

  BOOKING_FEED_TYPE = BookingFeedType;

  constructor() {}

  ngOnInit(): void {}
}
