import { Component, OnInit } from '@angular/core';
import { NotificationWithSubs, SetNotificationsReadGQL } from '../../../../generated/graphql';
import { NotificationsService } from '../../../services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  hasUnread = false;
  unreads: NotificationWithSubs[];
  constructor(public notifications: NotificationsService, private setUnread: SetNotificationsReadGQL) {}

  ngOnInit() {
    this.notifications.notifications.subscribe(n => {
      this.unreads = n.filter(nt => !nt.notification.isRead) as NotificationWithSubs[];
      this.hasUnread = !!this.unreads && this.unreads.length > 0;
    });
  }

  setAllUnread() {
    this.setUnread.mutate({ all: true }).subscribe(() => {});
  }

  getMore() {
    this.notifications.fetchMoreNotifications();
  }
}
