import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AddBillingToUserGQL,
  Billing,
  BillingFieldsFragment,
  GetBillingInfosDocument,
  UpdateBillingGQL,
} from '../../../generated/graphql';
import { IconsRegistryService, IconSubsets } from '../../services/icons-registry.service';

@Component({
  selector: 'app-add-billing-info',
  templateUrl: './add-billing-info.component.html',
  styleUrls: ['./add-billing-info.component.scss'],
})
export class AddBillingInfoComponent implements OnInit {
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  public billingToEdit: Billing;

  public billingDetailsForm: FormGroup;
  public isEditMode: boolean = false;
  public loading = false;

  private get formControls(): { [key: string]: AbstractControl } {
    return this.billingDetailsForm.controls;
  }

  constructor(
    private addBillingToUserGQL: AddBillingToUserGQL,
    private updateBillingInfoGQL: UpdateBillingGQL,
    private snackbar: MatSnackBar,
    private icons: IconsRegistryService,
  ) {
    this.icons.registerIcons([IconSubsets.ACTIONS]);
  }

  public ngOnInit() {
    // if (this.data) {
    //   this.billingToEdit = this.data.billingInfo;
    // this.isEditMode = true;
    // }
    this.initForm();
  }

  public initForm(): void {
    this.billingDetailsForm = new FormGroup({
      coName: new FormControl(this.billingToEdit?.companyName, Validators.required),
      orgNumber: new FormControl(this.billingToEdit?.orgNumber, Validators.required),
      address: new FormControl(this.billingToEdit?.address, Validators.required),
      email: new FormControl(this.billingToEdit?.email, [Validators.required, Validators.email]),
      reference: new FormControl(this.billingToEdit?.reference),
    });
  }

  public saveChanges(): void {
    if (this.isEditMode) {
      this.updateBillingInfo();
    } else {
      this.addBillingInfo();
    }
  }

  public updateBillingInfo(): void {
    if (this.billingDetailsForm.valid) {
      this.loading = true;
      this.updateBillingInfoGQL
        .mutate({
          billing: {
            id: this.billingToEdit.id,
            ...this.billingDetailsForm.value,
          },
        })
        .subscribe(
          () => {
            this.loading = false;
            this.snackbar.open($localize`Billing data was edit succesfully!`, undefined, { duration: 3000 });
          },
          (error) => this.snackbar.open(error.message, undefined, { duration: 3000 }),
        );
    }
  }

  public addBillingInfo(): void {
    if (this.billingDetailsForm.valid) {
      this.loading = true;
      this.addBillingToUserGQL
        .mutate(
          {
            billing: {
              ...this.billingDetailsForm.value,
              reference: this.billingDetailsForm.get('reference').value ?? '',
            },
          },
          {
            update: (store, { data }) => {
              const cache: { billingInfos: BillingFieldsFragment[] } = store.readQuery({
                query: GetBillingInfosDocument,
              });
              cache.billingInfos = data.addBillingToUser.billings;
              store.writeQuery({ query: GetBillingInfosDocument, data: cache });
            },
          },
        )
        .subscribe(
          () => {
            this.loading = false;
            this.snackbar.open($localize`New billing data was added succesfully!`, undefined, { duration: 3000 });
          },
          (error) => this.snackbar.open(error.message, undefined, { duration: 3000 }),
        );
    }
  }

  close() {
    this.onClose.emit();
  }
}
