import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageLink } from '../../../../../../../generated/graphql';

@Component({
  selector: 'app-artist-edit-media-audio',
  templateUrl: './artist-edit-media-audio.component.html',
  styleUrls: ['./artist-edit-media-audio.component.scss'],
})
export class ArtistEditMediaAudioComponent implements OnInit {
  @Output() onMediaChanged$: EventEmitter<PageLink[]> = new EventEmitter();
  @Input() audioArray: PageLink[] = [];

  public dropAudioTileId: number;
  public dragAudioId: number;

  public ngOnInit(): void {}

  public dragStartHandler(id: number): void {
    this.dragAudioId = id;
  }

  public dragEndHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'visible';
  }

  public ghostCreateHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'hidden';
  }

  public onAudioDropped(ev) {
    ev.drag.dropFinished();
  }

  public onEnterHandler(ev): void {
    this.dropAudioTileId = parseInt(ev.owner.element.nativeElement.id, 10);

    if (this.dragAudioId === this.dropAudioTileId) return;

    const dragIndex = this.audioArray.findIndex((audio) => audio.id === this.dragAudioId);
    const dropIndex = this.audioArray.findIndex((audio) => audio.id === this.dropAudioTileId);

    this.swapAudio(dragIndex, dropIndex);

    this.audioArray.find((audio) => audio.id === this.dragAudioId).order = dropIndex;

    if (dragIndex < dropIndex) {
      for (let i = dragIndex; i < dropIndex; i++) {
        this.audioArray[i].order--;
        this.audioArray[i].isHighlight = this.audioArray[i].order === 0;
      }
    } else {
      for (let i = dropIndex + 1; i <= dragIndex; i++) {
        this.audioArray[i].order++;
        this.audioArray[i].isHighlight = this.audioArray[i].order === 0;
      }
    }

    this.onMediaChanged$.emit(this.audioArray);
  }

  private swapAudio(dragIndex: number, dropIndex: number) {
    const tempObj = this.audioArray[dragIndex];
    this.audioArray.splice(dragIndex, 1);
    this.audioArray.splice(dropIndex, 0, tempObj);
  }

  public deleteMedia(mediaArray: PageLink[], mediaId: number): void {
    mediaArray.forEach((media, index) => {
      if (media.id === mediaId) mediaArray.splice(index, 1);
    });

    this.onMediaChanged$.emit(this.audioArray);
  }

  public dropAudioOnMobile(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.audioArray, event.previousIndex, event.currentIndex);
  }
}
