import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { GigsComponent } from './gigs.component';
import { ApplicationsComponent } from './pages/applications/applications.component';
import { ApplyComponent } from './pages/apply/apply.component';
import { CreateGigFormComponent } from './pages/create-gig-form/create-gig-form.component';
import { EditGigComponent } from './pages/edit-gig/edit-gig.component';
import { GigDetailsComponent } from './pages/gig-details/gig-details.component';
import { MyGigsListComponent } from './pages/my-gigs-list/my-gigs-list.component';

const routes: Routes = [
  {
    path: '',
    component: GigsComponent,
  },
  {
    path: 'add',
    component: CreateGigFormComponent,
  },
  { path: 'applications', component: ApplicationsComponent, canActivate: [AuthGuard] },
  { path: 'manage', component: MyGigsListComponent, canActivate: [AuthGuard] },
  {
    path: ':gigId',
    component: GigDetailsComponent,
  },
  {
    path: ':gigId/apply',
    component: ApplyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':gigId/edit',
    component: EditGigComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GigsRoutingModule {}
