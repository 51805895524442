<div class="channel-wrap">
  <div fxFlex="row">
    <app-channel
      fxFlex
      fxFlex.lt-sm="100%"
      fxLayout
      [loading]="bookingLoading"
      [booking]="booking | async"
      [channel]="(booking | async)?.channel"
      [currentUser]="currentUser | async"
      [hasStatus]="!!bookingStatus"></app-channel>
    <app-booking
      *ngIf="booking | async as booking"
      fxFlex="507px"
      fxHide.lt-sm="true"
      [currentUser]="currentUser | async"
      [booking]="booking"
      [loading]="bookingLoading"></app-booking>
    <router-outlet></router-outlet>
  </div>
</div>
