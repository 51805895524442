<div>
  <a [routerLink]="['/gigs', gig.id]" class="card-container cursor-pointer" fxLayout="column" fxFlex="grow">
    <div class="image-container">
      <app-cloud-image
        *ngIf="gig.images?.length > 0"
        [profilePicture]="gig.images[0]"
        [altText]="gig.name + 's image'"
        [maxWidth]="300"
        i18n-altText></app-cloud-image>
      <app-fallback-image [gig]="gig" *ngIf="!gig.images || gig.images.length === 0"></app-fallback-image>
      <div class="img-text-container" fxLayout="row" fxLayoutAlign="center center">
        <span *ngIf="!gig.images || gig.images.length === 0" class="img-text">{{ type.translation }}</span>
      </div>
      <div class="pill-container">
        <span i18n *ngIf="gig.isOwner" class="owner-pill">owner</span>
        <ng-container *ngIf="!gig.isOwner && !gig.limitedData">
          <!-- Ordered by length so we fit more tags on the first line since longer ones are more rare -->
          <span i18n *ngIf="gig.extraTags.includes(gigTags.NewGig)" class="owner-pill">new</span>
          <span i18n *ngIf="gig.type === gigTypes.Private" class="owner-pill">open for all</span>
          <span i18n *ngIf="gig.extraTags.includes(gigTags.NewSlots)" class="owner-pill">new dates</span>
          <span i18n *ngIf="gig.extraTags.includes(gigTags.HighPrice)" class="owner-pill">high price</span>
          <span i18n *ngIf="gig.extraTags.includes(gigTags.ActiveUser)" class="owner-pill">active booker</span>
          <span i18n *ngIf="gig.extraTags.includes(gigTags.HighChance)" class="owner-pill">high chance</span>
        </ng-container>
      </div>
    </div>
    <span class="type-loc-dates"
      >{{ type.translation }} · {{ locationName }}
      <ng-container *ngIf="gig.slots?.length > 0"
        >·
        {{ firstDate | apiDate | date: dateFormat }}
        <span *ngIf="doDatesDiffer">- {{ lastDate | apiDate | date: dateFormat }}</span></ng-container
      >
    </span>
    <h4 class="gig-name" *ngIf="gig.name">{{ gig.name }}</h4>
    <span class="tags-acts"
      >{{ tagsList }} · {{ availableActs }} <span i18n>{availableActs, plural, =1 { act} other { acts}}</span></span
    >
  </a>
</div>
