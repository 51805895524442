import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Review } from '../../../../generated/graphql';
import { MONTH_YEAR } from '../../../helpers/dates';
import { PopupService } from '../../../services/popup.service';
import { TranslationsService } from '../../../services/translations.service';
import { ReviewReadModalComponent } from '../review-read-modal/review-read-modal.component';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'],
})
export class ReviewCardComponent implements OnInit {
  @Input() expandable: boolean = true;
  @Input() review: Review;

  public showMore: boolean = false;
  public maxLength: number = 125;
  public companyName: string;
  public isMobile: boolean;
  public message: string;
  public name: string;
  public type: string;

  public DATE_FORMAT = MONTH_YEAR;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translations: TranslationsService,
    private popups: PopupService,
  ) {}

  public ngOnInit() {
    this.isMobile = this.breakpointObserver.isMatched('(max-width: 960px)');
    if (this.isMobile) this.maxLength = 120;

    this.companyName = this.getCompanyName();
    this.message = this.getMessage();
    this.type = this.getType();
    this.name = this.getName();
  }

  public getMessage(): string {
    if (this.expandable && this.review.message?.length > this.maxLength) {
      return this.review.message.slice(0, this.maxLength) + '...';
    } else {
      return this.review.message;
    }
  }

  public getName(): string {
    let name = 'Anonymous user'; //TODO: översätt
    if (this.review.reviewer) {
      const splitName = this.review.reviewer.name.split(' ');
      if (splitName.length === 1) {
        return splitName[0];
      }
      name = `${splitName[0]}`;
    } else if (this.review.user) {
      // TODO: Only show first letter of lastName (check reviewer name first)
      name = this.review.user.reviewerName;
    }
    return name;
  }

  public getCompanyName(): string {
    let coName = '';
    if (this.review.reviewer) {
      coName = this.review.reviewer.company;
    } else {
      coName = this.review.user.companyName || '';
    }

    return coName;
  }

  public getType(): string {
    if (!this.review.bookingDate) return null;

    return this.translations.gigType(this.review.bookingDate.booking.type).translation;
  }

  public expand(): void {
    this.popups.open(ReviewReadModalComponent, '800px', {
      data: { review: this.review },
      panelClass: 'gigital-modal',
    });
  }
}
