import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetPageReviewsGQL, Page, Review } from '../../../../generated/graphql';

@Component({
  selector: 'app-reviews-list',
  templateUrl: './reviews-list.component.html',
  styleUrls: ['./reviews-list.component.scss'],
})
export class ReviewsListComponent implements OnInit {
  @Input() reviews$: Observable<Review[]>;
  @Input() take: number;
  @Input() skip: number;
  @Input() page: Page;
  @Input() hasNextPage = false;
  @Input() totalReviews: number;

  public isMobile: boolean = false;
  public listPage: number = 0;

  constructor(private breakpointObserver: BreakpointObserver, private getPageReviewsGQL: GetPageReviewsGQL) {}

  public ngOnInit(): void {
    this.isMobile = this.breakpointObserver.isMatched('(max-width: 960px)');
    if (this.isMobile) this.getAllReviews();
  }

  private getReviews(): void {
    this.reviews$ = this.getPageReviewsGQL
      .watch({ pageId: this.page.id, paging: { limit: this.take, skip: this.skip } })
      .valueChanges.pipe(
        map(({ data }) => {
          this.skip = data.getPageReviews.skip;
          this.hasNextPage = data.getPageReviews.hasNextPage;
          this.totalReviews = data.getPageReviews.total;
          return data.getPageReviews.reviews as Review[];
        }),
      );
  }

  public nextPage(): void {
    if (!this.hasNextPage) {
      return;
    }
    this.listPage++;
    this.getReviews();
  }

  public getAllReviews(): void {
    this.take = this.totalReviews;
    this.skip = 0;
    this.getReviews();
  }

  prevPage() {
    if (this.listPage === 0) {
      return;
    }
    this.listPage--;
    this.reviews$ = this.getPageReviewsGQL
      .watch({ pageId: this.page.id, paging: { limit: this.take, skip: this.skip - this.take } })
      .valueChanges.pipe(
        map(({ data }) => {
          this.skip = data.getPageReviews.skip;
          this.hasNextPage = data.getPageReviews.hasNextPage;
          return data.getPageReviews.reviews as Review[];
        }),
      );
  }
}
