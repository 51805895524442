import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Image, User } from '../../../generated/graphql';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnChanges, OnInit {
  @Input() user: User;
  @Input() profilePicture: Image;

  @Input() square = false;
  @Input() borderRadius: string = this?.square ? '0' : '100%';
  @Input() customText: string;

  @Input() tiny = false;
  @Input() mini = false;
  @Input() small = false;
  @Input() medium = false;
  @Input() intermediate = false;
  @Input() big = false;
  @Input() large = false;
  @Input() huge = false;
  @Input() ballin = false;
  @Input() humongous = false;

  @Input() maxWidth = 50;
  @Input() fill = false;
  @Input() specSize: number;
  @Input() noOverflow = false;

  profilePictureError = false;
  profilePictureLoaded = false;

  background: string;

  constructor() {}

  ngOnInit(): void {
    if (this.small) {
      this.maxWidth = 50;
    } else if (this.medium) {
      this.maxWidth = 60;
    } else if (this.intermediate) {
      this.maxWidth = 70;
    } else if (this.big) {
      this.maxWidth = 80;
    } else if (this.large) {
      this.maxWidth = 100;
    } else if (this.huge) {
      this.maxWidth = 120;
    } else if (this.ballin) {
      this.maxWidth = 160;
    } else if (this.humongous) {
      this.maxWidth = 256;
    }

    if (this.specSize) {
      this.maxWidth = this.specSize;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && changes.user.currentValue) {
      this.background = this.getBackgroundFromUser(changes.user.currentValue);
    }
    if (changes.customText && changes.customText.currentValue) {
      this.background = this.getBackgroundFromText(changes.customText.currentValue);
    }

    // Hacky hack to force re-render when picture changes while a picture is still loaded cuz cloudinary kinda sucky 😳
    if (!!this.profilePicture && changes.profilePicture && changes.profilePicture.currentValue) {
      this.profilePicture = null;
      setTimeout(() => {
        this.profilePicture = changes.profilePicture.currentValue;
      });
    }
  }

  private getBackgroundFromUser(user: User): string {
    return this.getBackgroundValue(this.getAlphaVal(user.firstName ? user.firstName[0].toLowerCase() : ''));
  }
  private getBackgroundFromText(str: string): string {
    return this.getBackgroundValue(this.getAlphaVal(str));
  }

  private getBackgroundValue(num: number) {
    switch (num) {
      case 0:
        return 'light-blue';
      case 1:
        return 'light-purple';
      case 2:
        return 'yellow';
      case 3:
        return 'orange';
      case 4:
        return 'red';
      case 5:
        return 'pink';
      case 6:
        return 'turq';
      default:
        return 'light-blue';
    }
  }

  private getAlphaVal(string: string): number {
    return (string.toLowerCase().charCodeAt(0) - 97 + 1) % 6;
  }

  picLoaded() {
    this.profilePictureLoaded = true;
  }

  picError() {
    this.profilePictureError = true;
  }
}
