<div fxFlex="grow" fxLayout="column" fxLayoutGap="8px" class="form-input">
  <p class="form-input--label" *ngIf="!!label" [ngClass]="{ light: isLightLabel }">{{ translatedLabel }}</p>

  <div class="input-wrap">
    <input
      matGoogleMapsAutocomplete
      (onAutocompleteSelected)="onAutocompleteSelected($event)"
      [autoCompleteOptions]="autoCompleteOptions"
      [placeholder]="placeholder"
      [(ngModel)]="placeId"
      [ngClass]="{ invalid: invalid }"
      i18n-placeholder
      #placeIdInput />
    <mat-icon svgIcon="location"></mat-icon>
  </div>
</div>
