import { Component, OnInit } from '@angular/core';
import { GetContentfulEntryGQL, Image } from '../../../../generated/graphql';
import { parseQueryParams } from '../../../../utils/contentful';
import { richTextToHtml } from '../../../helpers';
import { MetaService } from '../../../services/meta.service';
import { TranslationsService } from '../../../services/translations.service';

@Component({
  selector: 'app-nordic-choice',
  templateUrl: './nordic-choice.component.html',
  styleUrls: ['./nordic-choice.component.scss'],
})
export class NordicChoiceComponent implements OnInit {
  constructor(
    private getContentfulEntry: GetContentfulEntryGQL,
    private translations: TranslationsService,
    private meta: MetaService,
  ) {}

  public parseQueryParams = parseQueryParams;
  isLoading = true;
  hero;
  infoBlock;
  tabbedInfo;
  selectedTab = 0;
  testimonials;
  faq;
  // helpVideo;
  logos;

  ngOnInit() {
    // do NOT index this page hihi 🤭
    this.meta.setNoIndex();

    this.getContentfulEntry
      .fetch({ id: '2IpWvHa7sGyWSRl9VnSsmp', locale: this.translations.getCurrentLocale() })
      .subscribe((result) => {
        const page = result.data.getContentfulEntry.contentfulData;
        this.hero = page.fields.blocks.find((e) => e.sys.contentType.sys.id === 'hero');
        this.infoBlock = page.fields.blocks.find((e) => e.sys.id === '3jZgnt1t8NLrAVWjfWBOkw');
        this.tabbedInfo = page.fields.blocks.find((e) => e.sys.id === '3kXt9uKkx3n6TyoBLutNtf');
        this.testimonials = page.fields.blocks.find((e) => e.sys.id === '4RQkO9PMGCqt5G4I4s58e8');
        this.faq = page.fields.blocks.find((e) => e.sys.id === '3plrQCXqGL2i3BJrFyrPfl');
        // this.helpVideo = page.fields.blocks.find((e) => e.sys.id === '2TZ10aSd5wNRk0g519sFL4');
        this.logos = page.fields.blocks.find((e) => e.sys.id === '1jAX0LwvUDtqNFG0SESWoc');

        this.isLoading = false;

        setTimeout(() => {
          var video = document.getElementsByTagName('video')[0];
          video.muted = true;
          video.loop = true;
          video.play();
        });
      });
  }

  imageIdToImage(publicId: string): Image {
    return {
      cropData: {
        x: -1,
        h: -1,
        w: -1,
        y: -1,
      },
      fileName: publicId,
      id: -1,
    };
  }
  richTextToHtml(text) {
    return richTextToHtml(text);
  }
}
