<mat-dialog-content class="select-applicants">
  <h1 i18n class="gray-1 h5" i18n
    >Applicatons for <strong>{{ data.gig.name }}</strong></h1
  >

  <!--
  <mat-vertical-stepper [formGroup]="applicationGroup">
    <mat-step>
      <ng-template i18n matStepLabel>Select an artist</ng-template>
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="12px grid" class="artist-list">
        <div *ngFor="let page of uniqueArtists; let i = index" fxLayout="column">
          <button class="artist-button" mat-button fxLayout="column" (click)="selectArtist(i)" matStepperNext>
            <div fxLayout="row" fxLayoutAlign="center center">
              <app-avatar [user]="page.user" [big]="true" [profilePicture]="page.profilePicture"></app-avatar>
            </div>
            {{ page.name }}<br />
          </button>
          <div class="sub-text">
            <p>{{ getArtistCategory(i) }}</p>
            <a [routerLink]="['/artists', page.slug]" mat-dialog-close>View profile</a>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template i18n matStepLabel>Select dates</ng-template>
      <div fxLayout="column">
        <div formArrayName="dates" *ngFor="let item of this.arrayDates.controls; let i = index">
          <div [formGroupName]="i" fxLayout="column">
            <mat-checkbox formControlName="date" color="primary">{{ item.value.visValue | date }}</mat-checkbox>
            <mat-form-field>
              <input matInput placeholder="Price" i18n-placeholder="@@price" formControlName="price" />
              <span matSuffix>{{ artistBookings[i].dates[i].priceCurrency | uppercase }}</span>
              <mat-hint *ngIf="!!selectedArtist" i18n>Pre-filled with {{ selectedArtist.name }}'s offer</mat-hint>
              <mat-error *ngIf="item.get('price').hasError('min')" i18n>You need to include a budget</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template i18n matStepLabel>Extra info</ng-template>
      <div fxLayout="column">
        <mat-form-field>
          <textarea matInput placeholder="Additional details" i18n-placeholder formControlName="message"></textarea>
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="acceptApplication()">Accept application</button>
        <mat-hint i18n *ngIf="!!selectedArtist"
          >Accepting an application will send a <b>non binding</b> booking request to
          {{ selectedArtist.name }}.</mat-hint
        >
      </div>
    </mat-step>
  </mat-vertical-stepper> -->
</mat-dialog-content>
