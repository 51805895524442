<div
  *ngIf="reviews$ | async as reviews; else loading"
  fxLayout="row wrap"
  fxLayoutAlign="flex-start space-between"
  fxLayout.lt-sm="row nowrap"
  fxLayoutAlign.lt-sm="start stretch"
  class="reviews-list">
  <app-review-card
    fxFlex="calc(50% - 75px)"
    fxFlex.lt-md="331px"
    class="reviews-list--item"
    *ngFor="let review of reviews"
    [review]="review"></app-review-card>
</div>
<span
  fxLayoutAlign="start center"
  fxLayoutGap="4px"
  *ngIf="hasNextPage"
  (click)="getAllReviews()"
  class="show-all-button cursor pink">
  <span i18n>Show all reviews</span><mat-icon svgIcon="expand-down-pink"></mat-icon>
</span>

<ng-template #loading>
  <div>
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="30px grid" class="loading">
      <div fxFlex.gt-sm="50%">
        <ngx-skeleton-loader
          [theme]="{
            width: '100%',
            height: '70px',
            'border-radius': '4px',
            'background-color': '#fafafa',
            'margin-bottom': '16px'
          }">
        </ngx-skeleton-loader>
      </div>
      <div fxFlex.gt-sm="50%">
        <ngx-skeleton-loader
          [theme]="{
            width: '100%',
            height: '70px',
            'border-radius': '4px',
            'background-color': '#fafafa',
            'margin-bottom': '16px'
          }">
        </ngx-skeleton-loader>
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="30px grid" class="loading">
      <div fxFlex.gt-sm="50%">
        <ngx-skeleton-loader
          [theme]="{
            width: '100%',
            height: '70px',
            'border-radius': '4px',
            'background-color': '#fafafa',
            'margin-bottom': '8px'
          }">
        </ngx-skeleton-loader>
      </div>
      <div fxFlex.gt-sm="50%">
        <ngx-skeleton-loader
          [theme]="{
            width: '100%',
            height: '70px',
            'border-radius': '4px',
            'background-color': '#fafafa',
            'margin-bottom': '8px'
          }">
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>
