<div class="filter-container" fxLayout="row" fxLayoutGap="10px" *ngIf="bookings$ | async as bookings">
  <button
    class="gig-button filter"
    [ngClass]="{ selected: selectedFilter === BOOKING_FEED_TYPE.Open }"
    (click)="onFilterSelect.emit(BOOKING_FEED_TYPE.Open)">
    <span i18n>Open </span><span>({{ bookingsCount.open }})</span>
  </button>
  <button
    class="gig-button filter"
    [ngClass]="{ selected: selectedFilter === BOOKING_FEED_TYPE.Upcoming }"
    (click)="onFilterSelect.emit(BOOKING_FEED_TYPE.Upcoming)">
    <span i18n>Upcoming </span><span>({{ bookingsCount.signed }})</span>
  </button>
  <button
    class="gig-button filter"
    [ngClass]="{ selected: selectedFilter === BOOKING_FEED_TYPE.Closed }"
    (click)="onFilterSelect.emit(BOOKING_FEED_TYPE.Closed)">
    <span i18n>Completed </span><span>({{ bookingsCount.closed }})</span>
  </button>
  <button
    class="gig-button filter"
    [ngClass]="{ selected: selectedFilter === BOOKING_FEED_TYPE.Others }"
    (click)="onFilterSelect.emit(BOOKING_FEED_TYPE.Others)">
    <span i18n>Other </span><span>({{ bookingsCount.others }})</span>
  </button>
</div>
