<div fxLayout="row" fxLayoutAlign="space-between center" class="title-wrap">
  <h2 class="title">
    <span i18n>Gigs</span>
    <span fxHide.lt-sm *ngIf="gigsCount > 0"> ({{ gigsCount }}) </span>
  </h2>
  <a *ngIf="gigsCount > 0" class="see-all" [routerLink]="['/gigs']" i18n>See all</a>
</div>

<mat-divider *ngIf="gigsCount <= 0" class="divider"></mat-divider>

<section *ngIf="gigs$ | async as gigs">
  <div *ngIf="gigsCount > 0; else noGigs">
    <div fxLayout="column">
      <div class="title-spacer"></div>
      <div class="data-header" fxLayout="row" fxLayoutAlign="space-between center" fxShow fxHide.lt-md>
        <span fxFlex="25%" i18n>Gig</span>
        <span fxFlex="25%" i18n>Status</span>
        <span fxFlex="25%" i18n>Remaining slots</span>
        <span fxFlex="15%" i18n>Action</span>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap.lt-md="8px" class="items-container">
      <!-- TODO: cache list of bookings in ts -->
      <app-customer-gigs-dashboard-item *ngFor="let gig of gigs" [gig]="gig"></app-customer-gigs-dashboard-item>
    </div>
  </div>
</section>

<ng-template #noGigs>
  <span i18n>You don’t have any posted gigs yet.</span>
</ng-template>
