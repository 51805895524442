<div fxLayout="column" class="form-input">
  <p
    *ngIf="!!label"
    fxLayoutAlign="start center"
    fxLayoutGap="6px"
    class="form-input--label"
    [ngClass]="{ light: isLightLabel }">
    <span>{{ label }}</span>
    <mat-icon *ngIf="!!labelTooltip" [matTooltip]="labelTooltip" svgIcon="info-fill"></mat-icon>
  </p>

  <ng-content></ng-content>

  <div fxLayoutAlign="start center" fxLayoutGap="50px">
    <label *ngFor="let option of options" fxLayoutAlign="start center" class="checkbox">
      <mat-checkbox [checked]="value === option.value" (change)="writeValue(option.value)" color="primary">
      </mat-checkbox>
      <span class="checkbox--name">{{ option.name }}</span>
    </label>
  </div>
</div>
