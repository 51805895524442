import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Gig, UpdateGigGQL } from '../../../../../../generated/graphql';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-gig-edit-base',
  templateUrl: './gig-edit-base.component.html',
  styleUrls: ['./gig-edit-base.component.scss'],
})
export class GigEditBaseComponent implements OnInit {
  @ViewChild('placeIdInput', { read: FormControlDirective })
  placeIdInput: FormControlDirective;

  @ViewChild('placeIdInput', { static: true })
  placeIdHtml: ElementRef;

  get placeId(): AbstractControl {
    return this.editGroup.get('placeId');
  }
  get date(): AbstractControl {
    return this.editGroup.get('date');
  }

  get name(): AbstractControl {
    return this.editGroup.get('name');
  }

  editGroup = new FormGroup({
    placeId: new FormControl('', [Validators.required]),
    type: new FormControl(''),
    description: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    deadline: new FormControl('DAY', [Validators.required]),
  });

  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { gig: Gig },
    private modalRef: MatDialogRef<GigEditBaseComponent>,
    private updateGig: UpdateGigGQL,
    private snackbar: MatSnackBar,
  ) {}
  ngOnInit() {
    this.editGroup.get('type').setValue(this.data.gig.type);
    this.editGroup.get('name').setValue(this.data.gig.name);
    this.editGroup.get('description').setValue(this.data.gig.details);
    this.editGroup.get('deadline').setValue(this.data.gig.deadline);
    this.editGroup.get('placeId').setValue(this.data.gig.placeData.formattedAddress);

    this.editGroup.updateValueAndValidity();

    setTimeout(() => {
      this.placeIdInput.control.setValue(this.data.gig.placeId, { emitModelToViewChange: false });
    });

    (this.placeIdHtml.nativeElement as HTMLInputElement).value = this.data.gig.placeData.formattedAddress;
  }

  onAutocompleteSelected(result: PlaceResult): void {
    this.placeIdInput.control.setValue(result.place_id, { emitModelToViewChange: false });
  }

  update() {
    this.loading = true;
    this.updateGig
      .mutate({
        gig: {
          name: this.editGroup.get('name').value,
          details: this.editGroup.get('description').value,
          id: this.data.gig.id,
          placeId: this.editGroup.get('placeId').value,
          type: this.editGroup.get('type').value,
          deadline: this.editGroup.get('deadline').value,
        },
      })
      .subscribe(
        () => {
          this.loading = false;
          this.snackbar.open($localize`Succesfully saved changes!`, undefined, {
            duration: 3000,
          });
          this.modalRef.close();
        },
        (error) => {
          this.loading = false;
          this.snackbar.open($localize`Could not save changes.`, undefined, {
            duration: 3000,
          });
          console.error(error);
        },
      );
  }
}
