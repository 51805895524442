import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { CheckButtonComponent } from '../components/check-button/check-button.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DatetimeInputComponent } from './datetime-input/datetime-input.component';
import { DropdownTimeInputComponent } from './dropdown-time-input/dropdown-time-input.component';
import { OutlinedInputComponent } from './outlined-input/outlined-input.component';
import { PlaceInputComponent } from './place-input/place-input.component';

@NgModule({
  declarations: [
    OutlinedInputComponent,
    DatetimeInputComponent,
    PlaceInputComponent,
    DateInputComponent,
    DropdownTimeInputComponent,
    CheckButtonComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
  ],
  exports: [
    DateInputComponent,
    DatetimeInputComponent,
    PlaceInputComponent,
    OutlinedInputComponent,
    DropdownTimeInputComponent,
    CheckButtonComponent,
  ],
})
export class InputModule {}
