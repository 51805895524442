<div class="for-artists" *ngIf="!isLoading; else loading">
  <div class="hero-container">
    <div class="hero-video">
      <cl-video [public-id]="hero.fields.backgroundImage[0].public_id" secure="true" autoplay loop> </cl-video>
    </div>
    <div class="container">
      <div class="hero-text for-artists-hero-text">
        <h1 class="h3 white">{{ hero.fields.title }}</h1>
        <div class="white mat-body strong" [innerHTML]="richTextToHtml(hero.fields.body)"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div
      class="info-block"
      fxLayout="column-reverse"
      fxLayout.gt-md="row"
      fxLayoutAlign="center center"
      fxLayoutGap="32px"
      fxLayoutGap.gt-md="128px">
      <cl-image
        fxFlex.gt-md="360px"
        [public-id]="infoBlock.fields.image[0].public_id"
        appLazyLoad
        attr.alt="Gigital artist">
        <cl-transformation quality="auto" fetch-format="auto" flags="progressive" responsive> </cl-transformation>
      </cl-image>
      <div class="info-block-text">
        <h2 class="h4 gray-1">{{ infoBlock.fields.title }}</h2>
        <div [innerHTML]="richTextToHtml(infoBlock.fields.body)" class="mat-body gray-2"></div>
        <a mat-flat-button color="primary" [routerLink]="['/', infoBlock.fields.ctAs.fields.url]">{{
          infoBlock.fields.ctAs.fields.title
        }}</a>
      </div>
    </div>
  </div>
  <div class="bg-bright-red container">
    <div class="nav-links" fxLayout fxLayoutGap="32px">
      <h2
        class="label white"
        *ngFor="let info of tabbedInfo.fields.blocks; let i = index"
        (click)="selectedTab = i"
        [class.selected]="i === selectedTab"
        >{{ info.fields.title }}</h2
      >
    </div>
    <div class="tab-content" fxLayout="column" fxLayout.gt-md="row">
      <div class="tab-content-text" fxFlex.gt-md="50%">
        <h3 class="white h5">{{ tabbedInfo.fields.blocks[selectedTab].fields.secondaryTitle }}</h3>
        <div class="mat-body white" [innerHTML]="richTextToHtml(tabbedInfo.fields.blocks[selectedTab].fields.body)">
        </div>
        <a mat-raised-button [routerLink]="['/', tabbedInfo.fields.blocks[selectedTab].fields.ctAs.fields.url]">{{
          tabbedInfo.fields.blocks[selectedTab].fields.ctAs.fields.title
        }}</a>
      </div>
      <div fxFlex.gt-md="50%" fxLayout fxLayoutAlign="center end">
        <cl-image
          fxFlex="320px"
          [public-id]="tabbedInfo.fields.blocks[selectedTab].fields.image[0].public_id"
          appLazyLoad>
          <cl-transformation quality="auto" fetch-format="auto" flags="progressive" responsive> </cl-transformation>
        </cl-image>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="testimonials">
      <h2 class="gray-1 h4">{{ testimonials.fields.title }}</h2>
      <div class="testimonial" *ngFor="let testimonial of testimonials.fields.blocks">
        <div class="testimonial-body gray-1" [innerHTML]="richTextToHtml(testimonial.fields.body)"></div>
        <div class="artist-info" fxLayout fxLayoutGap="16px" fxLayoutAlign="start center">
          <cl-image fxFlex="48px" [public-id]="testimonial.fields.image[0].public_id" appLazyLoad>
            <cl-transformation quality="auto" fetch-format="auto" flags="progressive" responsive> </cl-transformation>
          </cl-image>
          <div>
            <p class="gray-1 h6">{{ testimonial.fields.title }}</p>
            <p class="gray-2 small-label">{{ testimonial.fields.secondaryTitle }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container bg-gray-5">
    <div class="faq">
      <h2 class="gray-1 h4">{{ faq.fields.title }}</h2>
      <div *ngFor="let question of faq.fields.blocks" class="faq-question">
        <h3 class="h6 gray-1">{{ question.fields.title }}</h3>
        <div [innerHTML]="richTextToHtml(question.fields.body)" class="mat-body gray-1"></div>
      </div>
    </div>
  </div>
  <section class="bg-bright-red articles">
    <h2 class="h4 container white">Articles</h2>
    <app-blog-posts-preview [tags]="['artistinfo', 'artistnews']" utmSource="artist-landing"></app-blog-posts-preview>
  </section>
</div>
<ng-template #loading>
  <div class="loading-screen" fxLayout fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="48px" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template>
