import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetReviewByIdGQL, Review } from '../../../generated/graphql';

@Component({
  selector: 'app-review-details',
  templateUrl: './review-details.component.html',
  styleUrls: ['./review-details.component.scss'],
})
export class ReviewDetailsComponent implements OnInit {
  review: Observable<Review>;

  reviewForm = new FormGroup({
    recommended: new FormControl(true),
    message: new FormControl(''),
  });

  constructor(private getReviewByid: GetReviewByIdGQL, private route: ActivatedRoute) {}

  ngOnInit() {
    console.log(this.reviewForm.get('recommended'));
    const recommended = this.route.snapshot.queryParams.recommended === 'true';
    this.reviewForm.get('recommended').setValue(recommended);

    this.review = this.getReviewByid.watch({ id: parseInt(this.route.snapshot.params.reviewId) }).valueChanges.pipe(
      map(({ data }) => {
        if (data.reviewById.recommended) {
          this.reviewForm.get('recommended').setValue(data.reviewById.recommended);
        }
        this.reviewForm.get('message').setValue(data.reviewById.message || '');

        return data.reviewById;
      }),
    );
  }

  updateReview() {
    // this.review.pipe(first()).subscribe(review => {
    //   console.log(review);
    //   this.editReview
    //     .mutate({
    //       review: {
    //         id: review.id,
    //         message: this.reviewForm.get('message').value,
    //         recommended: this.reviewForm.get('recommended').value,
    //         visible: false,
    //         canUsePublic: false,
    //       },
    //     })
    //     .subscribe(console.log);
    // });
  }
}
