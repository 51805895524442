<section *ngIf="!isLoading; else loading">
  <div class="container">
    <div class="info-block" fxLayoutAlign="center center" fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="64px">
      <div fxFlex="100%" fxFlex.gt-md="50%">
        <h1 class="h4 gray-1">{{ infoBlock.fields.title }}</h1>
        <div class="mat-body gray-1" [innerHTML]="richTextToHtml(infoBlock.fields.body)"></div>
      </div>
      <cl-image [public-id]="infoBlock.fields.image[0].public_id" appLazyLoad>
        <cl-transformation quality="auto" fetch-format="auto" flags="progressive" responsive> </cl-transformation>
      </cl-image>
    </div>
  </div>
  <div class="bg-bright-red container timeline">
    <div class="timeline-years" fxLayout fxLayoutGap="80px">
      <h2
        class="gray-4 h5"
        *ngFor="let year of timeline.fields.blocks; let i = index"
        [class.white]="selectedYear === i"
        [class.selected]="selectedYear === i"
        (click)="selectedYear = i"
        >{{ year.fields.title }}</h2
      >
    </div>
    <div class="timeline-months" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="32px" fxLayoutGap.gt-sm="64px">
      <div
        fxFlex="100%"
        fxFlex.gt-sm="33% 0 0"
        class="timeline-month"
        *ngFor="let month of timeline.fields.blocks[selectedYear].fields.blocks">
        <h3 class="white h4">{{ month.fields.secondaryTitle }}</h3>
        <div class="mat-body white" [innerHTML]="richTextToHtml(month.fields.body)"></div>
      </div>
    </div>
  </div>
</section>
<ng-template #loading>
  <div class="loading-screen" fxLayout fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="48px" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template>
