<section *ngIf="!isLoading; else loading">
  <div class="hero-container">
    <div class="hero-image">
      <cl-image
        [public-id]="hero.fields.backgroundImage[0].public_id"
        style="width: 100%; height: 100%"
        dpr="auto"
        responsive
        width="auto"
        crop="scale"
        appLazyLoad
        attr.alt="Cajsa Frangquist">
        <cl-transformation quality="auto" fetch-format="auto" flags="progressive"></cl-transformation>
      </cl-image>
    </div>
    <div class="hero container">
      <div class="hero-text">
        <h1 class="h3 white">{{ hero.fields.title }}</h1>
        <h2 class="mat-body strong white">
          {{ hero.fields.body.content[0].content[0].value }}
        </h2>
      </div>
    </div>
  </div>
  <app-home-customers [customers]="customers" [showHoverLink]="false"></app-home-customers>
  <div class="container bg-gray-5">
    <div class="testimonials">
      <h2 class="gray-1 h4">{{ testimonials.fields.title }}</h2>
      <div class="testimonial" *ngFor="let testimonial of testimonials.fields.blocks">
        <div class="testimonial-body gray-1" [innerHTML]="richTextToHtml(testimonial.fields.body)"></div>
        <div class="artist-info" fxLayout fxLayoutGap="16px" fxLayoutAlign="start center">
          <cl-image fxFlex="48px" [public-id]="testimonial.fields.image[0].public_id" appLazyLoad>
            <cl-transformation quality="auto" fetch-format="auto" flags="progressive" responsive> </cl-transformation>
          </cl-image>
          <div>
            <p class="gray-1 h6">{{ testimonial.fields.title }}</p>
            <p class="gray-2 small-label">{{ testimonial.fields.secondaryTitle }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="use-cases" fxLayout="column" fxLayoutGap="80px">
      <h3 class="h4 gray-1">{{ userCases.fields.title }}</h3>
      <div class="use-case" *ngFor="let case of userCases.fields.blocks; let i = index">
        <div fxLayout fxLayoutGap="64px" fxLayoutAlign="center center">
          <div fxFlex="50%">
            <cl-image
              [public-id]="case.fields.image[0].public_id"
              dpr="auto"
              responsive
              width="auto"
              crop="scale"
              appLazyLoad>
              <cl-transformation quality="auto" fetch-format="auto" flags="progressive" aspect-ratio="1.66" crop="fill">
              </cl-transformation>
            </cl-image>
          </div>
          <div fxFlex="50%">
            <h4 class="h5 gray-1">{{ case.fields.title }}</h4>
            <div [innerHTML]="richTextToHtml(case.fields.body)" class="mat-body gray-2"></div>
            <button mat-stroked-button (click)="expanded = i" *ngIf="expanded !== i">{{
              case.fields.ctAs.fields.title
            }}</button>
            <button mat-stroked-button (click)="expanded = undefined" *ngIf="expanded === i">x</button>
          </div>
        </div>
        <div class="use-case-expanded" *ngIf="expanded === i">
          <div fxLayout fxLayoutGap="64px" fxLayoutAlign="center center">
            <div fxFlex="25">
              <cl-image
                [public-id]="case.fields.expandedInfo.fields.image[0].public_id"
                dpr="auto"
                responsive
                width="auto"
                crop="scale"
                appLazyLoad>
                <cl-transformation
                  quality="auto"
                  fetch-format="auto"
                  flags="progressive"
                  aspect-ratio="0.75"
                  crop="fill">
                </cl-transformation>
              </cl-image>
            </div>
            <div fxFlex="75" [innerHTML]="richTextToHtml(case.fields.expandedInfo.fields.body)" class="mat-body gray-2">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #loading>
  <div class="loading-screen" fxLayout fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="48px" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template>
