<div class="upload-container cursor-pointer">
  <div
    class="image-container"
    *ngIf="!imageData && !uploadedImage"
    fxLayout="column"
    fxLayoutAlign="center center"
    (click)="mainImgInput.click()"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
  >
    <input hidden #mainImgInput type="file" (input)="imgInputSelected($event)" />
    <ng-content></ng-content>
  </div>

  <div
    *ngIf="imageData || uploadedImage"
    class="img-preview-container hero"
    [style.background-image]="imageData?.safeCssUrl || (uploadedImage | safeCssImageUrl: 350)"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <div class="pill" *ngIf="!!pillText"
      ><span>{{ pillText }}</span></div
    >
    <button class="small-round remove-image-button" (click)="removeImage()">x</button>
  </div>
</div>
