import { Injectable } from '@angular/core';
import { Page } from '../../generated/graphql';
import { ArtistProgress, ArtistProgressStep, ProgressStepNumber, ProgressStepType } from '../models/ArtistProgress';

@Injectable({
  providedIn: 'root',
})
export class ArtistProgressService {
  constructor() {}

  public getArtistProgress(page: Page): ArtistProgress {
    const steps: ArtistProgressStep[] = this.mapDenyReasons(this.getArtistProgressSteps(page), page);
    const stepsCompleted: number = steps.filter((s) => s.completed).length;
    return {
      steps,
      stepsCompleted,
      stepsTotal: steps.length,
      progressPercentage: (stepsCompleted / steps.length) * 100,
    };
  }

  private mapDenyReasons(steps: ArtistProgressStep[], page: Page) {
    if (page.denyReasons) {
      return steps.map((step) => {
        const reason = page.denyReasons.find((d) => d.title === step.name);
        return {
          ...step,
          denyReason: reason ? $localize`Feedback from Gigital - ${step.name}\: ` + reason.extra : null,
          denied: !!reason,
        };
      });
    }
    return steps;
  }

  private getArtistProgressSteps(page: Page): ArtistProgressStep[] {
    return [
      {
        type: ProgressStepType.IMPORT_CONTENT,
        stepNumber: ProgressStepNumber.IMPORT_CONTENT,
        name: $localize`Import content`,
        description: $localize`From Youtube, Spotify, Mixcloud`,
        isNew: true,
        isRequired: false,
        completed: true,
        denied: false,
      },
      {
        type: ProgressStepType.BASIC_INFO,
        stepNumber: ProgressStepNumber.BASIC_INFO,
        name: $localize`Basic info`,
        description: $localize`Bio, location, price`,
        completed: this.checkIfBasicInfoStepCompleted(page),
        isNew: false,
        isRequired: true,
        denied: false,
      },
      {
        type: ProgressStepType.TAGS,
        stepNumber: ProgressStepNumber.TAGS,
        name: $localize`Tags`,
        description: $localize`Genres`,
        completed: page.tags?.length > 0,
        isNew: false,
        isRequired: true,
        denied: false,
      },
      {
        type: ProgressStepType.MEDIA,
        stepNumber: ProgressStepNumber.MEDIA,
        name: $localize`Media`,
        description: $localize`Video, song and photo`,
        completed: page.links?.length > 0 || page.gallery?.length > 0,
        isNew: false,
        isRequired: true,
        denied: false,
      },
      {
        type: ProgressStepType.FAQ,
        stepNumber: ProgressStepNumber.FAQ,
        name: $localize`FAQ (optional)`,
        description: $localize`Customer questions`,
        completed: page?.faq?.length > 0,
        isNew: true,
        isRequired: false,
        denied: false,
      },
    ];
  }

  private checkIfBasicInfoStepCompleted(page: Page): boolean {
    return (
      page.name &&
      page.name.length > 0 &&
      page.category &&
      page.placeData?.placeId &&
      page.placeData?.placeId?.length > 0 &&
      page.price &&
      page.price > 0 &&
      page.presentation &&
      page.presentation.length > 0
    );
  }
}
