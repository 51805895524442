<div *ngIf="notificationService.notifications | async as notifications; else loading" [class.isDropdown]="isDropdown">
  <div *ngFor="let notification of notifications" [ngClass]="{ 'card-container': !isDropdown }">
    <app-notification-card [notification]="notification" [borderRadius]="!isDropdown"></app-notification-card>
  </div>
  <div *ngIf="notifications.length === 0" fxLayout="column" fxLayoutAlign="center center" class="empty">
    <img src="assets/images/empty-notification-list.svg" class="illustration" />
    <h1 class="mat-body-1 gray-2" i18n>No notifications yet</h1>
    <h2 class="mat-body-2 gray-3" i18n>Keep using Gigital and we will let you know when we need your attention.</h2>
  </div>
</div>
<div #observeElement></div>
<ng-template #loading>
  <div *ngFor="let item of [].constructor(15)">
    <ngx-skeleton-loader
      [theme]="{
        width: '100%',
        height: '87px',
        'border-radius': '4px',
        'background-color': '#eee',
        'margin-top': '12px',
        'margin-bottom': 0
      }"
    >
    </ngx-skeleton-loader>
  </div>
</ng-template>
