<mat-toolbar
  fxLayoutAlign="space-between center"
  class="mat-elevation-z1 container main-nav"
  [class.transparent]="navbarStatus.transparent"
  (mouseleave)="!auth.isLoggedIn && hideDropdown()">
  <div fxLayout fxLayoutAlign="center center">
    <div class="mobile-menu-toggle" fxHide.gt-sm>
      <button mat-icon-button (click)="onToggleSideNav()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <a [routerLink]="['/']" class="gigital-logo">
      <app-gigital-logo [color]="navbarStatus.transparent ? '#fff' : '#1e272e'"></app-gigital-logo>
    </a>
  </div>

  <ng-container *ngIf="user | async as user; else loggedOut">
    <ng-container *ngIf="!loading">
      <div fxLayoutAlign="end center" fxLayoutGap="2px" class="logged-nav-container">
        <div fxHide.lt-md class="logged-nav">
          <a
            [routerLink]="['/']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="logged-nav--item"
            i18n
            >Dashboard</a
          >
          <a
            *ngIf="user.type === USER_TYPE_ENUM.Artist"
            [routerLink]="['/artists/search']"
            routerLinkActive="active"
            class="logged-nav--item"
            i18n
            >Artists</a
          >
          <a
            *ngIf="user.type === USER_TYPE_ENUM.Booker"
            [routerLink]="['/artists/search']"
            routerLinkActive="active"
            class="logged-nav--item"
            i18n
            >Book artists</a
          ><a [routerLink]="['/gigs']" routerLinkActive="active" class="logged-nav--item" i18n>Gigs</a>
          <a
            [routerLink]="['/bookings']"
            routerLinkActive="active"
            [matBadge]="openBookings | async"
            [matBadgeHidden]="!displayBookingsBadge"
            matBadgeSize="small"
            matBadgePosition="above after"
            class="logged-nav--item with-badge"
            i18n
            >Bookings</a
          >
        </div>

        <div fxLayoutAlign="end center" fxLayoutGap="13px" fxHide.lt-md>
          <button
            [matBadge]="notes.unreads"
            [matBadgeHidden]="notes.unreads <= 0 || !notes.canDisplayBadge"
            matBadgePosition="above after"
            matBadgeSize="small"
            mat-icon-button
            [matMenuTriggerFor]="notificationsMenu"
            (click)="openNotifications()">
            <mat-icon svgIcon="notification"></mat-icon>
          </button>
          <button class="gig-button icon p--0" [matMenuTriggerFor]="menu">
            <div class="empty-avatar">
              <img src="assets/images/empty-user-avatar.svg" />
            </div>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="ctas" fxFlexAlign="end" fxLayoutGap="12px" fxHide.xs *ngIf="!(user | async)">
    <button
      class="gig-button stroked wide"
      [class.cta-light]="navbarStatus.transparent"
      [routerLink]="['/login']"
      routerLinkActive="bright-red"
      i18n
      >Log in</button
    >
    <button class="gig-button primary wide" [routerLink]="['/signup']" i18n>Sign up</button>
  </div>
</mat-toolbar>

<ng-template #loggedOut>
  <div fxHide.lt-sm class="unlogged-nav" [class.transparent]="navbarStatus.transparent">
    <a class="unlogged-nav--item" [routerLink]="['/artists/search']" routerLinkActive="active" i18n>Book artists</a>
    <a
      class="unlogged-nav--item"
      [routerLink]="['/pricing']"
      routerLinkActive="active"
      (mouseenter)="hideDropdown()"
      i18n
      >Pricing
    </a>
    <a
      class="unlogged-nav--item"
      [routerLink]="['/how-it-works']"
      routerLinkActive="active"
      (mouseenter)="hideDropdown()"
      i18n
      >How it works
    </a>
    <a
      class="unlogged-nav--item"
      [routerLink]="['/for-artists']"
      routerLinkActive="active"
      (mouseenter)="hideDropdown()"
      i18n
      >For artists
    </a>

    <!-- Dropdown menus -->
    <div class="unlogged-nav-popover" #dropDown>
      <div class="popover-content" #dropDownContent>
        <!-- company section-->
        <div class="section section-company" name="company">
          <div>
            <ul class="label gray-1">
              <li>
                <a
                  [routerLink]="['/company']"
                  fxLayout
                  fxLayoutGap="16px"
                  fxLayoutAlign="start center"
                  class="label gray-1">
                  <mat-icon inline>supervised_user_circle</mat-icon><span>About Gigital</span>
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/customers']"
                  fxLayout
                  fxLayoutGap="16px"
                  fxLayoutAlign="start center"
                  class="label gray-1">
                  <mat-icon inline>location_city</mat-icon><span>Our customers</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- <div class="from-blog" fxLayout fxLayoutGap="16px">
            <mat-icon>rss_feed</mat-icon>
            <div>
              <span class="label gray-1">From the blog</span>
              <ul class="mat-body gray-2">
                <li>Make it easier</li>
                <li>Links are going out here aswell</li>
                <li>Lets go</li>
              </ul>
            </div>
          </div> -->
        </div>
        <!-- artist section-->
        <div class="section section-artist" name="artists">
          <div>
            <ul>
              <li>
                <a
                  [routerLink]="['/artists/search']"
                  fxLayout
                  fxLayoutGap="16px"
                  fxLayoutAlign="start center"
                  class="label gray-1">
                  <mat-icon inline>explore</mat-icon><span>Explore artists</span>
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/artists/search']"
                  fxLayout
                  fxLayoutGap="16px"
                  fxLayoutAlign="start center"
                  class="label gray-1">
                  <mat-icon inline>search</mat-icon><span>Search for artist</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="popover-background" #dropDownBackground></div>
    </div>
  </div>
</ng-template>
<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item [routerLink]="['/user/settings']" i18n="@@settings">Settings</button>
  <ng-container *ngIf="user | async as currentUser">
    <ng-container *ngIf="userPages$ | async as userPages">
      <button
        *ngIf="currentUser.type === USER_TYPE_ENUM.Artist || userPages.length > 0"
        mat-menu-item
        [routerLink]="['/user/artists']"
        i18n="@@yourArtistProfiles"
        >Your artist profiles</button
      >
    </ng-container>
  </ng-container>
  <button (click)="logout()" mat-menu-item i18n="@@logout">Logout</button>
</mat-menu>

<mat-menu #notificationsMenu="matMenu" class="notifications-menu" xPosition="before">
  <app-notifications-dropdown></app-notifications-dropdown>
  <!-- <ng-template matMenuContent>
  </ng-template> -->
</mat-menu>
