import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../../environments/environment';
import { ReviewCardComponent } from '../artists/components/review-card/review-card.component';
import { BookingCardComponent } from '../bookings/components/booking-card/booking-card.component';
import { AddBillingInfoComponent } from '../components/add-billing-info/add-billing-info.component';
import { ArtistDropdownComponent } from '../components/artist-dropdown/artist-dropdown.component';
import { AvatarComponent } from '../components/avatar/avatar.component';
import { BackArrowTitleComponent } from '../components/back-arrow-title/back-arrow-title.component';
import { BlogPostsPreviewComponent } from '../components/blog-posts-preview/blog-posts-preview.component';
import { BookingDatesStatusComponent } from '../components/booking-dates-status/booking-dates-status.component';
import { BookingStatusComponent } from '../components/booking-status/booking-status.component';
import { CancelBookingComponent } from '../components/booking/components/booking-header/cancel-booking/cancel-booking.component';
import { ButtonComponent } from '../components/button/button.component';
import { BotMessageComponent } from '../components/channel-message/bot-message/bot-message.component';
import { ChannelMessageComponent } from '../components/channel-message/channel-message.component';
import { ChannelComponent } from '../components/channel/channel.component';
import { ChannelDatesPickerComponent } from '../components/channel/components/channel-dates-picker/channel-dates-picker.component';
import { ChannelEditDatesFormComponent } from '../components/channel/components/channel-edit-dates-form/channel-edit-dates-form.component';
import { ChannelEditDatesMobileFormComponent } from '../components/channel/components/channel-edit-dates-mobile-form/channel-edit-dates-mobile-form.component';
import { ChannelHeaderComponent } from '../components/channel/components/channel-header/channel-header.component';
import { CloudImageComponent } from '../components/cloud-image/cloud-image.component';
import { ConfirmLeaveModalComponent } from '../components/confirm-leave-modal/confirm-leave-modal.component';
import { ContactInfoWarnComponent } from '../components/contact-info-warn/contact-info-warn.component';
import { ContractDateComponent } from '../components/contract-date/contract-date.component';
import { DropdownComponent } from '../components/dropdown/dropdown.component';
import { EquipmentIconsComponent } from '../components/equipment-icons/equipment-icons.component';
import { GigTypeInputComponent } from '../components/gig-type-input/gig-type-input.component';
import { GigitalHintComponent } from '../components/gigital-hint/gigital-hint.component';
import { GigitalLogoComponent } from '../components/gigital-logo/gigital-logo.component';
import { GratificationComponent } from '../components/gratification/gratification.component';
import { ImageUploadAreaComponent } from '../components/image-upload-area/image-upload-area.component';
import { CardComponent } from '../components/layout/card/card.component';
import { LinkComponent } from '../components/layout/link/link.component';
import { ModalComponent } from '../components/modal/modal.component';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { PaymentCardComponent } from '../components/payment/payment-card/payment-card.component';
import { PlectrumComponent } from '../components/plectrum/plectrum.component';
import { PriceRangeComponent } from '../components/price-range/price-range.component';
import { PriceComponent } from '../components/price/price.component';
import { ReviewsThumbsComponent } from '../components/reviews-thumbs/reviews-thumbs.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { GigFormButtonSelectComponent } from '../gigs/components/create/gig-form-button-select/gig-form-button-select.component';
import { GigFormPriceInputComponent } from '../gigs/components/create/gig-form-price-input/gig-form-price-input.component';
import { GigFormTextInputComponent } from '../gigs/components/create/gig-form-text-input/gig-form-text-input.component';
import { FallbackImageComponent } from '../gigs/components/fallback-image/fallback-image.component';
import { InputModule } from '../input/input.module';
import { ApiDatePipe } from '../pipes/api-date.pipe';
import { GigDurationPipe } from '../pipes/gig-duration.pipe';
import { MediaPipe } from '../pipes/media.pipe';
import { ParseQueryParamsPipe } from '../pipes/parse-query-params.pipe';
import { PriceWithSpacePipe } from '../pipes/price-with-space.pipe';
import { PricePipe } from '../pipes/price.pipe';
import { ReplaceTextPipe } from '../pipes/replace-text.pipe';
import { SafeCssImageUrlPipe } from '../pipes/safe-css-image-url.pipe';
import { ArtistsItemStatusComponent } from '../user/components/artists-item-status/artists-item-status.component';
import { CreateArtistComponent } from '../user/components/create-artist/create-artist.component';
import { AvatarUploaderComponent } from './components/avatar-uploader/avatar-uploader.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { GigDateComponent } from './components/gig-date/gig-date.component';
import { InfoBarComponent } from './components/info-bar/info-bar.component';
import { CheckboxInputButtonComponent } from './components/inputs/checkbox-input-button/checkbox-input-button.component';
import { CheckboxInputComponent } from './components/inputs/checkbox-input/checkbox-input.component';
import { CheckboxToggleSliderComponent } from './components/inputs/checkbox-toggle-slider/checkbox-toggle-slider.component';
import { CountryPhoneCodesDropdownComponent } from './components/inputs/country-phone-codes-dropdown/country-phone-codes-dropdown.component';
import { DateCalendarInputComponent } from './components/inputs/date-calendar-input/date-calendar-input.component';
import { FileInputDragAndDropComponent } from './components/inputs/file-input-drag-and-drop/file-input-drag-and-drop.component';
import { FileInputComponent } from './components/inputs/file-input/file-input.component';
import { InputComponent } from './components/inputs/input/input.component';
import { LocationInputComponent } from './components/inputs/location-input/location-input.component';
import { PhoneNumberInputComponent } from './components/inputs/phone-number-input/phone-number-input.component';
import { PriceInputComponent } from './components/inputs/price-input/price-input.component';
import { RadioButtonSliderInputComponent } from './components/inputs/radio-button-slider-input/radio-button-slider-input.component';
import { RadioButtonsInputComponent } from './components/inputs/radio-buttons-input/radio-buttons-input.component';
import { SearchInputComponent } from './components/inputs/search-input/search-input.component';
import { SelectInputComponent } from './components/inputs/select-input/select-input.component';
import { MediaIframeComponent } from './components/media-iframe/media-iframe.component';
import { SearchTrendItemComponent } from './components/search-trend/search-trend-item.component';
import { SliderControlsComponent } from './components/slider-controls/slider-controls.component';

@NgModule({
  declarations: [
    ChannelComponent,
    ChannelMessageComponent,
    BotMessageComponent,
    BookingCardComponent,
    AvatarComponent,
    CardComponent,
    GigTypeInputComponent,
    LinkComponent,
    PlectrumComponent,
    PriceComponent,
    CloudImageComponent,
    AddBillingInfoComponent,
    ConfirmDialogComponent,
    ModalComponent,
    GigitalHintComponent,
    PriceRangeComponent,
    // TODO: Move to artists module post ng 10
    ReviewCardComponent,
    ReviewsThumbsComponent,
    GigitalLogoComponent,
    ArtistsItemStatusComponent,
    CreateArtistComponent,
    BlogPostsPreviewComponent,
    ConfirmLeaveModalComponent,
    GratificationComponent,
    ButtonComponent,
    ArtistDropdownComponent,
    DropdownComponent,
    ImageUploadAreaComponent,
    MediaIframeComponent,
    GigFormButtonSelectComponent,
    GigFormTextInputComponent,
    GigFormPriceInputComponent,
    BackArrowTitleComponent,
    PaginatorComponent,
    ChannelHeaderComponent,
    ChannelDatesPickerComponent,
    BookingStatusComponent,
    ChannelEditDatesMobileFormComponent,
    ChannelEditDatesFormComponent,
    BookingDatesStatusComponent,
    ContactInfoWarnComponent,
    EquipmentIconsComponent,
    SliderControlsComponent,
    CancelBookingComponent,
    FallbackImageComponent,
    ContractDateComponent,
    GigDateComponent,
    AvatarUploaderComponent,
    InfoBarComponent,

    SearchTrendItemComponent,

    RadioButtonSliderInputComponent,
    FileInputDragAndDropComponent,
    CheckboxToggleSliderComponent,
    CheckboxInputButtonComponent,
    DateCalendarInputComponent,
    RadioButtonsInputComponent,
    PhoneNumberInputComponent,
    CheckboxInputComponent,
    SearchInputComponent,
    LocationInputComponent,
    SelectInputComponent,
    SearchInputComponent,
    PriceInputComponent,
    FileInputComponent,
    InputComponent,

    CountryPhoneCodesDropdownComponent,
    ParseQueryParamsPipe,
    SafeCssImageUrlPipe,
    PriceWithSpacePipe,
    GigDurationPipe,
    ReplaceTextPipe,
    ApiDatePipe,
    MediaPipe,
    PricePipe,
    ExpansionPanelComponent,

    ClickOutsideDirective,
    PaymentCardComponent,
  ],
  imports: [
    // BrowserModule,
    CommonModule,
    CloudinaryModule,
    NgxStripeModule.forRoot(environment.stripePk),
    FlexModule,
    RouterModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatExpansionModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    MatDividerModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatStepperModule,
    MatCardModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatTableModule,
    InputModule,
    MatGoogleMapsAutocompleteModule,
  ],
  exports: [
    AvatarComponent,
    CardComponent,
    ChannelComponent,
    BookingCardComponent,
    GigTypeInputComponent,
    LinkComponent,
    PlectrumComponent,
    PriceComponent,
    CloudImageComponent,
    AddBillingInfoComponent,
    ModalComponent,
    GigitalHintComponent,
    PriceRangeComponent,
    ReviewsThumbsComponent,
    // TODO: Move to artists module post ng 10
    ReviewCardComponent,
    GigitalLogoComponent,
    ArtistsItemStatusComponent,
    BlogPostsPreviewComponent,
    ButtonComponent,
    ArtistDropdownComponent,
    DropdownComponent,
    ImageUploadAreaComponent,
    MediaIframeComponent,
    GigFormButtonSelectComponent,
    GigFormTextInputComponent,
    GigFormPriceInputComponent,
    BackArrowTitleComponent,
    PaginatorComponent,
    ChannelHeaderComponent,
    BookingDatesStatusComponent,
    CancelBookingComponent,
    EquipmentIconsComponent,
    FallbackImageComponent,
    ContactInfoWarnComponent,
    SliderControlsComponent,
    GigDateComponent,
    AvatarUploaderComponent,
    InfoBarComponent,

    CountryPhoneCodesDropdownComponent,
    SearchTrendItemComponent,

    RadioButtonSliderInputComponent,
    FileInputDragAndDropComponent,
    CheckboxToggleSliderComponent,
    CheckboxInputButtonComponent,
    RadioButtonsInputComponent,
    DateCalendarInputComponent,
    PhoneNumberInputComponent,
    CheckboxInputComponent,
    LocationInputComponent,
    SelectInputComponent,
    SearchInputComponent,
    PriceInputComponent,
    FileInputComponent,
    SearchInputComponent,
    InputComponent,

    ParseQueryParamsPipe,
    SafeCssImageUrlPipe,
    PriceWithSpacePipe,
    GigDurationPipe,
    ReplaceTextPipe,
    ApiDatePipe,
    PricePipe,
    MediaPipe,

    ExpansionPanelComponent,
    PaymentCardComponent,
  ],
  entryComponents: [CreateArtistComponent, AddBillingInfoComponent, ConfirmDialogComponent],
})
export class SharedModule {}
