import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Currency, GetCurrentUserGQL, GigType, Page } from '../../../../../generated/graphql';
import { displayableFee } from '../../../../helpers/bookings';
import { formatDuration } from '../../../../helpers/dates';
import { TranslationsService } from '../../../../services/translations.service';

@Component({
  selector: 'app-artist-detail-book-summary',
  templateUrl: './artist-detail-book-summary.component.html',
  styleUrls: ['./artist-detail-book-summary.component.scss'],
})
export class ArtistDetailBookSummaryComponent implements OnInit {
  @Output() changeStepNumber$: EventEmitter<number> = new EventEmitter();

  @Input() createRequestForm: FormGroup;
  @Input() isBookingCreated: boolean;
  @Input() page$: Observable<Page>;

  public userFee: number = 1.15;

  public POLICY_LINK = $localize`http://help.gigital.se/en/articles/3350100-payment-policy`;
  public CURRENCY = Currency;

  public displayableFee = displayableFee;

  public get gigTypeTitle(): string {
    switch (this.createRequestForm.value.gigType) {
      case GigType.Business:
        return $localize`Corporate`;
      case GigType.Private:
        return $localize`Private`;
      case GigType.Venue:
        return $localize`Venue`;
      case GigType.Others:
        return $localize`Others`;
      default:
        return $localize`Corporate`;
    }
  }

  public get datesPreviewArray(): string[] {
    const locale = this.translationsService.getCurrentLocale();
    return this.createRequestForm.value.slotDates
      .map((d) => new Date(d))
      .map((d) => {
        const weekday = [
          $localize`Sunday`,
          $localize`Monday`,
          $localize`Tuesday`,
          $localize`Wednesday`,
          $localize`Thursday`,
          $localize`Friday`,
          $localize`Saturday`,
        ];
        const day = weekday[d.getDay()];
        const date = d.getDate();
        const month = new Intl.DateTimeFormat(locale, { month: 'short' }).format(d);
        const year = d.getFullYear();
        return `${day}, ${date} ${month} ${year}`;
      });
  }

  public get setLengthToDuration(): string {
    return formatDuration(this.createRequestForm.value.setLength);
  }

  public get bookingDatesPrice(): number {
    return Math.round(this.createRequestForm.value.slotDates.length * this.createRequestForm.value.budgetAll);
  }

  public get bookingFeeAmount(): number {
    return Math.round(this.bookingDatesPrice * this.userFee - this.bookingDatesPrice);
  }

  public get bookingEstimatedPrice(): number {
    return Math.round(this.bookingDatesPrice * this.userFee);
  }

  public constructor(private translationsService: TranslationsService, private getCurrentUserGQL: GetCurrentUserGQL) {}

  public ngOnInit(): void {
    this.getCurrentUserGQL
      .watch()
      .valueChanges.pipe(
        map(({ data }) => data.me),
        tap((cu) => {
          this.userFee = cu?.fee || 1.15;
        }),
      )
      .subscribe();
  }
}
