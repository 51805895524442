<div *ngIf="multiLine" fxLayout="column" class="full-height">
  <p class="title" *ngIf="!!title">{{ title }}</p>
  <textarea
    fxFlex="grow"
    class="multiline"
    [(ngModel)]="textValue"
    [placeholder]="placeholder"
    [ngClass]="{ 'has-error': hasError }"
    (input)="inputChanges($event)"
    [disabled]="disabled"
    [rows]="rows"></textarea>
</div>
<div *ngIf="!multiLine">
  <p class="title" *ngIf="!!title">{{ title }}</p>
  <input
    [(ngModel)]="textValue"
    type="text"
    [placeholder]="placeholder"
    [ngClass]="{ 'has-error': hasError }"
    (input)="inputChanges($event)"
    [disabled]="disabled" />
</div>
<div fxLayout="row" fxLayoutAlign="space-between" *ngIf="!disableMax">
  <div fxFlex="grow">
    <span [ngClass]="{ 'has-error': hasError }" class="error-message" *ngIf="hasError">{{ displayError }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="end">
    <span class="counter" [ngClass]="{ 'has-error': hasError }">{{ textValue.length }}/{{ maxChars }}</span>
  </div>
</div>
