import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeleteGigGQL, GetPagesByUserGQL, Gig, Image } from '../../../../../../generated/graphql';
import { isSlotOpen } from '../../../../../../utils/gigSlots';
import { getTownName } from '../../../../../../utils/places';
import { apiToDate, LONG_DATE } from '../../../../../helpers';
import { getGigMetaData, getTagsList, GigMeta } from '../../../../../helpers/gigs';
import { getGoogleMapsUrlFromPlaceId } from '../../../../../helpers/places';
import { IconsRegistryService, IconSubsets } from '../../../../../services/icons-registry.service';
import { TranslationsService } from '../../../../../services/translations.service';
import { GigEditBaseComponent } from '../gig-edit-base/gig-edit-base.component';

@Component({
  selector: 'app-gig-details-header',
  templateUrl: './gig-details-header.component.html',
  styleUrls: ['./gig-details-header.component.scss'],
})
export class GigDetailsHeaderComponent implements OnInit, OnChanges {
  @Input()
  gig: Gig;

  loading = false;

  meta: GigMeta;
  dateFormat = LONG_DATE;

  displayImagesUrl: SafeUrl[] = [];

  eventSpan = '';
  lookingFor = '';
  openSlots: number;

  canApply: Observable<boolean>;

  googleMapsUrl: string;
  fallbackImageUrl: string;

  firstDate: Date;
  lastDate: Date;
  dateState: 'single' | 'multi' | 'multiMonth' = 'single';
  totalBudget = 0;

  isBrowser = false;
  locationName: string;

  constructor(
    private iconsService: IconsRegistryService,
    public translations: TranslationsService,
    private getPages: GetPagesByUserGQL,
    private deleteGig: DeleteGigGQL,
    private sanitizer: DomSanitizer,
    private modal: MatDialog,

    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.iconsService.registerIcons([IconSubsets.FILLED, IconSubsets.ACTIONS]);
  }

  ngOnInit() {
    this.meta = getGigMetaData(this.gig);
    this.setGigData(this.gig);
  }

  private imageUrlBuilder(image: Image) {
    return `https://res.cloudinary.com/hthruuqgk/image/upload/c_limit,w_1200/f_auto,fl_progressive,q_auto/c_scale,dpr_1.0/v1/${image.fileName}`;
  }

  private setGigData(gig: Gig) {
    this.displayImagesUrl = gig.images.map((i) => {
      return this.sanitizer.bypassSecurityTrustUrl(`url(${this.imageUrlBuilder(i)})`);
    });

    if (this.gig.images.length === 1) {
      this.fallbackImageUrl = this.imageUrlBuilder(gig.images[0]);
    }

    if (!gig.limitedData) {
      let sortedDateSlots = gig.slots
        .filter((s) => isSlotOpen(s))
        .sort((a, b) => {
          return parseInt(a.date) - parseInt(b.date);
        });

      // If no open dates, just display full gig date span
      if (sortedDateSlots.length === 0) {
        sortedDateSlots = gig.slots.sort((a, b) => {
          return parseInt(a.date) - parseInt(b.date);
        });
      } else {
        this.firstDate = apiToDate(sortedDateSlots[0].date);
        this.lastDate = apiToDate(sortedDateSlots[sortedDateSlots.length - 1].date);

        if (this.firstDate.getTime() === this.lastDate.getTime()) {
          this.dateState = 'single';
        } else if (
          this.firstDate.getFullYear() === this.lastDate.getFullYear() &&
          this.firstDate.getMonth() === this.lastDate.getMonth()
        ) {
          // 1-2 månad
          this.dateState = 'multi';
        } else {
          // 1 månad - 2 månad
          this.dateState = 'multiMonth';
        }
      }

      this.totalBudget = gig.slots.map((s) => s.price).reduce((acc, p) => acc + p, 0);
    }
    this.openSlots = this.gig.slotsOpen;
    this.lookingFor = getTagsList(gig);

    this.canApply = this.getPages.watch().valueChanges.pipe(
      map((d) => {
        return d.data.pagesByUser.filter((p) => p.searchable).length > 0;
      }),
    );

    this.googleMapsUrl = getGoogleMapsUrlFromPlaceId(gig.placeId);

    this.locationName = getTownName(this.gig.placeData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    if (changes.gig) {
      this.meta = getGigMetaData(this.gig);
      this.setGigData(this.gig);
    }
  }

  edit() {
    this.modal.open(GigEditBaseComponent, {
      minWidth: '33%',
      data: {
        gig: this.gig,
      },
    });
  }

  update(deleteGig: boolean) {
    this.loading = true;
    this.deleteGig.mutate({ deleted: deleteGig, gigId: this.gig.id }).subscribe((d) => {
      this.loading = false;
    });
  }

  scrollToSlots() {
    if (this.isBrowser) {
      // eww I hate this get element by id crap
      var element = document.getElementById('slots');
      var rect = element.getBoundingClientRect();
      scrollTo({
        left: 0,
        top: rect.top,
        behavior: 'smooth',
      });
    }
  }
}
