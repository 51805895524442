import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetBookingsCountGQL, GetPagesByUserGQL, Page, User, UserType } from '../../../generated/graphql';
import { AuthService } from '../../services/auth.service';
import { IconsRegistryService, IconSubsets } from '../../services/icons-registry.service';
import { NavbarService } from '../../services/navbar.service';
import { NotificationsService } from '../../services/notifications.service';

export function debounce(delay: number = 150): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @ViewChild('dropDownBackground', { static: false }) dropdownBackgroundEl: ElementRef;
  @ViewChild('dropDownContent', { static: false }) dropdownContentEl: ElementRef;
  @ViewChild('dropDown', { static: false }) dropdownEl: ElementRef;

  @Output() sidenavToggle = new EventEmitter();

  @Input() user: Observable<User>;
  @Input() loading = false;

  public openBookings: Observable<number>;
  public userPages$: Observable<Page[]>;

  public displayBookingsBadge: boolean = false;
  public isBrowser: boolean = false;
  public USER_TYPE_ENUM = UserType;
  public companyDropdownBR;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,

    private getUserPagesGQL: GetPagesByUserGQL,
    private getOpen: GetBookingsCountGQL,
    public notes: NotificationsService,
    public navbarStatus: NavbarService,
    private iconsService: IconsRegistryService,
    public auth: AuthService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public ngOnInit(): void {
    this.openBookings = this.getOpen.watch().valueChanges.pipe(
      map((d) => {
        this.displayBookingsBadge = d.data.bookingsCount.open > 0;
        return d.data.bookingsCount.open;
      }),
    );

    this.userPages$ = this.getUserPagesGQL.fetch().pipe(map(({ data }) => data.pagesByUser));
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.

    if (this.isBrowser) {
      // set initial dropdown background size
      this.companyDropdownBR = this.dropdownContentEl.nativeElement.children
        .namedItem('company')
        .getBoundingClientRect();
      this.dropdownBackgroundEl.nativeElement.style.width = `${this.companyDropdownBR.width + 80}px`;
      this.dropdownBackgroundEl.nativeElement.style.height = `${this.companyDropdownBR.height + 64}px`;
    }
  }

  @HostListener('window:scroll', ['$event'])
  @debounce()
  handleScroll() {
    if (this.isBrowser) {
      const windowScroll = window.pageYOffset;
      this.navbarStatus.checkTransparency(windowScroll);
    }
  }

  showDropdown(sectionName: string): void {
    this.dropdownEl.nativeElement.classList.add('open');

    for (const element of this.dropdownContentEl.nativeElement.children) {
      element.classList.remove('active');
    }

    const dropdownContentEl = this.dropdownContentEl.nativeElement.children.namedItem(sectionName);

    const dropdownContentBR = dropdownContentEl.getBoundingClientRect();
    this.dropdownBackgroundEl.nativeElement.style.transform = `
        scaleX(${(dropdownContentBR.width + 80) / (this.companyDropdownBR.width + 80)})
        scaleY(${(dropdownContentBR.height + 64) / (this.companyDropdownBR.height + 64)})
        `;
    this.dropdownBackgroundEl.nativeElement.classList.add('animate');

    dropdownContentEl.classList.add('active');

    // Resize and position background

    // // Resize and position content
    // contentEl.style.width = dimensions[section].width + 'px';
    // contentEl.style.height = dimensions[section].height + 'px';
    // contentEl.style.transform = `translateX(${dimensions[section].x}px)`;
  }

  hideDropdown(): void {
    if (this.dropdownEl) {
      this.dropdownEl.nativeElement.classList.remove('open');
      for (const element of this.dropdownContentEl.nativeElement.children) {
        element.classList.remove('active');
      }
    }
  }

  logout() {
    this.auth.logout();
  }

  onToggleSideNav(): void {
    this.sidenavToggle.emit();
  }

  openNotifications() {
    this.notes.setNotificationsHalfRead();
  }
}
