import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArtistProgressStep } from '../../../../../models/ArtistProgress';
import { InfoBarType } from '../../../../../shared/components/info-bar/info-bar.component';

@Component({
  selector: 'app-artist-detail-edit-import',
  templateUrl: './artist-detail-edit-import.component.html',
  styleUrls: ['./artist-detail-edit-import.component.scss'],
})
export class ArtistDetailEditImportComponent {
  @Output() goToStep$: EventEmitter<number> = new EventEmitter<number>();

  @Input() pageProgressStep: ArtistProgressStep;
  @Input() isCreateMode: boolean;
  @Input() pageId: number;

  public INFO_BAR_TYPE = InfoBarType;
}
