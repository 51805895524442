import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Booking, EquipmentStatus, SupplyTypes } from '../../../../generated/graphql';

@Component({
  selector: 'app-edit-supplies',
  templateUrl: './edit-supplies.component.html',
  styleUrls: ['./edit-supplies.component.scss'],
})
export class EditSuppliesComponent implements OnInit {
  public editGroup: FormGroup;
  public booking: Booking;

  public speakers: AbstractControl;
  public mixer: AbstractControl;
  public none: AbstractControl;
  public pa: AbstractControl;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<EditSuppliesComponent>) {}

  ngOnInit(): void {
    this.initForm();
    this.booking = this.data.booking;

    this.mixer = this.editGroup.get('suppliesMixer');
    this.pa = this.editGroup.get('suppliesPa');
    this.speakers = this.editGroup.get('suppliesSpeakers');
    this.none = this.editGroup.get('suppliesNone');

    this.mixer.setValue(this.booking.supplies.includes(SupplyTypes.Mixer));
    this.pa.setValue(this.booking.supplies.includes(SupplyTypes.Pa));
    this.speakers.setValue(this.booking.supplies.includes(SupplyTypes.Speakers));
    this.none.setValue(!this.mixer.value && !this.pa.value && !this.speakers.value);
    this.editGroup.get('supplyStatus').setValue(this.booking.supplyStatus !== EquipmentStatus.None);

    this.mixer.valueChanges.subscribe((v) => {
      this.toggleNone();
    });
    this.pa.valueChanges.subscribe((v) => {
      this.toggleNone();
    });
    this.speakers.valueChanges.subscribe((v) => {
      this.toggleNone();
    });

    this.none.valueChanges.subscribe((v) => {
      if (v) {
        this.none.disable({ emitEvent: false, onlySelf: true });
        this.mixer.setValue(false, { emitEvent: false, onlySelf: true });
        this.pa.setValue(false, { emitEvent: false, onlySelf: true });
        this.speakers.setValue(false, { emitEvent: false, onlySelf: true });
      } else {
        this.none.enable({ emitEvent: false, onlySelf: true });
      }
    });
  }

  private initForm(): void {
    this.editGroup = new FormGroup({
      suppliesMixer: new FormControl(false),
      suppliesSpeakers: new FormControl(false),
      suppliesPa: new FormControl(false),
      suppliesNone: new FormControl(true),
      supplyStatus: new FormControl(false),
    });
  }

  toggleNone() {
    this.none.setValue(!(this.mixer.value || this.pa.value || this.speakers.value));
  }

  getSelectedSupplies() {
    const supplies: SupplyTypes[] = [];
    if (this.mixer.value) {
      supplies.push(SupplyTypes.Mixer);
    }
    if (this.speakers.value) {
      supplies.push(SupplyTypes.Speakers);
    }
    if (this.pa.value) {
      supplies.push(SupplyTypes.Pa);
    }

    return supplies;
  }

  private getSupplyStatus(): EquipmentStatus {
    if (!this.editGroup.get('supplyStatus').value) {
      return EquipmentStatus.None;
    } else {
      if (this.booking.supplyStatus === EquipmentStatus.None) {
        return EquipmentStatus.Requested;
      } else return this.booking.supplyStatus;
    }
  }

  public save(): void {
    let editSupplyData: any = {
      supplies: this.getSelectedSupplies(),
    };

    if (this.editGroup.get('supplyStatus').dirty) {
      editSupplyData.supplyStatus = this.getSupplyStatus();
    }

    this.dialogRef.close(editSupplyData);
  }
}
