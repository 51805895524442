<div fxLayout="column" fxLayoutGap="8px" class="form-input">
  <p
    *ngIf="!!label"
    fxLayoutAlign="start center"
    fxLayoutGap="6px"
    class="form-input--label"
    [ngClass]="{ light: isLightLabel }">
    <span>{{ translatedLabel }}</span>
    <mat-icon *ngIf="!!labelTooltip" [matTooltip]="labelTooltip" svgIcon="info-fill"></mat-icon>
  </p>

  <ng-container *ngIf="multiLine">
    <textarea
      fxFlex="grow"
      [(ngModel)]="textValue"
      [placeholder]="placeholder"
      [ngClass]="{ 'has-error': hasError, invalid: invalid }"
      (input)="inputChanges($event)"
      [disabled]="disabled"
      [rows]="rows"></textarea>
  </ng-container>

  <div *ngIf="!multiLine" class="input-container">
    <input
      fxFlex="grow"
      [(ngModel)]="textValue"
      [type]="type"
      (keypress)="keyPressNumbers($event)"
      [placeholder]="placeholder"
      [ngClass]="{ 'has-error': hasError, uppercase: uppercase, invalid: invalid }"
      [style.min-height]="specHeight ? specHeight + 'px' : '48px'"
      (input)="inputChanges($event)"
      [disabled]="disabled" />

    <span *ngIf="mask" class="mask">{{ mask }}</span>
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="space-between" *ngIf="showMaxChars">
  <div fxFlex="grow">
    <span [ngClass]="{ 'has-error': hasError }" class="error-message" *ngIf="hasError">{{ displayError }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="end">
    <span class="counter" [ngClass]="{ 'has-error': hasError }">{{ textValue.length }}/{{ maxChars }}</span>
  </div>
</div>
