<div>
  <app-gigs-header></app-gigs-header>
  <!-- <app-gigs-navbar></app-gigs-navbar> -->

  <app-gigs-list></app-gigs-list>
</div>
<!-- <ng-template #loggedOut>
  <app-gigs-logged-out></app-gigs-logged-out>
</ng-template> -->

<router-outlet></router-outlet>
