import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Image } from '../../../../../../generated/graphql';
import { ImageSelectedEvent } from '../../../../components/image-cropper/image-cropper.component';

@Component({
  selector: 'app-upload-image-modal',
  templateUrl: './upload-image-modal.component.html',
  styleUrls: ['./upload-image-modal.component.scss'],
})
export class UploadImageModalComponent implements OnInit {
  image: ImageSelectedEvent;
  constructor(
    private modalRef: MatDialogRef<UploadImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { aspect: number; type: number; currentImage: Image },
  ) {}

  ngOnInit() {}

  imageChanges(ev: ImageSelectedEvent) {
    this.image = ev;
  }

  save() {
    this.modalRef.close({
      image: this.image,
    });
  }
}
