<div fxLayout="column">
  <ng-container *ngIf="possibleReviews$ | async as reviews">
    <section *ngIf="reviews.length > 0" class="m-b--45">
      <h2 class="title m-b--24">
        <span i18n>Artist reviews</span>
        <span *ngIf="reviewsCount > 0"> ({{ reviewsCount }}) </span>
      </h2>

      <app-quick-review-slider [possibleReviews$]="possibleReviews$"></app-quick-review-slider>
    </section>
  </ng-container>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxLayoutGap="32px" class="m-b--64">
    <app-image-card-dashboard
      fxFlex="50%"
      fxFlex.lt-md="334px"
      imageName="web/Dashboard/Hendricks_Cucumber_Day_-_Grand_Hotel_-_Downtown_Camper-106_wgyk0j"
      [imageData]="{
        crop: {
          x: 100,
          y: 100
        },
        size: 'cover'
      }"
      [flipH]="true"
      title="Post a gig"
      i18n-title
      subTitle="Post a gig and find the perfect artists for your next event "
      i18n-subTitle
      buttonName="Post gig"
      i18n-buttonName
      buttonUrl="/gigs/add"></app-image-card-dashboard>
    <app-image-card-dashboard
      fxFlex="50%"
      fxFlex.lt-md="334px"
      imageName="web/Dashboard/dashboard-browse-artists-1_ojnnmi"
      [imageData]="{
        crop: {
          x: 50,
          y: 55
        },
        size: 'cover'
      }"
      title="Browse our artists"
      i18n-title
      subTitle="We have a unique selection of artists ready to perform magical gigs for you"
      i18n-subTitle
      buttonName="See all artists"
      i18n-buttonName
      buttonUrl="/artists/search"></app-image-card-dashboard>
  </div>

  <app-customer-gigs-dashboard class="m-b--85"></app-customer-gigs-dashboard>

  <ng-container *ngIf="dashboardBookings$ | async as dashboardBookings">
    <div *ngIf="dashboardBookings.pendingCount > 0" class="m-b--85">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="m-b--15">
        <h2 class="title">
          <span i18n="@@dashboarPendingBookings">Pending</span>
          <span *ngIf="dashboardBookings.pendingCount > 0"> ({{ dashboardBookings.pendingCount }}) </span>
        </h2>
        <a *ngIf="dashboardBookings.pendingCount > 0" class="see-all" [routerLink]="['/bookings']" i18n>See all</a>
      </div>

      <mat-divider *ngIf="dashboardBookings.pendingCount <= 0" class="m-b--15"></mat-divider>

      <app-dashboard-pending-bookings
        [pendingBookings]="dashboardBookings.pending"
        [pendingCount]="dashboardBookings.pendingCount"
        class="m-b--50">
      </app-dashboard-pending-bookings>
    </div>

    <div class="m-b--85">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="m-b--15">
        <h2 class="title">
          <span i18n>Bookings</span>
          <span *ngIf="dashboardBookings.upcomingCount > 0"> ({{ dashboardBookings.upcomingCount }}) </span>
        </h2>
        <a *ngIf="dashboardBookings.upcomingCount > 0" class="see-all" [routerLink]="['/bookings']" i18n>See all</a>
      </div>

      <mat-divider *ngIf="dashboardBookings.upcomingCount <= 0" class="m-b--15"></mat-divider>

      <app-dashboard-booking-dates
        [upcomingDates]="dashboardBookings.upcomingDates"
        [upcomingCount]="dashboardBookings.upcomingCount"
        class="m-b--105">
      </app-dashboard-booking-dates>
    </div>
  </ng-container>
</div>
