import { Component, Input, OnInit } from '@angular/core';
import { ApplicationStatus } from '../../../helpers/applications';
import { GigStatus } from '../../../helpers/gigs';

@Component({
  selector: 'app-gig-status',
  templateUrl: './gig-status.component.html',
  styleUrls: ['./gig-status.component.scss'],
})
export class GigStatusComponent implements OnInit {
  @Input() gigStatus: string;
  @Input() applicationStatus: string;

  public GIG_STATUS = GigStatus;
  public APP_STATUS = ApplicationStatus;

  constructor() {}

  ngOnInit(): void {}
}
