<div fxLayout="column" fxLayoutGap="8px" class="form-input">
  <p
    *ngIf="!!label"
    fxLayoutAlign="start center"
    fxLayoutGap="6px"
    class="form-input--label"
    [ngClass]="{ light: isLightLabel }">
    <span>{{ label }}</span>
    <mat-icon *ngIf="!!labelTooltip" [matTooltip]="labelTooltip" svgIcon="info-fill"></mat-icon>
  </p>

  <div (clickOutside)="onOutsideClick($event)">
    <div class="datepicker">
      <div
        fxLayoutAlign="space-between center"
        (click)="isCalendarOpened = !isCalendarOpened"
        [ngClass]="{ open: isCalendarOpened, invalid: invalid }"
        class="datepicker--input">
        <span *ngIf="slotDates?.length <= 0 && selectedDates?.length <= 0" class="placeholder">{{ placeholder }} </span>
        <span *ngIf="slotDates?.length > 0 || selectedDates?.length > 0">{{ selectedDatesPreview }} </span>
        <mat-icon svgIcon="calendar"></mat-icon>
      </div>

      <div *ngIf="isCalendarOpened" class="datepicker--calendar">
        <mat-calendar
          [minDate]="minDate"
          [single]="singleDate"
          [dateClass]="classicistMultiple()"
          [selectedDates]="selectedDates"
          (selectedChange)="selectDate($event)">
        </mat-calendar>
      </div>
    </div>
  </div>
</div>
