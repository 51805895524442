<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="item-container cursor-pointer"
  *ngIf="user | async as user">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayoutGap.gt-md="56px">
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
      <div class="image-container">
        <app-cloud-image
          [profilePicture]="gig?.images[0]"
          [maxWidth]="45"
          *ngIf="gig?.images?.length > 0"></app-cloud-image>
        <app-fallback-image
          class="gig-image"
          *ngIf="gig?.images.length === 0"
          [hideText]="true"
          [gig]="gig"></app-fallback-image>
        <app-fallback-image
          class="gig-image"
          *ngIf="template"
          [hideText]="true"
          [template]="template"></app-fallback-image>
      </div>
      <span class="gig-name" [ngClass]="{ template: !!template }">{{
        gig?.name || template.name | replaceText: COMPANY_REPLACE_STRING:user.companyName
      }}</span>
    </div>
    <ng-container *ngIf="gig">
      <span *ngIf="dateState === 'single'" class="info-section content">{{ firstDate | date: 'd MMM y' }}</span>
      <span *ngIf="dateState === 'multi'" class="info-section content"
        >{{ firstDate | date: 'd' }} - {{ lastDate | date: 'd MMM y' }}</span
      >
      <span *ngIf="dateState === 'multiMonth'" class="info-section content"
        >{{ firstDate | date: 'd MMM' }} - {{ lastDate | date: 'd MMM y' }}</span
      >
    </ng-container>
  </div>
  <span class="icon-container" fxHide fxShow.gt-md><mat-icon svgIcon="expand-left"></mat-icon></span>
</div>
