<a
  fxShow
  fxHide.lt-md
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="data-item"
  [routerLink]="['/gigs', gig.id]">
  <div fxFlex="25%" fxLayout="row" fxLayoutGap="16px">
    <div class="image-container">
      <app-cloud-image
        *ngIf="gig.images?.length > 0"
        [profilePicture]="gig.images[0]"
        [altText]="gig.name + 's image'"
        [maxWidth]="60"
        i18n-altText></app-cloud-image>
      <app-fallback-image [gig]="gig" *ngIf="!gig.images || gig.images.length === 0"></app-fallback-image>
    </div>
    <div fxLayout="column">
      <span class="booking-name">{{ gigName }}</span>
      <app-gig-status [gigStatus]="gigStatus"></app-gig-status>
    </div>
  </div>

  <div fxFlex="25%">
    <ng-container *ngIf="gig.newApplications > 0">
      <span class="f-weight--600">{{ gig.newApplications }}</span
      >&nbsp;<span i18n>new applications</span>
    </ng-container>
  </div>

  <div fxFlex="25%" fxLayout="column">
    <span class="gray-13"
      ><span class="f-weight--600">{{ remainingSlots.length }}</span
      >&nbsp;<span i18n="5 out of 8 (slots)">out of</span>&nbsp;<span class="f-weight--600">{{
        gig.slots.length
      }}</span></span
    >
  </div>

  <a fxFlex="15%" fxLayout="row" fxLayoutAlign="space-between" class="gray-1">
    <span *ngIf="isBooked" i18n>Add new slots</span>
    <span *ngIf="!isBooked!" i18n>Manage gig</span>
    <mat-icon class="arrow-icon" svgIcon="expand-left"></mat-icon>
  </a>
</a>

<!-- mobile view -->
<a
  fxHide
  fxShow.lt-md
  [routerLink]="['/gigs', gig.id]"
  fxLayout="row"
  fxLayoutGap="16px"
  fxLayoutAlign="space-between center"
  class="data-item mobile">
  <div fxFlex="81px" class="image-container mobile">
    <app-cloud-image
      *ngIf="gig.images?.length > 0"
      [profilePicture]="gig.images[0]"
      [altText]="gigName + 's image'"
      [maxWidth]="60"
      i18n-altText></app-cloud-image>
    <app-fallback-image [gig]="gig" *ngIf="!gig.images || gig.images.length === 0"></app-fallback-image>
  </div>

  <div fxLayout="column" fxFlex="grow">
    <span class="booking-name mobile">{{ gigName }}</span>
    <span *ngIf="remainingSlots.length > 0" class="m-b--4">
      <span class="f-weight--600">{{ remainingSlots.length }}</span>
      <span>/</span>
      <span class="f-weight--600">{{ gig.slots.length }}</span>
      &nbsp;<span i18n>slots remaining</span>
    </span>
    <span *ngIf="remainingSlots.length <= 0" class="m-b--4">
      <app-gig-date
        [startTime]="gig.slots[0]?.startTime"
        [endTime]="gig.slots[0]?.endTime"
        [dateFormat]="DATE_FORMAT"
        [date]="gig.slots[0]?.date"
        [isTimeDisplay]="false"></app-gig-date>
    </span>
    <app-gig-status class="mobile" [gigStatus]="gigStatus"></app-gig-status>
  </div>

  <mat-icon class="arrow-icon mobile" svgIcon="expand-left"></mat-icon>
</a>
