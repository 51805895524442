<main fxLayout.gt-md="row" fxLayout="column-reverse" fxLayoutAlign="center center" class="container">
  <div fxFlex.gt-md="40%" class="forgot-password">
    <mat-card class="fade-in" *ngIf="!apiSuccess; else success">
      <mat-card-title class="forgot-password-title" i18n>
        Reset your password
      </mat-card-title>
      <mat-card-content>
        <form fxLayout="column" class="forgot-password-form" [formGroup]="resetPasswordForm" (submit)="onSubmit()">
          <mat-form-field>
            <input matInput id="username" placeholder="Email" formControlName="username" />
            <mat-error *ngIf="username.hasError('email')" i18n>
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="username.hasError('required')" i18n>
              Email is required
            </mat-error>
          </mat-form-field>
          <button type="submit" mat-flat-button color="primary" [disabled]="loading" i18n>Reset password</button>
          <mat-error *ngIf="apiError" class="submit-error">{{ apiError }}</mat-error>
        </form>
        <div fxLayout="column" class="forgot-password-social">
          <p class="gray-3 mat-caption" i18n
            >If you have problems logging in with a social account, we can unfortunately not help you with resetting
            your password.</p
          >
        </div>
      </mat-card-content>
      <mat-card-actions class="forgot-password-links">
        <p class="mat-body-2 gray-2" i18n>Don't have an account? <a [routerLink]="['/signup']">Sign up here</a> </p>
        <p class="mat-body-2 gray-2" i18n>Got your password? <a [routerLink]="['/login']">Log in here</a> </p>
      </mat-card-actions>
    </mat-card>
  </div>
  <div
    fxFlex.gt-md="60%"
    fxFlexAlign.gt-md="start"
    fxLayout.gt-md="column"
    fxLayoutAlign.gt-md="center"
    class="welcome"
  >
    <h2 class="mat-display-3 gray-1" i18n>Welcome to Gigital</h2>
    <h3 class="mat-display-1 gray-2" i18n>Let's reset your password</h3>
  </div>
</main>

<ng-template #success>
  <mat-card>
    <mat-card-title class="forgot-password-title" i18n>
      Reset your password
    </mat-card-title>
    <mat-card-content>
      <div class="forgot-password-success" fxLayout="column">
        <p class="forgot-password-success-icon fade-in">
          <mat-icon>check_circle_outline</mat-icon>
        </p>
        <p>
          {{ apiSuccess }}
        </p>
        <a [routerLink]="['/login']" mat-flat-button color="primary" i18n>Go back to log in</a>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
