import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Page, PageLink } from '../../../../generated/graphql';

@Component({
  selector: 'app-artist-list-item-video',
  templateUrl: './artist-list-item-video.component.html',
  styleUrls: ['./artist-list-item-video.component.scss'],
})
export class ArtistListItemVideoComponent {
  constructor(
    public dialogRef: MatDialogRef<ArtistListItemVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { page: Page; pageVideo: PageLink },
  ) {}
}
