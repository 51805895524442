<div class="dropdown pad cursor-pointer" [class.opened]="opened">
  <div fxLayout="column" (click)="toggleOpened($event)">
    <div class="selection" [ngClass]="{ 'hover-bg-color': !isDisabled }" fxLayout="row" fxLayoutAlign="space-between">
      <ng-content select="[selected]"></ng-content>
      <div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="center center">
        <img
          *ngIf="!isSmallIcon"
          class="expand-arrow big"
          [ngClass]="{ disabled: isDisabled }"
          src="assets/icons/navigation/expand-left.svg" />
        <img
          *ngIf="isSmallIcon"
          class="expand-arrow small"
          [ngClass]="{ disabled: isDisabled }"
          src="assets/icons/navigation/expand-down-small.svg" />
      </div>
    </div>
  </div>
  <div #dropdownContent class="dropdown-content pad" [class.opened]="opened">
    <div fxLayout="column" class="dropdown-list">
      <ng-content select="[options]"></ng-content>
    </div>
  </div>
</div>
