<main class="main-container">
    <section class="container">
        <div fxLayout="row">
            <div fxLayout="column" fxLayoutGap="24px">
              <div>
                <h1 i18n="@@termsOfService">Terms of Service</h1>
                <h2 class="p mb-4">Du kanske också är intresserad av vår <a href="http://help.gigital.se/en/articles/3304374-privacy-policy" rel="noreferrer" target="_blank">integritetspolicy</a>
                <!-- <h2 class="p mb-4">Du kanske också är intresserad av vår <a [routerLink]="['/legal/privacy']">integritetspolicy</a> -->
                    <!-- eller <a [routerLink]="['/legal/cancellation']">avbokningspolicy</a>?</h2> -->
                    eller <a href="http://help.gigital.se/en/articles/3304388-cancellation-policy" target="_blank" rel="noreferrer">avbokningspolicy</a>?</h2>
              </div>

                <!-- Conversations -->
                <div fxLayout="row">
                    <div fxLayout="column">
                        <div class="bg-white main-text">
                            <h2 class="h5">Definitioner</h2>
                            <dl>
                                <dt>Företag</dt>
                                <dd>GIGITAL AB. Organisationsnummer 559112-1461.</dd>

                                <dt>Applikation</dt>
                                <dd>www.gigital.se. Går i detta dokument under benämningen Gigital.</dd>

                                <dt>Leverantör</dt>
                                <dd>En musikalisk artist vars tjänster finns utannonserade på Gigital.</dd>

                                <dt>Kund</dt>
                                <dd>En beställare av Leverantörens tjänster. Kunden använder Gigitals tjänster i syfte
                                    att
                                    hitta, jämföra
                                    och boka leverantörer för uppdrag på privata eller offentliga evenemang.</dd>

                                <dt>Användare</dt>
                                <dd>Både Kunder och Leverantörer registrerade på Gigital.</dd>

                                <dt>Ingånget avtal</dt>
                                <dd>Avtal som ingås i samband med överenskommelse mellan Kund och Leverantör avseende
                                    Tjänsten.</dd>

                                <dt>Tjänst</dt>
                                <dd>Framförande av Leverantör vid evenemang.</dd>
                            </dl>

                            <h2 class="h5 mt-5">Information om Gigital.se</h2>
                            <p>
                                Gigital.se är en tjänst som finns tillgänglig på Internet under adressen
                                http://www.gigital.se, benämns i användarvillkoren
                                som Gigital.
                            </p>
                            <p>
                                Gigital är en digital marknadsplats för upplevelseindustrin. Via marknadsplatsen kan
                                Leverantör publicera information om
                                sina Tjänster. I sin tur kan Kund använda marknadsplatsens funktioner för att hitta och
                                boka Leverantörens
                                framförande till privata och offentliga evenemang.
                            </p>
                            <p>
                                Användare av tjänsten registrerar sig utan kostnad och vid försäljning dras
                                bokningsavgift
                                för vardera part av Gigital. Läs
                                mer under “Pris och avgifter”. Gigital har rätten att ändra pris och avgifter och
                                Användare
                                har skyldighet
                                att kontrollera eventuella ändringar i “Pris och avgifter”.
                            </p>
                            <p>
                                Dessa användarvillkor gäller dig som Leverantör och Kund. Genom att registrera dig på
                                Gigital godkänner du dessa Användarvillkor
                                enligt detta avtal. Användarvillkoren gäller i Sverige. Personer under 18 år får inte
                                använda Gigital utan
                                målsmans godkännande.
                            </p>

                            <h2 class="h5 mt-5">Ansvar vid köp och försäljning av Tjänster till privata och offentliga
                                evenemang via Gigital.se</h2>
                            <p>
                                Leverantören och Kunden ansvarar för alla överenskommelser som sker via Gigital.
                                Gigital är
                                fri från ansvar och skyldigheter
                                för ouppfyllda överenskommelser från någon av parterna. Om informationen förmedlad av
                                Leverantör och/eller
                                Kund via Gigital är vilseledande eller felaktig ska dessa första taget hantera ärendet
                                sinsemellan. Om ärendet
                                inte är löst bör Leverantörer och/eller Kunden vända sig till Gigital med sitt ärende.
                                I de
                                fall då Leverantören
                                och/eller Kunden missköter sig på så sätt att Gigital bär kostnaden för misstagen
                                erhåller
                                Gigital ersättning
                                från berörd part motsvarande hela beloppet.
                            </p>
                            <p>
                                Användarna är själva ansvariga och skyldiga att följa svenska lag och regler kring
                                momsredovisning, skatteinbetalning och
                                andra avgifter i samband med försäljning och köp av Tjänster.
                            </p>

                            <h2 class="h5 mt-5">Skyldighet att följa svensk lag & riktlinjer</h2>
                            <p>
                                Leverantören är ytterst ansvariga för beskrivningen av sina Tjänster och erbjudanden.
                                Informationen som publiceras av Leverantören
                                måste vara korrekt, ej vilseledande och i övrigt förenligt med svensk lagstiftning och
                                tillämpliga regler.
                                Användarna är även ansvarig för att denne äger upphovsrätt och publiceringsrätt av
                                materialet som denne publicerar
                                på Gigital.
                            </p>
                            <p>
                                Gigital accepterar inte att Användare publicerar material eller uttrycker sig i
                                Gigitals
                                kanaler på ett sätt som kan anses
                                vara stötande, kränkande, uppmana till illegal aktivitet eller olämpligt. Materialet
                                som
                                publiceras av Användarna
                                får inte heller vara skadligt för eller illojalt mot Gigital. Missbruk av Gigitals
                                tjänster
                                och funktioner
                                är förbjudet. Gigital ansvarar för tjänsten enligt lagen om elektroniska anslagstavlor
                                (SFS
                                (1998:112)).
                            </p>

                            <h2 class="h5 mt-5">Immateriella rättigheter</h2>
                            <p>
                                Gigital har rätten att nyttja materialet publicerat av Användare såsom texter,
                                logotyper,
                                bildmaterial och övrigt material.
                                Immateriella rättigheter överlåts inte till Gigital utan kvarstannar hos Användaren och
                                dennes samarbetspartner.
                                Övrigt material som texter, grafik, bilder och logotyper som tillhandahålls via
                                tjänsten
                                ägs av Gigital och
                                är skyddat enligt upphovsrättslagen och andra immaterialrättsliga lagar. Databasen som
                                ett
                                resultat av Användarnas
                                aktivitet på Gigital är skyddad enligt upphovsrättslagen.

                            </p>

                            <h2 class="h5 mt-5">Överträdelse av lag och Användarvillkor</h2>
                            <p>
                                Gigital äger rätten att stänga av Användare som bryter mot svensk lag eller detta
                                avtal.
                                Gigital kan neka publicering eller
                                ta bort publicerat material om det strider mot dessa Användarvillkor. Vid upprepade
                                överträdelser eller allvarliga
                                brott mot reglerna kan en Användare stängas av med omedelbar verkan. Användare som
                                genom
                                vårdslöshet orsakat
                                en ekonomisk skada på Gigital är skyldig att ersätta denna. Gigital säkerställer
                                säkerheten
                                på webbplatsen
                                genom lämpliga skyddsfunktioner. Alla intrång i Gigitals system polisanmäls omedelbart.
                            </p>
                            <h3 class="h6 mt-3">Materialet som läggs upp av Användare får inte:</h3>
                            <ul class="small-p">
                                <li>Innehålla skadlig kod i form av virus, trojaner eller motsvarande.</li>
                                <li>Innehålla spam, reklam, kedjebrev eller motsvarande.</li>
                                <li>Innehålla pornografi, våldsskildringar eller motsvarande.</li>
                                <li>Innehålla kränkande yttranden eller hets mot folkgrupp.</li>
                                <li>Innehålla personuppgifter som gör intrång på/eller kränker privatlivet.</li>
                                <li>Uppmana till illegal aktivitet.</li>
                                <li>Vara falska, felaktiga, oriktiga eller missledande.</li>
                                <li>Bryta mot gällande lagar och regelverk eller tystnadsplikt</li>
                            </ul>

                            <h2 class="h5 mt-5">Ändrade villkor</h2>
                            <p>
                                Gigital har rätten att ändra Användarvillkoren. Användare bär skyldigheten att hålla
                                sig
                                uppdaterade avseende Gigitals tjänster
                                och Användarvillkor. Vid betydande ändringar som påverkar Användarnas aktivitet på
                                Gigital
                                meddelas ändringarna
                                per e-mail och/eller på Applikationen. Genom användning av Gigital accepterar Användare
                                de
                                aktuella användarvillkoren.
                                Om Användaren önskar att avbryta sin verksamhet på Gigital kan denne avregistrera sig
                                på
                                www.gigital.se
                            </p>

                            <h2 class="h5 mt-5">Pris och avgifter</h2>
                            <p>
                                Användning av Gigital är gratis tills ett avtal om en Tjänst ingås av Kunden och
                                Leverantören. Avgiften för Kunden är ett
                                påslag på 15% av det överenskomna försäljningsbeloppet. För Leverantörens del tas en
                                avgift
                                på 15% av försäljningsbeloppet
                                som dras av på Leverantörens utbetalning.
                            </p>

                            <h2 class="h5 mt-5">Avtalsperiod</h2>
                            <p>
                                Användare har möjlighet att säga upp sitt konto när som helst. För att uppsägningen ska
                                vara korrekt måste Användaren avregistrera
                                sig på www.gigital.se. Användaren som avslutar sitt konto måste stå för de eventuella
                                omkostnader detta orsakar
                                Gigital eller andra Användaren som denne har ingått avtal med.

                            </p>

                            <h2 class="h5 mt-5">Material, layout and textinnehåll</h2>
                            <p>
                                Gigital äger rätten att ändra design, layout och funktionalitet på Gigital. Material
                                som
                                tillhandahålls av Användare kan
                                granskas och redigeras av Gigital. Gigital bär däremot inget ansvar för att materialet
                                publicerat av Användare
                                är på något sätt korrekt. Om det upptäcks att Gigitals egna eller av Användarna
                                upplagda
                                materialet är inkorrekt
                                eller missvisande tas eller redigeras bort de felaktiga uppgifterna omedelbart.
                            </p>

                            <h2 class="h5 mt-5">Regler vid registrering</h2>
                            <ul class="p">
                                <li>För att bruka Gigitals tjänster måste Användare ha ett registrerat konto på
                                    www.gigital.se</li>
                                <li>
                                    Leverantörer som registrerar sig på Gigital måste vara oförhindrade att ingå avtal
                                    med
                                    Gigital eller kunder som i sin tur
                                    köper Leverantörens tjänster.
                                </li>
                                <li>
                                    Gigital äger rätten att kvalitetsgranska Leverantörer och dess erbjudanden i den
                                    mån
                                    det går
                                </li>

                                <li>Gigital förbehåller sig rätten att korrigera Användarnas profiler i de fallen då
                                    korrigeringen skulle
                                    generera bättre filteringsfunktionalitet och sökoptimering</li>
                                <li>Gigital förbehåller sig rätten att placera vissa användare i flera kategorier och
                                    ge
                                    personer extra exponering
                                    om sådant är skäligt</li>
                                <li>Information kopplat till Användarens konto får inte vara vilseledande eller oriktig</li>
                                <li>Leverantören anger priset för sina Tjänster i SEK</li>
                                <li>Ersättningen för Tjänsten kan ändras av Kund fram tills att en överenskommelse med
                                    Leverantör finns</li>
                                <li>För att kunna få utbetalningar behöver Leverantören ange bankkonto,
                                    organisationsnummer
                                    och adress. I
                                    de fall Leverantören använder sig av en tredje part för fakturering behöver ett
                                    referensnummer anges</li>
                            </ul>

                            <h2 class="h5 mt-5">Överlåtelse av fakturafordringar</h2>
                            <h3 class="h6 mt-3">Specifika villkor för Leverantörer</h3>
                            <p>På de villkor som följer av denna punkt (Överlåtelse av fakturafordringar) överlåter
                                härmed
                                Leverantören sina
                                fakturafordringar på Kunden till Gigital. Leverantören är skyldig att överlåta sina
                                fakturafordringar till
                                Gigital.
                            </p>
                            <p>
                                <i>Garantier och vissa åtaganden Leverantören bekräftar och garanterar:
                                </i>
                            </p>
                            <p>

                                (a) att inga hinder föreligger mot en överlåtelse av Fakturafordringarna;
                                <br />(b) att Fakturafordringarna inte överlåtits eller pantsatts till annan;
                                <br />(c) att det inte föreligger någon kvittningsrätt för Kunden (och Kunden avstår
                                härigenom från sådan
                                kvittningsrätt);
                                <br />(d) att de uppgifter de uppgett genom Applikationerna överensstämmer med
                                villkoren
                                som följer av det
                                Ingångna Avtalet;
                                <br />(e) att de uppgifter de lämnat i Avtalet är korrekta, fullständiga och
                                ickemissvisande samt att de har
                                rätt och förmåga att fullfölja sina förpliktelser som följer av det Ingångna Avtalet;
                                <br />(f) att det inte föreligger någon tvist om Fakturafordringarna vid
                                överlåtelsetidpunkten;
                                <br />(g) att det inte föreligger något avtal eller överenskommelse som strider mot det
                                Ingångna Avtalet samt
                                att det inte föreligger någon omständighet som kan komma att föranleda någon tvist med
                                anledning av det Ingångna
                                Avtalet; och
                                <br />(h) att de själva inte är på obestånd eller riskerar att hamna på obestånd.
                            </p>
                            <p>
                                Leverantören har inte rätt att efter överlåtelsen nedsätta kraven under
                                Fakturafordringarna
                                eller medge betalningsanstånd
                                till Kunden.
                            </p>
                            <p>
                                Varje faktura som utfärdas avseende Fakturafordringarna, ska utfärdas genom
                                Applikationerna
                                och förses med texten ”Fordran
                                enligt denna faktura har överlåtits till Gigital AB, org.nr. 559112-1461. Betalning kan
                                därför med befriande
                                verkan endast ske till Gigital AB.”.
                            </p>
                            <p>
                                Om Kund erlägger betalning för överlåten fordran direkt till Leverantören, eller någon
                                i
                                intressegemenskap med denne, skall
                                Leverantören genast meddela Gigital och överföra det erhållna beloppet till Gigital.
                            </p>
                            <p>Gigital äger vid utbetalning till Leverantören, oavsett grunden för utbetalningen, rätt
                                att
                                avräkna vad Gigital
                                har att fordra på grund av kostnader, avgifter, återkrav eller annan anledning med stöd
                                av
                                detta avtal eller
                                annat avtal parterna emellan.
                            </p>
                            <p>
                                <i>Övrigt avseende överlåtelse av fakturafodran
                                </i>
                            </p>
                            <p>För undvikande av tvivel ska vad som anges i Användarvillkoren äga tillämpning avseende
                                dessa Faktursköpsvillkor.
                                Fakturaköpsvillkoren är en integrerad del av Användarvillkoren.
                            </p>
                            <p>Leverantör och Kund får inte ingå avtal avseende en Tjänst som exponerats på Gigital, om
                                de
                                inte omedelbart
                                därefter bekräftar detta till Gigital genom Applikationerna och fullföljer de åtaganden
                                som
                                följer av dessa
                                villkor.
                            </p>
                            <h3 class="h6 mt-3">Specifika villkor för Kunder</h3>
                            <p>Gigital äger rätten att överlåta kundfordringar till tredje part. Kundens
                                betalningsvillkor
                                eller övriga fakturaspecifika
                                åtaganden påverkas inte av eventuell överlåtelse av fakturafordran.
                            </p>

                            <h2 class="h5 mt-5">Betalning</h2>
                            <p>
                                <i>Allmänna bestämmelser</i>
                            </p>
                            <p>
                                Kunden äger rätten att välja kort- eller fakturabetalning för köp av Leverantörens
                                tjänster. Gigital kan erbjuda sina kunder
                                att betala med faktura då beställning görs via Applikationen. Om Kunden väljer att
                                betala
                                med bankkort sker
                                betalningen omgående.
                            </p>
                            <p>
                                I de fall Kunden har valt faktura som betalningssätt skickas en faktura i samband med
                                en
                                överenskommelse avseende Tjänsten
                                som bekräftas via Applikationen senast samma dag som Leverantörens framförande äger
                                rum.
                            </p>
                            <p>
                                Leverantören erhåller sin ersättning från Gigital efter att Tjänsten är utförd om inget
                                annat är överenskommet avseende betalningsvillkor
                                mellan Kund och Leverantör.
                            </p>
                            <p>
                                <i>
                                    Upphörande av avtal
                                </i>
                            </p>
                            <p>
                                Skulle Leverantören i något avseende inte fullgöra sina förpliktelser enligt detta
                                avtal
                                eller skulle annars omständigheter
                                befinnas föreligga som enligt Gigitals bedömande äventyrar detta avtal, äger Gigital
                                rätt
                                att omedelbart
                                häva det Ingångna Avtalet, sänka avtalad kreditlimit eller avsätta spärrmedel.
                            </p>
                            <p>
                                Vid det Ingångna Avtalets upphörande ska Gigital återbetala hela beloppet till Kunden.
                                Gigital ska inte ha någon skyldighet
                                att erlägga ersättning eller skadestånd för en felaktig utbetalning. Leverantören ska
                                hålla
                                Gigital skadelös
                                för eventuella anspråk, krav eller liknande från Kunden avseende betalningen (såsom
                                hanteringen, utbetalning
                                eller annat) från Leverantören eller annan, och Kunden ska hålla Gigital skadelös för
                                eventuella anspråk,
                                krav eller liknande från Leverantören avseende betalningen (hanteringen, utbetalningen
                                eller annat).
                            </p>
                            <p>
                                Användare blir befriade från eventuella skadestånd och konsekvenser endast vid fall av
                                händelser bortom deras kontroll enligt
                                sk Force Majeure.
                            </p>

                            <h2 class="h5 mt-5">Person- och företagsuppgifter</h2>
                            <p>
                                Gigital är personuppgiftsansvarig och är skyldigt att följa Personuppgiftslagen.
                                Kontaktvägar till oss finns längst ner i
                                detta dokument. Som behörig att företräda Leverantör ger du ditt samtycke till att dina
                                och
                                Leverantörens
                                eventuella företagsuppgifter som registrerats på Gigital publiceras på www.gigital.se.
                                Köpare och Leverantör
                                är vidare införstådd med att Köpare, Gigital och dess samarbetspartners kan komma att
                                kontakta Leverantör
                                via brev, telefon, e-post och SMS.
                            </p>

                            <h2 class="h5 mt-5">Cookies</h2>
                            <p>
                                Gigital använder cookies för att hålla information om enskilda användares aktiviteter
                                på
                                www.gigital.se. Informationen används
                                bland annat till:
                            </p>
                            <ul>
                                <li>att verifiera inloggning.</li>
                                <li>att automatiskt fylla i relevanta formulärfält för användaren vid återbesök till
                                    våra
                                    webbplatser.</li>
                                <li>att kontrollera och identifiera användaren vid aktivitet på våra webbplatser
                                    (”session
                                    cookies”).</li>
                            </ul>
                            <p>
                                Om du ej accepterar att cookies lagras på din dator kommer du inte att kunna använda
                                Gigitals tjänster. Genom att registrera
                                ett konto godkänner du Gigitals cookies och kan därmed börja använda tjänsten.
                            </p>

                            <h2 class="h5 mt-5">Ansvarsfrihet</h2>
                            <p>
                                Gigital kan inte garantera att uppgifterna på www.gigital.se är korrekta men
                                eftersträvar
                                och arbetar aktivt för att dessa
                                uppgifter skall var så korrekta som möjligt. Leverantör och Köpare äger inte rätt att
                                ställa ekonomiska krav
                                på Gigital i de fall det publicerats icke korrekt material på www.gigital.se. Gigital
                                bär
                                inget ansvar för
                                oförmåga att uppfylla sina förpliktelser i enlighet med detta avtal på grund av
                                omständigheter som Gigital
                                inte kan råda över eller inte har kunnat förutse, inklusive (men inte begränsat till)
                                blixtnedslag, översvämning,
                                brand, krig, arbetskonflikter, myndighetsbestämmelser, påbud eller andra offentliga
                                bestämmelser, överbelastning
                                av Internet, fel i andra nätverk, samt systemavbrott eller leveransvägran från Gigitals
                                Leverantörer.
                            </p>

                            <h2 class="h5 mt-5">Kontaktuppgifter Gigital</h2>
                            <p>Du kan kontakta oss genom att skicka ett meddelande till
                                <a href="mailto:info@gigital.com">info@gigital.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>