import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Billing, Booking, BookingStatusEnum, GetBillingInfosGQL } from '../../../generated/graphql';

@Component({
  selector: 'app-contract-billing-info',
  templateUrl: './contract-billing-info.component.html',
  styleUrls: ['./contract-billing-info.component.scss'],
})
export class ContractBillingInfoComponent implements OnInit {
  @Input()
  booking: Booking;

  @Input()
  editable = false;

  @Input()
  loading = false;

  @Output()
  selectBillingInfo = new EventEmitter<number>();

  billings: Observable<Billing[]>;
  bookingStatus = BookingStatusEnum;
  addMode = false;

  constructor(private getBillings: GetBillingInfosGQL, private modal: MatDialog) {}

  ngOnInit() {
    this.billings = this.getBillings.watch().valueChanges.pipe(
      map((d) => {
        return d.data.billingInfos;
      }),
    );
  }

  public addBillingInfo(): void {
    this.addMode = true;
  }

  selectBilling(id: number, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.selectBillingInfo.next(id);
  }
}
