import { animate, animation, style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Page } from '../../../../generated/graphql';

export const fadeIn = animation([
  style({ opacity: 0 }), // start state
  animate('{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)', style({ opacity: 1 })),
]);

export const fadeOut = animation([animate('{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)', style({ opacity: 0 }))]);

@Component({
  selector: 'app-quick-review-slider',
  templateUrl: './quick-review-slider.component.html',
  styleUrls: ['./quick-review-slider.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition('void => fade', [useAnimation(fadeIn, { params: { time: '500ms' } })]),
      transition('fade => void', [useAnimation(fadeOut, { params: { time: '500ms' } })]),
    ]),
  ],
})
export class QuickReviewSliderComponent implements OnInit {
  @Input() possibleReviews$: Observable<Page[]>;

  public displayedReviewIndex: number = 0;
  public isEditMode: boolean = false;
  public reviewsCount: number = 0;

  public ngOnInit(): void {
    this.possibleReviews$.pipe(tap((reviews) => (this.reviewsCount = reviews.length))).subscribe();
  }

  public onPreviousClick(): void {
    this.displayedReviewIndex =
      this.displayedReviewIndex > 0 ? this.displayedReviewIndex - 1 : this.displayedReviewIndex;
    this.isEditMode = false;
  }

  public onNextClick(): void {
    this.displayedReviewIndex =
      this.displayedReviewIndex < this.reviewsCount - 1 ? this.displayedReviewIndex + 1 : this.displayedReviewIndex;
    this.isEditMode = false;
  }

  public onModeChange(isEditMode: boolean): void {
    this.isEditMode = isEditMode;
  }
}
