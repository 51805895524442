import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Booking, UpdateBookingGQL } from '../../../../../generated/graphql';
import { IconsRegistryService, IconSubsets } from '../../../../services/icons-registry.service';

@Component({
  selector: 'app-booking-travel-allowance-modal',
  templateUrl: './booking-travel-allowance-modal.component.html',
  styleUrls: ['./booking-travel-allowance-modal.component.scss'],
})
export class BookingTravelAllowanceModalComponent implements OnInit {
  public closeDropdown$: EventEmitter<any> = new EventEmitter();

  public allowanceOptions: string[] = ['250', '500', '1000', '1500'];
  public selectedAllowanceValue: string = $localize`Select`;
  public isOptionSelected: boolean;
  public booking: Booking;

  constructor(
    public activeModal: MatDialogRef<BookingTravelAllowanceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { booking: Booking },
    private updateBookingQuery: UpdateBookingGQL,
    private iconsService: IconsRegistryService,
    private snackbar: MatSnackBar,
  ) {
    iconsService.registerIcons([IconSubsets.NAVIGATION]);
    this.booking = data.booking;
  }

  ngOnInit(): void {}

  public onOptionSelected(option: string): void {
    this.selectedAllowanceValue = option;
    this.isOptionSelected = true;
    this.closeDropdown$.emit(null);
  }

  public closeTravelAllowanceModal(): void {
    this.activeModal.close();
  }

  public updateBookingTravelAllowance(): void {
    this.updateBookingQuery
      .mutate({
        bookingId: this.data.booking.id,
        booking: { travelPrice: parseInt(this.selectedAllowanceValue) },
      })
      .subscribe(
        () => {
          this.snackbar.open($localize`Travel allowance was successfully updated!`, undefined, { duration: 3000 });
          this.closeTravelAllowanceModal();
        },
        (error) => {
          this.snackbar.open($localize`Could not save changes.`, $localize`Try again`, { duration: 3000 });
          console.error(error);
        },
      );
  }
}
