import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageFaqAnswer, PageFaqQuestionTemplate, UpdatePageFaqGQL } from '../../../../../../../generated/graphql';

interface ExtendedPageFaqQuestionTemplate extends PageFaqQuestionTemplate {
  isSelected?: boolean;
  answer: string;
}
@Component({
  selector: 'app-artist-faq-item',
  templateUrl: './artist-faq-item.component.html',
  styleUrls: ['./artist-faq-item.component.scss'],
  animations: [
    trigger('expansion', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('225ms ease-out', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [style({ opacity: 1 }), animate('225ms ease-in', style({ height: 0, opacity: 0 }))]),
    ]),
  ],
})
export class ArtistFaqItemComponent implements OnInit {
  @Output() saveFaqAnswers$: EventEmitter<PageFaqAnswer> = new EventEmitter();
  @Output() removeFaqAnswer$: EventEmitter<PageFaqAnswer> = new EventEmitter();

  @Input() questionTemplate: PageFaqQuestionTemplate;
  @Input() pageFaqAnswer: PageFaqAnswer;

  @Input() isAnswerExist: boolean;
  @Input() questionNumber: number;

  public isQuestionActive: boolean = false;

  public questionForm: FormGroup;
  public isEditMode: boolean = false;

  public constructor(public updatePageFaqGQL: UpdatePageFaqGQL) {}

  public ngOnInit(): void {
    this.isQuestionActive = this.pageFaqAnswer?.answer ? true : false;

    this.questionForm = new FormGroup({
      answer: new FormControl(this.pageFaqAnswer?.answer),
    });
  }

  public onToggleClick(): void {
    this.isEditMode = !this.isEditMode;

    if (!this.isQuestionActive) {
      this.removeFaqAnswer$.emit(this.pageFaqAnswer);
    }
  }

  public savePageAnswer(): void {
    this.pageFaqAnswer = {
      lang: this.pageFaqAnswer?.lang || this.questionTemplate.questions[0].lang,
      question: this.pageFaqAnswer?.question || this.questionTemplate.questions[0].question,
      answer: this.questionForm.value.answer,
    };

    this.saveFaqAnswers$.emit(this.pageFaqAnswer);
  }
}
