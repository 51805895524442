<div *ngIf="possibleReviews$ | async as reviews; else loading" class="slider-container">
  <app-slider-controls
    [disabledLeft]="displayedReviewIndex <= 0"
    [disabledRight]="displayedReviewIndex >= reviewsCount - 1"
    (onPreviousClick)="onPreviousClick()"
    (onNextClick)="onNextClick()">
  </app-slider-controls>

  <div class="sliders-wrap" [ngClass]="{ edit: isEditMode }">
    <ng-container *ngFor="let reviewPage of reviews; let i = index">
      <app-quick-review-card
        class="slide"
        [@slideAnimation]="'fade'"
        (onModeChanged)="onModeChange($event)"
        *ngIf="i === displayedReviewIndex"
        [reviewPage]="reviewPage"></app-quick-review-card>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <div fxLayout="row" fxLayoutAlign="center center" style="margin: 15%">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
