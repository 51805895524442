import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export enum IconSubsets {
  SOCIAL_MEDIA = 'assets/icons/social-media',
  NAVIGATION = 'assets/icons/navigation',
  EQUIPMENT = 'assets/icons/equipment',
  ACTIONS = 'assets/icons/actions',
  BOOKING = 'assets/icons/booking',
  BADGES = 'assets/icons/badges',
  FILLED = 'assets/icons/filled',
  ARTIST = 'assets/icons/artist',
  INPUTS = 'assets/icons/inputs',
  MEDIA = 'assets/icons/media',
  FLAGS = 'assets/icons/flags',
  FORMS = 'assets/icons/forms',
}

@Injectable({
  providedIn: 'root',
})
export class IconsRegistryService {
  private paths = {
    [IconSubsets.SOCIAL_MEDIA]: socialMediaIconsSubset,
    [IconSubsets.NAVIGATION]: navigationIconsSubset,
    [IconSubsets.EQUIPMENT]: equipmentIconsSubset,
    [IconSubsets.ACTIONS]: actionsIconsSubset,
    [IconSubsets.BOOKING]: bookingIconsSubset,
    [IconSubsets.BADGES]: badgesIconsSubset,
    [IconSubsets.FILLED]: filledIconsSubset,
    [IconSubsets.ARTIST]: artistIconsSubset,
    [IconSubsets.INPUTS]: inputsIconsSubset,
    [IconSubsets.MEDIA]: mediaIconsSubset,
    [IconSubsets.FLAGS]: flagsIconsSubset,
    [IconSubsets.FORMS]: formsIconsSubset,
  };

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  public registerIcons(iconsSubsets: IconSubsets[]): void {
    iconsSubsets.forEach((subset) => this.loadIcons(Object.values(this.paths[subset]), subset));
  }

  private loadIcons(iconKeys, iconUrl: string): void {
    iconKeys.forEach((key) => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}

const socialMediaIconsSubset = {
  soundCloud: 'soundcloud',
  instagram: 'instagram',
  instagramBlack: 'instagram-black',
  facebook: 'facebook',
  facebookBlack: 'facebook-black',
  mixcloud: 'mixcloud',
  mixcloudFrame: 'mixcloud-frame',
  spotify: 'spotify',
  spotifyFrame: 'spotify-frame',
  youtube: 'youtube',
  youtubeFrame: 'youtube-frame',
  tiktok: 'tiktok',
};

const navigationIconsSubset = {
  arrowRightLight: 'arrow-right-light',
  expandRightGray: 'expand-right-gray',
  expandLeft: 'expand-left',
  expandDown: 'expand-down',
  expandDownSmall: 'expand-down-small',
  expandDownBold: 'expand-down-bold',
  expandDownMedium: 'expand-down-medium',
  syncArrows: 'sync-arrows',
  close: 'close',
  closeWhite: 'close-white',
  sliderControlLeft: 'slider-control-left',
  sliderControlRight: 'slider-control-right',
  backArrow: 'back-arrow',
  expandDownPink: 'expand-down-pink',
};

const equipmentIconsSubset = {
  paSystem: 'pa-system',
  speakers: 'speakers',
  mixer: 'mixer',
  none: 'none',
};

const actionsIconsSubset = {
  checkedRingGreen: 'checked-ring-green',
  checkRingLight: 'check-ring-light',
  shieldCheck: 'shield-check',
  shieldMinus: 'shield-minus',
  compass: 'compass',
  compassThick: 'compass-thick',
  copyLight: 'copy-light',
  contract: 'contract',
  calendar: 'calendar',
  filter: 'filter',
  artist: 'artist',
  clock: 'clock',
  clockGray2: 'clock-gray-2',
  send: 'send',
  star: 'star',
  thumbDown: 'thumb-down',
  thumbUp: 'thumb-up',
  loupe: 'loupe',
  requestLight: 'request-light',
  infoFill: 'info-fill',
  playVideo: 'play-video',
  trend: 'trend',
  sorting: 'sorting',
  notification: 'notification',
  party: 'party',
  bell: 'bell',
  addRing: 'add-ring',
  notificationRoundBlue: 'notification-round-blue',
  importWhite: 'import-white',
  closeCircleGray: 'close-circle-gray',
  doneGreen: 'done-green',
  doneGray: 'done-gray',
  infoMessage: 'info-message',
  warning: 'warning',
  eye: 'eye',
  questionFill: 'question-fill',
  trashBin: 'trash-bin',
  trashBin2: 'trash-bin-2',
  note: 'note',
  hourglass: 'hourglass',
  edit: 'edit',
};

const badgesIconsSubset = {
  ownEquipment: 'own-equipment',
  ownTravel: 'own-travel',
  topArtist: 'top-artist',
  customerPick: 'customer-pick',
  lightYellow: 'light-yellow',
};

const filledIconsSubset = {
  microphoneFillGray: 'microphone-fill-gray',
  calendarFillGray: 'calendar-fill-gray',
  budgetFillGray: 'budget-fill-gray',
  userFillGray: 'user-fill-gray',
};

const artistIconsSubset = {
  verification: 'verification',
  location: 'location',
  price: 'price',
  note: 'note',
};

const inputsIconsSubset = {
  checkbox: 'checkbox',
  upload: 'upload',
};

const mediaIconsSubset = {
  video: 'video',
  videoBrightRed: 'video-bright-red',
  audio: 'audio',
  audioBrightRed: 'audio-bright-red',
  photo: 'photo',
  photoBrightRed: 'photo-bright-red',
  file: 'file',
};

const flagsIconsSubset = {
  sweden: 'sweden',
  norway: 'norway',
  denmark: 'denmark',
  finland: 'finland',
};

const formsIconsSubset = {
  required: 'required',
  location: 'location',
  attachFile: 'attach-file',
  sendMessage: 'send-message',
};

const bookingIconsSubset = {
  datesCalendar: 'dates-calendar',
  equipmentRequest: 'equipment-request',
  technicalEquipment: 'technical-equipment',
  travelAllowance: 'travel-allowance',
  request: 'request',
  statusOpen: 'status-open',
};
