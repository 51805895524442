import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CropperPosition, ImageCroppedEvent } from 'ngx-image-cropper';
import { Image } from '../../../../generated/graphql';
import { dataURLtoFile } from '../../../helpers';

export interface ImageSelectedEvent {
  image: File;
  cropData: CropperPosition;
  preview: string; // b64 img
  isNew: boolean;
}

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  loading = false;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  image: File;
  imageBase64: string | ArrayBuffer;
  crop: CropperPosition;

  resImage: Image;
  cropImage: HTMLImageElement;
  scaleRatio: number;
  displayImages = false;
  hasError = false;
  isNewImage = true;

  @Input()
  currentImage: Image;

  @Output()
  imageSelected = new EventEmitter<ImageSelectedEvent>();

  @ViewChild('select') select: ElementRef;
  @ViewChild('cropper') cropper: ImageCropperComponent;

  ngOnInit(): void {
    if (this.currentImage) {
      this.loading = true;
      this.setCropperImage(`https://res.cloudinary.com/hthruuqgk/image/upload/${this.currentImage.fileName}`);
      this.isNewImage = false;
    }
  }

  async setCropperImage(url: string) {
    const result: any = await fetch(url);
    const blob = await result.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      this.image = dataURLtoFile(reader.result.toString(), 'fileName');
      this.cropImage = new Image();
      this.cropImage.src = URL.createObjectURL(this.image);
    };
  }

  openSelect() {
    (this.select.nativeElement as HTMLInputElement).click();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.image = event.target.files[0];
    this.cropImage = new Image();
    this.cropImage.src = URL.createObjectURL(this.image);
    this.isNewImage = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.crop = event.cropperPosition;

    // HACK: Access private props of the imagecropper component
    this.scaleRatio =
      this.cropImage.width /
      (((this.cropper as any).sourceImage as ElementRef).nativeElement as HTMLImageElement).width;

    this.upload();
  }

  imageLoaded(e) {
    // show cropper
    this.hasError = false;
  }
  cropperReady() {
    this.loading = false;

    if (this.currentImage) {
      this.scaleRatio =
        this.cropImage.width /
        (((this.cropper as any).sourceImage as ElementRef).nativeElement as HTMLImageElement).width;

      const minScale = Math.max(this.scaleRatio, 1);

      (this.cropper.cropper as any).x1 = this.currentImage.cropData.x / minScale;
      (this.cropper.cropper as any).x2 = (this.currentImage.cropData.x + this.currentImage.cropData.w) / minScale;
      (this.cropper.cropper as any).y1 = this.currentImage.cropData.y / minScale;
      (this.cropper.cropper as any).y2 = (this.currentImage.cropData.y + this.currentImage.cropData.h) / minScale;
    }

    if (this.isNewImage) {
      (this.cropper as any).resetCropperPosition();
    }
  }
  loadImageFailed(e) {
    console.log(e);
    // show message
    this.hasError = true;
  }
  upload() {
    // Since the cropper works on the visual scale,
    // a scaled down image will not have it's cropper positions at the same place the original would

    const cropData: CropperPosition = {
      x1: this.crop.x1 * this.scaleRatio,
      x2: this.crop.x2 * this.scaleRatio,
      y1: this.crop.y1 * this.scaleRatio,
      y2: this.crop.y2 * this.scaleRatio,
    };
    this.imageSelected.next({
      cropData,
      image: this.isNewImage ? this.image : null,
      preview: this.croppedImage,
      isNew: this.isNewImage,
    });
  }
}
