<div fxLayoutAlign="start center" class="search-input-wrap" [ngClass]="{ import: isImportStyles }">
  <button [disabled]="!searchValue || disabled" (click)="onSearchClick()" class="search-button">
    <mat-icon svgIcon="loupe"></mat-icon>
  </button>
  <input
    fxFlex="grow"
    type="text"
    [(ngModel)]="searchValue"
    [placeholder]="placeholder"
    [disabled]="disabled"
    (input)="inputChanges($event)" />
</div>
