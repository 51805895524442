import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GigsService } from '../../../../services/gigs.service';

interface SortOption {
  sort: string;
  displayName: string;
}

@Component({
  selector: 'app-sort-dropdown',
  templateUrl: './sort-dropdown.component.html',
  styleUrls: ['./sort-dropdown.component.scss'],
})
export class SortDropdownComponent implements OnInit {
  options: SortOption[] = [
    { sort: 'RELEVANCY:DESC', displayName: $localize`Most relevant` },
    { sort: 'ADDED_SLOT:DESC', displayName: $localize`Latest added slot` },
    { sort: 'PRICE:DESC', displayName: $localize`Price - high to low` },
    { sort: 'PRICE:ASC', displayName: $localize`Price - low to high` },
  ];
  selectedSort: SortOption = this.options[0];

  @Output() sortChanges = new EventEmitter<string>();

  constructor(private gigService: GigsService) {}

  ngOnInit(): void {
    if (this.gigService.urlFilter.sorting) {
      const existingSort = this.options.find((s) => s.sort === this.gigService.urlFilter.sorting);
      if (existingSort) {
        this.selectValue(existingSort);
      }
      this.gigService.filterHasUpdated.sorting = true;
    }
  }

  selectValue(sort: SortOption) {
    this.selectedSort = sort;

    this.sortChanges.emit(this.selectedSort.sort);
  }
}
