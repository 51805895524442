import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { YouTubePlayer } from '@angular/youtube-player';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ContentfulArtistItem, Tag } from '../../../generated/graphql';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-artist-hero',
  templateUrl: './artist-hero.component.html',
  styleUrls: ['./artist-hero.component.scss'],
})
export class ArtistHeroComponent implements OnInit {
  @Input()
  artist: ContentfulArtistItem;

  public isLoggedIn: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
  ) {}

  youtubeApiLoaded = false;
  videoHeight;
  videoWidth;
  showVideo = false;
  videoId;

  @ViewChild('ytVideo', { static: true })
  ytVideo: YouTubePlayer;

  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;
  searchInputSub;

  locationTag: Tag;

  isHandset: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn;
    if (isPlatformBrowser(this.platformId) && !this.youtubeApiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.youtubeApiLoaded = true;

      const heroEl: any = document.querySelector('.artist-list--hero');
      this.videoHeight = heroEl.offsetHeight + 150;
      this.videoWidth = heroEl.offsetWidth + 260;
    }

    this.locationTag = this.artist.page.tags.find((t) => t.group === 6);

    this.searchInputSub = fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        map((evt: any) => evt.target.value),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((text: string) =>
        this.router.navigate(['artists/search'], {
          queryParams: { textQuery: text, artistType: 'Artist,DJ,Band', sorting: 'relevancy:desc', limit: '15' },
        }),
      );

    this.videoId = this.getVideoId();

    this.isHandset.subscribe((_isHandset) => {
      console.log(_isHandset);
      if (!_isHandset) {
        this.ytVideo.ready.subscribe((e) => {
          e.target.mute();
          e.target.playVideo();
          setTimeout(() => {
            this.showVideo = true;
          }, 2000);

          // Hide video 5 seconds before end
          const videoDuration = e.target.getDuration();
          setTimeout(() => {
            this.showVideo = false;
          }, (videoDuration - 5) * 1000);
        });
      }
    });
  }

  getVideoId(): string {
    const video = this.artist.page.links.find((l) => l.socialMediaId === 6 && l.isHighlight);
    if (video?.url.includes('youtu.be')) {
      return video.url.split('be/')[1];
    }

    if (video?.url.includes('youtube')) {
      if (!video.url.includes('v=')) {
        return;
      }
      return video.url.split('v=')[1].split('&')[0];
    }
  }

  getArtistType(): Tag {
    return this.artist.page.tags.find((t) => t.group === 4);
  }
  getArtistLocation(): Tag {
    return this.artist.page.tags.find((t) => t.group === 6);
  }

  switchToSearch() {
    console.log('switch to search');
  }
}
