import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookingDates, BookingFieldsFragmentDoc, CancelApplicationGQL } from '../../../../generated/graphql';
import { ApplicationStatus, getApplicationStatus } from '../../../helpers/applications';
import { MEDIUM_DATE } from '../../../helpers/dates';
registerLocaleData(localeFr);

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss'],
})
export class ApplicationCardComponent implements OnDestroy {
  @Input() date: BookingDates;

  private unsubscribeAll: Subject<any> = new Subject<any>();

  public loading = false;
  public dateFormat = MEDIUM_DATE;
  public APPLICATION_STATUS = ApplicationStatus;

  public get appStatus(): string {
    return getApplicationStatus(this.date.booking);
  }

  constructor(private cancelApplication: CancelApplicationGQL, private snackbar: MatSnackBar) {}

  public clickCancelApplication(): void {
    this.loading = true;
    const oldApp = JSON.parse(JSON.stringify(this.date.booking));
    this.cancelApplication
      .mutate(
        { applicationId: this.date.booking.id, dateId: this.date.id },
        {
          update: (store, { data }) => {
            store.writeFragment({
              fragment: BookingFieldsFragmentDoc,
              id: `Booking:${this.date.booking.id.toString()}`,
              fragmentName: 'bookingFields',
              data: { ...oldApp, status: data.cancelApplication.status },
            });
          },
        },
      )
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        this.loading = false;
        this.snackbar.open($localize`Cancelled application`, undefined, {
          duration: 3000,
        });
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
