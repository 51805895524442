<div *ngIf="!isFullScreen" fxLayoutGap="20px" class="blog-post-list" [class.no-padding]="noPadding">
  <a
    *ngFor="let post of blog.posts"
    fxFlex="320px"
    fxLayout="column"
    class="blog-post"
    [href]="post.url"
    target="_blank"
    rel="noopener">
    <img fxFlex="251px" [src]="post.feature_image" />
    <div fxFlex="grow" class="blog-post-text">
      <h3 class="h6 gray-1 m-b--10">{{ post.title }}</h3>
      <p class="gray-2 description">{{ post.meta_description }}</p>
    </div>
  </a>
</div>

<div
  fxLayout="column"
  *ngIf="isFullScreen && blog.posts"
  class="blog-post-list full-screen"
  [class.no-padding]="noPadding">
  <app-slider-controls
    [disabledLeft]="displayedPostIndex <= 0"
    [disabledRight]="displayedPostIndex >= blog.posts.length - 1"
    (onPreviousClick)="onPreviousClick()"
    (onNextClick)="onNextClick()">
  </app-slider-controls>

  <ng-container *ngFor="let post of blog.posts; let i = index">
    <a
      fxLayoutAlign="start flex-end"
      *ngIf="i === displayedPostIndex"
      class="blog-post"
      [href]="post.url"
      target="_blank"
      rel="noopener"
      [style.background-image]="getSafeCssUrl(post.feature_image)"
      [ngClass]="{ 'full-screen': isFullScreen }">
      <div class="blog-post-text">
        <h3 class="h6 white m-b--10">{{ post.title }}</h3>
        <p class="white description">{{ post.meta_description }}</p>
      </div>
    </a></ng-container
  >
</div>
