import { Component, Input, OnInit } from '@angular/core';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

export enum InfoBarType {
  InfoMessage = 'info-message',
  Warning = 'warning',
}
@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
})
export class InfoBarComponent implements OnInit {
  @Input() infoType: InfoBarType;
  @Input() text: string;

  public INFO_BAR_TYPE = InfoBarType;

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  ngOnInit(): void {}
}
