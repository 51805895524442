<div class="media-container">
  <mat-tab-group
    *ngIf="videos?.length > 0 || audio?.length > 0 || gallery?.length > 0; else noMedia"
    headerPosition="below"
    (selectedTabChange)="onTabSelected($event)">
    <mat-tab *ngIf="videos?.length > 0" aria-label="video">
      <ng-template mat-tab-label>
        <span class="tab-label" fxLayoutAlign="start center" fxLayoutGap="8px">
          <mat-icon
            fxLayoutAlign="center center"
            class="tab-label--icon video"
            [svgIcon]="selectedTabLabel === MEDIA_TABS.VIDEO ? 'video-bright-red' : 'video'"></mat-icon>
          <span i18n>Video</span>&nbsp;({{ videos.length }})
        </span>
      </ng-template>

      <div class="gallery-container video">
        <div *ngIf="videos?.length > 1" class="slider-controls" fxFlex="100%" fxLayoutAlign="space-between center">
          <button (click)="onPreviousSlideClick()">
            <mat-icon
              fxLayoutAlign="center center"
              svgIcon="slider-control-left"
              class="slider-controls--item"></mat-icon>
          </button>

          <button (click)="onNextSlideClick(videos.length)">
            <mat-icon
              fxLayoutAlign="center center"
              svgIcon="slider-control-right"
              class="slider-controls--item"></mat-icon>
          </button>
        </div>

        <ng-container *ngFor="let video of videos; index as i">
          <div *ngIf="selectedSlideIndex === i" fxFlex="100%">
            <div class="video-16-9">
              <div class="video-16-9-loading" fxLayout fxLayoutAlign="center center">
                <mat-spinner [diameter]="64"></mat-spinner>
              </div>
              <app-media-iframe [srcLink]="video"></app-media-iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-tab>

    <mat-tab *ngIf="audio?.length > 0" aria-label="audio">
      <ng-template mat-tab-label>
        <span class="tab-label" fxLayoutAlign="start center" fxLayoutGap="9px">
          <mat-icon
            fxLayoutAlign="center center"
            class="tab-label--icon audio"
            [svgIcon]="selectedTabLabel === MEDIA_TABS.AUDIO ? 'audio-bright-red' : 'audio'"></mat-icon>
          <span i18n>Audio</span>&nbsp;({{ audio.length }})
        </span>
      </ng-template>

      <div class="gallery-container audio">
        <div *ngIf="audio?.length > 1" class="slider-controls" fxFlex="100%" fxLayoutAlign="space-between center">
          <button (click)="onPreviousSlideClick()">
            <mat-icon
              fxLayoutAlign="center center"
              svgIcon="slider-control-left"
              class="slider-controls--item"></mat-icon>
          </button>

          <button (click)="onNextSlideClick(audio.length)">
            <mat-icon
              fxLayoutAlign="center center"
              svgIcon="slider-control-right"
              class="slider-controls--item"></mat-icon>
          </button>
        </div>

        <ng-container *ngFor="let audioItem of audio; index as i">
          <div *ngIf="selectedSlideIndex === i" fxFlex="100%" class="iframe-container">
            <app-media-iframe
              [srcLink]="audioItem"
              [isArtist]="audioItem.isArtist"
              [iframeNgClass]="'id-' + audioItem.socialMediaId.toString()">
            </app-media-iframe>
          </div>
        </ng-container>
      </div>
    </mat-tab>

    <mat-tab *ngIf="gallery?.length > 0" aria-label="photo">
      <ng-template mat-tab-label>
        <span class="tab-label" fxLayoutAlign="start center" fxLayoutGap="9px">
          <mat-icon
            fxLayoutAlign="center center"
            class="tab-label--icon photo"
            [svgIcon]="selectedTabLabel === MEDIA_TABS.PHOTO ? 'photo-bright-red' : 'photo'"></mat-icon>
          <span i18n>Photo</span>&nbsp;({{ gallery.length }})
        </span>
      </ng-template>

      <div class="gallery-container photo">
        <div *ngIf="gallery.length > 1" class="slider-controls" fxFlex="100%" fxLayoutAlign="space-between center">
          <button (click)="onPreviousSlideClick()">
            <mat-icon
              fxLayoutAlign="center center"
              svgIcon="slider-control-left"
              class="slider-controls--item"></mat-icon>
          </button>

          <button (click)="onNextSlideClick(gallery.length)">
            <mat-icon
              fxLayoutAlign="center center"
              svgIcon="slider-control-right"
              class="slider-controls--item"></mat-icon>
          </button>
        </div>

        <ng-container *ngFor="let image of gallery; index as i">
          <div *ngIf="selectedSlideIndex === i" fxFlex="100%" class="">
            <app-cloud-image [cover]="true" [profilePicture]="image" i18n-altText></app-cloud-image>
          </div>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #noMedia>
  <mat-tab-group headerPosition="below">
    <mat-tab disabled="true">
      <ng-template mat-tab-label>
        <span fxLayoutAlign="start center" fxLayoutGap="8px" class="tab-label gray-15">
          <mat-icon fxLayoutAlign="center center" svgIcon="video" class="tab-label--icon video"></mat-icon>
          <span i18n>Video</span>&nbsp;({{ videos?.length }})
        </span>
      </ng-template>

      <div fxLayout="column" class="gallery-container video">
        <div
          fxFlex="505"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="6px"
          class="no-media-container bg-gray-6">
          <mat-icon fxLayoutAlign="center center" svgIcon="file" class="tab-label--icon file"></mat-icon>
          <p class="f-size--14 gray-13" i18n>No media yet</p>
        </div>
      </div>
    </mat-tab>

    <mat-tab disabled="true">
      <ng-template mat-tab-label>
        <span class="tab-label" fxLayoutAlign="start center" fxLayoutGap="9px">
          <mat-icon fxLayoutAlign="center center" svgIcon="audio" class="tab-label--icon audio"></mat-icon>
          <span i18n>Audio</span>&nbsp;({{ audio?.length }})
        </span>
      </ng-template>

      <div class="gallery-container audio"> </div>
    </mat-tab>

    <mat-tab disabled="true">
      <ng-template mat-tab-label>
        <span class="tab-label" fxLayoutAlign="start center" fxLayoutGap="9px">
          <mat-icon fxLayoutAlign="center center" svgIcon="photo" class="tab-label--icon photo"></mat-icon>
          <span i18n>Photo</span>&nbsp;({{ photo?.length || 0 }})
        </span>
      </ng-template>

      <div class="gallery-container photo">
        <div class="slider-controls" fxFlex="100%" fxLayoutAlign="space-between center"> </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-template>
