
<div [style.height.px]="size" [style.width.px]="size">
  <mat-icon
    *ngIf="icon"
    [style.left.px]="icon === 'music' ? -4 : 0"
    [ngStyle]="
      iconSize && { fontSize: iconSize + 'px', height: iconSize + 'px', width: iconSize + iconSize / 5 + 'px' }
    "
    >{{ icon }}</mat-icon
  >

  <!-- Plectrum 1 -->
  <svg
    [attr.height.px]="size"
    [attr.width.px]="size"
    [ngClass]="'fill-' + background"
    *ngIf="plectrumId === 1"
    viewBox="0 0 417.96 442.65"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M424.93,119.36c28.81,55,32.44,111.08,23.59,170.3S418.32,411,375.85,432.86s-106.28,3.74-171.91-23.05S70.77,347.6,45.91,288.38,39.18,146,86.67,86.81,211.2-7.73,276.72,1.44,396.12,64.4,424.93,119.36Z"
      transform="translate(-34.82)"
    ></path>
  </svg>

  <!-- Plectrum 2 -->
  <svg
    [attr.height.px]="size"
    [attr.width.px]="size"
    [ngClass]="'fill-' + background"
    *ngIf="plectrumId === 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 487.59 442.59"
  >
    <path
      d="M455.65,33.8c41.74,41.27,37.54,130.36,16,211.73S411.23,400.45,351.25,428.62s-141,11-206.12-25.6S31,310.42,10.56,242-1.37,92.49,41.42,51,161.26,7.49,243.69,3.16,413.8-7.47,455.65,33.8Z"
      transform="translate(0 -0.06)"
    />
  </svg>

  <!-- Plectrum 3 -->
  <svg
    [attr.height.px]="size"
    [attr.width.px]="size"
    [ngClass]="'fill-' + background"
    *ngIf="plectrumId === 3"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 483.44 442.65"
  >
    <path
      d="M459.22,92.68c30.33,51,28.39,113.56,23.41,171.19S469.53,374,439.2,404.6s-82.87,38.95-134.55,38-102.16-11.41-162.7-42-130.78-81.17-139-140S48.41,134.41,108.83,83.46,236.46-2.2,301.37.11,428.89,41.72,459.22,92.68Z"
      transform="translate(-2.07)"
    />
  </svg>

  <!-- Plectrum 4 -->
  <svg
    [attr.height.px]="size"
    [attr.width.px]="size"
    [ngClass]="'fill-' + background"
    *ngIf="plectrumId === 4"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 487.44 403.23"
  >
    <path
      d="M406.26,95.93c54.62,49.56,96,128.45,76.24,184.68s-100.85,89.69-173.17,114-135.8,39.55-184,18.17S44.16,333.39,21.63,264.62s-34.39-148.45-.12-193.41S136,15.9,210,20.85,351.76,46.26,406.26,95.93Z"
      transform="translate(-0.07 -19.71)"
    />
  </svg>

  <!-- Plectrum 5 -->
  <svg
    [attr.height.px]="size"
    [attr.width.px]="size"
    [ngClass]="'fill-' + background"
    *ngIf="plectrumId === 5"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 479.71 442.65"
  >
    <path
      d="M389.16,112.75c60.9,67.43,110.67,152.63,89.56,212S365.24,417.89,290.27,433.24,157.59,445.39,111.15,422,29.66,354.43,13.54,301.21.36,185.29,32,124.65,123.82,4.9,190.09.3,328.26,45.33,389.16,112.75Z"
      transform="translate(-3.94 0)"
    />
  </svg>

  <!-- Plectrum 6 -->
  <svg
    [attr.height.px]="size"
    [attr.width.px]="size"
    [ngClass]="'fill-' + background"
    *ngIf="plectrumId === 6"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 487.59 431.96"
  >
    <path
      d="M439.38,46.16C486.83,94,499.44,190,476.4,267.69S394.92,404.76,332.91,425.91s-127.44,4.15-190.13-26.31S20,325.34,4.34,265.8s13-134.55,57.53-181.63S166.74,18.22,238,8.53,391.94-1.54,439.38,46.16Z"
      transform="translate(0 -3.35)"
    />
  </svg>

  <!-- Plectrum 7 -->
  <svg
    [attr.height.px]="size"
    [attr.width.px]="size"
    [ngClass]="'fill-' + background"
    *ngIf="plectrumId === 7"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 478.69 442.65"
  >
    <path
      d="M461.6,49c33,39.13,23.48,114.46,2.08,178.53S409,344.41,352.41,386,215.83,457.52,150,434.9,32.41,336.71,13.09,253.44,6.61,78.83,56,36,191.49-1.38,272.32,2.77,428.59,9.87,461.6,49Z"
      transform="translate(-4.45)"
    />
  </svg>

  <!-- Plectrum 8 - Pride -->
  <svg
    [attr.height.px]="size"
    [attr.width.px]="size"
    class="fill-rainbow"
    *ngIf="plectrumId === 8"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 478.69 442.65"
  >
    <defs>
      <linearGradient id="prideGradient" gradientTransform="rotate(15)">
        <stop offset="0%" stop-color="#ff3f34" />
        <stop offset="24%" stop-color="#ff3f34" />
        <stop offset="25%" stop-color="#ffa801" />
        <stop offset="37%" stop-color="#ffa801" />
        <stop offset="38%" stop-color="#ffd32a" />
        <stop offset="50%" stop-color="#ffd32a" />
        <stop offset="51%" stop-color="#05c46b" />
        <stop offset="63%" stop-color="#05c46b" />
        <stop offset="64%" stop-color="#00d8d6" />
        <stop offset="76%" stop-color="#00d8d6" />
        <stop offset="77%" stop-color="#0fbcf9" />
        <stop offset="89%" stop-color="#0fbcf9" />
        <stop offset="90%" stop-color="#3c40c6" />
        <stop offset="102%" stop-color="#3c40c6" />
      </linearGradient>
    </defs>
    <path
      d="M461.6,49c33,39.13,23.48,114.46,2.08,178.53S409,344.41,352.41,386,215.83,457.52,150,434.9,32.41,336.71,13.09,253.44,6.61,78.83,56,36,191.49-1.38,272.32,2.77,428.59,9.87,461.6,49Z"
      transform="translate(-4.45)"
    />
  </svg>
</div>
