<h1 mat-dialog-title i18n class="h5 gray-1">Attached files</h1>
<mat-dialog-content>
  <div class="scroll">
    <mat-list>
      <mat-list-item *ngFor="let message of messages">
        <span fxLayoutAlign="flex-start center" fxLayoutGap="5px">
          <mat-icon color="primary">insert_drive_file</mat-icon>
          <a
            class="file-link"
            target="blank"
            download="{{ getFileDownloadName(message.file) }}"
            [href]="getDownloadUrl(message.file)">
            {{ message.file.fileName }}
          </a>
        </span>
      </mat-list-item>
    </mat-list>
  </div>
  <div fxLayoutAlign="end center">
    <button mat-button (click)="clickCancel()" i18n>Cancel</button>
  </div>
</mat-dialog-content>
