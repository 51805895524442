import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Image, PageLink } from '../../../../../../../generated/graphql';

export interface ExtendedImage extends Image {
  delete: boolean;
}
@Component({
  selector: 'app-artist-edit-media-gallery',
  templateUrl: './artist-edit-media-gallery.component.html',
  styleUrls: ['./artist-edit-media-gallery.component.scss'],
})
export class ArtistEditMediaGalleryComponent {
  @Output() onMediaChanged$: EventEmitter<PageLink[]> = new EventEmitter();
  @Input() gallery: ExtendedImage[];

  public dropImageTileId: number;
  public dragImageId: number;

  public constructor(private sanitizer: DomSanitizer) {}

  public getImageURL(image: ExtendedImage) {
    return this.sanitizer.bypassSecurityTrustUrl(
      `url(https://res.cloudinary.com/hthruuqgk/image/upload/c_limit,w_1200/f_auto,fl_progressive,q_auto/c_scale,dpr_1.0/v1/${image.fileName})`,
    );
  }

  public dragStartHandler(id: number): void {
    this.dragImageId = id;
  }

  public dragEndHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'visible';
  }

  public ghostCreateHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'hidden';
  }

  public onImageDropped(ev) {
    ev.drag.dropFinished();
  }

  public onEnterHandler(ev): void {
    this.dropImageTileId = parseInt(ev.owner.element.nativeElement.id, 10);

    if (this.dragImageId === this.dropImageTileId) return;

    const dragIndex = this.gallery.findIndex((image) => image.id === this.dragImageId);
    const dropIndex = this.gallery.findIndex((image) => image.id === this.dropImageTileId);

    this.swapImage(dragIndex, dropIndex);

    this.gallery.find((image) => image.id === this.dragImageId).order = dropIndex;

    if (dragIndex < dropIndex) {
      for (let i = dragIndex; i < dropIndex; i++) {
        this.gallery[i].order--;
      }
    } else {
      for (let i = dropIndex + 1; i <= dragIndex; i++) {
        this.gallery[i].order++;
      }
    }

    this.onMediaChanged$.emit(this.gallery);
  }

  private swapImage(dragIndex: number, dropIndex: number) {
    const tempObj = this.gallery[dragIndex];
    this.gallery.splice(dragIndex, 1);
    this.gallery.splice(dropIndex, 0, tempObj);
  }

  public deleteImage(imageId: number): void {
    this.gallery.forEach((image, index) => {
      if (image.id === imageId) this.gallery[index].delete = true;
    });

    this.onMediaChanged$.emit(this.gallery);
  }

  public dropImageOnMobile(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.gallery, event.previousIndex, event.currentIndex);
  }
}
