<div *ngIf="page" fxLayout="column" fxLayoutAlign="space-between center" class="page-card">
  <app-avatar
    *ngIf="page.profilePicture"
    [profilePicture]="page.profilePicture"
    [huge]="true"
    class="m-b--25"></app-avatar>

  <div class="default-avatar m-b--25" *ngIf="!page.profilePicture">
    <img src="assets/images/default-avatar.svg" />
  </div>

  <div fxFlex="grow" fxLayout="column" fxLayoutAlign="start center">
    <span class="page-name m-b--15">{{ page.name }}</span>

    <div class="page-status m-b--20">
      <span *ngIf="page.reviewStatus === ReviewStatusEnum.Approved" fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-icon svgIcon="shield-check"></mat-icon>
        <span i18n>Profile approved</span>
      </span>

      <span *ngIf="page.reviewStatus === ReviewStatusEnum.New" fxLayoutAlign="center start" fxLayoutGap="5px">
        <mat-icon svgIcon="shield-minus"></mat-icon>
        <span fxLayout="column">
          <span i18n>Not finished</span>
          <span class="page-progress">
            {{ artistProgress.stepsCompleted }} <span i18n>out of</span> {{ artistProgress.stepsTotal }}
            <span i18n>steps completed</span>
          </span>
        </span>
      </span>

      <span
        *ngIf="page.reviewStatus === ReviewStatusEnum.Denied || page.reviewStatus === ReviewStatusEnum.Pending"
        fxLayoutAlign="center start"
        fxLayoutGap="5px">
        <mat-icon svgIcon="clock"></mat-icon>
        <span fxLayout="column">
          <span i18n>Pending</span>
          <span *ngIf="page.reviewStatus === ReviewStatusEnum.Denied" class="page-progress">
            See step {{ deniedSteps }}
          </span>
        </span>
      </span>
    </div>
  </div>

  <!-- <div class="page-recommend m-b--35">
    <span fxLayoutAlign="center center" fxLayoutGap="5px">
      <mat-icon svgIcon="star" class="star-icon"></mat-icon>
      <span>{{ page.positiveReviews }} <span i18n>Recommendations</span></span>
    </span>
  </div> -->

  <button
    *ngIf="
      page.reviewStatus === ReviewStatusEnum.Approved ||
      page.reviewStatus === ReviewStatusEnum.Pending ||
      page.reviewStatus === ReviewStatusEnum.Denied
    "
    class="gig-button stroked w--100per"
    [routerLink]="['artists', page.slug]"
    i18n>
    View profile
  </button>

  <button
    *ngIf="page.reviewStatus === ReviewStatusEnum.New"
    [routerLink]="['artists', page.slug, 'edit']"
    class="gig-button primary w--100per"
    i18n>
    Finish profile
  </button>
</div>
