import { Component, OnInit } from '@angular/core';
import { Intercom } from 'ng-intercom';
import { GetContentfulEntryGQL } from '../../../generated/graphql';
import { richTextToHtml } from '../../helpers';
import { MetaService } from '../../services/meta.service';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  public infoBlocks: any[] = [];
  public trustedByData: any;
  public bannerData: any;
  public headerData: any;
  public faqData: any;

  public constructor(
    private getContentfulEntry: GetContentfulEntryGQL,
    private translations: TranslationsService,
    private metaService: MetaService,
    private intercom: Intercom,
  ) {}

  public ngOnInit() {
    this.getContentfulEntry
      .fetch({ name: 'Pricing-2.0', locale: this.translations.getCurrentLocale() })
      .subscribe((result) => {
        // this.isLoading = false;

        if (result.data.getContentfulEntry.contentfulData.fields) {
          const page = result.data.getContentfulEntry.contentfulData.fields;
          this.metaService.setContentfulSeoData(page);

          this.headerData = page.blocks.find((b) => b.sys.contentType.sys.id === 'landingHeader');
          this.faqData = page.blocks.find((b) => b.sys.id === '5FDtuDD27K1EQBTWZkH8ek');
          this.bannerData = page.blocks.find((b) => b.sys.id === '7BgZXOne4GkhTf0dFh8KtW');
          this.trustedByData = page.blocks.find((b) => b.sys.id === '6pA1B4xSfyB0ntdyTk4Jzn');

          const infoBlocks = page.blocks.filter((e) => e.sys.contentType.sys.id === 'infoBlock');
          infoBlocks.forEach((b) => {
            if (b.sys.id !== '1Zf3h1aj0JGdrCdsLgaiir') {
              this.infoBlocks.push(b);
            }
          });
        } else {
          // TODO: Handle content api errors?
          throw new Error('Couldnt fetch contentful content.');
        }
      });
  }

  public richTextToHtml(text): any {
    return richTextToHtml(text);
  }

  public openIntercom() {
    if ((window as any).Intercom.booted) {
      this.intercom.showNewMessage();
    }
  }
}
