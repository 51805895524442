<div
  fxLayout="column"
  class="slot-container"
  [ngClass]="{
    selectable: canSelect,
    selected: isSelected,
    loading: loading,
    'mat-elevation-z5': isSelected
  }">
  <mat-spinner [diameter]="32" *ngIf="loading" class="loading-spinner"></mat-spinner>
  <!-- DESKTOP -->
  <div class="header" fxHide.lt-md fxLayout="row">
    <div fxFlex fxLayoutAlign="start center">
      <div fxLayoutAlign="start center" fxLayoutGap="12px" class="m-r--75">
        <div class="date" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <div fxLayout="column" fxLayoutAlign="start center" class="slot-date">
            <span>{{ dateDetails.weekDay }}</span>
            <span class="day">{{ dateDetails.day }}</span>
            <span
              >{{ dateDetails.month }}<span *ngIf="isNextYearOrLater(slot?.date)"> - {{ dateDetails.year }}</span></span
            >
          </div>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="4px" class="f-weight--600 f-size--16">
          <span *ngIf="!slot.priceHidden">{{ slot.price }} SEK </span>
          <mat-icon *ngIf="isSelected" svgIcon="checked-ring-green" class="checked-ring-icon"></mat-icon>
          <span *ngIf="!slot.price && !slot.priceHidden" i18n>Make me an offer</span>
          <span *ngIf="!slot.price && slot.priceHidden">-</span></div
        >
      </div>

      <div fxFlex fxLayoutAlign="space-between">
        <div fxLayoutAlign="start center" fxLayoutGap="34px" class="slot-details">
          <div fxLayoutAlign="start center" *ngIf="!booking">
            <mat-icon svgIcon="note" class="note-icon"></mat-icon>&nbsp;{{ title }}
          </div>

          <div fxLayoutAlign="start center" *ngIf="!booking">
            <mat-icon svgIcon="clock" class="clock-icon"></mat-icon>&nbsp;
            <ng-container *ngIf="slot.startTime && slot.endTime">{{
              slot.startTime | apiDate | date: hhmmFormat
            }}</ng-container>
            <ng-container *ngIf="!slot.startTime"><span i18n>No set time</span> </ng-container>
          </div>

          <div fxLayoutAlign="start center" *ngIf="!booking">
            <mat-icon svgIcon="hourglass"></mat-icon>&nbsp;<span *ngIf="slot?.startTime && slot?.endTime">{{
              slot.startTime | gigDuration: slot.endTime
            }}</span>
          </div>
        </div>

        <ng-container *ngIf="!booking && !gig.isOwner">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <span fxLayout="row" fxLayoutGap="-8px" class="avatars-container">
              <ng-container *ngFor="let app of slot.applications; let i = index">
                <app-avatar
                  class="applied-with"
                  *ngIf="app.page.profilePicture"
                  [ngStyle]="{ 'z-index': i }"
                  [profilePicture]="app.page.profilePicture"
                  [specSize]="21"></app-avatar>
              </ng-container>
            </span>
          </div>
        </ng-container>

        <div>
          <button
            *ngIf="!gig.isOwner"
            (click)="clickSelect()"
            [disabled]="!canSelect"
            [ngClass]="{ black: !isSelected, stroked: isSelected }"
            class="gig-button wide">
            <ng-container *ngIf="!isSelected" i18n>Select</ng-container>
            <ng-container *ngIf="isSelected" i18n>Unselect</ng-container>
          </button>
        </div></div
      >
    </div>

    <div
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="end center"
      *ngIf="!booking && gig.isOwner"
      class="slot-actions">
      <button
        fxLayoutAlign="center center"
        mat-icon-button
        (click)="removeSlot()"
        [disabled]="gig.slots.length < 2"
        i18n-matTooltip
        matTooltip="Delete slot"
        class="action-button">
        <mat-icon svgIcon="trash-bin-2"></mat-icon>
      </button>

      <button
        fxLayoutAlign="center center"
        mat-icon-button
        (click)="copySlot()"
        i18n-matTooltip
        matTooltip="Duplicate slot"
        class="action-button">
        <mat-icon svgIcon="copy-light"></mat-icon>
      </button>

      <button
        fxLayoutAlign="center center"
        mat-icon-button
        (click)="openEdit()"
        i18n-matTooltip
        matTooltip="Edit slot"
        class="action-button">
        <mat-icon svgIcon="edit"></mat-icon>
      </button>
    </div>
    <ng-container *ngIf="!!booking">
      <div class="booking-artist" fxLayout fxLayoutGap="10px">
        <a
          [routerLink]="['/artists', booking.page.slug]"
          routerLinkActive="router-link-active"
          fxLayout
          fxLayoutGap="16px"
          fxLayoutAlign="start center">
          <app-cloud-image
            *ngIf="booking.page.profilePicture"
            [profilePicture]="booking.page.profilePicture"
            [altText]="booking.page.name + 's profile picture'"
            i18n-altText></app-cloud-image>
          <p class="page-name">{{ booking.page.name }}</p>
        </a>
      </div>
      <div class="booking-price" fxFlex="column" fxLayoutAlign="start center" fxFlex="nogrow">
        <p class="label-body" *ngIf="booking.price"
          >{{ booking.price }}&nbsp;{{ booking.priceCurrency.toUpperCase() }}</p
        >
      </div>
      <div class="booking-action">
        <a [routerLink]="['/bookings', booking.id]" mat-button i18n>View booking</a>
      </div>
    </ng-container>

    <div fxShow fxHide.lt-md *ngIf="!gig.isOwner">
      <!-- spacer for end -->
    </div>
  </div>

  <div fxHide fxShow.lt-md fxLayout="column">
    <div
      class="mobile-card-padding"
      [class.selected-bg]="isSelected"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <div fxFlex fxLayoutAlign="space-between center">
        <div fxLayoutAlign="start center" fxLayoutGap="12px">
          <div class="date" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxLayout="column" fxLayoutAlign="start center" class="slot-date">
              <span>{{ dateDetails.weekDay }}</span>
              <span class="day">{{ dateDetails.day }}</span>
              <span
                >{{ dateDetails.month
                }}<span *ngIf="isNextYearOrLater(slot?.date)"> - {{ dateDetails.year }}</span></span
              >
            </div>
          </div>
          <div fxLayoutAlign="start center" fxLayoutGap="4px" class="f-weight--600 f-size--16">
            <span *ngIf="!slot.priceHidden">{{ slot.price | priceSpaceFormat }} SEK </span>
            <mat-icon *ngIf="isSelected" svgIcon="checked-ring-green" class="checked-ring-icon"></mat-icon>
            <span *ngIf="!slot.price && !slot.priceHidden" i18n>Make me an offer</span>
            <span *ngIf="!slot.price && slot.priceHidden">-</span></div
          >
        </div>
        <div>
          <button
            *ngIf="!gig.isOwner"
            (click)="clickSelect()"
            [disabled]="!canSelect"
            [ngClass]="{ black: !isSelected, stroked: isSelected }"
            class="gig-button wide">
            <ng-container *ngIf="!isSelected" i18n>Select</ng-container>
            <ng-container *ngIf="isSelected" i18n>Unselect</ng-container>
          </button>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="8px"
        fxLayoutAlign="end center"
        *ngIf="!booking && gig.isOwner"
        class="slot-actions">
        <button
          fxLayoutAlign="center center"
          mat-icon-button
          (click)="removeSlot()"
          [disabled]="gig.slots.length < 2"
          i18n-matTooltip
          matTooltip="Delete slot"
          class="action-button">
          <mat-icon svgIcon="trash-bin-2"></mat-icon>
        </button>

        <button
          fxLayoutAlign="center center"
          mat-icon-button
          (click)="copySlot()"
          i18n-matTooltip
          matTooltip="Duplicate slot"
          class="action-button">
          <mat-icon svgIcon="copy-light"></mat-icon>
        </button>

        <button
          fxLayoutAlign="center center"
          mat-icon-button
          (click)="openEdit()"
          i18n-matTooltip
          matTooltip="Edit slot"
          class="action-button">
          <mat-icon svgIcon="edit"></mat-icon>
        </button>
      </div>
    </div>
    <div class="card-divider" [class.selected]="isSelected"></div>
    <div class="mobile-card-padding top" fxLayout="column" fxLayoutGap="25px">
      <div fxLayout="column" *ngIf="!booking">
        <div>
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="34px" class="slot-details">
            <div fxLayoutAlign="start center">
              <mat-icon svgIcon="note" class="note-icon"></mat-icon>&nbsp;{{ title }}
            </div>
            <div fxLayoutAlign="start center">
              <mat-icon svgIcon="clock" class="clock-icon"></mat-icon> &nbsp;
              <ng-container *ngIf="slot.startTime && slot.endTime">{{
                slot.startTime | apiDate | date: hhmmFormat
              }}</ng-container>
              <ng-container *ngIf="!slot.startTime"><span i18n>No set time</span> </ng-container>
            </div>
            <div fxLayoutAlign="start center" class="slot-details">
              <mat-icon svgIcon="hourglass"></mat-icon>&nbsp;<span *ngIf="slot?.startTime && slot?.endTime">{{
                slot.startTime | gigDuration: slot.endTime
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="booking" fxLayout="column" fxLayoutGap="4px">
        <label class="label-title" i18n>Booked artist</label>
        <div class="booking-artist" fxLayout fxLayoutGap="8px">
          <a
            [routerLink]="['/artists', booking.page.slug]"
            routerLinkActive="router-link-active"
            fxLayout
            fxLayoutGap="16px"
            fxLayoutGap.lt-md="8px"
            fxLayoutAlign="start center"
            *ngIf="booking.page.profilePicture">
            <app-cloud-image
              [profilePicture]="booking.page.profilePicture"
              [altText]="booking.page.name + 's profile picture'"
              i18n-altText></app-cloud-image>
            <p class="page-name">{{ booking.page.name }}</p>
          </a>
        </div>
      </div>
      <div *ngIf="booking" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxLayoutGap="4px">
          <label class="label-title" i18n
            >Price before
            <a href="http://help.gigital.se/en/articles/3350100-payment-policy" target="blank">fees</a></label
          >
          <span class="label-body">{{ slot.price }} SEK</span>
        </div>
        <div fxLayout="column" fxLayoutAlign="end end">
          <a class="view-link-mobile" [routerLink]="['/bookings', booking.id]" i18n>View</a>
        </div>
      </div>
    </div>
  </div>

  <div class="applications-container" fxLayout="column">
    <mat-divider
      *ngIf="!booking && gig.isOwner && (applications.length > 0 || requests.length > 0)"
      class="applications-divider"></mat-divider>
    <div fxLayout="column" fxLayoutGap="32px" *ngIf="!booking">
      <div *ngIf="gig.isOwner && requests.length > 0" class="slot-applications">
        <label i18n class="label bg-gray-5 gray-1 pill-label"
          >Requests <span class="gray-2">({{ requests.length }})</span></label
        >
        <div fxLayout fxLayoutGap="16px">
          <ng-container *ngFor="let req of requests">
            <app-gig-application [gig]="gig" [application]="req" [slot]="slot" selectable="false" [bookingId]="req.id">
            </app-gig-application>
          </ng-container>
        </div>
      </div>
      <div *ngIf="gig.isOwner && applications.length > 0" class="slot-applications">
        <label i18n class="label bg-gray-5 gray-1 pill-label"
          >Applications <span class="gray-2">({{ applications.length }})</span></label
        >
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <ng-container *ngFor="let app of applications">
            <app-gig-application [gig]="gig" [application]="app" [slot]="slot"></app-gig-application>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
