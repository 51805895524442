import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-check-button',
  templateUrl: './check-button.component.html',
  styleUrls: ['./check-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckButtonComponent,
      multi: true,
    },
  ],
})
export class CheckButtonComponent implements ControlValueAccessor {
  @Input() small: boolean = false;

  enabled = false;
  disabled = false;
  constructor() {}

  valueChanged(val) {}
  writeValue(obj: boolean): void {
    this.enabled = obj;
  }
  registerOnChange(fn: any): void {
    this.valueChanged = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onclick(event) {
    event.stopPropagation();
    event.preventDefault();

    this.enabled = !this.enabled;
    this.valueChanged(this.enabled);
  }
}
