import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { GigType } from '../../../generated/graphql';
import { TranslatedEnum, TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-gig-type-input',
  templateUrl: './gig-type-input.component.html',
  styleUrls: ['./gig-type-input.component.scss'],
})
export class GigTypeInputComponent implements OnInit {
  constructor(public translations: TranslationsService) {}

  @Input()
  formGroup: FormGroup;

  gigTypes: TranslatedEnum<GigType>[];
  selectedType: GigType;

  ngOnInit() {
    this.gigTypes = this.translations.getGigTypesList();
  }

  get type(): AbstractControl {
    return this.formGroup.get('type');
  }
}
