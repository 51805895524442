<div class="review" fxLayout="column" fxLayoutGap="8px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="reviewer">
    <div fxLayoutAlign="center center" class="reviewer--image">
      <span *ngIf="name">{{ name.slice(0, 1) }}</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="4px" class="reviewer--info">
      <div fxLayoutAlign="start end" fxLayoutGap="10px">
        <h4 class="reviewer-name" *ngIf="name">{{ name }}</h4>
        <p *ngIf="companyName?.length > 0" class="reviewer-company">{{ companyName }}</p>
      </div>
      <p class="review-date">{{ review.reviewDate | apiDate | date: DATE_FORMAT | lowercase }}</p>
    </div>
  </div>

  <div>
    <p *ngIf="!showMore" class="review-text"
      >{{ message }}
      <span *ngIf="review?.message?.length > maxLength" (click)="showMore = !showMore" class="cursor pink" i18n
        >Show more</span
      ></p
    >
    <p *ngIf="showMore">{{ review?.message }}</p>
  </div>
</div>
