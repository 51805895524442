import { Environment } from '../ngConf';

declare let NG_CONF;

export const environment: Environment = {
  production: true,
  appEnvironment: 'production',
  httpApiUrl: 'https://gig-gql-prod.herokuapp.com/graphql',
  wssApiUrl: 'wss://gig-gql-prod.herokuapp.com/graphql',
  DOMAIN: 'http://www.gigital.com',
  Auth0: {
    clientId: 'zWJnWvAnmfZ5SJbdJ05t8Ep6kxMHv7FL',
    domain: 'auth.gigital.com',
    callbackUrl: 'https://www.gigital.com/en/authorized',
    connection: 'gigital-authdb-prod',
  },
  INTERCOM_APP_ID: 'ubifunqd',
  ...(typeof NG_CONF !== 'undefined' ? NG_CONF : {}),
};
