import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CompletedGigs,
  CompletedGigsGQL,
  ExistingPlace,
  GetGigsGQL,
  GetGigsQuery,
  GetGigsQueryVariables,
  Gig,
  GigType,
  PlaceEntry,
  Tag,
  TagEntry,
  TypeEntry,
} from '../../../../generated/graphql';
import { GigsService } from '../../../services/gigs.service';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

@Component({
  selector: 'app-gigs-list',
  templateUrl: './gigs-list.component.html',
  styleUrls: ['./gigs-list.component.scss'],
})
export class GigsListComponent implements OnInit {
  @ViewChild('sidenav')
  sideNav: MatSidenav;

  sideNavRef: ElementRef;
  @ViewChild('sidenavRef', { static: false })
  set navRef(ref: ElementRef) {
    if (ref && !this.sideNavRef) {
      this.sideNavRef = ref;
    }
  }

  gigs: Observable<Gig[]>;
  availableLocations: Observable<PlaceEntry[]>;
  availableTags: Observable<TagEntry[]>;
  availableTypes: Observable<TypeEntry[]>;
  completedGigs: Observable<CompletedGigs>;
  gigsQuery: QueryRef<GetGigsQuery, GetGigsQueryVariables>;
  skip = 0;
  hasNextPage = false;

  @Input()
  completed: boolean;

  @Input()
  hideActs = false;

  filterChanges$ = new EventEmitter<{ locations: ExistingPlace[]; tags: number[]; type: GigType[]; sorting: string }>();

  getGigsVars: GetGigsQueryVariables = {
    paging: {
      limit: 9,
      skip: this.skip,
    },
    filter: {
      locations: [],
      tags: [],
      type: [],
    },
  };

  constructor(
    private iconsService: IconsRegistryService,
    private getCompleted: CompletedGigsGQL,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private gigService: GigsService,
    @Inject(PLATFORM_ID) private platformId: object,
    private getGigs: GetGigsGQL,
  ) {
    iconsService.registerIcons([IconSubsets.ACTIONS, IconSubsets.NAVIGATION]);
  }

  ngOnInit() {
    const filter = this.getFilterFromUrlParams();
    if (filter) {
      this.getGigsVars.filter = filter.filter;
    } else {
      this.getGigsVars.filter = { locations: this.getGigsVars.filter.locations, tags: this.getGigsVars.filter.tags };
    }

    this.gigsQuery = this.getGigs.watch({
      filter: this.getGigsVars.filter,
      paging: { limit: 9, skip: this.skip },
    });

    if (this.completed) {
      this.completedGigs = this.getCompleted.watch().valueChanges.pipe(
        map(({ data }) => {
          return data.completedGigs;
        }),
      );
    } else {
      this.gigs = this.gigsQuery.valueChanges.pipe(
        map(({ data }) => {
          this.skip = data.gigs.skip;
          this.hasNextPage = data.gigs.hasNextPage;
          return data.gigs.edges;
        }),
      );

      this.availableLocations = this.gigsQuery.valueChanges.pipe(
        map(({ data }) => {
          return data.gigs.availableFilter?.locations || [];
        }),
      );

      this.availableTags = this.gigsQuery.valueChanges.pipe(
        map(({ data }) => {
          return data.gigs.availableFilter?.tags || [];
        }),
      );

      this.availableTypes = this.gigsQuery.valueChanges.pipe(
        map(({ data }) => {
          return data.gigs.availableFilter?.types || [];
        }),
      );
    }
  }

  private setUrlParams() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const tags = this.getGigsVars.filter.tags.join(',');
    const sorting = this.getGigsVars.filter.sorting;
    const types = this.getGigsVars.filter.type
      ? this.getGigsVars.filter.type.map((t) => `"${t.toString()}"`).join(',')
      : '';

    const queryParams = {
      types: `[${types}]`,
      tags: `[${tags}]`,
      sorting,
      locations: JSON.stringify(this.getGigsVars.filter.locations),
    };

    const existing = this.activatedRoute.snapshot.queryParams;

    if (queryParams == existing) {
      return;
    }

    this.gigService.urlFilterExists = true;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams,
    });
  }

  private getFilterFromUrlParams(): GetGigsQueryVariables {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.gigService.filterHasUpdated.locations = false;
    this.gigService.filterHasUpdated.tags = false;
    this.gigService.filterHasUpdated.types = false;
    this.gigService.filterHasUpdated.sorting = false;

    const params = this.activatedRoute.snapshot.queryParams;
    if (params.tags) {
      this.gigService.urlFilterExists = true;

      this.gigService.urlFilter.locations = JSON.parse(params.locations);
      this.gigService.urlFilter.tags = JSON.parse(params.tags);
      this.gigService.urlFilter.types = JSON.parse(params.types);
      this.gigService.urlFilter.sorting = params.sorting;

      // If filter doesn't exist in url or is empty array or other falsey value, set it to true instantly
      this.gigService.filterHasUpdated.locations = this.gigService.urlFilter.locations?.length === 0;
      this.gigService.filterHasUpdated.tags = this.gigService.urlFilter.tags?.length === 0;
      this.gigService.filterHasUpdated.types = this.gigService.urlFilter.types?.length === 0;
      this.gigService.filterHasUpdated.sorting = !this.gigService.urlFilter.sorting;

      return {
        filter: {
          locations: this.gigService.urlFilter.locations,
          tags: this.gigService.urlFilter.tags,
          type: this.gigService.urlFilter.types,
          sorting: this.gigService.urlFilter.sorting,
        },
      };
    } else {
      this.gigService.filterHasUpdated.locations = true;
      this.gigService.filterHasUpdated.tags = true;
      this.gigService.filterHasUpdated.types = true;
      this.gigService.filterHasUpdated.sorting = true;
    }

    return undefined;
  }

  toggleSideNav() {
    setTimeout(() => {
      this.sideNav.toggle();
    }, 0);
  }

  clearFilters() {
    this.filterChanges({ locations: [], tags: [], types: [], sorting: 'RELEVANCY:DESC' });
  }

  private setTagFilter(tags: Tag[]) {
    this.getGigsVars.filter.tags = [];

    if (tags.length > 0) {
      this.getGigsVars.filter.tags = tags.map((t) => t.id);
    }
  }

  tagsChanges(tags: Tag[]) {
    this.setTagFilter(tags);
    this.fetchFilteredGigs();
  }

  private setLocationFilter(locs: ExistingPlace[]) {
    this.getGigsVars.filter.locations = [];

    if (locs.length > 0) {
      this.getGigsVars.filter.locations = locs.map((l) => {
        return {
          longName: l.longName,
          types: l.types,
        };
      });
    }
  }

  locationsChanges(locs: ExistingPlace[]) {
    this.setLocationFilter(locs);
    this.fetchFilteredGigs();
  }

  private setTypeFilter(types: GigType[]) {
    this.getGigsVars.filter.type = [];

    if (types.length > 0) {
      this.getGigsVars.filter.type = types;
    }
  }

  typesChanges(types: GigType[]) {
    this.setTypeFilter(types);
    this.fetchFilteredGigs();
  }

  private setSortFilter(sort: string) {
    this.getGigsVars.filter.sorting = sort;
  }

  sortingChanges(sort: string) {
    this.setSortFilter(sort);
    this.fetchFilteredGigs();
  }

  filterChanges(filterOpts: { locations: ExistingPlace[]; tags: Tag[]; types: GigType[]; sorting: string }) {
    this.setTagFilter(filterOpts?.tags);
    this.setLocationFilter(filterOpts.locations);
    this.setTypeFilter(filterOpts?.types);
    this.setSortFilter(filterOpts?.sorting);

    if (this.gigService.isFilteredHandled()) {
      this.setUrlParams();

      this.fetchFilteredGigs();
    }
  }

  private async fetchFilteredGigs() {
    if (!this.gigService.isFilteredHandled()) {
      return;
    }

    // Debounce because a lot of filters set their values at the same time
    this.debouncedFilter();
  }

  debouncedFilter = debounce(this.filterFetch, 50);
  private filterFetch() {
    this.skip = 0;
    this.gigsQuery.refetch({
      filter: {
        locations: this.getGigsVars.filter.locations,
        tags: this.getGigsVars.filter.tags,
        type: this.getGigsVars.filter.type,
        sorting: this.getGigsVars.filter.sorting,
      },
      paging: { limit: 9, skip: this.skip },
    });
    this.filterChanges$.emit({
      locations: this.getGigsVars.filter.locations,
      tags: this.getGigsVars.filter.tags,
      type: this.getGigsVars.filter.type,
      sorting: this.getGigsVars.filter.sorting,
    });
  }

  // Close on clicking outside of select dropdown
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.sideNavRef.nativeElement.contains(event.target) && this.sideNav.opened) {
      this.sideNav.close();
    }
  }

  fetchMore() {
    if (this.completed) return;
    try {
      this.getGigsVars.paging.skip = this.skip;
      this.setUrlParams();
      this.gigsQuery.fetchMore({
        query: this.getGigs.document,
        variables: {
          filter: {
            locations: this.getGigsVars.filter.locations,
            tags: this.getGigsVars.filter.tags,
            sorting: this.getGigsVars.filter.sorting,
            type: this.getGigsVars.filter.type,
          },
          paging: { limit: 9, skip: this.skip },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!!prev && prev.gigs) {
            prev.gigs.edges = [...prev.gigs.edges, ...fetchMoreResult.gigs.edges];
            prev.gigs.skip = fetchMoreResult.gigs.skip;
            prev.gigs.hasNextPage = fetchMoreResult.gigs.hasNextPage;
          }
          return prev;
        },
      });
    } catch (error) {
      console.error('gigs-list-component.ts - fetchMore()', error);
    }
  }
}
