<p
  [ngClass]="{
    status: true,
    open: gigStatus === GIG_STATUS.OPEN,
    closed: gigStatus === GIG_STATUS.CLOSED,
    booked: gigStatus === GIG_STATUS.BOOKED || applicationStatus === APP_STATUS.BOOKED,
    pending: applicationStatus === APP_STATUS.PENDING,
    declined: applicationStatus === APP_STATUS.DECLINED,
    finalizing: applicationStatus === APP_STATUS.FINALIZING
  }"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="8px">
  <span class="dot"></span>
  <span>{{ gigStatus || applicationStatus }}</span>
</p>
