import { SafeUrl } from '@angular/platform-browser';

export interface ImageToBeUploaded {
  img: File;
  objectUrl: string;
  safeCssUrl: SafeUrl;
}

export const isImageToBeUploaded = (val: any): val is ImageToBeUploaded => {
  return val.img && val.safeCssUrl && val.objectUrl;
};
