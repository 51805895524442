import { gql } from 'apollo-angular';

// TODO: Move queries and mutation to separate files? Maybe in grahpql/client and make the codegen ignore that folder.
export const GET_PAGE_FILTER = gql`
  query getPageFilter {
    pageFilter @client {
      limit
      skip
      tags
      priceMin
      priceMax
      sorting
      textQuery
      category
      location
      locationRadiusKm
      includesEquipment
    }
  }
`;

export const SET_PAGE_FILTER = gql`
  mutation SetPageFilter($pageFilter: SetPageFilterInput!) {
    setPageFilter(pageFilter: $pageFilter) @client {
      skip
    }
  }
`;

export const GET_SELECTED_APPLICATIONS = gql`
  query getSelectedApplications {
    selectedApplications @client {
      applicationId
      slotId
      dateId
    }
  }
`;

export const SELECT_APPLICATION = gql`
  mutation SelectApplication($application: ApplicationInput!) {
    selectApplication(application: $application) @client {
      applicationId
      slotId
      dateId
    }
  }
`;

export const CLEAR_APPLICATIONS = gql`
  mutation ClearApplications {
    clearApplications @client
  }
`;

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    todos: [Todo]!
    pageFilter: PageFilter
    selectedApplications: [Application]!
  }

  input AddTodoInput {
    id: String!
    title: String!
    icon: String!
    description: String!
    action: String!
    status: String!
  }

  input SetPageFilterInput {
    limit: Int
    skip: Int
    tags: [Int]
    priceMin: Int
    priceMax: Int
    sorting: String
    textQuery: String
    location: LatLongInput
    locationRadiusKm: Int
    category: PageCategory
    includesEquipment: Boolean
  }

  input ApplicationInput {
    applicationId: Int!
    slotId: Int!
    dateId: Int!
  }

  extend type Mutation {
    addTodo(todo: AddTodoInput!): Todo
    setPageFilter(pageFilter: SetPageFilterInput!): PageFilter
    selectApplication(application: ApplicationInput!): Application
    clearApplications: String
  }

  type Todo {
    id: String!
    title: String!
    icon: String!
    description: String!
    action: String!
    status: String!
  }

  type PageFilter {
    limit: Int
    skip: Int
    tags: [Int]
    priceMin: Int
    priceMax: Int
    sorting: String!
    textQuery: String
  }

  type Application {
    applicationId: Int!
    slotId: Int!
    dateId: Int!
  }
`;

export const resolvers = {
  Mutation: {
    addTodo: (_, { todo }, { cache }) => {
      const query = gql`
        query GetTodos {
          todos @client {
            id
            title
            icon
            description
            action
            status
          }
        }
      `;

      const previous = cache.readQuery({ query });

      const newTodo = {
        ...todo,
        __typename: 'Todo',
      };

      if (previous.todos.length === 0 || !previous.todos.find((t) => t.id === newTodo.id)) {
        const data = {
          todos: [...previous.todos, newTodo],
        };

        cache.writeQuery({ query, data });
      }

      return newTodo;
    },
    setPageFilter: (_, { pageFilter }, { cache }) => {
      const data = {
        pageFilter,
      };

      cache.writeQuery({ data, query: GET_PAGE_FILTER });
    },
    selectApplication: (_, { application }, { cache }) => {
      const previous = cache.readQuery({ query: GET_SELECTED_APPLICATIONS });
      const newApplication = {
        ...application,
        __typename: 'Application',
      };

      let data;

      const existingIndex: number = previous.selectedApplications.findIndex(
        (a) => a.applicationId === newApplication.applicationId && a.slotId === newApplication.slotId,
      );

      if (existingIndex > -1) {
        previous.selectedApplications.splice(existingIndex, 1);
        data = {
          selectedApplications: [...previous.selectedApplications],
        };
      } else {
        data = {
          selectedApplications: [...previous.selectedApplications, newApplication],
        };
      }

      cache.writeQuery({ data, query: GET_SELECTED_APPLICATIONS });
      return newApplication;
    },
    clearApplications: (_1, _2, { cache }) => {
      const data = {
        selectedApplications: [],
      };
      cache.writeQuery({ data, query: GET_SELECTED_APPLICATIONS });
      return { __typename: 'applicationStatus', status: 'success' };
    },
  },
};
