import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Booking, EquipmentStatus, UpdateBookingGQL, User } from '../../../../generated/graphql';
import { IconSubsets, IconsRegistryService } from '../../../services/icons-registry.service';
import { EditSuppliesPriceModalComponent } from './edit-supplies-price-modal/edit-supplies-price-modal.component';

@Component({
  selector: 'app-booking-equipment-request',
  templateUrl: './booking-equipment-request.component.html',
  styleUrls: ['./booking-equipment-request.component.scss'],
  animations: [
    trigger('expansion', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('225ms ease-out', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [style({ opacity: 1 }), animate('225ms ease-in', style({ height: 0, opacity: 0 }))]),
    ]),
  ],
})
export class BookingEquipmentRequestComponent implements OnInit {
  @Input() canIEditBooking: boolean;
  @Input() amIBooker: boolean;
  @Input() currentUser: User;
  @Input() booking: Booking;

  public supplyFormGroup: FormGroup;
  public isFormExpanded: boolean;
  public isEditMode: boolean;

  public EQUIPMENT_STATUS = EquipmentStatus;

  get toggleValue(): boolean {
    return this.supplyFormGroup.value.supplyStatus;
  }

  constructor(
    private updateBookingQuery: UpdateBookingGQL,
    private iconsService: IconsRegistryService,
    private snackbar: MatSnackBar,
    private modal: MatDialog,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS, IconSubsets.BOOKING]);
  }

  ngOnInit(): void {
    this.isEditMode = this.booking.supplyStatus !== EquipmentStatus.Supplied;
    this.isFormExpanded = this.booking.supplyStatus === EquipmentStatus.Supplied;

    this.initForm();
  }

  private initForm(): void {
    this.supplyFormGroup = new FormGroup({
      supplyStatus: new FormControl(this.booking.supplyStatus),
      supplyPrice: new FormControl(null, [Validators.required, Validators.min(1)]),
    });
  }

  public onSupplyStatusToggle(): void {
    this.isFormExpanded = !this.isFormExpanded;

    if (!this.toggleValue) {
      this.saveSupplayStatus();
    }
  }

  public declineSupplyRequest(): void {
    this.booking.supplyStatus = EquipmentStatus.Requested;
    this.booking.supplyPrice = null;
    this.saveSupplayStatus();
  }

  public saveSupplayStatus(): void {
    this.updateBookingQuery
      .mutate({
        bookingId: this.booking.id,
        booking: {
          supplyStatus: this.booking.supplyStatus,
          supplyPrice: this.booking.supplyPrice,
        },
      })
      .subscribe(
        (result) => {
          this.snackbar.open($localize`Equipment status was successfully updated!`, undefined, { duration: 3000 });
        },
        (error) => {
          this.snackbar.open($localize`Could not save changes.`, $localize`Try again`, { duration: 3000 });
          console.error(error);
        },
      );
  }

  public openSupplyPriceModal(): void {
    this.modal.open(EditSuppliesPriceModalComponent, {
      data: { booking: this.booking },
      minWidth: '400px',
    });
  }
}
