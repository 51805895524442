import { Component, OnInit } from '@angular/core';
import { User } from '@sentry/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetCurrentUserGQL, UserFieldsFragment } from '../../../generated/graphql';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  currentUser: Observable<User | UserFieldsFragment>;
  page: any;
  constructor(private getUser: GetCurrentUserGQL) {}

  ngOnInit() {
    this.currentUser = this.getUser.watch().valueChanges.pipe(map(d => d.data.me));
  }
}
