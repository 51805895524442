import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { TranslationsService } from '../../../../services/translations.service';

@Component({
  selector: 'app-date-calendar-input',
  templateUrl: './date-calendar-input.component.html',
  styleUrls: ['./date-calendar-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateCalendarInputComponent,
      multi: true,
    },
  ],
})
export class DateCalendarInputComponent implements ControlValueAccessor, OnInit {
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  @Input() placeholder: string = $localize`Select date`;
  @Input() singleDate: boolean = false;
  @Input() selectedDates: Date[] = [];
  @Input() invalid: boolean = false;
  @Input() minDate: Date;
  @Input() label: string;

  public onDropdownClose$: EventEmitter<any> = new EventEmitter();

  public isCalendarOpened: boolean = false;
  public slotDates: Date[] = [];

  get value() {
    return this.slotDates;
  }

  public get selectedDatesPreview(): string {
    const locale = this.translationsService.getCurrentLocale();

    const selectedDates = this.slotDates && this.slotDates.length ? this.slotDates : this.selectedDates;
    let preview = selectedDates
      .slice(0, 3)
      .map((d) => {
        const day = d.getDate();
        const month = new Intl.DateTimeFormat(locale, { month: 'short' }).format(d);
        const year = d.getFullYear();
        return `${day} ${month} ${year}`;
      })
      .join(', ');

    if (selectedDates.length > 3) {
      preview += ', ...';
    }

    return preview;
  }

  set value(value: any) {
    this.slotDates = value;

    this.onTouch(value);
    this.onChange(value);
  }

  public constructor(private translationsService: TranslationsService) {}

  public ngOnInit(): void {
    if (this.selectedDates?.length > 0) {
      this.selectedDates.forEach((d) => {
        d.setUTCHours(22);
        d.setUTCMinutes(0);
        d.setUTCSeconds(0);
        d.setUTCMilliseconds(0);
        this.handleSlotDateChange(d);
      });
    }
  }

  public setDisabledState(isDisabled: boolean): void {}

  public writeValue(value: any): void {}

  private onChange: any = () => {};
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  private onTouch: any = () => {};
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public classicistMultiple() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (this.slotDates.find((c) => !!c && c.toISOString() === date.toISOString())) {
        return 'highligted-date';
      }
      return '';
    };
  }

  public selectDate(event): void {
    this.handleSlotDateChange(event);
    this.value = this.slotDates;
  }

  public handleSlotDateChange(e: Date): void {
    // Only add/remove values if they are date type.
    // Stops for example null from becoming a chip when we clear out the temp value
    if (!(e instanceof Date)) {
      return;
    }

    const idx = this.slotDates.findIndex((d) => {
      return e.getFullYear() === d.getFullYear() && e.getMonth() === d.getMonth() && e.getDate() === d.getDate();
    });

    if (idx > -1) {
      this.slotDates.splice(idx, 1);
    } else {
      this.slotDates.push(e);
    }

    // Hack to re-render the calendar so the correct css classes are applied
    if (this.calendar) {
      this.calendar.updateTodaysDate();
    }
  }

  public onOutsideClick(targetElement): void {
    if (this.isCalendarOpened && !targetElement?.classList.contains('mat-calendar-body-cell-content')) {
      this.isCalendarOpened = false;
    }
  }
}
