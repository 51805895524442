import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  Booking,
  BookingEdgesFragment,
  BookingFeedType,
  BookingsByUser,
  BookingsCount,
  BookingStatusEnum,
  GetBookingsCountGQL,
  GetUserBookingsGQL,
  User,
} from '../../../../generated/graphql';
import { MetaService } from '../../../services/meta.service';
import { PopupService } from '../../../services/popup.service';

const TIME_LIMIT = 172800000;

@Component({
  selector: 'app-bookings-list',
  templateUrl: './bookings-list.component.html',
  styleUrls: ['./bookings-list.component.scss'],
})
export class BookingsListComponent implements OnInit {
  constructor(
    private getUserBookingsCount: GetBookingsCountGQL,
    private getUserBookings: GetUserBookingsGQL,
    public popupservice: PopupService,
    private meta: MetaService,
  ) {}

  public selectedFilter$: BehaviorSubject<BookingFeedType> = new BehaviorSubject(BookingFeedType.Open);
  public bookingsCount$: Observable<BookingsCount>;
  public bookings$: Observable<BookingsByUser>;
  public currentUser$: Observable<User>;

  public bookingsLoaded: boolean = false;
  private showNotes: number[] = [];

  BOOKING_FEED_TYPE = BookingFeedType;
  BOOKING_STATUS = BookingStatusEnum;

  ngOnInit(): void {
    this.bookingsCount$ = this.getUserBookingsCount.fetch().pipe(map(({ data }) => data.bookingsCount));

    this.bookings$ = combineLatest([this.selectedFilter$]).pipe(
      switchMap(([type]: [BookingFeedType]) => this.getUserBookings.fetch({ type: type })),
      map(({ data }) => {
        this.bookingsLoaded = true;
        data.bookingsByUser.open = data.bookingsByUser.open.sort((a, b) => this.sortInTimeLimit(a, b));
        return data.bookingsByUser;
      }),
    );

    this.selectedFilter$.next(BookingFeedType.Open);
    this.meta.setTitleWithDefaults('Your booking requests');
  }

  private sortInTimeLimit(a: Booking | BookingEdgesFragment, b: Booking | BookingEdgesFragment) {
    const now = Date.now();
    const aInLimit = now - parseInt(a.createdDate) < TIME_LIMIT;
    const bInLimit = now - parseInt(b.createdDate) < TIME_LIMIT;

    if (aInLimit) {
      if (!this.showNotes.find((n) => n === a.id)) {
        this.showNotes.push(a.id);
      }
    }
    if (bInLimit) {
      if (!this.showNotes.find((n) => n === b.id)) {
        this.showNotes.push(b.id);
      }
    }

    if (aInLimit && !bInLimit) {
      return -1;
    }
    if (bInLimit && !aInLimit) {
      return 1;
    }
    return 0;
  }
}
