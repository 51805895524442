import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { GigType, TypeEntry } from '../../../../../generated/graphql';
import { GigsService } from '../../../../services/gigs.service';
import { TranslatedEnum, TranslationsService } from '../../../../services/translations.service';

@Component({
  selector: 'app-type-buttons',
  templateUrl: './type-buttons.component.html',
  styleUrls: ['./type-buttons.component.scss'],
})
export class TypeButtonsComponent implements OnInit {
  group = new FormGroup({});

  availableTypesSubscription: Subscription;

  @Input()
  set availableTypes(ref: Observable<TypeEntry[]>) {
    const permitted = this.translations.permittedGigTypes;

    if (ref && !this.availableTypesSubscription) {
      this.availableTypesSubscription = ref.subscribe((entries) => {
        const existing = entries.map((t) => t.type);

        // Disable all controls
        for (const c in this.group.controls) {
          if (Object.prototype.hasOwnProperty.call(this.group.controls, c)) {
            const element = this.group.controls[c];
            element.disable({ emitEvent: false });
          }
        }

        // Enable existing controls
        for (const type of permitted) {
          if (existing.includes(type)) {
            this.group.controls[type].enable({ emitEvent: false });
          }
        }
      });
    }
  }

  @Output() typesChanges = new EventEmitter<GigType[]>();

  types: TranslatedEnum<GigType>[];

  constructor(private translations: TranslationsService, private gigService: GigsService) {}

  ngOnInit(): void {
    this.types = this.translations.getGigTypesList();
    for (const type of this.types) {
      this.group.addControl(type.type, new FormControl(false));
    }

    this.group.valueChanges.subscribe((v) => {
      const selected: GigType[] = [];
      for (const key in v) {
        if (Object.prototype.hasOwnProperty.call(v, key)) {
          const element = v[key];
          if (!!element) {
            const found = this.types.find((f) => f.type == key);
            selected.push(found.original);
          }
        }
      }

      this.typesChanges.emit(selected);
    });

    if (this.gigService.urlFilter.types) {
      for (const type of this.gigService.urlFilter.types) {
        const typeInput = this.group.get(type);
        if (typeInput) {
          typeInput.setValue(true);
        }
      }
      this.gigService.filterHasUpdated.types = true;
    }
  }
}
