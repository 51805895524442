import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Image } from '../../generated/graphql';

@Pipe({
  name: 'safeCssImageUrl',
})
export class SafeCssImageUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: Image, maxWidth: number, ...args: unknown[]): unknown {
    return this.sanitizer.bypassSecurityTrustUrl(
      `url(https://res.cloudinary.com/hthruuqgk/image/upload/c_limit,w_${maxWidth}/f_auto,fl_progressive,q_auto/c_scale,dpr_1.0/v1/${value.fileName})`,
    );
  }
}
