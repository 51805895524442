import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Booking, GetCurrentUserGQL, GetPagesByUserGQL, Page, UserType } from '../../../../generated/graphql';

@Component({
  selector: 'app-dashboard-pending-bookings',
  templateUrl: './dashboard-pending-bookings.component.html',
  styleUrls: ['./dashboard-pending-bookings.component.scss'],
})
export class DashboardPendingBookingsComponent implements OnInit {
  @Input() pendingBookings: Booking[];
  @Input() pendingCount: number;

  public userPages$: Observable<Page[]>;
  public userType: UserType;

  USER_TYPE = UserType;

  constructor(private getCurrentUser: GetCurrentUserGQL, private getUserPages: GetPagesByUserGQL) {}

  ngOnInit() {
    this.pendingBookings = this.pendingBookings.sort(
      (a, b) =>
        parseInt(a.dates.sort((date, nextDate) => parseInt(date.date) - parseInt(nextDate.date))[0].date) -
        parseInt(b.dates.sort((date, nextDate) => parseInt(date.date) - parseInt(nextDate.date))[0].date),
    );

    this.getCurrentUser
      .fetch()
      .pipe(tap(({ data }) => (this.userType = data.me.type || UserType.Booker)))
      .subscribe();

    this.userPages$ = this.getUserPages.watch().valueChanges.pipe(map(({ data }) => data.pagesByUser));
  }
}
