import { socialMediaId } from '../app/models/SocialMediaType';
import { PageLink } from '../generated/graphql';

export const filterVideoMediaLinks = (mediaLink: PageLink) => {
  return mediaLink.socialMediaId === socialMediaId.YOUTUBE || mediaLink.socialMediaId === socialMediaId.VIMEO;
};

export const filterAudioMediaLinks = (mediaLink: PageLink) => {
  return (
    mediaLink.socialMediaId === socialMediaId.SOUND_CLOUD ||
    mediaLink.socialMediaId === socialMediaId.SPOTIFY ||
    mediaLink.socialMediaId === socialMediaId.MIX_CLOUD
  );
};

export const sortMediaByOrder = (mediaArray: any[]) => {
  mediaArray = mediaArray.map((media) => {
    if (!media.hasOwnProperty('order') || media.order === null) {
      media.order = -1;
    }
    return media;
  });

  let orderedItems = mediaArray
    .filter((link) => link.order > -1)
    .sort((a, b) => a.order - b.order)
    .map((item, index) => {
      return {
        ...item,
        order: index,
      };
    });

  let unorderedItems = mediaArray
    .filter((link) => link.order < 0)
    .map((item, index) => {
      return {
        ...item,
        order: index + orderedItems.length,
      };
    });

  return orderedItems.concat(unorderedItems);
};
