import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AcceptApplicationGQL, Booking, BookingStatusEnum, Gig, Page } from '../../../../generated/graphql';
import { apiToDate } from '../../../helpers';

@Component({
  selector: 'app-select-applicants',
  templateUrl: './select-applicants.component.html',
  styleUrls: ['./select-applicants.component.scss'],
})
export class SelectApplicantsComponent implements OnInit {
  applications: Booking[];
  uniqueArtists: Page[];
  artistBookings: Booking[];
  selectedArtist: Page;

  applicationGroup = new FormGroup({
    message: new FormControl(''),
    dates: new FormArray([]),
  });

  get arrayDates(): FormArray {
    return this.applicationGroup.get('dates') as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { gig: Gig },
    private accept: AcceptApplicationGQL,
    private router: Router,
  ) {}

  ngOnInit() {
    // Lol, map->filter->reduce->concat
    this.applications = this.data.gig.slots
      .map(s => s.applications.filter(a => a.status === BookingStatusEnum.Application))
      .reduce((a, b) => a.concat(b));

    const pages = this.applications.map(a => a.page);
    this.uniqueArtists = [];
    for (const page of pages) {
      if (!this.uniqueArtists.find(p => p.id === page.id)) {
        this.uniqueArtists.push(page);
      }
    }
    console.log(this.data);
  }

  selectArtist(index: number) {
    this.artistBookings = this.applications.filter(a => a.page.id === this.uniqueArtists[index].id);
    this.selectedArtist = this.artistBookings[0].page;

    // Reset array length
    while (this.arrayDates.controls.length > 0) {
      this.arrayDates.removeAt(0);
    }

    // this.applicationGroup.get('price').setValue(this.artistBookings[0].price.value);

    for (let i = 0; i < this.artistBookings.length; i++) {
      this.arrayDates.push(
        new FormGroup({
          date: new FormControl(false),
          visValue: new FormControl(apiToDate(this.artistBookings[i].dates[i].date)),
          price: new FormControl(this.artistBookings[i].dates[i].price, [Validators.min(1)]),
          id: new FormControl(this.artistBookings[i].dates[i].id),
        }),
      );
    }
    console.log(this.artistBookings);
  }

  getArtistCategory(index: number) {
    return this.uniqueArtists[index].tags.find(t => t.group === 4).name;
  }

  acceptApplication() {
    // const dates: { date: string }[] = this.arrayDates.controls
    //   .map(c => {
    //     if (c.value.date) {
    //       return { date: (c.value.visValue as Date).toISOString() };
    //     }
    //   })
    //   .filter(c => !!c);
    // console.log(dates);
    // this.accept
    //   .mutate({
    //     applicationId: this.artistBookings[0].id,
    //     message: this.applicationGroup.get('message').value,
    //     dates: this.arrayDates.controls
    //       .filter(c => c.get('date').value)
    //       .map(c => {
    //         return {
    //           id: c.get('id').value,
    //           price: c.get('price').value,
    //         };
    //       }),
    //   })
    //   .subscribe(e => {
    //     // e.data.
    //   });
  }
}
