import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GigType } from '../../../generated/graphql';
import { MetaService } from '../../services/meta.service';
import { TranslationsService } from '../../services/translations.service';

declare let NG_CONF;

@Component({
  selector: 'app-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.scss'],
})
export class MetaComponent implements OnInit, AfterViewInit {
  @ViewChild('fallbackTitle', { static: true }) fallbackTitle: ElementRef;
  @ViewChild('fallbackDescription', { static: true }) fallbackDescription: ElementRef;

  @ViewChild('types', { static: true }) types: ElementRef;

  constructor(private meta: MetaService, private translations: TranslationsService) {}

  ngOnInit() {
    this.meta.fallbackTitle =
      (this.fallbackTitle.nativeElement as HTMLSpanElement).innerText || this.meta.fallbackTitle;
    this.meta.fallbackDescription =
      (this.fallbackDescription.nativeElement as HTMLSpanElement).innerText || this.meta.fallbackDescription;
  }

  ngAfterViewInit(): void {
    // Called after every check of the component's view. Applies to components only.
    // Add 'implements AfterViewChecked' to the class.
    const typesText = (this.types.nativeElement as HTMLSpanElement).innerText;
    if (!!typesText) {
      const splitTypes = typesText.split(',');
      for (const type of splitTypes) {
        const splitType = type.split(':');

        // Find the correct gig type by finding the index of value, then grab the key with index to select correct enum value
        var k = Object.values(GigType).findIndex((b) => b === splitType[0]);
        var t = GigType[Object.keys(GigType)[k]];

        this.translations.translatedTypes.push({
          type: splitType[0],
          translation: splitType[1],
          original: t,
        });
      }
    }
  }
}
