import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-gig-form-text-input',
  templateUrl: './gig-form-text-input.component.html',
  styleUrls: ['./gig-form-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GigFormTextInputComponent,
      multi: true,
    },
  ],
})
export class GigFormTextInputComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string = '';

  @Input() multiLine = false;
  @Input() rows: number = 1;
  @Input() title = '';

  @Input() maxChars: number = 0;
  @Input() disableMax: boolean = false;

  @Input() hasError: boolean = false;
  @Input() errorMessage: string = '';
  @Input() disabled: boolean;
  displayError = '';
  textValue: string = '';

  constructor() {}
  writeValue(obj: any): void {
    this.textValue = obj;
  }
  registerOnChange(fn: any): void {
    this.valueChanged = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  valueChanged(val) {}

  inputChanges(event) {
    let value: string = event.target.value;

    // CVA overwritter
    this.valueChanged(value);

    this.hasError = !this.disableMax && value.length > this.maxChars;
    if (!this.disableMax && value.length > this.maxChars) {
      this.displayError = $localize`Too many characters`;
    } else {
      this.displayError = this.errorMessage;
    }
  }
}
