<div class="container list-container">
  <h3 class="white h4">{{ list.contenfulData.fields.listName }}</h3>
  <p class="mat-body white" *ngIf="list.contenfulData.fields.listDescription">
    {{ list.contenfulData.fields.listDescription }}</p>
  <div fxLayout="row" fxLayoutGap.gt-sm="32px grid" fxLayoutGap="16px grid" class="artist-list">
    <ng-container *ngFor="let artist of list.list.pages">
      <app-artists-list-item-contentful class="artist-list-item" fxFlex="0 0 50%" fxFlex.gt-sm="0 0 25%"
        [artist]="artist.page"></app-artists-list-item-contentful>
    </ng-container>
  </div>
</div>
