<ng-template #noBookings>
  <div class="full-height">
    <div class="empty-booking-list" fxFlex="0 0 100%" fxLayout="column" fxLayoutAlign="center center">
      <img src="assets/images/empty-booking-list.svg" class="illustration fade-in" />
      <h2 class="mat-h1" i18n>You don't have any bookings yet</h2>
      <p class="mat-body-1 gray-2" i18n>When you get your first booking going it will show up here.</p>
      <a mat-flat-button color="primary" [routerLink]="['/artists/search']" i18n>Go to artists</a>
    </div>
  </div>
</ng-template>

<main class="container booking-list" fxLayout="column">
  <div>
    <app-back-arrow-title [titleText]="'Bookings'" i18n-titleText></app-back-arrow-title>

    <div class="info-banner" fxLayout="row wrap" fxLayoutGap="8px"
      ><span i18n
        >Cancellation policy is applied to all signed bookings. It means that you as an artist have guaranteed
        compensation for bookings cancelled close to the gig date.</span
      >
      <a i18n i18n-href href="http://help.gigital.se/en/articles/3304388-cancellation-policy">Read more here.</a></div
    >

    <ng-container *ngIf="bookingsCount$ | async as bookingsCount">
      <app-bookings-filter
        *ngIf="bookings$ | async"
        [bookings$]="bookings$"
        [bookingsCount]="bookingsCount"
        [selectedFilter]="selectedFilter$ | async"
        (onFilterSelect)="selectedFilter$.next($event)">
      </app-bookings-filter>
    </ng-container>

    <mat-divider fxHide.lt-sm></mat-divider>

    <div [ngSwitch]="selectedFilter$ | async">
      <div *ngSwitchCase="BOOKING_FEED_TYPE.Open">
        <ng-container *ngIf="!bookingsLoaded">
          <ng-container *ngTemplateOutlet="loading"></ng-container>
        </ng-container>

        <ng-container *ngIf="bookingsLoaded && (bookings$ | async)?.open.length === 0">
          <ng-container *ngTemplateOutlet="noBookings"></ng-container>
        </ng-container>

        <div
          class="booking-list--list"
          fxLayout="column"
          *ngIf="bookingsLoaded && (bookings$ | async)?.open.length > 0">
          <header class="booking-list--list-header">
            <h2 i18n>Pending bookings</h2>
            <p i18n class="mat-body gray-2 m--0">Booking request that require your attention</p>
          </header>
          <mat-divider class="m-b--0"></mat-divider>

          <app-booking-card
            *ngFor="let booking of (bookings$ | async)?.open"
            [currentUser]="currentUser | async"
            [booking]="booking"></app-booking-card>
        </div>
      </div>

      <div *ngSwitchCase="BOOKING_FEED_TYPE.Upcoming">
        <ng-container *ngIf="!bookingsLoaded">
          <ng-container *ngTemplateOutlet="loading"></ng-container>
        </ng-container>

        <ng-container *ngIf="bookingsLoaded && (bookings$ | async)?.signed.length === 0">
          <ng-container *ngTemplateOutlet="noBookings"></ng-container>
        </ng-container>

        <div
          class="booking-list--list"
          fxLayout="column"
          *ngIf="bookingsLoaded && (bookings$ | async)?.signed.length > 0">
          <header class="booking-list--list-header">
            <h2 i18n>Upcoming bookings</h2>
            <p i18n class="mat-body gray-2 m--0">These are your upcoming bookings.</p>
          </header>
          <mat-divider></mat-divider>

          <app-booking-card
            *ngFor="let booking of (bookings$ | async)?.signed"
            [currentUser]="currentUser | async"
            [booking]="booking"></app-booking-card>
        </div>
      </div>

      <div *ngSwitchCase="BOOKING_FEED_TYPE.Closed">
        <ng-container *ngIf="!bookingsLoaded">
          <ng-container *ngTemplateOutlet="loading"></ng-container>
        </ng-container>

        <ng-container *ngIf="bookingsLoaded && (bookings$ | async)?.closed.length === 0">
          <ng-container *ngTemplateOutlet="noBookings"></ng-container>
        </ng-container>

        <div
          class="booking-list--list"
          fxLayout="column"
          *ngIf="bookingsLoaded && (bookings$ | async)?.closed.length > 0">
          <header class="booking-list--list-header">
            <h2 i18n>Completed bookings</h2>
            <p i18n class="mat-body gray-2 m--0">These bookings are completed.</p>
          </header>

          <mat-divider></mat-divider>

          <app-booking-card
            *ngFor="let booking of (bookings$ | async)?.closed"
            [currentUser]="currentUser | async"
            [booking]="booking"></app-booking-card>
        </div>
      </div>

      <div *ngSwitchCase="BOOKING_FEED_TYPE.Others">
        <ng-container *ngIf="!bookingsLoaded">
          <ng-container *ngTemplateOutlet="loading"></ng-container>
        </ng-container>

        <ng-container *ngIf="bookingsLoaded && (bookings$ | async)?.others.length === 0">
          <ng-container *ngTemplateOutlet="noBookings"></ng-container>
        </ng-container>

        <div
          class="booking-list--list"
          fxLayout="column"
          *ngIf="bookingsLoaded && (bookings$ | async)?.others.length > 0">
          <header class="booking-list--list-header">
            <h2 i18n>Other bookings</h2>
            <p i18n class="mat-body gray-2 m--0">These booking requests are cancelled or didn't go through.</p>
          </header>

          <mat-divider></mat-divider>

          <app-booking-card
            *ngFor="let booking of (bookings$ | async)?.others"
            [currentUser]="currentUser | async"
            [booking]="booking"></app-booking-card>
        </div>
      </div>
    </div>
  </div>
</main>

<ng-template #loading>
  <div class="loading-container booking-list--list" fxLayout="column">
    <ngx-skeleton-loader
      [theme]="{
        width: '222px',
        height: '29px',
        'border-radius': '4px',
        'background-color': '#eee',
        'margin-bottom': '4px'
      }"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      [theme]="{
        width: '370px',
        height: '29px',
        'border-radius': '4px',
        'background-color': '#eee',
        'margin-bottom': '16px'
      }"></ngx-skeleton-loader>
    <ngx-skeleton-loader
      *ngFor="let item of [].constructor(6)"
      [theme]="{
        width: '100%',
        height: '82px',
        'border-radius': '4px',
        'background-color': '#eee',
        'margin-bottom': '16px'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>
