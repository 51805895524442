import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
// TODO: Set up tests here
import { apiToDate, dateToApi, LONG_DATE, LONG_DATE_WITH_TIME, SHORT_DATE, timeSince } from './dates';

export { apiToDate, dateToApi, LONG_DATE, LONG_DATE_WITH_TIME, SHORT_DATE, timeSince };

export const zeroPad = (value: number) => {
  return `0${value}`.slice(-2);
};

export const flat = <T>(arr: T[][]): T[] => {
  return arr.reduce((a, b) => a.concat(b), []);
};

export const flattenObject = (object: object) => {
  return Object.keys(object).reduce((toReturn, k) => {
    if (Object.prototype.toString.call(object[k]) === '[object Date]') {
      toReturn[k] = object[k].toString();
    } else if (typeof object[k] === 'object' && object[k]) {
      const flatObject = flattenObject(object[k]);
      Object.keys(flatObject).forEach((k2) => {
        toReturn[`${k}.${k2}`] = flatObject[k2];
      });
    } else {
      toReturn[k] = object[k];
    }

    return toReturn;
  }, {});
};

export const dataURLtoFile = (dataurl: string, filename: string): File => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const richTextToHtml = (richText: any): any => {
  if (richText === undefined || richText === null) {
    return '<p>Error</p>';
  }
  return documentToHtmlString(richText);
};

export const getEnumValues = (enumObj: any): number[] => {
  return Object.keys(enumObj)
    .filter((item) => isNaN(Number(item)))
    .map((item) => enumObj[item]);
};

export const minTwoDigits = (n: number | string) => {
  return (+n < 10 ? '0' : '') + n;
};
