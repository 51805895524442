<div fxLayout="column" class="bg-gray-5" [ngClass]="{ limited: gig.limitedData }">
  <app-gigs-booking-bar *ngIf="gig.isOwner" [gig]="gig" class="sticky-nav"></app-gigs-booking-bar>
  <mat-tab-group class="gigs-container tab-group">
    <mat-tab i18n-label label="Open acts ({{ slots.open.length }})">
      <div fxLayout="column" fxLayoutGap="16px" class="slot-grid">
        <app-gig-details-slot-details
          *ngFor="let slot of slots.open; let i = index"
          [slot]="slot"
          [gig]="gig"
          (selectSlot)="selectSlot($event)"
          [hasApprovedPage]="(pages | async)?.length > 0"
          [hasApplied]="!gig.isOwner && slot.applications.length > 0"></app-gig-details-slot-details>
        <p *ngIf="slots.open.length === 0" i18n class="mat-body-1 gray-1 text-center">No open acts.</p>
        <div class="add-act-container" *ngIf="gig.isOwner" fxLayout>
          <div class="add-act" fxFlex="100%" fxLayout fxLayoutAlign="center center" (click)="openNew()">
            <button mat-flat-button color="primary" i18n>Add new act</button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab i18n-label label="Booked acts ({{ slots.closed.length }})">
      <div fxHide fxShow.gt-md class="titles-header" fxLayout="row" fxLayoutAlign="start center">
        <span class="label date" i18n>Date</span>
        <span class="label looking-for" i18n>Booked artist</span>
        <span class="label budget" i18n
          >Price before
          <a href="http://help.gigital.se/en/articles/3350100-payment-policy" target="blank">fees</a></span
        >
      </div>

      <div fxLayout="column" fxLayoutGap="16px" class="slot-grid">
        <app-gig-details-slot-details
          *ngFor="let slot of slots.closed; let i = index"
          [slot]="slot"
          [gig]="gig"
          (selectSlot)="selectSlot($event)"
          [hasApprovedPage]="(pages | async)?.length > 0"
          [hasApplied]="!gig.isOwner && slot.applications.length > 0"></app-gig-details-slot-details>
        <p *ngIf="slots.closed.length === 0" i18n class="mat-body-1 gray-1 text-center">No booked acts.</p>
      </div>
    </mat-tab>
  </mat-tab-group>

  <ng-container *ngIf="pages | async as pages">
    <div fxLayout="column" fxLayoutGap="32px">
      <div
        fxLayout="row"
        class="full-w gigs-container"
        fxLayoutAlign="center center"
        [ngClass]="{ 'fetch-more-pad': pages.length === 0 }">
        <button mat-flat-button color="primary" [disabled]="!hasNextPage" *ngIf="hasNextPage" (click)="fetchMoreSlots()"
          >Load more slots</button
        >
      </div>

      <div *ngIf="!gig.isOwner && pages.length > 0" class="slot-actions gigs-container">
        <button (click)="openApply()" [disabled]="selectedSlots.length === 0" class="gig-button primary wide" i18n
          >Apply to {{ selectedSlots.length }}&nbsp;{selectedSlots.length, plural, =1 { act} other { acts}}</button
        >
      </div>
    </div>
  </ng-container>
  <!-- <div class="slot-actions container" *ngIf="gig.isOwner">
    <button mat-raised-button color="primary" (click)="openSelect()" i18n>Book an artist</button>
  </div> -->
</div>
