<app-info-bar [infoType]="INFO_BAR_TYPE.InfoMessage" [text]="INFO_BAR_TEXT"></app-info-bar>
<app-info-bar
  *ngIf="pageProgressStep.denied"
  [infoType]="INFO_BAR_TYPE.Warning"
  [text]="pageProgressStep.denyReason"></app-info-bar>
<div class="d--block container container-right">
  <h1 fxHide.lt-sm class="h6 f-weight--600 m-b--32" i18n>Introduction</h1>
  <div fxLayout="column" fxFlex="585px" fxFlex.lt-sm="grow" *ngIf="page" class="basic-info">
    <app-avatar-uploader
      [profilePicture]="page.profilePicture"
      [user]="page.user"
      [size]="150"
      (onPictureUploaded$)="updateProfilePicture($event)">
    </app-avatar-uploader>

    <form
      *ngIf="!isLoading; else loading"
      fxFlex.lt-md="grow"
      fxLayout="column"
      fxLayoutAlign.lt-md="start stretch"
      [formGroup]="formGroup"
      (submit)="savePageBasicInfo()">
      <div fxLayout="column" fxLayoutGap="40px" fxLayoutGap.lt-md="30px" class="m-b--32">
        <div>
          <app-input
            formControlName="name"
            placeholder="Enter artist name"
            [label]="artistNameLabel"
            [invalid]="
              (formGroup.controls.name.touched || formGroup.controls.name.dirty) && formGroup.controls.name.invalid
            "
            i18n-placeholder></app-input>
          <div
            *ngIf="
              (formGroup.controls.name.touched || formGroup.controls.name.dirty) && formGroup.controls.name.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="formGroup.controls.name.errors.required"
              class="input-errors--message"
              i18n>
              <mat-icon svgIcon="required"></mat-icon>
              Artist name is required.
            </p>
          </div>
        </div>

        <app-select-input
          label="Category"
          [selectOptions]="pageCategoryOptions"
          formControlName="category"
          i18n-label></app-select-input>

        <div>
          <div class="m-b--12">
            <app-location-input
              (onPlaceSelected$)="onLocationSelected($event)"
              [formattedAddress]="page.placeData?.formattedAddress"
              [invalid]="
                (formGroup.controls.placeId.touched || formGroup.controls.placeId.dirty) &&
                formGroup.controls.placeId.invalid
              "
              placeholder="Enter location"
              [label]="locationLabel"
              formControlName="placeId"
              i18n-placeholder>
            </app-location-input>
            <div
              *ngIf="
                (formGroup.controls.placeId.touched || formGroup.controls.placeId.dirty) &&
                formGroup.controls.placeId.invalid
              "
              class="input-errors">
              <p
                fxLayoutAlign="start center"
                *ngIf="formGroup.controls.placeId.errors?.required"
                class="input-errors--message"
                i18n>
                <mat-icon svgIcon="required"></mat-icon>
                Location is required.
              </p>
            </div>
          </div>
          <app-check-button formControlName="canTravel" [small]="true" i18n> Can travel nationwide </app-check-button>
        </div>

        <app-radio-buttons-input
          formControlName="hasEquipment"
          [options]="equipmentOptions"
          label="Technical equipment"
          i18n-label>
          <span class="m-b--12 f-size--15" i18n>Can you bring your own equipment if needed?</span>
        </app-radio-buttons-input>

        <div>
          <app-price-input
            [invalid]="
              (formGroup.controls.price.touched || formGroup.controls.price.dirty) &&
              (formGroup.controls.price.errors?.min || !priceAboveMinimum)
            "
            label="Price"
            formControlName="price"
            i18n-label></app-price-input>
          <div class="input-errors" *ngIf="formGroup.controls.price?.errors || !priceAboveMinimum">
            <p
              fxLayoutAlign="start center"
              *ngIf="
                (formGroup.controls.price.touched || formGroup.controls.price.dirty) &&
                formGroup.controls.price.errors?.min
              "
              class="input-errors--message"
              i18n>
              <mat-icon svgIcon="required"></mat-icon>
              Price is required.
            </p>
            <p
              fxLayoutAlign="start center"
              *ngIf="
                (formGroup.controls.price.touched || formGroup.controls.price.dirty) &&
                !formGroup.controls.price.errors?.min &&
                !priceAboveMinimum
              "
              class="input-errors--message"
              i18n>
              <mat-icon svgIcon="required"></mat-icon>
              Recommended starting price {{ minimumPrice }} SEK.
            </p>
          </div>
        </div>

        <div>
          <app-input
            label="Bio"
            [multiLine]="true"
            [invalid]="
              (formGroup.controls.presentation.touched || formGroup.controls.presentation.dirty) &&
              formGroup.controls.presentation.invalid
            "
            [rows]="5"
            placeholder="Describe yourself.."
            formControlName="presentation"
            i18n-placeholder
            i18n-label>
          </app-input>
          <div
            *ngIf="
              (formGroup.controls.presentation.touched || formGroup.controls.presentation.dirty) &&
              formGroup.controls.presentation.invalid
            "
            class="input-errors">
            <p
              fxLayoutAlign="start center"
              *ngIf="formGroup.controls.presentation.errors.required"
              class="input-errors--message"
              i18n>
              <mat-icon svgIcon="required"></mat-icon>
              Bio is required.
            </p>
          </div>
        </div>
      </div>
      <div fxLayoutAlign="space-between center">
        <button fxLayoutAlign="start center" class="gig-button transparent wide" (click)="goToStep$.emit(-1)">
          <mat-icon fxHide.lt-sm svgIcon="back-arrow"></mat-icon>
          <span i18n>Back</span>
        </button>
        <span fxFlex fxHide fxShow.lt-sm class="text-center"
          >{{ PROGRESS_STEP_NUMBER.BASIC_INFO }} <span i18n>of</span> 5</span
        >
        <button type="submit" class="gig-button primary wide" i18n>Next</button>
      </div>
    </form>
  </div>
</div>

<ng-template #loading>
  <div class="loading-screen" fxLayout fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="48px" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
      <app-gigital-logo></app-gigital-logo>
    </div>
  </div>
</ng-template>
