import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IconsRegistryService, IconSubsets } from '../../services/icons-registry.service';

@Component({
  selector: 'app-back-arrow-title',
  templateUrl: './back-arrow-title.component.html',
  styleUrls: ['./back-arrow-title.component.scss'],
})
export class BackArrowTitleComponent implements OnInit {
  @Input() fontWeight: number = 500;
  @Input() fontSize: number = 30;
  @Input() titleText: string;

  constructor(private iconService: IconsRegistryService, private location: Location) {
    this.iconService.registerIcons([IconSubsets.NAVIGATION]);
  }

  public ngOnInit() {}

  public onBackClick(): void {
    this.location.back();
  }
}
