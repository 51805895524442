import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { IgxDialogModule, IgxDragDropModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ArtistHeroComponent } from '../components/artist-hero/artist-hero.component';
import { ArtistsListContentfulComponent } from '../components/artists-list-contentful/artists-list-contentful.component';
import { ArtistsListItemContentfulComponent } from '../components/artists-list-item-contentful/artists-list-item-contentful.component';
import { LazyLoadingDirective } from '../directives/lazy-loading.directive';
import { InputModule } from '../input/input.module';
import { ArtistsHomeComponent } from '../pages/artists-home/artists-home.component';
import { CroppedImagePipe } from '../pipes/cropped-image.pipe';
import { SharedModule } from '../shared/shared.module';
import { ArtistsRoutingModule } from './artists-routing.module';
import { ArtistAudienceItemComponent } from './components/artist-audience-item/artist-audience-item.component';
import { ArtistBadgeComponent } from './components/artist-badge/artist-badge.component';
import { ArtistBookComponent } from './components/artist-book/artist-book.component';
import { ArtistFaqQuestionComponent } from './components/artist-faq-question/artist-faq-question.component';
import { ArtistImportFormComponent } from './components/artist-import-form/artist-import-form.component';
import { ArtistListFilterMobileModalComponent } from './components/artist-list-filter-mobile/artist-list-filter-mobile-modal/artist-list-filter-mobile-modal.component';
import { ArtistListFilterMobileComponent } from './components/artist-list-filter-mobile/artist-list-filter-mobile.component';
import { ArtistListSortingMobileModalComponent } from './components/artist-list-filter-mobile/artist-list-sorting-mobile-modal/artist-list-sorting-mobile-modal.component';
import { ArtistListFilterSideComponent } from './components/artist-list-filter-side/artist-list-filter-side.component';
import { ArtistListFilterMenuItemComponent } from './components/artist-list-filter/artist-list-filter-menu-item/artist-list-filter-menu-item.component';
import { ArtistListFilterComponent } from './components/artist-list-filter/artist-list-filter.component';
import { ArtistListItemVideoComponent } from './components/artist-list-item-video/artist-list-item-video.component';
import { ArtistListItemComponent } from './components/artist-list-item/artist-list-item.component';
import { ArtistListSliderComponent } from './components/artist-list-slider/artist-list-slider.component';
import { ArtistMediaComponent } from './components/artist-media/artist-media.component';
import { ArtistOverviewComponent } from './components/artist-overview/artist-overview.component';
import { ArtistProfileCardComponent } from './components/artist-profile-card/artist-profile-card.component';
import { EmptyArtistProfileComponent } from './components/artist-profile-card/empty-artist-profile/empty-artist-profile.component';
import { BookEditModalComponent } from './components/book-edit-modal/book-edit-modal.component';
import { EditorialListListComponent } from './components/editorial-list-list/editorial-list-list.component';
import { EditorialListComponent } from './components/editorial-list/editorial-list.component';
import { ImportablePageCardComponent } from './components/importable-page-card/importable-page-card.component';
import { ReviewsListComponent } from './components/reviews-list/reviews-list.component';
import { ArtistDetailBookDescriptionComponent } from './pages/artist-detail-book/artist-detail-book-description/artist-detail-book-description.component';
import { ArtistDetailBookDetailsComponent } from './pages/artist-detail-book/artist-detail-book-details/artist-detail-book-details.component';
import { ArtistDetailBookComponent } from './pages/artist-detail-book/artist-detail-book.component';
import { ArtistDetailEditComponent } from './pages/artist-detail-edit/artist-detail-edit.component';
import { ArtistConnectAudienceItemComponent } from './pages/artist-detail-edit/components/artist-detail-audience/artist-connect-audience-item/artist-connect-audience-item.component';
import { ArtistDetailAudienceComponent } from './pages/artist-detail-edit/components/artist-detail-audience/artist-detail-audience.component';
import { ArtistDetailBasicInfoComponent } from './pages/artist-detail-edit/components/artist-detail-basic-info/artist-detail-basic-info.component';
import { ArtistDetailEditImportComponent } from './pages/artist-detail-edit/components/artist-detail-edit-import/artist-detail-edit-import.component';
import { ArtistDetailFaqComponent } from './pages/artist-detail-edit/components/artist-detail-faq/artist-detail-faq.component';
import { ArtistFaqItemComponent } from './pages/artist-detail-edit/components/artist-detail-faq/artist-faq-item/artist-faq-item.component';
import { ArtistDetailTagsComponent } from './pages/artist-detail-edit/components/artist-detail-tags/artist-detail-tags.component';
import { ArtistEditMediaAudioComponent } from './pages/artist-detail-edit/components/artist-edit-media/artist-edit-media-audio/artist-edit-media-audio.component';
import { ArtistEditMediaGalleryComponent } from './pages/artist-detail-edit/components/artist-edit-media/artist-edit-media-gallery/artist-edit-media-gallery.component';
import { ArtistEditMediaVideoComponent } from './pages/artist-detail-edit/components/artist-edit-media/artist-edit-media-video/artist-edit-media-video.component';
import { ArtistEditMediaComponent } from './pages/artist-detail-edit/components/artist-edit-media/artist-edit-media.component';
import { ArtistDetailPreviewModalComponent } from './pages/artist-detail/artist-detail-preview-modal/artist-detail-preview-modal.component';
import { ArtistDetailPreviewComponent } from './pages/artist-detail/artist-detail-preview/artist-detail-preview.component';
import { ArtistDetailComponent } from './pages/artist-detail/artist-detail.component';
import { ArtistImportProfileComponent } from './pages/artist-import-profile/artist-import-profile.component';
import { ArtistListComponent } from './pages/artist-list/artist-list.component';
import { ArtistDetailBookSummaryComponent } from './pages/artist-detail-book/artist-detail-book-summary/artist-detail-book-summary.component';

@NgModule({
  declarations: [
    ArtistListSliderComponent,
    ArtistListComponent,
    ArtistListItemComponent,
    ArtistListFilterComponent,

    LazyLoadingDirective,

    ArtistListItemVideoComponent,
    ArtistListFilterSideComponent,
    ArtistDetailComponent,
    ArtistDetailEditComponent,
    CroppedImagePipe,
    ArtistBookComponent,
    ArtistDetailBookComponent,
    EditorialListComponent,
    EditorialListListComponent,
    ReviewsListComponent,
    ArtistHeroComponent,
    ArtistsHomeComponent,
    ArtistsListContentfulComponent,
    ArtistsListItemContentfulComponent,
    BookEditModalComponent,
    ArtistProfileCardComponent,
    EmptyArtistProfileComponent,

    ArtistListSortingMobileModalComponent,
    ArtistListFilterMobileModalComponent,
    ArtistListFilterMenuItemComponent,
    ArtistListFilterMobileComponent,

    ArtistDetailBasicInfoComponent,
    ArtistImportProfileComponent,

    ArtistDetailAudienceComponent,
    ArtistConnectAudienceItemComponent,
    ArtistAudienceItemComponent,
    ArtistEditMediaComponent,

    ArtistDetailTagsComponent,

    ArtistDetailFaqComponent,
    ArtistFaqQuestionComponent,
    ArtistFaqItemComponent,

    ArtistOverviewComponent,
    ArtistMediaComponent,
    ArtistBadgeComponent,
    ImportablePageCardComponent,
    ArtistEditMediaVideoComponent,
    ArtistDetailPreviewModalComponent,
    ArtistDetailPreviewComponent,
    ArtistDetailEditImportComponent,
    ArtistImportFormComponent,
    ArtistEditMediaAudioComponent,
    ArtistEditMediaGalleryComponent,
    ArtistDetailBookDescriptionComponent,
    ArtistDetailBookDetailsComponent,
    ArtistDetailBookSummaryComponent,
  ],
  exports: [ArtistProfileCardComponent, EmptyArtistProfileComponent, ArtistListSliderComponent],
  imports: [
    InfiniteScrollModule,
    CommonModule,
    ArtistsRoutingModule,
    MatGoogleMapsAutocompleteModule,
    CloudinaryModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatChipsModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatExpansionModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    SharedModule,
    ImageCropperModule,
    InputModule,

    MatCardModule,
    MatTabsModule,
    MatListModule,
    DragDropModule,
    MatToolbarModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDividerModule,
    MatProgressBarModule,

    YouTubePlayerModule,

    IgxDragDropModule,
    IgxDialogModule,
    IvyCarouselModule,
  ],
  entryComponents: [ArtistListItemVideoComponent, ArtistBookComponent, ArtistDetailPreviewModalComponent],
})
export class ArtistsModule {}
