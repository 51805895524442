<div class="container">
  <app-back-arrow-title
    class="m-b--45"
    [fontWeight]="600"
    [titleText]="'Request a review'"
    i18n-titleText></app-back-arrow-title>

  <div fxLayout="row" fxLayoutGap="100px" *ngIf="!isLoading; else loadingSpinner">
    <form
      [formGroup]="requestFormGroup"
      fxFlex="527px"
      fxLayout="column"
      fxLayoutGap="22px"
      class="request-form"
      (submit)="submitReviewInvite()">
      <div *ngIf="pages$ | async as pages" fxLayout="column" fxLayoutGap="3px">
        <span class="input-label" i18n>Artist profile</span>
        <app-artist-dropdown [artists]="pages" [mini]="true" (selectionChanges)="onPageSelected($event.page)">
        </app-artist-dropdown>
      </div>

      <div fxLayout="column" fxLayoutGap="3px">
        <span class="input-label"><span i18n>Email</span>&nbsp;<span class="sub-label" i18n>(recipient)</span></span>
        <app-gig-form-text-input [disableMax]="true" formControlName="email"></app-gig-form-text-input>
      </div>

      <div fxLayout="column" fxLayoutGap="3px" class="m-b--36">
        <span class="input-label" i18n>Personal message</span>
        <app-gig-form-text-input [disableMax]="true" [multiLine]="true" [rows]="6" formControlName="message">
        </app-gig-form-text-input>
      </div>

      <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
        <button type="submit" class="gig-button primary" i18n>Send request</button>
        <button class="gig-button transparent" [routerLink]="'../..'">Cancel</button>
      </div>
    </form>

    <div fxLayout="column" fxFlex="grow" class="description">
      <img fxFlex="121px" src="assets/images/invite_request.svg" class="m-b--36" />
      <p class="f-weight--600 m-b--15 title" i18n>Make your profile more attractive - Get more gigs!</p>
      <p i18n
        >Positive reviews increase your chances of getting more and better gigs. Gather reviews from gigs outside of
        Gigital to boost your profile.
      </p>
    </div>
  </div>
</div>

<ng-template #loadingSpinner>
  <div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayout="center">
      <mat-spinner></mat-spinner>
    </div>
    <p i18n>Loading...</p>
  </div>
</ng-template>
