<main class="container">
  <h1 class="h5" i18n="@@cancellationPolicy">Cancellation policy</h1>
  <p class="small-p mb-4"
    ><strong
      >Gigital tillämpar en standardiserad avbokningspolicy för alla gigs förmedlade via Gigitals hemsida.</strong
    >
    Du kanske också är intresserad av våra
    <a href="http://help.gigital.se/juridiskt/anvandarvillkor" rel="noreferrer" target="_blank">användarvillkor</a>
    eller vår
    <!-- <a [routerLink]="['/legal/privacy']">integritetspolicy</a>?</p> -->
    <a href="http://help.gigital.se/en/articles/3304374-privacy-policy" rel="noreferrer" target="_blank"
      >integritetspolicy</a
    >?</p
  >
  <span class="dash bg-gray-4 mb-2 ml-0"></span>
  <section>
    <!-- Conversations -->
    <div fxLayout="row" class="py-4">
      <div fxLayout="column">
        <div class="bg-white p-4 p-md-5">
          <div fxLayout="row" fxLayoutAlign.gt-md="center" fxLayoutGap="12px" class="mt-5" style="height: 750px">
            <div fxLayout="column" fxLayoutAlign="start end">
              <p class="text mt-0 mb-3" style="padding-bottom: 21%">Förhandling</p>
              <p class="text" style="padding-bottom: 104%">Bokningstillfälle</p>
              <p class="text" style="padding-bottom: 103%">7 dagar innan giget</p>
              <p class="text" style="padding-bottom: 108%">24h innan giget</p>
              <p class="text">Gig</p>
            </div>
            <div fxLayout="column" class="text" id="timeline"> </div>
            <div fxLayout="column" class="text text-left">
              <div fxLayout="row" fxLayoutAlign="center">
                <div fxLayout="column">
                  <p class="text m-0">&#9899;<span *ngIf="isPortrait">1.</span></p>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center" style="height: 5%;">
                <div fxLayout="column">
                  <div class="cancel-line-grey mx-auto p-0"></div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center">
                <div fxLayout="column">
                  <p class="text m-0">&#9899;<span *ngIf="isPortrait">2.</span></p>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center" style="height: 25%;">
                <div fxLayout="column">
                  <div class="cancel-line-grey mx-auto p-0"></div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center">
                <div fxLayout="column">
                  <p class="text m-0">&#9899;<span *ngIf="isPortrait">3.</span></p>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center" style="height: 25%;">
                <div fxLayout="column">
                  <div class="cancel-line-grey mx-auto p-0"></div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center">
                <div fxLayout="column">
                  <p class="text m-0">&#9899;<span *ngIf="isPortrait">4.</span></p>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center" style="height: 26%;">
                <div fxLayout="column">
                  <div class="cancel-line-grey mx-auto p-0"></div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center">
                <div fxLayout="column">
                  <p class="text m-0">&#9899;<span *ngIf="isPortrait">5.</span></p>
                </div>
              </div>
            </div>

            <div fxLayout="column" class="text text-left" *ngIf="!isPortrait">
              <div fxLayout="row">
                <div fxLayout="column">
                  <p class="text text-left m-0" style="padding-bottom: 10%"
                    >Första kontakt mellan arrangör och artist</p
                  >
                </div>
              </div>

              <div fxLayout="row">
                <div fxLayout="column">
                  <p class="text text-left" style="padding-bottom: 29%"
                    >Bokningen bekräftas när bägge parter accepterar bokningens villkor</p
                  >
                </div>
              </div>

              <div fxLayout="row">
                <div fxLayout="column">
                  <p class="text text-left" style="padding-bottom: 27%"
                    >Både artist och arrangör kan avboka fritt fram till 7 dagar innan giget.</p
                  >
                </div>
              </div>

              <div fxLayout="row">
                <div fxLayout="column">
                  <p class="text text-left" style="padding-bottom: 19%"
                    >Vid avbokning mindre än 7 dagar innan giget debiteras arrangören med 50% av fakturabeloppet.
                    <br />
                    Artister som avbokar mindre än 7 dagar innan giget får en varning.*</p
                  >
                </div>
              </div>

              <div fxLayout="row">
                <div fxLayout="column">
                  <p class="text text-left"
                    >Vid avbokning mindre än 24h innan giget debiteras arrangören med 100% av fakturabeloppet.
                    <br />
                    Artister som avbokar mindre än 24h innan giget blir avstängda. **</p
                  >
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row">
            <div fxLayout="column">
              <div fxLayout="column" class="text text-left" *ngIf="isPortrait">
                <div fxLayout="row">
                  <div fxLayout="column">
                    <p class="text text-left"><strong>1.</strong> Första kontakt mellan arrangör och artist</p>
                  </div>
                </div>

                <div fxLayout="row">
                  <div fxLayout="column">
                    <p class="text text-left"
                      ><strong>2.</strong> Bokningen bekräftas när bägge parter accepterar bokningens villkor</p
                    >
                  </div>
                </div>

                <div fxLayout="row">
                  <div fxLayout="column">
                    <p class="text text-left"
                      ><strong>3.</strong> Både artist och arrangör kan avboka fritt fram till 7 dagar innan giget.</p
                    >
                  </div>
                </div>

                <div fxLayout="row">
                  <div fxLayout="column">
                    <p class="text text-left"
                      ><strong>4.</strong> Vid avbokning mindre än 7 dagar innan giget debiteras arrangören med 50% av
                      fakturabeloppet.
                      <br />
                      Artister som avbokar mindre än 7 dagar innan giget får en varning.*</p
                    >
                  </div>
                </div>
                <div fxLayout="row">
                  <div fxLayout="column">
                    <p class="text text-left"
                      ><strong>5.</strong> Vid avbokning mindre än 24h innan giget debiteras arrangören med 100% av
                      fakturabeloppet.
                      <br />
                      Artister som avbokar mindre än 24h innan giget blir avstängda. **</p
                    >
                  </div>
                </div>
              </div>

              <div fxLayout="row" class="mt-5">
                <div fxLayout="column">
                  <div fxLayout="row">
                    <div fxLayout="column">
                      <p class="text"
                        >Om artisten inte dyker upp måste detta meddelas till Gigital senast 24h efter gigets starttid
                        och arrangören kommer inte att debiteras för det giget.</p
                      >
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxLayout="column">
                      <p class="text"
                        >Om artisten inte dyker upp har denne ingen rätt till någon ersättning för giget.</p
                      >
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxLayout="column">
                      <p class="text">Gigital har sista ordet i alla tvister.</p>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxLayout="column">
                      <p class="text">
                        <i>
                          *En artist kan få uppemot två varningar innan denne blir avstängd från Gigital.
                        </i>
                      </p>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxLayout="column">
                      <p class="text">
                        <i>
                          **Debitering och avstängning vid avbokningar nära inpå giget kan påverkas enligt Force
                          Majeure-bestämmelser som hittas i Användarvillkoren eller andra giltiga skäl.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
