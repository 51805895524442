import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BlogPost } from '../models/BlogPost';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  private apiUrl = 'https://gigital-blog.herokuapp.com/blog/ghost/api/v2/content/';

  // Safe to use https://ghost.org/docs/api/v2/content/#key
  private apiKey = '41793bb4587e5e874ee0f48845';

  posts: BlogPost[];

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {}

  getNews(limit: number = 3, tags: string[] = ['artistnews', 'bookernews'], utmSource: string) {
    // FIXME: Call fails on server for some reason and stalls the SSR rendering until it has failed completely
    if (isPlatformBrowser(this.platformId)) {
      this.apiReq('posts', limit, { filter: tags.map(t => `tag:${t}`).join(',') }).subscribe(d => {
        this.posts = (d as any).posts.map(p => {
          if (utmSource) {
            p.url = `${p.url}?utm_source=${utmSource}`;
          }
          return p;
        });
      });
    }
  }

  private apiReq(endPoint: string, limit: number, extra?: any) {
    return this.http.get(this.apiUrl + endPoint, { params: { key: this.apiKey, limit: limit.toString(), ...extra } });
  }
}
