import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Booking, GetCurrentUserGQL, Gig, GigTags, GigType, User } from '../../../../generated/graphql';
import { getTownName } from '../../../../utils/places';
import { flat } from '../../../helpers';
import { SHORT_DATE_NO_YEAR } from '../../../helpers/dates';
import { getGigMetaData, getTagsList, GigMeta } from '../../../helpers/gigs';
import { TranslatedEnum, TranslationsService } from '../../../services/translations.service';

@Component({
  selector: 'app-gig-card',
  templateUrl: './gig-card.component.html',
  styleUrls: ['./gig-card.component.scss'],
})
export class GigCardComponent implements OnInit {
  @Input()
  gig: Gig;

  @Input()
  hideActs = false;
  meta: GigMeta;
  tagsList: string;
  applications: Booking[];
  currentUser: Observable<User>;
  dateFormat = SHORT_DATE_NO_YEAR;
  availableActs = 0;

  gigTags = GigTags;
  gigTypes = GigType;
  type: TranslatedEnum<GigType>;
  firstDate: string;
  lastDate: string;
  doDatesDiffer = false;
  locationName: string;

  constructor(public translations: TranslationsService, private getUser: GetCurrentUserGQL) {}

  ngOnInit() {
    this.meta = getGigMetaData(this.gig);

    this.type = this.translations.gigType(this.gig.type);

    this.tagsList = getTagsList(this.gig);
    this.availableActs = this.gig.slotsOpen;

    if (this.gig.slots.length > 0) {
      const sortedDates = this.gig.slots.map((s) => s.date).sort((a, b) => parseInt(a) - parseInt(b));
      this.firstDate = sortedDates[0];
      this.lastDate = sortedDates[sortedDates.length - 1];
      this.doDatesDiffer = this.firstDate != this.lastDate;
    }

    this.locationName = getTownName(this.gig.placeData);

    this.currentUser = this.getUser.watch().valueChanges.pipe(
      map((d) => {
        if (!!d.data.me) {
          this.applications = flat(this.gig.slots.map((g) => g.applications || []));
        } else {
          this.applications = [];
        }
        return d.data.me;
      }),
    );
  }
}
