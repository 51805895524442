import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExistingPlace, GetExistingGigPlacesGQL, PlaceEntry } from '../../../../../generated/graphql';
import { GigsService } from '../../../../services/gigs.service';

@Component({
  selector: 'app-places-check-list',
  templateUrl: './places-check-list.component.html',
  styleUrls: ['./places-check-list.component.scss'],
})
export class PlacesCheckListComponent implements OnInit {
  existingPlaces: Observable<PlaceEntry[]>;
  placesGroup = new FormGroup({
    all: new FormControl(true),
  });
  selectedLocations: ExistingPlace[] = [];

  @Output()
  locationsChanges = new EventEmitter<ExistingPlace[]>();

  filterChangesSub: Subscription;

  @Input()
  set filterChanges(ref: Observable<{ locations: ExistingPlace[]; tags: number[] }>) {
    if (ref && !this.filterChangesSub) {
      this.filterChangesSub = ref.subscribe((f) => {
        for (const loc of Object.keys(this.placesGroup.controls)) {
          this.placesGroup.get(loc).setValue(false, { emitEvent: false });
        }
        for (const l of f.locations) {
          this.placesGroup.get(l.longName).setValue(true, { emitEvent: false });
        }

        this.checkAllPlaces(this.placesGroup.value);
      });
    }
  }

  constructor(private getExistingPlaces: GetExistingGigPlacesGQL, private gigService: GigsService) {}

  ngOnInit(): void {
    this.existingPlaces = this.getExistingPlaces.watch().valueChanges.pipe(
      map((d) => {
        return d.data.existingPlaces;
      }),
      tap((existing) => {
        for (const d of existing) {
          this.placesGroup.addControl(d.place.longName, new FormControl(false));

          this.placesGroup.get(d.place.longName).valueChanges.subscribe((v) => {
            if (v) {
              this.selectedLocations.push(d.place);
            } else {
              const idx = this.selectedLocations.findIndex((s) => s === d.place);
              if (idx > -1) {
                this.selectedLocations.splice(idx, 1);
              }
            }

            this.locationsChanges.emit(this.selectedLocations);
          });

          if (this.gigService.urlFilter.locations) {
            for (const loc of this.gigService.urlFilter.locations) {
              const placeInput = this.placesGroup.get(loc.longName);
              if (placeInput) {
                placeInput.setValue(true);
              }
            }
          }
          this.gigService.filterHasUpdated.locations = true;
        }
      }),
    );

    this.placesGroup.valueChanges.subscribe((locs) => {
      this.checkAllPlaces(locs);
    });

    this.placesGroup.get('all').valueChanges.subscribe((v) => {
      if (v) {
        for (const loc of Object.keys(this.placesGroup.controls)) {
          this.placesGroup.get(loc).setValue(false, { emitEvent: false });
        }
        this.selectedLocations = [];
        this.locationsChanges.emit(this.selectedLocations);
      }
    });
  }

  toggleFormControl(key: string, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.placesGroup.get(key).setValue(!this.placesGroup.get(key).value);
  }

  checkAllPlaces(locs: { [name: string]: boolean }) {
    for (const key in locs) {
      if (Object.prototype.hasOwnProperty.call(locs, key)) {
        // skip all check
        if (key === 'all') {
          continue;
        }

        const element = locs[key];
        if (element) {
          this.placesGroup.get('all').setValue(false, { emitEvent: false });
          return;
        }
      }
    }

    this.placesGroup.get('all').setValue(true, { emitEvent: false });
  }
}
