import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import {
  ApplyToGigGQL,
  GetGigApplyToGQL,
  GetPagesByUserGQL,
  Gig,
  GigSlot,
  GigType,
  Page,
  ReviewStatusEnum,
} from '../../../../generated/graphql';
import { doesTextContainContactInfo, doesTextContainUrl } from '../../../../utils/contactInfo';
import { flat } from '../../../helpers';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss'],
})
export class ApplyComponent implements OnInit {
  public gig: Observable<Gig>;
  public previewSlots: Observable<GigSlot[]>;
  public restSlots: Observable<GigSlot[]>;
  public appliedPages: Observable<Page[]>;
  public pages: Observable<Page[]>;
  public warnContactInfo: boolean;
  constructor(
    private getGig: GetGigApplyToGQL,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private getPages: GetPagesByUserGQL,
    private applyMutation: ApplyToGigGQL,
    private router: Router,
    private analytics: AnalyticsService,
  ) {}

  displayedImageSafeUrl: SafeUrl;
  slotIds: number[] = [];

  loading = false;
  showAllSlots = false;
  hasManySlots = false;

  private gigsLoaded = false;
  private pagesLoaded = false;
  private pageHasApplied = false;
  private gigType: GigType;

  get allDataLoaded(): boolean {
    return this.gigsLoaded && this.pagesLoaded;
  }

  selectedSlots = 0;
  totalSlots = 0;

  applyGroup = new FormGroup({
    page: new FormControl(null, [Validators.required]),
    slots: new FormControl(null, [Validators.required]),
    message: new FormControl('', [Validators.required, Validators.minLength(5)]),
  });

  ngOnInit(): void {
    const gigId = parseInt(this.route.snapshot.params.gigId);
    this.slotIds = this.route.snapshot.queryParams.slots.split(',').map((s) => parseInt(s));

    const gigsQuery = this.getGig.watch({
      gigId,
    });
    const pageQuery = this.getPages.watch();

    this.gig = gigsQuery.valueChanges.pipe(
      tap((d) => {
        this.gigsLoaded = !d.loading;
        if (d.data.gigById.gig.images?.length > 0) {
          this.displayedImageSafeUrl = this.sanitizer.bypassSecurityTrustUrl(
            `url(https://res.cloudinary.com/hthruuqgk/image/upload/c_fill,g_center,w_145,h_145/f_auto,fl_progressive,q_auto/dpr_1.0/v1/${d.data.gigById.gig.images[0].fileName})`,
          );
        }
      }),
      map((d) => {
        return d.data.gigById.gig;
      }),
    );

    this.previewSlots = gigsQuery.valueChanges.pipe(
      map((d) => {
        const slots = d.data.gigById.gig.slots.filter((s) => this.slotIds.includes(s.id));
        this.selectedSlots = slots.length;
        this.totalSlots = d.data.gigById.gig.slots.length;
        this.applyGroup.get('slots').setValue(slots);
        this.hasManySlots = slots.length > 4;
        this.gigType = d.data.gigById.gig.type;

        // Await that we have gig data before we get pages
        this.pages = pageQuery.valueChanges.pipe(
          tap((d) => {
            this.pagesLoaded = !d.loading;
          }),
          map((d) => {
            console.log(this.gigType, 'pages');
            if (this.gigType === GigType.Private) {
              return d.data.pagesByUser;
            } else {
              return d.data.pagesByUser.filter((p) => p.reviewStatus === ReviewStatusEnum.Approved);
            }
          }),
        );

        return slots.slice(0, 4);
      }),
    );

    this.restSlots = gigsQuery.valueChanges.pipe(
      map((d) => {
        const slots = d.data.gigById.gig.slots.filter((s) => this.slotIds.includes(s.id));
        return slots.slice(4);
      }),
    );

    this.appliedPages = gigsQuery.valueChanges.pipe(
      map((d) => {
        const slots = d.data.gigById.gig.slots.filter((s) => this.slotIds.includes(s.id));
        return flat(slots.map((s) => s.applications)).map((a) => a.page);
      }),
    );
  }

  public selectedPageChanges({ page, hasApplied }: { page: Page; hasApplied: boolean }) {
    this.applyGroup.get('page').setValue(page);
    this.pageHasApplied = hasApplied;
    console.log(hasApplied);
  }

  acknowledgeContactInfo() {
    this.warnContactInfo = false;
    this.analytics.emitEvent('contact_info_acknowledged_gig_application');
  }

  public sendApplication() {
    const containsContactInfo =
      doesTextContainContactInfo(this.applyGroup.get('message').value) ||
      doesTextContainUrl(this.applyGroup.get('message').value);
    if (containsContactInfo) {
      this.warnContactInfo = true;
      this.gig.pipe(first()).subscribe((g) => {
        this.analytics.emitEvent('contact_info_blocked_gig_application', {
          gigId: g.id,
          page_id: this.applyGroup.get('page').value.id,
        });
      });
      return;
    }

    this.loading = true;
    this.applyMutation
      .mutate({
        application: {
          message: this.applyGroup.get('message').value,
          pageId: this.applyGroup.get('page').value.id,
          slots: (this.applyGroup.get('slots').value as GigSlot[]).map((s) => {
            return {
              id: s.id,
              price: s.price,
            };
          }),
        },
      })
      .subscribe((d) => {
        console.log(d);
        this.loading = false;
        this.router.navigate(['/gigs', parseInt(this.route.snapshot.params.gigId)]);
        this.analytics.emitEvent(
          'applied_to_gig',
          {
            gig_id: d.data.applyToGig.appliedTo.id,
            page_id: this.applyGroup.get('page').value.id,
          },
          {
            value: (this.applyGroup.get('slots').value as GigSlot[]).reduce((a, b) => a + b.price, 0) * 0.01,
          },
        );
      });
  }
}
