import { ContentfulArtistList } from '../generated/graphql';

export interface MappedContentfulArtistList {
  contenfulData: any;
  list: ContentfulArtistList;
}

export const extractContentfulArtistsLists = (
  blocks: any[],
  lists: ContentfulArtistList[],
): MappedContentfulArtistList[] => {
  const outLists: MappedContentfulArtistList[] = [];

  for (const block of blocks) {
    if (block.sys.contentType.sys.id === 'artistList') {
      outLists.push({
        contenfulData: block,
        list: lists.find((l) => block.sys.id === l.listId),
      });
    }
  }

  return outLists;
};

export const parseQueryParams = (queryString: string) => {
  var query = {};
  var pairs = queryString.split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};
