import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancellation-policy',
  templateUrl: './cancellation-policy.component.html',
  styleUrls: ['./cancellation-policy.component.scss']
})
export class CancellationPolicyComponent implements OnInit {
  public isMobile = false;
  public isPortrait = false;

  ngOnInit() {
    this.setMobileProperties();
  }
  public setMobileProperties() {
    this.isMobile = this.testIfMobile();
    this.isPortrait = this.testIfPortrait();
  }
  private testIfMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
  private testIfPortrait() {
    return window.innerHeight > window.innerWidth;
  }

}
