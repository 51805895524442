import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconSubsets, IconsRegistryService } from '../../../../services/icons-registry.service';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LocationInputComponent,
      multi: true,
    },
  ],
})
export class LocationInputComponent implements ControlValueAccessor, OnInit {
  @ViewChild('placeIdInput', { static: true }) placeIdInput: ElementRef;

  @Output() onPlaceSelected$: EventEmitter<PlaceResult> = new EventEmitter();

  // Supported types: '(geocode)', '(address)', '(establishment)', '(regions)', '(cities)'
  @Input() locationType: string[] = ['(cities)'];

  @Input() formattedAddress: string;
  @Input() placeholder: string = '';

  @Input() label: string = '';
  @Input() isLightLabel: boolean = false;

  @Input() invalid: boolean = false;

  public placeId: string;

  set value(value) {
    this.placeId = value;
    this.onChange(value);
    this.onTouch(value);
  }

  get value() {
    return this.placeId;
  }

  get autoCompleteOptions() {
    return { types: this.locationType };
  }

  get translatedLabel(): string {
    if (this.label) {
      return $localize`${this.label}`;
    }
    return null;
  }

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.FORMS]);
  }

  public ngOnInit(): void {
    if (this.formattedAddress) {
      this.placeIdInput.nativeElement.value = this.formattedAddress;
    }
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  private onChange: any = () => {};
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  private onTouch: any = () => {};
  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  public onAutocompleteSelected(result: PlaceResult): void {
    this.value = result.place_id;
    this.formattedAddress = result.formatted_address;
    this.onPlaceSelected$.emit(result);
  }
}
