import { Component, Input, OnInit } from '@angular/core';
import { Badge } from '../../../../generated/graphql';
import { IconsRegistryService, IconSubsets } from '../../../services/icons-registry.service';

@Component({
  selector: 'app-artist-badge',
  templateUrl: './artist-badge.component.html',
  styleUrls: ['./artist-badge.component.scss'],
})
export class ArtistBadgeComponent implements OnInit {
  @Input() badge: any;

  public BADGE_TYPES = Badge;

  public constructor(private iconsService: IconsRegistryService) {
    this.iconsService.registerIcons([IconSubsets.BADGES]);
  }

  public ngOnInit(): void {}
}
