<div class="file-uploader">
  <span *ngIf="label" class="file-uploader--label d--block m-b--8">{{ label }}</span>

  <div fxLayout="row" fxLayoutGap="16px">
    <label
      *ngIf="filesArray.length === 0 || filesArray.length < 3"
      fxLayout="column"
      fxLayoutAlign="start center"
      for="file-upload"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      class="file-uploader--dropzone"
      [ngClass]="{ small: filesArray.length > 0 }">
      <span fxLayoutAlign="center center" class="upload-icon">
        <mat-icon svgIcon="upload"></mat-icon>
      </span>
      <p *ngIf="filesArray.length <= 0" class="f-size--14"
        ><span i18n>Drag & drop files or</span>&nbsp;<span class="browse-link cursor" i18n>browse files</span></p
      >
      <p *ngIf="filesArray.length <= 0" class="browse-prompt f-size--12" i18n>JPG or PNG - Max file size XMB</p>
    </label>

    <ng-container *ngFor="let image of filesArray; let i = index">
      <div *ngIf="image" class="image-preview" [style.background-image]="image.safeCssUrl">
        <span fxLayoutAlign="center center" (click)="removeImage(i)" class="image-preview--remove"
          ><mat-icon svgIcon="trash-bin"></mat-icon
        ></span>
      </div>
    </ng-container>
  </div>

  <input
    id="file-upload"
    multiple
    type="file"
    [accept]="requiredFileType"
    (change)="imgInputSelected($event.target.files)" />
</div>
