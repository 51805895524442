import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Booking,
  BookingDates,
  CancelBookingDatesGQL,
  CancelBookingGQL,
  CancelReason,
  DeclineBookingGQL,
  User,
} from '../../../../../../generated/graphql';
import { amIBooker, isBookingSignedByBoth } from '../../../../../bookings/bookingUtils';
import { apiToDate } from '../../../../../helpers';
import { DAY_MONTH } from '../../../../../helpers/dates';
import { IconSubsets, IconsRegistryService } from '../../../../../services/icons-registry.service';
import { CancellationPolicyModalComponent } from './cancellation-policy-modal/cancellation-policy-modal.component';

@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss'],
})
export class CancelBookingComponent implements OnInit {
  @Output() goBack = new EventEmitter<any>();

  @Input() dateToCancel: BookingDates;
  @Input() currentUser: User;
  @Input() booking: Booking;

  public TERMS_OF_SERVICE_LINK = $localize`http://help.gigital.se/en/articles/3315741-terms-of-use`;
  public POLICY_LINK = $localize`http://help.gigital.se/en/articles/3350100-payment-policy`;

  public CANCEL_REASON = CancelReason;
  public DATE_FORMAT = DAY_MONTH;

  public reasonOptionsArtist: { name: string; value: any }[] = [
    { name: $localize`No longer available`, value: CancelReason.NotAvailable },
    { name: $localize`Health reasons`, value: CancelReason.Health },
    { name: $localize`Other (Enter reason)`, value: CancelReason.Other },
  ];

  public reasonOptionsBooker: { name: string; value: any }[] = [
    { name: $localize`Event is cancelled`, value: CancelReason.Cancelled },
    { name: $localize`Booked another act`, value: CancelReason.BookedOtherAct },
    { name: $localize`Other (Write an explanation)`, value: CancelReason.Other },
  ];

  public cancellationForm: FormGroup;
  public selectedReason: CancelReason = CancelReason.NotAvailable;

  get amIBooker(): boolean {
    return amIBooker(this.booking, this.currentUser.id);
  }

  get isBookingSignedByBoth(): boolean {
    return isBookingSignedByBoth(this.booking);
  }

  get reasonOptions(): { name: string; value: any }[] {
    if (this.amIBooker) {
      return this.reasonOptionsBooker;
    } else {
      return this.reasonOptionsArtist;
    }
  }

  get freeCancelUntil(): Date {
    let bookingDates = this.booking.dates.map((d) => apiToDate(d.date));
    let latestDate: Date = bookingDates[0];

    if (this.dateToCancel?.date) {
      latestDate = apiToDate(this.dateToCancel.date);
    } else {
      for (let i = 1; i < bookingDates.length; i++) {
        const currentDate = bookingDates[i];

        if (currentDate > latestDate) {
          latestDate = currentDate;
        }
      }
    }

    let newDate = new Date(latestDate);
    newDate.setDate(newDate.getDate() - 30);

    return newDate;
  }

  constructor(
    private iconService: IconsRegistryService,

    private cancelBookingDateGQL: CancelBookingDatesGQL,
    private declineBooking: DeclineBookingGQL,
    private cancelBooking: CancelBookingGQL,

    private snackbar: MatSnackBar,
    private modal: MatDialog,
  ) {
    this.iconService.registerIcons([IconSubsets.NAVIGATION]);
  }

  public ngOnInit(): void {
    this.cancellationForm = new FormGroup({
      cancelReason: new FormControl(CancelReason.NotAvailable, Validators.required),
      message: new FormControl(),
    });
  }

  public onBookingCancel(): void {
    if (this.dateToCancel) {
      this.cancelBookingDateGQL
        .mutate({
          dateIds: [this.dateToCancel.id],
          isCancelling: true,
          // cancelledReason: this.cancellationForm.value
        })
        .subscribe(
          () => {
            this.goBack.next();
            this.snackbar.open($localize`The date was successfully canceled`, undefined, { duration: 3000 });
          },
          (error) => this.snackbar.open(error.message, undefined, { duration: 3000 }),
        );
    } else if (this.amIBooker) {
      this.cancelBooking
        .mutate({
          cancelReason: this.cancellationForm.value.cancelReason,
          message: this.cancellationForm.value.message,
          bookingId: this.booking.id,
        })
        .subscribe(
          () => {
            this.goBack.next();
            this.snackbar.open($localize`The booking was successfully canceled`, undefined, { duration: 3000 });
          },
          (error) => this.snackbar.open(error.message, undefined, { duration: 3000 }),
        );
    } else {
      this.declineBooking
        .mutate({
          cancelReason: this.cancellationForm.value.cancelReason,
          message: this.cancellationForm.value.message,
          bookingId: this.booking.id,
        })
        .subscribe(
          () => this.snackbar.open($localize`The booking was successfully canceled`, undefined, { duration: 3000 }),
          (error) => this.snackbar.open(error.message, undefined, { duration: 3000 }),
        );
    }
  }

  public openCancellationPolicyModal(): void {
    this.modal.open(CancellationPolicyModalComponent, { width: '570px' });
  }
}
