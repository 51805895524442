import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GetContentfulEntryGQL, GetPageTagListsGQL, Page } from '../../../generated/graphql';
import { IconsRegistryService, IconSubsets } from '../../services/icons-registry.service';
import { MetaService } from '../../services/meta.service';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-home-landing',
  templateUrl: './home-landing.component.html',
  styleUrls: ['./home-landing.component.scss'],
})
export class HomeLandingComponent implements OnInit {
  public pages$: Observable<Page[]>;

  public headerMetaData: { backgroundImage: any; ctaLabel: string; ctaUrl: string; title: string };
  public userFlowSteps: { stepTitle: string; stepDescription: string }[];
  public trendingSearchesBlocks: any[];
  public artistsListsBottom: any;
  public artistsListsTop: any;

  constructor(
    private getContentfulEntryGQL: GetContentfulEntryGQL,
    private getPageTagListsGQL: GetPageTagListsGQL,

    private translationsService: TranslationsService,
    private iconsService: IconsRegistryService,
    private metaService: MetaService,
  ) {
    this.iconsService.registerIcons([IconSubsets.ACTIONS]);
  }

  public ngOnInit(): void {
    this.getContentfulEntry();
  }

  private getContentfulEntry(): void {
    this.getContentfulEntryGQL
      .fetch({ name: 'Home-2.0', locale: this.translationsService.getCurrentLocale() })
      .subscribe(({ data }) => {
        if (data.getContentfulEntry.contentfulData.fields) {
          const pageMetaData = data.getContentfulEntry.contentfulData.fields;

          this.metaService.setContentfulSeoData(pageMetaData);
          this.parsePageMetaData(pageMetaData);
          this.getPagesLists();
        } else {
          throw new Error('Couldnt fetch contentful content.');
        }
      });
  }

  private parsePageMetaData(pageMetaData: any): void {
    this.headerMetaData = pageMetaData.blocks.find((e) => e.sys.contentType.sys.id === 'hero').fields;
    this.userFlowSteps = pageMetaData.blocks.find((e) => e.sys.id === '3XFqtk3gyaGji6ia9pm88C').fields.blocks;
    this.artistsListsTop = pageMetaData.blocks.find((e) => e.sys.id === '3eBaRyisZPSRLrx6OzQ5Fs').fields.blocks;
    this.trendingSearchesBlocks = pageMetaData.blocks.find((e) => e.sys.id === '3q6lHxgtMbW1wYG8MJt2H7').fields.blocks;
    this.artistsListsBottom = pageMetaData.blocks.find((e) => e.sys.id === '3glouwy9y2XWoQNMi28RMw').fields.blocks;
  }

  private getPagesLists() {
    const pageListsParams = [
      ...this.artistsListsTop.map((param) => JSON.parse(param.fields.ctAs.fields.queryParams)),
      ...this.artistsListsBottom.map((param) => JSON.parse(param.fields.ctAs.fields.queryParams)),
    ];

    this.getPageTagListsGQL.fetch({ tagLists: pageListsParams }).subscribe(({ data: { getPageTagLists } }) => {
      console.log(getPageTagLists);

      this.artistsListsTop[0].pages = getPageTagLists[0].pages;
      this.artistsListsTop[1].pages = getPageTagLists[1].pages;

      this.artistsListsBottom[0].pages = getPageTagLists[2].pages;
      this.artistsListsBottom[1].pages = getPageTagLists[3].pages;
    });
  }
}
